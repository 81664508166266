import { Grid, LinearProgress, makeStyles, Typography } from '@material-ui/core'
import React, { useContext, useEffect, useRef, useState } from 'react'
import useExhibition from '../../../../../engines/GeneralHooks/useExhibition';
import useExhibitors from '../../../../../engines/GeneralHooks/useExhibitors'
import useConfigurationService from '../../../../../utils/ConfigurationService';
import ExhibitorCard from '../../components/ExhibitorCard/Mobile'
import TagArea from '../../components/TagArea';
import GlobalContext from '../../context/GlobalContext';
import useExhibitorsQL from '../../../../../engines/GraphQL/hooks/useExhibitorsQL'
import { DEFAULT_PAGE_INDEX } from '../../../../../constants/Urls';
import useInfiniteScoll from '../../../../../engines/GeneralHooks/useInfiniteScoll';
import BookmarkSwitch from '../../components/BookmarkSwitch';

const useStyles = makeStyles(theme => ({
    root: {
        paddingTop: theme.spacing(5),
        //paddingBottom: theme.spacing(3),
        overflowY: "auto",
        overflowX: "hidden",
        paddingTop: "32px",
        width: "100%",
        height: "100%",
        marginLeft: 20,
        paddingLeft: 20,
        paddingRight: 20,
    },
    tagArea: {
        marginBottom: 16,
        marginRight: 50,
    },
    switch: {
        marginRight: 18,
        marginBottom: -80,
        marginTop: 4
    }
}));

function ExhibitorView() {
    const classes = useStyles();
    const containerRef = useRef();
    const { currentExhibitor, currentUser, setCurrentUser, setCurrentExhibitor } = useContext(GlobalContext);
    const [showBookmarks, setShowBookmarks] = useState(false);
    const [searchText, setSearchText] = useState();
    const [currentIndex, setCurrentIndex] = useState(DEFAULT_PAGE_INDEX);
    const [exhibitors, loading, totalPages, refetchBookmarks, fetchNewData] = useExhibitorsQL(searchText,currentIndex,15,showBookmarks);
    const [exhibition] = useExhibition();
    const context = useContext(GlobalContext);

    const [currentPageIndex, isBottom, resetPageIndex] = useInfiniteScoll(containerRef.current,totalPages,true,loading);

    useEffect(() => {
        if (searchText && searchText !== "") {
            resetPageIndex();
        }
    }, [searchText])
    
    useEffect(() => {
        if (currentPageIndex === currentIndex) {
            return
        }
        setCurrentIndex(currentPageIndex);
        fetchNewData();
    }, [currentPageIndex])

    useEffect(() => {
        resetPageIndex();
        fetchNewData();
    }, [context.tags])

    useEffect(() => {
        if (!exhibitors || exhibitors.length === 0) {
            return;
        }
        resetPageIndex();
        refetchBookmarks();
    }, [showBookmarks])

    const updateFeed = () => {
        if (showBookmarks) {
            resetPageIndex();
            fetchNewData();
        }
    }

    return !exhibitors ? (<div />) : (
        <div ref={containerRef} className={classes.root} >
            <div className={classes.switch}>
                <BookmarkSwitch showBookmarks={showBookmarks} setShowBookmarks={setShowBookmarks} />
            </div>
            <div className={classes.tagArea}>
                <TagArea resetPageIndex={() => resetPageIndex()} setSearchText={(text) => setSearchText(text)} exhibition={exhibition} />
            </div>
            {loading && <LinearProgress />}
            <Grid container direction="row">
                {exhibitors.map(exhibitor => exhibitor.isBookmarked() || !showBookmarks ? (<ExhibitorCard refetchBookmarks={() => updateFeed()} setExhibitor={setCurrentExhibitor} exhibitor={exhibitor} />):undefined)}
            </Grid>
        </div>
    )
}

export default ExhibitorView
