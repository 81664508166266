import { STATUS_CODES } from "./NetworkRequest";
import ApplicationError from "./ApplicationError";

export default class ResponseService {
  mapper = undefined;

  constructor(mapper) {
    this.mapper = mapper;
  }

  buildObject = json => {
    const data = json.data || json
    if (Array.isArray(data)) {
      return {
        objects: data === [] ? [] : this.mapper.buildFromArray(data),
        paginationData: json.pagination ? ResponseService.paginationMetaData(json.pagination):undefined,
        additional: undefined//json.additional
      };
    } else {
      return this.mapper.build(data);
    }
  };

  call = async (response, success, failure, optionalSuccessFunction = () => {}, optionalFailureFunction = () => {}) => {
    let object = undefined;
    let json = undefined;

    switch (response.status) {
      case STATUS_CODES.NO_CONTENT:
        return success(object);

      case STATUS_CODES.OK:
        json = await response.json();
        object = this.buildObject(json);
        optionalSuccessFunction(object)
        return success(object);
      case STATUS_CODES.CREATED:
        json = await response.json();
        object = this.buildObject(json);
        optionalSuccessFunction(object)
        return success(object);
      case STATUS_CODES.NOT_FOUND:
        optionalFailureFunction()
        return failure(ApplicationError.buildNotFound());
      case STATUS_CODES.FORBIDDEN:
        optionalFailureFunction()
        return failure(ApplicationError.buildForbidden());
      case STATUS_CODES.BAD_REQUEST:
        json = await response.json();
        optionalFailureFunction()
        return failure(ApplicationError.buildBadRequest());
      case STATUS_CODES.UNAUTHORIZED:
        optionalFailureFunction()
        return failure(ApplicationError.buildUnauthorized());
      case STATUS_CODES.INTERNAL_SERVER_ERROR:
        optionalFailureFunction(json)
        return failure(ApplicationError.buildInternalServerError());

      default:
        return failure(ApplicationError.buildInternalServerError());
    }
  };

  callAxios = async (response,data, success, failure, optionalSuccessFunction = () => {}, optionalFailureFunction = () => {}) => {
    let object = undefined;
    let json = data;

    switch (response.status) {
      case STATUS_CODES.NO_CONTENT:
        return success(object);

      case STATUS_CODES.OK:
        //json = await response.json();
        object = this.buildObject(json);
        optionalSuccessFunction(object)
        return success(object);
      case STATUS_CODES.CREATED:
        json = await response.json();
        object = this.buildObject(json);
        optionalSuccessFunction(object)
        return success(object);
      case STATUS_CODES.NOT_FOUND:
        optionalFailureFunction()
        return failure(ApplicationError.buildNotFound());
      case STATUS_CODES.FORBIDDEN:
        optionalFailureFunction()
        return failure(ApplicationError.buildForbidden());
      case STATUS_CODES.BAD_REQUEST:
        json = await response.json();
        optionalFailureFunction()
        return failure(ApplicationError.buildBadRequest());
      case STATUS_CODES.UNAUTHORIZED:
        optionalFailureFunction()
        return failure(ApplicationError.buildUnauthorized());
      case STATUS_CODES.INTERNAL_SERVER_ERROR:
        optionalFailureFunction(json)
        return failure(ApplicationError.buildInternalServerError());

      default:
        return failure(ApplicationError.buildInternalServerError());
    }
  };

  static paginationMetaData = payload => ({
    totalItemsCount: payload.total_items_count,
    totalPages: payload.total_pages,
    pageSize: payload.page_size,
    pageIndex: payload.page_index
  });

  static defaultPaginationMetaData = () => ({
    totalItemsCount: 0,
    totalPages: 1,
    pageSize: 10,
    pageIndex: 1
  });
}
