import { Accordion, IconButton, AccordionDetails, AccordionSummary, Chip, Grid, Typography, FormControlLabel, Checkbox, makeStyles, Input } from '@material-ui/core'
import React, { useContext, useEffect, useState } from 'react'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useTranslation } from 'react-i18next';
import MyStore from '../../../../../../utils/MyStore';

const useStyles = makeStyles(theme => ({
    root: {
    },
    container: {
        width: "inherit"
    },
    accordion: {
        border: "none",
        boxShadow: "none",
        width: "fit-content",
    },
    accordionSummery: {
        display: "inline-flex"
    },
    chipContainer: {
        marginRight: 8,
        marginBottom: 8,
    }
}))

function sleep(delay = 0) {
    return new Promise((resolve) => {
        setTimeout(resolve, delay);
    });
}

function EditTagArea({ exhibition, center, entityTags, setEntityTags, maxTags, disabled }) {
    const classes = useStyles();
    const { t } = useTranslation();

    const handleDelete = (tag) => {
        setEntityTags(entityTags.filter(element => element.getName() !== tag.getName()));
    }

    const handleAdd = (tag) => {
        if (entityTags.length < maxTags) {
            setEntityTags(entityTags.concat(tag));
        }
    }

    return !exhibition ? <div /> : (
        <div className={classes.container} onClick={(e) => {
            if (disabled) {
                e.stopPropagation()
            }
        }}>
            <Grid container direction="row" style={!center ? { width: "100%" } : undefined}>

                {exhibition && exhibition.getTags() && exhibition.getTags().map((tag) => {
                    const usedTag = entityTags.find(element => element.getName() === tag.getName());

                    return !usedTag ? (
                        <Grid item className={classes.chipContainer}>
                            <Chip
                                disabled={entityTags.length === maxTags || disabled}
                                color="secondary"
                                variant="outlined"
                                label={tag.getName(MyStore.getLocal())}
                                onClick={(event) => { handleAdd(tag); event.stopPropagation() }}
                                onFocus={(event) => event.stopPropagation()}
                            />
                        </Grid>
                    ) : (
                            <Grid item>
                                <Chip
                                    disabled={disabled}
                                    color="secondary"
                                    label={tag.getName(MyStore.getLocal())}
                                    onDelete={(event) => { handleDelete(tag); event.stopPropagation() }}
                                    onFocus={(event) => event.stopPropagation()}                                    
                                />
                            </Grid>
                        )

                })}
            </Grid>
        </div>
    )
}

export default EditTagArea
