import ApplicationModel from "./ApplicationModel";

export default class CommentsLike extends ApplicationModel {
  constructor(_opts = {}) {
    super(_opts);
    this.state = { ..._opts };
  }

  getUserId = () => this.state.userId
  getCommentId = () => this.state.commentId

  className = "CommentsLike"

  // Backend Attributes
  static attributes = {
    ...super.attributes,
    commentId: 'comment_id',
    userId: 'user_id'
  };

  static orderByParams = {
    ...super.orderByParams
  };

  static filterParams = {
    ...super.filterParams
  };
}
