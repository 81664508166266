import React, { useState, useEffect } from "react";
import LbMobileNavigation from "./LbMobileNavigation";
import LbAppBar from "./LbAppBar";
import LbScreenContainer from "./LbScreenContainer";
import "./styles.css";
import { createTheme, Grid, ThemeProvider } from "@material-ui/core";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  withRouter,
  Redirect
} from "react-router-dom";
import LbStreamContainer from "./StreamComponent/LbStreamContainer";
import { ScreenRotationOutlined } from "@material-ui/icons";
import LbPostContainer from "./LbPostContainer";
import useConfigurationService from "../../../utils/ConfigurationService";
import LbMobileExhibitorView from "./LbMobileExhibitorsContainer";
import LbMobileAgenda from "./LbMobileAgenda";

function MobileApp() {
  const [landscapeOrientation, setLandscapeOrientation] = useState(
    window.innerHeight < window.innerWidth
  );
  const [config] = useConfigurationService();

  useEffect(() => {
    window.addEventListener("resize", () =>
      setLandscapeOrientation(window.innerHeight < window.innerWidth)
    );
    return () =>
      window.removeEventListener("resize", () =>
        setLandscapeOrientation(window.innerHeight < window.innerWidth)
      );
  }, []);

  useEffect(() => {
    if (!config) {
      return
    }

    (window as any).intercomSettings = {
      ...(window as any).intercomSettings,
      app_id: (config as any).system.intercom_app_id,
      alignment: 'right',
      horizontal_padding: 20,
      vertical_padding: 20
    };

    (window as any).Intercom("update");
  }, [config])

  return landscapeOrientation ? (
    <div
      style={{
        display: "grid",
        padding: "3rem",
        alignItems: "center",
        justifyItems: "center",
        height: "100%",
        alignSelf: "center"
      }}
    >
      <Grid container direction="row" justify="center" alignItems="center">
        <Grid item xs={2}>
          <ScreenRotationOutlined />
        </Grid>
        <Grid item xs={10}>
          <p>
            Landscape mode is not supported, please use your device in portait
            orientation.
          </p>
        </Grid>
      </Grid>
    </div>
  ) : (
    <div className="LbMobileApp">
      <Switch>
        <Route exact path="/">
          <Redirect to="/feed" />
        </Route>
        <Route path="/feed">
          <LbPostContainer />
        </Route>
        <Route path="/exhibitors">
          <LbMobileExhibitorView />
        </Route>
        <Route path="/agenda">
          <LbMobileAgenda />
        </Route>
        <Route path="/profile">
          <LbScreenContainer color={"orange"} />
        </Route>
      </Switch>
      <LbAppBar />
      <LbStreamContainer />
      <LbMobileNavigation />
    </div>
  );
}

export default MobileApp;
