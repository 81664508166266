import ApplicationModel from "./ApplicationModel";

export default class PostsLike extends ApplicationModel {
  constructor(_opts = {}) {
    super(_opts);
    this.state = { ..._opts };
  }

  getUserId = () => this.state.userId
  getPostId = () => this.state.postId

  className = "PostsLike"

  // Backend Attributes
  static attributes = {
    ...super.attributes,
    postId: 'post_id',
    userId: 'user_id'
  };

  static orderByParams = {
    ...super.orderByParams
  };

  static filterParams = {
    ...super.filterParams
  };
}
