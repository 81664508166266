import Post from "../models/Post";
import BaseMapper from "./BaseMapper";
import ExhibitorMapper from "./ExhibitorMapper";
import CommentMapper from "./CommentMapper";
import BookmarkMapper from "./BookmarkMapper";
import ErrorLogger from "../utils/ErrorLogger";
import DocumentMapper from "./DocumentMapper";
import ImageMapper from "./ImageMapper";
import LocalTextMapper from "./LocalTextMapper";
import VideoMapper from "./VideoMapper";
import MyStore from "../utils/MyStore";
import { compareIds } from "../utils";

const attributes = Post.attributes;

export default class PostMapper {
  static build = (json, bookmark = undefined) => {
    if (!json) {
      return;
    }

    try {
      return new Post({
        ...BaseMapper.attributes(json),
        text: json.text,
        uid: json.uid,
        userId: json.user_id,
        exhibitor: ExhibitorMapper.build(json.exhibitor),
        exhibitorId: json.exhibitor.id,
        exhibitionId: json.exhibition ? json.exhibition.id : undefined,
        document: undefined,//DocumentMapper.build(json[attributes.document]),
        visibleAt: new Date(json[attributes.visibleAt]),
        title: json[attributes.title],
        postType: json[attributes.postType],
        isBookmarked: json.marks && json.marks.length > 0 && json.marks[0].bookmark ? true:false,
        bookmark: json.marks && json.marks.length > 0 && json.marks[0].bookmark ? json.marks[0]:undefined,
        salesmark: json.marks && json.marks.length > 0 && json.marks[0].salesmark ? json.marks[0]:undefined,
        isSalesmarked: json.marks && json.marks.length > 0 && json.marks[0].salesmark ? true:false,
        documents: DocumentMapper.buildFromArray(json.documents),
        likesCount: json.post_likes ? json.post_likes.length : 0,
        likedByUser: json.post_likes.find((like) => {
          return compareIds(like.user.id,MyStore.getCurrentUser().getId()) || compareIds(like.user,MyStore.getCurrentUser().getId());
        }) !== undefined,
        commentsCount: json.comment_count,
        lastComments: CommentMapper.buildFromArray(json.comments),
        postMedia: VideoMapper.build(json.post_media),
        pictures: ImageMapper.buildFromArray(json.pictures),
        localDescription: LocalTextMapper.buildFromArray(json.local_description),
      });
    } catch (error) {
      ErrorLogger.call(this, error);
    }
  };

  static buildFromArray = (jsonArray = [], bookmarks = []) => {
    const resultArray = jsonArray.map((entry) => {
      const bm = bookmarks.find(b => b.getBookmarkableId() === entry.id);
      return this.build(entry, bm);
    });
    
    return resultArray.filter(a => a !== undefined);
  };

  static toJsonString(post) {
    return JSON.stringify({
      [attributes.visibleAt]: post.getVisibleAt(),
      [attributes.exhibitorId]: post.getExhibitorId(),
      [attributes.text]: post.getText(),
      [attributes.title]: post.getTitle(),
      [attributes.postType]: post.getPostType(),
      post_media: post.getPostMedia(MyStore.getLocal())
    });
  }
}
