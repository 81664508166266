import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { AppBar, Toolbar } from '@material-ui/core';
import { config } from "../../../config/config";
import useConfigurationService from '../../../utils/ConfigurationService';
import FrontendRoutes from '../../../constants/FrontendRoutes';

const useStyles = makeStyles(() => ({
  root: {
    boxShadow: 'none'
  }
}));

function Topbar({ className, ...rest }) {
  const classes = useStyles();
  const [config] = useConfigurationService()

  return !config ? <div/> : (
    <AppBar
      {...rest}
      className={clsx(classes.root, className)}
      color="primary"
    >
      <Toolbar>
        <RouterLink to={FrontendRoutes.auth.login()}>
          <img
            alt="Logo"
            src={config.login.logo_icon.url}
            style={{width:"100px", height:"100px", objectFit:"scale-down"}}
          />
        </RouterLink>
      </Toolbar>
    </AppBar>
  );
}

Topbar.propTypes = {
  className: PropTypes.string
};

export default Topbar;
