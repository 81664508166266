import Configuration from "../models/Configuration";

export default class ConfigurationMapper {
  static build = json => {
    if (!json) {
      return;
    }

    return new Configuration({
      ...json
    });
  };

  static buildFromArray = (jsonArray = []) => {
    return jsonArray.map(entry => {
      return this.build(entry);
    });
  };
}