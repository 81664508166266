import {
  FETCHING_EXHIBITION_MEETINGS_USERS_REQUEST,
  FETCHING_EXHIBITION_MEETINGS_USERS_SUCCESS,
  FETCHING_EXHIBITION_MEETINGS_USERS_FAILURE,

  CREATE_EXHIBITION_MEETINGS_USER_REQUEST,
  CREATE_EXHIBITION_MEETINGS_USER_SUCCESS,
  CREATE_EXHIBITION_MEETINGS_USER_FAILURE,

  UPDATE_EXHIBITION_MEETINGS_USER_REQUEST,
  UPDATE_EXHIBITION_MEETINGS_USER_SUCCESS,
  UPDATE_EXHIBITION_MEETINGS_USER_FAILURE,

  DELETE_EXHIBITION_MEETINGS_USER_REQUEST,
  DELETE_EXHIBITION_MEETINGS_USER_SUCCESS,
  DELETE_EXHIBITION_MEETINGS_USER_FAILURE,

} from './types';
import Urls, { DEFAULT_OPTIONAL_PARAMETERS } from '../../constants/Urls';
import { BEARER } from '../../utils/MyStore';

import MeetingsUserMapper from '../../mapper/MeetingsUserMapper';
import ResponseService from '../../utils/ResponseService';
import ApplicationError from '../../utils/ApplicationError';
import ErrorLogger from '../../utils/ErrorLogger';

export const fetchingMeetingsUsersRequest = () => ({
  type: FETCHING_EXHIBITION_MEETINGS_USERS_REQUEST
})

export const fetchingMeetingsUsersSuccess = (json) => ({
  type: FETCHING_EXHIBITION_MEETINGS_USERS_SUCCESS,
  payload: json
})
export const fetchingMeetingsUsersFailure = (error) => ({
  type: FETCHING_EXHIBITION_MEETINGS_USERS_FAILURE,
  payload: error
})

export const createMeetingsUserRequest = () => ({
  type: CREATE_EXHIBITION_MEETINGS_USER_REQUEST
})

export const createMeetingsUserSuccess = (meetingsUser) => ({
  type: CREATE_EXHIBITION_MEETINGS_USER_SUCCESS,
  payload: meetingsUser
})
export const createMeetingsUserFailure = (error) => ({
  type: CREATE_EXHIBITION_MEETINGS_USER_FAILURE,
  payload: error
})

export const updateMeetingsUserRequest = () => ({
  type: UPDATE_EXHIBITION_MEETINGS_USER_REQUEST
})

export const updateMeetingsUserSuccess = (meetingsUser) => ({
  type: UPDATE_EXHIBITION_MEETINGS_USER_SUCCESS,
  payload: meetingsUser
})

export const updateMeetingsUserFailure = (error) => ({
  type: UPDATE_EXHIBITION_MEETINGS_USER_FAILURE,
  payload: error
})

export const deleteMeetingsUserRequest = () => ({
  type: DELETE_EXHIBITION_MEETINGS_USER_REQUEST
})

export const deleteMeetingsUserSuccess = () => ({
  type: DELETE_EXHIBITION_MEETINGS_USER_SUCCESS
})

export const deleteMeetingsUserFailure = (error) => ({
  type: DELETE_EXHIBITION_MEETINGS_USER_FAILURE,
  payload: error
})

export const fetchMeetingsUsers = (exhibitionId, meetingId, _opts = DEFAULT_OPTIONAL_PARAMETERS) => {
  return async dispatch => {
    dispatch(fetchingMeetingsUsersRequest());
    try {

      const route = Urls.exhibitions.meetings_users.all
      const bearer = localStorage.getItem(BEARER)
      const headers = Urls.header.secure(bearer)
      const url     = Urls.build(route.url(exhibitionId, meetingId, _opts.pageIndex, _opts.pageSize), _opts.filters)

      let response = await fetch(url, {
        method: route.method,
        headers: headers
      });

      let responseService = new ResponseService(MeetingsUserMapper)

      let action = await responseService.call(response, 
        (value) => fetchingMeetingsUsersSuccess(value),
        (error) => fetchingMeetingsUsersFailure(error))

      dispatch(action)  
    } catch(error) {
      ErrorLogger.call(this, error)
      dispatch(fetchingMeetingsUsersFailure(error))
    }
  };
};

export const createMeetingsUser = (meetingsUser) => {
  return async dispatch => {
    dispatch(createMeetingsUserRequest());
    try {

      const route = Urls.exhibitions.meetings_users.create
      const bearer = localStorage.getItem(BEARER)
      const headers = Urls.header.secure(bearer)

      let response = await fetch(route.url(meetingsUser.getExhibitionId(), meetingsUser.getId()), {
        method: route.method,
        headers: headers,
        body: MeetingsUserMapper.toJsonString(meetingsUser)
      });

      let responseService = new ResponseService(MeetingsUserMapper)

      let action = await responseService.call(response, 
        (value) => createMeetingsUserSuccess(value),
        (error) => createMeetingsUserFailure(error))

      dispatch(action)  
    } catch(error) {
      ErrorLogger.call(this, error)
      dispatch(createMeetingsUserFailure(ApplicationError.buildApplicationError(error)))
    }
  };
};

export const updateMeetingsUser = (exhibitionId,meetingId,meetingsUser) => {
  return async dispatch => {
    dispatch(updateMeetingsUserRequest());
    try {

      const route = Urls.exhibitions.meetings_users.update
      const bearer = localStorage.getItem(BEARER)
      const headers = Urls.header.secure(bearer)
      const url = route.url(exhibitionId, meetingId, meetingsUser.getId());

      let response = await fetch(url, {
        method: route.method,
        headers: headers,
        body: MeetingsUserMapper.toJsonString(meetingsUser)
      });

      let responseService = new ResponseService(MeetingsUserMapper)

      let action = await responseService.call(response, 
        (value) => updateMeetingsUserSuccess(value),
        (error) => updateMeetingsUserFailure(error))

      dispatch(action)  
    } catch(error) {
      ErrorLogger.call(this, error)
      dispatch(updateMeetingsUserFailure(ApplicationError.buildApplicationError(error)))
    }
  };
};

export const deleteMeetingsUser = (meetingsUser) => {
  return async dispatch => {
    dispatch(deleteMeetingsUserRequest());
    try {

      const route = Urls.exhibitions.meetings_users.delete
      const bearer = localStorage.getItem(BEARER)
      const headers = Urls.header.secure(bearer)
      const url = route.url(meetingsUser.getExhibitionId(), meetingsUser.getId())

      let response = await fetch(url, {
        method: route.method,
        headers: headers
      });

      let responseService = new ResponseService(MeetingsUserMapper)

      let action = await responseService.call(response, 
        (value) => deleteMeetingsUserSuccess(value),
        (error) => deleteMeetingsUserFailure(error))

      dispatch(action)  
    } catch(error) {
      ErrorLogger.call(this, error)
      dispatch(deleteMeetingsUserFailure(ApplicationError.buildApplicationError(error)))
    }
  };
};