import React, { useEffect, useState,useRef} from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import { Lightbox } from 'react-modal-image';
import {
  Avatar,
  Box,
  Link,
  Typography,
  makeStyles
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import MyStore from '../../../utils/MyStore';
import "moment/locale/de";
import "moment/locale/nl";
import Tooltip from "@material-ui/core/Tooltip";
import UserDetailModal from '../../layouts/Dashboard/components/UserDetailModal';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(2),
    display: 'flex',
    overflow: "hidden",
  },
  avatar: {
    height: 32,
    width: 32,
    cursor: 'pointer',
  },
  image: {
    cursor: 'pointer',
    height: 'auto',
    maxWidth: '100%',
    width: 380
  },
  messageText: {
    whiteSpace: "pre-wrap",
    marginLeft: 8,
  },
  demiBold: {
    fontWeight: 700,
    cursor: 'pointer',
  },
  wordBreak: {
    wordBreak: "break-word"
  },
}));

function Message({
  className,
  message,
  messagesRef,
  ...rest
}) {
  const classes = useStyles();
  const messageRef = useRef();
  const [openedFile, setOpenedFile] = useState(null);
  const [t] = useTranslation();
  const usersReducer = useSelector(state => state.usersReducer);
  const [tooltipIsOpen, setTooltipIsOpen] = useState(false);
  const [isProfileOpened, setIsProfileOpened] = useState(false);

  useEffect(() => {
    moment.locale(MyStore.getLocal())
  }, [MyStore.getLocal()])

  useEffect(() =>{
    const spaceForTooltipCheck = () =>{
      if ((messageRef.current.getBoundingClientRect().top - 50)<
                messagesRef.current.clientHeight){
                  setTooltipIsOpen(false);
                }
    }
    messagesRef.current.addEventListener('scroll', spaceForTooltipCheck);

  },[])
  
  const currentId = usersReducer.currentUser.state.id;
  const ids = Object.keys(message.conversation.channelState.attributes);
  const otherId = ids.find(id => id !== currentId);
  const authorId = message.state.author.split('-')[1];
  
  const author = message.conversation.channelState.attributes[authorId].userForVideo.state;

  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Box
        display="flex"
        maxWidth={500}
        ml={message.state.author.split('-')[1] === String(currentId) ? 'auto' : 0}
      >
        <Avatar
          className={classes.avatar}
          onClick={() => setIsProfileOpened(true)}
          src={
            message.state.author.split('-')[1] === String(currentId)
              ? usersReducer.currentUser.state.profilePicture ? usersReducer.currentUser.state.profilePicture.state.url : ''
              : message.conversation.channelState.attributes[currentId].img
          }
        />
        <Box ml={2}>
        <Tooltip
            title={moment(message.state.dateUpdated).fromNow()}
            open={tooltipIsOpen}
            ref={messageRef}
            onOpen={() => {
              if (
                (messageRef.current.getBoundingClientRect().top - 50)>
                messagesRef.current.clientHeight
              ) {
                setTooltipIsOpen(true);
              }
            }}
            onClose={() => setTooltipIsOpen(false)}
            placement="top"
          >
          <Box
            bgcolor={'primary.main'}
            color={'primary.contrastText'}
            bgcolor={
              message.state.author.split('-')[1] === String(currentId)
                ? 'primary.main'
                : 'white'}
            color={
              message.state.author.split('-')[1] === String(currentId)
                ? 'primary.contrastText'
                : 'text.primary'}
            py={1}
            px={2}
            borderRadius="borderRadius"
            boxShadow={1}
          >
                <Box className={classes.wordBreak}>
                <Typography>
                  <Typography
                    component="b"
                    className={classes.demiBold}
                    onClick={() => setIsProfileOpened(true)}
                  >
                  {message.state.author.split('-')[1] === String(currentId)
                ? t('frontend.me')
                : message.conversation.channelState.attributes[currentId].nameChat}
                  </Typography>
                      {message.contentType === "image" ? (
                        <Box mt={2} onClick={() => setOpenedFile(message.body)}>
                          <img
                            alt="Attachment"
                            className={classes.image}
                            src={message.body}
                          />
                        </Box>
                      ) : (
                        <Typography
                          color="inherit"
                          variant="body1"
                          display="inline"
                          className={classes.messageText}
                        >
                          {message.state.body}
                        </Typography>
                      )}
                  </Typography>
              </Box>
              
          </Box>
          </Tooltip>
        </Box>
      </Box>
      {openedFile && (
        <Lightbox
          large={openedFile}
          onClose={() => setOpenedFile(null)}
        />
      )}
      {isProfileOpened && (
        <UserDetailModal 
          userId={authorId} 
          setClose={setIsProfileOpened} 
          open={isProfileOpened} 
          visitorList={[author]} 
          currentVisitor={author} 
          currentIndex={0}/>
      )}
    </div>
  );
}

Message.propTypes = {
  className: PropTypes.string,
  message: PropTypes.object.isRequired
};

export default Message;
