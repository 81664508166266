import React, { Suspense, useState, useEffect, lazy } from "react";
import { renderRoutes } from "react-router-config";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/styles";
import { Grid, LinearProgress, Typography } from "@material-ui/core";
import NavBar from "./NavBar";
import TopBar from "./TopBarTablet";
import OldTopBar from "./OldTopBar";
import ChatBar from "./Chat/ChatBarMobile";
import ScheduleBar from "./ScheduleBarMobile";
import useExhibition from "../../../engines/GeneralHooks/useExhibition";
import Exhibition from "../../../models/Exhibition";
import MeetingView from "./components/MeetingView";
import { useRawPrivatePushNotification } from "../../../engines/Notification/NotificationEngine";
import PushNotification from "../../../models/PushNotification";
import Meeting from "../../../models/Meeting";
import { useSelector } from "react-redux";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import ExhibitorDetailsModal from "./components/ExhibitorDetailsModal/Mobile";
import UserProfile from "./components/UserProfile";
import GlobalContext from "./context/GlobalContext";

import PostFeed from "./PostFeed/Mobile";
import VisitorView from "./views/VisitorView/Mobile";
import ExhibitorView from "./views/ExhibitorView/Mobile";
import Agenda from "../../views/Agenda";
import MyStore from "../../../utils/MyStore";
import SponsorView from "./views/SponsorView/Mobile";
import useWindowSize from "../../../engines/Util/useWindowSize";
import GlobalModal, { MODAL_TYPE } from "./components/GlobalModal/Mobile";
import { useLocation } from "react-router";
import queryString from "query-string";
import context from "react-bootstrap/esm/AccordionContext";
import Exhibitor from "../../../models/Exhibitor";
import useTracking, {
  EVENT_TRACKING_TYPES,
  VISIT_TRACKING_TYPES,
} from "../../engines/tracking/useTracking";
import useConfigurationService from "../../../utils/ConfigurationService";

const useStyles = makeStyles((theme) => ({
  container: {
    //maxWidth: "1758px",
    minWidth: "800px",
    marginLeft: "auto",
    marginRight: "auto",
    position: "relative",
    display: "flex",
    backgroundColor: theme.palette.background.default,
    overflowY: "hidden",
    overflowX: "auto",
  },
  content: {
    height: "100%",
    width: "100%",
    padding: 20,
    paddingTop: 16,
    zIndex: 900,
    position: "relative",
    overflow: "hidden",
  },
  keyvisual: {
    position: "fixed",
    left: 0,
    right: 0,
    height: 400,
    width: "100%",
    objectFit: "cover",
    zIndex: 800,
  },
  keyvisualOverlay: {
    position: "fixed",
    left: 0,
    right: 0,
    top: 250,
    height: 150,
    width: "100%",
    objectFit: "cover",
    zIndex: 801,
  },
  feed: {
    overflow: "hidden",
    minWidth: "650px",
  },
  oldContainer: {
    minHeight: "100vh",
    display: "flex",
    "@media all and (-ms-high-contrast:none)": {
      height: 0, // IE11 fix
    },
  },
  oldContent: {
    paddingRight: 374,
    paddingTop: 64,
    flexGrow: 1,
    maxWidth: "100%",
    overflowX: "hidden",
    [theme.breakpoints.up("lg")]: {
      paddingLeft: 274,
    },
    [theme.breakpoints.down("xs")]: {
      paddingTop: 56,
    },
  },
}));

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const TAB_DEFINITION = {
  postFeed: 0,
  exhibitors: 1,
  visitors: 2,
  agenda: 3,
  sponsors: 4,
  userProfile: 5,
};

function Dashboard({ route }) {
  const classes = useStyles();
  const [exhibition] = useExhibition();
  const [tabIndex, setTabIndex] = useState(0);
  const [meetingId, setMeetingId] = useState();
  const [width, height] = useWindowSize();

  const [incoming] = useRawPrivatePushNotification();
  const [trackEvent, trackVisit] = useTracking();
  const [currentExhibitor, setCurrentExhibitor] = useState();
  const [loadingStage, setLoadingStage] = useState(0);
  const [currentUser, setCurrentUser] = useState();
  const [tags, setTags] = useState(MyStore.getTags() || []);
  const [bookmarkedPost, setBookmarkedPost] = useState();
  const [updateShortlist, setUpdateShortlist] = useState(false);
  const [salesmarkExhibitor, setSalesmarkExhibitor] = useState();
  const [videoFullWidth, setVideoFullWidth] = useState(false);
  const [modalStack, setModalStack] = useState([]);
  const [muted, setMuted] = useState(false);
  // {
  //   type: MODAL_TYPE.USER_MODAL,
  //   id: "5fa91715ee605d0017a48592"
  // }

  const [oldView, setOldView] = useState(false);
  const [openNavBarMobile, setOpenNavBarMobile] = useState(false);
  const [videoOpen, setVideoOpen] = useState(false);

  const users = useSelector((state) => state.usersReducer);

  const [keynoteConversation, setKeynoteConversation] = useState();
  const [videoMode, setVideoMode] = useState(0);
  const query = useQuery();
  const [chatOpen, setChatOpen] = useState(false)
  const [config] = useConfigurationService();

  useEffect(() => {
    if (!config) {
      return
    }

    if (chatOpen) {
      window.intercomSettings = {
        ...window.intercomSettings,
        app_id: config.system.intercom_app_id,
      };
      delete window.intercomSettings.alignment;
      delete window.intercomSettings.horizontal_padding;
      delete window.intercomSettings.vertical_padding;
  
      window.Intercom("update");
    } else {
      window.intercomSettings = {
        ...window.intercomSettings,
        app_id: config.system.intercom_app_id,
        alignment: 'right',
        horizontal_padding: 20,
        vertical_padding: 20
      };
  
      window.Intercom("update");
    }
  }, [chatOpen, config])

  useEffect(() => {
    MyStore.setTags(tags);
  }, [tags]);

  useEffect(() => {
    if (exhibition && users.currentUser) {
    }
  }, [exhibition, users.currentUser]);

  useEffect(() => {
    if (!incoming) {
      return;
    }

    if (
      incoming.getNotificationType() ===
      PushNotification.NOTIFICATION_TYPES.meetingStarted &&
      incoming.getPayload().getReason() === Meeting.reason.CHAT_ONE_TO_ONE
    ) {
      setMeetingId(incoming.getPayload().getId());
    }
  }, [incoming]);

  useEffect(() => {
    if (meetingId) {
      setMuted(true);
    } else {
      setMuted(false);
    }
  }, [meetingId]);

  function hexToRgbA(hex, alpha) {
    var c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
      c = hex.substring(1).split("");
      if (c.length == 3) {
        c = [c[0], c[0], c[1], c[1], c[2], c[2]];
      }
      c = "0x" + c.join("");
      return (
        "rgba(" +
        [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") +
        "," +
        alpha +
        ")"
      );
    }
    throw new Error("Bad Hex");
  }

  document.documentElement.style.overflow =
    meetingId || currentExhibitor || currentUser ? "hidden" : "scroll";
  document.body.scroll =
    meetingId || currentExhibitor || currentUser ? "no" : "yes";

  const openGlobalModal = (modalType, objectId) => {
    setModalStack([{ type: modalType, id: objectId }]);
  };

  const closeGlobalModal = () => {
    setModalStack([]);
  };

  const location = useLocation();

  useEffect(() => {
    if (!users.currentUser || !exhibition) {
      return;
    }

    switch (tabIndex) {
      case TAB_DEFINITION.postFeed:
        trackVisit(VISIT_TRACKING_TYPES.feed);
        break;
      case TAB_DEFINITION.exhibitors:
        trackVisit(VISIT_TRACKING_TYPES.exhibitors);
        break;
      case TAB_DEFINITION.visitors:
        trackVisit(VISIT_TRACKING_TYPES.attendees);
        break;
      case TAB_DEFINITION.agenda:
        trackVisit(VISIT_TRACKING_TYPES.agenda);
        break;
      case TAB_DEFINITION.sponsors:
        trackVisit(VISIT_TRACKING_TYPES.sponsors);
        break;
      case TAB_DEFINITION.userProfile:
        trackVisit(VISIT_TRACKING_TYPES.userProfile);
        break;
      default:
        break;
    }
  }, [tabIndex, exhibition, users.currentUser]);

  useEffect(() => {
    if (muted) {
      trackEvent(EVENT_TRACKING_TYPES.stage.stageMuted, { muted })
    }
  }, [muted])

  return oldView ? (
    <div>
      <OldTopBar onOpenNavBarMobile={() => setOpenNavBarMobile(true)} />
      <NavBar
        onMobileClose={() => setOpenNavBarMobile(false)}
        openMobile={openNavBarMobile}
        setKeynoteConversation={(conversation) =>
          setKeynoteConversation(conversation)
        }
        setParentVideoMode={(mode) => setVideoMode(mode)}
      />
      <ChatBar
        videoOpen={videoOpen}
        detailView={videoMode === 2}
        setVideoOpen={(open) => setVideoOpen(open)}
        keynoteConversation={keynoteConversation}
      />
      <div className={classes.oldContainer}>
        <div className={classes.oldContent}>
          <Suspense fallback={<LinearProgress />}>
            {renderRoutes(route.routes)}
          </Suspense>
        </div>
      </div>
    </div>
  ) : (
      <GlobalContext.Provider
        value={{
          currentExhibitor,
          currentUser,
          tags,
          tabIndex,
          loadingStage,
          bookmarkedPost,
          meetingId,
          updateShortlist,
          salesmarkExhibitor,
          videoFullWidth,
          modalStack,
          muted,
          setMuted,
          setVideoFullWidth,
          setSalesmarkExhibitor,
          setUpdateShortlist,
          setMeetingId,
          setBookmarkedPost,
          setLoadingStage,
          setTabIndex,
          setCurrentUser,
          setCurrentExhibitor,
          setTags,
          openGlobalModal,
          closeGlobalModal,
        }}
      >
        <div className={classes.container} style={{ height: height }}>
          <ScheduleBar
            setParentVideoMode={(mode) => setVideoMode(mode)}
            setKeynoteConversation={(conversation) =>
              setKeynoteConversation(conversation)
            }
          />
          <MeetingView
            meetingId={meetingId}
            closeMeeting={() => setMeetingId(undefined)}
          />
          <GlobalModal />
          <div className={classes.content}>
            <div style={{ width: "100%", paddingLeft: 15 }}>
              <TopBar
                setTabIndex={(index) => setTabIndex(index)}
                tabIndex={tabIndex}
                exhibition={exhibition}
              />
            </div>
            <Grid container direction="row" style={{ height: "100%" }}>
              {
                exhibition &&
                  exhibition.getExhibitionType() ===
                  Exhibition.EXHIBITION_TYPES.LANDINGPAGE ? (
                    <div className={classes.feed}>
                      <PostFeed />
                    </div>
                  ) : (
                    //<SwipeableViews style={{height: "100%", overflow:"hidden"}} scrolling={false} index={tabIndex}>
                    <div
                      style={{
                        height: "100%",
                        overflow: "hidden",
                        width: chatOpen ? "calc(100% - 350px)":"100%",
                      }}
                    >
                      {tabIndex === 0 && (
                        <div
                          className={classes.feed}
                          hidden={tabIndex !== 0}
                          style={{ height: height - 64 }}
                        >
                          <PostFeed />
                        </div>
                      )}
                      {tabIndex === 1 && (
                        <div
                          className={classes.feed}
                          hidden={tabIndex !== 1}
                          style={{ height: height - 64 }}
                        >
                          <ExhibitorView />
                        </div>
                      )}
                      {tabIndex === 2 && (
                        <div
                          className={classes.feed}
                          hidden={tabIndex !== 2}
                          style={{ height: height - 64 }}
                        >
                          <VisitorView />
                        </div>
                      )}
                      {tabIndex === 3 && (
                        <div
                          className={classes.feed}
                          hidden={tabIndex !== 3}
                          style={{ height: height - 64 }}
                        >
                          <Agenda />
                        </div>
                      )}
                      {tabIndex === 4 && (
                        <div
                          className={classes.feed}
                          hidden={tabIndex !== 4}
                          style={{ height: height - 64 }}
                        >
                          <SponsorView />
                        </div>
                      )}
                      {users.currentUser && exhibition && (
                        <div
                          className={classes.feed}
                          hidden={tabIndex !== 5}
                          style={{ height: height - 64, padding: 50 }}
                        >
                          <UserProfile
                            exhibition={exhibition}
                            userId={
                              users.currentUser
                                ? users.currentUser.getId()
                                : undefined
                            }
                          />
                        </div>
                      )}
                    </div>
                  ) /* </SwipeableViews> */
              }
              <ChatBar
                videoOpen={videoOpen}
                detailView={videoMode === 2}
                setVideoOpen={(open) => setVideoOpen(open)}
                keynoteConversation={keynoteConversation}
                chatOpen={chatOpen}
                setChatOpen={setChatOpen}
              />
            </Grid>
          </div>
          <ExhibitorDetailsModal open={true} />
          {/* <GlobalModal /> */}
        </div>
      </GlobalContext.Provider>
    );
}

Dashboard.propTypes = {
  route: PropTypes.object,
};

export default Dashboard;
