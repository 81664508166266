import ApplicationModel from "./ApplicationModel";

export default class MeetingsUser extends ApplicationModel {
  constructor(_opts = {}) {
    super(_opts);
    this.state = { ..._opts };
  }

  getUserId = () => this.state.user.getId();
  getUser = () => this.state.user;
  getUserFullName = () => this.state.userFullName;
  getMeetingId = () => this.state.meetingId;

  getStatus = () => this.state.status;
  setStatus = (value) => this.state.status = value;

  static status = {
    REQUESTED: "requested",
    ACCEPTED: "accepted",
    DECLINED: "declined",
  };

  // Backend Attributes
  static attributes = {
    ...super.attributes,
    userId: "user_id",
    meetingId: "meeting_id",
    status: "status",
    userFullName: "user_full_name",
  };

  static orderByParams = {
    ...super.orderByParams,
  };

  static filterParams = {
    ...super.filterParams,
    status: this.attributes.status,
    userId: this.attributes.userId
  };
}
