import Meeting from "../models/Meeting";
import BaseMapper from "./BaseMapper";
import UserMapper from "./UserMapper";
import ErrorLogger from "../utils/ErrorLogger";
import MeetingsUserMapper from "./MeetingsUserMapper";

const attributes = Meeting.attributes;

export default class MeetingMapper {
  static build = (json = undefined) => {
    if (!json) {
      return;
    }

    try {
      return new Meeting({
        ...BaseMapper.attributes(json),
        title: json[attributes.title],
        startAt: json[attributes.startAt],
        endAt: json[attributes.endAt],
        reason: json[attributes.reason],
        status: json[attributes.status],
        meetingStatus: json[attributes.meetingStatus],
        participants: UserMapper.buildFromArray(json[attributes.participants]),
        meetingsUsers: MeetingsUserMapper.buildFromArray(json[attributes.meetingsUsers]),
        user: UserMapper.build(json[attributes.user]),
        twilioConfiguration: json.twilio_configuration,
        exhibition: json.exhibition,
      });
    } catch (error) {
      ErrorLogger.call(this, error);
    }
  };

  static buildFromArray = (jsonArray = []) => {
    return jsonArray.map((entry) => {
      return this.build(entry);
    });
  };

  static toJsonString(meeting) {
    return JSON.stringify({
      id: meeting.getId(),
      [attributes.title]: meeting.getTitle(),
      [attributes.status]: meeting.getStatus(),
      [attributes.startAt]: meeting.getStartAt(),
      [attributes.endAt]: meeting.getEndAt(),
      [attributes.reason]: meeting.getReason(),
      [attributes.meetingStatus]: meeting.getMeetingStatus(),
      [attributes.participants]: meeting.getParticipants().map(participant => participant.state),
      [attributes.exhibition]: meeting.getExhibition(),
    });
  }
}
