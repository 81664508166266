import { Box, Grid, IconButton, Input } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import MyStore from "../../../../../utils/MyStore";
import FilterDropdown from "../../../../components/FilterDropdown";
import useTracking, {
  EVENT_TRACKING_TYPES,
} from "../../../../engines/tracking/useTracking";
import GlobalContext from "../../context/GlobalContext";
import NumberRange from "./../../../../components/NumberRange";
import { useSelector } from 'react-redux'

function sleep(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}

function TagArea({
  exhibition,
  center,
  setSearchText,
  resetPageIndex,
  showTags = true,
  showRange = false,
}) {
  const { tags, setTags, minPrice, setMinPrice, maxPrice, setMaxPrice } =
    useContext(GlobalContext);
  const { t } = useTranslation();
  const [searchInput, setSearchInput] = useState();
  const [trackEvent] = useTracking();
  const {configuration: {state: {filters}}} = useSelector(state => state.configurationsReducer)

  const rangeFilterMinimum = useMemo(() => {
    return exhibition && exhibition.getMinPrice();
  }, [exhibition]);

  const rangeFilterMaximum = useMemo(() => {
    return exhibition && exhibition.getMaxPrice();
  }, [exhibition]);

  const step = useMemo(() => {
    return exhibition && exhibition.getPriceSteps();
  }, [exhibition]);

  useEffect(() => {
    if (!setSearchText) {
      return;
    }
    let active = true;

    (async () => {
      await sleep(1e3);
      if (active) {
        resetPageIndex();
        setSearchText(searchInput);
      }
    })();

    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchInput]);

  const onTagsFilterChange = (newTagList) => {
    tags.forEach((removedTag) => {
      trackEvent(EVENT_TRACKING_TYPES.feed.deactivatedTag, {
        tag: removedTag.getName(),
        exhibitionId: exhibition.getId(),
      });
    });

    newTagList.forEach((newTag) => {
      trackEvent(EVENT_TRACKING_TYPES.feed.tagActivated, {
        tag: newTag.getName(),
        exhibitionId: exhibition.getId(),
      });
    });

    setTags(newTagList);
  };

  if (!exhibition) return <div />;

  return (
    <Box width="100%">
      <Grid
        container
        direction="row"
        alignItems="center"
        style={!center && setSearchText ? { width: "100%" } : undefined}
        spacing={1}
      >
        {showTags && filters && filters.tags_filter && (
          <Grid item>
            <FilterDropdown
              label={t("frontend.pages.feed.filter_label")}
              optionList={(exhibition && exhibition.getTags()) || []}
              selectedList={tags}
              setSelectedList={onTagsFilterChange}
              getValue={(tag) => tag.getName()}
              getLabel={(tag) => tag.getName(MyStore.getLocal())}
              multiple
            />
          </Grid>
        )}
        {showRange && filters && filters.price_range_filter &&(
          <Grid item>
            <NumberRange
              prefixId="range-filter"
              buttonText={t("frontend.pages.feed.price_range_label")}
              minimum={rangeFilterMinimum}
              maximum={rangeFilterMaximum}
              step={step}
              leftValue={minPrice}
              rightValue={maxPrice}
              setValues={(left, right) => {
                setMinPrice(left);
                setMaxPrice(right);
              }}
            />
          </Grid>
        )}
        {setSearchText && filters && filters.search &&(
          <Grid item>
            <Box display="flex" alignItems="center" pl={3}>
              <Input
                type="search"
                placeholder={t("frontend.search")}
                style={{ width: 120 }}
                value={searchInput}
                onKeyUp={(event) => {
                  if (event.keyCode === 13 || event.key === 13) {
                    setSearchText(searchInput);
                  }
                }}
                onChange={(e) => setSearchInput(e.target.value)}
              />
              <IconButton
                onClick={() => setSearchText(searchInput)}
                size="30px"
                style={{ padding: 0 }}
              >
                <SearchIcon />
              </IconButton>
            </Box>
          </Grid>
        )}
      </Grid>
    </Box>
  );
}

export default TagArea;
