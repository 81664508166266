import { Grid, Typography, Divider } from '@material-ui/core'
import { KeyboardArrowDown } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import React from 'react'
import { useState } from 'react'
import NeumorphicStyle from '../../../../../../constants/Neumorphic'

const useStyles = makeStyles((theme) => ({
    root: {},
    attachmentContainter: {
        marginTop: 30,
    },
    attachmentDropdownContainer: {
        justifyItems: "center",
        background: theme.palette.background.default,
        zIndex: 10000,
        position:"relative"
    },
    attachmentDropdown: {
        background: theme.palette.background.default,
        height: 50,
        justifyItems: "center",
        alignItems: "center",
        paddingLeft: 8,
        paddingRight: 8,
        borderRadius: 5,
        boxShadow: NeumorphicStyle.boxShadowMedium(theme.palette.primary.main, theme.palette.secondary.dark),
        transition: "height 0.2s, opacity 0.2s 0.2s",
    },
    attachmentDropdownOpen: {
        background: theme.palette.background.default,
        overflow: "hidden",
        position: "absolute",
        padding: 10,
        borderRadius: 5,
        boxShadow: NeumorphicStyle.boxShadowMedium(theme.palette.primary.main, theme.palette.secondary.dark),
        transition: "height 0.2s, opacity 0.2s 0.2s",
    },
    attachmentDropdownTitle: {
    },
    openDropdownIcon: {
        transition: "transform .3s ease-in-out",
    },
    closeDropdownIcon: {
        transition: "transform .3s ease-in-out",
        transform: "rotate(180deg)",
    },
    attachmentDropdownCard: {
        marginTop: 10,
        height: 40
    },
}))

function LeadbaseDropdown({ placeholder = "", value, width = 350, childHeight = 45, children, ...rest }) {
    const classes = useStyles();
    const [open, setOpen] = useState(false)

    return (
        <div className={classes.attachmentDropdownContainer} style={{width: width, height: childHeight}}>
            <div
                onClick={() => setOpen(!open)}
                className={open ? classes.attachmentDropdownOpen : classes.attachmentDropdown}
                style={{ height: open ? children.length * childHeight + childHeight : childHeight, width: width, paddingTop: (childHeight - 22) / 2 }}
            >
                <Grid container direction="row">
                    <Typography className={classes.attachmentDropdownTitle} variant="h5">
                        {value || placeholder}
                    </Typography>
                    <KeyboardArrowDown className={open ? classes.closeDropdownIcon : classes.openDropdownIcon} style={{ marginLeft: "auto" }} />
                </Grid>
                {open && (<Divider color="black" variant="fullWidth" />)}
                {open && children.map(child => (
                    <div style={{height: childHeight, width: width - 16, cursor: "pointer"}}>
                        {child}
                    </div>
                ))}
            </div>
        </div>
    )
}

export default LeadbaseDropdown
