import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchExhibitors } from "../../redux/actions/ExhibitorsActions"
import { fetchVisitors } from "../../redux/actions/VisitorsActions"
import { fetchMessages } from "../../redux/actions/MessagesActions"
import { fetchConversations } from "../../redux/actions/ConversationsActions"
import { fetchPosts } from "../../redux/actions/PostsActions"
import { fetchKeynotes } from "../../redux/actions/KeynotesActions"
import Exhibitor from "../../models/Exhibitor"
import User from "../../models/User"
import Message from "../../models/Message"
import Post from "../../models/Post"
import Keynote from "../../models/Keynote"
import Conversation from "../../models/Conversation"
import FilterParams from '../../utils/FilterParams'
import { DEFAULT_PAGE_SIZE, DEFAULT_PAGE_INDEX } from "../../constants/Urls";

function sleep(delay = 0) {
    return new Promise((resolve) => {
        setTimeout(resolve, delay);
    });
}

export default function useSearchEngine(searchText, searchDataType, pageIndex = DEFAULT_PAGE_INDEX, pageSize = DEFAULT_PAGE_SIZE) {
    const dispatch = useDispatch();

    const [resultSet, setResultSet] = useState();
    const [searching, setSearching] = useState(false);
    const [finishedSearch, setFinishedSearch] = useState(false);
    const [totalPages, setTotalPages] = useState(0);
    const [totalItemsCount, setTotalItemsCount] = useState(0);

    const exhibitorsReducer = useSelector(state => state.exhibitorsReducer)
    const visitorsReducer = useSelector(state => state.visitorsReducer)
    const messagesReducer = useSelector(state => state.messagesReducer)
    const conversationsReducer = useSelector(state => state.conversationsReducer)
    const postsReducer = useSelector(state => state.postsReducer)
    const keynotesReducer = useSelector(state => state.keynotesReducer);
    const exhibitions = useSelector(state => state.exhibitionsReducer);
    const stagesReducer = useSelector(state => state.stagesReducer);

    useEffect(() => {
        let active = true;
        if (!exhibitions.exhibition) {
            return;
        }

        if (!searchText || searchText === "") {
            setResultSet(undefined);
            setSearching(false);
            setFinishedSearch(false);
            setTotalPages(0);
            return;
        }

        const id = exhibitions.exhibition.getId();

        (async () => {
            await sleep(3e2);
            if (active) {
                switch (searchDataType) {
                    case SEARCH_DATATYPE.exhibitor:
                        dispatch(fetchExhibitors(id, {
                            pageIndex: pageIndex,
                            pageSize: pageSize,
                            filters: [
                                FilterParams.filterBy(Exhibitor.filterParams.search, searchText)
                            ]
                        }));
                        break;
                    case SEARCH_DATATYPE.visitor:
                        dispatch(fetchVisitors(id, {
                            pageIndex: pageIndex,
                            pageSize: pageSize,
                            filters: [
                                FilterParams.filterBy(User.filterParams.search, searchText)
                            ]
                        }));
                        break;
                    case SEARCH_DATATYPE.keynote:
                        dispatch(fetchKeynotes(id, {
                            pageIndex: pageIndex,
                            pageSize: pageSize,
                            filters: [
                                FilterParams.filterBy(Keynote.filterParams.search, searchText)
                            ]
                        }));
                        break;
                    case SEARCH_DATATYPE.chat:
                        dispatch(fetchMessages(id, {
                            pageIndex: pageIndex,
                            pageSize: pageSize,
                            filters: [
                                FilterParams.filterBy(Message.filterParams.text, searchText)
                            ]
                        }));
                        break;
                    case SEARCH_DATATYPE.conversation:
                        dispatch(fetchConversations(id, {
                            pageIndex: pageIndex,
                            pageSize: pageSize,
                            filters: [
                                // FilterParams.filterBy(Conversation.filterParams.superuser, false),
                                // FilterParams.filterBy(Conversation.filterParams.standalone, true),
                                FilterParams.filterBy(Conversation.filterParams.search, searchText)
                            ]
                        }));
                        break;
                    case SEARCH_DATATYPE.post:
                        dispatch(fetchPosts(id, {
                            pageIndex: pageIndex,
                            pageSize: pageSize,
                            filters: [
                                FilterParams.filterBy(Post.filterParams.search, searchText)
                            ]
                        }));
                        break;
                }

                setSearching(true);
            }
        })();

        return () => {
            active = false;
        }
    }, [exhibitions.currentSet, searchText, pageSize, pageIndex])

    useEffect(() => {
        let currentReducer;
        let currentDataSet;

        switch (searchDataType) {
            case SEARCH_DATATYPE.exhibitor:
                currentReducer = exhibitorsReducer;
                currentDataSet = exhibitorsReducer.exhibitors;
                break;
            case SEARCH_DATATYPE.visitor:
                currentReducer = visitorsReducer;
                currentDataSet = visitorsReducer.visitors;
                break;
            case SEARCH_DATATYPE.keynote:
                currentReducer = keynotesReducer;
                currentDataSet = keynotesReducer.keynotes;
                break;
            case SEARCH_DATATYPE.chat:
                currentReducer = messagesReducer;
                currentDataSet = messagesReducer.messages;
                break;
            case SEARCH_DATATYPE.conversation:
                currentReducer = conversationsReducer;
                currentDataSet = conversationsReducer.conversations;
                break;
            case SEARCH_DATATYPE.post:
                currentReducer = postsReducer;
                currentDataSet = postsReducer.posts;
                break;
            case SEARCH_DATATYPE.stage:
                currentReducer = stagesReducer;
                currentDataSet = stagesReducer.stages;
                break;
            default:
                currentDataSet = undefined;
                currentReducer = undefined;
                break;
        }

        if (
            !currentReducer ||
            currentReducer.isFetching ||
            !currentDataSet ||
            currentReducer.error.isError() ||
            !searchText || searchText === ""
        ) {
            return;
        }


        setTotalPages(currentReducer.totalPages);
        setTotalItemsCount(currentReducer.totalItemsCount);
        setResultSet(currentDataSet);
        setSearching(false);
        setFinishedSearch(true);
    }, [
        exhibitorsReducer, visitorsReducer, messagesReducer, conversationsReducer, postsReducer, keynotesReducer
    ])

    return [resultSet, searching, finishedSearch, totalPages, totalItemsCount]
}

export const SEARCH_DATATYPE = {
    exhibitor: 0,
    visitor: 1,
    post: 2,
    conversation: 3,
    chat: 4,
    keynote: 5,
    stage: 6,
}

