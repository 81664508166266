import PropTypes from 'prop-types'
import Post from "../models/Post";
import MyStore from "../utils/MyStore";

const PUBLIC_CHANNEL = "private-push-";

export default class PublicCable {

  static propTypes = {
    post: PropTypes.instanceOf(Post),
  };

  constructor(cable, exhibition) {
    const userUid = MyStore.getCurrentUser() && MyStore.getCurrentUser().getId()

    this.state = {
      userUid: userUid,
      cable: cable,
      subscription: undefined,
      exhibition: exhibition,
    };
  }

  disconnect = () => {
    this.state.cable.unsubscribe(
      PUBLIC_CHANNEL + this.state.exhibition.getId()
    )
  }

  connect = (callback) => {
    if (this.state.subscription) {
      this.disconnect()
    }

    let subscription = this.state.cable.channel(PUBLIC_CHANNEL + this.state.exhibition.getId());
    if (!subscription) {
      subscription = this.state.cable.subscribe(
        PUBLIC_CHANNEL + this.state.exhibition.getId()
      );
    }
    subscription.bind("notification", data => callback(data));

    this.state.subscription = subscription
  }
}
