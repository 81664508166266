/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect } from "react";
import { useHistory } from "react-router";
import PropTypes from "prop-types";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import {
  AppBar,
  Badge,
  Button,
  IconButton,
  Toolbar,
  Hidden,
  Input,
  colors,
  Popper,
  Paper,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ClickAwayListener,
  InputLabel,
  Select,
  FormControl,
  Typography,
  Tooltip,
} from "@material-ui/core";
import NotificationsIcon from "@material-ui/icons/NotificationsOutlined";
import InputIcon from "@material-ui/icons/Input";
import MenuIcon from "@material-ui/icons/Menu";
import SearchIcon from "@material-ui/icons/Search";
import NotificationsPopover from "../../components/NotificationsPopover";
import { deleteSession } from "../../../redux/actions/SessionActions";
import FrontendRoutes from "../../../constants/FrontendRoutes";
import useNotificationEngine from "../../../engines/Notification/NotificationEngine";
import { MenuItem } from "react-contextmenu";
import { Trans, useTranslation } from "react-i18next";
import {
  updateUser,
  logOutUser,
  clearUser,
} from "../../../redux/actions/UsersActions";
import User from "../../../models/User";
import PushNotification from "../../../models/PushNotification";
import {
  DEFAULT_PAGE_INDEX,
  DEFAULT_PAGE_SIZE,
  MAX_PAGE_SIZE,
} from "../../../constants/Urls";
import { useActiveKeynote } from "../../../engines/GeneralHooks/useKeynotes";
import { Link } from "react-router-dom";
import NeumorphicStyle from "../../../constants/Neumorphic";
import MyStore from "../../../utils/MyStore";

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.background.default,
    borderRadius: NeumorphicStyle.borderRadius,
    boxShadow: `-5px 5px 15px ${theme.palette.primary.main}, 5px -5px 15px ${theme.palette.secondary.dark}, inset 2px -2px 4px ${theme.palette.primary.main}, inset -2px 2px 4px ${theme.palette.secondary.dark}`,
    //marginLeft:320,
    marginTop: 16,
    right: 16,
    [theme.breakpoints.up("lg")]: {
      left: 288,
    },
    [theme.breakpoints.down("md")]: {
      left: 16,
    },
    position: "fixed",
    zIndex: "999",
  },
  flexGrow: {
    flexGrow: 1,
  },
  formControl: {
    maxHeight: "40px",
  },
  search: {
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: theme.palette.primary.main,
    backgroundColor: "rgba(255,255,255, 0.1)",
    borderRadius: 4,
    flexBasis: 300,
    height: 36,
    padding: theme.spacing(0, 2),
    display: "flex",
    alignItems: "center",
  },
  searchIcon: {
    marginRight: theme.spacing(2),
    color: theme.palette.primary.main,
  },
  searchInput: {
    flexGrow: 1,
    color: "text",
    "& input::placeholder": {
      opacity: 1,
      color: "inherit",
    },
  },
  searchPopper: {
    zIndex: theme.zIndex.appBar + 100,
  },
  searchPopperContent: {
    marginTop: theme.spacing(1),
  },
  menuButton: {
    marginRight: theme.spacing(1) - 256,
  },
  chatButton: {
    marginLeft: theme.spacing(1),
  },
  notificationsButton: {
    marginLeft: theme.spacing(1),
  },
  notificationsBadge: {
    backgroundColor: colors.orange[600],
  },
  logoutButton: {
    marginLeft: theme.spacing(1),
  },
  logoutIcon: {
    marginRight: theme.spacing(1),
  },

  profileButton: {
    "&:hover": {
      transition: "none",
    },
  },

  profilePicture: {
    height: theme.spacing(4),
    width: theme.spacing(4),
    borderRadius: "50%",
    marginRight: theme.spacing(1),
  },
}));

const popularSearches = [];

function OldTopBar({ onOpenNavBarMobile, className, ...rest }) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const notificationsRef = useRef(null);
  const [openSearchPopover, setOpenSearchPopover] = useState(false);
  const [openNotifications, setOpenNotifications] = useState(false);
  const [notificationPageIndex, setNotificationPageIndex] = useState(
    DEFAULT_PAGE_INDEX
  );
  const [notificationPageSize, setNotificationPageSize] = useState(
    DEFAULT_PAGE_SIZE
  );
  const [logout, setLogout] = useState(false);
  const [openChatBar, setOpenChatBar] = useState(false);
  const [availability, setAvailability] = useState();

  const users = useSelector((state) => state.usersReducer);
  const session = useSelector((state) => state.sessionReducer);
  const [
    notifications,
    loading,
    incomingNotification,
    unreadMessagesCount,
    resetUnreadMessages,
    totalPages,
  ] = useNotificationEngine(
    openNotifications,
    notificationPageSize,
    notificationPageIndex
  );
  const [activeKeynote, activeKeynoteLoading] = useActiveKeynote();

  const [imageUrl, setImageUrl] = useState();

  useEffect(() => {
    if (!users.user) {
      return;
    }

    setImageUrl(
      users.user.getProfilePicture()
        ? users.user.getProfilePicture().getUrl("small")
        : ""
    );
    setAvailability(
      users.user.getAvailabilityStatus()
        ? users.user.getAvailabilityStatus("small")
        : ""
    );
  }, [users.user, users.currentUser]);

  useEffect(() => {
    if (openNotifications) {
      return;
    }
    setNotificationPageIndex(DEFAULT_PAGE_INDEX);
  }, [openNotifications]);

  useEffect(() => {
    if (!logout || session.isFetching) {
      return;
    }
    history.push(FrontendRoutes.auth.login());
  }, [session.isFetching]);

  const handleChatBarClose = () => {
    setOpenChatBar(false);
  };

  const handleLogout = async () => {
    setLogout(true);
    handleChatBarClose();
    await dispatch(deleteSession());
    await dispatch(logOutUser());
    await dispatch(clearUser());
    MyStore.removeAllKeys();
    history.push(FrontendRoutes.auth.logout());
  };

  const handleNotificationsOpen = () => {
    setOpenNotifications(true);
    resetUnreadMessages();
  };

  const handleNotificationsClose = () => {
    setOpenNotifications(false);
  };

  const handleAvailabilityChange = (event) => {
    const status = event.target.value
      ? event.target.value
      : User.AVAILABILITY_STATUS.AVAILABLE;
    if (!users.user) {
      return;
    }
    users.user.setAvailabilityStatus(status);
    dispatch(updateUser(users.user));
    setAvailability(status);
  };

  // const handleSearchChange = (event) => {
  //   setSearchValue(event.target.value);

  //   if (event.target.value) {
  //     if (!openSearchPopover) {
  //       setOpenSearchPopover(true);
  //     }
  //   } else {
  //     setOpenSearchPopover(false);
  //   }
  // };

  const handleSearchPopverClose = () => {
    setOpenSearchPopover(false);
  };

  const handleLoadMore = () => {
    setNotificationPageIndex(notificationPageIndex + 1);
  };

  const activeKeynoteContainer = () => {
    if (users.currentUser.hasExpoOnlyPermissions()) {
      return undefined;
    }

    return activeKeynoteLoading ? (
      <Typography
        variant="h6"
        style={{
          borderStyle: "solid",
          borderWidth: 1,
          borderColor: "black",
          borderRadius: "10px",
          padding: "6px",
          marginLeft: 0,
        }}
      >
        <Trans>frontend.topBar.loading_active_keynote</Trans>
      </Typography>
    ) : activeKeynote ? (
      <Tooltip title={activeKeynote.getTitle()}>
        <Link to={FrontendRoutes.workshops.show(activeKeynote.getId())}>
          <Typography
            variant="h6"
            style={{
              borderStyle: "solid",
              borderWidth: 1,
              borderColor: "black",
              borderRadius: "10px",
              padding: "6px",
              marginLeft: 0,
            }}
          >
            <Trans>frontend.topBar.active_keynote</Trans>
            {activeKeynote.getTitle().length > 30
              ? activeKeynote.getTitle().substring(0, 30) + "..."
              : activeKeynote.getTitle()}
          </Typography>
        </Link>
      </Tooltip>
    ) : (
      <Typography
        variant="h6"
        style={{
          borderStyle: "solid",
          borderWidth: 1,
          borderColor: "black",
          borderRadius: "10px",
          padding: "6px",
          marginLeft: 0,
        }}
      >
        <Trans>frontend.topBar.no_active_keynote</Trans>
      </Typography>
    );
  };

  return (
    <div {...rest} className={clsx(classes.root, className)} color="secondary">
      <Toolbar>
        <Hidden lgUp>
          <IconButton
            className={classes.menuButton}
            color="default"
            onClick={onOpenNavBarMobile}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
        {users.currentUser && activeKeynoteContainer()}
        <div className={classes.flexGrow} />
        {/* <Hidden smDown>
          <div className={classes.search} ref={searchRef}>
            <SearchIcon className={classes.searchIcon} />
            <Input
              className={classes.searchInput}
              disableUnderline
              onChange={handleSearchChange}
              placeholder="Search people &amp; places"
              value={searchValue}
            />
          </div>
          <Popper
            anchorEl={searchRef.current}
            className={classes.searchPopper}
            open={openSearchPopover}
            transition
          >
            <ClickAwayListener onClickAway={handleSearchPopverClose}>
              <Paper className={classes.searchPopperContent} elevation={3}>
                <List>
                  {popularSearches.map((search) => (
                    <ListItem
                      button
                      key={search}
                      onClick={handleSearchPopverClose}
                    >
                      <ListItemIcon>
                        <SearchIcon />
                      </ListItemIcon>
                      <ListItemText primary={search} />
                    </ListItem>
                  ))}
                </List>
              </Paper>
            </ClickAwayListener>
          </Popper>
        </Hidden> */}
        {/* <Hidden mdDown> */}{" "}
        {/** 
           * <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel id="availability-status"><Trans>frontend.status.availability.label</Trans></InputLabel>
          <Select
            labelId="availability-status"
            id="availability-label"
            value={availability ? availability:" "}
            onChange={handleAvailabilityChange}
            label={t("frontend.availability.label")}
            style={{minWidth:"120px", maxHeight:"40px"}}
          >
            <MenuItem value={User.AVAILABILITY_STATUS.AVAILABLE}><Trans>frontend.status.availability.available</Trans></MenuItem>
            <MenuItem value={User.AVAILABILITY_STATUS.MUTED}><Trans>frontend.status.availability.muted</Trans></MenuItem>
          </Select>
        </FormControl>
           * 
          */}
        <IconButton
          className={classes.notificationsButton}
          color="text"
          onClick={handleNotificationsOpen}
          ref={notificationsRef}
        >
          <Badge
            badgeContent={unreadMessagesCount}
            classes={{ badge: classes.notificationsBadge }}
            variant="dot"
          >
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <Button
          className={classes.profileButton}
          color="text"
          onClick={() => {
            history.push(FrontendRoutes.users.show(users.currentUser.getId()));
          }}
        >
          <img
            src={
              users.currentUser
                ? users.currentUser.getProfilePicture()
                  ? users.currentUser.getProfilePicture().getUrl("small")
                  : ""
                : ""
            }
            className={classes.profilePicture}
          />
          {users.currentUser ? users.currentUser.getFullName() : "Name Missing"}
        </Button>
        <Button
          className={classes.logoutButton}
          color="text"
          onClick={handleLogout}
        >
          <InputIcon className={classes.logoutIcon} />
          Logout
        </Button>
        {/* </Hidden> */}
      </Toolbar>
      <NotificationsPopover
        anchorEl={notificationsRef.current}
        notifications={notifications}
        loading={loading}
        handleLoadMore={() => handleLoadMore()}
        onClose={handleNotificationsClose}
        open={openNotifications}
        loadMoreActive={notificationPageIndex !== totalPages}
      />
    </div>
  );
}

OldTopBar.propTypes = {
  className: PropTypes.string,
  onOpenNavBarMobile: PropTypes.func,
};

export default OldTopBar;
