import {
  FETCHING_EXHIBITION_CONVERSATIONS_REQUEST,
  FETCHING_EXHIBITION_CONVERSATIONS_SUCCESS,
  FETCHING_EXHIBITION_CONVERSATIONS_FAILURE,

  FETCHING_EXHIBITION_CONVERSATION_REQUEST,
  FETCHING_EXHIBITION_CONVERSATION_SUCCESS,
  FETCHING_EXHIBITION_CONVERSATION_FAILURE,

  CREATE_EXHIBITION_CONVERSATION_REQUEST,
  CREATE_EXHIBITION_CONVERSATION_SUCCESS,
  CREATE_EXHIBITION_CONVERSATION_FAILURE,

  UPDATE_EXHIBITION_CONVERSATION_REQUEST,
  UPDATE_EXHIBITION_CONVERSATION_SUCCESS,
  UPDATE_EXHIBITION_CONVERSATION_FAILURE,

  DELETE_EXHIBITION_CONVERSATION_REQUEST,
  DELETE_EXHIBITION_CONVERSATION_SUCCESS,
  DELETE_EXHIBITION_CONVERSATION_FAILURE,

  CLEAR_EXHIBITION_CONVERSATION,
  SET_UNREAD_MESSAGES_COUNT

} from './types';
import Urls, { DEFAULT_OPTIONAL_PARAMETERS } from '../../constants/Urls';
import { BEARER } from '../../utils/MyStore';
import ApplicationError from '../../utils/ApplicationError';
import ResponseService from '../../utils/ResponseService';
import ConversationMapper from '../../mapper/ConversationMapper';
import ErrorLogger from '../../utils/ErrorLogger';
import axios from 'axios'

export const fetchingConversationsRequest = () => ({
  type: FETCHING_EXHIBITION_CONVERSATIONS_REQUEST
})

export const fetchingConversationsSuccess = (json) => ({
  type: FETCHING_EXHIBITION_CONVERSATIONS_SUCCESS,
  payload: json
})
export const fetchingConversationsFailure = (error) => ({
  type: FETCHING_EXHIBITION_CONVERSATIONS_FAILURE,
  payload: error
})

export const fetchingConversationRequest = () => ({
  type: FETCHING_EXHIBITION_CONVERSATION_REQUEST
})

export const fetchingTokenConversationsSuccess = (token) => {
  return ({
    type: 'TOKEN_CONVERSATTION',
    payload: token.token
  })
}

export const fetchingConversationSuccess = (conversation) => ({
  type: FETCHING_EXHIBITION_CONVERSATION_SUCCESS,
  payload: conversation
})
export const fetchingConversationFailure = (error) => ({
  type: FETCHING_EXHIBITION_CONVERSATION_FAILURE,
  payload: error
})

export const createConversationRequest = () => ({
  type: CREATE_EXHIBITION_CONVERSATION_REQUEST
})

export const createConversationSuccess = (conversation) => ({
  type: CREATE_EXHIBITION_CONVERSATION_SUCCESS,
  payload: conversation
})

export const createConversationFailure = (error) => ({
  type: CREATE_EXHIBITION_CONVERSATION_FAILURE,
  payload: error
})

export const updateConversationRequest = () => ({
  type: UPDATE_EXHIBITION_CONVERSATION_REQUEST
})

export const updateConversationSuccess = (conversation) => ({
  type: UPDATE_EXHIBITION_CONVERSATION_SUCCESS,
  payload: conversation
})

export const updateConversationFailure = (error) => ({
  type: UPDATE_EXHIBITION_CONVERSATION_FAILURE,
  payload: error
})

export const deleteConversationRequest = () => ({
  type: DELETE_EXHIBITION_CONVERSATION_REQUEST
})

export const deleteConversationSuccess = () => ({
  type: DELETE_EXHIBITION_CONVERSATION_SUCCESS
})

export const deleteConversationFailure = (error) => ({
  type: DELETE_EXHIBITION_CONVERSATION_FAILURE,
  payload: error
})

export const clearConversation = () => ({
  type: CLEAR_EXHIBITION_CONVERSATION
})

export const setUnreadConversationsMessagesCount = (val) => ({
  type: SET_UNREAD_MESSAGES_COUNT,
  payload: val
})

export const getTokenConversations = () => {
  return async dispatch => {    
    try {
      const bearer  = localStorage.getItem(BEARER)
      const headers = Urls.header.secure(bearer)

      let response = await axios.post(
        `${process.env.REACT_APP_WEB_PROTOCOL}${process.env.REACT_APP_API_URL}/twilio-token`,
        {},
        {
          headers: headers,
        }
      );

      dispatch(fetchingTokenConversationsSuccess(response.data))
    } catch(error) {
      ErrorLogger.call(this, error)
      dispatch(fetchingConversationsFailure(ApplicationError.buildApplicationError(error)))
    }
  };
};

export const createTwilioIdentity = (userId) => {
  return async (dispatch) => {
    try {
      const bearer = localStorage.getItem(BEARER);
      const headers = Urls.header.secure(bearer);

      await axios.get(
        `${process.env.REACT_APP_WEB_PROTOCOL}${process.env.REACT_APP_API_URL}/twilio-identity`,
        {
          headers: headers,
          params: { userId },
        }
      );
    } catch (error) {
      ErrorLogger.call(this, error);
      dispatch(
        fetchingConversationsFailure(
          ApplicationError.buildApplicationError(error)
        )
      );
    }
  };
};

export const fetchConversations = (exhibitionId, _opts = DEFAULT_OPTIONAL_PARAMETERS) => {
  return async dispatch => {
    dispatch(fetchingConversationsRequest());
    
    try {
      const route   = Urls.exhibitions.conversations.all
      const bearer  = localStorage.getItem(BEARER)
      const headers = Urls.header.secure(bearer)
      const url     = Urls.build(route.url(exhibitionId, _opts.pageIndex, _opts.pageSize), _opts.filters)

      let response = await fetch(url, {
        method: route.method,
        headers: headers
      });

      let responseService = new ResponseService(ConversationMapper)

      let action = await responseService.call(response, 
        (value) => fetchingConversationsSuccess(value),
        (error) => fetchingConversationsFailure(error))

      dispatch(action)
    } catch(error) {
      ErrorLogger.call(this, error)
      dispatch(fetchingConversationsFailure(ApplicationError.buildApplicationError(error)))
    }
  };
};

export const fetchConversation = (exhibitionId, id) => {
  return async dispatch => {
    dispatch(fetchingConversationRequest());

    try {
      const route   = Urls.exhibitions.conversations.show;
      const bearer  = localStorage.getItem(BEARER);
      const headers = Urls.header.secure(bearer);
      const url     = Urls.build(route.url(exhibitionId, id))

      let response = await fetch(url, {
        method: route.method,
        headers: headers
      })

      let responseService = new ResponseService(ConversationMapper)

      let action = await responseService.call(response, 
        (value) => fetchingConversationSuccess(value),
        (error) => fetchingConversationFailure(error))

      dispatch(action)

    } catch(error) {
      ErrorLogger.call(this, error)
      dispatch(fetchingConversationFailure(error))
    }
  }
}

export const createConversation = (conversation) => {
  return async dispatch => {
    dispatch(createConversationRequest());
    
    try {
      const route   = Urls.exhibitions.conversations.create
      const bearer  = localStorage.getItem(BEARER)
      const headers = Urls.header.secure(bearer)
      const url     = Urls.build(route.url(conversation.getExhibitionId()))

      let response = await fetch(url, {
        method: route.method,
        headers: headers,
        body: ConversationMapper.toJsonString(conversation)
      });

      let responseService = new ResponseService(ConversationMapper)

      let action = await responseService.call(response, 
        (value) => createConversationSuccess(value),
        (error) => createConversationFailure(error))

      dispatch(action)
    } catch(error) {
      ErrorLogger.call(this, error)
      dispatch(createConversationFailure(ApplicationError.buildApplicationError(error)))
    }
  };
};

export const updateConversation = (conversation) => {
  return async dispatch => {
    dispatch(updateConversationRequest());
    
    try {
      const route   = Urls.exhibitions.conversations.update
      const bearer  = localStorage.getItem(BEARER)
      const headers = Urls.header.secure(bearer)
      const url     = Urls.build(route.url(conversation.getExhibitionId(), conversation.getId()))

      let response = await fetch(url, {
        method: route.method,
        headers: headers,
        body: ConversationMapper.toJsonString(conversation)
      });

      let responseService = new ResponseService(ConversationMapper)

      let action = await responseService.call(response, 
        (value) => updateConversationSuccess(value),
        (error) => updateConversationFailure(error))

      dispatch(action)
    } catch(error) {
      ErrorLogger.call(this, error)
      dispatch(updateConversationFailure(ApplicationError.buildApplicationError(error)))
    }
  };
};

export const deleteConversation = (conversation) => {
  return async dispatch => {
    dispatch(deleteConversationRequest());
    
    try {
      const route   = Urls.exhibitions.conversations.delete
      const bearer  = localStorage.getItem(BEARER)
      const headers = Urls.header.secure(bearer)
      const url     = route.url(conversation.getExhibitionId(), conversation.getId())

      let response = await fetch(url, {
        method: route.method,
        headers: headers
      });

      let responseService = new ResponseService(ConversationMapper)

      let action = await responseService.call(response, 
        (value) => deleteConversationSuccess(value),
        (error) => deleteConversationFailure(error))

      dispatch(action)
    } catch(error) {
      ErrorLogger.call(this, error)
      dispatch(deleteConversationFailure(ApplicationError.buildApplicationError(error)))
    }
  };
};