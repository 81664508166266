import React, { useEffect, useState } from 'react'
import useFetch from "../../GeneralHooks/useFetch";
import { Session } from "@heroiclabs/nakama-js"
import Urls from '../../../constants/Urls';
import MyStore, { BEARER } from '../../../utils/MyStore';

function useNakamaServer() {
    const [session, setSession] = useState<Session>();
    const url = Urls.nakama.auth.url(); //http://127.0.0.1:1337/nakama-auth
    //const url = 'https://staging-backend.leadbase.de/nakama-auth'
    const { response, isLoading, error} = useFetch(url, {
        headers: Urls.header.secure(localStorage.getItem(BEARER)) as HeadersInit,
        method: Urls.nakama.auth.method,
    });
    
    useEffect(() => {
        if (!response) {
            return;
        }

        const { token, refreshToken, created } = response;
        if (!response.token) {
            return;
        }
        setSession(new Session(token, refreshToken, created));

    }, [response])

    return [session, isLoading, error];
}

export default useNakamaServer
