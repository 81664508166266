import { useEffect, useState } from "react";

const useFetch = (url: RequestInfo, options?: RequestInit) => {
  const [response, setResponse] = useState<any>(null);
  const [error, setError] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const res = await fetch(url, options);
        const json = await res.json();
        setResponse(json);
        setIsLoading(false);
      } catch (error) {
        setError(error);
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { response, error, isLoading };
};

export const useFetchLazy = (url: RequestInfo, options?: RequestInit) => {
  const [response, setResponse] = useState<any>(null);
  const [error, setError] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  
  const fetchData = () => {
    setIsLoading(true);
    try {
      fetch(url, options)
        .then(res => {
          res.json()
            .then(json => {
              setResponse(json);
              setIsLoading(false);
            })
        })
    } catch (error) {
      setError(error);
      setIsLoading(false);
    }
  };

  return { response, error, isLoading, fetchData };
};

export default useFetch;
