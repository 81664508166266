import ApplicationModel from "./ApplicationModel";

export default class TalkingPoint extends ApplicationModel {
  constructor(_opts = {}) {
    super(_opts);
    this.state = { ..._opts };
  }

  getId = () => this.state.id;
  getName = (language) => {
    if (this.state.localTitle && this.state.localTitle.length > 0 && language)  {
      const title = this.state.localTitle.find(element => element.getLanguage() === language);
      if (title && title.getName()) {
        return title.getName();
      }
    }
    return this.state.name
  };
  getDescription = () => this.state.description;
  getPrimaryColor = () => this.state.primaryColor;
  getHighlightColor = () => this.state.highlightColor;
  getFontColor = () => this.state.fontColor;
  getFontHighlightColor = () => this.state.fontHighlightColor;
  getHeaderImage = (language) => {
    if (this.state.localTitle && this.state.localTitle.length > 0 && language)  {
      const title = this.state.localTitle.find(element => element.getLanguage() === language);
      if (title && title.getHeader()) {
        return title.getHeader();
      }
    }
    return this.state.headerImage
  };
  getConversation = () => this.state.conversation;
  getOrderIndex = () => this.state.orderIndex;

  static attributes = {
    ...super.attributes,
    id: "id",
    name: "name",
    description: "description",
    primaryColor: "primary_color",
    highlightColor: "highlight_color",
    fontColor: "font_color",
    fontHighlightColor: "font_highlight_color",
    headerImage: "header_image",
    conversation: "conversation",
    orderIndex: "order_index",
    localTitle: "local_title",
  };

  static orderByParams = {
    ...super.orderByParams,
  };

  static filterParams = {
    ...super.filterParams,
  };
}
