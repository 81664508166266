/* eslint-disable react/no-multi-comp */
import React, { useContext, useEffect, useState } from "react";
import { useLocation, matchPath } from "react-router";
import { Link as RouterLink } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import {
  CardHeader,
  Card,
  Divider,
  Grid,
  Typography,
  IconButton,
  Checkbox,
} from "@material-ui/core";
import MessageAdd from "./PublicMessageAdd";
import FrontendRoutes from "../../../../constants/FrontendRoutes";
import useConfigurationService from "../../../../utils/ConfigurationService";
import { DEFAULT_PAGE_INDEX, MAX_PAGE_SIZE } from "../../../../constants/Urls";
import useChatEngine, { useChatPushNotification, useCreateConversation } from "../../../../engines/Chat/ChatEngine";
import { fetchConversations } from "../../../../redux/actions/ConversationsActions";
import ThreadDetails from "./PublicThreadDetails"
import useExhibition from "../../../../engines/GeneralHooks/useExhibition";
import NeumorphicStyle from "../../../../constants/Neumorphic";
import GlobalContext from "../context/GlobalContext";
import { ArrowDownward } from "@material-ui/icons";
import { Trans } from "react-i18next";

const { auth, profiles } = FrontendRoutes;

const appID = "23390f1d35cf8da";
const region = "eu";
// const appSetting = new CometChat.AppSettingsBuilder().subscribePresenceForAllUsers().setRegion(region).build();
// CometChat.init(appID, appSetting).then(
//   () => {
//     console.log("Initialization completed successfully");
//     // You can now call login function.
//   },
//   error => {
//     console.log("Initialization failed with error:", error);
//     // Check the reason for error and take appropriate action.
//   }
// );

const useStyles = makeStyles(theme => ({
  root: {
    width: 350,
    background: theme.palette.background.default,
    borderRadius: NeumorphicStyle.borderRadius,
    boxShadow: NeumorphicStyle.boxShadowLarge(theme.palette.primary.main, theme.palette.secondary.dark),
    position: "relative",
    top: 20,
    height: "calc(100% - 100px)",
    zIndex: 6000,
    padding: "8px"
  },
  chatListItem: {
    width: "100%",
    height: "100%",
    overflowY: "auto"
  },
  chatDetailItem: {
    width: "100%",
    height: "calc(100% - 100px)",
  },
  messageAdd: {
    position: "relative",
    bottom: 70,
    width: "100%",
    padding: 0,
  },
  expansionPanel: {
    width: "100%",
    height: "100%",
    boxShadow: "none",
    borderRadius: NeumorphicStyle.borderRadius,
    background: theme.palette.background.default,
  },
  fadeOut: {
    opacity: 0,
    width: 0,
    height: 0,
    padding: 0,
    margin: 0,
    transition: "width 0.2s 0.2s, height 0.2s 0.2s, opacity 0.2s",
  },
  fadeIn: {
    opacity: 1,
    width: "100%",
    height: "calc(100% - 25px)",
    transition: "width 0.2s, height 0.2s, opacity 0.2s 0.2s",
  },
  addIcon: {
    transition: "transform .2s ease-in-out",
  },
  closeIcon: {
    transition: "transform .2s ease-in-out",
    transform: "rotate(45deg)",
  },
  chatSwitchContainer: {
    width: "auto",
    height: 40,
    marginLeft: "auto",
    marginRight: "auto",
    padding: 2,
    // borderRadius: 20,
    // boxShadow: `-2px 2px 4px ${theme.palette.primary.main}, 2px -2px 4px ${theme.palette.secondary.dark}, inset 1px -1px 2px ${theme.palette.primary.main}, inset -1px 1px 2px ${theme.palette.secondary.dark}`
  },
  switchButton: {
    borderRadius: 2,
    boxShadow: NeumorphicStyle.boxShadowSmall(theme.palette.primary.main, theme.palette.secondary.dark),
    height: 30,
    padding: 4,
    textTransform: "none",
    "&:hover": {
      backgroundColor: theme.palette.background.default,
      borderRadius: 2,
      boxShadow: NeumorphicStyle.boxShadowSmallInset(theme.palette.primary.main, theme.palette.secondary.dark),
      textTransform: "none",
    }
  },
  pressedSwitchButton: {
    borderRadius: 2,
    boxShadow: NeumorphicStyle.boxShadowSmallInset(theme.palette.primary.main, theme.palette.secondary.dark),
    color: theme.palette.secondary.main,
    textTransform: "none",
    "&:hover": {
      backgroundColor: theme.palette.background.default,
      borderRadius: 2,
      boxShadow: NeumorphicStyle.boxShadowSmallInset(theme.palette.primary.main, theme.palette.secondary.dark),
      textTransform: "none",
    }
  },
  chatHeader: {
    padding: 15
  },
  matchmakingButton: {
    backgroundColor: theme.palette.secondary.light,
    marginLeft: "auto",
    color: "white",
    textTransform: "uppercase",
    fontWeight: "bold",
    height: 30,
    width: 144,
    padding: 4
  },
  scrollButton: {
    display: "inline-block",
    width: 30,
    height: 30,
    bottom: 10,
    right: 10
  }
}));

function PublicChatBar({ keynoteConversation, detailView, chatIndex, setChatIndex }) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [detailsOpen, setDetailsOpen] = useState(true);
  const [selectedConversation, setSelectedConversation] = useState();
  const [autoScroll, setAutoScroll] = useState(true);
  const [loadMoreTrigger, setLoadMoreTrigger] = useState(false);
  const [currentPageIndex, setCurrentPageIndex] = useState(DEFAULT_PAGE_INDEX);
  const [newChatUser, setNewChatUser] = useState();
  const conversationsReducer = useSelector(
    (state) => state.conversationsReducer
  );
  const usersReducer = useSelector(state => state.usersReducer)
  const context = useContext(GlobalContext);
  const [moderatorOnly, setModeratorOnly] = useState(false)

  const [
    conversation,
    messages,
    loading,
    incomingMessagesCount,
    totalPages,
    resetIncomingMessagesCount,
    sendMessage,
    sending,
    clearThread,
  ] = useChatEngine(
    selectedConversation ? selectedConversation.getId() : undefined,
    currentPageIndex, 15
  );
  const [chatPush] = useChatPushNotification();
  const [
    newConversationId,
    sendNewChatMessage,
    clearNewConversationId,
  ] = useCreateConversation(newChatUser);
  const [config] = useConfigurationService();
  const [localMessagesCount, setLocalMessagesCount] = useState(0);
  const [exhibition] = useExhibition();

  useEffect(() => {
    if (!detailView) {
      return
    }

    setChatIndex(2);
  }, [detailView])

  useEffect(() => {
    if (!keynoteConversation) {
      return;
    }

    setSelectedConversation(keynoteConversation);
  }, [chatIndex])

  useEffect(() => {
    if (!chatPush) {
      return;
    }

    if (
      (!selectedConversation ||
        selectedConversation.getId() !== chatPush.getConversationId())
      && !open
    ) {
      setLocalMessagesCount(localMessagesCount + 1);
    }

    // Update Conversations Unread Messages Counter
    let incomingMessageConversation = conversationsReducer.conversations.filter(
      (conv) => conv.getId() === chatPush.getConversationId()
    )[0];

    // update only if Conversation is in List
    if (incomingMessageConversation !== undefined) {
      if (
        !selectedConversation ||
        selectedConversation.getId() !== chatPush.getConversationId()
      ) {
        incomingMessageConversation.setUnreadMessagesCount(
          incomingMessageConversation.getUnreadMessagesCount() + 1
        );
      }
    }

    return () => { };
  }, [chatPush]);

  useEffect(() => {
    if (!open) {
      return;
    }

  }, [open]);

  useEffect(() => {
    setLocalMessagesCount(0);
  }, [conversationsReducer.unreadMessagesCount]);

  useEffect(() => {
    if (!loadMoreTrigger) {
      return;
    }
    if (currentPageIndex < totalPages) {
      setAutoScroll(false);
      setCurrentPageIndex(currentPageIndex + 1);
    }
    setLoadMoreTrigger(false);
  }, [loadMoreTrigger]);

  const loadMore = () => {
    setLoadMoreTrigger(true);
  };

  useEffect(() => {
    if (!open) {
      return
    }
    setLocalMessagesCount(0);
  }, [open])

  const newMessage = (v) => {
    sendNewChatMessage(v);
  }

  useEffect(() => {
    if (!newConversationId) {
      return
    }

    setSelectedConversation(undefined);
    setNewChatUser(undefined);
    clearNewConversationId();
  }, [newConversationId])

  useEffect(() => {
    if (chatIndex === 2) {
      setSelectedConversation(keynoteConversation);
    }
  }, [keynoteConversation])

  return (
    <>
      <Grid item className={classes.chatDetailItem}>
        <Card className={classes.expansionPanel}>
          <CardHeader
            style={{ padding: 6 }}
            onClick={() => setDetailsOpen(!detailsOpen)}
            title={
              (<Typography variant="h4">
                {/* {
                    selectedConversation.getOtherUser() &&
                    selectedConversation.getOtherUser().getFullName()
                  } */}
              </Typography>)
            }
            action={<div></div>}
          />
          <Divider />
          <div className={classes.fadeIn}>
            <ThreadDetails
              loading={loading}
              messages={messages}
              autoScroll={autoScroll}
              loadMore={() => loadMore()}
            />
          </div>
        </Card>
      </Grid>
      <div className={classes.messageAdd}>
        <Divider />
        <div style={{textJustify: "center", paddingTop: "4px"}}>
          <input
            type="checkbox"
            style={{paddingBottom: 0, marginRight: "4px", height: "10px", width: "10px" }}
            checked={moderatorOnly}
            onChange={(e) => {
              setModeratorOnly(e.target.checked);
            }}
          />
          <Trans>frontend.moderator_only_chat</Trans>
        </div>
        <MessageAdd
          sendMessage={(value) => sendMessage(value)}
          conversation={conversation}
          disabled={            
            usersReducer.user &&
            usersReducer.user.getRole() &&
            !usersReducer.user.getRole().getPreEventVisible(exhibition)
          }
          globalChat={true}
          moderatorOnly={moderatorOnly}          
          setModeratorOnly={setModeratorOnly}          
        />
        {/* <IconButton className={classes.scrollButton}>
          <ArrowDownward />
        </IconButton> */}
      </div>
    </>
  );
}

PublicChatBar.propTypes = {
  className: PropTypes.string,
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default PublicChatBar;