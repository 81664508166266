import {
  CREATE_PUSH_NOTIFICATION_REQUEST,
  CREATE_PUSH_NOTIFICATION_SUCCESS,
  CREATE_PUSH_NOTIFICATION_FAILURE
} from "../actions/types";

import ApplicationError from "../../utils/ApplicationError";

const initialState = {
  error: ApplicationError.buildDefault(),
  isFetching: false
};

const pushNotificationsReducer = (state = initialState, action) => {
  switch (action.type) {
    // Create
    case CREATE_PUSH_NOTIFICATION_REQUEST:
      return { ...state, isFetching: true };
    case CREATE_PUSH_NOTIFICATION_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: ApplicationError.buildDefault(),
      };
    case CREATE_PUSH_NOTIFICATION_FAILURE:
      return { ...state, isFetching: false, error: action.payload };

   
    default:
      return state;
  }
};

export default pushNotificationsReducer;
