import { gql } from "@apollo/client";

const postQueries = {
  feedQuery: gql`
    query (
      $userId: Int
      $bmUserId: Int
      $tags: [String]
      $limit: Int
      $start: Int
      $exhibitionId: ID!
      $minPrice: Int
      $maxPrice: Int
      $show: Boolean
    ) {
      posts(
        sort: "visible_at:desc"
        limit: $limit
        start: $start
        where: {
          _or: [
            {
              exhibitor: {
                exhibitor_min_price_gte: $minPrice
                exhibitor_min_price_lte: $maxPrice
              }
            },
            {
              exhibitor: {
                exhibitor_max_price_gte: $minPrice
                exhibitor_max_price_lte: $maxPrice
              }
            },
          ]
          tags: { name_in: $tags }
          exhibition: { id: $exhibitionId }
          marks: { user: { id: $bmUserId } }
          scheduled: true,
          show: $show,
        }
      ) {
        id
        title
        created_at
        updated_at
        visible_at
        text
        exhibitor {
          id
          name
          profile_thumb {
            name
            url
            formats
          }
          profile_picture {
            name
            url
            formats
          }
          marks(where: { user: { id: $userId } }) {
            user {
              id
            }
            bookmark
            salesmark
          }
        }
        pictures {
          width
          height
          url
          formats
          name
        }
        post_media {
          name
          url
          formats
        }
        documents {
          name
          url
        }
        post_likes {
          id
          user {
            id
          }
        }
        comments(sort: "created_at:desc", limit: 1) {
          id
          text
          comment_likes {
            id
            user {
              id
            }
          }
          created_at
          updated_at
          post {
            id
          }
          user {
            id
            first_name
            last_name
            profile_picture {
              name
              url
              formats
            }
          }
        }
        tags {
          name
        }
        likes_count
        comment_count
        marks(where: { user: { id: $userId } }) {
          user {
            id
          }
          salesmark
          bookmark
        }
        local_description {
          text
          language
          video {
            name
            url
            formats
            previewUrl
          }
          title
        }
      }
      postsConnection(where: { exhibition: { id: $exhibitionId } }) {
        aggregate {
          count
        }
      }
    }
  `,
  feedQueryExhibitor: gql`
    query (
      $userId: Int
      $bmUserId: Int
      $tags: [String]
      $limit: Int
      $start: Int
      $exhibitionId: ID!
      $minPrice: Int
      $maxPrice: Int
      $exhibitors: [Int]
    ) {
      posts(
        sort: "visible_at:desc"
        limit: $limit
        start: $start
        where: {
          _or: [
            {
              exhibitor: {
                exhibitor_min_price_gte: $minPrice
                exhibitor_min_price_lte: $maxPrice
              }
            },
            {
              exhibitor: {
                exhibitor_max_price_gte: $minPrice
                exhibitor_max_price_lte: $maxPrice
              }
            },
          ]
          exhibitor: {
            id_in: $exhibitors
          }
          tags: { name_in: $tags }
          exhibition: { id: $exhibitionId }
          marks: { user: { id: $bmUserId } }
          scheduled: true,
        }
      ) {
        id
        title
        created_at
        updated_at
        visible_at
        text
        exhibitor {
          id
          name
          profile_thumb {
            name
            url
            formats
          }
          profile_picture {
            name
            url
            formats
          }
          marks(where: { user: { id: $userId } }) {
            user {
              id
            }
            bookmark
            salesmark
          }
        }
        pictures {
          width
          height
          url
          formats
          name
        }
        post_media {
          name
          url
          formats
        }
        documents {
          name
          url
        }
        post_likes {
          id
          user {
            id
          }
        }
        comments(sort: "created_at:desc", limit: 1) {
          id
          text
          comment_likes {
            id
            user {
              id
            }
          }
          created_at
          updated_at
          post {
            id
          }
          user {
            id
            first_name
            last_name
            profile_picture {
              name
              url
              formats
            }
          }
        }
        tags {
          name
        }
        likes_count
        comment_count
        marks(where: { user: { id: $userId } }) {
          user {
            id
          }
          salesmark
          bookmark
        }
        local_description {
          text
          language
          video {
            name
            url
            formats
            previewUrl
          }
          title
        }
      }
      postsConnection(where: { exhibition: { id: $exhibitionId } }) {
        aggregate {
          count
        }
      }
    }
  `,
  bookmarkedPosts: gql`
    query ($userId: Int!) {
      bookmarks(where: { user: { id: $userId }, sharable_type: "Post" }) {
        id
        created_at
        bookmarkable
        sharable_id
        updated_at
        sharable_type
        name
      }
    }
  `,
};

export default postQueries;
