/* eslint-disable react/no-multi-comp */
import React, { useEffect, useState } from "react";
import { useLocation, matchPath } from "react-router";
import { Link as RouterLink } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import {
  Drawer,
  Divider,
  List,
  Hidden,
  colors,
  Grid,
  Typography,
  IconButton
} from "@material-ui/core";
import NavItem from "../../components/NavItem";
import navConfig from "./navConfig";
import MyStore from "../../../utils/MyStore";
import { useHistory } from "react-router";
import User from "../../../models/User";
import FrontendRoutes from "../../../constants/FrontendRoutes";
import useConfigurationService from "../../../utils/ConfigurationService";
import useExhibition from "../../../engines/GeneralHooks/useExhibition";
import { VideoLabelOutlined, Close } from "@material-ui/icons";
import Exhibition from "../../../models/Exhibition";
import VideoBox from "./components/VideoBox";
import NeumorphicStyle from "../../../constants/Neumorphic";

const { auth, profiles } = FrontendRoutes;

const useStyles = makeStyles(theme => ({
  root: {
    background: theme.palette.background.default,
    borderRadius: NeumorphicStyle.borderRadius,
    boxShadow: `-5px 5px 15px ${theme.palette.primary.main}, 5px -5px 15px ${theme.palette.secondary.dark}, inset 2px -2px 4px ${theme.palette.primary.main}, inset -2px 2px 4px ${theme.palette.secondary.dark}`,
    width: 250,
    marginLeft: 16,
    marginRight:8,
    //position: "fixed",
    marginTop: 16,
    marginBottom: 235,
    zIndex: 999,
    overflowY: "scroll",
  },
  desktopDrawer: {
    backgroundColor: theme.palette.background.default,
    border: 0
  },
  videoBox: {
    height: 169,
    width: 300,
    left: 16,
    background: theme.palette.background.default,
    boxShadow: `-5px 5px 10px ${theme.palette.primary.main}, 5px -5px 10px ${theme.palette.secondary.dark}, inset 2px -2px 4px ${theme.palette.primary.main}, inset -2px 2px 4px ${theme.palette.secondary.dark}`,
    borderRadius: NeumorphicStyle.borderRadius,
    bottom: 32,
    position: "fixed",
    zIndex: 100000,
    transition: "width 0.2s, height 0.2s, opacity 0.2s 0.2s",
  },
  videoBoxCinema: {
    height: 507,
    background: theme.palette.background.default,
    boxShadow: `-5px 5px 10px ${theme.palette.primary.main}, 5px -5px 10px ${theme.palette.secondary.dark}, inset 2px -2px 4px ${theme.palette.primary.main}, inset -2px 2px 4px ${theme.palette.secondary.dark}`,
    borderRadius: NeumorphicStyle.borderRadius,
    width: 900,
    left: 16,
    bottom: 32,
    position: "fixed",
    zIndex: 100000,
    transition: "width 0.2s, height 0.2s, opacity 0.2s 0.2s",
  },
  videoBoxFull: {
    left: 16,
    right: 382,
    top: 96,
    background: theme.palette.background.default,
    boxShadow: `-5px 5px 10px ${theme.palette.primary.main}, 5px -5px 10px ${theme.palette.secondary.dark}, inset 2px -2px 4px ${theme.palette.primary.main}, inset -2px 2px 4px ${theme.palette.secondary.dark}`,
    borderRadius: NeumorphicStyle.borderRadius,
    bottom: 32,
    position: "fixed",
    zIndex: 100000,
    transition: "width 0.2s, height 0.2s, opacity 0.2s 0.2s",
  },
  navigation: {
    overflow: "auto",
    padding: theme.spacing(4, 4, 4, 4),
    flexGrow: 1
  },
  profile: {
    padding: theme.spacing(2),
    display: "flex",
    alignItems: "center"
  },
  badge: {
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`
  },
  badgeDot: {
    height: 9,
    minWidth: 9
  },
  onlineBadge: {
    backgroundColor: colors.green[600]
  },
  awayBadge: {
    backgroundColor: colors.orange[600]
  },
  busyBadge: {
    backgroundColor: colors.red[600]
  },
  offlineBadge: {
    backgroundColor: colors.grey[300]
  },
  avatar: {
    cursor: "pointer",
    width: 40,
    height: 40
  },
  details: {
    marginLeft: theme.spacing(2)
  },
  moreButton: {
    marginLeft: "auto",
    color: theme.palette.primary.main
  }
}));

function renderNavItems({
  // eslint-disable-next-line react/prop-types
  items,
  subheader,
  key,
  config,
  ...rest
}) {
  return (
    <List key={key}>
      <RouterLink to={FrontendRoutes.root()} style={{ marginBottom: 20 }}>
        <img
          src={config.logo.url}
          alt="logo" style={{ height: '150px', width: '100%', marginLeft: 'auto', marginRight: 'auto', objectFit: "scale-down" }} />
      </RouterLink>
      <div style={{ marginBottom: 30 }} />
      {items.reduce(
        // eslint-disable-next-line no-use-before-define
        (acc, item) => reduceChildRoutes({ acc, item, config, ...rest }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, config, depth = 0 }) {
  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        key={item.href}
        label={item.label}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        key={item.href}
        label={item.label}
        title={(config.navbar[`${item.title}`])}
      />
    );
  }

  if (item.divider) {
    acc.push(<Divider style={{ marginTop: "12px", marginBottom: "12px", marginLeft: "8px", marginRight: "16px" }} />)
  }

  return acc;
}

function NavBar({ openMobile, onMobileClose, setParentVideoMode, setKeynoteConversation, className, ...rest }) {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const [config] = useConfigurationService()
  const [videoMode, setVideoMode] = useState(0);
  const [exhibition] = useExhibition();

  const users = useSelector(state => state.usersReducer);

  const [user, setUser] = useState(new User());

  useEffect(() => {
    if (!users.user) {
      return;
    }
    setUser(users.user);
  }, [users.user]);

  useEffect(() => {
    if (users.error.isUnauthorized()) {
      MyStore.removeAllKeys();
      history.push(auth.login());
    }
  }, [users.isFetching]);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }

    // eslint-disable-next-line
  }, [location.pathname]);

  const content = (config) => (
    <div {...rest} className={clsx(classes.root, className)}>
      <nav className={classes.navigation}>
        {navConfig.map(list =>
          renderNavItems({
            config: config,
            items: list.items,
            subheader: list.subheader,
            pathname: location.pathname,
            key: list.subheader
          })
        )}
      </nav>
      {/* <Divider className={classes.divider} /> */}
      {/* <Grid container style={{width:"100%", paddingLeft:"8px", paddingRight:"8px", paddingBottom:"8px",
            maxHeight: "20%"}} direction="column" justify="center"> */}
      {/* <Grid item container style={{
        marginBottom: "4px", width: "50%", marginLeft: "auto", marginRight: "auto",
        WebkitMarginStart: "auto",
        WebkitMarginEnd: "auto",
        maxHeight: "40px"
      }} >
        <Typography style={{ color: "white", marginLeft: "auto", marginRight: "auto", WebkitMarginEnd: "auto", }} variant="caption">powered by </Typography>
      </Grid>
      <Grid
        item
        container
        style={{
          width: "50%",
          marginLeft: "auto",
          marginRight: "auto",
          paddingBottom: "16px",
          WebkitMarginStart: "auto",
          WebkitMarginEnd: "auto",
          maxHeight: "90px"
        }}> */}
        {/* <img src={require("../../../assets/images/leadevents-logo-white.svg")} alt="logo" style={{ width: "50%", marginLeft: "auto", marginRight: "auto" }} /> */}
      {/* </Grid> */}
      {/* </Grid> */}
    </div>
  );

  return !config ? <div /> : (
    <div>
      {exhibition && exhibition.getExhibitionType() === Exhibition.EXHIBITION_TYPES.CLASSIC && (
        <VideoBox setParentVideoMode={setParentVideoMode} setKeynoteConversation={(conversation) => setKeynoteConversation(conversation)}/>
      )}
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{
            paper: classes.mobileDrawer
          }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content(config)}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          id="drawer"
          classes={{
            paper: classes.desktopDrawer
          }}
          open
          variant="persistent"
        >
          {content(config)}
        </Drawer>
      </Hidden>
      <div style={{ position: "fixed", bottom: 8, left: 16 }}>
        <Typography color={"textPrimary"}>
          powered by {" "}
          {(<a href={"https://leadevents.io"} target="_blank">leadevents.io</a>)}
        </Typography>
      </div>
    </div>
  );
}

NavBar.propTypes = {
  className: PropTypes.string,
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
