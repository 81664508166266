import {
  FETCHING_EXHIBITION_POSTS_REQUEST,
  FETCHING_EXHIBITION_POSTS_SUCCESS,
  FETCHING_EXHIBITION_POSTS_FAILURE,
  
  FETCHING_EXHIBITION_POST_REQUEST,
  FETCHING_EXHIBITION_POST_SUCCESS,
  FETCHING_EXHIBITION_POST_FAILURE,

  CREATE_EXHIBITION_POST_FAILURE,
  CREATE_EXHIBITION_POST_SUCCESS,
  CREATE_EXHIBITION_POST_REQUEST,

  UPDATE_EXHIBITION_POST_FAILURE,
  UPDATE_EXHIBITION_POST_SUCCESS,
  UPDATE_EXHIBITION_POST_REQUEST,

  DELETE_EXHIBITION_POST_FAILURE,
  DELETE_EXHIBITION_POST_SUCCESS,
  DELETE_EXHIBITION_POST_REQUEST,
  DELETE_ALL_POSTS_LOCAL,
} from '../actions/types';
import ResponseService from '../../utils/ResponseService';
import ApplicationError from '../../utils/ApplicationError';

const initialState = {
  posts: [],
  post: undefined,
  error: ApplicationError.buildDefault(),
  isFetching: false,
  ...ResponseService.defaultPaginationMetaData()
};

const postsReducer = (state = initialState, action ) => {
  switch(action.type) {
    case FETCHING_EXHIBITION_POSTS_REQUEST:
      return {...state, isFetching: true, posts: [] }
    case FETCHING_EXHIBITION_POSTS_SUCCESS:
      return {...state, isFetching: false, 
        posts: action.payload.objects, 
        error: ApplicationError.buildDefault(),
        ...action.payload.paginationData }
        
    case FETCHING_EXHIBITION_POSTS_FAILURE:
      return {...state, isFetching: false, error: action.payload }
    
    case FETCHING_EXHIBITION_POST_REQUEST:
      return {...state, isFetching: true }
    case FETCHING_EXHIBITION_POST_SUCCESS: 
      return {...state, isFetching: false, post: action.payload, error: ApplicationError.buildDefault() }
    case FETCHING_EXHIBITION_POST_FAILURE:
      return {...state, isFetching: false, error: action.payload }  


    case  CREATE_EXHIBITION_POST_REQUEST:
      return {...state, isFetching: true }
    case  CREATE_EXHIBITION_POST_SUCCESS:
      return {...state, isFetching: false, post: action.payload, error: ApplicationError.buildDefault() }
    case  CREATE_EXHIBITION_POST_FAILURE:
      return {...state, isFetching: false, error: action.payload }

    case  UPDATE_EXHIBITION_POST_REQUEST:
      return {...state, isFetching: true }
    case  UPDATE_EXHIBITION_POST_SUCCESS:
      return {...state, isFetching: false, post: action.payload, error: ApplicationError.buildDefault() }
    case  UPDATE_EXHIBITION_POST_FAILURE:
      return {...state, isFetching: false, error: action.payload }

    case  DELETE_EXHIBITION_POST_REQUEST:
      return {...state, isFetching: true }
    case  DELETE_EXHIBITION_POST_SUCCESS:
      return {...state, isFetching: false, error: ApplicationError.buildDefault() }
    case DELETE_ALL_POSTS_LOCAL:
      return { ...state, isFetching: false, posts: action.payload }
    case  DELETE_EXHIBITION_POST_FAILURE:
      return {...state, isFetching: false, error: action.payload }

    case DELETE_ALL_POSTS_LOCAL:
      return { ...state, isFetching: false, posts: [], post: undefined }  

    default: 
      return state
  }
}

export default postsReducer;