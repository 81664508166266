import {
  FETCHING_EXHIBITION_KEYNOTES_REQUEST,
  FETCHING_EXHIBITION_KEYNOTES_SUCCESS,
  FETCHING_EXHIBITION_KEYNOTES_FAILURE,

  FETCHING_EXHIBITION_KEYNOTE_REQUEST,
  FETCHING_EXHIBITION_KEYNOTE_SUCCESS,
  FETCHING_EXHIBITION_KEYNOTE_FAILURE,

  CREATE_EXHIBITION_KEYNOTE_REQUEST,
  CREATE_EXHIBITION_KEYNOTE_SUCCESS,
  CREATE_EXHIBITION_KEYNOTE_FAILURE,

  UPDATE_EXHIBITION_KEYNOTE_REQUEST,
  UPDATE_EXHIBITION_KEYNOTE_SUCCESS,
  UPDATE_EXHIBITION_KEYNOTE_FAILURE,

  DELETE_EXHIBITION_KEYNOTE_REQUEST,
  DELETE_EXHIBITION_KEYNOTE_SUCCESS,
  DELETE_EXHIBITION_KEYNOTE_FAILURE,

  CLEAR_EXHIBITION_KEYNOTE

} from './types';
import Urls, { DEFAULT_OPTIONAL_PARAMETERS } from '../../constants/Urls';
import { BEARER } from '../../utils/MyStore';
import ApplicationError from '../../utils/ApplicationError';
import ResponseService from '../../utils/ResponseService';
import KeynoteMapper from '../../mapper/KeynoteMapper';
import ErrorLogger from '../../utils/ErrorLogger';

export const fetchingKeynotesRequest = () => ({
  type: FETCHING_EXHIBITION_KEYNOTES_REQUEST
})

export const fetchingKeynotesSuccess = (json) => ({
  type: FETCHING_EXHIBITION_KEYNOTES_SUCCESS,
  payload: json
})
export const fetchingKeynotesFailure = (error) => ({
  type: FETCHING_EXHIBITION_KEYNOTES_FAILURE,
  payload: error
})

export const fetchingKeynoteRequest = () => ({
  type: FETCHING_EXHIBITION_KEYNOTE_REQUEST
})

export const fetchingKeynoteSuccess = (keynote) => ({
  type: FETCHING_EXHIBITION_KEYNOTE_SUCCESS,
  payload: keynote
})
export const fetchingKeynoteFailure = (error) => ({
  type: FETCHING_EXHIBITION_KEYNOTE_FAILURE,
  payload: error
})

export const createKeynoteRequest = () => ({
  type: CREATE_EXHIBITION_KEYNOTE_REQUEST
})

export const createKeynoteSuccess = (keynote) => ({
  type: CREATE_EXHIBITION_KEYNOTE_SUCCESS,
  payload: keynote
})

export const createKeynoteFailure = (error) => ({
  type: CREATE_EXHIBITION_KEYNOTE_FAILURE,
  payload: error
})

export const updateKeynoteRequest = () => ({
  type: UPDATE_EXHIBITION_KEYNOTE_REQUEST
})

export const updateKeynoteSuccess = (keynote) => ({
  type: UPDATE_EXHIBITION_KEYNOTE_SUCCESS,
  payload: keynote
})

export const updateKeynoteFailure = (error) => ({
  type: UPDATE_EXHIBITION_KEYNOTE_FAILURE,
  payload: error
})

export const deleteKeynoteRequest = () => ({
  type: DELETE_EXHIBITION_KEYNOTE_REQUEST
})

export const deleteKeynoteSuccess = () => ({
  type: DELETE_EXHIBITION_KEYNOTE_SUCCESS
})

export const deleteKeynoteFailure = (error) => ({
  type: DELETE_EXHIBITION_KEYNOTE_FAILURE,
  payload: error
})

export const clearKeynotes = () => ({
  type: CLEAR_EXHIBITION_KEYNOTE
})

export const fetchKeynotes = (exhibitionId, _opts = DEFAULT_OPTIONAL_PARAMETERS) => {
  return async dispatch => {
    dispatch(fetchingKeynotesRequest());
    
    try {
      const route   = Urls.exhibitions.keynotes.all
      const bearer  = localStorage.getItem(BEARER)
      const headers = Urls.header.secure(bearer)
      const url     = Urls.build(route.url(exhibitionId, _opts.pageIndex, _opts.pageSize), _opts.filters)

      let response = await fetch(url, {
        method: route.method,
        headers: headers
      });

      let responseService = new ResponseService(KeynoteMapper)

      let action = await responseService.call(response, 
        (value) => fetchingKeynotesSuccess(value),
        (error) => fetchingKeynotesFailure(error))

      dispatch(action)
    } catch(error) {
      ErrorLogger.call(this, error)
      dispatch(fetchingKeynotesFailure(ApplicationError.buildApplicationError(error)))
    }
  };
};

export const fetchKeynote = (exhibitionId, id) => {
  return async dispatch => {
    dispatch(fetchingKeynoteRequest());

    try {
      const route   = Urls.exhibitions.keynotes.show;
      const bearer  = localStorage.getItem(BEARER);
      const headers = Urls.header.secure(bearer);
      const url     = route.url(exhibitionId, id)

      let response = await fetch(url, {
        method: route.method,
        headers: headers
      })

      let responseService = new ResponseService(KeynoteMapper)

      let action = await responseService.call(response, 
        (value) => fetchingKeynoteSuccess(value),
        (error) => fetchingKeynoteFailure(error))

      dispatch(action)

    } catch(error) {
      ErrorLogger.call(this, error)
      dispatch(fetchingKeynoteFailure(error))
    }
  }
}

export const createKeynote = (keynote) => {
  return async dispatch => {
    dispatch(createKeynoteRequest());
    
    try {
      const route   = Urls.exhibitions.keynotes.create
      const bearer  = localStorage.getItem(BEARER)
      const headers = Urls.header.secure(bearer)
      const url     = route.url(keynote.getExhibitionId())

      let response = await fetch(url, {
        method: route.method,
        headers: headers,
        body: KeynoteMapper.toJsonString(keynote)
      });

      let responseService = new ResponseService(KeynoteMapper)

      let action = await responseService.call(response, 
        (value) => createKeynoteSuccess(value),
        (error) => createKeynoteFailure(error))

      dispatch(action)
    } catch(error) {
      ErrorLogger.call(this, error)
      dispatch(createKeynoteFailure(ApplicationError.buildApplicationError(error)))
    }
  };
};

export const updateKeynote = (keynote) => {
  return async dispatch => {
    dispatch(updateKeynoteRequest());
    
    try {
      const route   = Urls.exhibitions.keynotes.update
      const bearer  = localStorage.getItem(BEARER)
      const headers = Urls.header.secure(bearer)
      const url     = route.url(keynote.getExhibitionId(), keynote.getId())

      let response = await fetch(url, {
        method: route.method,
        headers: headers,
        body: KeynoteMapper.toJsonString(keynote)
      });

      let responseService = new ResponseService(KeynoteMapper)

      let action = await responseService.call(response, 
        (value) => updateKeynoteSuccess(value),
        (error) => updateKeynoteFailure(error))

      dispatch(action)
    } catch(error) {
      ErrorLogger.call(this, error)
      dispatch(updateKeynoteFailure(ApplicationError.buildApplicationError(error)))
    }
  };
};

export const deleteKeynote = (keynote) => {
  return async dispatch => {
    dispatch(deleteKeynoteRequest());
    
    try {
      const route   = Urls.exhibitions.keynotes.delete
      const bearer  = localStorage.getItem(BEARER)
      const headers = Urls.header.secure(bearer)
      const url     = route.url(keynote.getExhibitionId(), keynote.getId())

      let response = await fetch(url, {
        method: route.method,
        headers: headers
      });

      let responseService = new ResponseService(KeynoteMapper)

      let action = await responseService.call(response, 
        (value) => deleteKeynoteSuccess(value),
        (error) => deleteKeynoteFailure(error))

      dispatch(action)
    } catch(error) {
      ErrorLogger.call(this, error)
      dispatch(deleteKeynoteFailure(ApplicationError.buildApplicationError(error)))
    }
  };
};