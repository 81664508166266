import {
  FETCHING_BOOKMARKS_REQUEST,
  FETCHING_BOOKMARKS_SUCCESS,
  FETCHING_BOOKMARKS_FAILURE,
  CREATE_BOOKMARK_REQUEST,
  CREATE_BOOKMARK_SUCCESS,
  CREATE_BOOKMARK_FAILURE,
  DELETE_BOOKMARK_REQUEST,
  DELETE_BOOKMARK_SUCCESS,
  DELETE_BOOKMARK_FAILURE,
} from "./types";
import Urls, { DEFAULT_OPTIONAL_PARAMETERS } from "../../constants/Urls";
import { BEARER } from "../../utils/MyStore";

import BookmarkMapper from "../../mapper/BookmarkMapper";
import ResponseService from "../../utils/ResponseService";
import ApplicationError from "../../utils/ApplicationError";
import ErrorLogger from "../../utils/ErrorLogger";

export const fetchingBookmarksRequest = () => ({
  type: FETCHING_BOOKMARKS_REQUEST,
});

export const fetchingBookmarksSuccess = (bookmark) => ({
  type: FETCHING_BOOKMARKS_SUCCESS,
  payload: bookmark,
});

export const fetchingBookmarksFailure = (error) => ({
  type: FETCHING_BOOKMARKS_FAILURE,
  payload: error,
});

export const createBookmarkRequest = () => ({
  type: CREATE_BOOKMARK_REQUEST,
});

export const createBookmarkSuccess = (bookmark) => ({
  type: CREATE_BOOKMARK_SUCCESS,
  payload: bookmark,
});

export const createBookmarkFailure = (error) => ({
  type: CREATE_BOOKMARK_FAILURE,
  payload: error,
});

export const deleteBookmarkRequest = () => ({
  type: DELETE_BOOKMARK_REQUEST,
});

export const deleteBookmarkSuccess = () => ({
  type: DELETE_BOOKMARK_SUCCESS,
});

export const deleteBookmarkFailure = (error) => ({
  type: DELETE_BOOKMARK_FAILURE,
  payload: error,
});

export const fetchBookmarks = (exhibitionId, _opts = DEFAULT_OPTIONAL_PARAMETERS) => {
  return async dispatch => {
    dispatch(fetchingBookmarksRequest());
    
    try {
      const route   = Urls.exhibitions.bookmarks.all
      const bearer  = localStorage.getItem(BEARER);
      const headers = Urls.header.secure(bearer)
      const url     = Urls.build(route.url(exhibitionId, _opts.pageIndex, _opts.pageSize), _opts.filters)

      let response = await fetch(url, {
        method: route.method,
        headers: headers,
      });

      let responseService = new ResponseService(BookmarkMapper)

      let action = await responseService.call(response, 
        (value) => fetchingBookmarksSuccess(value),
        (error) => fetchingBookmarksFailure(error))

      dispatch(action)  
    } catch(error) {
      ErrorLogger.call(this, error)
      dispatch(fetchingBookmarksFailure(ApplicationError.buildApplicationError(error)))
    }
  };
};

export const createBookmark = (bookmark) => {
  return async (dispatch) => {
    dispatch(createBookmarkRequest());

    try {
      const route = Urls.exhibitions.bookmarks.create;
      const bearer = localStorage.getItem(BEARER);
      const headers = Urls.header.secure(bearer);
      const url = Urls.build(route.url());

      let response = await fetch(url, {
        method: route.method,
        headers: headers,
        body: await BookmarkMapper.toJsonString(bookmark),
      });

      let responseService = new ResponseService(BookmarkMapper);

      let action = await responseService.call(
        response,
        (value) => createBookmarkSuccess(value),
        (error) => createBookmarkFailure(error)
      );

      dispatch(action);
    } catch (error) {
      ErrorLogger.call(this, error)
      dispatch(
        createBookmarkFailure(ApplicationError.buildApplicationError(error))
      );
    }
  };
};

export const deleteBookmark = (bookmark) => {
  return async (dispatch) => {
    dispatch(deleteBookmarkRequest());

    try {
      const route = Urls.exhibitions.bookmarks.delete;
      const bearer = localStorage.getItem(BEARER);
      const headers = Urls.header.secure(bearer);
      const url = Urls.build(route.url(undefined, bookmark.getId()));

      let response = await fetch(url, {
        method: route.method,
        headers: headers,
      });

      let responseService = new ResponseService(BookmarkMapper);

      let action = await responseService.call(
        response,
        (value) => deleteBookmarkSuccess(value),
        (error) => deleteBookmarkFailure(error)
      );

      dispatch(action);
    } catch (error) {
      ErrorLogger.call(this, error)
      dispatch(
        deleteBookmarkFailure(ApplicationError.buildApplicationError(error))
      );
    }
  };
};
