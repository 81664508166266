/* eslint-disable react/no-multi-comp */
import React, { useContext, useEffect, useState } from "react";
import ContentLoader from "react-content-loader";
import { Client as ConversationsClient } from "@twilio/conversations";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import {
  CardHeader,
  Card,
  IconButton,
  Divider,
  Grid,
  Typography,
  Badge,
  Button,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import VideoCallIcon from "@material-ui/icons/VideoCall";
import VideoModal from "../../../views/ChatView/ThreadDetails/VideoModal";
import useConfigurationService from "../../../../utils/ConfigurationService";
import {
  getTokenConversations,
  createTwilioIdentity,
} from "../../../../redux/actions/ConversationsActions";
import { fetchVisitor } from "../../../../redux/actions/VisitorsActions";
import ThreadList from "../../../components/GlobalChat/ThreadList";
import VisitorsList from "../../../components/GlobalChat/VisitorsList";
import MyStore from "../../../../utils/MyStore";
import Exhibition from "../../../../models/Exhibition";
import ChatBubbleOutlineIcon from "@material-ui/icons/ChatBubbleOutline";
import QuestionAnswerOutlinedIcon from "@material-ui/icons/QuestionAnswerOutlined";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import NeumorphicStyle from "../../../../constants/Neumorphic";
import GlobalContext from "../context/GlobalContext";
import ExhibitorsUsers from "../../../components/ExhibitorsUsers";
import { useExhibitorsUsers } from "../../../../engines/GeneralHooks/useExhibitors";
import { Trans } from "react-i18next";
import { MODAL_TYPE } from "../components/GlobalModal";
import { useGetOnlineVisitorsQL } from "../../../../engines/GraphQL/hooks/useVisitorsQL";

import Conversation from "./Conversation";
import PublicChatBar from "../PublicChat/PublicChatBar";
import BannerBar from "../BannerBar";
import useTracking, {
  EVENT_TRACKING_TYPES,
} from "../../../engines/tracking/useTracking";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHandshake } from "@fortawesome/free-solid-svg-icons";

const appID = "23390f1d35cf8da";
const region = "eu";
// const appSetting = new CometChat.AppSettingsBuilder().subscribePresenceForAllUsers().setRegion(region).build();
// CometChat.init(appID, appSetting).then(
//   () => {
//     console.log("Initialization completed successfully");
//     // You can now call login function.
//   },
//   error => {
//     console.log("Initialization failed with error:", error);
//     // Check the reason for error and take appropriate action.
//   }
// );

const useStyles = makeStyles((theme) => ({
  root: {
    width: 350,
    background: theme.palette.background.default,
    position: "relative",
    top: 20,
    height: "100%",
    zIndex: 6000,
    transition: "width 0.2s 0.2s, opacity 0.2s",
  },
  closedChat: {
    position: "absolute",
    top: 250,
    right: 0,
    background: theme.palette.background.default,
    height: 50,
    width: 50,
    borderRadius: "12px 0px 0px 12px",
    boxShadow: NeumorphicStyle.boxShadowSmall(theme.palette.primary.main, theme.palette.secondary.dark),
    alignItems: "center",
    justifyItems: "center"
  },
  closedButtonIcon: {
    padding: 0,
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: 8,
    marginBottom: "auto"
  },
  closedSpeedDating: {
    background: theme.palette.secondary.light,
    color: "white",
    height: 50,
    width: 50,
    borderRadius: "12px 0px 0px 0px",
  },
  closedChatButton: {
    background: theme.palette.secondary.light,
    color: "white",
    height: 50,
    width: 50,
    borderRadius: "0px 0px 0px 12px",
  },
  rootClosed: {
    width: 0,
    height: 0,
    background: theme.palette.background.default,
    position: "relative",
    top: 20,
    zIndex: 6000,
    transition: "width 0.2s 0.2s, opacity 0.2s",
  },
  rootChat: {
    background: theme.palette.background.default,
    borderRadius: NeumorphicStyle.borderRadius,
    boxShadow: NeumorphicStyle.boxShadowSmall(theme.palette.primary.main, theme.palette.secondary.dark),
    position: "relative",
    padding: "8px",
  },
  chatListItem: {
    width: "100%",
    height: "calc(100% - 100px)",
    overflowY: "auto",
  },
  chatDetailItem: {
    width: "100%",
    height: "calc(100% - 100px)",
  },
  messageAdd: {
    position: "relative",
    bottom: 160,
    width: "100%",
  },
  expansionPanel: {
    width: "100%",
    height: "100%",
    boxShadow: "none",
    borderRadius: NeumorphicStyle.borderRadius,
    background: theme.palette.background.default,
  },
  fadeOut: {
    opacity: 0,
    width: 0,
    height: 0,
    padding: 0,
    margin: 0,
    transition: "width 0.2s 0.2s, height 0.2s 0.2s, opacity 0.2s",
  },
  fadeIn: {
    opacity: 1,
    width: "100%",
    height: "calc(100% - 65px)",
    transition: "width 0.2s, height 0.2s, opacity 0.2s 0.2s",
  },
  addIcon: {
    transition: "transform .2s ease-in-out",
  },
  closeIcon: {
    transition: "transform .2s ease-in-out",
    transform: "rotate(45deg)",
  },
  chatSwitchContainer: {
    width: "auto",
    height: 40,
    marginLeft: "auto",
    marginRight: "auto",
    padding: 2,
    // borderRadius: 20,
    // boxShadow: `-2px 2px 4px ${theme.palette.primary.main}, 2px -2px 4px ${theme.palette.secondary.dark}, inset 1px -1px 2px ${theme.palette.primary.main}, inset -1px 1px 2px ${theme.palette.secondary.dark}`
  },
  switchButton: {
    borderRadius: 2,
    boxShadow: NeumorphicStyle.boxShadowSmall(
      theme.palette.primary.main,
      theme.palette.secondary.dark
    ),
    height: 30,
    padding: 4,
    textTransform: "none",
    "&:hover": {
      backgroundColor: theme.palette.background.default,
      borderRadius: 2,
      boxShadow: NeumorphicStyle.boxShadowSmallInset(
        theme.palette.primary.main,
        theme.palette.secondary.dark
      ),
      textTransform: "none",
    },
  },
  pressedSwitchButton: {
    borderRadius: 2,
    boxShadow: NeumorphicStyle.boxShadowSmallInset(
      theme.palette.primary.main,
      theme.palette.secondary.dark
    ),
    color: theme.palette.secondary.main,
    textTransform: "none",
    "&:hover": {
      backgroundColor: theme.palette.background.default,
      borderRadius: 2,
      boxShadow: NeumorphicStyle.boxShadowSmallInset(
        theme.palette.primary.main,
        theme.palette.secondary.dark
      ),
      textTransform: "none",
    },
  },
  chatHeader: {
    padding: 15,
  },
  matchmakingButton: {
    backgroundColor: theme.palette.secondary.light,
    marginLeft: "auto",
    color: "white",
    textTransform: "uppercase",
    fontWeight: "bold",
    height: 30,
    width: 144,
    padding: 4,
  },
  banner: {
    marginTop: "-50px",
    marginRight: "6px",
  },
}));

function ChatBar({ videoOpen, setVideoOpen, keynoteConversation, detailView, chatOpen, setChatOpen }) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [newMessageMode, setNewMessageMode] = useState(false);
  const [detailsOpen, setDetailsOpen] = useState(true);
  const [selectedConversation, setSelectedConversation] = useState();
  const [loadMoreTrigger, setLoadMoreTrigger] = useState(false);
  const [totalUnreadMessageCount, setTotalUnreadMessageCount] = useState(0);
  const conversationsReducer = useSelector(
    (state) => state.conversationsReducer
  );
  const conversationsToken = conversationsReducer.token;
  const exhibitionsReducer = useSelector(
    (state) => state.exhibitionsReducer.exhibition
  );
  const usersReducer = useSelector((state) => state.usersReducer);
  const { exhibition } = useSelector((state) => state.exhibitionsReducer);
  const [chatIndex, setChatIndex] = useState(2);
  const [defaultChatIndex, setDefaultChatIndex] = useState(2);
  const [isOpenContact, setIsOpenContact] = useState(false);
  const context = useContext(GlobalContext);
  const [exhibitorsUsers] = useExhibitorsUsers(context.currentExhibitor);
  const [config] = useConfigurationService();
  const dispatch = useDispatch();
  const [localMessagesCount, setLocalMessagesCount] = useState(0);
  const [trackEvent] = useTracking();

  const [banners, setBanners] = useState([]);
  const local = MyStore.getLocal();
  const visitors = useSelector((state) => state.visitorsReducer);
  const [isLoadChat, setIsLoadChat] = useState(false);

  // ----------------twilio----------------
  const [name, setName] = useState("");
  const [token, setToken] = useState(null);
  const [statusString, setStatusString] = useState(null);
  const [conversationsReady, setConversationsReady] = useState(false);
  const [conversations, setConversations] = useState([]);
  const [lastAddedMessageAuthor, setLastAddedMessageAuthor] = useState(null);
  const [selectedConversationSid, setSelectedConversationSid] = useState("");
  const [status, setStatus] = useState();
  const [nowСhattingFlag, setNowСhattingFlag] = useState(false);
  const [conversationContent, setConversationContent] = useState();
  const [userIds, setUserIds] = useState([]);
  const [onlineVisitors] = useGetOnlineVisitorsQL(userIds);

  useEffect(() => {
    dispatch(getTokenConversations());
  }, [dispatch]);

  useEffect(() => {
    if (conversationsToken) getToken();
  }, [conversationsToken]);

  const getToken = () => {
    const myToken = conversationsReducer.token;
    setToken(myToken);
    initConversations(myToken);
  };

  const getTotalUnreadMessage = async (fromNull) => {
    setTotalUnreadMessageCount(0);

    await conversations.forEach(async (conversation, i) => {
      let unreadMessageCount = 0;
      const messageCount = await conversation.getUnreadMessagesCount();

      if (messageCount === null && conversation.lastMessage) {
        const count = conversation.lastMessage.index + 1;
        unreadMessageCount = count;
      } else {
        unreadMessageCount = messageCount;
      }
      if (
        lastAddedMessageAuthor !==
        `${exhibitionsReducer.state.id}-${usersReducer.currentUser.state.id}`
      ) {
        if (fromNull && i === 0) {
          await setTotalUnreadMessageCount(unreadMessageCount);
        } else {
          await setTotalUnreadMessageCount((prev) => prev + unreadMessageCount);
        }
      } else {
        setLastAddedMessageAuthor(null);
      }
    });
  };

  const initConversations = async (myToken) => {
    window.conversationsClient = ConversationsClient;

    const conversationsClient = await ConversationsClient.create(myToken);
    setStatusString("Connecting to Twilio…");

    conversationsClient.on("conversationAdded", (newConversation) => {
      setConversations((prevConversations) => [
        ...prevConversations.filter((it) => it.sid !== newConversation.sid),
        newConversation,
      ]);
    });
    conversationsClient.on("conversationLeft", (thisConversation) => {
      setConversations((prevConversations) =>
        prevConversations.filter((it) => it !== thisConversation)
      );
    });
    conversationsClient.on("messageAdded", async (data) => {
      setLastAddedMessageAuthor(data.author);
      const conversation = data.conversation;
      setTotalUnreadMessageCount(0);
      setConversations((prevConversations) => {
        const newConversations = [...prevConversations];
        const conversationId = prevConversations.find(
          (conv) => conv.sid === conversation.sid
        );
        newConversations[conversationId] = conversation;

        return newConversations;
      });
    });
  };

  const getUsersOnlineStatus = () => {
    if (conversations.length) {
      const currentUserId = usersReducer.currentUser.state.id;
      const ids = [];
      conversations.forEach((conversation) => {
        if (conversation.channelState.attributes) {
          const keys = Object.keys(conversation.channelState.attributes);
          keys.forEach((key) => {
            if (String(currentUserId) !== key) {
              ids.push(key);
            }
          });
        }
      });
      if (ids.length !== userIds.length) {
        setUserIds(ids);
      }
    }
  };

  useEffect(() => {
    getTotalUnreadMessage();
    getUsersOnlineStatus();
  }, [conversations]);

  useEffect(() => {
    getTotalUnreadMessage(true);
    getUsersOnlineStatus();
  }, [selectedConversationSid]);

  const addedNewConversations = async (userAll, currentUserAll) => {
    const user = userAll.state;
    const currentUser = currentUserAll.state;
    window.conversationsClient = ConversationsClient;
    const conversationsClient = await ConversationsClient.create(token);
    setStatusString("Connecting to Twilio…");

    conversationsClient.on("connectionStateChanged", async (state) => {
      if (state === "connecting") {
        setStatusString("Connecting to Twilio…");
        setStatus("default");
      }
      if (state === "connected") {
        setStatusString("You are connected.");
        setStatus("success");
        const uniqueName = `dm-${exhibitionsReducer.state.id}-${[
          Number(user.id),
          Number(currentUser.id),
        ]
          .sort()
          .join("-")}`;

        const attributes = {
          [currentUser.id]: {
            img: user.profilePicture ? user.profilePicture.state.url : "",
            nameChat: `${user.firstName} ${user.lastName}`,
            userForVideo: userAll,
          },
          [user.id]: {
            img: currentUser.profilePicture
              ? currentUser.profilePicture.state.url
              : "",
            nameChat: `${currentUser.firstName} ${currentUser.lastName}`,
            userForVideo: currentUserAll,
          },
        };

        try {
          const conversationExisting = await conversationsClient.getConversationByUniqueName(
            uniqueName
          );
          if (
            !conversationExisting.channelState.attributes[currentUser.id]
              .userForVideo
          ) {
            conversationExisting.updateAttributes(attributes);
          }
          setNewMessageMode(!newMessageMode);
          loadChat(conversationExisting);
          setIsLoadChat(false);
        } catch (error) {
          console.log("error", error);

          await dispatch(createTwilioIdentity(user.id));

          const conversationNew = await conversationsClient.createConversation({
            uniqueName: uniqueName,
            friendlyName: `Chat with ${user.firstName} ${user.lastName}`,
            attributes,
          });
          await conversationNew.add(
            `${exhibitionsReducer.state.id}-${user.id}`
          );
          await conversationNew.join();
          setNewMessageMode(!newMessageMode);
          trackEvent(EVENT_TRACKING_TYPES.chat.conversationStarted, {
            conversation: uniqueName,
          });
          loadChat(conversationNew);
          setIsLoadChat(false);
        }
      }
      if (state === "disconnecting") {
        setStatusString("Disconnecting from Twilio…");
        setConversationsReady(false);
        setStatus("default");
      }
      if (state === "disconnected") {
        setStatusString("Disconnected.");
        setConversationsReady(false);
        setStatus("warning");
      }
      if (state === "denied") {
        setStatusString("Failed to connect.");
        setConversationsReady(false);
        setStatus("error");
      }
    });
  };

  useEffect(() => {
    const currentConversation = conversations.find(
      (it) => it.sid === selectedConversationSid
    );
    setSelectedConversation(currentConversation);

    if (currentConversation) {
      setConversationContent(
        <Conversation
          reloadNotifications={() => getTotalUnreadMessage(true)}
          conversationProxy={currentConversation}
          myIdentity={name}
          nameClass={classes.fadeIn}
        />
      );
    } else if (status !== "success") {
      setConversationContent("Loading your conversation!");
    }
  }, [chatIndex, conversations, selectedConversationSid]);

  useEffect(() => {
    if (!context.currentExhibitor) {
      if (chatIndex === 3) {
        setIsOpenContact(false);
        setChatIndex(defaultChatIndex);
      } else {
        setIsOpenContact(false);
      }
      return;
    }

    if (chatIndex === 2 || chatIndex === 1) {
      setDefaultChatIndex(chatIndex);
    }

    if (!isOpenContact) {
      setIsOpenContact(true);
      setChatIndex(3);
    }
  }, [context.currentExhibitor]);

  useEffect(() => {
    setLocalMessagesCount(0);
  }, [conversationsReducer.unreadMessagesCount]);

  useEffect(() => {
    if (!loadMoreTrigger) {
      return;
    }
    setLoadMoreTrigger(false);
  }, [loadMoreTrigger]);

  useEffect(() => {
    if (visitors.visitor && visitors.visitor.state) {
      const currentUser = usersReducer.currentUser.state;
      const user = visitors.visitor.state;
      const attributes = {
        [currentUser.id]: {
          img: user.profilePicture ? user.profilePicture.state.url : "",
          nameChat: `${user.firstName} ${user.lastName}`,
          userForVideo: visitors.visitor,
        },
        [user.id]: {
          img: currentUser.profilePicture
            ? currentUser.profilePicture.state.url
            : "",
          nameChat: `${currentUser.firstName} ${currentUser.lastName}`,
          userForVideo: usersReducer.currentUser,
        },
      };
      const activeConv = conversations.find(
        (conv) => conv.sid === selectedConversationSid
      );
      if (activeConv && Object.keys(attributes).length === 2)
        activeConv.updateAttributes(attributes);
      // dispatch(clearVisitor())
    }
  }, [visitors.visitor]);

  const loadChat = (c) => {
    const currentUser = usersReducer.currentUser.state;

    if (!c.channelState.attributes[currentUser.id].userForVideo) {
      const idUser = Object.keys(c.channelState.attributes).filter(
        (id) => id !== currentUser.id
      )[0];
      dispatch(fetchVisitor(exhibition.getId(), idUser));
    }

    setSelectedConversationSid(c.sid);
    setNowСhattingFlag(true);
  };

  const loadMore = () => {
    setLoadMoreTrigger(true);
  };

  const handleChatStart = (user) => {
    setIsLoadChat(true);
    addedNewConversations(user, usersReducer.currentUser);
  };

  useEffect(() => {
    if (!open) {
      return;
    }
    setLocalMessagesCount(0);
  }, [open]);

  useEffect(() => {
    if (
      exhibition &&
      exhibition.state.banners.length > 0 &&
      banners.length === 0
    ) {
      setBanners(
        exhibition.state.banners.filter((banner) => banner.language === local)
      );
    } else if (banners.length > 0 && banners[0].language !== local) {
      setBanners(
        exhibition.state.banners.filter((banner) => banner.language === local)
      );
    }
  }, [local, exhibition]);

  const onChatClose = (flag) => {
    setNowСhattingFlag(flag);
    getTotalUnreadMessage(true);
  };

  const [isOpenSpeedDating, setIsOpenSpeedDating] = useState(false);
  const [intervalContext] = useState({ interval: null });

  const changeOpenSpeedDating = (localExhibition) => {
    if (
      localExhibition &&
      localExhibition.state &&
      localExhibition.state.openingHour &&
      localExhibition.state.openingHour.length > 0
    ) {
      const nowDate = new Date();
      setIsOpenSpeedDating(false);
      localExhibition.state.openingHour.forEach((openingHour) => {
        const startDate = new Date(openingHour.speeddating_open_at);
        const closeDate = new Date(openingHour.speeddating_close_at);
        if (nowDate > startDate && nowDate < closeDate) {
          setIsOpenSpeedDating(true);
        }
      });
    }
  };

  useEffect(() => {
    changeOpenSpeedDating(exhibition);
  }, [exhibition, chatIndex, selectedConversationSid]);

  const loadData = () => {
    changeOpenSpeedDating(exhibitionsReducer);
  };

  const stopInterval = () => {
    if (intervalContext.interval) {
      clearInterval(intervalContext.interval);
    }
  };

  const startInterval = () => {
    stopInterval();
    loadData();
    intervalContext.interval = setInterval(loadData, 60000);
  };

  useEffect(() => {
    startInterval();

    return () => {
      stopInterval();
    };
  }, []);

  const renderChatButtons = () => (
    <Grid style={{ right: chatOpen ? 350 : 0 }} container direction="column" className={classes.closedChat}>
      {/* <Button onClick={() =>
          context.openGlobalModal(MODAL_TYPE.MATCHMAKING_MODAL)
        } 
        className={classes.closedSpeedDating}
      >
        <FontAwesomeIcon style={{ fontSize: "1.4em", marginBottom: 4, marginRight: "auto", marginLeft: 8 }} className={classes.closedButtonIcon} icon={faHandshake} />
      </Button> */}
      <Button onClick={() => {
        setChatOpen(!chatOpen)
      }}>
        <Badge
          badgeContent=" "
          invisible={totalUnreadMessageCount === 0}
          variant="dot"
          color="error"
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <ChatBubbleOutlineIcon style={{ fontSize: "1.7em", marginTop: 8, marginRight: "auto", marginLeft: -12 }} className={classes.closedButtonIcon} />
        </Badge>
      </Button>
    </Grid>
  )

  return !chatOpen || (!config || usersReducer.currentUser) &&
    !usersReducer.currentUser.hasWritePermissions() ? (
      <>
        {renderChatButtons()}
      </>
    ) : (
      <Grid container direction="row" justify="flex-end" className={chatOpen ? classes.root : classes.rootClosed}>
        {renderChatButtons()}
        <Grid
          item
          container
          direction="row"
          className={classes.rootChat}
          style={{
            height:
              exhibition &&
                exhibition.state &&
                exhibition.state.banners.length > 0
                ? "calc(100% - 155px)"
                : "calc(100% - 75px)",
          }}
          onClick={(e) => e.stopPropagation()}
        >
          <Grid container direction="row" className={classes.chatHeader}>
            <Typography variant="h3" style={{ textAlign: "center" }}>
              Chat
          </Typography>
            {chatIndex !== 3 && (
              <Button
                variant="contained"
                disabled={!isOpenSpeedDating}
                onClick={() =>
                  context.openGlobalModal(MODAL_TYPE.MATCHMAKING_MODAL)
                }
                className={classes.matchmakingButton}
              >
                Speed Dating
              </Button>
            )}
          </Grid>
          <div className={classes.chatSwitchContainer}>
            {/* <Button disableFocusRipple className={chatIndex === 0 ? classes.pressedSwitchButton:classes.switchButton} onClick={() => setChatIndex(0)} disabled={!eventConversation}>
          <PublicIcon />
        </Button> */}
            <Button
              className={
                chatIndex === 2
                  ? classes.pressedSwitchButton
                  : classes.switchButton
              }
              onClick={() => setChatIndex(2)}
            >
              <QuestionAnswerOutlinedIcon style={{ marginRight: "8px" }} />
              <Typography variant="body1">
                <Trans>frontend.chat_public</Trans>
              </Typography>
            </Button>
            <Badge
              badgeContent=" "
              invisible={totalUnreadMessageCount === 0}
              variant="dot"
              color="error"
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <Button
                className={
                  chatIndex === 1
                    ? classes.pressedSwitchButton
                    : classes.switchButton
                }
                onClick={() => setChatIndex(1)}
              >
                <ChatBubbleOutlineIcon style={{ marginRight: "8px" }} />
                <Typography variant="body1">
                  <Trans>frontend.chat_private</Trans>
                </Typography>
              </Button>
            </Badge>
            {context.currentExhibitor && (
              //exhibitorsUsers &&
              //exhibitorsUsers.length !== 0 &&
              <Button
                className={
                  chatIndex === 3
                    ? classes.pressedSwitchButton
                    : classes.switchButton
                }
                onClick={() => setChatIndex(3)}
                disabled={exhibitorsUsers && exhibitorsUsers.length === 0}
              >
                <AccountCircleIcon style={{ marginRight: "8px" }} />
                <Typography variant="body1">
                  <Trans>frontend.chat_contact</Trans>
                </Typography>
              </Button>
            )}
          </div>

          {chatIndex === 3 && ( //&& exhibitorsUsers && isOpenContact
            <ExhibitorsUsers
              chatBar={true}
              handleChatStart={(user) => handleChatStart(user)}
              setChatIndex={setChatIndex}
              exhibitorsUsers={exhibitorsUsers}
            />
          )}

          {chatIndex === 2 ? (
            <PublicChatBar
              keynoteConversation={keynoteConversation}
              detailView={detailView}
              chatIndex={chatIndex}
              setChatIndex={setChatIndex}
            />
          ) : (
              <>
                {chatIndex === 3 ? (
                  <div className={classes.fadeIn} />
                ) : (
                    nowСhattingFlag && (
                      <Grid item className={classes.chatDetailItem}>
                        <Card className={classes.expansionPanel}>
                          <CardHeader
                            style={{ padding: 12 }}
                            onClick={() => setDetailsOpen(!detailsOpen)}
                            title={
                              <Typography variant="h4">
                                {
                                  conversations.find(
                                    (conv) => conv.sid === selectedConversationSid
                                  ).channelState.attributes[
                                    usersReducer.currentUser.state.id
                                  ].nameChat
                                }
                              </Typography>
                            }
                            action={
                              chatIndex !== 2 ? (
                                <div>
                                  {/* <IconButton
                                    onClick={() => setVideoOpen(true)}
                                    style={{ borderRadius: 0 }}
                                  >
                                    <VideoCallIcon />
                                  </IconButton> */}
                                  <IconButton
                                    onClick={() => onChatClose(false)}
                                    style={{ borderRadius: 0 }}
                                  >
                                    <CloseIcon />
                                  </IconButton>
                                </div>
                              ) : (
                                  <div></div>
                                )
                            }
                          />
                          <Divider />
                          <div className={classes.fadeIn}>{conversationContent}</div>
                        </Card>
                      </Grid>
                    )
                  )}
                {chatIndex === 3 ? (
                  <div className={classes.fadeIn} />
                ) : (
                    !nowСhattingFlag && (
                      <Grid item className={classes.chatListItem}>
                        <Card className={classes.expansionPanel}>
                          {isLoadChat ? (
                            <ContentLoader
                              speed={2}
                              backgroundColor="#f3f3f3"
                              foregroundColor="#ecebeb"
                              width="100%"
                              height="100%"
                            >
                              {/* <circle cx="50%" cy="50%" r="50%" /> */}
                              <rect x="0" y="0" width="100%" height="100%" />
                            </ContentLoader>
                          ) : (
                              <>
                                <CardHeader
                                  style={{
                                    padding: 0,
                                    paddingLeft: 10,
                                    paddingRight: 10,
                                    marginTop: 8,
                                    marginBottom: 8,
                                  }}
                                  title={
                                    <Typography
                                      variant="h3"
                                      style={{ width: "100%" }}
                                      onClick={() => setOpen(!open)}
                                    >
                                      {newMessageMode ? (
                                        <Trans>frontend.navBar.visitors</Trans>
                                      ) : (
                                          ""
                                        )}
                                    </Typography>
                                  }
                                  action={
                                    exhibition &&
                                      exhibition.getExhibitionType() ===
                                      Exhibition.EXHIBITION_TYPES.CLASSIC ? (
                                        <IconButton
                                          onClick={() => {
                                            setNewMessageMode(!newMessageMode);
                                          }}
                                          style={{ borderRadius: 0 }}
                                        >
                                          <AddIcon
                                            style={{ fontSize: "0.8em" }}
                                            className={
                                              newMessageMode
                                                ? classes.closeIcon
                                                : classes.addIcon
                                            }
                                          />
                                        </IconButton>
                                      ) : (
                                        <div />
                                      )
                                  }
                                />
                                {chatIndex === 3 ? (
                                  <div className={classes.fadeIn} />
                                ) : (
                                    <div
                                      className={classes.fadeIn}
                                      style={{ height: "calc(100% - 54px)" }}
                                    >
                                      {newMessageMode ? (
                                        <VisitorsList handleChatStart={handleChatStart} />
                                      ) : (
                                          <ThreadList
                                            // selectedConversation={selectedConversation}
                                            conversations={conversations}
                                            loadChat={loadChat}
                                            localMessagesCount={localMessagesCount}
                                            onlineVisitors={onlineVisitors}
                                            currentUserId={
                                              usersReducer.currentUser.state.id
                                            }
                                          />
                                        )}
                                    </div>
                                  )}
                              </>
                            )}
                        </Card>
                      </Grid>
                    )
                  )}
              </>
            )}
        </Grid>
        {selectedConversation &&
          selectedConversation.channelState.attributes[
            usersReducer.currentUser.state.id
          ].userForVideo && (
            <>
              <VideoModal
                open={videoOpen}
                handleClose={() => setVideoOpen(false)}
                chatUser={
                  selectedConversation.channelState.attributes[
                    usersReducer.currentUser.state.id
                  ].userForVideo
                }
              />
            </>
          )}
        {chatOpen && exhibition && banners.length > 0 && (
          <Grid item className={classes.banner}>
            <BannerBar banners={banners} exhibitionId={exhibition.state.id} />
          </Grid>
        )}
      </Grid>
    );
}

ChatBar.propTypes = {
  className: PropTypes.string,
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default ChatBar;
