import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import { useDispatch } from "react-redux";
import { CircularProgress, Box } from "@material-ui/core";
import MyStore from "../../../utils/MyStore";
import { logOutUser, clearUser } from "../../../redux/actions/UsersActions";
import { deleteSession } from "../../../redux/actions/SessionActions";
import { removeActionCable } from "../../../redux/actions/ActionCableActions";
import FrontendRoutes from "../../../constants/FrontendRoutes";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(2, 2),
    overflow: "auto",
  },
}));

function Logout() {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const handleLogout = async () => {
    await dispatch(deleteSession());
    await dispatch(logOutUser());
    await dispatch(clearUser());
    await dispatch(removeActionCable());
    MyStore.removeAllKeys();
    history.push(FrontendRoutes.auth.login());
  };

  useEffect(() => {
    handleLogout();
  }, []);

  return (
    <div className={classes.root} title="Logout">
      <Box>
        <CircularProgress size="40" />
      </Box>
    </div>
  );
}

export default Logout;
