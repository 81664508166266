import { Avatar, Grid, Link, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles'
import React, { useContext } from 'react'
import { Trans } from 'react-i18next';
import MyStore from '../../../../../utils/MyStore';
import useTracking, { EVENT_TRACKING_TYPES } from '../../../../engines/tracking/useTracking';
import GlobalContext from '../../context/GlobalContext';
import { MODAL_TYPE } from '../GlobalModal';

const Entities = require("html-entities").XmlEntities;

const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: 15
    },
    timeString: {
        fontWeight: "normal",
        marginBottom: 8,
    },
    title: {
        fontWeight: "bold"
    },
    bodyText: {
        marginBottom: 30
    },
    speakerRow: {
        width: "100%"
    },
    speakerContainer: {
         marginRight: 20,
         marginBottom: 8,
         cursor: "pointer",
         width: "auto",
    },
    speakerTitle: {
        fontWeight: "bold",
        color: theme.palette.text.primary,
        marginBottom: 2
    },
    speakerSubtitle: {

    },
    speakerAvatar: {
        height: 50,
        width: 50,
        marginRight: 8,
    },
    speakerBookmark: {
        //for later use
    },
    speakerNameContainer: {
        marginTop: "auto",
        marginBottom: "auto",
    }
}));

function KeynoteDetails({ keynote }) {
    const classes = useStyles();
    const context = useContext(GlobalContext);
    const entities = new Entities();
    const [trackEvent,trackVisit] = useTracking();

    return (
        <div className={classes.root}>
            {keynote && keynote.getStartAt() && keynote.getEndAt() && (
                <Typography className={classes.timeString} variant="h4" color="secondary">
                    {new Date(keynote.getStartAt()).toLocaleString([], {
                        day: "2-digit",
                        month: "long",
                        year: "numeric",
                        hour: "2-digit",
                        minute: "2-digit",
                    }) + " - " + new Date(keynote.getEndAt()).toLocaleTimeString([], {
                        hour: "2-digit",
                        minute: "2-digit",
                    })}
                </Typography>
            )}
            {keynote && keynote.getTitle() && (
                <Typography variant="h3" className={classes.title} color="textPrimary">
                    {keynote.getTitle()}
                </Typography>
            )}
            {keynote && keynote.getDescription(MyStore.getLocal()) && (
                <Typography 
                    variant="h5" 
                    className={classes.bodyText} 
                    color="textPrimary"
                    dangerouslySetInnerHTML={{
                        __html: entities.decode(keynote.getDescription(MyStore.getLocal())),
                    }}
                >
                </Typography>
            )}
            {keynote && (keynote.getModerators() || keynote.getSpeakers()) && (
                <Grid container direction="row" className={classes.speakerRow}>
                    {keynote.getSpeakers() && keynote.getSpeakers().map(speaker => 
                        <Grid item direction="row" container className={classes.speakerContainer} onClick={() => {
                            trackEvent(EVENT_TRACKING_TYPES.keynote.lookedUpSpeaker,{
                                keynote: keynote.getId(),
                                speaker: speaker.getId()
                            })
                            context.openGlobalModal(MODAL_TYPE.USER_MODAL, speaker.getId());
                        }}>
                            <Avatar className={classes.speakerAvatar} src={speaker.getProfilePicture() ? speaker.getProfilePicture().getUrl("small"):""} />
                            <div className={classes.speakerNameContainer}>
                                <Typography className={classes.speakerTitle} variant="h6">
                                    {speaker.getFullName()}
                                </Typography>
                                <Typography variant="body1" className={classes.speakerSubtitle}>
                                    <Trans>frontend.speaker</Trans>
                                </Typography>
                            </div>
                        </Grid>
                    )}
                    {keynote.getModerators() && keynote.getModerators().map(speaker => 
                        <Grid item direction="row" container className={classes.speakerContainer} onClick={() => {
                            trackEvent(EVENT_TRACKING_TYPES.keynote.lookedUpModerator,{
                                keynote: keynote.getId(),
                                moderator: speaker.getId()
                            })
                            context.openGlobalModal(MODAL_TYPE.USER_MODAL, speaker.getId())
                        }}>
                            <Avatar className={classes.speakerAvatar} src={speaker.getProfilePicture() ? speaker.getProfilePicture().getUrl("small"):""} />
                            <div className={classes.speakerNameContainer}>
                                <Typography className={classes.speakerTitle} variant="h6">
                                    {speaker.getFullName()}
                                </Typography>
                                <Typography variant="body1" className={classes.speakerSubtitle}>
                                    <Trans>frontend.moderator</Trans>
                                </Typography>
                            </div>
                        </Grid>
                    )}
                </Grid>
            )}
        </div>
    )
}

export default KeynoteDetails
