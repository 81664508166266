import MyStore from "../utils/MyStore";
import ApplicationModel from "./ApplicationModel";

export const INTERNAL_IDS = {
  AUTHENTICATED: 'Authenticated',
  VISITOR: 'Visitor',
  EDITOR: 'Editor',
  CUSTOMER: 'Customer',
  EXHIBITOR: 'Exhibitor'
}

export default class Role extends ApplicationModel {

  constructor(_opts = {}){
    super(_opts)
    this.state = { ..._opts }
  }

  getName = (ignoreVisitorView = false) => {
    let name = !ignoreVisitorView && MyStore.getVisitorView() === true ? INTERNAL_IDS.VISITOR : this.state.name;
    return name;
  }
  getType = () => this.state.type
  getDescription = () => this.state.description
  getPreEventVisible = (exhibition) => {
    switch (this.state.name) {
      case INTERNAL_IDS.AUTHENTICATED:
        return true;
      case INTERNAL_IDS.CUSTOMER:
        return true;
      default:
        if (exhibition && exhibition.getExhibitionStarted()) {
          return true;
        }
        return false;
    }
  }

  // Backend Attributes
  static attributes = {
    ...super.attributes,
    name: "name",
    description: "description",
    type: "type",
  }

  static orderByParams = {
    ...super.orderByParams
  }

  static filterParams = {
    ...super.filterParams,
  }
}
