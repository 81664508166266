import { Grid, LinearProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import React, { Suspense, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { renderRoutes } from "react-router-config";
import useExhibition from "../../../engines/GeneralHooks/useExhibition";
import { useRawPrivatePushNotification } from "../../../engines/Notification/NotificationEngine";
import useBreakpoints from "../../../engines/Util/useBreakpoints";
import useWindowSize from "../../../engines/Util/useWindowSize";
import Exhibition from "../../../models/Exhibition";
import Meeting from "../../../models/Meeting";
import PushNotification from "../../../models/PushNotification";
import MyStore from "../../../utils/MyStore";
import useTracking, {
  EVENT_TRACKING_TYPES,
  VISIT_TRACKING_TYPES,
} from "../../engines/tracking/useTracking";
import VideoStateProvider from "../../state";
import Agenda from "../../views/Agenda";
import MobileApp from "../Mobile/MobileApp";
import ChatBar from "./Chat/ChatBar";
import ExhibitorDetailsModal from "./components/ExhibitorDetailsModal";
import GlobalModal from "./components/GlobalModal";
import MeetingView from "./components/MeetingView";
import UserProfile from "./components/UserProfile";
import VideoMode from "./components/VideoBox/FullVideoView/VideoMode";
import GlobalContext from "./context/GlobalContext";
import NavBar from "./NavBar";
import OldTopBar from "./OldTopBar";
import PostFeed from "./PostFeed";
import ScheduleBar from "./ScheduleBar/ScheduleBar";
import TopBar from "./TopBar";
import DiscussionRoomView from "./views/DiscussionView";
import ExhibitorView from "./views/ExhibitorView";
import SponsorView from "./views/SponsorView";
import VisitorView from "./views/VisitorView";
import parser from 'ua-parser-js';

const useStyles = makeStyles((theme) => ({
  container: {
    //maxWidth: "1758px",
    minWidth: "600px",
    marginLeft: "auto",
    marginRight: "auto",
    position: "relative",
    display: "flex",
    backgroundColor: theme.palette.background.default,
    overflowY: "hidden",
    overflowX: "auto",
  },
  content: {
    height: "100%",
    width: "100%",
    padding: 20,
    paddingTop: 16,
    zIndex: 900,
    position: "relative",
    overflow: "hidden",
  },
  keyvisual: {
    position: "fixed",
    left: 0,
    right: 0,
    height: 400,
    width: "100%",
    objectFit: "cover",
    zIndex: 800,
  },
  keyvisualOverlay: {
    position: "fixed",
    left: 0,
    right: 0,
    top: 250,
    height: 150,
    width: "100%",
    objectFit: "cover",
    zIndex: 801,
  },
  feed: {
    overflow: "hidden",
    minWidth: "450px",
  },
  oldContainer: {
    minHeight: "100vh",
    display: "flex",
    "@media all and (-ms-high-contrast:none)": {
      height: 0, // IE11 fix
    },
  },
  oldContent: {
    paddingRight: 374,
    paddingTop: 64,
    flexGrow: 1,
    maxWidth: "100%",
    overflowX: "hidden",
    [theme.breakpoints.up("lg")]: {
      paddingLeft: 274,
    },
    [theme.breakpoints.down("xs")]: {
      paddingTop: 56,
    },
  },
}));

// function useQuery() {
//   return new URLSearchParams(useLocation().search);
// }

const TAB_DEFINITION = {
  postFeed: 0,
  exhibitors: 1,
  visitors: 2,
  agenda: 3,
  sponsors: 4,
  userProfile: 5,
  video_mode: 6,
  discussion_room: 7,
};

function Dashboard({ route }) {
  const [breakpoint] = useBreakpoints();
  const classes = useStyles(breakpoint);
  const [exhibition] = useExhibition();
  const [tabIndex, setTabIndex] = useState(0);
  const [meetingId, setMeetingId] = useState();
  // eslint-disable-next-line no-unused-vars
  const [width, height] = useWindowSize();
  const [deviceType, setDeviceType] = useState();

  const [incoming] = useRawPrivatePushNotification();
  const [trackEvent, trackVisit] = useTracking();
  const [currentExhibitor, setCurrentExhibitor] = useState();
  const [loadingStage, setLoadingStage] = useState(0);
  const [currentUser, setCurrentUser] = useState();
  const [tags, setTags] = useState(MyStore.getTags() || []);
  const [bookmarkedPost, setBookmarkedPost] = useState();
  const [updateShortlist, setUpdateShortlist] = useState(false);
  const [salesmarkExhibitor, setSalesmarkExhibitor] = useState();
  const [videoFullWidth, setVideoFullWidth] = useState(false);
  const [modalStack, setModalStack] = useState([]);
  const [muted, setMuted] = useState(false);
  const [talkingPointId, setTalkingPointId] = useState();
  const [upBannerPosition, setUpBannerPosition] = useState(0);
  const [bottomBannerPosition, setBottomBannerPosition] = useState(0);
  const [lastGlobalMessage, setLastGlobalMessage] = useState();

  const [currentExhibitorList, setCurrentExhibitorList] = useState([]);

  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(0);

  // {
  //   type: MODAL_TYPE.USER_MODAL,
  //   id: "5fa91715ee605d0017a48592"
  // }

  // eslint-disable-next-line no-unused-vars
  const [oldView, setOldView] = useState(false);
  const [openNavBarMobile, setOpenNavBarMobile] = useState(false);
  const [videoOpen, setVideoOpen] = useState(false);

  const usersReducer = useSelector((state) => state.usersReducer);

  const [keynoteConversation, setKeynoteConversation] = useState();
  const [videoMode, setVideoMode] = useState(0);
  // const query = useQuery();

  useEffect(() => {
    MyStore.setTags(tags);
  }, [tags]);

  useEffect(() => {
    if (exhibition && usersReducer.currentUser) {
    }
  }, [exhibition, usersReducer.currentUser]);

  useEffect(() => {
    if (!incoming) {
      return;
    }

    if (
      incoming.getNotificationType() ===
      PushNotification.NOTIFICATION_TYPES.meetingStarted &&
      incoming.getPayload().getReason() === Meeting.reason.CHAT_ONE_TO_ONE
    ) {
      setMeetingId(incoming.getPayload().getId());
    }
  }, [incoming]);

  useEffect(() => {
    if (!exhibition) {
      return;
    }

    if (exhibition.getExhibitionType() === Exhibition.EXHIBITION_TYPES.VIDEO) {
      setTabIndex(6);
    }

    setMinPrice(exhibition.getMinPrice());
    setMaxPrice(exhibition.getMaxPrice());
  }, [exhibition]);

  useEffect(() => {
    if (meetingId) {
      setMuted(true);
    } else {
      setMuted(false);
    }
  }, [meetingId]);

  document.documentElement.style.overflow =
    meetingId || currentExhibitor || currentUser ? "hidden" : "scroll";
  document.body.scroll =
    meetingId || currentExhibitor || currentUser ? "no" : "yes";

  const openGlobalModal = (modalType, objectId) => {
    setModalStack([{ type: modalType, id: objectId }]);
  };

  const closeGlobalModal = () => {
    setModalStack([]);
  };

  // const location = useLocation();

  useEffect(() => {
    if (!usersReducer.currentUser || !exhibition) {
      return;
    }

    switch (tabIndex) {
      case TAB_DEFINITION.postFeed:
        trackVisit(VISIT_TRACKING_TYPES.feed);
        break;
      case TAB_DEFINITION.exhibitors:
        trackVisit(VISIT_TRACKING_TYPES.exhibitors);
        break;
      case TAB_DEFINITION.visitors:
        trackVisit(VISIT_TRACKING_TYPES.attendees);
        break;
      case TAB_DEFINITION.agenda:
        trackVisit(VISIT_TRACKING_TYPES.agenda);
        break;
      case TAB_DEFINITION.sponsors:
        trackVisit(VISIT_TRACKING_TYPES.sponsors);
        break;
      case TAB_DEFINITION.userProfile:
        trackVisit(VISIT_TRACKING_TYPES.userProfile);
        break;
      case TAB_DEFINITION.discussion_room:
        trackVisit(VISIT_TRACKING_TYPES.discussionRoom);
        break;
      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabIndex, exhibition, usersReducer.currentUser]);

  useEffect(() => {
    if (muted) {
      trackEvent(EVENT_TRACKING_TYPES.stage.stageMuted, { muted });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [muted]);

  useEffect(() => {
    const { device: { type } } = parser();
    setDeviceType(type);
  }, [height, width])

  return <GlobalContext.Provider
    value={{
      currentExhibitor,
      currentUser,
      tags,
      tabIndex,
      loadingStage,
      bookmarkedPost,
      meetingId,
      updateShortlist,
      salesmarkExhibitor,
      videoFullWidth,
      modalStack,
      muted,
      talkingPointId,
      upBannerPosition, setUpBannerPosition,
      bottomBannerPosition, setBottomBannerPosition,
      setMuted,
      setVideoFullWidth,
      setSalesmarkExhibitor,
      setUpdateShortlist,
      setMeetingId,
      setBookmarkedPost,
      setLoadingStage,
      setTabIndex,
      setCurrentUser,
      setCurrentExhibitor,
      setTags,
      setTalkingPointId,
      openGlobalModal,
      closeGlobalModal,

      minPrice,
      setMinPrice,
      maxPrice,
      setMaxPrice,
      videoMode,
      setVideoMode,
      keynoteConversation,
      setKeynoteConversation,
      lastGlobalMessage,
      setLastGlobalMessage,
    }}
  >
    {deviceType === 'mobile' && deviceType !== 'tablet' ? (
      <MobileApp />
    ) : (
      <div className={classes.container} style={{ height: height }}>
        <ScheduleBar
          setParentVideoMode={(mode) => setVideoMode(mode)}
          setKeynoteConversation={(conversation) =>
            setKeynoteConversation(conversation)
          }
        />
        <MeetingView
          meetingId={meetingId}
          closeMeeting={() => setMeetingId(undefined)}
        />
        <VideoStateProvider>
          <GlobalModal />
        </VideoStateProvider>
        <div className={classes.content}>
          <div style={{ width: "100%", paddingLeft: 15 }}>
            <TopBar
              setTabIndex={(index) => setTabIndex(index)}
              setTalkingPointId={setTalkingPointId}
              tabIndex={tabIndex}
              exhibition={exhibition}
            />
          </div>
          <Grid container direction="row" style={{ height: "100%" }}>
            {
              exhibition && (
                //<SwipeableViews style={{height: "100%", overflow:"hidden"}} scrolling={false} index={tabIndex}>
                <div
                  style={{
                    height: "100%",
                    overflow: "hidden",
                    width: breakpoint <= 3 ? "calc(100% - 258px)" : "calc(100% - 358px)",
                    marginRight: "8px"
                  }}
                >
                  {tabIndex === 6 && (
                    <div
                      className={classes.feed}
                      hidden={tabIndex !== 6}
                      style={{ height: height - 64 }}
                    >
                      <VideoMode
                        setKeynoteConversation={setKeynoteConversation}
                      />
                    </div>
                  )}
                  {tabIndex === 0 && (
                    <div
                      className={classes.feed}
                      hidden={tabIndex !== 0}
                      style={{ height: height - 64 }}
                    >
                      <PostFeed />
                    </div>
                  )}
                  {tabIndex === 1 && (
                    <div
                      className={classes.feed}
                      hidden={tabIndex !== 1}
                      style={{ height: height - 64 }}
                    >
                      <ExhibitorView
                        setCurrentExhibitorList={setCurrentExhibitorList}
                      />
                    </div>
                  )}
                  {tabIndex === 2 && (
                    <div
                      className={classes.feed}
                      hidden={tabIndex !== 2}
                      style={{ height: height - 64 }}
                    >
                      <VisitorView />
                    </div>
                  )}
                  {tabIndex === 3 && (
                    <div
                      className={classes.feed}
                      hidden={tabIndex !== 3}
                      style={{ height: height - 64 }}
                    >
                      <Agenda />
                    </div>
                  )}
                  {tabIndex === 4 && (
                    <div
                      className={classes.feed}
                      hidden={tabIndex !== 4}
                      style={{ height: height - 64 }}
                    >
                      <SponsorView
                        setCurrentExhibitorList={setCurrentExhibitorList}
                      />
                    </div>
                  )}
                  {tabIndex === 7 && (
                    <div
                      className={classes.feed}
                      hidden={tabIndex !== 7}
                      style={{ height: height - 64 }}
                    >
                      <DiscussionRoomView
                        language={
                          (usersReducer &&
                            usersReducer.user &&
                            usersReducer.user.getLocale()) ||
                          "en"
                        }
                      />
                    </div>
                  )}
                  {usersReducer.currentUser && exhibition && (
                    <div
                      className={classes.feed}
                      hidden={tabIndex !== 5}
                      style={{ height: height - 64, padding: 50 }}
                    >
                      <UserProfile
                        exhibition={exhibition}
                        userId={
                          usersReducer.currentUser
                            ? usersReducer.currentUser.getId()
                            : undefined
                        }
                      />
                    </div>
                  )}
                </div>
              )
            }
            <ChatBar
              videoOpen={videoOpen}
              detailView={videoMode === 2}
              setVideoOpen={(open) => setVideoOpen(open)}
              keynoteConversation={keynoteConversation}
            />
          </Grid>
        </div>
        <ExhibitorDetailsModal
          exhibitorList={currentExhibitorList}
          setCurrentExhibitorList={setCurrentExhibitorList}
        />
        {/* <GlobalModal /> */}
      </div>
    )}
  </GlobalContext.Provider> 
}

Dashboard.propTypes = {
  route: PropTypes.object,
};

export default Dashboard;
