import { Grid, LinearProgress, makeStyles, Box, useMediaQuery, Divider } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles';
import React, { useEffect, useRef, useState } from 'react'
import { useContext } from 'react';
import { DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE } from '../../../../../constants/Urls';
import useInfiniteScoll from '../../../../../engines/GeneralHooks/useInfiniteScoll';
import useVisitorsQL from '../../../../../engines/GraphQL/hooks/useVisitorsQL';
import useExhibition from '../../../../engines/GeneralHooks/useExhibition';
import BookmarkSwitch from '../../components/BookmarkSwitch';
import TagArea from '../../components/TagArea';
import VisitorCard from '../../components/VisitorCard'
import GlobalContext from '../../context/GlobalContext';

const useStyles = makeStyles(theme => ({
    root: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(3),
        overflow: "scroll",
        height: "100%",
        paddingLeft: theme.spacing(2.5),
        paddingRight: theme.spacing(2.5),
    },
    tagArea: {
        width: "100%",
        marginBottom: theme.spacing(2),
    },
    visitorContainer: {
        width: "100%",
        height: "100%",
        maxHeight: "320px",
        marginLeft: theme.spacing(2.5)
    },
}));

function VisitorView() {
    const classes = useStyles();
    const containerRef = useRef();
    const [exhibition] = useExhibition();
    const xsSize = useMediaQuery('(min-width: 1360px)')
    const smSize = useMediaQuery('(min-width: 1600px)')
    const mdSize = useMediaQuery('(min-width: 1920px)')
    const mdxSize = useMediaQuery('(min-width: 2300px)')
    const lgSize = useMediaQuery('(min-width: 2560px)')

    const pageSize = lgSize ? 20 : mdxSize ? 12 : mdSize ? 9 : smSize ? 6 : xsSize ? 6 : DEFAULT_PAGE_SIZE

    const [searchText, setSearchText] = useState()
    const [filteredVisitors, setFilteredVisitors] = useState([])
    const [showBookmarks, setShowBookmarks] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(DEFAULT_PAGE_INDEX);
    const [visitors, loading, totalPages, refetchBookmarks, fetchNewData] = useVisitorsQL(searchText, currentIndex, pageSize, showBookmarks);
    const context = useContext(GlobalContext);
    const [currentPageIndex, isBottom, resetPageIndex, setIsBottom] = useInfiniteScoll(containerRef.current, totalPages, true, loading);

    useEffect(() => {
        if (!isBottom) {
            fetchNewData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (isBottom) {
            refetchBookmarks()
            setIsBottom(false)
        }
    }, [isBottom, refetchBookmarks])

    useEffect(() => {
        if (currentPageIndex === currentIndex) {
            return
        }
        setCurrentIndex(currentPageIndex);
    }, [currentPageIndex])

    useEffect(() => {
        resetPageIndex();
        fetchNewData();
    }, [context.tags])

    useEffect(() => {
        if (!visitors || visitors.length === 0) {
            return;
        }
        resetPageIndex();
        refetchBookmarks();
    }, [showBookmarks])

    const updateFeed = () => {
        if (showBookmarks) {
            setCurrentIndex(DEFAULT_PAGE_INDEX);
            fetchNewData();
        }
    }

    useEffect(() => {
        if (visitors.length !== 0) {
            const filteredData = visitors.filter(visitor => visitor !== undefined);
            const set = new Set();

            const noDuplicates = filteredData.filter((item) => {
                const isDuplicate = set.has(item.state.id);
                set.add(item.state.id);
                return !isDuplicate;
            });
            setFilteredVisitors([...noDuplicates]);
        } else if (visitors.length === 0 && filteredVisitors.length > 0) {
            setFilteredVisitors([]);
        }
    }, [visitors])

    return filteredVisitors && (
        <div className={classes.root} ref={containerRef}>
            <Box display="flex" mb={2} pl={2.625}>
                <Box className={classes.tagArea}>
                    <TagArea dropdownWidth="70%" resetPageIndex={() => resetPageIndex()} setSearchText={(text) => setSearchText(text)} exhibition={exhibition} />
                </Box>
                <Box pt={1} mr={-0.25}>
                    <BookmarkSwitch showBookmarks={showBookmarks} setShowBookmarks={setShowBookmarks} />
                </Box>
            </Box>
            {loading && filteredVisitors.length === 0 && <LinearProgress />}
            <Box height="100%">
                <Grid container justify="flex-start" direction="row" spacing={3} className={classes.visitorContainer}>
                    {filteredVisitors.map((visitor, index) => (
                        <Grid item>
                            <VisitorCard visitor={visitor} refetchBookmarks={() => updateFeed()} currentIndex={index} currentVisitor={visitor} visitorList={filteredVisitors} />
                        </Grid>
                    ))}
                </Grid>
                <Box height="1%" />
                {loading && currentPageIndex !== DEFAULT_PAGE_INDEX && (
                    <LinearProgress
                        style={{ maxWidth: "80%", marginLeft: "auto", marginRight: "auto", marginTop: 12 }}
                    />
                )}
                {currentPageIndex >= totalPages && (
                    <Divider style={{ background: 'black', height: 2, marginTop: 8 }} />
                )}
            </Box>
        </div>
    )
}

export default VisitorView
