import { Box } from '@material-ui/core'
import React, {useState, useEffect} from 'react'
import useStyles from './useStyles'

const Badge = ({badges}) => {
    const classes = useStyles()
    const [currentImage, setCurrentImage] = useState(0)

    useEffect(() => {
        timer()
    }, [currentImage])

    const timer = () => {
        setTimeout(() => {
            changeCurrentImage()
        }, badges[currentImage].seconds_show * 1000 || 30 * 1000)
    }

    const changeCurrentImage = () => {
        if(currentImage < badges.length && currentImage !== badges.length - 1){
            setCurrentImage(currentImage + 1)
        }
        else{
            setCurrentImage(0)
        }
    }

    const handleClickBadge = (url) => {
        url = (!url.match(/^https?:\/\//i) ? "https://":"") + url;
        window.open(url,"_blank");
    }

    return (
        <>
        <Box display="flex">
            {badges.map((badge) => (
                <Box display="flex" alignItems="center" pr={1} onClick={() => handleClickBadge(badge.badge_url)}>
                    <img className={classes.image} src={badge.badge_image.url} />
                </Box> 
            )
            )}
        </Box>
        </>
    )
}

export default Badge
