import {
  REGISTRATION_CREATE_FAILURE,
  REGISTRATION_CREATE_REQUEST,
  REGISTRATION_CREATE_SUCCESS
} from '../actions/types';
import ApplicationError from '../../utils/ApplicationError';

const initialState = {
  error: ApplicationError.buildDefault(),
  isFetching: false
};

const registrationReducer = (state = initialState, action ) => {
  switch(action.type) {
    case REGISTRATION_CREATE_REQUEST:
      return {...state, isFetching: true }
    case REGISTRATION_CREATE_SUCCESS:
      return {...state, user: action.payload, isFetching: false, error: ApplicationError.buildDefault() }
    case REGISTRATION_CREATE_FAILURE:
      return {...state, isFetching: false, error: action.payload }

    default: 
      return state
  }
}

export default registrationReducer;