import {
  CREATE_USER_IMAGE_REQUEST,
  CREATE_USER_IMAGE_SUCCESS,
  CREATE_USER_IMAGE_FAILURE,
  UPDATE_USER_IMAGE_REQUEST,
  UPDATE_USER_IMAGE_SUCCESS,
  UPDATE_USER_IMAGE_FAILURE,
  DELETE_USER_IMAGE_REQUEST,
  DELETE_USER_IMAGE_SUCCESS,
  DELETE_USER_IMAGE_FAILURE,
} from "./types";
import Urls from "../../constants/Urls";
import { BEARER } from "../../utils/MyStore";

import ImageMapper from "../../mapper/ImageMapper";
import ResponseService from "../../utils/ResponseService";
import ApplicationError from "../../utils/ApplicationError";
import ErrorLogger from "../../utils/ErrorLogger";

export const createUserImageRequest = () => ({
  type: CREATE_USER_IMAGE_REQUEST,
});

export const createUserImageSuccess = (image) => ({
  type: CREATE_USER_IMAGE_SUCCESS,
  payload: image,
});

export const createUserImageFailure = (error) => ({
  type: CREATE_USER_IMAGE_FAILURE,
  payload: error,
});

export const updateUserImageRequest = () => ({
  type: UPDATE_USER_IMAGE_REQUEST,
});

export const updateUserImageSuccess = (image) => ({
  type: UPDATE_USER_IMAGE_SUCCESS,
  payload: image,
});

export const updateUserImageFailure = (error) => ({
  type: UPDATE_USER_IMAGE_FAILURE,
  payload: error,
});

export const deleteUserImageRequest = () => ({
  type: DELETE_USER_IMAGE_REQUEST,
});

export const deleteUserImageSuccess = () => ({
  type: DELETE_USER_IMAGE_SUCCESS,
});

export const deleteUserImageFailure = (error) => ({
  type: DELETE_USER_IMAGE_FAILURE,
  payload: error,
});

export const createUserImage = (exhibitionId,image) => {
  return async (dispatch) => {
    dispatch(createUserImageRequest());

    try {
      const route = Urls.serverUrl;
      const bearer = localStorage.getItem(BEARER);
      const headers = Urls.header.secure(bearer);
      const url = route + "upload";

      delete headers['Content-Type'];

      let body = FormData();
      body.append("files", image.getContent());
      body.append("ref",image.getAttachedToType());
      body.append("refId",image.getAttachedToId());
      body.append("field",image.getImageType())
      body.append("source","users-permissions");

      let response = await fetch(url, {
        method: 'POST',
        headers: headers,
        body: body,
      });

      let responseService = new ResponseService(ImageMapper);

      let action = await responseService.call(
        response,
        (value) => createUserImageSuccess(value),
        (error) => createUserImageFailure(error)
      );

      dispatch(action);
    } catch (error) {
      ErrorLogger.call(this, error)
      dispatch(
        createUserImageFailure(
          ApplicationError.buildApplicationError(error)
        )
      );
    }
  };
};

export const updateUserImage = (exhibitionId,image) => {
  return async (dispatch) => {
    dispatch(updateUserImageRequest());

    try {
      const route = Urls.serverUrl;
      const bearer = localStorage.getItem(BEARER);
      const headers = Urls.header.secure(bearer);
      const url = route + "upload";

      delete headers['Content-Type'];

      let body = new FormData();
      body.append("files", image.getContent());
      body.append("ref",image.getAttachedToType());
      body.append("refId",image.getAttachedToId());
      body.append("field",image.getImageType())
      body.append("source","users-permissions");

      let response = await fetch(url, {
        method: 'POST',
        headers: headers,
        body: body,
      });

      let responseService = new ResponseService(ImageMapper);

      let action = await responseService.call(
        response,
        (value) => updateUserImageSuccess(value),
        (error) => updateUserImageFailure(error)
      );

      dispatch(action);
    } catch (error) {
      ErrorLogger.call(this, error)
      dispatch(
        updateUserImageFailure(
          ApplicationError.buildApplicationError(error)
        )
      );
    }
  };
};

export const deleteUserImage = (exhibitionId,image) => {
  return async (dispatch) => {
    dispatch(deleteUserImageRequest());

    try {
      const route = Urls.users.images.delete;
      const bearer = localStorage.getItem(BEARER);
      const headers = Urls.header.secure(bearer);
      const url = Urls.build(route.url(exhibitionId,image.getAttachedToId(), image.getId()));

      let response = await fetch(url, {
        method: route.method,
        headers: headers,
      });

      let responseService = new ResponseService(ImageMapper);

      let action = await responseService.call(
        response,
        (value) => deleteUserImageSuccess(value),
        (error) => deleteUserImageFailure(error)
      );

      dispatch(action);
    } catch (error) {
      ErrorLogger.call(this, error)
      dispatch(
        deleteUserImageFailure(
          ApplicationError.buildApplicationError(error)
        )
      );
    }
  };
};
