import EventUserMapper from "../mapper/EventUserMapper";
import TagMapper from "../mapper/TagMapper";
import EventUser from "../models/EventUser";
import UserMapper from "./../mapper/UserMapper";


export const BEARER = "Authorization"
export const CURRENT_USER = "currentUser"
export const CURRENT_EVENT_USER = "currentEventUser"
export const LOCAL_STORAGE = "localeString"
export const TAGS = "tags"
export const VISITOR_VIEW = "visitorView"

export default class MyStore {
  
  static getCurrentUser = () => {
    let userString = localStorage.currentUser

    if(userString === undefined ) { return undefined }

    let userJson = JSON.parse( userString )

    if(userJson === null) { return undefined }

    return UserMapper.build(userJson)
  }

  static setCurrentUser = (user) => {
    localStorage.setItem(CURRENT_USER, UserMapper.toJsonString(user))
  }

  static getCurrentEventUser = () => {
    let eventUserString = localStorage.currentEventUser

    if (eventUserString === undefined ) { return undefined }

    let eventUserJson = JSON.parse( eventUserString )

    if(eventUserJson === null) { return undefined }

    return EventUserMapper.build(eventUserJson)
  }

  static setCurrentEventUser = (eventUser) => {
    localStorage.setItem(CURRENT_EVENT_USER, EventUserMapper.toJsonString(eventUser))
  }

  static getLocal = () => {
    return localStorage.getItem(LOCAL_STORAGE)
  }

  static setLocal = (locale) => {
    localStorage.setItem(LOCAL_STORAGE, locale)
  }

  static getVisitorView = () => {
    let visitorView = localStorage.getItem(VISITOR_VIEW);
    if (visitorView === undefined) {
      this.setVisitorView("true");
      visitorView = 'true';
    }
    return visitorView === "true" ? true:false
  }

  static setVisitorView = (visitorView) => {
    localStorage.setItem(VISITOR_VIEW, visitorView ? "true":"false")
  }

  static getTags = () => {
    if (!localStorage.tags || localStorage.tags.length === 0) {
      return []
    }
    return TagMapper.buildFromArray(JSON.parse(localStorage.tags))
  }

  static setTags = (tags) => {
    localStorage.setItem(TAGS, JSON.stringify(tags.map(tag => {return {name: tag.getName(), additional: tag.isAdditional()}})))
  }

  static removeAllKeys = () => {
    localStorage.removeItem(CURRENT_USER)
    localStorage.removeItem(CURRENT_EVENT_USER)
    localStorage.removeItem(BEARER)
  }
}
