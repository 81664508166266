export default {
  root: {
  },
  input: {
    padding: 10,

  },
  notchedOutline: {
    borderColor: 'rgba(0,0,0,0.15)'
  }
};
