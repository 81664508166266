import React, {useEffect, useState} from 'react'
import {Modal, Box, IconButton} from '@material-ui/core'
import CloseIcon from "@material-ui/icons/Close";
import ArrowLeft from '@material-ui/icons/ArrowBackIosRounded';
import ArrowRight from '@material-ui/icons/ArrowForwardIosRounded';
import useStyles from './useStyles'

const FullScreenImageView = ({pictures, currentImage, setCurrentImage}) => {
    const [isLeftDisabled, setIsLeftDisabled] = useState(false)
    const [isRightDisabled, setIsRightDisabled] = useState(false)
    const classes = useStyles()

    useEffect(() => {
        if(currentImage === 0){
            setIsLeftDisabled(true)
        }
        else{
            setIsLeftDisabled(false)  
        }
        if(currentImage === pictures.length - 1){
            setIsRightDisabled(true)
        }
        else{
            setIsRightDisabled(false)
        }
    }, [currentImage])

    const next = () => {
        if(currentImage < pictures.length && currentImage !== pictures.length - 1){
            setCurrentImage(currentImage + 1)
        }
    }

    const back = () => {
        if(currentImage > 0) {
            setCurrentImage(currentImage - 1)
        }
    }
    const handleCloseOnBackgroundClick = (event) => {
        if(event.target.id === 'background') {
            setCurrentImage(null)
        }
    }

    return (
        <Modal 
            open
            onclose={() => setCurrentImage(null)}
            onEscapeKeyDown={() => setCurrentImage(null)}
        >
            <Box id="background" display="flex" justifyContent="center" alignItems="center" width="100%" height="100%" onClick={handleCloseOnBackgroundClick}>
                <Box>
                    <IconButton onClick={() => back()} disabled={isLeftDisabled}>
                        <ArrowLeft/>
                    </IconButton>
                </Box>
                <Box display="flex">
                    <Box>
                        <img src={pictures[currentImage].getUrl("medium")}/>
                    </Box>
                    <Box>
                        <IconButton
                            className={classes.closeIcon}
                            onClick={() => setCurrentImage(null)}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Box>
                <Box>
                    <IconButton onClick={() => next()} disabled={isRightDisabled}>
                        <ArrowRight/>
                    </IconButton>
                </Box>
            </Box>
        </Modal>
    )
}

export default FullScreenImageView
