import { gql } from "@apollo/client";
//, user: { marks: { user: { id: $userId }}} 
export const getOnlineVisitorQueries = {
  feedQuery: gql`
    query($userIds: [String], $exhibitionId: Int!) {
      eventusers(
        sort: "last_name:asc"
        where: { user: { id_in: $userIds }, exhibition: {id: $exhibitionId} }
      ) {
        id
        online
        user {
          id
        }
      } 
    }
    `,
}

export const userQueries = {
  entityQuery: gql`
    query($userId: Int) {
        user(id: $userId) {
          id
          first_name
          last_name
          company_email
          compnay_name
          profession
          description
          search_text
          gender
          phone_number
          website_url
          linkedIn_url
          facebook_url
          twitter_url
          instagram_url
          profile_picture {
            name
            url
            formats
          }
          created_at
          updated_at
          role {
            id
            name
            type
            description
          }
          locale      
        }
    }
    `,
}

const visitorQueries = {
  feedQuery: gql`
    query($userId: Int, $bmUserId: Int, $tags: [String], $limit: Int, $start: Int, $exhibitionId: Int!, $searchText: String) {
      eventusers(
        sort: "last_name:asc"
        limit: $limit
        start: $start
        where: { _or: [
            {user: { compnay_name_contains: $searchText}},
            {user: { profession_contains: $searchText}}, 
            {user: { last_name_contains: $searchText}}, 
            {user: { first_name_contains: $searchText}},
            {user: { full_name_contains: $searchText}}
          ],
          fake_user: false,
          toc_accepted: true,
          tags: { name_in: $tags },
          exhibition: {id: $exhibitionId},
          user: {
            marks: {
              user: {id: $bmUserId}
            }
          },
        }
      ) {
        id
        online
        last_status_at
        user {
          id
          first_name
          last_name
          company_email
          compnay_name
          profession
          description
          profile_picture {
            name
            url
            formats
          }
          created_at
          updated_at
          role {
            id
            name
            type
            description
          }
          locale
          marks(where: { user: { id: $userId }}) {
            user {
              id
            }
            bookmark
            salesmark
          }       
        }
        tags {
          id
          name
        }
      }
      eventusersConnection(where: {_or: [
        {user: { compnay_name_contains: $searchText}},
        {user: { profession_contains: $searchText}}, 
        {user: { last_name_contains: $searchText}}, 
        {user: { first_name_contains: $searchText}},
        {user: { full_name_contains: $searchText}}
      ], exhibition: {id: $exhibitionId}}) 
      {
        aggregate {
          count
        }
      }
    }
    `,
  bookmarkedEntries: gql`
      query ($userId: Int!) {
          bookmarks(where: {user: { id: $userId }, sharable_type: "User"}) {
            id
            created_at
            bookmarkable
            sharable_id
            updated_at
            sharable_type
            name
          }
      }
    `,
  entityQuery: gql`
    query($userId: Int, $visitorId: ID!, $exhibitionId: Int) {
        user(id: $visitorId) {
          id
          first_name
          last_name
          company_email
          compnay_name
          profession
          description
          search_text
          gender
          phone_number
          website_url
          linkedIn_url
          facebook_url
          twitter_url
          instagram_url
          profile_picture {
            name
            url
            formats
          }
          created_at
          updated_at
          role {
            id
            name
            type
            description
          }
          locale
          marks(where: { user: { id: $userId }}) {
            user {
              id
            }
            bookmark
            salesmark
          }       
        }
        eventusers(where: {user: { id: $visitorId}, exhibition: {id: $exhibitionId}}) {
          id
          tags {
            id
            name
          } 
        }
    }
    `,
  entityUuidQuery: gql`
    query($userId: Int, $visitorUuid: String) {
        users(
          where: {
            user_uuid_eq: $visitorUuid
          }
        ) 
      {
        id
        first_name
        last_name
        company_email
        compnay_name
        profession
        description
        search_text
        gender
        phone_number
        website_url
        linkedIn_url
        facebook_url
        twitter_url
        instagram_url
        profile_picture {
          name
          url
          formats
        }
        created_at
        updated_at
        role {
          id
          name
          type
          description
        }
        locale
        marks(where: { user: { id: $userId }}) {
          user {
            id
          }
          bookmark
          salesmark
        }       
      }
    }
    `,
  entityEmailQuery: gql`
    query($userId: Int, $visitorEmail: String) {
        users(
          where: {
            email: $visitorEmail
          }
        ) 
      {
        id
        first_name
        last_name
        company_email
        compnay_name
        profession
        description
        search_text
        gender
        phone_number
        website_url
        linkedIn_url
        facebook_url
        twitter_url
        instagram_url
        profile_picture {
          name
          url
          formats
        }
        created_at
        updated_at
        role {
          id
          name
          type
          description
        }
        locale
        marks(where: { user: { id: $userId }}) {
          user {
            id
          }
          bookmark
          salesmark
        }       
      }
    }
    `,
}

export default visitorQueries
