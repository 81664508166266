import { Avatar, makeStyles } from "@material-ui/core";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import Message from "../../../../models/Message";
import GlobalContext from "../../Dashboard/context/GlobalContext";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    display: "flex",
  },
  chatAvatar: {
    height: "2rem",
    width: "2rem"
  },
  chatBody: {
    fontSize: ".78rem",
    textAlign: "justify",
    marginLeft: "8px",
    textOverflow: "ellipsis",
    height: "inherit",
    overflow: "hidden",
    position: "relative",
    "&::after": {
      content: '""',
      textAlign: "right",
      position: "absolute",
      bottom: 0,
      right: 0,
      width: "70%",
      height: "1.2em",
      background:
        "linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 70%)"
    }
  }
}));

const LbPreviewChatMessage: React.FC = () => {
  const classes = useStyles();
  const { lastGlobalMessage: message } = useContext(GlobalContext);
  const [t] = useTranslation();

  return !message ? null : (
    <div className={classes.root}>
      <Avatar
        className={classes.chatAvatar}
        src={message.getUser() && message.getUser().getProfilePicture()
          ? message.getUser().getProfilePicture().getUrl("thumbnail")
          : ""}
      />
      <div className={classes.chatBody}>
        <b>{message.isMe()
          ? t("frontend.me")
          : message.getUser().getFullName()
        }</b> {" "}{message.getText()}
      </div>
    </div>
  );
};

export default LbPreviewChatMessage;
