import { Typography, Grid, Box, LinearProgress, Button, styled, IconButton, CircularProgress } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/styles'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import NeumorphicStyle from '../../../../../constants/Neumorphic';
import MyStore from '../../../../../utils/MyStore';
import VisitorCard from '../../components/VisitorCard';
import { useVisitorByEmail, useVisitorByUuidQL } from '../../../../../engines/GraphQL/hooks/useVisitorsQL';
import useExhibition from '../../../../../engines/GeneralHooks/useExhibition';
import useNakamaServer from '../../../../../engines/CommunicationHooks/useNakamaServer/useNakamaServer';
import useVideoToken from '../../../../../engines/CommunicationHooks/useVideoToken/useVideoToken';
import VideoStateProvider, { useVideoState } from '../../../../state';
import useConnectionOptions from '../../../../components/Communications/utils/useConnectionOptions/useConnectionOptions';
import MenuBar from '../../../../components/Communications/Video/MenuBar/MenuBar';
import MobileTopMenuBar from '../../../../components/Communications/Video/MobileTopMenuBar/MobileTopMenuBar';
import ReconnectingNotification from '../../../../components/Communications/Video/ReconnectingNotification/ReconnectingNotification';
import RecordingNotifications from '../../../../components/Communications/Video/RecordingNotifications/RecordingNotifications';
import Room from '../../../../components/Communications/Video/Room/Room';
import useRoomState from '../../../../../engines/CommunicationHooks/useRoomState/useRoomState';
import useVideoContext from '../../../../../engines/CommunicationHooks/useVideoContext/useVideoContext';
import useLocalTracks from '../../../../components/Communications/Video/VideoProvider/useLocalTracks/useLocalTracks';
import GlobalContext from '../../context/GlobalContext';
import { MODAL_TYPE } from '../GlobalModal';
import Close from '@material-ui/icons/Close';
import { Info } from '@material-ui/icons';
import LbDynamicVisitorCard from './LbDynamicVisitorCard';
import useLocalAudioToggle from '../../../../../engines/CommunicationHooks/useLocalAudioToggle/useLocalAudioToggle';
import useLocalVideoToggle from '../../../../../engines/CommunicationHooks/useLocalVideoToggle/useLocalVideoToggle';
import { MatchmakingContext, MatchmakingState } from '../../../../components/Communications/Matchmaking/MatchmakingProvider';
import LbVideoTimer from './LbVideoTimer';

const useStyles = makeStyles(theme => ({
    root: {
        borderRadius: 'inherit',
        '& .MuiPopover-root': {
            zIndex: 13000,
        }
    },
    title: {
        fontSize: 'clamp(1rem, 3vw, 1.5rem)',
        position: 'absolute',
        left: '1em',
        top: '1em',
        color: "white",
        lineHeight: "1.1em"
    },
    timerContainer: {
        position: 'absolute',
        display: 'grid',
        alignItems: "center",
        justifyItems: 'center',
        left: '0',
        top: '1em',
        right: '0',
    },
    videoBox: {
        marginLeft: "auto",
        marginRight: "auto",
        overflow: "hidden",
        backgroundColor: "black",
        width: "90%",
        paddingTop: "calc(50.625% + 72px)",
        boxShadow: NeumorphicStyle.boxShadowMedium(theme.palette.primary.main, theme.palette.secondary.dark),
        borderRadius: NeumorphicStyle.borderRadius,
        position: "relative",
        minWidth: "200px",
        minHeight: "112.5px",
        //zIndex: 500,
        height: 0,
    },
    video: {
        width: "100%",
        height: "100%",
        borderRadius: "inherit",
        border: "none",
        left: 0,
        top: 0,
        position: "absolute",
        objectFit: "cover"
    },
    infoBox: {
        position: 'absolute',
        fontSize: 'clamp(.5rem,2vh,1.5rem)',
        height: '2em',
        width: '2em',
        top: "1.5em",
        right: "1.5em",
        overflow: 'hidden',
        backgroundColor: 'rgba(255,255,255,.8)',
        boxShadow: '1px 1px 4px 1px rgba(0,0,0,0.2)',
        color: "black",
        borderRadius: NeumorphicStyle.borderRadius,
        transition: 'all 200ms ease',
        '&:hover': {
            width: '50%',
            height: '13em',
            transformOrigin: 'top right',
            borderRadius: NeumorphicStyle.borderRadius,
            backgroundColor: 'rgba(255,255,255,.95)',
        },
        zIndex: 50,
    },
    infoIconContainer: {
        width: '100%',
        display: 'flex',
        padding: '.27em',
    },
    infoIcon: {
        marginLeft: 'auto',
        width: '1em',
        fontSize: '1.5em',
        color: 'rgba(0,0,0,0.7)',
    },
    helpContainer: {
        padding: '0 1.25em 1.25em 1.25em',
        fontSize: '1em',
        lineHeight: '1.2em',
        zIndex: 50,
    },
    matchmakingContainer: {
        width: "100%",
    },
    closeIcon: {
        position: "absolute",
        top: "1.5rem",
        right: "1rem",
        marginRight: 8,
        background: theme.palette.background.default,
        zIndex: 800,
    },
    buttonContainer: {
        left: '50%',
        bottom: 'calc(clamp(1.25rem, 2vh, 3rem) + 72px)',
        position: 'absolute',
        zIndex: 50,
    },
    startButton: {
        left: '-50%',
        fontSize: 'clamp(.5rem,2vw,1.25rem)',
        position: 'relative',
        boxShadow: '1px 1px 4px 1px rgba(0,0,0,0.2)',
        borderRadius: NeumorphicStyle.borderRadius,
        padding: '1em',
        background: 'rgba(255,255,255,.8)',
        '&:hover': {
            background: 'rgba(255,255,255,.95)',
        }
    },
    skipButton: {
        left: '-50%',
        fontSize: 'clamp(.5rem,2vw,1.25rem)',
        position: 'relative',
        boxShadow: '1px 1px 4px 1px rgba(0,0,0,0.2)',
        borderRadius: NeumorphicStyle.borderRadius,
        padding: '1em',
        background: 'rgba(255,255,255,.8)',
        '&:hover': {
            background: 'rgba(255,255,255,.95)',
        }
    },
    visitorCardContainer: {
        fontSize: 'clamp(1rem, 3vw, 1.5rem)',
        position: 'absolute',
        left: '1em',
        bottom: 'calc(.8em + 72px)',
        zIndex: 3,
    }
}))

const Container = styled('div')({
    display: 'grid',
    gridTemplateRows: '1fr auto',
});

const Main = styled('main')(({ theme }) => ({
    overflow: 'hidden',
    paddingBottom: `${theme.footerHeight}px`, // Leave some space for the footer
    background: 'red',
    [theme.breakpoints.down('sm')]: {
        paddingBottom: `${theme.mobileFooterHeight + theme.mobileTopBarHeight}px`, // Leave some space for the mobile header and footer
    },
}));

function MatchMaking() {
    const classes = useStyles();
    const theme = useTheme();
    const context = useContext(GlobalContext);
    const { match, disconnectNakamaSession, isLoadingNakamaSession, ticket, startMatchmaking, roomId, matchmakingState, onVideoConnected, matchedUsername } = useContext(MatchmakingContext);
    const [exhibition] = useExhibition();

    const [visitor, loading, fetchNewData] = useVisitorByEmail(matchedUsername);
    const roomState = useRoomState();
    const { getToken, isFetching: isFetchingToken } = useVideoState();
    const { room, connect: videoConnect, isAcquiringLocalTracks, isConnecting, getLocalAudioTrack, getLocalVideoTrack, localTracks } = useVideoContext();
    const [isAudioEnabled, toggleAudioEnabled] = useLocalAudioToggle();
    const [isVideoEnabled, toggleVideoEnabled] = useLocalVideoToggle();
    const [videoTrack, setVideoTrack] = useState();
    const [audioTrack, setAudioTrack] = useState();

    const roomRef = useRef();

    useEffect(() => {
        roomRef.current = room;
    }, [room])

    useEffect(() => {
        if (!roomId || roomState === 'connected') {
            if (roomState === 'connected') {
                disconnectVideo();
            }
            return;
        }

        getToken(MyStore.getCurrentUser().getEmail(), roomId).then(res => {
            videoConnect(res.token);
        })
    }, [roomId])

    useEffect(() => {
        if (isConnecting || !room || !match) {
            return;
        }

        if (!isVideoEnabled) {
            getLocalVideoTrack().then((track) => {
                setVideoTrack(track);
                toggleVideoEnabled();
                room.localParticipant.publishTrack(track);
            })
        }
        if (!isAudioEnabled) {
            getLocalAudioTrack().then((track) => {
                setAudioTrack(track);
                toggleAudioEnabled();
                room.localParticipant.publishTrack(track);
            }).catch(e => {
                console.log(e);
            })
        }
        onVideoConnected();
    }, [isConnecting, room, match])

    useEffect(() => {
        if (matchmakingState !== MatchmakingState.connected) {
            disconnectVideo();
        }
    }, [matchmakingState])

    const disconnectVideo = () => {
        if (localTracks) {
            localTracks.forEach(track => {
                track.stop();
            })
        }
        if (room) {
            room.localParticipant.tracks.forEach((track) => {
                track.track.stop();
            })
            room.disconnect();
        }
        audioTrack?.stop();
        videoTrack?.stop();
    }

    const disconnectSession = async (skip = false) => {
        disconnectVideo();
        await disconnectNakamaSession(skip);
    }
    const skipPartner = () => {
        disconnectSession(true);
    }

    return (
        <div className={classes.root}>
            <div onClick={(e) => e.stopPropagation()}>
                <IconButton
                    className={classes.closeIcon}
                    onClick={() => {
                        disconnectSession();
                        context.closeGlobalModal();
                    }}
                >
                    <Close />
                </IconButton>
                <Grid item xs={12}>
                    <div className={classes.videoBox}>
                        {roomState === 'connected' && (
                            <Main>
                                <ReconnectingNotification />
                                <RecordingNotifications />
                                <MobileTopMenuBar />
                                <Room />
                                <MenuBar matchmaking={true} />
                            </Main>
                        )}
                        <Typography className={classes.title} variant="h2"><Trans>frontend.pages.speeddating.title</Trans></Typography>
                        {matchmakingState === MatchmakingState.connected && <div className={classes.timerContainer}>
                            <LbVideoTimer
                                active={matchmakingState === MatchmakingState.connected}
                                milliseconds={
                                    (
                                        exhibition && exhibition.getMatchmakingLimitMinutes() && exhibition.getMatchmakingLimitMinutes() > 0 ?
                                            exhibition.getMatchmakingLimitMinutes() :
                                            3
                                    ) * 60000
                                }
                                roomId={roomId}
                                onTimerEnd={() => {
                                    if (matchmakingState === MatchmakingState.connected){
                                        disconnectSession(true)
                                    }
                                }}
                            />
                        </div>}
                        <div className={classes.buttonContainer}>
                            {matchmakingState === MatchmakingState.searching && (
                                <CircularProgress />
                            )}
                            {(matchmakingState === MatchmakingState.initializing ||
                                matchmakingState === MatchmakingState.waiting) && (
                                    <Button disabled={matchmakingState === MatchmakingState.initializing} className={classes.startButton} onClick={() => startMatchmaking()}>Start Search</Button>
                                )}
                            {matchmakingState === MatchmakingState.connected && (
                                <Button className={classes.skipButton} onClick={() => skipPartner()}>Skip Partner</Button>
                            )}
                        </div>
                        <div className={classes.infoBox}>
                            <div className={classes.infoIconContainer}>
                                <Info className={classes.infoIcon} />
                            </div>
                            <div className={classes.helpContainer}>
                                <Trans>frontend.pages.speeddating.hint_1</Trans><br />
                                <Trans>frontend.pages.speeddating.hint_2</Trans><br />
                                <Trans>frontend.pages.speeddating.hint_3</Trans>
                            </div>
                        </div>
                        {visitor && (
                            <div className={classes.visitorCardContainer}>
                                <LbDynamicVisitorCard visitor={visitor} />
                            </div>
                        )}
                    </div>
                </Grid>
            </div>
        </div>
    )
}

export default MatchMaking


// {
//     "ticket":"0df38be8-7743-4e2e-9190-f1be31c79a19",
//     "token":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE2MzA0ODM2MTIsIm1pZCI6IjZmMmU4NDkzLTc3ZTMtNDMwMC1iMjEwLTMwMThhY2JkYzdkNi4ifQ.BDnxecLro8eXTULyqzA_6dQ0jdwsEV_oga6zksftwoc",
//     "users":[
//         {"presence": {
//             "user_id":"76328c28-ca26-41aa-8595-31190bd937c2",
//             "session_id":"72835eb9-0afb-11ec-a55b-7106fdcb5b46",
//             "username":"eddi@leadevents.io"
//         },
//         "string_properties": {
//             "region":"europe"
//         },
//         "numeric_properties":{
//             "rank":8
//         }},
//         {"presence":{
//             "user_id":"b106b4d2-230e-420d-bbc1-3a3cef4434d4",
//             "session_id":"6a5f85bf-0afb-11ec-a55b-7106fdcb5b46",
//             "username":"eddi@leadbase.de"
//         },
//         "string_properties":{
//             "region":"europe"
//         },
//         "numeric_properties":{
//             "rank":8
//         }}
//     ],
//     "self": {
//         "presence": {
//             "user_id":"76328c28-ca26-41aa-8595-31190bd937c2",
//             "session_id":"72835eb9-0afb-11ec-a55b-7106fdcb5b46",
//             "username":"eddi@leadevents.io"
//         },
//         "string_properties": {
//             "region":"europe"
//         },
//         "numeric_properties": {
//             "rank":8
//         }
//     }
// }