import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles((theme) => ({
    closeIcon: {
        position: "absolute",
        right: "25%",
        background: theme.palette.background.default,
        zIndex: 800,
        cursor: "pointer"
    },
    hiddenOverflow: {
        overflow: "hidden"
    },
    close:{
        position: "absolute",
        right: "25%",
        top: "30%"
    }
}))

export default useStyles;