import React, { useState, useEffect, useContext } from 'react'
import { Modal, Backdrop, Card, CircularProgress, Typography } from '@material-ui/core'
import { createMeeting } from '../../../../redux/actions/MeetingsActions';
import { useSelector, useDispatch } from 'react-redux';
import Meeting from '../../../../models/Meeting';
import MyStore from '../../../../utils/MyStore';
import PushNotificationCable from '../../../../actioncable/PushNotificationCable';
import PushNotification from '../../../../models/PushNotification';
import PushNotificationMapper from "../../../../mapper/PushNotificationMapper";
import FrontendRoutes from '../../../../constants/FrontendRoutes';
import { makeStyles } from '@material-ui/styles';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useRawPrivatePushNotification, useRawPushNotification } from '../../../../engines/Notification/NotificationEngine';
import GlobalContext from '../../../layouts/Dashboard/context/GlobalContext';

const useStyles = makeStyles((theme) => ({
    root: {
        display: "grid",
        alignItems: "center",
        justifyContent: "center",
    },
    modal: {
        maxHeight: "720px",
        maxWidth: "1000px",
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: "2px solid #000",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}));

function VideoModal({ open, handleClose, chatUser }) {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();

    const [requested, setRequested] = useState(false);
    const [incomingPush] = useRawPrivatePushNotification();
    const context = useContext(GlobalContext);

    const exhibitions = useSelector(state => state.exhibitionsReducer);

    useEffect(() => {
        if (!incomingPush ||
            !(
                incomingPush.getNotificationType() === PushNotification.NOTIFICATION_TYPES.meetingAccepted ||
                incomingPush.getNotificationType() === PushNotification.NOTIFICATION_TYPES.meetingDeclined)
        ) {
            return
        }


        if (incomingPush.getNotificationType() === PushNotification.NOTIFICATION_TYPES.meetingDeclined) {
            handleClose();
        } else {
            context.setMeetingId(incomingPush.getPayload().getId());
            handleClose();
        }
    }, [incomingPush])

    useEffect(() => {
        if (!open || !chatUser) {
            return;
        }

        let selectedTimeSlot = new Date();
        let startAt = selectedTimeSlot;
        selectedTimeSlot.setMinutes(selectedTimeSlot.getMinutes() + 15)
        let endAt = selectedTimeSlot

        var meeting = new Meeting({
            title: t("frontend.your_personal_meeting_with") + " " + chatUser.state.firstName  + " " + chatUser.state.lastName, //+ chatUser.getFullName(),
            startAt: startAt,
            endAt: endAt,
            reason: Meeting.reason.CHAT_ONE_TO_ONE,
            user: MyStore.getCurrentUser(),
            participants: [
                chatUser
            ],
            exhibition: exhibitions.exhibition,
        })

        dispatch(createMeeting(meeting));
    }, [open, chatUser])

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.root}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Card
                className={classes.paper}
            >
                <Typography variant="h5"><Trans>frontend.video_call_sent</Trans></Typography>
                {requested ? (<CircularProgress />) : (<div />)}
            </Card>
        </Modal>
    )
}

export default VideoModal
