import { useMediaQuery } from "@material-ui/core";
import { useEffect, useState } from "react";
import parser from 'ua-parser-js';

export const BREAKPOINT = {
    xs: 1,
    sm: 2,
    md: 3,
    mdx: 4,
    lg: 5
} 

function useBreakpoints() {
    const [breakpoint, setBreakpoint] = useState(BREAKPOINT.lg);

    const xsSize = useMediaQuery('(min-width: 400px)')
    const smSize = useMediaQuery('(min-width: 600px)')
    const mdSize = useMediaQuery('(min-width: 900px)')
    const mdxSize = useMediaQuery('(min-width: 1200px)')
    const lgSize = useMediaQuery('(min-width: 1400px)')

    useEffect(() => {
        if (lgSize) {
            setBreakpoint(BREAKPOINT.lg);
            return;
        }
        if (mdxSize) {
            setBreakpoint(BREAKPOINT.mdx);
            return;
        }
        if (mdSize) {
            setBreakpoint(BREAKPOINT.md);
            return;
        }
        if (smSize) {
            setBreakpoint(BREAKPOINT.sm);
            return;
        }
        if (xsSize) {
            setBreakpoint(BREAKPOINT.xs);
        }
    }, [xsSize, smSize, mdSize, mdxSize, lgSize])
    
    return [breakpoint];
}

export function useBlockScreen() {
    const [breakpoint] = useBreakpoints();
    const [block, setBlock] = useState(false);
    const [rotatePossible, setRotatePossible] = useState(false);
    const enoughHeight = useMediaQuery('(min-height: 900px)')

    useEffect(() => {
        if (breakpoint <= 2) {
            setBlock(true);
        }
    }, [breakpoint])

    useEffect(() => {
        if (block && enoughHeight) {
            const { device: { type } } = parser();
            setRotatePossible(type === "tablet");
        } else {
            setRotatePossible(false);
        }
    }, [enoughHeight])

    return [block,rotatePossible];
}

export default useBreakpoints
