import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import moment from "moment";
import { makeStyles } from "@material-ui/styles";
import {
  Card,
  CardHeader,
  CardContent,
  TextField,
  Typography,
  Select,
  FormControl,
  InputLabel,
} from "@material-ui/core";
import { MenuItem } from "react-contextmenu";
import { Trans, useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {},
  alert: {
    marginBottom: theme.spacing(3),
  },
  formGroup: {
    marginBottom: theme.spacing(3),
  },
  fieldGroup: {
    display: "flex",
    alignItems: "center",
  },
  fieldHint: {
    margin: theme.spacing(1, 0),
  },
  tags: {
    marginTop: theme.spacing(1),
    "& > * + *": {
      marginLeft: theme.spacing(1),
    },
  },
  flexGrow: {
    flexGrow: 1,
  },
  dateField: {
    "& + &": {
      marginLeft: theme.spacing(2),
    },
  },
}));

function AboutProject({ days, handleSelectDay, className, ...rest }) {
  const classes = useStyles();
  const [value, setValue] = useState();
  const { t } = useTranslation();

  const handleFieldChange = (event, selected) => {
    event.persist();
    handleSelectDay(selected)
    setValue(selected);
  };

  useEffect(() => {
    if(days.length === 1) {
      // setValue(days[0])
      // handleSelectDay(days[0])
    }
  }, [days])

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader title={t("frontend.which_day")} />
      <CardContent>
        <FormControl className={classes.formControl} style={{width:"100%"}}>
          <InputLabel htmlFor="agent-simple"><Trans>frontend.day</Trans></InputLabel>
            <Select
                value={value}
                onChange={(event) =>
                  handleFieldChange(event, event.target.value)
                }
                inputProps={{
                  name: "agent",
                  id: "age-simple"
                }}
              >
              {days.map((day, index) => {
                return <MenuItem value={day}>{day.getDate().toLocaleDateString()}</MenuItem>;
              })}
            </Select>
        </FormControl>
      </CardContent>
    </Card>
  );
}

AboutProject.propTypes = {
  className: PropTypes.string,
};

export default AboutProject;
