import {
  FETCHING_EXHIBITION_NOTIFICATIONS_REQUEST,
  FETCHING_EXHIBITION_NOTIFICATIONS_SUCCESS,
  FETCHING_EXHIBITION_NOTIFICATIONS_FAILURE
} from '../actions/types';
import ResponseService from '../../utils/ResponseService';
import ApplicationError from '../../utils/ApplicationError';

const initialState = {
  notifications: [],
  error: ApplicationError.buildDefault(),
  isFetching: false,
  ...ResponseService.defaultPaginationMetaData()
};

const notificationsReducer = (state = initialState, action ) => {
  switch(action.type) {

    // Get All Notification
    case FETCHING_EXHIBITION_NOTIFICATIONS_REQUEST:
      return {...state, isFetching: true }
    case FETCHING_EXHIBITION_NOTIFICATIONS_SUCCESS:
      return {...state, isFetching: false, 
        notifications: action.payload.objects, 
        error: ApplicationError.buildDefault(),
        ...action.payload.paginationData }

    case FETCHING_EXHIBITION_NOTIFICATIONS_FAILURE:
      return {...state, isFetching: false, error: action.payload }

    default: 
      return state
  }
}

export default notificationsReducer;