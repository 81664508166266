import { Hidden, IconButton, makeStyles } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import React, { useContext, useState } from "react";
import VideoBox from "../../Dashboard/components/VideoBox";
import GlobalContext from "../../Dashboard/context/GlobalContext";
import PublicChatBar from "../../Dashboard/PublicChat/PublicChatBar";
import LbMobilePublicChatBar from "./LbMobilePublicChatBar";
import LbMobileVideoBox from "./LbMobileVideoBox";
import LbPreviewChatMessage from "./LbPreviewChatMessage";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "rgba(255,255,255,1)",
    position: "fixed",
    left: "1.5rem",
    bottom: "4rem",
    right: "1.5rem",
    alignItems: "center",
    display: "inline-flex",
    paddingTop: 0,
    height: "4rem",
    boxShadow: "1px 1px 8px 2px rgba(90,90,90,0.4)",
    transition: "height .2s, bottom .2s, left .2s, right .2s",
  },
  rootFull: {
    backgroundColor: "rgba(255,255,255,1)",
    position: "fixed",
    left: "0.5rem",
    bottom: "0.5rem",
    right: "0.5rem",
    alignItems: "start",
    display: "flex",
    flexDirection: "column",
    paddingTop: 0,
    height: "calc(100% - 1rem)",
    boxShadow: "1px 1px 8px 2px rgba(90,90,90,0.4)",
    zIndex: 5,
    transition: "height .2s, bottom .2s, left .2s, right .2s",
    overflow: "hidden"
  },
  videoContainer: {
    backgroundColor: "purple",
    paddingBottom: "4rem",
    width: "7.1rem",
    flexShrink: 0,
    transition: "width .1s, padding-bottom .1s linear .06s",
    position: "relative",
  },
  videoContainerFull: {
    backgroundColor: "purple",
    paddingBottom: "56.25%",
    width: "100%",
    flexShrink: 0,
    transition: "width .1s, padding-bottom .2s",
    position: "relative",
  },
  chatPreviewContainer: {
    padding: "0.5rem",
    width: "auto",
    flexGrow: 1,
    height: "3rem"
  },
  chatMessageContainer: {
    width: "100%",
    height: "100%",
    maxHeight: "calc(100% - 56.25vw + 1rem)",
  },
  chatAvatar: {
    height: "3rem",
    width: "3rem"
  },
  iconButtonArea: {
    width: "auto",
    borderRadius: "inherit",
    background: "rgba(255,255,255,0.7)",
    color: "black",
    position: "absolute",
    right: 8,
    top: 8,
    padding: "4px"
  },
  iconButtonClose: {
    padding: "4px"
  },
}));

const LbStreamContainer: React.FC = () => {
  const classes = useStyles();
  const [fullView, setFullView] = useState(false);
  const context = useContext(GlobalContext);

  return (
    <div
      className={fullView ? classes.rootFull : classes.root}
      onClick={(e) => {
        if (!fullView) {
          setFullView((fullView) => !fullView);
        }
        e.stopPropagation();
      }}
    >
      <div
        className={
          fullView ? classes.videoContainerFull : classes.videoContainer
        }
      >
        <LbMobileVideoBox setKeynoteConversation={(context as any).setKeynoteConversation} setParentVideoMode={(context as any).setVideoMode} />
      </div>
      <div className={classes.chatMessageContainer} style={{ height: fullView ? "100%":"0px", width: fullView ? "100%":"0px"}}>
        <LbMobilePublicChatBar
          keynoteConversation={context.keynoteConversation}
          detailView={fullView}
          chatIndex={2}
          setChatIndex={() => { }}
        />
      </div>
      {!fullView && context.lastGlobalMessage && (
        <div className={classes.chatPreviewContainer}>
          <LbPreviewChatMessage />
        </div>
      )}
      {fullView && (
        <div className={classes.iconButtonArea}>
          <IconButton className={classes.iconButtonClose} onClick={() => { setFullView(false); }}>
            <Close />
          </IconButton>
        </div>
      )}
    </div>
  );
};

export default LbStreamContainer;
