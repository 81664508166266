import Exhibitor from "../models/Exhibitor";
import BaseMapper from "./BaseMapper";
import DocumentMapper from "./DocumentMapper";
import ImageMapper from "./ImageMapper";
import LocalTextMapper from "./LocalTextMapper";
import TagMapper from "./TagMapper";
import UserMapper from "./UserMapper";
import VideoMapper from "./VideoMapper";

const attributes = Exhibitor.attributes;

export default class ExhibitorMapper {
  static build = (json = undefined, bookmark = undefined) => {
    if (!json) {
      return;
    }

    return new Exhibitor({
      ...BaseMapper.attributes(json),
      name: json[attributes.name],
      usersCount: json[attributes.usersCount],
      shortDescription: json[attributes.shortDescription],
      companyWebsite: json[attributes.companyWebsite],
      category: json[attributes.category],
      profilePicture: ImageMapper.build(json.profile_picture),
      headerPicture: ImageMapper.build(json.header_picture),
      profileThumb: ImageMapper.build(json.profile_thumb),
      isBookmarked:
        json.marks && json.marks.length > 0 && json.marks[0].bookmark
          ? true
          : false,
      bookmark:
        json.marks && json.marks.length > 0 && json.marks[0].bookmark
          ? json.marks[0]
          : undefined,
      salesmark:
        json.marks && json.marks.length > 0 && json.marks[0].salesmark
          ? json.marks[0]
          : undefined,
      isSalesmarked:
        json.marks && json.marks.length > 0 && json.marks[0].salesmark
          ? true
          : false,
      sponsor: json[attributes.sponsor],
      exhibitionId: json[attributes.exhibitionId],
      sponsorName: json[attributes.sponsorName],
      bookingUrl: json[attributes.bookingUrl],
      profileVideo: VideoMapper.build(json[attributes.profileVideo]),
      documents: DocumentMapper.buildFromArray(json[attributes.documents]),
      users: UserMapper.buildFromArray(json[attributes.users]),
      localDescription: LocalTextMapper.buildFromArray(json.local_description),
      title: json.title,
      pictures: ImageMapper.buildFromArray(json.pictures),
      tags: TagMapper.buildFromArray(json.tags),
      instagramUrl: json[attributes.instagramUrl],
      linkedInUrl: json[attributes.linkedInUrl],
      facebookUrl: json[attributes.facebookUrl],
      twitterUrl: json[attributes.twitterUrl],
      FC_Module: json[attributes.FC_Module],
      yearOfEstablishment: json.date_of_establishment,
      badge: json[attributes.badge],
      priceInfo: json[attributes.priceInfo],
      exhibitorMinPrice: json[attributes.exhibitorMinPrice],
      exhibitorMaxPrice: json[attributes.exhibitorMaxPrice],
      createAt: json[attributes.createAt],
      factSheet: json[attributes.factSheet],
      hostProducts: json[attributes.hostProducts],
    });
  };

  static buildFromArray = (jsonArray = [], bookmarks = []) => {
    return jsonArray.map((entry) => {
      const bm = bookmarks.find((b) => b.getBookmarkableId() === entry.id);
      return this.build(entry, bm);
    });
  };

  static toJsonString(exhibitor) {
    return JSON.stringify({
      name: exhibitor.getName(),
      [attributes.shortDescription]: exhibitor.getShortDescription(),
      [attributes.companyWebsite]: exhibitor.getCompanyWebsite(),
      [attributes.sponsor]: exhibitor.getSponsor(),
      [attributes.sponsorName]: exhibitor.getSponsorName(),
    });
  }
}
