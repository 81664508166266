import ApplicationModel from "./ApplicationModel";


export default class LocalName extends ApplicationModel {

  constructor(_opts = {}){
    super(_opts)
    this.state = { ..._opts }
  }

  getLanguage = () => this.state.language
  getName = () => this.state.name
  

  // Backend Attributes
  static attributes = {
    ...super.attributes,
    language: "language",
    name: "name",
  }

  static orderByParams = {
    ...super.orderByParams
  }

  static filterParams = {
    ...super.filterParams,
  }
}