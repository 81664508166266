import { gql } from "@apollo/client";
//, user: { marks: { user: { id: $userId }}} 
const commentsQueries = {
  feedQuery: gql`
    query($postId: String, $limit: Int, $start: Int) {
      comments(
        sort: "updated_at:desc"
        limit: $limit
        start: $start
        where: { post: { id: $postId}},
      ) {
        id
        text
        created_at
        updated_at
        exhibition { id }
        user {
          id
          first_name
          last_name
          profile_picture {
            name
            url
            formats
          }
          created_at
          updated_at
        }
        comment_likes {
          id
          user {
            id
          }
          created_at
          updated_at
        }
        post {
          id
        }
      }
      commentsConnection(where: { post: { id: $postId}}) {aggregate {count}}
    }
    `,
}

export default commentsQueries