import { colors } from '@material-ui/core';

export default {
  contained: {
    fontFamily: "Lato",
    boxShadow: '0 1px 1px 0 rgba(0,0,0,0.14)',
    paddingLeft: "40px",
    paddingBottom: "12px",
    paddingTop: "12px",
    paddingRight: "40px",
    borderRadius: "3px",
    backgroundColor: colors.grey[100],
    textTransform: "none",
    fontWeight: "bolder",
    '&:hover': {
      backgroundColor: colors.grey[300]
    }
  },
};
