import {
  FETCHING_EXHIBITION_MEETINGS_REQUEST,
  FETCHING_EXHIBITION_MEETINGS_SUCCESS,
  FETCHING_EXHIBITION_MEETINGS_FAILURE,

  FETCHING_EXHIBITION_MEETING_REQUEST,
  FETCHING_EXHIBITION_MEETING_SUCCESS,
  FETCHING_EXHIBITION_MEETING_FAILURE,

  CREATE_EXHIBITION_MEETING_REQUEST,
  CREATE_EXHIBITION_MEETING_SUCCESS,
  CREATE_EXHIBITION_MEETING_FAILURE,

  UPDATE_EXHIBITION_MEETING_REQUEST,
  UPDATE_EXHIBITION_MEETING_SUCCESS,
  UPDATE_EXHIBITION_MEETING_FAILURE,

  DELETE_EXHIBITION_MEETING_REQUEST,
  DELETE_EXHIBITION_MEETING_SUCCESS,
  DELETE_EXHIBITION_MEETING_FAILURE,

} from './types';
import Urls, { DEFAULT_OPTIONAL_PARAMETERS } from '../../constants/Urls';
import { BEARER } from '../../utils/MyStore';
import MeetingMapper from '../../mapper/MeetingMapper';
import ResponseService from '../../utils/ResponseService';
import ApplicationError from '../../utils/ApplicationError';
import ErrorLogger from '../../utils/ErrorLogger';

export const fetchingMeetingsRequest = () => ({
  type: FETCHING_EXHIBITION_MEETINGS_REQUEST
})

export const fetchingMeetingsSuccess = (json) => ({
  type: FETCHING_EXHIBITION_MEETINGS_SUCCESS,
  payload: json
})
export const fetchingMeetingsFailure = (error) => ({
  type: FETCHING_EXHIBITION_MEETINGS_FAILURE,
  payload: error
})

export const fetchingMeetingRequest = () => ({
  type: FETCHING_EXHIBITION_MEETING_REQUEST
})

export const fetchingMeetingSuccess = (meeting) => ({
  type: FETCHING_EXHIBITION_MEETING_SUCCESS,
  payload: meeting
})
export const fetchingMeetingFailure = (error) => ({
  type: FETCHING_EXHIBITION_MEETING_FAILURE,
  payload: error
})

export const createMeetingRequest = () => ({
  type: CREATE_EXHIBITION_MEETING_REQUEST
})

export const createMeetingSuccess = (meeting) => ({
  type: CREATE_EXHIBITION_MEETING_SUCCESS,
  payload: meeting
})

export const createMeetingFailure = (error) => ({
  type: CREATE_EXHIBITION_MEETING_FAILURE,
  payload: error
})

export const updateMeetingRequest = () => ({
  type: UPDATE_EXHIBITION_MEETING_REQUEST
})

export const updateMeetingSuccess = (meeting) => ({
  type: UPDATE_EXHIBITION_MEETING_SUCCESS,
  payload: meeting
})

export const updateMeetingFailure = (error) => ({
  type: UPDATE_EXHIBITION_MEETING_FAILURE,
  payload: error
})

export const deleteMeetingRequest = () => ({
  type: DELETE_EXHIBITION_MEETING_REQUEST
})

export const deleteMeetingSuccess = () => ({
  type: DELETE_EXHIBITION_MEETING_SUCCESS
})

export const deleteMeetingFailure = (error) => ({
  type: DELETE_EXHIBITION_MEETING_FAILURE,
  payload: error
})

export const fetchMeetings = (exhibitionId, _opts = DEFAULT_OPTIONAL_PARAMETERS) => {
  return async dispatch => {
    dispatch(fetchingMeetingsRequest());
    
    try {
      const route   = Urls.exhibitions.meetings.all
      const bearer  = localStorage.getItem(BEARER)
      const headers = Urls.header.secure(bearer)
      const url     = Urls.build(route.url(exhibitionId, _opts.pageIndex, _opts.pageSize), _opts.filters)

      let response = await fetch(url, {
        method: route.method,
        headers: headers
      });

      let responseService = new ResponseService(MeetingMapper)


      let action = await responseService.call(response, 
        (value) => fetchingMeetingsSuccess(value),
        (error) => fetchingMeetingsFailure(error))

      dispatch(action)  
    } catch(error) {
      ErrorLogger.call(this, error)
      dispatch(fetchingMeetingsFailure(ApplicationError.buildApplicationError(error)))
    }
  };
};

export const fetchMeeting = (exhibitionId, id) => {
  return async dispatch => {
    dispatch(fetchingMeetingRequest());

    try {
      const route = Urls.exhibitions.meetings.show;
      const bearer = localStorage.getItem(BEARER)
      const headers = Urls.header.secure(bearer);

      let response = await fetch(route.url(exhibitionId, id), {
        method: route.method,
        headers: headers
      })

      let responseService = new ResponseService(MeetingMapper)

      let action = await responseService.call(response, 
        (value) => fetchingMeetingSuccess(value),
        (error) => fetchingMeetingFailure(error))

      dispatch(action)  

    } catch(error) {
      ErrorLogger.call(this, error)
      dispatch(fetchingMeetingFailure(error))
    }
  }
}

export const createMeeting = (meeting) => {
  return async dispatch => {
    dispatch(createMeetingRequest());
    try {

      const route = Urls.exhibitions.meetings.create
      const bearer = localStorage.getItem(BEARER)
      const headers = Urls.header.secure(bearer)

      let response = await fetch(route.url(meeting.getExhibitionId(), meeting.getId()), {
        method: route.method,
        headers: headers,
        body: MeetingMapper.toJsonString(meeting)
      });

      let responseService = new ResponseService(MeetingMapper)

      let action = await responseService.call(response, 
        (value) => createMeetingSuccess(value),
        (error) => createMeetingFailure(error))

      dispatch(action)  
    } catch(error) {
      ErrorLogger.call(this, error)
      dispatch(createMeetingFailure(ApplicationError.buildApplicationError(error)))
    }
  };
};

export const updateMeeting = (meeting) => {
  return async dispatch => {
    dispatch(updateMeetingRequest());
    try {

      const route = Urls.exhibitions.meetings.update
      const bearer = localStorage.getItem(BEARER)
      const headers = Urls.header.secure(bearer)

      let response = await fetch(route.url(meeting.getExhibitionId(), meeting.getId()), {
        method: route.method,
        headers: headers,
        body: MeetingMapper.toJsonString(meeting)
      });

      let responseService = new ResponseService(MeetingMapper)

      let action = await responseService.call(response, 
        (value) => updateMeetingSuccess(value),
        (error) => updateMeetingFailure(error))

      dispatch(action)  
    } catch(error) {
      ErrorLogger.call(this, error)
      dispatch(updateMeetingFailure(ApplicationError.buildApplicationError(error)))
    }
  };
};

export const deleteMeeting = (meeting) => {
  return async dispatch => {
    dispatch(deleteMeetingRequest());
    try {

      const route = Urls.exhibitions.meetings.delete
      const bearer = localStorage.getItem(BEARER)
      const headers = Urls.header.secure(bearer)

      let response = await fetch(route.url(meeting.getExhibitionId(), meeting.getId()), {
        method: route.method,
        headers: headers
      });

      let responseService = new ResponseService(MeetingMapper)

      let action = await responseService.call(response, 
        (value) => deleteMeetingSuccess(value),
        (error) => deleteMeetingFailure(error))

      dispatch(action)  
    } catch(error) {
      ErrorLogger.call(this, error)
      dispatch(deleteMeetingFailure(ApplicationError.buildApplicationError(error)))
    }
  };
};