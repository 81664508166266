import { Grid, Typography, Avatar } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { useContext, useRef } from 'react'
import { Trans } from 'react-i18next';
import { useHistory } from 'react-router';
import useWindowSize from '../../../../../../engines/Util/useWindowSize';
import ApplicationModel from '../../../../../../models/ApplicationModel';
import Logo from "../../../../../../assets/images/leadevents-logo-white.svg";
import AttachmentCard from '../../../../../components/AttachmentCard/AttachmentCard';
import { Link } from "react-router-dom";
import FrontendRoutes from '../../../../../../constants/FrontendRoutes';
import MyStore from '../../../../../../utils/MyStore';
import GlobalContext from '../../../context/GlobalContext';
import KeynoteDetails from '../../KeynoteView/KeynoteDetails';

const Entities = require("html-entities").XmlEntities;

const useStyles = makeStyles(theme => ({
    root: {
        background: theme.palette.background.default,
        maxHeight: "100%",
        left: "inherit",
        right: "inherit",
        top: "inherit",
        bottom: "inherit",
        overflowY: "scroll",
        borderRadius: "inherit",
        margin: "0 auto",
    },
    inheritParent: {
        width: "100%",
        height: "100%",
        borderRadius: "inherit",
        left: 0,
        top: 0,
        position: "absolute",
    },
}));

const defaultVideoScreen = () => (
    <div
        style={{
            position: "relative",
            backgroundColor: "lightGrey",
            width: "inherit",
            height: "inherit",
            //maxWidth:750,
            //maxHeight: 750 * 0.5525
        }}
    >
        <img
            src={Logo}
            style={{
                width: "50%",
                height: "50%",
                marginLeft: "25%",
                marginTop: "12.5%",
            }}
        />
    </div>
);
const speakerCard = (speaker, history, isModerator) => (
    <Grid
        container
        item
        direction="row"
        justify="center"
        alignItems="center"
        xs={6}
        style={{ boxShadow: "0 2px 5px 0 rgba(0, 0, 0, .13)", padding: "5px" }}
    >
        {/*height: isModerator ? "50px":"80px",*/}
        <Grid
            conatiner
            item
            xs={2}
            alignItems="center"
            style={{ marginLeft: "auto", marginRight: "auto", maxWidth: "80%" }}
        >
            <Avatar
                variant="circle"
                src={speaker.getProfilePicture() ? speaker.getProfilePicture().getUrl("small") : ""}
                style={{
                    marginLeft: "auto",
                    marginRight: "auto",
                    height: isModerator ? "30px" : "40px",
                    width: isModerator ? "30px" : "40px",
                }}
            />
        </Grid>
        <Grid container item direction="column" xs={10}>
            <Link to={FrontendRoutes.visitors.show(speaker.getId())}>
                <Typography
                    variant={isModerator ? "h6" : "h5"}
                    style={{
                        verticalAlign: "middle",
                        textAlign: "center",
                        fontSize: "0.8em",
                    }}
                >
                    {speaker.getFullName()}
                </Typography>
            </Link>
        </Grid>
    </Grid>
);

function FullVideoView({ exhibition, stage, keynote, ...rest }) {
    const classes = useStyles();
    const history = useHistory();
    const entities = new Entities();
    const context = useContext(GlobalContext)

    return !keynote || !stage ? (<div />) : (
        <div className={classes.root}>
            {!keynote.isRunning() ? (
                <div
                    style={{
                        backgroundColor: "lightGrey",
                        height: 0,
                        overflow: "hidden",
                        paddingTop: "56.25%",
                        position: "relative",
                    }}
                >
                    <img
                        src={
                            keynote.getImage() ? keynote.getImage().getUrl("large") : Logo
                        }
                        style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            objectFit: "cover"
                        }}
                    />
                </div>
            ) : stage.getStreamUrl(MyStore.getLocal()) ? (
                <div
                    style={{
                        position: "relative",
                        backgroundColor: "lightGrey",
                        overflow: "hidden",
                        paddingBottom: "56.25%",
                        //maxWidth:750,
                        //maxHeight: 750 * 0.5525
                    }}
                >
                    <iframe
                        src={stage.getStreamUrl(MyStore.getLocal()) + (context.muted ? "?muted=1" : "")}
                        frameBorder="0"
                        allowFullScreen
                        allow="autoplay; fullscreen"
                        className={classes.inheritParent}
                    />
                </div>
            ) : (
                        defaultVideoScreen()
                    )}
            <div style={{paddingLeft: 30, paddingRight: 30, paddingTop: 15}}>
                <KeynoteDetails keynote={keynote} />
            </div>
        </div>
    )
}

export default FullVideoView
