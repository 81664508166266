export function compareEntitiesById(leftEntity,rightEntity) {
    if (
        (!leftEntity || !rightEntity) ||
        (!leftEntity.getId() || !rightEntity.getId())
    ) {
        return false;
    }
    
    return compareIds(leftEntity.getId(),rightEntity.getId());
}
export function compareIds(leftId,rightId) {
    if (!leftId || !rightId) {
        return false;
    }
    
    return leftId.toString() === rightId.toString();
}

const langObj = { de: 'enable_german', fr: 'enable_french', it: 'enable_italy', nl: 'enable_dutch'}

export function isLocaleEnabled(lang, config) {
    if(!config){
        return true
    }
    if(lang === 'en'){
        return true
    }
    return config[langObj[lang]];
}