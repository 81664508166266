import React, { useEffect, useState, Suspense } from "react";
import { Router } from "react-router-dom";
import { renderRoutes } from "react-router-config";
import { createBrowserHistory } from "history";
import MomentUtils from "@date-io/moment";
import { useDispatch, useSelector } from "react-redux";
import { ThemeProvider } from "@material-ui/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import "react-perfect-scrollbar/dist/css/styles.css";
import { theme, genereateTheme } from "./theme";
import routes from "./routes";
//import GoogleAnalytics from "./components/GoogleAnalytics";
import CookiesNotification from "./components/CookiesNotification";
import ScrollReset from "./components/ScrollReset";
import StylesProvider from "./components/StylesProvider";
import MyStore, { BEARER } from "../utils/MyStore";
import { clearUser, fetchUser, logOutUser, setCurrentUser } from "../redux/actions/UsersActions";
import FrontendRoutes from "../constants/FrontendRoutes";
import {
  fetchExhibition,
  setCurrentExhibition,
} from "../redux/actions/ExhibitionsActions";
import { ActionCableProvider } from "react-actioncable-provider";
import { useTranslation } from "react-i18next";
import { addActionCable } from "../redux/actions/ActionCableActions";
import ExhibitionInformationService from "../utils/ExhibitionInformationService";
import ActionCable from "actioncable";
import Urls from "../constants/Urls";
import { fetchConfiguration } from "../redux/actions/ConfigurationsActions";
import useConfigurationService from "../utils/ConfigurationService";
import HttpsRedirect from 'react-https-redirect';
import Exhibition from "../models/Exhibition";
import { CircularProgress, CssBaseline, responsiveFontSizes } from "@material-ui/core";
import { deleteSession } from "../redux/actions/SessionActions";
import parser from 'ua-parser-js';
import useWindowSize from "../engines/Util/useWindowSize";
import AuthTermsPage from "./layouts/Auth/AuthTermsPage";
import { useBlockScreen } from "../engines/Util/useBreakpoints";
import MobileDevice from "./components/MobileDevice";

const history = createBrowserHistory();

const { auth } = FrontendRoutes;

const Pusher = require('pusher-js');
require('dotenv').config()

const App = () => {
  const dispatch = useDispatch();
  const [loaded, setLoaded] = useState(false);
  const [cable, setCable] = useState();
  const users = useSelector((state) => state.usersReducer);
  const exhibitions = useSelector((state) => state.exhibitionsReducer);
  const [t, i18n] = useTranslation();
  const [currentTheme, setCurrentTheme] = useState();
  const [config] = useConfigurationService();
  const [deviceType, setDeviceType] = useState();
  const windowSize = useWindowSize();
  const [eventUserModalOpen, setEventUserModalOpen] = useState(false);
  const [block,rotatePossible] = useBlockScreen();

  useEffect(() => {
    i18n.changeLanguage("en");
    dispatch(fetchConfiguration())
  }, []);

  useEffect(() => {
    const { device: { type } } = parser();
    setDeviceType(type);
  }, [windowSize])

  useEffect(() => {
    if (!config) { return }

    setCurrentTheme(
      genereateTheme(
        config.theme.primary,
        config.theme.secondary,
        config.theme.additional,
        config.theme.font,
        config.theme.accent_color,
        config.theme.primary_contrast,
        config.theme.secondary_contrast,
        config.theme.background,
        config.theme.highlight_button,
      )
    );

    window.Intercom("update");

    if (!MyStore.getCurrentUser()) {
      window.intercomSettings = {
        app_id: config.system.intercom_app_id,
      };
    }

  }, [window.location.pathname, config])

  useEffect(() => {
    let user = MyStore.getCurrentUser();

    if (window.location.pathname === FrontendRoutes.auth.alt_landing()) {
      history.push(FrontendRoutes.auth.landing());
      return;
    }

    if (
      !user &&
      window.location.pathname !== FrontendRoutes.auth.register.root() &&
      window.location.pathname !== FrontendRoutes.auth.register.welcome() &&
      window.location.pathname !== FrontendRoutes.auth.register.profile_media() &&
      window.location.pathname !== FrontendRoutes.auth.register.exhibitors() &&
      window.location.pathname !== FrontendRoutes.auth.register.confirm() &&
      window.location.pathname !== FrontendRoutes.auth.register.password() &&
      window.location.pathname !== FrontendRoutes.auth.reset() &&
      window.location.pathname !== FrontendRoutes.auth.faq() &&
      window.location.pathname !== FrontendRoutes.auth.landing()
    ) {
      history.push(
        config && config.landingpage ?
          auth.landing() : auth.login()
      );
      return;
    }

    if (user) {
      dispatch(fetchUser(user.getId()));
    }
  }, []);

  useEffect(() => {
    if (users.isFetching || !users.currentUser || !config) {
      return;
    }

    var pusherConfig = {
      cluster: process.env.REACT_APP_PUSHER_CLUSTER,
      disableStats: true,
      encrypted: true,
      authEndpoint: (process.env.REACT_APP_WEB_PROTOCOL || 'https://') + process.env.REACT_APP_API_URL + "/pusher/auth",
      auth: {
        headers: {
          'Authorization': "Bearer " + localStorage.getItem(BEARER)
        }
      }
    }

    if (process.env.REACT_APP_PUSHER_HOST) {
      pusherConfig.wsHost = process.env.REACT_APP_PUSHER_HOST;
      pusherConfig.httpHost = process.env.REACT_APP_PUSHER_HOST;
    }

    let cable = new Pusher(process.env.REACT_APP_PUSHER_KEY, pusherConfig)
    //ActionCable.createConsumer("ws://127.0.0.1:1337?token=" + localStorage.getItem(BEARER))
    dispatch(addActionCable(cable));
    setCable(cable);

    window.Intercom("boot", {
      app_id: config.system.intercom_app_id,
      user_id: users.currentUser ? users.currentUser.getId() : '',
      name: users.currentUser ? users.currentUser.getFullName() : '', // Full name
      email: users.currentUser ? users.currentUser.getEmail() : '', // Email address
      created_at: users.currentUser ? users.currentUser.getRawCreatedAt() : new Date(), // Signup date as a Unix timestamp
    });

    //segment
    if (users.currentUser) {
      window.analytics.identify(users.currentUser.getId(), {
        name: users.currentUser.getFullName(),
        email: users.currentUser.getEmail(),
      });
    }
  }, [users.currentUser, config]);

  useEffect(() => {
    if (users.error.isError()) {
      MyStore.removeAllKeys();
      history.push(
        config && config.landingpage ?
          auth.landing() : auth.login()
      );
      return;
    }

    if (!users.isFetching && users.user && config) {
      setLoaded(true);
      dispatch(setCurrentUser(users.user));
      dispatch(fetchExhibition(config.exhibition.id));
    }
  }, [users.isFetching, config]);

  const handleLogout = async () => {
    await dispatch(deleteSession());
    await dispatch(logOutUser());
    await dispatch(clearUser());
    MyStore.removeAllKeys();
    history.push(FrontendRoutes.auth.login());
  };

  useEffect(() => {
    if (
      !loaded ||
      exhibitions.isFetching ||
      exhibitions.error.isError() || 
      !users.currentUser
    ) {
      if (exhibitions.error.isError()) {
        handleLogout();
      }
      return;
    }

    if (!exhibitions.exhibition) {
      handleLogout();
    }

    dispatch(setCurrentExhibition(exhibitions.exhibition));

    if (exhibitions.exhibition && exhibitions.exhibition.getStartAt() > Date.now()) {
      if ((users.currentUser.getRole().getName() === "Visitor")) {
        history.push(FrontendRoutes.auth.register.root());
      }
    }
  }, [exhibitions.isFetching]);

  useEffect(() => {
    if (!MyStore.getCurrentEventUser() || 
        !exhibitions.exhibition || 
        !exhibitions.exhibition.getTerms() || 
        MyStore.getCurrentEventUser().getTocAccepted() === true
    ) {
      return;
    }

    if (MyStore.getCurrentUser() && !MyStore.getCurrentUser().getConfirmed()) {
      history.push(FrontendRoutes.auth.register.confirm());
    }

    history.push(FrontendRoutes.auth.terms());
  }, [MyStore.getCurrentEventUser(),exhibitions.exhibition])

  return !currentTheme ? (
    <HttpsRedirect>
      <div style={{ width: window.innerWidth, height: window.innerHeight }}>
        {config && config.profile_picture && (<img src={config.profile_picture.url} style={{ width: 300, height: 300, objectFit: "scale-down" }} />)}
        <CircularProgress size={40} thickness={7.0} style={{ marginLeft: window.innerWidth / 2 - 20, marginTop: window.innerHeight / 2 - 20 }} />
      </div>
    </HttpsRedirect>
  ) : (
      <HttpsRedirect>
        <ThemeProvider theme={currentTheme}>
          <CssBaseline />
          <StylesProvider>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <Router history={history}>
                <ScrollReset />
                {/* <GoogleAnalytics /> */}
                {/* <CookiesNotification /> */}
                {/* <ExhibitionInformationService /> */}
                <ActionCableProvider cable={cable}>
                  {!window.location.pathname.includes("auth") && !exhibitions.exhibition ? 
                    <div style={{ width: window.innerWidth, height: window.innerHeight }}>
                      {config && config.profile_picture && (<img src={config.profile_picture.url} style={{ width: 300, height: 300, objectFit: "scale-down" }} />)}
                      <CircularProgress size={40} thickness={7.0} style={{ marginLeft: window.innerWidth / 2 - 20, marginTop: window.innerHeight / 2 - 20 }} />
                    </div> 
                    : renderRoutes(routes(deviceType))}
                </ActionCableProvider>
              </Router>
            </MuiPickersUtilsProvider>
          </StylesProvider>
        </ThemeProvider>
      </HttpsRedirect>
    );
};

export default App;
