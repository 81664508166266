import { Modal, Box, IconButton } from '@material-ui/core'
import React, {useState, useEffect} from 'react'
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import UserProfile from '../UserProfile';
import useStyles from './useStyles';
import { Close } from '@material-ui/icons';
import useTracking, { EVENT_TRACKING_TYPES } from '../../../../engines/tracking/useTracking';

const UserDetailModal = ({userId, setClose, open, visitorList, currentIndex}) => {
    const [currentUserId, setCurrentUserId] = useState(userId)
    const [currentUserIndex, setCurrentUserIndex] = useState(currentIndex)
    const [trackEvent] = useTracking();

    const handleNext = (e) => {
        e.stopPropagation()
        const nextId = currentUserIndex + 1
        const userId = visitorList[nextId]?.getId()
        if(userId){
            setCurrentUserId(userId)
            setCurrentUserIndex(nextId)
        }
    }

    const handleBack = (e) => {
        e.stopPropagation()
        if(currentUserIndex !== 1){
            const nextId = currentUserIndex - 1
            const userId = visitorList[nextId]?.getId()
            if(userId){
                setCurrentUserId(userId)
                setCurrentUserIndex(nextId)
            }
        }
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const classes = useStyles()

    useEffect(() => {
        if (!currentUserId) {
            return        
        }

        trackEvent(EVENT_TRACKING_TYPES.attendees.viewAttendee, {
            attendee: currentUserId,
        });
    }, [currentUserId])

    return(
        <>
            <Modal 
                open={open}
                onClick={() => setClose(false)}
                className={classes.hiddenOverflow}
            >
                <Box display="flex" justifyContent="center" alignItems="center" width="100vw" height="100vh" zIndex="10800">
                        <Box>
                            <IconButton onClick={(e) => handleBack(e)}>
                                <ArrowBackIosRoundedIcon/>
                             </IconButton>
                        </Box>
                        <Box onClick={(e) => e.stopPropagation()}>
                            <Box position="absolute" right="25%" mt={1} ml={1}>
                                <IconButton onClick={() => setClose(false)}>
                                        <Close/>
                                </IconButton>
                            </Box>
                            <UserProfile userId={currentUserId}/>
                        </Box>
                        <Box>
                            <IconButton onClick={(e) => handleNext(e)}>
                                <ArrowForwardIosRoundedIcon/>
                            </IconButton>
                        </Box>
                    </Box>
            </Modal>
        </>
    )
}

export default UserDetailModal

