/* eslint-disable react/no-multi-comp */
import React, { useContext, useEffect, useState } from "react";
import { useLocation, matchPath } from "react-router";
import { Link as RouterLink } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import {
    CardHeader,
    Card,
    Divider,
    Grid,
    Typography,
    IconButton,
    Theme,
} from "@material-ui/core";
import MessageAdd from "./LbMobileMessageAdd";
import FrontendRoutes from "../../../../constants/FrontendRoutes";
import useConfigurationService from "../../../../utils/ConfigurationService";
import { DEFAULT_PAGE_INDEX, MAX_PAGE_SIZE } from "../../../../constants/Urls";
import useChatEngine, { useChatPushNotification, useCreateConversation } from "../../../../engines/Chat/ChatEngine";
import ThreadDetails from "./LbMobileThreadDetails"
import useExhibition from "../../../../engines/GeneralHooks/useExhibition";
import NeumorphicStyle from "../../../../constants/Neumorphic";
import GlobalContext from "../../Dashboard/context/GlobalContext";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        width: "100%",
        height: "100%",
    },
    messageAdd: {
        position: "absolute",
        bottom: 0,
        width: "calc(100%)",
        padding: 0,
    },
    fadeIn: {
        width: "100%",
        height: "calc(100% - 70px)",
    },
    scrollButton: {
        display: "inline-block",
        width: 30,
        height: 30,
        bottom: 10,
        right: 10
    }
}));

function LbMobilePublicChatBar({ keynoteConversation, detailView, chatIndex, setChatIndex }) {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [detailsOpen, setDetailsOpen] = useState(true);
    const [selectedConversation, setSelectedConversation] = useState();
    const [autoScroll, setAutoScroll] = useState<boolean>(true);
    const [loadMoreTrigger, setLoadMoreTrigger] = useState(false);
    const [currentPageIndex, setCurrentPageIndex] = useState(DEFAULT_PAGE_INDEX);
    const [newChatUser, setNewChatUser] = useState();
    const conversationsReducer = useSelector(
        (state) => (state as any).conversationsReducer
    );
    const usersReducer = useSelector(state => (state as any).usersReducer)
    const [config] = useConfigurationService();
    const { setLastGlobalMessage } = useContext(GlobalContext);

    const [
        conversation,
        messages,
        loading,
        incomingMessagesCount,
        totalPages,
        resetIncomingMessagesCount,
        sendMessage,
        sending,
        clearThread,
    ] = useChatEngine(
        selectedConversation ? (selectedConversation as any).getId() : undefined,
        currentPageIndex, 15
    );
    const [chatPush] = useChatPushNotification();
    const [
        newConversationId,
        sendNewChatMessage,
        clearNewConversationId,
    ] = useCreateConversation(newChatUser);
    const [localMessagesCount, setLocalMessagesCount] = useState(0);
    const [exhibition] = useExhibition();

    useEffect(() => {
        if (!detailView) {
            return
        }

        setChatIndex(2);
    }, [detailView])

    useEffect(() => {
        if (!keynoteConversation) {
            return;
        }

        setSelectedConversation(keynoteConversation);
    }, [chatIndex])

    useEffect(() => {
        if (!chatPush) {
            return;
        }

        if (
            (!selectedConversation ||
                (selectedConversation as any).getId() !== (chatPush as any).getConversationId())
            && !open
        ) {
            setLocalMessagesCount(localMessagesCount + 1);
        }

        // Update Conversations Unread Messages Counter
        let incomingMessageConversation = conversationsReducer.conversations.filter(
            (conv) => conv.getId() === (chatPush as any).getConversationId()
        )[0];

        // update only if Conversation is in List
        if (incomingMessageConversation !== undefined) {
            if (
                !selectedConversation ||
                (selectedConversation as any).getId() !== (chatPush as any).getConversationId()
            ) {
                incomingMessageConversation.setUnreadMessagesCount(
                    incomingMessageConversation.getUnreadMessagesCount() + 1
                );
            }
        }

        return () => { };
    }, [chatPush]);

    useEffect(() => {
        if (!open) {
            return;
        }

    }, [open]);

    useEffect(() => {
        setLocalMessagesCount(0);
    }, [conversationsReducer.unreadMessagesCount]);

    useEffect(() => {
        if (!loadMoreTrigger) {
            return;
        }
        if (currentPageIndex < totalPages) {
            setAutoScroll(false);
            setCurrentPageIndex(currentPageIndex + 1);
        }
        setLoadMoreTrigger(false);
    }, [loadMoreTrigger]);

    const loadMore = () => {
        setLoadMoreTrigger(true);
    };

    useEffect(() => {
        if (!open) {
            return
        }
        setLocalMessagesCount(0);
    }, [open])

    useEffect(() => {
        if (!newConversationId) {
            return
        }

        setSelectedConversation(undefined);
        setNewChatUser(undefined);
        (clearNewConversationId as any)();
    }, [newConversationId])

    useEffect(() => {
        if (chatIndex === 2) {
            setSelectedConversation(keynoteConversation);
        }
    }, [keynoteConversation])

    useEffect(() => {
        if (!messages || [messages as [any]].length === 0) {
            return;
        }

        const messageArray = (messages as [any]);
        const length = messageArray.length -1;
        setLastGlobalMessage(messageArray[length]);
    }, [messages])

    return !detailView ? null : (
        <div className={classes.root}>
            <ThreadDetails
                loading={loading}
                messages={messages}
                loadMore={() => loadMore()}
            />
            <div className={classes.messageAdd}>
                <Divider />
                <MessageAdd
                    className=""
                    sendMessage={(value) => (sendMessage as any)(value)}
                    conversation={conversation}
                    disabled={
                        usersReducer.user &&
                        usersReducer.user.getRole() &&
                        !usersReducer.user.getRole().getPreEventVisible(exhibition)
                    }
                    globalChat={true}
                />
            </div>
        </div>
    );
}

LbMobilePublicChatBar.propTypes = {
    className: PropTypes.string,
    onMobileClose: PropTypes.func,
    openMobile: PropTypes.bool
};

export default LbMobilePublicChatBar;