export default class BaseMapper {
  static attributes = (json = undefined) => {
    if (!json) {
      return {};
    }

    return {
      id: json.id,
      createdAt: new Date(json.created_at),
      updatedAt: new Date(json.updated_at)
    };
  };
}
