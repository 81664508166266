import React from "react";
import { Box, Typography } from "@material-ui/core";
import ScreenLockPortraitIcon from "@material-ui/icons/ScreenLockPortrait";

function MobileDevice() {
  return (
    <Box width={1} height="100vh" display="flex" alignItems="center">
      <Box
        width={1}
        display="flex"
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
        px={1}
      >
        <Typography variant="h6" align="center">
          We have so much content prepared for you, a phone just isn't enough to
          check it out. Please use your desktop computer to register and enjoy
          the event.
        </Typography>
      </Box>
    </Box>
  );
}

export default MobileDevice;
