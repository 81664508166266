import {
  CREATE_EXHIBITION_DOCUMENT_REQUEST,
  CREATE_EXHIBITION_DOCUMENT_SUCCESS,
  CREATE_EXHIBITION_DOCUMENT_FAILURE,
  DELETE_EXHIBITION_DOCUMENT_REQUEST,
  DELETE_EXHIBITION_DOCUMENT_SUCCESS,
  DELETE_EXHIBITION_DOCUMENT_FAILURE,
} from "../actions/types";

import ResponseService from "../../utils/ResponseService";
import ApplicationError from "../../utils/ApplicationError";

const initialState = {
  error: ApplicationError.buildDefault(),
  isFetching: false,
  ...ResponseService.defaultPaginationMetaData(),
};

const documentsReducer = (state = initialState, action) => {
  switch (action.type) {
    // Create
    case CREATE_EXHIBITION_DOCUMENT_REQUEST:
      return { ...state, isFetching: true };
    case CREATE_EXHIBITION_DOCUMENT_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: ApplicationError.buildDefault(),
      };
    case CREATE_EXHIBITION_DOCUMENT_FAILURE:
      return { ...state, isFetching: false, error: action.payload };

    // Delete
    case DELETE_EXHIBITION_DOCUMENT_REQUEST:
      return { ...state, isFetching: true };
    case DELETE_EXHIBITION_DOCUMENT_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: ApplicationError.buildDefault(),
      };
    case DELETE_EXHIBITION_DOCUMENT_FAILURE:
      return { ...state, isFetching: false, error: action.payload };

    default:
      return state;
  }
};

export default documentsReducer;
