import {
  FETCHING_EXHIBITIONS_REQUEST,
  FETCHING_EXHIBITIONS_SUCCESS,
  FETCHING_EXHIBITIONS_FAILURE,
  FETCHING_EXHIBITIONS_UPCOMING_REQUEST,
  FETCHING_EXHIBITIONS_UPCOMING_SUCCESS,
  FETCHING_EXHIBITIONS_UPCOMING_FAILURE,
  FETCHING_EXHIBITION_REQUEST,
  FETCHING_EXHIBITION_SUCCESS,
  FETCHING_EXHIBITION_FAILURE,
  CREATE_EXHIBITION_REQUEST,
  CREATE_EXHIBITION_SUCCESS,
  CREATE_EXHIBITION_FAILURE,
  UPDATE_EXHIBITION_REQUEST,
  UPDATE_EXHIBITION_SUCCESS,
  UPDATE_EXHIBITION_FAILURE,
  DELETE_EXHIBITION_REQUEST,
  DELETE_EXHIBITION_SUCCESS,
  DELETE_EXHIBITION_FAILURE,
  SET_CURRENT_EXHIBITION,
  SET_CURRENT_EXHIBITORS,
} from "./types";
import Urls, { DEFAULT_OPTIONAL_PARAMETERS } from "../../constants/Urls";
import MyStore, { BEARER } from "../../utils/MyStore";
import axios from "axios"

import ExhibitionMapper from "../../mapper/ExhibitionMapper";
import ResponseService from "../../utils/ResponseService";
import ApplicationError from "../../utils/ApplicationError";
import Exhibition from "../../models/Exhibition";
import FilterParams from "../../utils/FilterParams";
import ErrorLogger from "../../utils/ErrorLogger";
import ExhibitorMapper from "../../mapper/ExhibitorMapper";
import { setCurrentExhibitors } from "./UsersActions";
import { INTERNAL_IDS } from "../../models/Role";

export const currentExhibition = (exhibition) => ({
  type: SET_CURRENT_EXHIBITION,
  payload: exhibition,
});

export const setCurrentExhibition = (exhibition) => {
  return async (dispatch) => {
    dispatch(currentExhibition(exhibition));
  };
};

export const fetchingExhibitionsRequest = () => ({
  type: FETCHING_EXHIBITIONS_REQUEST,
});

export const fetchingExhibitionsSuccess = (json) => ({
  type: FETCHING_EXHIBITIONS_SUCCESS,
  payload: json,
});
export const fetchingExhibitionsFailure = (error) => ({
  type: FETCHING_EXHIBITIONS_FAILURE,
  payload: error,
});

export const fetchingExhibitionsUpcomingRequest = () => ({
  type: FETCHING_EXHIBITIONS_UPCOMING_REQUEST,
});

export const fetchingExhibitionsUpcomingSuccess = (json) => ({
  type: FETCHING_EXHIBITIONS_UPCOMING_SUCCESS,
  payload: json,
});
export const fetchingExhibitionsUpcomingFailure = (error) => ({
  type: FETCHING_EXHIBITIONS_UPCOMING_FAILURE,
  payload: error,
});

export const fetchingExhibitionRequest = () => ({
  type: FETCHING_EXHIBITION_REQUEST,
});

export const fetchingExhibitionSuccess = (exhibition) => ({
  type: FETCHING_EXHIBITION_SUCCESS,
  payload: exhibition,
});
export const fetchingExhibitionFailure = (error) => ({
  type: FETCHING_EXHIBITION_FAILURE,
  payload: error,
});

export const createExhibitionRequest = () => ({
  type: CREATE_EXHIBITION_REQUEST,
});

export const createExhibitionSuccess = (exhibition) => ({
  type: CREATE_EXHIBITION_SUCCESS,
  payload: exhibition,
});

export const createExhibitionFailure = (error) => ({
  type: CREATE_EXHIBITION_FAILURE,
  payload: error,
});

export const updateExhibitionRequest = () => ({
  type: UPDATE_EXHIBITION_REQUEST,
});

export const updateExhibitionSuccess = (exhibition) => ({
  type: UPDATE_EXHIBITION_SUCCESS,
  payload: exhibition,
});

export const updateExhibitionFailure = (error) => ({
  type: UPDATE_EXHIBITION_FAILURE,
  payload: error,
});

export const deleteExhibitionRequest = () => ({
  type: DELETE_EXHIBITION_REQUEST,
});

export const deleteExhibitionSuccess = () => ({
  type: DELETE_EXHIBITION_SUCCESS,
});

export const deleteExhibitionFailure = (error) => ({
  type: DELETE_EXHIBITION_FAILURE,
  payload: error,
});

export const fetchExhibitions = (_opts = DEFAULT_OPTIONAL_PARAMETERS) => {
  return async (dispatch) => {
    dispatch(fetchingExhibitionsRequest());

    try {
      const route = Urls.exhibitions.all;
      const bearer = localStorage.getItem(BEARER);
      const headers = Urls.header.secure(bearer);
      const url = Urls.build(
        route.url(_opts.pageIndex, _opts.pageSize),
        _opts.filters
      );

      let response = await fetch(url, {
        method: route.method,
        headers: headers,
      });

      let responseService = new ResponseService(ExhibitionMapper);

      let action = await responseService.callAxios(
        response,
        (value) => fetchingExhibitionsSuccess(value),
        (error) => fetchingExhibitionsFailure(error)
      );

      dispatch(action);
    } catch (error) {
      ErrorLogger.call(this, error);
      dispatch(
        fetchingExhibitionsFailure(
          ApplicationError.buildApplicationError(error)
        )
      );
    }
  };
};

export const fetchExhibitionsUpcoming = (
  _opts = DEFAULT_OPTIONAL_PARAMETERS
) => {
  return async (dispatch) => {
    dispatch(fetchingExhibitionsUpcomingRequest());

    try {
      const route = Urls.exhibitions.all;
      const bearer = localStorage.getItem(BEARER);
      const headers = Urls.header.secure(bearer);
      const url = Urls.build(route.url(_opts.pageIndex, _opts.pageSize), [
        FilterParams.filterBy(Exhibition.filterParams.upcoming, true),
      ]);

      let response = await fetch(url, {
        method: route.method,
        headers: headers,
      });

      let responseService = new ResponseService(ExhibitionMapper);

      let action = await responseService.call(
        response,
        (value) => fetchingExhibitionsUpcomingSuccess(value),
        (error) => fetchingExhibitionsUpcomingFailure(error)
      );

      dispatch(action);
    } catch (error) {
      ErrorLogger.call(this, error);
      dispatch(
        fetchingExhibitionsUpcomingFailure(
          ApplicationError.buildApplicationError(error)
        )
      );
    }
  };
};

export const fetchExhibition = (id) => {
  return async (dispatch) => {
    dispatch(fetchingExhibitionRequest());

    try {
      const route = Urls.exhibitions.show;
      const bearer = localStorage.getItem(BEARER);
      const headers = Urls.header.secure(bearer);
      const url = Urls.build(route.url(id));

      let response = await axios.get(url,{
        headers: headers,
      });

      let responseService = new ResponseService(ExhibitionMapper);

      let action = await responseService.callAxios(
        response,
        response.data,
        (value) => fetchingExhibitionSuccess(value),
        (error) => fetchingExhibitionFailure(error)
      );

      if (MyStore.getCurrentUser().getRole().getName() !== INTERNAL_IDS.VISITOR) {
        response = await fetch(Urls.users.exhibitors.url(), {
          method: Urls.users.exhibitors.method,
          headers: headers,
          credentials: "same-origin"
        });
  
        let data = await response.json();
        let exhibitors = ExhibitorMapper.buildFromArray(data);
        dispatch(setCurrentExhibitors(exhibitors.map(e => {
          if (e) {
            return e.getId();
          }
        })));
      }

      dispatch(action);
    } catch (error) {
      ErrorLogger.call(this, error);
      dispatch(
        fetchingExhibitionFailure(ApplicationError.buildApplicationError(error))
      );
    }
  };
};

export const createExhibition = (exhibition) => {
  return async (dispatch) => {
    dispatch(createExhibitionRequest());

    try {
      const route = Urls.exhibitions.create;
      const bearer = localStorage.getItem(BEARER);
      const headers = Urls.header.secure(bearer);
      const url = Urls.build(route.url());

      let body = ExhibitionMapper.toJsonString(exhibition);

      let response = await fetch(url, {
        method: route.method,
        headers: headers,
        body: body,
      });

      let responseService = new ResponseService(ExhibitionMapper);

      let action = await responseService.call(
        response,
        (value) => createExhibitionSuccess(value),
        (error) => createExhibitionFailure(error)
      );

      dispatch(action);
    } catch (error) {
      ErrorLogger.call(this, error);
      dispatch(
        createExhibitionFailure(ApplicationError.buildApplicationError(error))
      );
    }
  };
};

export const updateExhibition = (exhibition) => {
  return async (dispatch) => {
    dispatch(updateExhibitionRequest());

    try {
      const route = Urls.exhibitions.update;
      const bearer = localStorage.getItem(BEARER);
      const headers = Urls.header.secure(bearer);
      const url = Urls.build(route.url(exhibition.getId()));

      let response = await fetch(url, {
        method: route.method,
        headers: headers,
        body: ExhibitionMapper.toJsonString(exhibition),
      });

      let responseService = new ResponseService(ExhibitionMapper);

      let action = await responseService.call(
        response,
        (value) => updateExhibitionSuccess(value),
        (error) => updateExhibitionFailure(error)
      );

      dispatch(action);
    } catch (error) {
      ErrorLogger.call(this, error);
      dispatch(
        updateExhibitionFailure(ApplicationError.buildApplicationError(error))
      );
    }
  };
};

export const deleteExhibition = (exhibition) => {
  return async (dispatch) => {
    dispatch(deleteExhibitionRequest());

    try {
      const route = Urls.exhibitions.delete;
      const bearer = localStorage.getItem(BEARER);
      const headers = Urls.header.secure(bearer);
      const url = Urls.build(route.url(exhibition.getId()));

      let response = await fetch(url, {
        method: route.method,
        headers: headers,
      });

      let responseService = new ResponseService(ExhibitionMapper);

      let action = await responseService.call(
        response,
        (value) => deleteExhibitionSuccess(value),
        (error) => deleteExhibitionFailure(error)
      );

      dispatch(action);
    } catch (error) {
      ErrorLogger.call(this, error);
      dispatch(
        deleteExhibitionFailure(ApplicationError.buildApplicationError(error))
      );
    }
  };
};
