import React, { useEffect, useState } from 'react'
import { 
    makeStyles,
    Modal, 
    Typography, 
    Fade, 
    Grid, 
    CardHeader, 
    Card, 
    CardContent, 
    CardActions, 
    Button, 
    Avatar, 
    TextField 
} from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';
import { createMessage } from "../../../redux/actions/MessagesActions";
import Message from "../../../models/Message";
import { useDispatch, useSelector } from 'react-redux';
import { createShare } from "../../../redux/actions/SharesActions"
import Share from '../../../models/Share';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { fetchVisitors } from '../../../redux/actions/VisitorsActions';
import { MAX_PAGE_SIZE, DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE } from '../../../constants/Urls';
import FilterParams from '../../../utils/FilterParams';
import User from '../../../models/User';
import useVisitorsQL from '../../../engines/GraphQL/hooks/useVisitorsQL';

const useStyles = makeStyles(theme => ({
    root: {
        display: "grid",
        alignItems: "center",
        justifyContent: "center",
    },    
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: "2px solid #000",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        maxWidth: "800px",
        minWidth: "500px"
    },
    inner: {
        padding: theme.spacing(2)
    }
}));

function sleep(delay = 0) {
    return new Promise((resolve) => {
        setTimeout(resolve, delay);
    });
}

function ShareModal({open, payload, handleClose, history}) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const [searchText, setSearchText] = useState();
    const [visitors,loading, totalPages] = useVisitorsQL(searchText,DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE, false, true);

    const [value,setValue] = useState("");
    const [selectedUser,setSelectedUser] = useState();

    const exhibitions = useSelector(state => state.exhibitionsReducer);

    useEffect(() => {
        let active = true;
        if (!exhibitions.exhibition || !open || value.length === 0) {
            return;
        }

        (async () => {
            await sleep(1e3);
            if (active) {
                setSearchText(value);
            }
        })();

        return () => {
            active = false;
        }
    }, [exhibitions.currentSet, value])

    const sendShare = () => {
        if (!exhibitions.exhibition || !selectedUser) {
            return
        }

        let share = new Share({
            sharableType: payload.className,
            sharableId: payload.getId(),
            user: selectedUser,
            exhibitionId: exhibitions.exhibition.getId(),
        });
    
        dispatch(createShare(share));

        handleClose();
        // const url = FrontendRoutes.chat.show(conversations.conversation.getId());
        // history.push(url); xxx removed due to disfunction in routing combined with redux. to be fixed
    }

    const handleInputChange = (e) => {
        setValue(e.target.value);
    }
    const handleChange = (e) => {
        setSelectedUser(visitors[e.target.value]);
    }

    return !payload ? (<div/>):(
        <Modal 
            className={classes.root}
            open={open}
            onClose={() => handleClose()}
        >
            <Fade in={open}>
                <Card className={classes.paper}>
                    <CardHeader 
                        title={(
                            <Typography variant="h2"><Trans>frontend.modals.share_modal.to_share_with</Trans></Typography>
                        )}
                    />
                    <CardContent>
                        <Autocomplete 
                            id="combo-box-demo"
                            getOptionSelected={(option, value) => option.name === value.name}
                            options={visitors}
                            getOptionLabel={(option) => option.getFullName()}
                            style={{ width: 300 }}
                            renderInput={(params) => <TextField {...params} label={t("frontend.modals.share_modal.type_user_name")} variant="outlined" />}
                            loading={loading}
                            noOptionsText={t("frontend.modals.share_modal.no_options")}
                            onInputChange={handleInputChange}
                            onChange={handleChange}
                        />
                    </CardContent>
                    <CardActions>
                        <Button variant="contained" onClick={() => handleClose()}><Trans>frontend.components.chat.abort</Trans></Button>
                        <Button 
                            variant="contained" 
                            disabled={!selectedUser}
                            style={{marginLeft:"auto"}} 
                            color="secondary"
                            onClick={() => sendShare()}
                        >
                            <Trans>frontend.components.chat.send</Trans>
                        </Button>
                    </CardActions>
                </Card>
            </Fade>
        </Modal>
    )
}

export default ShareModal