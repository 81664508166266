import React, { useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
    Avatar,
    Box,
    Divider,
    IconButton,
    Input,
    Paper,
    SvgIcon,
    Tooltip,
    makeStyles
} from '@material-ui/core';
import AddPhotoIcon from '@material-ui/icons/AddPhotoAlternate';
import AttachFileIcon from '@material-ui/icons/AttachFile';
//import { Send as SendIcon } from 'react-feather';
import SendIcon from '@material-ui/icons/Send';
// import { createMessage } from '../../../redux/actions/MessagesActions';
import Message from '../../../../../src/models/Message';
// import MyStore from '../../../utils/MyStore';
import { useTranslation } from 'react-i18next';
import useTracking, { EVENT_TRACKING_TYPES } from '../../../engines/tracking/useTracking';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.default,
        display: 'flex',
        alignItems: 'center',
        padding: 0,
        height: "75px",
    },
    paper: {
        flexGrow: 1,
        marginLeft: 12,
        padding: 1,
    },
    button: {
        marginRight: 72,

    },
    divider: {
        width: 1,
        height: 24
    },
    fileInput: {
        display: 'none'
    },
    input: {}
}));

function LbMobileMessageAdd({
    className,
    conversation,
    disabled,
    sendMessage,
    globalChat,
    ...rest
}) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const fileInputRef = useRef(null);
    const [body, setBody] = useState('');
    const attachments = [];
    const { t } = useTranslation();
    const [trackEvent] = useTracking();

    const users = useSelector(state => (state as any).usersReducer);

    const handleChange = (event) => {
        if (event.target.value.split("\n").length < 6) {
            event.persist();
            setBody(event.target.value);
        }
    };

    const createMessage = () => {
        if (!body) {
            return;
        }

        if (!conversation) {
            sendMessage(body);
            setBody("");
            return;
        }

        let message = new Message({
            conversationId: conversation.getId(),
            conversation: conversation,
            exhibitionId: conversation.getExhibitionId(),
            me: true,
            text: body,
            user: users.currentUser,
            receivers: conversation.getOtherUser() ? [conversation.getOtherUser()] : undefined,
            createdAt: new Date(),
        });

        sendMessage(message);
        trackEvent(EVENT_TRACKING_TYPES.chat.messageSentGlobalChat);
        setBody("");
    };

    const handleKeyUp = (event) => {
        if (event.keyCode === 13 && !event.shiftKey) {
            createMessage();
        }
    };

    const handleAttach = () => {
        fileInputRef.current.click();
    };

    return (
        <div
            className={clsx(classes.root, className)}
            {...rest}
        >
            <Paper
                variant="outlined"
                component={Box}
                className={classes.paper}
            >
                <Input
                    className={classes.input}
                    disableUnderline
                    fullWidth
                    multiline
                    rowsMax={3}
                    inputProps={{
                        maxlength: 500,
                        rowsMax: 5
                    }}
                    value={body}
                    onChange={handleChange}
                    onKeyUp={handleKeyUp}
                    placeholder={t("frontend.components.chat.leave_message")}
                />
            </Paper>
            <Tooltip title="Send">
                <span>
                    <IconButton
                        color="secondary"
                        disabled={!body || disabled || !body.replace(/\s/g, '').length}
                        onClick={createMessage}
                        className={classes.button}
                    >
                        <SvgIcon fontSize="small">
                            <SendIcon />
                        </SvgIcon>
                    </IconButton>
                </span>
            </Tooltip>
        </div>
    );
}

LbMobileMessageAdd.propTypes = {
    className: PropTypes.string,
    thread: PropTypes.object,
    disabled: PropTypes.bool,
    onAdd: PropTypes.func
};

LbMobileMessageAdd.defaultProps = {
    className: '',
    disabled: false,
    onAdd: () => { }
};

export default LbMobileMessageAdd;