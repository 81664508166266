import { 
  FETCHING_EXHIBITION_POSTS_LIKES_REQUEST,
  FETCHING_EXHIBITION_POSTS_LIKES_SUCCESS,
  FETCHING_EXHIBITION_POSTS_LIKES_FAILURE,

  CREATE_EXHIBITION_POSTS_LIKE_REQUEST,
  CREATE_EXHIBITION_POSTS_LIKE_SUCCESS,
  CREATE_EXHIBITION_POSTS_LIKE_FAILURE,

  DELETE_EXHIBITION_POSTS_LIKE_REQUEST,
  DELETE_EXHIBITION_POSTS_LIKE_SUCCESS,
  DELETE_EXHIBITION_POSTS_LIKE_FAILURE,
} from "../actions/types";

import ResponseService from '../../utils/ResponseService';
import ApplicationError from '../../utils/ApplicationError';

const initialState = {
  users: [],
  error: ApplicationError.buildDefault(),
  isFetching: false,
  ...ResponseService.defaultPaginationMetaData()
};

const postsLikesReducer = (state = initialState, action ) => {
  switch(action.type) {

    // Get MeetingsUsers
    case FETCHING_EXHIBITION_POSTS_LIKES_REQUEST:
      return {...state, isFetching: true }
    case FETCHING_EXHIBITION_POSTS_LIKES_SUCCESS:
      return {...state, isFetching: false, 
        users: action.payload.objects, 
        error: ApplicationError.buildDefault(),
        ...action.payload.paginationData }
        
    case FETCHING_EXHIBITION_POSTS_LIKES_FAILURE:
      return {...state, isFetching: false, error: action.payload }
  

    // Create
    case  CREATE_EXHIBITION_POSTS_LIKE_REQUEST:
      return {...state, isFetching: true }
    case  CREATE_EXHIBITION_POSTS_LIKE_SUCCESS:
      return {...state, isFetching: false, error: ApplicationError.buildDefault() }
    case  CREATE_EXHIBITION_POSTS_LIKE_FAILURE:
      return {...state, isFetching: false, error: action.payload }


    // Delete  
    case  DELETE_EXHIBITION_POSTS_LIKE_REQUEST:
      return {...state, isFetching: true }
    case  DELETE_EXHIBITION_POSTS_LIKE_SUCCESS:
      return {...state, isFetching: false, error: ApplicationError.buildDefault() }
    case  DELETE_EXHIBITION_POSTS_LIKE_FAILURE:
      return {...state, isFetching: false, error: action.payload }


    default: 
      return state
  }
}

export default postsLikesReducer