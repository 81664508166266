import Notification from "../models/Notification";
import KeynoteMapper from "./KeynoteMapper";
import MeetingMapper from "./MeetingMapper";
import MessageMapper from "./MessageMapper";
import UserMapper from "./UserMapper";
import MeetingsUserMapper from "./MeetingsUserMapper";
import BaseMapper from "./BaseMapper";
import ShareMapper from "./ShareMapper";
import CommentMapper from "./CommentMapper";
import PostMapper from "./PostMapper";
import PostsLikeMapper from "./PostsLikeMapper";
import CommentsLikeMapper from "./CommentsLikeMapper";

const attributes = Notification.attributes

export default class NotificationMapper {
  static build = (json = undefined) => {
    if (!json) {
      return;
    }

    return new Notification({
      ...BaseMapper.attributes(json),
      attachedToType: json[attributes.attachedToType],
      attachedToId: json[attributes.attachedToId],
      attachedTo: this.buildPayload(json),
      notificationType: json[attributes.notificationType],
      otherUser: UserMapper.build(json[attributes.otherUser]),      
      exhibitionId: json[attributes.exhibitionId],
      unread: json[attributes.unread]
    });
  };

  static buildFromArray = (jsonArray = []) => {
    return jsonArray.map((entry) => {
      return this.build(entry);
    });
  };

  static buildPayload(json){
    switch(json[attributes.attachedToType]) {
      case 'Keynote':
        return KeynoteMapper.build(json[attributes.attachedTo])
      case 'Meeting':
        return MeetingMapper.build(json[attributes.attachedTo])
      case 'Message':
        return MessageMapper.build(json[attributes.attachedTo])
      case 'MeetingsUser':
        return MeetingsUserMapper.build(json[attributes.attachedTo])
      case "Share": 
        return ShareMapper.build(json[attributes.attachedTo])
      case "Comment":
        return CommentMapper.build(json[attributes.attachedTo])
      case "PostsLike":
        return PostsLikeMapper.build(json[attributes.attachedTo])
      case "CommentsLike":
        return CommentsLikeMapper.build(json[attributes.attachedTo])  
          
    }
  }
}
