import MeetingsUser from "../models/MeetingsUser";
import BaseMapper from "./BaseMapper";
import ErrorLogger from "../utils/ErrorLogger";
import UserMapper from "./UserMapper";
import EventUser from "../models/EventUser";
import ExhibitionMapper from "./ExhibitionMapper";

const attributes = EventUser.attributes;

export default class EventUserMapper {
  static build = (json = undefined) => {
    if (!json) {
      return;
    }

    try {
      return new EventUser({
        ...BaseMapper.attributes(json),
        user: UserMapper.build(json.user),
        name: json['name'],
        firstName: json['first_name'],
        lastName: json['last_name'],
        tags: json['tags'],
        contactPerson: json['contact_person'],
        online: json['online'],
        fakeUser: json['fake_user'],
        tocAccepted: json['toc_accepted'],
        newsletter: json['newsletter'],
        shareInformation: json['share_information'],
        lastStatusAt: json['last_status_at'],
        exhibition: ExhibitionMapper.build(json['exhhibition']),
      });
    } catch (error) {
      ErrorLogger.call(this, error);
    }
  };

  static buildFromArray = (jsonArray = []) => {
    return jsonArray.map((entry) => {
      return this.build(entry);
    });
  };

  static toJsonString = (eventUser) => {
    return JSON.stringify({
      id: eventUser.getId(),
      updatedAt: eventUser.getUpdatedAt(),
      createdAt: eventUser.getCreatedAt(),
      user: UserMapper.toJsonString(eventUser.getUser()),
      name: eventUser.getName(),
      first_name: eventUser.getFirstName(),
      last_name: eventUser.getLastName(),
      tags: eventUser.getTags(),
      contact_person: eventUser.getContactPerson(),
      online: eventUser.getOnline(),
      fake_user: eventUser.getFakeUser(),
      toc_accepted: eventUser.getTocAccepted(),
      newsletter: eventUser.getNewsletter(),
      share_information: eventUser.getShareInformation(),
      last_status_at: eventUser.getLastStatusAt()
    });
  };
}
