import { Typography } from "@material-ui/core";
import React from "react";

const TimeDisplay = ({ seconds }) => {
  const mm = Math.floor(seconds / 60)
    .toString()
    .padStart(2, "0");
  const ss = (seconds % 60).toString().padStart(2, "0");
  return (
    <div>
      <Typography
        style={{
          fontFamily: "Montserrat",
          fontWeight: "bold",
          fontSize: "1.1em",
          textAlign: "center",
        }}
      >
        {mm}:{ss}
      </Typography>
    </div>
  );
};

export default TimeDisplay;
