import {
  FETCHING_EXHIBITION_POSTS_LIKES_REQUEST,
  FETCHING_EXHIBITION_POSTS_LIKES_SUCCESS,
  FETCHING_EXHIBITION_POSTS_LIKES_FAILURE,

  CREATE_EXHIBITION_POSTS_LIKE_REQUEST,
  CREATE_EXHIBITION_POSTS_LIKE_SUCCESS,
  CREATE_EXHIBITION_POSTS_LIKE_FAILURE,

  DELETE_EXHIBITION_POSTS_LIKE_REQUEST,
  DELETE_EXHIBITION_POSTS_LIKE_SUCCESS,
  DELETE_EXHIBITION_POSTS_LIKE_FAILURE,

} from './types';
import Urls, { DEFAULT_OPTIONAL_PARAMETERS } from '../../constants/Urls';
import { BEARER } from '../../utils/MyStore';

import PostMapper from '../../mapper/PostMapper';
import ApplicationError from '../../utils/ApplicationError';
import ResponseService from '../../utils/ResponseService';
import UserMapper from '../../mapper/UserMapper';
import ErrorLogger from '../../utils/ErrorLogger';

export const fetchingPostsLikesRequest = () => ({
  type: FETCHING_EXHIBITION_POSTS_LIKES_REQUEST
})

export const fetchingPostsLikesSuccess = (json) => ({
  type: FETCHING_EXHIBITION_POSTS_LIKES_SUCCESS,
  payload: json
})
export const fetchingPostsLikesFailure = (error) => ({
  type: FETCHING_EXHIBITION_POSTS_LIKES_FAILURE,
  payload: error
})

export const createPostsLikeRequest = () => ({
  type: CREATE_EXHIBITION_POSTS_LIKE_REQUEST
})

export const createPostsLikeSuccess = (post) => ({
  type: CREATE_EXHIBITION_POSTS_LIKE_SUCCESS,
  payload: post
})

export const createPostsLikeFailure = (error) => ({
  type: CREATE_EXHIBITION_POSTS_LIKE_FAILURE,
  payload: error
})

export const deletePostsLikeRequest = () => ({
  type: DELETE_EXHIBITION_POSTS_LIKE_REQUEST
})

export const deletePostsLikeSuccess = () => ({
  type: DELETE_EXHIBITION_POSTS_LIKE_SUCCESS
})

export const deletePostsLikeFailure = (error) => ({
  type: DELETE_EXHIBITION_POSTS_LIKE_FAILURE,
  payload: error
})

export const fetchPostsLikes = (exhibitionId, _opts = DEFAULT_OPTIONAL_PARAMETERS) => {
  return async dispatch => {
    dispatch(fetchingPostsLikesRequest());
    
    try {
      const route = Urls.exhibitions.posts.likes.all
      const bearer = localStorage.getItem(BEARER)
      const headers = Urls.header.secure(bearer)
      const url     = Urls.build(route.url(exhibitionId, _opts.pageIndex, _opts.pageSize), _opts.filters)

      let response = await fetch(url, {
        method: route.method,
        headers: headers
      });

      let responseService = new ResponseService(UserMapper)

      let action = await responseService.call(response, 
        (value) => fetchingPostsLikesSuccess(value),
        (error) => fetchingPostsLikesFailure(error))

      dispatch(action)  
    } catch(error) {
      dispatch(fetchingPostsLikesFailure(ApplicationError.buildApplicationError(error)))
    }
  };
};

export const createPostsLike = (post) => {
  return async dispatch => {
    dispatch(createPostsLikeRequest());
    
    try {
      const route   = Urls.exhibitions.posts.likes.create
      const bearer  = localStorage.getItem(BEARER)
      const headers = Urls.header.secure(bearer)
      const url     = Urls.build(route.url(post.getExhibitionId(), post.getId()))

      let response = await fetch(url, {
        method: route.method,
        headers: headers, 
        body: JSON.stringify({ post: post.getId() })
      });

      let responseService = new ResponseService(PostMapper)

      let action = await responseService.call(response, 
        (value) => createPostsLikeSuccess(value),
        (error) => createPostsLikeFailure(error))

      dispatch(action)
    } catch(error) {
      ErrorLogger.call(this, error)
      dispatch(createPostsLikeFailure(ApplicationError.buildApplicationError(error)))
    }
  };
};

export const deletePostsLike = (post) => {
  return async dispatch => {
    dispatch(deletePostsLikeRequest());
    
    try {
      const route   = Urls.exhibitions.posts.likes.delete
      const bearer  = localStorage.getItem(BEARER)
      const headers = Urls.header.secure(bearer)
      const url     = Urls.build(route.url(post.getExhibitionId(), post.getId()))

      let response = await fetch(url, {
        method: route.method,
        headers: headers,
        body: JSON.stringify({ post: post.getId() })        
      });

      let responseService = new ResponseService(PostMapper)

      let action = await responseService.call(response, 
        (value) => deletePostsLikeSuccess(value),
        (error) => deletePostsLikeFailure(error))

      dispatch(action)  
    } catch(error) {
      ErrorLogger.call(this, error)
      dispatch(deletePostsLikeFailure(ApplicationError.buildApplicationError(error)))
    }
  };
};