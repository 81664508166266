import React, { Suspense } from "react";
import parser from 'ua-parser-js';
import ReactDOM from "react-dom";
import "./index.css";
import * as serviceWorker from "./serviceWorker";

import "./config/i18n";

import { BrowserRouter } from "react-router-dom";
import { createStore, applyMiddleware } from "redux";

import { Provider } from "react-redux";
import thunk from "redux-thunk";
import rootReducer from "./redux/reducers";
import App from "./frontend/App";
import MobileDevice from "./frontend/components/MobileDevice";
import { LinearProgress } from "@material-ui/core"; import { MatomoProvider, createInstance } from '@datapunt/matomo-tracker-react'
import { ApolloClient, InMemoryCache, ApolloProvider, createHttpLink } from '@apollo/client';

import { setContext } from '@apollo/client/link/context';
import Urls from "./constants/Urls";
import MobileApp from "./frontend/layouts/Mobile/MobileApp";

const BEARER = "Authorization"

const httpLink = createHttpLink({
  uri: Urls.graphUrl,
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem(BEARER);
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      exhibition: process.env.REACT_APP_EXHIBITION_ID,
      authorization: token ? `Bearer ${token}` : "",
      exhibition: process.env.REACT_APP_EXHIBITION_ID,
    }
  }
});

const apolloClient = new ApolloClient({
  link: authLink.concat(httpLink), //TO-DO: Change to ENV variables
  cache: new InMemoryCache()
});

require("dotenv").config();

const instance = createInstance({
  urlBase: process.env.REACT_APP_MATOMO_URL_BASE || 'https://leadevents.matomo.cloud',
  siteId: process.env.REACT_APP_MATOMO_SITE_ID || 1
})

const createStoreWithMiddleware = applyMiddleware(thunk)(createStore);
const store = createStoreWithMiddleware(
  rootReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

const https_url = () => "https://" + window.location.host + window.location.pathname;

// Redirect to https: Protocol if production
if (
  process.env.NODE_ENV === "production" &&
  window.location.protocol !== "https:" &&
  !process.env.REACT_APP_WEB_PROTOCOL
) {
  window.location.href = https_url()
}

const { device: { type } } = parser();
console.log(type);
ReactDOM.render(
  <BrowserRouter>
    <MatomoProvider value={instance}>
      <Provider store={store}>
        <ApolloProvider client={apolloClient}>
          <Suspense fallback={<LinearProgress />}>
            <App />
          </Suspense>
        </ApolloProvider>
      </Provider>
    </MatomoProvider>
  </BrowserRouter>,
  document.getElementById("root")
);

serviceWorker.unregister();
