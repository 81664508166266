import {
  FETCHING_EXHIBITION_CONVERSATIONS_USERS_REQUEST,
  FETCHING_EXHIBITION_CONVERSATIONS_USERS_SUCCESS,
  FETCHING_EXHIBITION_CONVERSATIONS_USERS_FAILURE,
  
  CREATE_EXHIBITION_CONVERSATIONS_USER_FAILURE,
  CREATE_EXHIBITION_CONVERSATIONS_USER_SUCCESS,
  CREATE_EXHIBITION_CONVERSATIONS_USER_REQUEST,

  DELETE_EXHIBITION_CONVERSATIONS_USER_FAILURE,
  DELETE_EXHIBITION_CONVERSATIONS_USER_SUCCESS,
  DELETE_EXHIBITION_CONVERSATIONS_USER_REQUEST,
} from '../actions/types';
import ResponseService from '../../utils/ResponseService';
import ApplicationError from '../../utils/ApplicationError';

const initialState = {
  conversationsUsers: [],
  conversationsUser: undefined,
  error: ApplicationError.buildDefault(),
  isFetching: false,
  ...ResponseService.defaultPaginationMetaData()
};

const conversationsUsersReducer = (state = initialState, action ) => {
  switch(action.type) {
    case FETCHING_EXHIBITION_CONVERSATIONS_USERS_REQUEST:
      return {...state, isFetching: true }
    case FETCHING_EXHIBITION_CONVERSATIONS_USERS_SUCCESS:
      return {...state, isFetching: false, 
        conversationsUsers: action.payload.objects, 
        error: ApplicationError.buildDefault(),
        ...action.payload.paginationData }

    case FETCHING_EXHIBITION_CONVERSATIONS_USERS_FAILURE:
      return {...state, isFetching: false, error: action.payload }

    case  CREATE_EXHIBITION_CONVERSATIONS_USER_REQUEST:
      return {...state, isFetching: true }
    case  CREATE_EXHIBITION_CONVERSATIONS_USER_SUCCESS:
      return {...state, isFetching: false, conversationsUser: action.payload, error: ApplicationError.buildDefault() }
    case  CREATE_EXHIBITION_CONVERSATIONS_USER_FAILURE:
      return {...state, isFetching: false, error: action.payload }

    case  DELETE_EXHIBITION_CONVERSATIONS_USER_REQUEST:
      return {...state, isFetching: true }
    case  DELETE_EXHIBITION_CONVERSATIONS_USER_SUCCESS:
      return {...state, isFetching: false, error: ApplicationError.buildDefault() }
    case  DELETE_EXHIBITION_CONVERSATIONS_USER_FAILURE:
      return {...state, isFetching: false, error: action.payload }

    default: 
      return state
  }
}

export default conversationsUsersReducer;