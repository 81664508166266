import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchExhibitorsUsers,
  deleteExhibitorsUser,
  clearExhibitorUsers,
} from "../../../redux/actions/ExhibitorsUsersActions";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { Button, Container, Grid } from "@material-ui/core";
import Preferences from "../../views/Meeting/CreateMeeting/Preferences";
import AboutProject from "../../views/Meeting/CreateMeeting/AboutProject";
import AboutAuthor from "../../views/Meeting/CreateMeeting/AboutAuthor";
import Header from "../../views/Meeting/CreateMeeting/Header";
import { fetchTimeslots } from "../../../redux/actions/TimeslotsActions";
import Timeslot from "../../../models/Timeslot";
import { createMeeting } from "../../../redux/actions/MeetingsActions";
import { Trans, useTranslation } from "react-i18next";
import Meeting from "../../../models/Meeting";
import MyStore from "../../../utils/MyStore";
import useExhibition from "../../../engines/GeneralHooks/useExhibition";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import FrontendRoutes from "../../../constants/FrontendRoutes";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "grid",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    overflow: "auto",
    maxHeight: window.innerHeight * 0.7,
    // maxHeight: "1000px",
    // maxWidth: "800px",
  },
  timeSlot: {
    width: 200,
  },
  timeSlotGrid: {
    marginTop: "16px",
    overflow: "auto",
    maxHeight: "500px",
    padding: "2vh",
  },
  timeSlotCard: {
    display: "flex",
    justifyContent: "center",
    padding: "16px",
  },
  selectedTimeSlotCard: {
    display: "flex",
    justifyContent: "center",
    padding: "16px",
    backgroundColor: "lightBlue",
  },
  phantom: {
    display: "block",
    padding: "20px",
    height: "60px",
    width: "100%",
    marginTop: "1vh",
  },
  avatarItem: {
    textAlign: "center",
  },
  avatarCard: {
    justifyContent: "center",
    padding: "16px",
    flexGrow: "1",
  },
  selectedAvatarCard: {
    justifyContent: "center",
    padding: "16px",
    backgroundColor: "lightBlue",
  },
  avatar: {
    width: theme.spacing(11),
    height: theme.spacing(11),
  },
  aboutAuthor: {
    marginTop: theme.spacing(3),
  },
  aboutProject: {
    marginTop: theme.spacing(3),
  },
  projectCover: {
    marginTop: theme.spacing(3),
  },
  projectDetails: {
    marginTop: theme.spacing(3),
  },
  preferences: {
    marginTop: theme.spacing(3),
  },
  actions: {
    marginTop: theme.spacing(3),
  },
}));

export default function BookMeetingModal({ exhibitor, open, handleClose, fromClass, payload }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [selectedUser, setSelectedUser] = useState();
  const [days, setDays] = useState([]);
  const [freeSlots, setFreeSlots] = useState([]);
  const timeslots = useSelector((state) => state.timeslotsReducer);
  const exhibitorsUsersReducer = useSelector(
    (state) => state.exhibitorsUsersReducer
  );
  const dispatch = useDispatch();
  const [selectedDay, setSelectedDay] = useState(new Timeslot());
  const [exhibition] = useExhibition()
  const { trackPageView, trackEvent } = useMatomo();

  let selectedTimeSlot;

  const handleInternClose = () => {
    dispatch(clearExhibitorUsers());
    handleClose();
  };

  useEffect(() => {
    if (!open || exhibitorsUsersReducer.exhibitorsUsers.length > 0) {
      return;
    }

    dispatch(
      fetchExhibitorsUsers(exhibitor.getExhibitionId(), exhibitor.getId(), {
        pageSize: 20,
      })
    );
  }, [open]);

  useEffect(() => {
    if (
      exhibitorsUsersReducer.isFetching ||
      !exhibitorsUsersReducer.exhibitorsUsers ||
      exhibitorsUsersReducer.exhibitorsUsers.length === 0
    ) {
      return;
    }
  }, [exhibitorsUsersReducer.isFetching]);

  useEffect(() => {
    if (!selectedUser) {
      return;
    }

    dispatch(fetchTimeslots(exhibitor.getExhibitionId(), selectedUser.getId()));
  }, [selectedUser, exhibitor]);

  useEffect(() => {
    if (timeslots.timeslots.length === 0) {
      return;
    }

    setDays(timeslots.timeslots);
    let firstDaySlot = timeslots.timeslots[0];

    setFreeSlots(
      firstDaySlot
        .getFreeSlots()
        .map((slot) => `${slot.getHours()}:${slot.getMinutes()}`)
    );
  }, [timeslots.timeslots]);

  const handleSelectUser = (user) => {
    setSelectedUser(user);
  };

  const handleSelectDay = (day) => {
    setSelectedDay(day);
  };

  const handleSelectTimeslot = (timeslot) => {
    selectedTimeSlot = timeslot;
  };

  const handleCreateMeeting = () => {
    if (!selectedTimeSlot) {
      return;
    }

    let startAt = new Date(selectedTimeSlot);
    const minutesToAdd = exhibition && exhibition.getTimeslotLengthMin() ? exhibition.getTimeslotLengthMin() : 30;
    selectedTimeSlot.setMinutes(selectedTimeSlot.getMinutes() + minutesToAdd);
    let endAt = selectedTimeSlot;

    var meeting = new Meeting({
      title:
        t("frontend.your_personal_meeting_with") +
        " " +
        selectedUser.getFullName(),
      startAt: startAt,
      endAt: endAt,
      reason: Meeting.reason.ONE_TO_ONE,
      user: MyStore.getCurrentUser(),
      participants: [selectedUser],
      exhibition: exhibition,
    });

    dispatch(createMeeting(meeting));

    trackingEvent('schedule-meeting-success');

    handleInternClose();
  };

  const trackingEvent = (action) => {
    let category = "";
    switch (fromClass) {
      case "Post":
        category = "post-tracking"
        break;
      default:
        category = "exhibitor-tracking"
        break;
    }
    trackEvent({
      category: category,
      action: action,
      href: fromClass === "Post" ? FrontendRoutes.posts.show(payload.getId()):FrontendRoutes.exhibitors.show(exhibitor.getId()), // optional
      customDimensions: [
        fromClass === "Post" && {
          id: 4,
          value: payload.getId(),
        },
        {
          id: 9,
          value: exhibition.getId()
        },
        {
          id: 10,
          value: MyStore.getCurrentUser().getEmail(),
        },
        {
          id: 5,
          value: exhibitor.getId(),
        },
        
      ],
    })
  }

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Container className={classes.paper}>
            <Header exhibitor={exhibitor} />
            <AboutAuthor
              className={classes.aboutAuthor}
              handleSelectUser={handleSelectUser}
              exhibitorsUsers={exhibitorsUsersReducer.exhibitorsUsers}
            />
            <AboutProject
              className={classes.aboutProject}
              days={days}
              handleSelectDay={handleSelectDay}
            />
            <Preferences
              day={selectedDay}
              className={classes.preferences}
              handleSelectTimeslot={(timeslot) =>
                handleSelectTimeslot(timeslot)
              }
              selectedTimeslot={selectedTimeSlot}
            />
            <div className={classes.actions}>
              <Grid container direction="row">
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={handleCreateMeeting}
                >
                  <Trans>frontend.schedule_meeting</Trans>
                </Button>
                <Button
                  color="default"
                  variant="contained"
                  onClick={() => {
                    trackingEvent('schedule-meeting-canceled');
                    handleClose()
                  }}
                  style={{ marginLeft: "auto" }}
                >
                  <Trans>frontend.cancel</Trans>
                </Button>
              </Grid>
            </div>
          </Container>
        </Fade>
      </Modal>
    </div>
  );
}
