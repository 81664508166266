import ApplicationModel from "./ApplicationModel";

export default class Stage extends ApplicationModel {
  constructor(_opts = {}) {
    super(_opts);
    this.state = { ..._opts };
  }

  static className = "Keynote";

  getCurrentKeynoteId = (language) => {
    if (this.state.localDescription && this.state.localDescription.length > 0 && language)  {
      const desc = this.state.localDescription.find(element => element.getLanguage() === language);
      if (desc && desc.getActiveKeynote()) {
        return desc.getActiveKeynote().getId();
      }
    }
    return this.state.currentKeynoteId;
  };

  getStreamUrl = (language) => {
    if (this.state.localDescription && this.state.localDescription.length > 0 && language)  {
      const desc = this.state.localDescription.find(element => element.getLanguage() === language);
      if (desc && desc.getStreamUrl()) {
        return desc.getStreamUrl();
      }
    }
    return this.state.streamUrl
  };
  
  getConversationId = (language) => {
    if (this.state.localDescription && this.state.localDescription.length > 0 && language)  {
      const desc = this.state.localDescription.find(element => element.getLanguage() === language);
      if (desc && desc.getConversation()) {
        return desc.getConversation().getId();
      }
    }
    return this.state.conversationId;
  };

  getName = () => this.state.name;

  className = "Stage"

  // Backend Attributes
  static attributes = {
    ...super.attributes,
    streamUrl: "stream_url",
    currentKeynoteId: "currentKeynote",
    name: "name",
    conversationId: "conversation",
    localDescription: "local_description"
  };

  static orderByParams = {
    ...super.orderByParams,
    name: this.attributes.name,
    startAt: this.attributes.startAt,
  };

  static filterParams = {
    ...super.filterParams,
    status: this.attributes.status,
    keynoteType: this.attributes.keynoteType,
    title: this.attributes.title,
    search: '_q',
    date: "date",
    running: 'running'
  };
}
