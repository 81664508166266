import DiscussionRoom from "../models/DiscussionRoom";
import BaseMapper from "./BaseMapper";
import VideoMapper from "./VideoMapper";

const attributes = DiscussionRoom.attributes;

export default class DiscussionRoomMapper {
  static build = (json = undefined) => {
    if (!json) {
      return;
    }

    return new DiscussionRoom({
      ...BaseMapper.attributes(json),
      name: json[attributes.name],
      url: json[attributes.url],
      language: json[attributes.language],
      headerVideo: VideoMapper.build(json.header_video),
      hint: json[attributes.hint],
    });
  };

  static buildFromArray = (jsonArray = []) => {
    return jsonArray.map((entry) => {
      return this.build(entry);
    });
  };

  static toJsonString(discussionRoom) {
    return JSON.stringify({
      [discussionRoom.name]: discussionRoom.getName(),
      [discussionRoom.url]: discussionRoom.getUrl(),
      [discussionRoom.language]: discussionRoom.getLanguage(),
    });
  }
}
