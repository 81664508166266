import React, { useContext, useEffect, useState } from "react";
import {
  Typography,
  Grid,
  IconButton,
  Backdrop,
  Divider,
  Box,
  Avatar,
} from "@material-ui/core";
import { Instagram, LinkedIn, Facebook, Twitter, CheckOutlined } from '@material-ui/icons'
import AttachmentCard from "../../../../components/AttachmentCard/AttachmentCard";
import GlobalContext from "../../context/GlobalContext";
import marked from "marked";
import CloseIcon from "@material-ui/icons/Close";
import StarIcon from "@material-ui/icons/Star";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import MyStore from "../../../../../utils/MyStore";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import CogoToast from "../../../../../utils/CogoToast";
import { KeyboardArrowDown } from "@material-ui/icons";
import { bookmarkExhibitor } from "../../../../../redux/actions/ExhibitorsActions";
import { useExhibitorQL } from "../../../../../engines/GraphQL/hooks/useExhibitorsQL";
import { useHistory, useLocation } from "react-router";
import FrontendRoutes from "../../../../../constants/FrontendRoutes";
import Exhibitor from "../../../../../models/Exhibitor";
import queryString from "query-string";
import useTracking, {
  EVENT_TRACKING_TYPES,
} from "../../../../engines/tracking/useTracking";
import useStyles from './useStyles'
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import Badge from '../Badge'
import FullScreenImageView from "../FullScreenImageView";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import useBreakpoints from "../../../../../engines/Util/useBreakpoints";
import HostSectionLogo from "../../../../../assets/images/host-module/section-logo.png";
import CheckMark from "../../../../../assets/images/host-module/check-mark.png";
import NeumorphicStyle from "../../../../../constants/Neumorphic";
import {useTheme} from "@material-ui/styles"

const SOCIAL_LINK_TYPE = {
  insta: "instagram",
  linkedin: "linkedin",
  facebook: "facebook",
  twitter: "twitter",
}

function ExhibitorDetailsModal({
  payload,
  handleClose,
  exhibitorList = [],
  setCurrentExhibitorList = null,
}) {
  const [trackEvent, trackVisit] = useTracking();
  const [breakpoint] = useBreakpoints();
  const classes = useStyles({ breakpoint });
  const context = useContext(GlobalContext);
  const [open, setOpen] = useState(false);
  const [openFullScrennImage, setOpenFullScreenImage] = useState(null)
  const [exhibitorId, setExhibitorId] = useState();
  const [exhibitor] = useExhibitorQL(exhibitorId);
  const dispatch = useDispatch();
  const [t] = useTranslation();
  const [attachmentsOpen, setAttachmentsOpen] = useState(false);
  const [carouselIndex, setCarouselIndex] = useState(0);
  const history = useHistory();
  const location = useLocation();

  const [bookmarked, setBookmarked] = useState(false);
  const [bookmarksLoading, setBookmarksLoading] = useState(false);
  const [unbookmarked, setUnbookmarked] = useState(false);
  const exhibitorsReducer = useSelector((state) => state.exhibitorsReducer);

  const [videoPlaying, setVideoPlaying] = useState(false);
  const [time, setTime] = useState();
  const theme = useTheme();

  const handleOpenLink = (url,socialLinkType = undefined) => {
    url = (!url.match(/^https?:\/\//i) ? "https://" : "") + url;
    window.open(url, "_blank");
    if (socialLinkType) {
      trackEvent(EVENT_TRACKING_TYPES.exhibitor.openedSocialLink, {
        exhibitor: context.currentExhibitor.getId(),
        exhibitor_name: context.currentExhibitor.getName(),
        language: MyStore.getLocal(),
        type: socialLinkType
      });
    } else {
      trackEvent(EVENT_TRACKING_TYPES.exhibitor.websiteOpened, {
        exhibitor: context.currentExhibitor.getId(),
        exhibitor_name: context.currentExhibitor.getName(),
        language: MyStore.getLocal(),
      });
    }
  }

  useEffect(() => {
    return () => {
      handleVideoPlayPause(true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {

    const onbeforeunloadFn = () => {
      if (context.currentExhibitor) {
        trackEvent(EVENT_TRACKING_TYPES.exhibitor.videoPaused, {
          exhibitor: context.currentExhibitor.getId(),
          exhibitor_name: context.currentExhibitor.getName(),
          language: MyStore.getLocal(),
          timeViewed: Date.now() - time,
        });
      }
    }

    window.addEventListener('beforeunload', onbeforeunloadFn);
    return () => {
      window.removeEventListener('beforeunload', onbeforeunloadFn);
    }
  }, [videoPlaying])

  useEffect(() => {
    setOpen(context.currentExhibitor !== undefined);
    if (!context.currentExhibitor) {
      if (exhibitorId) {
        trackEvent(
          exhibitor.getSponsorName()
            ? EVENT_TRACKING_TYPES.sponsors.leftSponsor
            : EVENT_TRACKING_TYPES.exhibitor.leftExhibitor,
          {
            exhibitor: exhibitor.getId(),
            exhibitor_name: exhibitor.getName(),
          }
        );
      }
      setExhibitorId(undefined);
      setCarouselIndex(0);
      setAttachmentsOpen(false);
      if (location.search.includes("exhibitor")) {
        history.push(FrontendRoutes.root());
      }
      return;
    }
    setExhibitorId(context.currentExhibitor.getId());
    if (!location.search.includes("exhibitor")) {
      history.push(
        FrontendRoutes.exhibitors.show(context.currentExhibitor.getId())
      );
    }
    // trackVisit(VISIT_TRACKING_TYPES.exhibitor, { // Exhibitor Modal
    //   exhibitor: context.currentExhibitor.getId(),
    // });
    trackEvent(
      context.currentExhibitor.getSponsorName()
        ? EVENT_TRACKING_TYPES.sponsors.viewedSponsor //Sponsor Viewed
        : EVENT_TRACKING_TYPES.exhibitor.exhibitorView, //Exhibitor Viewed
      {
        exhibitor: context.currentExhibitor.getId(),
        exhibitor_name: context.currentExhibitor.getName(),
        exhibitorHall: context.talkingPointId,
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context.currentExhibitor]);

  useEffect(() => {
    let searchParams = queryString.parse(location.search);
    if (
      searchParams.exhibitor &&
      searchParams.exhibitor !== "" &&
      !context.currentExhibitor
    ) {
      context.setCurrentExhibitor(
        new Exhibitor({ id: searchParams.exhibitor })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  useEffect(() => {
    if (!unbookmarked) {
      return;
    }

    dispatch(bookmarkExhibitor(exhibitor));
    trackEvent(EVENT_TRACKING_TYPES.exhibitor.unbookmarked, {
      exhibitor: context.currentExhibitor.getId(),
    });
    setBookmarksLoading(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unbookmarked]);

  useEffect(() => {
    if (!bookmarked) {
      return;
    }

    dispatch(bookmarkExhibitor(exhibitor));
    trackEvent(EVENT_TRACKING_TYPES.exhibitor.bookmark, {
      exhibitor: context.currentExhibitor.getId(),
    });
    setBookmarksLoading(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookmarked]);

  const handleDownload = (document, factsheet = false) => {
    window.open(document.getFileUrl());
    trackEvent(EVENT_TRACKING_TYPES.exhibitor.documentDownload, {
      exhibitor: exhibitor.getId(),
      file_name: document.getName(),
      factsheet: factsheet ? true : false,
    })
  }

  // Bookmark
  useEffect(() => {
    if (
      (!bookmarked && !unbookmarked) ||
      !bookmarksLoading ||
      exhibitorsReducer.isFetching ||
      exhibitorsReducer.error.isError()
    ) {
      return;
    }

    if (bookmarked) {
      exhibitor.setIsBookmarked(true);
      CogoToast.info(t("frontend.added_bookmark"));
    }

    if (unbookmarked) {
      exhibitor.setIsBookmarked(false);
      CogoToast.info(t("frontend.removed_bookmark"));
    }

    setBookmarked(false);
    setUnbookmarked(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exhibitorsReducer.isFetching]);

  const handleBookmark = () =>
    exhibitor.isBookmarked() ? setUnbookmarked(true) : setBookmarked(true);

  const handleVideoPlayPause = (closing) => {
    if (closing && !videoPlaying) {
      return;
    }

    if (videoPlaying) {
      trackEvent(EVENT_TRACKING_TYPES.exhibitor.videoPaused, {
        exhibitor: context.currentExhibitor.getId(),
        exhibitor_name: context.currentExhibitor.getName(),
        language: MyStore.getLocal(),
        timeViewed: Date.now() - time,
      });
      setVideoPlaying(false);
      setTime(undefined);
    } else {
      setTime(Date.now());
      trackEvent(EVENT_TRACKING_TYPES.exhibitor.videoStarted, {
        exhibitor: context.currentExhibitor.getId(),
        exhibitor_name: context.currentExhibitor.getName(),
        language: MyStore.getLocal(),
      });
      setVideoPlaying(true);
    }
  }

  const prevExhibitor = React.useMemo(() => {
    if (!exhibitor || exhibitorList.length === 0) return undefined;

    const currentExhibitorIndex = exhibitorList.findIndex(
      (loopExhibitor) => loopExhibitor.getId() === exhibitor.getId()
    );

    if (
      currentExhibitorIndex <= 0 ||
      currentExhibitorIndex > exhibitorList.length - 1
    ) {

      return undefined;
    }

    return exhibitorList[currentExhibitorIndex - 1];
  }, [exhibitor, exhibitorList]);

  const nextExhibitor = React.useMemo(() => {
    if (!exhibitor || exhibitorList.length === 0) return undefined;
    const currentExhibitorIndex = exhibitorList.findIndex(
      (loopExhibitor) => loopExhibitor.getId() === exhibitor.getId()
    );

    if (
      currentExhibitorIndex <= -1 ||
      currentExhibitorIndex >= exhibitorList.length - 1
    ) {
      return undefined;
    }
    return exhibitorList[currentExhibitorIndex + 1];
  }, [exhibitor, exhibitorList]);

  return !exhibitor ? (
    <Box />
  ) : (
    <>
      {openFullScrennImage === null ? (
        <Backdrop
          className={classes.backdrop}
          open={open}
          onClick={() => {
            if (setCurrentExhibitorList) {
              setCurrentExhibitorList([]);
            }
            context.setCurrentExhibitor(undefined);
            handleVideoPlayPause(true);
          }}
        >
          <Box
            display="flex"
            alignItems="center"
            mr={7}
            width={1}
            height={1}
            mt={24}
            position="relative"
            left={16}
          >
            {prevExhibitor !== undefined && (
              <Box mr={-6.25} zIndex={2}>
                <IconButton
                  size="medium"
                  className={classes.arrowIcons}
                  onClick={(e) => { e.stopPropagation(); context.setCurrentExhibitor(prevExhibitor) }}
                >
                  <NavigateBeforeIcon />
                </IconButton>
              </Box>
            )}
            <Box height={1} width={1} className={classes.responsive}>
              <Box pb={2} className={classes.root} onClick={(e) => e.stopPropagation()} height={"calc(100% - 117px)"}>
                <Box display="contents">
                  <Box pt={2} display="flex" flexDirection="row-reverse">
                    <IconButton
                      className={classes.closeIcon}
                      onClick={() => {
                        if (setCurrentExhibitorList) {
                          setCurrentExhibitorList([]);
                        }
                        context.setCurrentExhibitor(undefined);
                        handleVideoPlayPause(true);
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                    {exhibitor.getBadge().length > 0 && (<Badge badges={exhibitor.getBadge()} />)}
                    <Grid
                      item
                      container
                      className={classes.avatarRow}
                      direction="row"
                      justify="center"
                    >
                      <Avatar src={
                        exhibitor && exhibitor.getProfilePicture()
                          ? exhibitor.getProfilePicture().getUrl("medium")
                          : ""
                      }
                        className={classes.avatar}
                      />
                      <Typography className={classes.title} variant="h1">
                        {exhibitor ? exhibitor.getName() : ""}
                      </Typography>
                      {exhibitor && exhibitor.isBookmarked() ? (
                        <StarIcon
                          onClick={handleBookmark}
                          className={classes.favIconFilled}
                        />
                      ) : (
                        <StarBorderIcon
                          onClick={handleBookmark}
                          className={classes.favIcon}
                        />
                      )}
                    </Grid>
                  </Box>
                  {exhibitor && exhibitor.getProfileVideo(MyStore.getLocal()) ? (
                    <Box width="100%" mt={2} pt={"56.25%"} overflow="hidden" className={classes.videoBox}>
                      <video
                        src={exhibitor.getProfileVideo(MyStore.getLocal()).getUrl()}
                        onPlay={() => {
                          context.setMuted(true);
                          handleVideoPlayPause();
                        }}
                        onPause={() => {
                          context.setMuted(false);
                          handleVideoPlayPause();
                        }}
                        on
                        className={classes.video}
                        disablePictureInPicture
                        controls
                        controlsList="nodownload"
                      />
                    </Box>
                  ) : exhibitor && exhibitor.getHeaderPicture() && (
                    <Box width="100%" mt={2} pt={"56.25%"} overflow="hidden" className={classes.videoBox}>
                      <img
                        src={
                          exhibitor && exhibitor.getHeaderPicture()
                            ? exhibitor.getHeaderPicture().getUrl()
                            : ""
                        }
                        className={classes.video}
                      />
                    </Box>
                  )}
                  <Box pt={2}>
                    <Box display="flex" justifyContent="space-between">
                      {(!exhibitor.getSponsorName() && exhibitor.getTags() && (
                        <Typography
                          variant="h3"
                          color="textSecondary"
                        >
                          {`${t("frontend.categories")}: ${exhibitor.getTags().map((t, i) => `${i > 0 ? " " : ""} ${t.getName(MyStore.getLocal())}`).join(',')}`}
                        </Typography>
                      ))}
                    </Box>
                    <Box display="flex" justifyContent="flex-end" pb={0.5}>
                      {(exhibitor.getYearOfEstablishment() && (
                        <Typography variant="h3" color="textSecondary">{`${t("frontend.year_of_establishment")}: ${exhibitor.getYearOfEstablishment()}`}</Typography>
                      ))}
                    </Box>
                    <Box display="flex" justifyContent="space-between">
                      {!exhibitor.getSponsorName() && exhibitor.getExhibitorMaxPrice() && exhibitor.getExhibitorMinPrice() && (
                        <Box display="flex">
                          <Typography variant="h3" color="textSecondary"><Trans>frontend.price_range</Trans>:</Typography>
                          {exhibitor.getExhibitorMaxPrice() && exhibitor.getExhibitorMinPrice() && (
                            <Box>
                              <Typography variant="h3" color="textSecondary" className={classes.textInfo}>€ {exhibitor.getExhibitorMinPrice()} - € {exhibitor.getExhibitorMaxPrice()}</Typography>
                            </Box>
                          )}
                        </Box>
                      )}
                      {exhibitor.getCompanyWebsite() && (
                        <Box onClick={() => handleOpenLink(exhibitor.getCompanyWebsite())}>
                          <Typography className={classes.pointer} variant="h3" color="textSecondary">
                            {exhibitor.getCompanyWebsite()}
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  </Box>
                  <Box pt={4} display="flex" justifyContent="space-between">
                    <Box display="flex">
                      {exhibitor.getFactSheet(MyStore.getLocal()) && (
                        <Box className={classes.attachmentDropdownContainer}>
                          <Box
                            onClick={() => handleDownload(exhibitor.getFactSheet(MyStore.getLocal()), true)}
                            className={classes.attachmentDropdown}
                          >
                            <Box display="flex">
                              <Typography
                                className={classes.attachmentDropdownTitle}
                                variant="h4"
                              >
                                <Trans>frontend.download_fact_sheet</Trans>
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      )}
                    </Box>
                    <Box display="flex">
                      {exhibitor.getDocuments() && exhibitor.getDocuments().length > 0 && (
                        <Box className={classes.attachmentDropdownContainer}>
                          <Box
                            onClick={() => setAttachmentsOpen(!attachmentsOpen)}
                            className={
                              attachmentsOpen
                                ? classes.attachmentDropdownOpen
                                : classes.attachmentDropdown
                            }
                            style={{
                              height: attachmentsOpen
                                ? exhibitor.getDocuments().length * 45 + 55
                                : 50,
                            }}
                          >
                            <Box display="flex">
                              <Typography
                                className={classes.attachmentDropdownTitle}
                                variant="h4"
                              >
                                <Trans>frontend.download_catalogs</Trans>
                              </Typography>
                              <KeyboardArrowDown
                                className={
                                  attachmentsOpen
                                    ? classes.closeDropdownIcon
                                    : classes.openDropdownIcon
                                }
                                style={{ marginLeft: "auto" }}
                              />
                            </Box>
                            {attachmentsOpen && (
                              <Divider color="black" variant="fullWidth" />
                            )}
                            {attachmentsOpen &&
                              exhibitor.getDocuments().map((document) => (
                                <Box style={{ marginTop: 10 }}>
                                  <AttachmentCard document={document} />
                                </Box>
                              ))}
                          </Box>
                        </Box>
                      )}
                    </Box>
                  </Box>
                  <Box>
                    <Box pt={2}>
                      {exhibitor.getTitle(MyStore.getLocal()) && (
                        <Box>
                          <Typography variant="h3" style={{ fontWeight: "bold", marginBottom: "25px", marginTop: "25px" }}>{exhibitor.getTitle(MyStore.getLocal())}</Typography>
                        </Box>
                      )}
                      <Box className={classes.content} dangerouslySetInnerHTML={{
                        __html: marked(
                          exhibitor.getDescription(MyStore.getLocal()) || ""
                        ),
                      }} />
                    </Box>
                    <Box display="flex" marginTop="15px" justifyContent="flex-end">
                      {exhibitor.getInstagramUrl() && (<IconButton onClick={() => handleOpenLink(exhibitor.getInstagramUrl(),SOCIAL_LINK_TYPE.insta)} className={classes.socialIconButton}><Instagram className={classes.socialIcon} /></IconButton>)}
                      {exhibitor.getTwitterUrl() && (<IconButton onClick={() => handleOpenLink(exhibitor.getTwitterUrl(),SOCIAL_LINK_TYPE.twitter)} className={classes.socialIconButton}><Twitter className={classes.socialIcon} /></IconButton>)}
                      {exhibitor.getLinkedInUrl() && (<IconButton onClick={() => handleOpenLink(exhibitor.getLinkedInUrl(),SOCIAL_LINK_TYPE.linkedin)} className={classes.socialIconButton}><LinkedIn className={classes.socialIcon} /></IconButton>)}
                      {exhibitor.getFacebookUrl() && (<IconButton onClick={() => handleOpenLink(exhibitor.getFacebookUrl(),SOCIAL_LINK_TYPE.facebook)} className={classes.socialIconButton}><Facebook className={classes.socialIcon} /></IconButton>)}
                    </Box>
                  </Box>
                  <Box pt={2}>
                    <Grid
                      container
                      alignItems="stretch"
                      direction="row"
                      style={{ position: "relative", width: "100%" }}
                    >
                      {exhibitor.getPictures() &&
                        exhibitor.getPictures().map((picture, i) =>
                          Math.floor(i / 3) === carouselIndex ? (
                            <Grid
                              item
                              className={classes.carouselContainer}
                            >
                              <Box onClick={() => setOpenFullScreenImage(i)} position="absolute" width="100%" height="100%" p={1.25}>
                                <img
                                  src={picture.getUrl("medium")}
                                  className={classes.carouselImage}
                                />
                              </Box>
                            </Grid>
                          ) : undefined
                        )}
                    </Grid>
                    <Grid
                      direction="row"
                      justify="center"
                      alignItems="center"
                      container
                      className={classes.carouselButtons}
                    >
                      {carouselIndex > 0 ? (
                        <ArrowBackIosIcon
                          onClick={() => setCarouselIndex(carouselIndex - 1)}
                          className={classes.pointer}
                        />
                      ) : (
                        <div style={{ width: 25 }} />
                      )}
                      {exhibitor.getPictures().map((p, i) =>
                        i % 3 === 0 ? (
                          <FiberManualRecordIcon
                            onClick={() => setCarouselIndex(Math.floor((i + 1) / 3))}
                            style={{
                              color:
                                carouselIndex === Math.floor((i + 1) / 3)
                                  ? "grey"
                                  : "lightgrey",
                              cursor: "pointer",
                              width: 15,
                              height: 15,
                            }}
                          />
                        ) : (
                          <div />
                        )
                      )}
                      {exhibitor.getPictures() &&
                        Math.floor((exhibitor.getPictures().length - 1) / 3) >
                        carouselIndex ? (
                        <ArrowForwardIosIcon
                          onClick={() => setCarouselIndex(carouselIndex + 1)}
                          className={classes.pointer}
                          style={{ width: 25 }}
                        />
                      ) : (
                        <Box width="25px" />
                      )}
                    </Grid>
                  </Box>
                  {exhibitor.getHostProducts() && (
                    <Grid container direction="column" style={{ marginTop: "40px", background: "white", borderRadius: NeumorphicStyle.borderRadius, padding: "20px", paddingBottom: "30px", boxShadow: NeumorphicStyle.boxShadowSmall(
                      theme.palette.primary.main,
                      theme.palette.secondary.dark
                    ) }}>
                      <Grid container item xs={12}>
                        <img src={HostSectionLogo} style={{
                          width: "65%",
                          height: "50px",
                          maxWidth: "300px",
                          objectFit: "scale-down",
                          marginLeft: "auto",
                          marginRight: "auto",
                        }} />
                      </Grid>
                      <Grid item container direction="row" xs={10} style={{ marginLeft: "auto", marginRight: "auto", marginTop: 15 }}>
                        <Typography variant="h4" style={{ fontWeight: "normal", marginTop: "10px", marginBottom: "10px", marginLeft: "auto", marginRight: "auto" }}>THIS BRAND PARTICIPATES IN FOLLOWING FASHION CLOUD SERVICES</Typography>
                      </Grid>
                      <Grid item container direction="row" xs={8} style={{ marginLeft: "auto", paddingLeft: "10%", marginRight: "auto", marginTop: 20 }}>
                        <Grid container item xs={1} justify="center">
                          <img src={exhibitor.getHostProducts().product_1 ? CheckMark : ""} style={{ marginLeft: "auto", marginTop: 3, marginRight: 8, height: 16, objectFit: "scale-down" }} />
                        </Grid>
                        <Grid item xs={5}>
                          <Typography variant="h4" style={{ fontWeight: "normal", lineHeight: "24px" }}>Content</Typography>
                        </Grid>
                        <Grid container item xs={1}>
                          <img src={exhibitor.getHostProducts().product_2 ? CheckMark : ""} style={{ marginLeft: "auto", marginTop: 3, marginRight: 8, height: 16, objectFit: "scale-down" }} />
                        </Grid>
                        <Grid item xs={5}>
                          <Typography variant="h4" style={{ fontWeight: "normal", lineHeight: "18px" }}>Reorder (B2B)</Typography>
                        </Grid>
                      </Grid>
                      <Grid item container direction="row" xs={8} style={{ marginLeft: "auto", marginRight: "auto", paddingLeft: "10%", marginTop: 20 }}>
                        <Grid container item xs={1}>
                          <img src={exhibitor.getHostProducts().product_3 ? CheckMark : ""} style={{ marginLeft: "auto", marginTop: 3, marginRight: 8, height: 16, objectFit: "scale-down" }} />
                        </Grid>
                        <Grid item xs={5}>
                          <Typography variant="h4" style={{ fontWeight: "normal", lineHeight: "18px" }}>Clara / POS Reorder</Typography>
                        </Grid>
                        <Grid container item xs={1}>
                          <img src={exhibitor.getHostProducts().product_4 ? CheckMark : ""} style={{ marginLeft: "auto", marginTop: 3, marginRight: 8, height: 16, objectFit: "scale-down" }} />
                        </Grid>
                        <Grid item xs={5}>
                          <Typography variant="h4" style={{ fontWeight: "normal", lineHeight: "18px" }}>Preorder (Digital/Web)</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                </Box>
              </Box>
            </Box>
            {nextExhibitor && (
              <Box ml={-7} zIndex={2}>
                <IconButton
                  size="medium"
                  className={classes.arrowIcons}
                  onClick={(e) => { e.stopPropagation(); context.setCurrentExhibitor(nextExhibitor) }}
                >
                  <NavigateNextIcon />
                </IconButton>
              </Box>
            )}
          </Box>
        </Backdrop>
      ) : (
        <FullScreenImageView pictures={exhibitor.getPictures()} setCurrentImage={setOpenFullScreenImage} currentImage={openFullScrennImage} />
      )}
    </>
  );
}

export default ExhibitorDetailsModal;
