import React from 'react'
import { ListItem, ListItemAvatar, ListItemText, Avatar } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles';
import { Link as RouterLink } from 'react-router-dom';
import FrontendRoutes from "../../../constants/FrontendRoutes";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import ShareIcon from '@material-ui/icons/Share';
import OndemandVideoIcon from '@material-ui/icons/OndemandVideo';
import InsertInvitationIcon from '@material-ui/icons/InsertInvitation';
import DoneIcon from '@material-ui/icons/Done';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
    root: {},
    listItem: {
      '&:hover': {
        backgroundColor: theme.palette.background.default
      }
    },
    avatarBlue: {
      backgroundColor: "blue"
    },
    avatarGreen: {
        backgroundColor: "green"
    },
    avatarOrange: {
        backgroundColor: "orange"
    },
    avatarIndigo: {
        backgroundColor: "indigo"
    },
    avatarRed: {
        backgroundColor: "red"
    },
}));

function MeetingDeclined({meetingUser, payload}) {
    const classes = useStyles()
    const { t } = useTranslation();
    const dispatch = useDispatch();

    return !meetingUser ? (<div/>):(
        <ListItem
            className={classes.listItem}
            key={meetingUser.getCreatedAt()}
        >
        <ListItemAvatar>
        {   
            <Avatar className={classes.avatarRed}>
                <CancelIcon />
            </Avatar>
        }
        </ListItemAvatar>
        <ListItemText
            primary={payload.getOtherUser().getFullName() + t("frontend.notifications.meeting.declined")}
            primaryTypographyProps={{ variant: 'body1' }}
            secondary={moment(meetingUser.getRawCreatedAt()).fromNow()}
        />
    </ListItem>
    )}

export default MeetingDeclined
