import ApplicationModel from "./ApplicationModel";

export default class Bookmark extends ApplicationModel {
  constructor(_opts = {}) {
    super(_opts);
    this.state = { ..._opts };
  }

  getExhibitionId = () => this.state.exhibitionId;
  getBookmarkable = () => this.state.bookmarkable;
  setBookmarkable = (bookmarkable) => this.state.bookmarkable = bookmarkable;
  getBookmarkableId = () => this.state.bookmarkableId;
  getBookmarkableType = () => this.state.bookmarkableType;

  static attributes = {
    ...super.attributes,
    bookmarkable: "bookmarkable",
    exhibitionId: "exhibition_id",
    bookmarkableId: "sharable_id",
    bookmarkableType: "sharable_type",
  };

  static orderByParams = {
    ...super.orderByParams,
  };

  static filterParams = {
    ...super.filterParams,
    bookmarkableType: "bookmarkable_type",
    search: '_q',
  };
}
