import {
  CREATE_EXHIBITION_KEYNOTES_USER_REQUEST,
  CREATE_EXHIBITION_KEYNOTES_USER_SUCCESS,
  CREATE_EXHIBITION_KEYNOTES_USER_FAILURE,

  DELETE_EXHIBITION_KEYNOTES_USER_REQUEST,
  DELETE_EXHIBITION_KEYNOTES_USER_SUCCESS,
  DELETE_EXHIBITION_KEYNOTES_USER_FAILURE
} from '../actions/types';
import ApplicationError from '../../utils/ApplicationError';

const initialState = {
  keynote: undefined,
  error: ApplicationError.buildDefault(),
  isFetching: false
};

const keynotesReducer = (state = initialState, action ) => {
  switch(action.type) {
    case  CREATE_EXHIBITION_KEYNOTES_USER_REQUEST:
      return {...state, isFetching: true}
    case CREATE_EXHIBITION_KEYNOTES_USER_SUCCESS:
      return {...state, isFetching: false, keynote: action.payload, error: ApplicationError.buildDefault() }
    case CREATE_EXHIBITION_KEYNOTES_USER_FAILURE: 
      return {...state, isFetching: false, error: action.payload }

    case DELETE_EXHIBITION_KEYNOTES_USER_REQUEST:
      return {...state, isFetching: true}
    case DELETE_EXHIBITION_KEYNOTES_USER_SUCCESS:
      return {...state, isFetching: false, error: ApplicationError.buildDefault() }
    case DELETE_EXHIBITION_KEYNOTES_USER_FAILURE:
      return {...state, isFetching: false, error: action.payload}
      
    default: 
      return state
  }
}

export default keynotesReducer;