import {
  FETCHING_EMPLOYEES_REQUEST,
  FETCHING_EMPLOYEES_SUCCESS,
  FETCHING_EMPLOYEES_FAILURE,
  FETCHING_EMPLOYEE_REQUEST,
  FETCHING_EMPLOYEE_SUCCESS,
  FETCHING_EMPLOYEE_FAILURE,
  CREATE_EMPLOYEE_REQUEST,
  CREATE_EMPLOYEE_SUCCESS,
  CREATE_EMPLOYEE_FAILURE,
  UPDATE_EMPLOYEE_REQUEST,
  UPDATE_EMPLOYEE_SUCCESS,
  UPDATE_EMPLOYEE_FAILURE,
  DELETE_EMPLOYEE_REQUEST,
  DELETE_EMPLOYEE_SUCCESS,
  DELETE_EMPLOYEE_FAILURE,
} from "../actions/types";
import ApplicationError from "../../utils/ApplicationError";
import ResponseService from "../../utils/ResponseService";

const initialState = {
  employees: [],
  employee: undefined,
  error: ApplicationError.buildDefault(),
  isFetching: false,
  ...ResponseService.defaultPaginationMetaData(),
};

const employeesReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCHING_EMPLOYEES_REQUEST:
      return { ...state, isFetching: true };
    case FETCHING_EMPLOYEES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        employees: action.payload.objects,
        error: ApplicationError.buildDefault(),
        ...action.payload.paginationData,
      };

    case FETCHING_EMPLOYEES_FAILURE:
      return { ...state, isFetching: false, error: action.payload };

    case FETCHING_EMPLOYEE_REQUEST:
      return { ...state, isFetching: true };
    case FETCHING_EMPLOYEE_SUCCESS:
      return { ...state, isFetching: false, employee: action.payload };
    case FETCHING_EMPLOYEE_FAILURE:
      return { ...state, isFetching: false, error: action.payload };

    case CREATE_EMPLOYEE_REQUEST:
      return { ...state, isFetching: true };
    case CREATE_EMPLOYEE_SUCCESS:
      return { ...state, isFetching: false, employee: action.payload };
    case CREATE_EMPLOYEE_FAILURE:
      return { ...state, isFetching: false, error: action.payload };

    case UPDATE_EMPLOYEE_REQUEST:
      return { ...state, isFetching: true };
    case UPDATE_EMPLOYEE_SUCCESS:
      return { ...state, isFetching: false, employee: action.payload };
    case UPDATE_EMPLOYEE_FAILURE:
      return { ...state, isFetching: false, error: action.payload };

    case DELETE_EMPLOYEE_REQUEST:
      return { ...state, isFetching: true };
    case DELETE_EMPLOYEE_SUCCESS:
      return { ...state, isFetching: false, employee: action.payload };
    case DELETE_EMPLOYEE_FAILURE:
      return { ...state, isFetching: false, error: action.payload };

    default:
      return state;
  }
};

export default employeesReducer;
