import {
  FETCHING_EXHIBITION_KEYNOTES_REQUEST,
  FETCHING_EXHIBITION_KEYNOTES_SUCCESS,
  FETCHING_EXHIBITION_KEYNOTES_FAILURE,
  
  FETCHING_EXHIBITION_KEYNOTE_REQUEST,
  FETCHING_EXHIBITION_KEYNOTE_SUCCESS,
  FETCHING_EXHIBITION_KEYNOTE_FAILURE,

  CREATE_EXHIBITION_KEYNOTE_FAILURE,
  CREATE_EXHIBITION_KEYNOTE_SUCCESS,
  CREATE_EXHIBITION_KEYNOTE_REQUEST,

  UPDATE_EXHIBITION_KEYNOTE_FAILURE,
  UPDATE_EXHIBITION_KEYNOTE_SUCCESS,
  UPDATE_EXHIBITION_KEYNOTE_REQUEST,

  DELETE_EXHIBITION_KEYNOTE_FAILURE,
  DELETE_EXHIBITION_KEYNOTE_SUCCESS,
  DELETE_EXHIBITION_KEYNOTE_REQUEST,
  CLEAR_EXHIBITION_KEYNOTE
} from '../actions/types';
import ResponseService from '../../utils/ResponseService';
import ApplicationError from '../../utils/ApplicationError';

const initialState = {
  keynotes: [],
  keynote: undefined,
  error: ApplicationError.buildDefault(),
  isFetching: false,
  ...ResponseService.defaultPaginationMetaData()
};

const keynotesReducer = (state = initialState, action ) => {
  switch(action.type) {
    case FETCHING_EXHIBITION_KEYNOTES_REQUEST:
      return {...state, isFetching: true, keynotes: [] }
    case FETCHING_EXHIBITION_KEYNOTES_SUCCESS:
      return {...state, isFetching: false, 
        keynotes: action.payload.objects, 
        error: ApplicationError.buildDefault(),
        ...action.payload.paginationData }

    case FETCHING_EXHIBITION_KEYNOTES_FAILURE:
      return {...state, isFetching: false, error: action.payload }
    
    case FETCHING_EXHIBITION_KEYNOTE_REQUEST:
      return {...state, isFetching: true }
    case FETCHING_EXHIBITION_KEYNOTE_SUCCESS: 
      return {...state, isFetching: false, keynote: action.payload, error: ApplicationError.buildDefault() }
    case FETCHING_EXHIBITION_KEYNOTE_FAILURE:
      return {...state, isFetching: false, error: action.payload }  

    case  CREATE_EXHIBITION_KEYNOTE_REQUEST:
      return {...state, isFetching: true }
    case  CREATE_EXHIBITION_KEYNOTE_SUCCESS:
      return {...state, isFetching: false, keynote: action.payload, error: ApplicationError.buildDefault() }
    case  CREATE_EXHIBITION_KEYNOTE_FAILURE:
      return {...state, isFetching: false, error: action.payload }

    case  UPDATE_EXHIBITION_KEYNOTE_REQUEST:
      return {...state, isFetching: true }
    case  UPDATE_EXHIBITION_KEYNOTE_SUCCESS:
      return {...state, isFetching: false, keynote: action.payload, error: ApplicationError.buildDefault() }
    case  UPDATE_EXHIBITION_KEYNOTE_FAILURE:
      return {...state, isFetching: false, error: action.payload }

    case  DELETE_EXHIBITION_KEYNOTE_REQUEST:
      return {...state, isFetching: true }
    case  DELETE_EXHIBITION_KEYNOTE_SUCCESS:
      return {...state, isFetching: false, error: ApplicationError.buildDefault() }
    case  DELETE_EXHIBITION_KEYNOTE_FAILURE:
      return {...state, isFetching: false, error: action.payload }

    case CLEAR_EXHIBITION_KEYNOTE: 
        return {...initialState}

    default: 
      return state
  }
}

export default keynotesReducer;