import Organization from "../models/Organization";
import BaseMapper from "./BaseMapper";

export default class OrganizationMapper {
  static build = (json = undefined) => {
    if (!json) {
      return;
    }

    return new Organization({
      ...BaseMapper.attributes(json),
      name: json.name
    });
  };

  static buildFromArray = (jsonArray = []) => {
    return jsonArray.map(entry => {
      return this.build(entry);
    });
  };

  static toJsonString(organization) {
    return JSON.stringify({
      [Organization.attributes.name]: organization.getName()
    });
  }
}
