import { TextField, Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Popover from "@material-ui/core/Popover";
import Slider from "@material-ui/core/Slider";
import InputAdornment from '@material-ui/core/InputAdornment'
import { makeStyles } from "@material-ui/core/styles";
import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import FilterListIcon from '@material-ui/icons/FilterList'

const useStyles = makeStyles((theme) => ({
  popoverInnerRoot: {
    width: ({ sliderWidth }) => sliderWidth,
  },
  sliderRoot: {
    color: "#3a8589",
    height: 3,
    padding: "13px 0",
  },
  buttonRoot: {
    borderWidth: ({ isSelected }) => (isSelected ? 2 : 1),
    borderRadius: 0,
    maxHeight: 45,
  },
  thumb: {
    height: 27,
    width: 27,
    backgroundColor: "#fff",
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: theme.palette.secondary.main,
    marginTop: -12,
    marginLeft: -13,
    "& .bar": {
      height: 9,
      width: 1,
      backgroundColor: theme.palette.secondary.main,
      marginLeft: 1,
      marginRight: 1,
    },
  },
  active: {},
  track: {
    height: 3,
    backgroundColor: theme.palette.secondary.main,
  },
  rail: {
    color: "white",
    opacity: 1,
    height: 1,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: theme.palette.secondary.main,
  },
}));

export default function NumberRange({
  prefixId,
  buttonText,
  minimum,
  maximum,
  leftValue,
  rightValue,
  setValues,
  sliderWidth = 300,
  step = 1,
}) {
  const { t } = useTranslation();
  const [insideLeftValue, setInsideLeftValue] = useState(minimum);
  const [insideRightValue, setInsideRightValue] = useState(maximum);
  const classes = useStyles({
    sliderWidth,
    isSelected: insideLeftValue !== minimum || insideRightValue !== maximum,
  });

  const handleValueChange = (_, newValue) => {
    setInsideLeftValue(newValue[0]);
    setInsideRightValue(newValue[1]);
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
    setInsideLeftValue(leftValue);
    setInsideRightValue(rightValue);
  };

  const handleClose = () => {
    setValues(insideLeftValue, insideRightValue);
    setAnchorEl(null);
  };

  const handleSave = () => {
    handleClose();
  };

  const open = Boolean(anchorEl);
  const id = open ? `${prefixId}-number-range-popover` : undefined;
  const isTouched = leftValue !== minimum || rightValue !== maximum;

  return (
    <>
      <Button
        aria-describedby={id}
        variant="outlined"
        onClick={handleOpen}
        classes={{ root: classes.buttonRoot }}
      >
        <Box p={1} height={45} display="flex" alignItems="center">
          {buttonText}
          &nbsp;
          {isTouched && (
            <Box color="secondary.main">
              <FilterListIcon />
            </Box>
          )}
          
        </Box>
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Box className={classes.popoverInnerRoot} p={2}>
          <Box display="flex" justifyContent="space-around" alignItems="center" marginBottom={1}>
            <Typography variant="h4">
              <Trans>
                frontend.wholesale_price
              </Trans>
            </Typography>
          </Box>
          <Box display="flex" justifyContent="space-around" alignItems="center">
            <TextField
              type="number"
              value={insideLeftValue}
              InputProps={{ 
                inputProps: {
                  min: minimum,
                  max: maximum
                },
                startAdornment: <InputAdornment position="start">€</InputAdornment>,
               }}
              onChange={(event) => {
                if (
                  event.target.value >= minimum &&
                  event.target.value <= maximum &&
                  event.target.value <= insideRightValue
                ) {
                  setInsideLeftValue(event.target.value);
                }
              }}
              placeholder={t(
                "frontend.components.numberRange.leftValuePlaceholder"
              )}
              variant="outlined"
            />
            <Box px={1}>-</Box>
            <TextField
              type="number"
              value={insideRightValue}
              InputProps={{ 
                inputProps: {
                  min: minimum,
                  max: maximum
                },
                startAdornment: <InputAdornment position="start">€</InputAdornment>,
               }}
              onChange={(event) => {
                if (
                  event.target.value >= minimum &&
                  event.target.value <= maximum &&
                  event.target.value >= insideLeftValue
                ) {
                  setInsideRightValue(event.target.value);
                }
              }}
              placeholder={t(
                "frontend.components.numberRange.rightValuePlaceholder"
              )}
              variant="outlined"
            />
          </Box>
          <Box p={2.5}>
            <Slider
              min={minimum}
              max={maximum}
              value={[insideLeftValue, insideRightValue]}
              onChange={handleValueChange}
              aria-labelledby={`${prefixId}-range-slider`}
              step={step}
              classes={{
                root: classes.sliderRoot,
                thumb: classes.thumb,
                track: classes.track,
                rail: classes.rail,
                active: classes.active,
              }}
            />
          </Box>
          <Box display="flex" justifyContent="space-around">
            <Button
              size="large"
              variant="outlined"
              onClick={() => {
                setInsideLeftValue(minimum);
                setInsideRightValue(maximum);
              }}
            >
              {t("frontend.components.numberRange.resetButton")}
            </Button>
            <Button
              size="large"
              variant="outlined"
              color="secondary"
              onClick={handleSave}
            >
              {t("frontend.components.numberRange.confirmButton")}
            </Button>
          </Box>
        </Box>
      </Popover>
    </>
  );
}
