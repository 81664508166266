/* eslint-disable no-unused-vars */
import {
  Avatar,
  Button,
  colors,
  Menu,
  MenuItem as MuiMenuItem,
  Select,
  Toolbar,
  Typography,
  useTheme,
} from "@material-ui/core";
import InputIcon from "@material-ui/icons/Input";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { MenuItem } from "react-contextmenu";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import Flag from "react-world-flags";
import FrontendRoutes from "../../../constants/FrontendRoutes";
import NeumorphicStyle from "../../../constants/Neumorphic";
import { DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE } from "../../../constants/Urls";
import { useActiveKeynote } from "../../../engines/GeneralHooks/useKeynotes";
import useNotificationEngine from "../../../engines/Notification/NotificationEngine";
import Exhibition from "../../../models/Exhibition";
import User from "../../../models/User";
import {
  changeLocaleUser,
  updateUser,
} from "../../../redux/actions/UsersActions";
import useConfigurationService from "../../../utils/ConfigurationService";
import MyStore from "../../../utils/MyStore";
import { isLocaleEnabled } from "../../../utils";
import { FormControlLabel } from "@material-ui/core";
import { Switch } from "@material-ui/core";
import { faVideo, faHome, faUserFriends, faClock, faTablet } from "@fortawesome/free-solid-svg-icons"
import { faDiscourse } from "@fortawesome/free-brands-svg-icons"
import useBreakpoints from "../../../engines/Util/useBreakpoints";
import LbResponsiveNavItem from "./components/LbNavItem/LbResponsiveNavItem";
import GlobalContext from "./context/GlobalContext";
import { useContext } from "react";
import { INTERNAL_IDS } from "../../../models/Role";

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.background.default,
    borderRadius: NeumorphicStyle.borderRadius,
    boxShadow: NeumorphicStyle.boxShadowLarge(
      theme.palette.primary.main,
      theme.palette.secondary.dark
    ),
    height: "60px",
    width: "100%",
    position: "relative",
    zIndex: "999",
  },
  flexGrow: {
    flexGrow: 1,
  },
  formControl: {
    maxHeight: "40px",
  },
  search: {
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: theme.palette.primary.main,
    backgroundColor: "rgba(255,255,255, 0.1)",
    borderRadius: 4,
    flexBasis: 300,
    height: 36,
    padding: theme.spacing(0, 2),
    display: "flex",
    alignItems: "center",
  },
  searchIcon: {
    marginRight: theme.spacing(2),
    color: theme.palette.primary.main,
  },
  searchInput: {
    flexGrow: 1,
    color: "text",
    "& input::placeholder": {
      opacity: 1,
      color: "inherit",
    },
  },
  searchPopper: {
    zIndex: theme.zIndex.appBar + 100,
  },
  searchPopperContent: {
    marginTop: theme.spacing(1),
  },
  menuButton: {
    marginRight: theme.spacing(1) - 256,
  },
  chatButton: {
    marginLeft: theme.spacing(1),
  },
  notificationsButton: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  languageButton: {
    marginLeft: theme.spacing(1),
    borderRadius: 0,
    padding: 4,
    backgroundColor: theme.palette.background.default,
  },
  notificationsBadge: {
    backgroundColor: colors.orange[600],
  },
  appointmentButton: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    fontSize: "1em",
    fontWeight: "",
    borderRadius: "5px",
    marginRight: "12px",
    boxShadow: NeumorphicStyle.boxShadowSmall(
      theme.palette.primary.main,
      theme.palette.secondary.dark
    ),
  },
  logoutButton: {
    marginLeft: theme.spacing(2),
    paddingLeft: "8px",
    paddingRight: "8px",
    color: theme.palette.text.primary,
    borderRadius: "4px",
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
      paddingLeft: "8px",
      borderRadius: "4px",
      paddingRight: "8px",
      color: "#FFF",
    },
  },
  logoutIcon: {
    color: theme.palette.text.primary,
  },

  profileButton: {
    marginLeft: 20,
    "&:hover": {
      transition: "none",
    },
  },
  navButton: {
    marginRight: 20,
    padding: 4,
  },
  selectedNavButton: {
    marginRight: 20,
    padding: 4,
    color: theme.palette.secondary.main,
  },
  profilePicture: {
    height: theme.spacing(4),
    width: theme.spacing(4),
    borderRadius: "50%",
  },
  profileMenuItem: {
    textTransform: "uppercase",
  },
  paperSelect: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  menuPaper: {
    marginTop: theme.spacing(7)
  }
}));

const popularSearches = [];

function TopBar({
  onOpenNavBarMobile,
  tabIndex,
  setTabIndex,
  setTalkingPointId,
  className,
  exhibition,
  ...rest
}) {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const notificationsRef = useRef(null);
  const [openSearchPopover, setOpenSearchPopover] = useState(false);
  const [openNotifications, setOpenNotifications] = useState(false);
  const [notificationPageIndex, setNotificationPageIndex] = useState(
    DEFAULT_PAGE_INDEX
  );
  const [notificationPageSize, setNotificationPageSize] = useState(
    DEFAULT_PAGE_SIZE
  );
  const [logout, setLogout] = useState(false);
  const [openChatBar, setOpenChatBar] = useState(false);
  const [availability, setAvailability] = useState();
  const [config] = useConfigurationService();

  const users = useSelector((state) => state.usersReducer);
  const session = useSelector((state) => state.sessionReducer);
  const [
    notifications,
    loading,
    incomingNotification,
    unreadMessagesCount,
    resetUnreadMessages,
    totalPages,
  ] = useNotificationEngine(
    openNotifications,
    notificationPageSize,
    notificationPageIndex
  );
  const [activeKeynote, activeKeynoteLoading] = useActiveKeynote();

  const [imageUrl, setImageUrl] = useState();

  const [language, setLanguage] = useState("en");

  const [visitorView, setVisitorView] = useState(MyStore.getVisitorView());

  const [breakpoint] = useBreakpoints();

  const context = useContext(GlobalContext);

  useEffect(() => {
    console.log(breakpoint);
  }, [breakpoint])

  useEffect(() => {
    i18n.changeLanguage(language);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);
  useEffect(() => {
    if (!isLocaleEnabled(users.currentUser.getLocale(), config)) {
      dispatch(changeLocaleUser('en'));
      return
    }
  }, [config])

  useEffect(() => {
    if (!users.user) {
      return;
    }

    setImageUrl(
      users.user.getProfilePicture()
        ? users.user.getProfilePicture().getUrl("small")
        : ""
    );
    setAvailability(
      users.user.getAvailabilityStatus()
        ? users.user.getAvailabilityStatus("small")
        : ""
    );
    setLanguage(users.currentUser.getLocale());
    MyStore.setLocal(users.currentUser.getLocale());
  }, [users.user, users.currentUser]);

  useEffect(() => {
    if (openNotifications) {
      return;
    }
    setNotificationPageIndex(DEFAULT_PAGE_INDEX);
  }, [openNotifications]);

  useEffect(() => {
    if (!logout || session.isFetching) {
      return;
    }
    history.push(FrontendRoutes.auth.login());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session.isFetching]);

  const handleLogout = async () => {
    setLogout(true);
    history.push(FrontendRoutes.auth.logout());
  };

  useEffect(() => {
    if (visitorView !== MyStore.getVisitorView()) {
      MyStore.setVisitorView(visitorView);
      window.location.reload();
    }
  }, [visitorView])

  const handleChatBarClose = () => {
    setOpenChatBar(false);
  };

  const handleNotificationsOpen = () => {
    setOpenNotifications(true);
    resetUnreadMessages();
  };

  const handleNotificationsClose = () => {
    setOpenNotifications(false);
  };

  const handleAvailabilityChange = (event) => {
    const status = event.target.value
      ? event.target.value
      : User.AVAILABILITY_STATUS.AVAILABLE;
    if (!users.user) {
      return;
    }
    users.user.setAvailabilityStatus(status);
    dispatch(updateUser(users.user));
    setAvailability(status);
  };

  // const handleSearchChange = (event) => {
  //   setSearchValue(event.target.value);

  //   if (event.target.value) {
  //     if (!openSearchPopover) {
  //       setOpenSearchPopover(true);
  //     }
  //   } else {
  //     setOpenSearchPopover(false);
  //   }
  // };

  const handleSearchPopverClose = () => {
    setOpenSearchPopover(false);
  };

  const handleLoadMore = () => {
    setNotificationPageIndex(notificationPageIndex + 1);
  };

  const [profileAnchorEl, setProfileAnchorEl] = React.useState(null);
  const profileOpen = Boolean(profileAnchorEl);

  const handleProfileClick = (event) => {
    setProfileAnchorEl(event.currentTarget);
  };

  const handleProfileClose = () => {
    setProfileAnchorEl(null);
  };

  return (
    <div className={clsx(classes.root, className)}>
      <Toolbar>
        {exhibition && config && (
          <>
            {exhibition.getExhibitionType() ===
              Exhibition.EXHIBITION_TYPES.VIDEO && (
                <Button
                  className={
                    tabIndex === 6 ? classes.selectedNavButton : classes.navButton
                  }
                  onClick={() => setTabIndex(0)}
                >
                  {/* {config && config.navbar.attendees ? config.navbar.attendees : "Attendees"} */}
                  <Typography variant="h4" style={{ color: "inherit" }}>
                    <Trans>frontend.navBar.video</Trans>
                  </Typography>
                </Button>
              )}
            {exhibition.getExhibitionType() !==
              Exhibition.EXHIBITION_TYPES.VIDEO && (
                <LbResponsiveNavItem
                  tabIndex={0}
                  selectedIndex={tabIndex}
                  setTabIndex={setTabIndex}
                  label={t("frontend.navBar.news")}
                  icon={faHome}
                  threshold={3}
                />
              )}
            {config && config.system.activate_exhibitors && (
              <LbResponsiveNavItem
                tabIndex={1}
                selectedIndex={tabIndex}
                setTabIndex={setTabIndex}
                label={t("frontend.navBar.exhibitors")}
                icon={faVideo}
                threshold={3}
                setTalkingPointId={context.setTalkingPointId}
              />
            )}
            {
              config &&
              config.system.activate_visitors &&
              users.user &&
              users.user.getRole() &&
              users.user.getRole().getPreEventVisible(exhibition) && (
                <LbResponsiveNavItem
                  tabIndex={2}
                  selectedIndex={tabIndex}
                  setTabIndex={setTabIndex}
                  label={t("frontend.navBar.visitors")}
                  icon={faUserFriends}
                  threshold={3}
                />
              )
            }
            {
              config &&
              config.system.activate_agenda &&
              users.user &&
              users.user.getRole() &&
              users.user.getRole().getPreEventVisible(exhibition) && (
                <LbResponsiveNavItem
                  tabIndex={3}
                  selectedIndex={tabIndex}
                  setTabIndex={setTabIndex}
                  label={t("frontend.navBar.agenda")}
                  icon={faClock}
                  threshold={3}
                />
              )}
            {
              config &&
              config.system.activate_sponsors && (
                <LbResponsiveNavItem
                  tabIndex={4}
                  selectedIndex={tabIndex}
                  setTabIndex={setTabIndex}
                  label={t("frontend.navBar.sponsors")}
                  icon={faTablet}
                  threshold={3}
                />
              )}
            {
              config &&
              config.system.activate_discussion_room &&
              users.user &&
              users.user.getRole() &&
              users.user.getRole().getPreEventVisible(exhibition) && (
                <LbResponsiveNavItem
                  tabIndex={7}
                  selectedIndex={tabIndex}
                  setTabIndex={setTabIndex}
                  label={t("frontend.navBar.discussion_room")}
                  icon={faDiscourse}
                  threshold={3}
                />
              )}
          </>
        )}
        <div className={classes.flexGrow} />
        {exhibition && exhibition.getExhibitionStarted() && MyStore.getCurrentUser().getRole().getName(true) !== INTERNAL_IDS.VISITOR &&
          (<FormControlLabel
            control={
              <Switch
                checked={visitorView}
                color='secondary'
                onChange={() => {
                  setVisitorView(MyStore.getVisitorView() ? false : true);
                }}
                name="visitorViewToggle"
              />
            }
            label={(<Typography variant={breakpoint <= 3 ? "h6":"h4"}>{MyStore.getVisitorView() ? "Disable Visitor View" : "Visitor View"}</Typography>)}
          />)
        }
        <Select
          className={classes.languageButton}
          value={language}
          onChange={(e) => {
            setLanguage(e.target.value);
            MyStore.setLocal(e.target.value);
            if (users.user && users.user.getLocale() !== e.target.value) {
              dispatch(changeLocaleUser(e.target.value));
            }
          }}
          disableUnderline
          MenuProps={{
            MenuListProps: { className: classes.paperSelect },
          }}
        >
          {config?.enable_german ? <MenuItem value={"de"}><Flag code="de" height={20} width={30} /></MenuItem> : null}
          <MenuItem value={"en"}><Flag code="gb" height={20} width={30} /></MenuItem>
          {config?.enable_dutch ? <MenuItem value={"nl"}><Flag code="nl" height={20} width={30} /></MenuItem> : null}
          {config?.enable_italy ? <MenuItem value={"it"}><Flag code="it" height={20} width={30} /></MenuItem> : null}
          {config?.enable_french ? <MenuItem value={"fr"}><Flag code="fr" height={20} width={30} /></MenuItem> : null}
        </Select>
        {exhibition ? (
          <>
            <Button
              aria-controls="fade-menu"
              aria-haspopup="true"
              onClick={handleProfileClick}
            >
              <Avatar
                src={
                  users.currentUser
                    ? users.currentUser.getProfilePicture()
                      ? users.currentUser
                        .getProfilePicture()
                        .getUrl("thumbnail")
                      : ""
                    : ""
                }
                className={classes.profilePicture}
              />
            </Button>
            <Menu
              className={classes.menuPaper}
              id="fade-menu"
              anchorEl={profileAnchorEl}
              keepMounted
              open={profileOpen}
              onClose={handleProfileClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <MuiMenuItem
                onClick={() => {
                  handleProfileClose();
                  setTabIndex(5);
                }}
              >
                <Typography variant="h4" className={classes.profileMenuItem}>
                  {users.currentUser
                    ? users.currentUser.getFullName()
                    : "Name Missing"}
                </Typography>
              </MuiMenuItem>
              <MuiMenuItem
                onClick={() => {
                  handleProfileClose();
                  handleLogout();
                }}
              >
                <Typography variant="h4" className={classes.profileMenuItem}>
                  <Trans>frontend.logout</Trans>
                </Typography>
              </MuiMenuItem>
            </Menu>
          </>
        ) : (
          <Button
            className={classes.logoutButton}
            onClick={handleLogout}
            startIcon={<InputIcon className={classes.logoutIcon} />}
          >
            <Typography variant="h4">
              <Trans>frontend.logout</Trans>
            </Typography>
          </Button>
        )}

        {/* </Hidden> */}
      </Toolbar>
      {/* <NotificationsPopover
        anchorEl={notificationsRef.current}
        notifications={notifications}
        loading={loading}
        handleLoadMore={() => handleLoadMore()}
        onClose={handleNotificationsClose}
        open={openNotifications}
        loadMoreActive={notificationPageIndex !== totalPages}
      /> */}
    </div>
  );
}

TopBar.propTypes = {
  className: PropTypes.string,
  onOpenNavBarMobile: PropTypes.func,
};

export default TopBar;
