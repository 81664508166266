import {
  Box,
  Button,
  Checkbox,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Popover,
} from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import React from "react";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  listRoot: {
    maxHeight: 400,
    overflow: "auto",
  },
  buttonRoot: {
    borderWidth: ({ isSelected }) => (isSelected ? 2 : 1),
    borderRadius: 0,
    maxHeight: 45,
  },
  popoverPaper: {
    borderRadius: 0,
    borderColor: "",
  },
  listItemRoot: {
    height: 40,
    minWidth: 200,
  },
  saveButtonRoot: {
    borderRadius: 0,
    backgroundColor: theme.palette.secondary.main,
  },
  resetButtonRoot: {
    borderRadius: 0,
  },
}));

export default function FilterDropdown({
  prefixId,
  label,
  multiple,
  optionList,
  getValue,
  getLabel,
  selectedList,
  setSelectedList,
}) {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [innerOptionList, setInnerOptionList] = React.useState(
    selectedList.map((selected) => getValue(selected))
  );

  const classes = useStyles({
    isSelected: innerOptionList.length > 0,
  });

  const open = Boolean(anchorEl);
  const popoverId = open ? `${prefixId}-popover` : undefined;

  const countValue = React.useMemo(
    () => (open ? innerOptionList.length : selectedList.length),
    [innerOptionList.length, open, selectedList.length]
  );

  const handleOpen = (event) => {
    setInnerOptionList(selectedList.map((selected) => getValue(selected)));
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    const selectedObjectList = innerOptionList.map((innerOption) => {
      return optionList.find((option) => getValue(option) === innerOption);
    });
    setSelectedList(selectedObjectList);
    setAnchorEl(null);
  };

  const handleToggle = (value) => () => {
    if (!multiple) {
      if (innerOptionList.indexOf(value) === -1) {
        setInnerOptionList([value]);
      } else {
        setInnerOptionList([]);
      }

      return;
    }
    const currentIndex = innerOptionList.indexOf(value);
    const newChecked = [...innerOptionList];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setInnerOptionList(newChecked);
  };

  const handleReset = () => {
    setInnerOptionList([]);
  };

  const handleSave = () => {
    handleClose();
  };

  return (
    <>
      <Button
        onClick={handleOpen}
        aria-describedby={popoverId}
        variant="outlined"
        classes={{ root: classes.buttonRoot }}
      >
        <Box p={1} display="flex" alignItems="center">
          <Box pr={1} component="span">
            {label}
          </Box>
          {multiple && innerOptionList.length > 0 && (
            <Box component="span" bgcolor="secondary.main" p={0.5}>
              {countValue}
            </Box>
          )}
          <Box component="span" pl={1}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </Box>
        </Box>
      </Button>
      <Popover
        id={popoverId}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        classes={{ paper: classes.popoverPaper }}
      >
        <Box>
          <Box p={1}>
            <List className={classes.listRoot}>
              {optionList.map((option) => {
                const value = getValue(option);
                const labelId = `checkbox-list-label-${value}`;
                return (
                  <ListItem
                    key={value}
                    role={undefined}
                    dense
                    button
                    onClick={handleToggle(value)}
                    classes={{ root: classes.listItemRoot }}
                  >
                    <ListItemText id={labelId} primary={getLabel(option)} />
                    <ListItemIcon>
                      <Checkbox
                        edge="end"
                        checked={innerOptionList.indexOf(value) > -1}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ "aria-labelledby": labelId }}
                        icon={<Box />}
                        checkedIcon={<CheckIcon height={30} />}
                      />
                    </ListItemIcon>
                  </ListItem>
                );
              })}
            </List>
          </Box>
          <Box pt={0.5} display="flex" flexBasis={0}>
            <Box width="50%">
              <Button
                variant="outlined"
                fullWidth
                onClick={handleReset}
                borderRadius={0}
                classes={{ root: classes.resetButtonRoot }}
              >
                <Box p={1}>
                  {t("frontend.components.filterDropdown.buttonReset")}
                </Box>
              </Button>
            </Box>
            <Box width="50%">
              <Button
                variant="outlined"
                fullWidth
                onClick={handleSave}
                borderRadius={0}
                classes={{ root: classes.saveButtonRoot }}
              >
                <Box p={1}>
                  {t("frontend.components.filterDropdown.buttonSave")}
                </Box>
              </Button>
            </Box>
          </Box>
        </Box>
      </Popover>
    </>
  );
}
