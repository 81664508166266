import Conversation from "../models/Conversation";
import BaseMapper from "./BaseMapper";
import UserMapper from "./UserMapper";
import MessageMapper from "./MessageMapper";

const attributes = Conversation.attributes;

export default class ConversationMapper {
  static build = json => {
    if (!json) {
      return;
    }

    return new Conversation({
      ...BaseMapper.attributes(json),
      name: json[attributes.name],
      title: json[attributes.title],
      lastMessage: MessageMapper.build(json[attributes.lastMessage]),
      otherUser: UserMapper.build(json[attributes.otherUser]),
      unreadMessagesCount: json[attributes.unreadMessagesCount],
      exhibitionId: json[attributes.exhibitionId],
      user: UserMapper.build(json.user)
    });
  };

  static buildFromArray = (jsonArray = []) => {
    return jsonArray.map(entry => {
      return this.build(entry);
    });
  };

  static toJsonString(conversation) {
    return JSON.stringify({
      [attributes.title]: conversation.getTitle(),
      'user_ids': conversation.getUserIds()
    });
  }
}
