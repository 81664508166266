export default class ApplicationModel {
  state = {
    id: 0,
    createdAt: new Date(),
    updatedAt: new Date(),
  }

  constructor(props){
    this.state = {...this.state, ...props}
  }

  /**
   * Default Getter
   */

  getId = () => this.state.id
  getCreatedAt = () => this.dateToString(this.state.createdAt)
  getUpdatedAt = () => this.dateToString(this.state.updatedAt)

  getRawCreatedAt = () => this.state.createdAt ? this.state.createdAt:new Date()
  getRawUpdatedAt = () => this.state.updatedAt ? this.state.updatedAt:new Date()
  
  dateToString = (date) => ApplicationModel.dateToString(date)

  static attributes = {
    id: 'id',
    createdAt: 'created_at',
    updatedAt: 'updated_at'
  }

  static orderByParams = {
    createdAt: this.attributes.createdAt,
    updatedAt: this.attributes.updatedAt
  }

  static filterParams = {}

  static dateToString = (date) => {
    if(!date) { return '' }

    let month = (date.getMonth() + 1 < 10) ? "0" + (date.getMonth() + 1) : (date.getMonth() + 1)
    return date.getDate() + "." + month + "." + date.getFullYear() }

  static dateToStringTime = (date) => { 
    if(!date) { return '' }
    
    let minutes = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()

    return this.dateToString(date) + " " + date.getHours() + ":" + minutes }
}