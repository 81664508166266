import ApplicationModel from "./ApplicationModel";

export default class Keynote extends ApplicationModel {
  constructor(_opts = {}) {
    super(_opts);
    this.state = { ..._opts };
  }

  static className = "Keynote";

  getTitle = () => this.state.title;
  getStatus = () => this.state.status;
  getShortKey = () => this.state.shortKey;
  getApprovedAt = () => this.state.approvedAt;
  getDescription = (language) => {
    if (this.state.localDescription && this.state.localDescription.length > 0 && language)  {
      const desc = this.state.localDescription.find(element => element.getLanguage() === language);
      if (desc && desc.getText()) {
        return desc.getText();
      }
    }
    return this.state.description
  };
  getKeynoteType = () => this.state.keynoteType;

  getEndAt = () => this.state.endAt;
  getStartAt = () => this.state.startAt;

  getSpeakers = () => this.state.speakers;
  getModerators = () => this.state.moderators;

  getDocuments = () => this.state.documents;

  getThematicAreaId = () => this.state.thematicAreaId;
  setThematicAreaId = (id) => this.state.thematicAreaId = id;

  getExhibitionId = () => this.state.exhibitionId;

  isBookmarked = () => this.state.isBookmarked;
  isRegistered = () => this.state.isRegistered;

  setIsBookmarked = (val) => (this.state.isBookmarked = val);
  setIsRegistered = (val) => (this.state.isRegistered = val);

  setBookmark = (bookmark) => (this.state.bookmark = bookmark);
  getBookmark = () => this.state.bookmark;

  setExhibitor = (exhibitor) => (this.state.exhibitor = exhibitor);
  getExhibitor = () => this.state.exhibitor;

  getStreamingUrl = () => this.state.streamingUrl;
  setStreamingUrl = (streamingUrl) => this.state.streamingUrl = streamingUrl
  getConversationId = () => this.state.conversation ? this.state.conversation.getId():undefined;

  setTitle = (title) => (this.state.title = title);
  setShortKey = (key) => this.state.shortKey = key
  setStatus = (status) => (this.state.status = status);
  setEndAt = (endAt) => (this.state.endAt = endAt);
  setStartAt = (startAt) => (this.state.startAt = startAt);
  setApprovedAt = (approvedAt) => (this.state.approvedAt = approvedAt);
  setDescription = (description) => (this.state.description = description);
  setKeynoteType = (keynoteType) => (this.state.keynoteType = keynoteType);

  getTemporaryEnabled = () => this.state.temporaryEnabled
  setTemporaryEnabled = (enabled) => this.state.temporaryEnabled = enabled

  getFormattedTitle = () => this.state.title + (this.state.description ? (' : ' + this.state.description.substr(0,100)) : '')

  getDisconnected = () => this.state.disconnected
  setDisconnected = (val) => this.state.disconnected = val

  getImage = () => this.state.image
  setImage = (image) => this.state.image = image

  isRunning = () => this.state.running

  getLanguage = () => this.state.language

  className = "Keynote"

  static keynoteTypes = {
    SLAM: "slam",
    PANEL: "panel",
    PITCH: "pitch",
    KEYNOTE: "keynote",
    AWARD_CEREMONY: "award_ceremony",
    CONGRESS_SESSION: "congress_session",
  };

  static status = {
    OPEN: "open",
    APPROVED: "approved",
    DECLINED: "declined",
  };

  // Backend Attributes
  static attributes = {
    ...super.attributes,
    title: "Title",
    status: "status",
    approvedAt: "approved_at",
    startAt: "start_at",
    endAt: "end_at",
    shortKey: "short_key",
    running: "running",
    speakers: "speakers",
    moderators: "moderators",
    bookmark: "bookmark",
    exhibitor: "exhibitor",
    conversation: "conversation",
    streamingUrl: "streaming_url",
    thematicAreaId: "thematic_area_id",
    isBookmarked: "is_bookmarked",
    isRegistered: "is_registered",
    description: "description",
    keynoteType: "keynote_type",
    disconnected: "disconnected",
    registeredUsersCount: "registered_users_count",
    exhibitionId: "exhibition_id",
    temporaryEnabled: "temporary_enabled",
    localDescription: "local_description",
    language: "language"
  };

  static orderByParams = {
    ...super.orderByParams,
    name: this.attributes.name,
    startAt: this.attributes.startAt,
  };

  static filterParams = {
    ...super.filterParams,
    status: this.attributes.status,
    keynoteType: this.attributes.keynoteType,
    title: this.attributes.title,
    search: '_q',
    date: "date",
    running: 'running'
  };
}
