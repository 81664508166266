import {
  FETCHING_EXHIBITION_POST_COMMENTS_REQUEST,
  FETCHING_EXHIBITION_POST_COMMENTS_SUCCESS,
  FETCHING_EXHIBITION_POST_COMMENTS_FAILURE,

  FETCHING_EXHIBITION_POST_COMMENT_REQUEST,
  FETCHING_EXHIBITION_POST_COMMENT_SUCCESS,
  FETCHING_EXHIBITION_POST_COMMENT_FAILURE,

  CREATE_EXHIBITION_POST_COMMENT_REQUEST,
  CREATE_EXHIBITION_POST_COMMENT_SUCCESS,
  CREATE_EXHIBITION_POST_COMMENT_FAILURE,

  UPDATE_EXHIBITION_POST_COMMENT_REQUEST,
  UPDATE_EXHIBITION_POST_COMMENT_SUCCESS,
  UPDATE_EXHIBITION_POST_COMMENT_FAILURE,

  DELETE_EXHIBITION_POST_COMMENT_REQUEST,
  DELETE_EXHIBITION_POST_COMMENT_SUCCESS,
  DELETE_EXHIBITION_POST_COMMENT_FAILURE,

} from './types';
import Urls, { DEFAULT_OPTIONAL_PARAMETERS } from '../../constants/Urls';
import { BEARER } from '../../utils/MyStore';

import CommentMapper from '../../mapper/CommentMapper';
import ResponseService from '../../utils/ResponseService';
import ApplicationError from '../../utils/ApplicationError';
import ErrorLogger from '../../utils/ErrorLogger';

export const fetchingCommentsRequest = () => ({
  type: FETCHING_EXHIBITION_POST_COMMENTS_REQUEST
})

export const fetchingCommentsSuccess = (json) => ({
  type: FETCHING_EXHIBITION_POST_COMMENTS_SUCCESS,
  payload: json
})
export const fetchingCommentsFailure = (error) => ({
  type: FETCHING_EXHIBITION_POST_COMMENTS_FAILURE,
  payload: error
})

export const fetchingCommentRequest = () => ({
  type: FETCHING_EXHIBITION_POST_COMMENT_REQUEST
})

export const fetchingCommentSuccess = (comment) => ({
  type: FETCHING_EXHIBITION_POST_COMMENT_SUCCESS,
  payload: comment
})
export const fetchingCommentFailure = (error) => ({
  type: FETCHING_EXHIBITION_POST_COMMENT_FAILURE,
  payload: error
})

export const createCommentRequest = () => ({
  type: CREATE_EXHIBITION_POST_COMMENT_REQUEST
})

export const createCommentSuccess = (comment) => ({
  type: CREATE_EXHIBITION_POST_COMMENT_SUCCESS,
  payload: comment
})

export const createCommentFailure = (error) => ({
  type: CREATE_EXHIBITION_POST_COMMENT_FAILURE,
  payload: error
})

export const updateCommentRequest = () => ({
  type: UPDATE_EXHIBITION_POST_COMMENT_REQUEST
})

export const updateCommentSuccess = (comment) => ({
  type: UPDATE_EXHIBITION_POST_COMMENT_SUCCESS,
  payload: comment
})

export const updateCommentFailure = (error) => ({
  type: UPDATE_EXHIBITION_POST_COMMENT_FAILURE,
  payload: error
})

export const deleteCommentRequest = () => ({
  type: DELETE_EXHIBITION_POST_COMMENT_REQUEST
})

export const deleteCommentSuccess = () => ({
  type: DELETE_EXHIBITION_POST_COMMENT_SUCCESS
})

export const deleteCommentFailure = (error) => ({
  type: DELETE_EXHIBITION_POST_COMMENT_FAILURE,
  payload: error
})

export const fetchComments = (exhibitionId, postId, _opts = DEFAULT_OPTIONAL_PARAMETERS) => {
  
  return async dispatch => {
    dispatch(fetchingCommentsRequest());
    
    try {
      const route   = Urls.exhibitions.posts.comments.all
      const bearer  = localStorage.getItem(BEARER)
      const headers = Urls.header.secure(bearer)
      const url     = Urls.build(route.url(exhibitionId, postId, _opts.pageIndex, _opts.pageSize), _opts.filters)

      let response = await fetch(url, {
        method: route.method,
        headers: headers
      });
      let responseService = new ResponseService(CommentMapper)

      let action = await responseService.call(response, 
        (value) => fetchingCommentsSuccess(value),
        (error) => fetchingCommentsFailure(error))

      dispatch(action)  
    } catch(error) {
      ErrorLogger.call(this, error)
      dispatch(fetchingCommentsFailure(error))
    }
  };
};

export const fetchComment = (exhibitionId, postId, id) => {
  return async dispatch => {
    dispatch(fetchingCommentRequest());

    try {
      const route   = Urls.exhibitions.posts.comments.show
      const bearer  = localStorage.getItem(BEARER)
      const headers = Urls.header.secure(bearer)
      const url     = Urls.build(route.url(exhibitionId, postId, id))

      let response = await fetch(url, {
        method: route.method,
        headers: headers
      })

      let responseService = new ResponseService(CommentMapper)

      let action = await responseService.call(response, 
        (value) => fetchingCommentSuccess(value),
        (error) => fetchingCommentFailure(error))

      dispatch(action)
    } catch(error) {
      ErrorLogger.call(this, error)
      dispatch(fetchingCommentFailure(ApplicationError.buildApplicationError(error)))
    }
  }
}

export const createComment = (comment) => {
  return async dispatch => {
    dispatch(createCommentRequest());
    try {

      const route = Urls.exhibitions.posts.comments.create
      const bearer = localStorage.getItem(BEARER)
      const headers = Urls.header.secure(bearer)
      const url = route.url(comment.getExhibitionId(), comment.getPostId(), comment.getId())

      let response = await fetch(url, {
        method: route.method,
        headers: headers,
        body: CommentMapper.toJsonString(comment)
      });

      let responseService = new ResponseService(CommentMapper)

      let action = await responseService.call(response, 
        (value) => createCommentSuccess(value),
        (error) => createCommentFailure(error))

      dispatch(action)  
    } catch(error) {
      ErrorLogger.call(this, error)
      dispatch(createCommentFailure(ApplicationError.buildApplicationError(error)))
    }
  };
};

export const updateComment = (comment) => {
  return async dispatch => {
    dispatch(updateCommentRequest());
    
    try {
      const route   = Urls.exhibitions.posts.comments.update
      const bearer  = localStorage.getItem(BEARER)
      const headers = Urls.header.secure(bearer)
      const url     = route.url(comment.getExhibitionId(), comment.getPostId(), comment.getId())

      let response = await fetch(url, {
        method: route.method,
        headers: headers,
        body: CommentMapper.toJsonString(comment)
      });

      let responseService = new ResponseService(CommentMapper)

      let action = await responseService.call(response, 
        (value) => updateCommentSuccess(value),
        (error) => updateCommentFailure(error))

      dispatch(action)  
    } catch(error) {
      ErrorLogger.call(this, error)
      dispatch(updateCommentFailure(ApplicationError.buildApplicationError(error)))
    }
  };
};

export const deleteComment = (comment) => {
  return async dispatch => {
    dispatch(deleteCommentRequest());
    
    try {
      const route   = Urls.exhibitions.posts.comments.delete
      const bearer  = localStorage.getItem(BEARER)
      const headers = Urls.header.secure(bearer)
      const url     = Urls.build(route.url(comment.getExhibitionId(), comment.getPostId(), comment.getId()))

      let response = await fetch(url, {
        method: route.method,
        headers: headers
      });

      let responseService = new ResponseService(CommentMapper)

      let action = await responseService.call(response, 
        (value) => deleteCommentSuccess(value),
        (error) => deleteCommentFailure(error))

      dispatch(action)  
    } catch(error) {
      ErrorLogger.call(this, error)
      dispatch(deleteCommentFailure(ApplicationError.buildApplicationError(error)))
    }
  };
};