import ApplicationModel from "./ApplicationModel"; 

export default class Image extends ApplicationModel {
  constructor(_opts = {}) {
    super(_opts);
    this.state = { ..._opts };
  }

  getName = () => this.state.name;
  setName = (name) => this.state.name = name; //deprecated

  getContent = () => this.state.content;
  getImageType = () => this.state.imageType;
  setImageType = (imageType) => this.state.imageType = imageType;
  getExhibitionId = () => this.state.exhibitionId;
  setExhibitionId = (id) => this.state.exhibitionId = id
  getAttachedToId = () => this.state.attachedToId;
  getAttachedToType = () => this.state.attachedToType;

  setAttachedToId = (id) => this.state.attachedToId = id;
  setAttachedToType = (type) => this.state.attachedToType = type;

  setContent = (content) => this.state.content = content
  getFormats = () => this.state.formats

  getUrl = (type) => {
    switch (type) {
      case "thumbnail":
        return this.state.formats && this.state.formats.thumbnail ? this.state.formats.thumbnail.url:this.state.url;
      case "small":
        return this.state.formats && this.state.formats.small ? this.state.formats.small.url:this.state.url;
      case "medium":
        return this.state.formats && this.state.formats.medium ? this.state.formats.medium.url:this.state.url;
      case "large":
        return this.state.formats && this.state.formats.large ? this.state.formats.large.url:this.state.url;
      default:
        return this.state.url;
    }
  };

  static attributes = {
    ...super.attribtes,
    url: "url",
    formats: "formats",
    name: "name",
    content: "content",
    imageType: "image_type",
    exhibitionId: "exhibition_id",
    attachedToId: "attached_to_id",
    attachedToType: "attached_to_type",
  };

  static attachedToTypes = {
    USER: "user",
    EXHIBITOR: "exhibitor",
    EXHIBITION: "exhibition"
  }

  static imageTypes = {
    PROFILE_IMAGE: "profile_picture",
    HEADER_IMAGE: "header_picture"
  }
}
