import {
  FETCHING_EXHIBITION_COMMENTS_LIKES_REQUEST,
  FETCHING_EXHIBITION_COMMENTS_LIKES_SUCCESS,
  FETCHING_EXHIBITION_COMMENTS_LIKES_FAILURE,

  CREATE_EXHIBITION_COMMENTS_LIKE_REQUEST,
  CREATE_EXHIBITION_COMMENTS_LIKE_SUCCESS,
  CREATE_EXHIBITION_COMMENTS_LIKE_FAILURE,

  DELETE_EXHIBITION_COMMENTS_LIKE_REQUEST,
  DELETE_EXHIBITION_COMMENTS_LIKE_SUCCESS,
  DELETE_EXHIBITION_COMMENTS_LIKE_FAILURE,

} from './types';
import Urls, { DEFAULT_OPTIONAL_PARAMETERS } from '../../constants/Urls';
import { BEARER } from '../../utils/MyStore';

import CommentMapper from '../../mapper/CommentMapper';
import ApplicationError from '../../utils/ApplicationError';
import ResponseService from '../../utils/ResponseService';
import UserMapper from '../../mapper/UserMapper';
import ErrorLogger from '../../utils/ErrorLogger';

export const fetchingCommentsLikesRequest = () => ({
  type: FETCHING_EXHIBITION_COMMENTS_LIKES_REQUEST
})

export const fetchingCommentsLikesSuccess = (json) => ({
  type: FETCHING_EXHIBITION_COMMENTS_LIKES_SUCCESS,
  payload: json
})
export const fetchingCommentsLikesFailure = (error) => ({
  type: FETCHING_EXHIBITION_COMMENTS_LIKES_FAILURE,
  payload: error
})

export const createCommentsLikeRequest = () => ({
  type: CREATE_EXHIBITION_COMMENTS_LIKE_REQUEST
})

export const createCommentsLikeSuccess = (comment) => ({
  type: CREATE_EXHIBITION_COMMENTS_LIKE_SUCCESS,
  payload: comment
})

export const createCommentsLikeFailure = (error) => ({
  type: CREATE_EXHIBITION_COMMENTS_LIKE_FAILURE,
  payload: error
})

export const deleteCommentsLikeRequest = () => ({
  type: DELETE_EXHIBITION_COMMENTS_LIKE_REQUEST
})

export const deleteCommentsLikeSuccess = () => ({
  type: DELETE_EXHIBITION_COMMENTS_LIKE_SUCCESS
})

export const deleteCommentsLikeFailure = (error) => ({
  type: DELETE_EXHIBITION_COMMENTS_LIKE_FAILURE,
  payload: error
})

export const fetchCommentsLikes = (exhibitionId, _opts = DEFAULT_OPTIONAL_PARAMETERS) => {
  return async dispatch => {
    dispatch(fetchingCommentsLikesRequest());
    
    try {
      const route = Urls.exhibitions.posts.comments.likes.all
      const bearer = localStorage.getItem(BEARER)
      const headers = Urls.header.secure(bearer)
      const url     = Urls.build(route.url(exhibitionId, _opts.pageIndex, _opts.pageSize), _opts.filters)

      let response = await fetch(url, {
        method: route.method,
        headers: headers
      });

      let responseService = new ResponseService(UserMapper)

      let action = await responseService.call(response, 
        (value) => fetchingCommentsLikesSuccess(value),
        (error) => fetchingCommentsLikesFailure(error))

      dispatch(action)  
    } catch(error) {
      dispatch(fetchingCommentsLikesFailure(ApplicationError.buildApplicationError(error)))
    }
  };
};

export const createCommentsLike = (comment) => {
  return async dispatch => {
    dispatch(createCommentsLikeRequest());
    
    try {
      const route   = Urls.exhibitions.posts.comments.likes.create
      const bearer  = localStorage.getItem(BEARER)
      const headers = Urls.header.secure(bearer)
      const url     = Urls.build(route.url(comment.getExhibitionId(), comment.getPostId(), comment.getId()))

      let response = await fetch(url, {
        method: route.method,
        headers: headers, 
        body: JSON.stringify({ comment_id: comment.getId() })
      });

      let responseService = new ResponseService(CommentMapper)

      let action = await responseService.call(response, 
        (value) => createCommentsLikeSuccess(value),
        (error) => createCommentsLikeFailure(error))

      dispatch(action)
    } catch(error) {
      ErrorLogger.call(this, error)
      dispatch(createCommentsLikeFailure(ApplicationError.buildApplicationError(error)))
    }
  };
};

export const deleteCommentsLike = (commentLike) => {
  return async dispatch => {
    dispatch(deleteCommentsLikeRequest());
    
    try {
      const route   = Urls.exhibitions.posts.comments.likes.delete
      const bearer  = localStorage.getItem(BEARER)
      const headers = Urls.header.secure(bearer)
      const url     = Urls.build(route.url(commentLike.getId()))

      let response = await fetch(url, {
        method: route.method,
        headers: headers
      });

      let responseService = new ResponseService(CommentMapper)

      let action = await responseService.call(response, 
        (value) => deleteCommentsLikeSuccess(value),
        (error) => deleteCommentsLikeFailure(error))

      dispatch(action)  
    } catch(error) {
      ErrorLogger.call(this, error)
      dispatch(deleteCommentsLikeFailure(ApplicationError.buildApplicationError(error)))
    }
  };
};