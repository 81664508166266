import PushNotification from "./PushNotification";
import ApplicationModel from "./ApplicationModel";

export default class Notification extends ApplicationModel {
  constructor(_opts = {}) {
    super(_opts);
    this.state = { ..._opts };
  }

  getNotificationType = () => this.state.notificationType;
  getExhibitionId = () => this.state.exhibitionId;
  getReadAt = () => this.state.readAt;
  getAttachedToType = () => this.state.attachedToType;
  getAttachedToId = () => this.state.attachedToId;
  getAttachedTo = () => this.state.attachedTo;
  getOtherUser = () => this.state.otherUser;

  isUnread = () => this.state.unread;

  // Backend Attributes
  static attributes = {
    ...super.attributes,
    attachedToType: "attached_to_type",
    attachedToId: "attached_to_id",
    attachedTo: "attached_to",
    exhibitionId: "exhibition_id",
    readAt: "read_at",
    otherUser: "other_user",
    notificationType: "notification_type",
    unread: "unread",
  };

  static NOTIFICATION_TYPES = PushNotification.NOTIFICATION_TYPES;
}
