import ApplicationModel from "./ApplicationModel";


export default class LocalTitle extends ApplicationModel {

  constructor(_opts = {}){
    super(_opts)
    this.state = { ..._opts }
  }

  getLanguage = () => this.state.language
  getName = () => this.state.name
  getHeader = () => this.state.header
  

  // Backend Attributes
  static attributes = {
    ...super.attributes,
    language: "language",
    name: "name",
    header: "header",
  }

  static orderByParams = {
    ...super.orderByParams
  }

  static filterParams = {
    ...super.filterParams,
  }
}