import { Avatar, IconButton, Select, Theme, Toolbar, MenuItem } from "@material-ui/core";
import { ChatBubble, Search } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import React, { useState } from "react";
import useExhibition from "../../../engines/GeneralHooks/useExhibition";
import Exhibition from "../../../models/Exhibition";
import useConfigurationService from "../../../utils/ConfigurationService";
import Flag from "react-world-flags";
import MyStore from "../../../utils/MyStore";
import { changeLocaleUser } from "../../../redux/actions/UsersActions";
import { useDispatch, useSelector } from "react-redux";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: "rgba(255,255,255,0)",
    position: "fixed",
    left: 0,
    top: 0,
    right: 0,
    height: "3.4rem",
    padding: "1.25rem .25rem"
  },
  toolbar: {
    height: "inherit",
    backgroundColor: "inherit"
  },
  avatar: {
    height: "3rem",
    width: "3rem",
    boxShadow: "1px 1px 8px 3px rgba(90,90,90,0.4)"
  },
  languageButton: {
    marginLeft: theme.spacing(1),
    borderRadius: 0,
    padding: 4,
    backgroundColor: "inherit"
  },
  chatIconButton: {
    marginLeft: ".5rem",
    backgroundColor: "rgba(255,255,255,.95)",
    height: "2.5rem",
    width: "2.5rem",
    boxShadow: "1px 1px 8px 2px rgba(90,90,90,0.4)"
  },
  icon: {
    height: "1.3rem",
    width: "1.3rem"
  },
  searchIconButton: {
    marginLeft: "auto",
    backgroundColor: "rgba(255,255,255,.95)",
    height: "2.5rem",
    width: "2.5rem",
    boxShadow: "1px 1px 8px 2px rgba(90,90,90,0.4)"
  },
  exhibitionName: {
    backgroundColor: "rgba(255,255,255,.95)",
    padding: ".5rem",
    borderRadius: "4px",
    height: "3rem",
    boxShadow: "1px 1px 8px 2px rgba(90,90,90,0.4)",
    fontSize: "1.25rem",
    display: "flex",
    alignItems: "center",
    justifyItems: "center",
    marginLeft: "auto",
    fontWeight: "bold",
    color: theme.palette.primary.light
  },
  paperSelect: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "inherit"
  },
}));

const LbAppBar: React.FC = () => {
  const classes = useStyles();
  const [searchOpen, setSearchOpen] = useState(false);
  const [exhibition] = useExhibition();
  const [language, setLanguage] = useState("en");
  const [config] = useConfigurationService();
  const users = useSelector((state) => (state as any).usersReducer);
  const dispatch = useDispatch();
  //https://source.unsplash.com/random/500x200

  return (
    <div className={classes.root}>
      <Toolbar className={classes.toolbar}>
        <Avatar
          variant="rounded"
          className={classes.avatar}
          src={"https://source.unsplash.com/random/200x200"}
        />
        {exhibition && (
          <div className={classes.exhibitionName}>
              {(exhibition as Exhibition).getName()}
              <Select
                className={classes.languageButton}
                value={language}
                onChange={(e) => {
                  setLanguage((e as any).target.value);
                  MyStore.setLocal((e as any).target.value);
                  if (users.user && users.user.getLocale() !== e.target.value) {
                    dispatch(changeLocaleUser(e.target.value));
                  }
                }}
                disableUnderline
                MenuProps={{
                  MenuListProps: { className: classes.paperSelect },
                }}
              >
              {(config as any)?.enable_german ? <MenuItem value={"de"}><Flag code="de" height={20} width={30} /></MenuItem> : null}
              <MenuItem value={"en"}><Flag code="gb" height={20} width={30} /></MenuItem>
              {(config as any)?.enable_dutch ? <MenuItem value={"nl"}><Flag code="nl" height={20} width={30} /></MenuItem> : null}
              {(config as any)?.enable_italy ? <MenuItem value={"it"}><Flag code="it" height={20} width={30} /></MenuItem> : null}
              {(config as any)?.enable_french ? <MenuItem value={"fr"}><Flag code="fr" height={20} width={30} /></MenuItem> : null}
            </Select>
        </div>
        )}
        {/* {searchOpen ? null : ( //for later
          <IconButton className={classes.searchIconButton}>
            <Search className={classes.icon} />
          </IconButton>
        )}
        <IconButton className={classes.chatIconButton}>
          <ChatBubble className={classes.icon} />
        </IconButton> */}
      </Toolbar>
    </div>
  );
};

export default LbAppBar;
