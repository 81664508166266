import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import StoryBar from "../../components/Story/StoryBar";
import ExhibitionHeader from "../../components/ExhibitionHeader";
import Exhibition from "../../../models/Exhibition";
import { Typography } from "@material-ui/core";
import { Trans, useTranslation } from "react-i18next";
import useConfigurationService from "../../../utils/ConfigurationService";

const useStyles = makeStyles(() => ({
  root: {},
}));

function Header({ className, ...rest }) {
  const classes = useStyles();
  const [config] = useConfigurationService();
  const [t] = useTranslation();

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Typography variant="h2" style={{marginTop:"0.6em", marginBottom: "0.3em"}}>
        {config ? `${t("frontend.agenda")}`:"Sponsor"}
      </Typography>
      {/* <StoryBar /> */}
    </div>
  );
}

Header.propTypes = {
  className: PropTypes.string,
};

export default Header;
