import {
  FETCHING_EXHIBITION_POST_COMMENTS_REQUEST,
  FETCHING_EXHIBITION_POST_COMMENTS_SUCCESS,
  FETCHING_EXHIBITION_POST_COMMENTS_FAILURE,
  
  FETCHING_EXHIBITION_POST_COMMENT_REQUEST,
  FETCHING_EXHIBITION_POST_COMMENT_SUCCESS,
  FETCHING_EXHIBITION_POST_COMMENT_FAILURE,

  CREATE_EXHIBITION_POST_COMMENT_FAILURE,
  CREATE_EXHIBITION_POST_COMMENT_SUCCESS,
  CREATE_EXHIBITION_POST_COMMENT_REQUEST,

  UPDATE_EXHIBITION_POST_COMMENT_FAILURE,
  UPDATE_EXHIBITION_POST_COMMENT_SUCCESS,
  UPDATE_EXHIBITION_POST_COMMENT_REQUEST,

  DELETE_EXHIBITION_POST_COMMENT_FAILURE,
  DELETE_EXHIBITION_POST_COMMENT_SUCCESS,
  DELETE_EXHIBITION_POST_COMMENT_REQUEST,
} from '../actions/types';
import ResponseService from '../../utils/ResponseService';
import ApplicationError from '../../utils/ApplicationError';

const initialState = {
  comments: [],
  comment: undefined,
  error: ApplicationError.buildDefault(),
  isFetching: false,
  ...ResponseService.defaultPaginationMetaData()
};

const commentsReducer = (state = initialState, action ) => {
  switch(action.type) {
    case FETCHING_EXHIBITION_POST_COMMENTS_REQUEST:
      return {...state, isFetching: true }
    case FETCHING_EXHIBITION_POST_COMMENTS_SUCCESS:
      return {...state, isFetching: false, 
        comments: action.payload.objects, 
        error: ApplicationError.buildDefault(),
        ...action.payload.paginationData }
        
    case FETCHING_EXHIBITION_POST_COMMENTS_FAILURE:
      return {...state, isFetching: false, error: action.payload }
    
    case FETCHING_EXHIBITION_POST_COMMENT_REQUEST:
      return {...state, isFetching: true }
    case FETCHING_EXHIBITION_POST_COMMENT_SUCCESS: 
      return {...state, isFetching: false, comment: action.payload, error: ApplicationError.buildDefault() }
    case FETCHING_EXHIBITION_POST_COMMENT_FAILURE:
      return {...state, isFetching: false, error: action.payload }  


    case  CREATE_EXHIBITION_POST_COMMENT_REQUEST:
      return {...state, isFetching: true }
    case  CREATE_EXHIBITION_POST_COMMENT_SUCCESS:
      return {...state, isFetching: false, comment: action.payload, error: ApplicationError.buildDefault() }
    case  CREATE_EXHIBITION_POST_COMMENT_FAILURE:
      return {...state, isFetching: false, error: action.payload }

    case  UPDATE_EXHIBITION_POST_COMMENT_REQUEST:
      return {...state, isFetching: true }
    case  UPDATE_EXHIBITION_POST_COMMENT_SUCCESS:
      return {...state, isFetching: false, comment: action.payload, error: ApplicationError.buildDefault() }
    case  UPDATE_EXHIBITION_POST_COMMENT_FAILURE:
      return {...state, isFetching: false, error: action.payload }

    case  DELETE_EXHIBITION_POST_COMMENT_REQUEST:
      return {...state, isFetching: true }
    case  DELETE_EXHIBITION_POST_COMMENT_SUCCESS:
      return {...state, isFetching: false, error: ApplicationError.buildDefault() }
    case  DELETE_EXHIBITION_POST_COMMENT_FAILURE:
      return {...state, isFetching: false, error: action.payload }


    default: 
      return state
  }
}

export default commentsReducer;