import {
  CREATE_USER_IMAGE_REQUEST,
  CREATE_USER_IMAGE_SUCCESS,
  CREATE_USER_IMAGE_FAILURE,

  UPDATE_USER_IMAGE_REQUEST,
  UPDATE_USER_IMAGE_SUCCESS,
  UPDATE_USER_IMAGE_FAILURE,

  DELETE_USER_IMAGE_REQUEST,
  DELETE_USER_IMAGE_SUCCESS,
  DELETE_USER_IMAGE_FAILURE,
} from "../actions/types";

import ResponseService from "../../utils/ResponseService";
import ApplicationError from "../../utils/ApplicationError";

const initialState = {
  image: undefined,
  error: ApplicationError.buildDefault(),
  isFetching: false,
  ...ResponseService.defaultPaginationMetaData(),
};

const imagesReducer = (state = initialState, action) => {
  switch (action.type) {
    // Create
    case CREATE_USER_IMAGE_REQUEST:
      return { ...state, isFetching: true };
    case CREATE_USER_IMAGE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        image: action.payload,
        error: ApplicationError.buildDefault(),
      };
    case CREATE_USER_IMAGE_FAILURE:
      return { ...state, isFetching: false, error: action.payload };

    // Update
    case UPDATE_USER_IMAGE_REQUEST:
      return { ...state, isFetching: true };
    case UPDATE_USER_IMAGE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        image: action.payload,
        error: ApplicationError.buildDefault(),
      };
    case UPDATE_USER_IMAGE_FAILURE:
      return { ...state, isFetching: false, error: action.payload };

    // Delete
    case DELETE_USER_IMAGE_REQUEST:
      return { ...state, isFetching: true };
    case DELETE_USER_IMAGE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: ApplicationError.buildDefault(),
      };
    case DELETE_USER_IMAGE_FAILURE:
      return { ...state, isFetching: false, error: action.payload };

    default:
      return state;
  }
};

export default imagesReducer;
