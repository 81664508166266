import { colors } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  event: {
    border: "solid",
    borderWidth: "2px",
  },
  other: {
    backgroundColor: colors.lightBlue[50],
  },
  root: {
    cursor: "pointer",
    height: "inherit",
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    overflowY: "auto",
    "& .fc-unthemed td": {
      border: "none",
    },
    "& .fc-list-view": {
      border: "none",
    },
    "& .fc-unthemed .fc-divider, .fc-unthemed .fc-popover .fc-header, .fc-unthemed .fc-list-heading td": {
      backgroundColor: theme.palette.background.default,
    },
    "& .fc-widget-header": {
      backgroundColor: theme.palette.background.default,
    },
    "& .fc-axis": {
      ...theme.typography.body2,
    },
    "& .fc-event-dot": {
      marginTop: "8px",
    },
    "& .fc-list-item-time": {
      ...theme.typography.h3,
      fontSize: "1.3em",
    },
    "& .fc-list-table": {
      border: "none",
    },
    "& .fc-list-item-title": {
      ...theme.typography.h3,
      "& h5": {
        fontSize: 16,
        fontWeight: "bold",
      },
      "& p": {
        fontSize: 15,
      },
    },
    "& .fc-list-heading": {
      backgroundColor: theme.palette.background.default,
    },
    "& .fc-list-heading-main": {
      ...theme.typography.h3,
    },
    "& .fc-list-heading-alt": {
      ...theme.typography.h6,
    },
    "& .fc th": {
      borderColor: theme.palette.divider,
    },
    "& .fc-day-header": {
      ...theme.typography.subtitle2,
      fontWeight: 500,
      color: theme.palette.text.secondary,
      padding: theme.spacing(1),
      backgroundColor: colors.grey[50],
    },
    "& .fc-day-top": {
      ...theme.typography.body2,
    },
    "& .fc-highlight": {
      backgroundColor: colors.blueGrey[50],
    },
    "& .fc-event": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      borderWidth: 2,
      opacity: 0.9,
      padding: "0 !important",
      "& .fc-time": {
        ...theme.typography.h6,
        color: "inherit",
      },
      "& .fc-title": {
        ...theme.typography.body1,
        color: "inherit",
      },
    },
    "& .fc-list-empty": {
      ...theme.typography.subtitle1,
    },
  },
  card: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(2),
    minHeight: "200px",
  },
}));

export default useStyles;
