import { makeStyles } from "@material-ui/styles";
import NeumorphicStyle from "../../../../../constants/Neumorphic";

const useStyles = makeStyles((theme) => ({
    root: {
      padding: (props) => props.breakpoint <= 3 ? 12 : 20,
      height: (props) => props.breakpoint <= 3 ? 230 : 320,
      width: (props) => props.breakpoint <= 3 ? 220 : 300,
      borderRadius: 8,
      overflow: "hidden",
      boxShadow: NeumorphicStyle.boxShadowMedium(theme.palette.primary.main, theme.palette.secondary.dark)
    },
    content: {
      width: "100%",
      height: "100%",
      overflowY: "auto",
      overflowX: "hidden",
      position: "relative"
    },
    userContainer: {
      width: "100%",
      position: "relative"
    },
    tagContainer: {
      marginTop: (props) => props.breakpoint <= 3 ? 10 : 15,
    },
    tag: {
      borderRadius: 4,
      height: (props) => props.breakpoint <= 3 ? 15 : 25,
      fontSize: (props) => props.breakpoint <= 3 ? 12 : 14,
    },
    image: {
      height: (props) => props.breakpoint <= 3 ? 39 : 56,
      width: (props) => props.breakpoint <= 3 ? 39 : 56,
      marginTop: 2,
    },
    nameContainer: {
      maxWidth: (props) => props.breakpoint <= 3 ? 131 : 190,
      marginLeft: (props) => props.breakpoint <= 3 ? 8 : 12
    },
    icon: {
      marginRight: 8,
    },
    name: {
    },
    contactContainer: {
      marginTop: (props) => props.breakpoint <= 3 ? 8 : 12,
    },
    profession: {
      lineHeight: (props) => props.breakpoint <= 3 ? "12px" : "18px",
    },
    companyName: {
      lineHeight: (props) => props.breakpoint <= 3 ? "12px" : "18px",
    },
    aboutContainer: {
      marginTop: (props) => props.breakpoint <= 3 ? 8 : 12,
      overflow: "hidden",
      maxHeight: (props) => props.breakpoint <= 3 ? 55 : 80,
    },
    aboutText: {
      lineHeight: (props) => props.breakpoint <= 3 ? "12px" : "17px",
    },
    subtitle: {
    },
    socialButton: {
    },
    actionArea: {
      position: "absolute",
      bottom: 0,
      left: 0,
      right: 0
    },
    starIcon: {
      color: theme.palette.secondary.light
    }
}));

export default useStyles;
