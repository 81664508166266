import {
  FETCHING_BOOKMARKS_REQUEST,
  FETCHING_BOOKMARKS_SUCCESS,
  FETCHING_BOOKMARKS_FAILURE,
  CREATE_BOOKMARK_REQUEST,
  CREATE_BOOKMARK_SUCCESS,
  CREATE_BOOKMARK_FAILURE,
  DELETE_BOOKMARK_REQUEST,
  DELETE_BOOKMARK_SUCCESS,
  DELETE_BOOKMARK_FAILURE,
} from "../actions/types";

import ResponseService from "../../utils/ResponseService";
import ApplicationError from "../../utils/ApplicationError";

const initialState = {
  bookmarks: [],
  error: ApplicationError.buildDefault(),
  isFetching: false,
  ...ResponseService.defaultPaginationMetaData(),
};

const bookmarksReducer = (state = initialState, action) => {
  switch (action.type) {
    // ALL
    case FETCHING_BOOKMARKS_REQUEST:
      return { ...state, isFetching: true };
    case FETCHING_BOOKMARKS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        bookmarks: action.payload.objects,
        error: ApplicationError.buildDefault(),
        ...action.payload.paginationData,
      };
    case FETCHING_BOOKMARKS_FAILURE:
      return { ...state, isFetching: false, error: action.payload };

    // Create
    case CREATE_BOOKMARK_REQUEST:
      return { ...state, isFetching: true };
    case CREATE_BOOKMARK_SUCCESS:
      return {
        ...state,
        isFetching: false,
        bookmark: action.payload,
        error: ApplicationError.buildDefault(),
      };
    case CREATE_BOOKMARK_FAILURE:
      return { ...state, isFetching: false, error: action.payload };

    // Delete
    case DELETE_BOOKMARK_REQUEST:
      return { ...state, isFetching: true };
    case DELETE_BOOKMARK_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: ApplicationError.buildDefault(),
      };
    case DELETE_BOOKMARK_FAILURE:
      return { ...state, isFetching: false, error: action.payload };

    default:
      return state;
  }
};

export default bookmarksReducer;
