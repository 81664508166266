import React, { useEffect, useState } from "react";
import {
  makeStyles,
  Modal,
  Typography,
  Fade,
  Grid,
  CardHeader,
  Card,
  CardContent,
  CardActions,
  Button,
  Avatar,
  TextField,
} from "@material-ui/core";
import { Client as ConversationsClient } from "@twilio/conversations";
import { Trans } from "react-i18next";
import { createMessage } from "../../../redux/actions/MessagesActions";
import Message from "../../../models/Message";
import {
  createConversation,
  createTwilioIdentity,
} from "../../../redux/actions/ConversationsActions";
import Conversation from "../../../models/Conversation";
import { useDispatch, useSelector } from "react-redux";
import FrontendRoutes from "../../../constants/FrontendRoutes";
import MyStore from "../../../utils/MyStore";
import useTracking, {
  EVENT_TRACKING_TYPES,
} from "../../engines/tracking/useTracking";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "grid",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    maxWidth: "800px",
    minWidth: "500px",
  },
  inner: {
    padding: theme.spacing(2),
  },
}));

function StartChatModal({ open, chatUser, handleClose, history }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [trackEvent] = useTracking();

  const [value, setValue] = useState("");
  const [statusString, setStatusString] = useState("");
  const [status, setStatus] = useState("");
  const [newMessageMode, setNewMessageMode] = useState("");
  const [conversationsReady, setConversationsReady] = useState("");

  const exhibitions = useSelector((state) => state.exhibitionsReducer);
  const usersReducer = useSelector((state) => state.usersReducer);
  const conversationsReducer = useSelector(
    (state) => state.conversationsReducer
  );

  const sendChatMessage = async () => {
    if (!exhibitions.exhibition || value === "") {
      return;
    }
    const token = conversationsReducer.token;
    if (!token) {
      return;
    }
    window.conversationsClient = ConversationsClient;
    const conversationsClient = await ConversationsClient.create(token);

    setStatusString("Connecting to Twilio…");
    const exhibitionId = exhibitions.exhibition.state.id;
    const currentUser = usersReducer.currentUser.state;
    const user = chatUser.state;

    const readMessage = async (conversation, lastMessageIndex) => {
      await conversation.advanceLastReadMessageIndex(lastMessageIndex);
      await conversation.updateLastReadMessageIndex(lastMessageIndex);
    };

    const sendMessage = async (conversation) => {
      const lastMessageIndex = await conversation.sendMessage(value);
      await readMessage(conversation, lastMessageIndex);
    };

    conversationsClient.on("connectionStateChanged", async (state) => {
      if (state === "connecting") {
        setStatusString("Connecting to Twilio…");
        setStatus("default");
      }
      if (state === "connected") {
        setStatusString("You are connected.");
        setStatus("success");
        const uniqueName = `dm-${exhibitionId}-${[
          Number(user.id),
          Number(currentUser.id),
        ]
          .sort()
          .join("-")}`;

        const attributes = {
          [currentUser.id]: {
            img: user.profilePicture ? user.profilePicture.state.url : "",
            nameChat: `${user.firstName} ${user.lastName}`,
            userForVideo: chatUser,
          },
          [user.id]: {
            img: currentUser.profilePicture
              ? currentUser.profilePicture.state.url
              : "",
            nameChat: `${currentUser.firstName} ${currentUser.lastName}`,
            userForVideo: usersReducer.currentUser,
          },
        };

        try {
          const conversationExisting = await conversationsClient.getConversationByUniqueName(
            uniqueName
          );
          if (
            !conversationExisting.channelState.attributes[currentUser.id]
              .userForVideo
          ) {
            conversationExisting.updateAttributes(attributes);
          }
          setNewMessageMode(!newMessageMode);
          await sendMessage(conversationExisting);
        } catch (error) {
          console.log("error", error);

          await dispatch(createTwilioIdentity(user.id));

          const conversationNew = await conversationsClient.createConversation({
            uniqueName: uniqueName,
            friendlyName: `Chat with ${user.firstName} ${user.lastName}`,
            attributes,
          });
          await conversationNew.add(`${exhibitionId}-${user.id}`);
          await conversationNew.join();
          setNewMessageMode(!newMessageMode);
          trackEvent(EVENT_TRACKING_TYPES.chat.conversationStarted, {
            conversation: uniqueName,
          });
          await sendMessage(conversationNew);
        }
      }
      if (state === "disconnecting") {
        setStatusString("Disconnecting from Twilio…");
        setConversationsReady(false);
        setStatus("default");
      }
      if (state === "disconnected") {
        setStatusString("Disconnected.");
        setConversationsReady(false);
        setStatus("warning");
      }
      if (state === "denied") {
        setStatusString("Failed to connect.");
        setConversationsReady(false);
        setStatus("error");
      }
    });

    handleClose();

    // const url = FrontendRoutes.chat.show(conversations.conversation.getId());
    // history.push(url); xxx removed due to disfunction in routing combined with redux. to be fixed
  };

  const handleInputChange = (e) => {
    if (e.target.value.split("\n").length < 6) {
      setValue(e.target.value);
    }
  };

  return !chatUser ? (
    <div />
  ) : (
    <Modal className={classes.root} open={open} onClose={() => handleClose()}>
      <Fade in={open}>
        <Card className={classes.paper}>
          <CardHeader
            title={
              <Typography variant="h2">
                <Trans>frontend.components.chat.create_new_message</Trans>
              </Typography>
            }
          />
          <CardContent>
            <Grid container direction="column">
              <Grid container item direction="row" alignItems="center">
                <Grid item xs={2}>
                  <Avatar
                    src={
                      chatUser.getProfilePicture()
                        ? chatUser.getProfilePicture().getUrl("medium")
                        : ""
                    }
                    sizes="large"
                  />
                </Grid>
                <Grid item xs={10}>
                  <Typography variant="h5">{chatUser.getFullName()}</Typography>
                </Grid>
              </Grid>
              <Grid item style={{ marginTop: "16px", width: "100%" }}>
                <TextField
                  multiline
                  rows={1}
                  rowsMax={5}
                  inputProps={{
                    maxlength: 500,
                    maxRows: 5,
                    rows: 1,
                  }}
                  variant="outlined"
                  style={{ width: "100%" }}
                  value={value}
                  onChange={handleInputChange}
                />
              </Grid>
            </Grid>
          </CardContent>
          <CardActions>
            <Button variant="contained" onClick={() => handleClose()}>
              <Trans>frontend.components.chat.abort</Trans>
            </Button>
            <Button
              variant="contained"
              style={{ marginLeft: "auto" }}
              color="secondary"
              onClick={() => sendChatMessage()}
            >
              <Trans>frontend.components.chat.send</Trans>
            </Button>
          </CardActions>
        </Card>
      </Fade>
    </Modal>
  );
}

export default StartChatModal;
