import {
  FETCHING_EXHIBITION_EXHIBITORS_USERS_REQUEST,
  FETCHING_EXHIBITION_EXHIBITORS_USERS_SUCCESS,
  FETCHING_EXHIBITION_EXHIBITORS_USERS_FAILURE,
  FETCHING_EXHIBITION_EXHIBITORS_USER_REQUEST,
  FETCHING_EXHIBITION_EXHIBITORS_USER_SUCCESS,
  FETCHING_EXHIBITION_EXHIBITORS_USER_FAILURE,
  CREATE_EXHIBITION_EXHIBITORS_USER_REQUEST,
  CREATE_EXHIBITION_EXHIBITORS_USER_SUCCESS,
  CREATE_EXHIBITION_EXHIBITORS_USER_FAILURE,
  UPDATE_EXHIBITION_EXHIBITORS_USER_REQUEST,
  UPDATE_EXHIBITION_EXHIBITORS_USER_SUCCESS,
  UPDATE_EXHIBITION_EXHIBITORS_USER_FAILURE,
  DELETE_EXHIBITION_EXHIBITORS_USER_REQUEST,
  DELETE_EXHIBITION_EXHIBITORS_USER_SUCCESS,
  DELETE_EXHIBITION_EXHIBITORS_USER_FAILURE,

  CLEAR_EXHIBITION_EXHIBITORS_USER
} from "./types";
import Urls, { DEFAULT_OPTIONAL_PARAMETERS } from "../../constants/Urls";
import { BEARER } from "../../utils/MyStore";
import ResponseService from "../../utils/ResponseService";
import ApplicationError from "../../utils/ApplicationError";
import UserMapper from "../../mapper/UserMapper";
import ErrorLogger from "../../utils/ErrorLogger";

const CLASS = "ExhibitorsUsersActions";

export const fetchingExhibitorsUsersRequest = () => ({
  type: FETCHING_EXHIBITION_EXHIBITORS_USERS_REQUEST,
});

export const fetchingExhibitorsUsersSuccess = (json) => ({
  type: FETCHING_EXHIBITION_EXHIBITORS_USERS_SUCCESS,
  payload: json,
});
export const fetchingExhibitorsUsersFailure = (error) => ({
  type: FETCHING_EXHIBITION_EXHIBITORS_USERS_FAILURE,
  payload: error,
});

export const fetchingExhibitorsUserRequest = () => ({
  type: FETCHING_EXHIBITION_EXHIBITORS_USER_REQUEST,
});

export const fetchingExhibitorsUserSuccess = (exhibitorsUser) => ({
  type: FETCHING_EXHIBITION_EXHIBITORS_USER_SUCCESS,
  payload: exhibitorsUser,
});
export const fetchingExhibitorsUserFailure = (error) => ({
  type: FETCHING_EXHIBITION_EXHIBITORS_USER_FAILURE,
  payload: error,
});

export const createExhibitorsUserRequest = () => ({
  type: CREATE_EXHIBITION_EXHIBITORS_USER_REQUEST,
});

export const createExhibitorsUserSuccess = (exhibitorsUser) => ({
  type: CREATE_EXHIBITION_EXHIBITORS_USER_SUCCESS,
  payload: exhibitorsUser,
});

export const createExhibitorsUserFailure = (error) => ({
  type: CREATE_EXHIBITION_EXHIBITORS_USER_FAILURE,
  payload: error,
});

export const updateExhibitorsUserRequest = () => ({
  type: UPDATE_EXHIBITION_EXHIBITORS_USER_REQUEST,
});

export const updateExhibitorsUserSuccess = (exhibitorsUser) => ({
  type: UPDATE_EXHIBITION_EXHIBITORS_USER_SUCCESS,
  payload: exhibitorsUser,
});

export const updateExhibitorsUserFailure = (error) => ({
  type: UPDATE_EXHIBITION_EXHIBITORS_USER_FAILURE,
  payload: error,
});

export const deleteExhibitorsUserRequest = () => ({
  type: DELETE_EXHIBITION_EXHIBITORS_USER_REQUEST,
});

export const deleteExhibitorsUserSuccess = () => ({
  type: DELETE_EXHIBITION_EXHIBITORS_USER_SUCCESS,
});

export const deleteExhibitorsUserFailure = (error) => ({
  type: DELETE_EXHIBITION_EXHIBITORS_USER_FAILURE,
  payload: error,
});

export const clearExhibitorUsers = () => ({
  type: CLEAR_EXHIBITION_EXHIBITORS_USER
})

export const fetchExhibitorsUsers = (
  exhibitionId,
  exhibitorId,
  _opts = DEFAULT_OPTIONAL_PARAMETERS
) => {
  return async (dispatch) => {
    dispatch(fetchingExhibitorsUsersRequest());

    try {
      const route = Urls.exhibitions.exhibitors.users.all;
      const bearer = localStorage.getItem(BEARER);
      const headers = Urls.header.secure(bearer);
      const url = Urls.build(
        route.url(exhibitionId, exhibitorId, _opts.pageIndex, _opts.pageSize),
        _opts.filters
      );

      let response = await fetch(url, {
        method: route.method,
        headers: headers,
      });

      let responseService = new ResponseService(UserMapper);

      let action = await responseService.call(
        response,
        (value) => fetchingExhibitorsUsersSuccess(value),
        (error) => fetchingExhibitorsUsersFailure(error)
      );

      dispatch(action);
    } catch (error) {
      ErrorLogger.call(this, error);
      dispatch(
        fetchingExhibitorsUsersFailure(
          ApplicationError.buildApplicationError(error, this)
        )
      );
    }
  };
};

export const fetchExhibitorsUser = (exhibitionId, exhibitorId, id) => {
  return async (dispatch) => {
    dispatch(fetchingExhibitorsUserRequest());

    try {
      const route = Urls.exhibitions.exhibitors.users.show;
      const bearer = localStorage.getItem(BEARER);
      const headers = Urls.header.secure(bearer);
      const url = Urls.build(route.url(exhibitionId, exhibitorId, id));

      let response = await fetch(url, {
        method: route.method,
        headers: headers,
      });

      let responseService = new ResponseService(UserMapper);

      let action = await responseService.call(
        response,
        (value) => fetchingExhibitorsUserSuccess(value),
        (error) => fetchingExhibitorsUserFailure(error)
      );

      dispatch(action);
    } catch (error) {
      ErrorLogger.call(this, error);
      dispatch(
        fetchingExhibitorsUserFailure(
          ApplicationError.buildApplicationError(error)
        )
      );
    }
  };
};

export const createExhibitorsUser = (user) => {
  return async (dispatch) => {
    dispatch(createExhibitorsUserRequest());

    try {
      const route = Urls.exhibitions.exhibitors.users.create;
      const bearer = localStorage.getItem(BEARER);
      const headers = Urls.header.secure(bearer);
      const url = Urls.build(
        route.url(user.getExhibitionId(), user.getExhibitorId())
      );

      let response = await fetch(url, {
        method: route.method,
        headers: headers,
        body: UserMapper.toJsonString(user),
      });

      let responseService = new ResponseService(UserMapper);

      let action = await responseService.call(
        response,
        (value) => createExhibitorsUserSuccess(value),
        (error) => createExhibitorsUserFailure(error)
      );

      dispatch(action);
    } catch (error) {
      ErrorLogger.call(this, error);
      dispatch(
        createExhibitorsUserFailure(
          ApplicationError.buildApplicationError(
            error,
            CLASS + "::createExhibitorsUser::"
          )
        )
      );
    }
  };
};

export const updateExhibitorsUser = (user) => {
  return async (dispatch) => {
    dispatch(updateExhibitorsUserRequest());

    try {
      const route = Urls.exhibitions.exhibitors.users.update;
      const bearer = localStorage.getItem(BEARER);
      const headers = Urls.header.secure(bearer);
      const url = Urls.build(
        route.url(user.getExhibitionId(), user.getExhibitorId(), user.getId())
      );

      let response = await fetch(url, {
        method: route.method,
        headers: headers,
        body: UserMapper.toJsonString(user),
      });

      let responseService = new ResponseService(UserMapper);

      let action = await responseService.call(
        response,
        (value) => updateExhibitorsUserSuccess(value),
        (error) => updateExhibitorsUserFailure(error)
      );

      dispatch(action);
    } catch (error) {
      ErrorLogger.call(this, error);
      dispatch(
        updateExhibitorsUserFailure(
          ApplicationError.buildApplicationError(error)
        )
      );
    }
  };
};

export const deleteExhibitorsUser = (user) => {
  return async (dispatch) => {
    dispatch(deleteExhibitorsUserRequest());

    try {
      const route = Urls.exhibitions.exhibitors.users.delete;
      const bearer = localStorage.getItem(BEARER);
      const headers = Urls.header.secure(bearer);
      const url = Urls.build(
        route.url(user.getExhibitionId(), user.getExhibitorId(), user.getId())
      );

      let response = await fetch(url, {
        method: route.method,
        headers: headers,
      });

      let responseService = new ResponseService(UserMapper);

      let action = await responseService.call(
        response,
        (value) => deleteExhibitorsUserSuccess(value),
        (error) => deleteExhibitorsUserFailure(error)
      );

      dispatch(action);
    } catch (error) {
      ErrorLogger.call(this, error);
      dispatch(
        deleteExhibitorsUserFailure(
          ApplicationError.buildApplicationError(error)
        )
      );
    }
  };
};
