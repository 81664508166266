const ORDER_BY      = '_sort'
const DESC          = ':DESC'
const ASC           = ':ASC'

export default class FilterParams {
  
  state = {}

  constructor(key, value){
    this.state = { key: key, value: value }
  }

  toUrl = () => encodeURI(this.state.key + "=" + this.state.value)

  static orderByAsc = (value)     => new this(ORDER_BY, value + ASC )
  static orderByDesc = (value)    => new this(ORDER_BY, value + DESC )

  static filterBy = (key, value)  => new this(key, value)

  static formatUrl = (filterParams = []) => filterParams.map(filterParam => "&" + filterParam.toUrl()).join('')
}