import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import {
  Card,
  CardHeader,
  CardContent,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
} from "@material-ui/core";
import { useTranslation, Trans } from "react-i18next";
import { MenuItem } from "react-contextmenu";

const useStyles = makeStyles((theme) => ({
  root: {},
  options: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
  },
}));

function Preferences({ day, selectedTimeslot, handleSelectTimeslot, className, ...rest }) {
  const classes = useStyles();
  const [value, setValue] = useState(selectedTimeslot);
  const { t } = useTranslation();

  const handleFieldChange = (event, timeslot) => {
    handleSelectTimeslot(timeslot);
  }

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader title={t("frontend.which_time")} />
      <CardContent>
        <FormControl className={classes.formControl} style={{width:"100%"}}>
          <InputLabel htmlFor="timeSlot"><Trans>frontend.time</Trans></InputLabel>
          <Select
              label={value ? (<Typography variant="body1" color="textPrimary">value.toLocaleTimeString()</Typography>):""}
              value={value}
              onChange={(event) =>
                handleFieldChange(event, event.target.value)
              }
              inputProps={{
                name: "timeSlot",
                id: "timeSlot"
              }}
            >
            {day.getFreeSlots().map((timeslot) => {
              return <MenuItem value={timeslot}>{timeslot.toLocaleTimeString()}</MenuItem>;
            })}
          </Select>
          <Typography className={classes.fieldHint} variant="body2">
            {t("frontend.which_time_tooltip")}
          </Typography>
        </FormControl>
      </CardContent>
    </Card>
  );
}

Preferences.propTypes = {
  className: PropTypes.string,
};

export default Preferences;
