import CommentsLike from "../models/CommentsLike";
import BaseMapper from "./BaseMapper";
import ErrorLogger from "../utils/ErrorLogger";

export default class CommentsLikeMapper {
  static build = (json) => {
    if (!json) {
      return;
    }

    try {
      return new CommentsLike({
        ...BaseMapper.attributes(json),
        userId: json.user_id,
        commentId: json.comment_id
      });
    } catch (error) {
      ErrorLogger.call(this, error);
    }
  };

  static buildFromArray = (jsonArray = []) => {
    return jsonArray.map((entry) => {
      return this.build(entry);
    });
  };
}
