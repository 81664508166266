import React from 'react'
import cogoToast from 'cogo-toast';
import { Card, CardHeader, Typography, CardContent, Avatar, CardActionArea, Grid, IconButton, Button, CardActions } from '@material-ui/core';
import { Trans } from 'react-i18next';
import PhoneIcon from '@material-ui/icons/Phone';
import CallEndIcon from '@material-ui/icons/CallEnd';
import Sound from 'react-sound'
import CloseIcon from '@material-ui/icons/Close';

const Entities = require("html-entities").XmlEntities;
export default class CogoToast {

  static error(message){
    cogoToast.error(message, {
      hideAfter: 5,
      position: 'top-right',
      heading: 'Fehler',
    })
  }

  static info(message, options={}){
    cogoToast.info(message, {
      hideAfter: 10,
      position: 'top-right',
      ...options
    })
  }

  static newChatMessage(message, options={}){
    const { hide } = cogoToast.info(message, {
      hideAfter: 5,
      position: 'top-right',
      onClick: () => {
        if(options.onClick !== undefined) { options.onClick() }
        hide();
      },
      ...options
    })
  }

  static success(message, options={}){
    cogoToast.success(message, {
      hideAfter:5,
      position: 'top-right',
      heading: "Erfolgreich"
    })
  }

  static saved(message){
    cogoToast.success(message, {
      hideAfter: 5,
      position: 'top-right',
      heading: 'Gespeichert'
    })
  }

  static deleted(message){
    cogoToast.success(message, {
      hideAfter: 5,
      position: 'top-right'
    })
  }

  static started(message, options = {}) {
    const { hide } = cogoToast.info(message, {
      hideAfter: 10,
      position: 'top-center',
      heading: options.heading,
      onClick: () => {
        if(options.onClick !== undefined) { options.onClick() }
        hide();
      },
    })
  }

  static started(message, options = {}) {
    const { hide } = cogoToast.info((<div>
      <b>
        {options.heading}
      </b>
      <div>
        {message}
      </div>
    </div>), {
      hideAfter: 10,
      position: 'top-center',
      onClick: () => {
        if(options.onClick !== undefined) { options.onClick() }
        hide();
      },
    })
  }

  static custom(message, options = {}) {
    const { hide } = cogoToast.info((
      <Grid container direction="row">
        
          <div dangerouslySetInnerHTML={{
            __html: new Entities().decode(message),
          }}/>
          <IconButton onClick={() => {
            hide();
          }}>
            <CloseIcon />
          </IconButton>
      </Grid>
    ),{
      hideAfter: 10,
      ...options
    })
  }

  static incomingCall(options = {}, otherUser, handleAccept, handleDecline, meeting) {
    const { hide } = cogoToast.loading(
      (<Card>
        <CardHeader
          title={
            <Typography variant="h4" style={{textAlign:"center"}}>
              {otherUser.getFullName()}<Trans>frontend.notifications.meeting.asks_for_video_chat</Trans>
            </Typography>
          }
        />
        <CardContent>
          <div style={{width:"300px",height:"300px", marginLeft:"auto", marginRight:"auto"}}>
            <Avatar img={otherUser.getProfilePicture() ? otherUser.getProfilePicture().getUrl("medium"):""} style={{width:"100%", height:"100%"}} />
          </div>
          <Sound
            url={require("../assets/sounds/incoming_call.wav")}
            playStatus={Sound.status.PLAYING}
            loop={true}
          />
        </CardContent>
        <CardActions>
          <Grid container direction="row">
            <Grid container item xs={6} direction="column" justify="center">
              <Button startIcon={(<PhoneIcon style={{color:"green"}}/>)} onClick={() => {
                handleAccept();
                hide();
              }}>
                <Typography variant="caption" style={{textAlign:"center"}}><Trans>frontend.notifications.meeting.accept</Trans></Typography>
              </Button>
            </Grid>
            <Grid item container direction="column" xs={6} justify="center">
              <Button startIcon={<CallEndIcon style={{color:"red"}} />} onClick={() => {
                handleDecline();
                hide();
              }}>
                <Typography variant="caption" style={{textAlign:"center"}}><Trans>frontend.notifications.meeting.decline</Trans></Typography>
              </Button>
            </Grid>
          </Grid>
        </CardActions>
      </Card>), {
      hideAfter: 1000,
      position: 'top-center',
    })
  }
}
