import {
  Avatar,
  Button,
  CircularProgress,
  Divider,
  Grid,
  LinearProgress,
  Typography,
} from "@material-ui/core";
import StarIcon from "@material-ui/icons/Star";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import { makeStyles } from "@material-ui/styles";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { DEFAULT_PAGE_INDEX } from "../../../../../constants/Urls";
import { useExhibitorsQLReduced } from "../../../../../engines/GraphQL/hooks/useExhibitorsQL";
import { bookmarkExhibitor } from "../../../../../redux/actions/ExhibitorsActions";
import { compareEntitiesById } from "../../../../../utils";
import CogoToast from "../../../../../utils/CogoToast";
import GlobalContext from "../../context/GlobalContext";
import BookmarkSwitch from "../BookmarkSwitch";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 16,
  },
  header: {
    height: 40,
    marginBottom: 8,
  },
  title: {
    height: 40,
    textAlign: "center",
    justifySelf: "center",
    marginRight: "auto",
  },
  exhibitorRow: {
    paddingLeft: 12,
  },
  exhibitorsList: {
    marginTop: 8,
  },
  circularProgress: {},
  exploreBrands: {
    width: "95%",
    height: "50px",
    marginTop: "15px",
    background: `linear-gradient(to right, ${theme.palette.secondary.main}, ${theme.palette.primary.light})`,
    "&:hover": {
      background: `linear-gradient(to right, ${theme.palette.secondary.main}, ${theme.palette.primary.light})`,
    },
  },
  exploreBrandsLabel: {
    color: theme.palette.secondary.contrastText,
  },
  favIcon: {
    cursor: "pointer",
  },
  starIcon: {
    color: theme.palette.secondary.light,
    cursor: "pointer",
  },
}));

function ExhibitorsShortList({ exhibition, filterString }) {
  const classes = useStyles();
  const context = useContext(GlobalContext);
  const containerRef = useRef();
  const [showBookmarks, setShowBookmarks] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(DEFAULT_PAGE_INDEX);
  const [
    exhibitors,
    loading,
    totalPages,
    refetchBookmarks,
  ] = useExhibitorsQLReduced(undefined, currentIndex, 15, showBookmarks, true);

  const dispatch = useDispatch();
  const [t] = useTranslation();

  const [bookmarked, setBookmarked] = useState(false);
  const [unbookmarked, setUnbookmarked] = useState(false);
  const exhibitorsReducer = useSelector((state) => state.exhibitorsReducer);
  const [exhibitor, setExhibitor] = useState();
  const [bookmarkLoading, setBookmarkLoading] = useState(false);

  useEffect(() => {
    if (!unbookmarked) {
      return;
    }

    dispatch(bookmarkExhibitor(exhibitor));
    setBookmarkLoading(true);
  }, [unbookmarked]);

  useEffect(() => {
    if (
      context.bookmarkedPost &&
      !context.bookmarkedPost.getExhibitor().isBookmarked()
    ) {
      handleBookmark(context.bookmarkedPost.getExhibitor());
      context.setBookmarkedPost(undefined);
    }
  }, [context.bookmarkedPost]);

  useEffect(() => {
    if (!bookmarked) {
      return;
    }

    dispatch(bookmarkExhibitor(exhibitor));
    setBookmarkLoading(true);
  }, [bookmarked]);

  // Bookmark
  useEffect(() => {
    if (
      (!bookmarked && !unbookmarked) ||
      !bookmarkLoading ||
      exhibitorsReducer.isFetching ||
      exhibitorsReducer.error.isError()
    ) {
      if (exhibitorsReducer.error.isError()) {
        setBookmarkLoading(false);
      }
      return;
    }

    if (bookmarked) {
      exhibitor.setIsBookmarked(true);
      CogoToast.info(t("frontend.added_bookmark"));
    }

    if (unbookmarked) {
      exhibitor.setIsBookmarked(false);
      CogoToast.info(t("frontend.removed_bookmark"));
    }

    refetchBookmarks();
    setBookmarkLoading(false);
    setBookmarked(false);
    setUnbookmarked(false);
  }, [exhibitorsReducer.isFetching]);

  const handleBookmark = (e) => {
    setExhibitor(e);
    e.isBookmarked() ? setUnbookmarked(true) : setBookmarked(true);
  };

  return (
    <div ref={containerRef} className={classes.root}>
      <Grid
        container
        direction="row"
        justify="center"
        className={classes.header}
      >
        <Typography variant="h3" className={classes.title}>
          <Trans>frontend.our_exhibitors</Trans>
        </Typography>
        <BookmarkSwitch
          horizontal={true}
          setShowBookmarks={setShowBookmarks}
          showBookmarks={showBookmarks}
        />
      </Grid>
      <Divider />
      {loading && <LinearProgress />}
      <Grid container spacing={2} className={classes.exhibitorsList}>
        {exhibitors &&
          exhibitors.map((e) =>
            e.isBookmarked() || !showBookmarks ? (
              <Grid
                container
                item
                direction="row"
                alignItems="center"
                className={classes.exhibitorRow}
              >
                <Grid item xs={3} container alignItems="flex-start">
                  <Avatar
                    style={{ cursor: "pointer" }}
                    onClick={() => context.setCurrentExhibitor(e)}
                    src={
                      e.getProfileThumb()
                        ? e.getProfileThumb().getUrl("small")
                        : ""
                    }
                  />
                </Grid>
                <Grid item xs={7}>
                  <Typography
                    noWrap
                    variant="h4"
                    color="textPrimary"
                    style={{ cursor: "pointer", width: "inherit" }}
                    onClick={() => context.setCurrentExhibitor(e)}
                  >
                    {e.getName()}
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  {bookmarkLoading && compareEntitiesById(exhibitor, e) ? (
                    <CircularProgress
                      color="secondary"
                      size={20}
                      thickness={7}
                      className={classes.circularProgress}
                    />
                  ) : e && e.isBookmarked() ? (
                    <StarIcon
                      onClick={() => handleBookmark(e)}
                      className={classes.starIcon}
                    />
                  ) : (
                    <StarBorderIcon
                      onClick={() => handleBookmark(e)}
                      className={classes.favIcon}
                    />
                  )}
                </Grid>
              </Grid>
            ) : undefined
          )}
        <Button
          variant="contained"
          className={classes.exploreBrands}
          onClick={() => context.setTabIndex(1)}
        >
          <Typography variant="h4" className={classes.exploreBrandsLabel}>
            <Trans>frontend.explore_exhibitors</Trans>
          </Typography>
        </Button>
      </Grid>
    </div>
  );
}

export default ExhibitorsShortList;
