import ApplicationModel from "./ApplicationModel"

export default class Organization extends ApplicationModel{
  /*
    Additional Getter
  */

  constructor(_opts){
    super()
    this.state = { ..._opts }
  }

  getName = () => this.state.name
  setName = (name) => this.state.name = name

  getCurrentExhibition = () => this.state.currentExhibition

  // Backend Attributes
  static attributes = {
    ...super.attributes,
    name: 'name'
  }

  static orderByParams = {
    ...super.orderByParams,
    name: this.attributes.name
  }

  static filterParams = {
    ...super.filterParams
  }
}