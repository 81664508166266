/* eslint-disable react/no-multi-comp */
import React, { useEffect, useState } from "react";
import { useLocation, matchPath } from "react-router";
import { Link as RouterLink } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import {
  Drawer,
  Divider,
  List,
  Hidden,
  colors,
  Grid,
  Typography, IconButton, Button
} from "@material-ui/core";
import NavItem from "../../components/NavItem";
import navConfig from "./navConfig";
import MyStore from "../../../utils/MyStore";
import { useHistory } from "react-router";
import User from "../../../models/User";
import FrontendRoutes from "../../../constants/FrontendRoutes";
import useConfigurationService from "../../../utils/ConfigurationService";
import { VideoLabelOutlined, Close } from "@material-ui/icons";
import useExhibition from "../../../engines/GeneralHooks/useExhibition";
import ExhibitorsUsers from "../../components/ExhibitorsUsers";
import Exhibition from "../../../models/Exhibition";
import VideoBox from "./components/VideoBox/Mobile";
import ExhibitorsShortList from "./components/ExhibitorsShortList";
import NeumorphicStyle from "../../../constants/Neumorphic";

const { auth, profiles } = FrontendRoutes;

const useStyles = makeStyles(theme => ({
  root: {
    background: theme.palette.background.default,
    borderRadius: NeumorphicStyle.borderRadius,
    boxShadow: NeumorphicStyle.boxShadowLarge(theme.palette.primary.main,theme.palette.secondary.dark),
    width: 250,
    left: 16,
    position: "relative",
    top: 50,
    height: "calc(100% - 400px)",
    zIndex: 999,
    overflowY: "scroll"
  },
  rootLanding: {
    width: 300,
    left: 16,
    background: theme.palette.background.default,
    borderRadius: NeumorphicStyle.borderRadius,
    boxShadow: NeumorphicStyle.boxShadowLarge(theme.palette.primary.main,theme.palette.secondary.dark),
    position: "relative",
    top: 50,
    bottom: 32,
    zIndex: 999,
    overflowY: "scroll"
  },
  logoBox: {
    height: 100,
    background: theme.palette.background.default,
    borderRadius: NeumorphicStyle.borderRadius,
    boxShadow: NeumorphicStyle.boxShadowLarge(theme.palette.primary.main,theme.palette.secondary.dark),
    width: 250,
    left: 16,
    position: "relative",
    top: 16,
    zIndex: 999
  },
  logo: {
    marginLeft: "auto",
    width: 218,
    height: 80,
    marginLeft: 16,
    marginRight: 16,
    marginTop: 10,
    objectFit: "scale-down"
  },
  customerLinkArea: {
    height: 50,
    width: 250,
    left: 16,
    top: 30,
    zIndex: 999,
    paddingLeft: 40,
    paddingRight: 40,
    paddingTop: 20,
    paddingBottom: 20,
    borderRadius: NeumorphicStyle.borderRadius,
    position: "relative",
    background: `linear-gradient(to right, ${theme.palette.secondary.main}, ${theme.palette.primary.light})`,
    borderRadius: NeumorphicStyle.borderRadius,
    boxShadow: NeumorphicStyle.boxShadowLarge(theme.palette.primary.main,theme.palette.secondary.dark),
  },
  customerLinkImage: {
    objectFit: "scale-down",
    width: "100%",
    height: "100%"
  },
  videoBox: {
    height: 141,
    width: 250,
    left: 16,
    background: theme.palette.background.default,
    boxShadow: NeumorphicStyle.boxShadowLarge(theme.palette.primary.main,theme.palette.secondary.dark),
    borderRadius: NeumorphicStyle.borderRadius,
    bottom: 32,
    position: "absolute",
    zIndex: 999,
    transition: "width 0.2s, height 0.2s, opacity 0.2s 0.2s",
  },
  videoBoxCinema: {
    height: 507,
    background: theme.palette.background.default,
    boxShadow: NeumorphicStyle.boxShadowLarge(theme.palette.primary.main,theme.palette.secondary.dark),
    borderRadius: NeumorphicStyle.borderRadius,
    width: 900,
    left: 16,
    bottom: 32,
    position: "absolute",
    zIndex: 999,
    transition: "width 0.2s, height 0.2s, opacity 0.2s 0.2s",
  },
  videoBoxFull: {
    left: 16,
    right: 16,
    top: 96,
    background: theme.palette.background.default,
    boxShadow: NeumorphicStyle.boxShadowLarge(theme.palette.primary.main,theme.palette.secondary.dark),
    borderRadius: NeumorphicStyle.borderRadius,
    bottom: 32,
    position: "absolute",
    zIndex: 999,
    transition: "width 0.2s, height 0.2s, opacity 0.2s 0.2s",
  },
  mobileDrawer: {
    width: 256,
    background: `linear-gradient(${theme.palette.primary.main},  ${theme.palette.secondary.dark});`,
  },
  desktopDrawer: {

  },
  navigation: {
    overflow: "auto",
    padding: theme.spacing(4, 4, 4, 4),
    flexGrow: 1
  },
  profile: {
    padding: theme.spacing(2),
    display: "flex",
    alignItems: "center"
  },
  badge: {
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`
  },
  badgeDot: {
    height: 9,
    minWidth: 9
  },
  onlineBadge: {
    backgroundColor: colors.green[600]
  },
  awayBadge: {
    backgroundColor: colors.orange[600]
  },
  busyBadge: {
    backgroundColor: colors.red[600]
  },
  offlineBadge: {
    backgroundColor: colors.grey[300]
  },
  avatar: {
    cursor: "pointer",
    width: 40,
    height: 40
  },
  details: {
    marginLeft: theme.spacing(2)
  },
  moreButton: {
    marginLeft: "auto",
    color: theme.palette.primary.main
  },
  usersGrid: {
    width: "100%"
  }
}));

function ScheduleBar({ openMobile, onMobileClose, setKeynoteConversation, setParentVideoMode, className, ...rest }) {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const [config] = useConfigurationService()
  const [exhibition] = useExhibition();

  const users = useSelector(state => state.usersReducer);

  const [user, setUser] = useState(new User());

  useEffect(() => {
    if (!users.user) {
      return;
    }
    setUser(users.user);
  }, [users.user]);

  useEffect(() => {
    if (users.error.isUnauthorized()) {
      MyStore.removeAllKeys();
      history.push(auth.login());
    }
  }, [users.isFetching]);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }

    // eslint-disable-next-line
  }, [location.pathname]);

  return !config ? <div /> : (
    <div>
      <div className={classes.logoBox}>
        <img className={classes.logo} src={exhibition && exhibition.getProfilePicture() ? exhibition.getProfilePicture().getUrl("medium") : ""} />
      </div>
      {exhibition && exhibition.getOrganizerLink() && exhibition.getOrganizerLinkImage() && (
        <Button className={classes.customerLinkArea} href={exhibition.getOrganizerLink()}>
          <img className={classes.customerLinkImage} src={exhibition && exhibition.getOrganizerLinkImage() ? exhibition.getOrganizerLinkImage().getUrl():""} />
        </Button>
      )}
      <div
        className={exhibition && exhibition.getExhibitionType() === Exhibition.EXHIBITION_TYPES.CLASSIC ? classes.root : classes.rootLanding}
      >
        {exhibition && exhibition.getExhibitionType() === Exhibition.EXHIBITION_TYPES.LANDINGPAGE ? (
          <ExhibitorsUsers exhibitorsUsers={exhibition && exhibition.getUsers()} />
        ):(
          <ExhibitorsShortList exhibition={exhibition}/>
        )}
      </div>
      {exhibition && exhibition.getExhibitionType() === Exhibition.EXHIBITION_TYPES.CLASSIC && (
        <VideoBox setParentVideoMode={setParentVideoMode} setKeynoteConversation={setKeynoteConversation}/>
      )}
      <div style={{ position: "fixed", bottom: 8, left: 16 }}>
        <Typography color={"textPrimary"}>
          powered by {" "}
          {(<a href={"https://leadevents.io"} target="_blank">leadevents.io</a>)}
        </Typography>
      </div>
    </div>
  );
}

ScheduleBar.propTypes = {
  className: PropTypes.string,
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default ScheduleBar;