import { Avatar, CircularProgress, Grid, Link } from "@material-ui/core";
import StarIcon from "@material-ui/icons/Star";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import { useInView } from "react-hook-inview";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import NeumorphicStyle from "../../../../../constants/Neumorphic";
import useBreakpoints from "../../../../../engines/Util/useBreakpoints";
import { bookmarkExhibitor } from "../../../../../redux/actions/ExhibitorsActions";
import CogoToast from "../../../../../utils/CogoToast";
import BookMeetingModal from "../../../../components/BookMeetingModal/BookMeetingModal";
import useTracking, {
  EVENT_TRACKING_TYPES,
} from "../../../../engines/tracking/useTracking";
import GlobalContext from "../../context/GlobalContext";

const useStyles = makeStyles((theme) => ({
  root: {
    // background: theme.palette.background.default,
    // borderRadius: NeumorphicStyle.borderRadius,
    // boxShadow: `-5px 5px 15px ${theme.palette.primary.main}, 5px -5px 15px ${theme.palette.secondary.dark}, inset 2px -2px 3px ${theme.palette.primary.main}, inset -2px 2px 4px ${theme.palette.secondary.dark}`,
    marginBottom: "16px",
    marginRight: "16px",
    height: (props) => props.breakpoint <= 3 ? 150:250,
    width: (props) => props.breakpoint <= 3 ? 150:250,
    padding: (props) => props.breakpoint <= 3 ? 8:16,
    background: ({ primaryColor }) =>
      primaryColor || theme.palette.background.default,
    color: ({ fontColor }) => fontColor || theme.palette.text.primary,
    borderRadius: "10px",
    boxShadow: NeumorphicStyle.boxShadowMedium(
      theme.palette.primary.main,
      theme.palette.secondary.dark
    ),
    position: "relative",
    "&:hover": {
      background: ({ highlightColor }) =>
        highlightColor || theme.palette.background.default,
      color: ({ fontHighlightColor }) =>
        fontHighlightColor || theme.palette.text.primary,
    },
  },
  subheader: {
    width: "auto",
    maxWidth: "100%",
    marginTop: 12,
  },
  bookmarkIcon: {
    position: "absolute",
    right: (props) => props.breakpoint <= 3 ? 10:20,
    top: (props) => props.breakpoint <= 3 ? 10:20,
    width: (props) => props.breakpoint <= 3 ? 20:30,
    height: (props) => props.breakpoint <= 3 ? 20:30,
    cursor: "pointer",
  },
  cardHeader: {
    backgroundSize: "cover",
    width: "95%",
    paddingBottom: "10%",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    //alignItems: "flex-start",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "16px",
    borderRadius: "15px",
    boxShadow: NeumorphicStyle.boxShadowMedium(
      theme.palette.primary.main,
      theme.palette.secondary.dark
    ),
  },
  avatar: {
    width: "70%",
    height: "70%",
    objectFit: "scale-down",
  },
  avatarContainer: {
    width: "100%",
    height: "100%",
    alignItems: "center",
  },
  profileVideo: {
    width: 356,
    height: 200,
    alignItems: "center",
    justifyItems: "center",
    marginRight: "24px",
    marginTop: "20px",
    borderRadius: "10px",
    boxShadow: NeumorphicStyle.boxShadowSmall(
      theme.palette.primary.main,
      theme.palette.secondary.dark
    ),
  },
  actions: {
    background: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    borderRadius: "5px",
    boxShadow: NeumorphicStyle.boxShadowSmall(
      theme.palette.primary.main,
      theme.palette.secondary.dark
    ),
  },
  title: {
    color: theme.palette.text.primary,
    maxWidth: "100%",
  },
  accessTimeIcon: {
    color: theme.palette.text.secondary,
    fontSize: "14px",
    height: 14,
    width: 14,
    marginRight: 6,
  },
  content: {
    paddingTop: 24,
  },
  message: {
    marginBottom: theme.spacing(2),
  },
  mediaArea: {
    marginBottom: theme.spacing(2),
    padding: 16,
    justifyItems: "flex-end",
    width: "auto",
    marginLeft: "auto",
  },
  media: {
    backgroundPosition: "initial",
    width: "100%",
    paddingBottom: "55%",
    marginTop: "1em",
    marginBottom: "1em",
    objectFit: "cover",
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  contentBody: {
    "& img": {
      width: "100%",
      height: "auto",
      marginTop: "1em",
      marginBottom: "1em",
    },
    "& iframe": {
      width: "100%",
      height: "414px", // 16:9 bei einer Breite von 736px
      marginTop: "1em",
      marginBottom: "1em",
    },
    "& p": {
      marginTop: "0em",
      marginBottom: "0em",
      fontSize: "1em",
    },
  },
  starFilled: {
    color: theme.palette.secondary.light,
    position: "absolute",
    right: (props) => props.breakpoint <= 3 ? 10:20,
    top: (props) => props.breakpoint <= 3 ? 10:20,
    width: (props) => props.breakpoint <= 3 ? 20:30,
    height: (props) => props.breakpoint <= 3 ? 20:30,
    cursor: "pointer",
  },
}));

function ExhibitorCard({
  exhibitor,
  setExhibitor,
  refetchBookmarks,
  className,
  primaryColor,
  highlightColor,
  fontColor,
  fontHighlightColor,
  onClick = null,
  ...rest
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [breakpoint] = useBreakpoints();
  const classes = useStyles({
    primaryColor,
    highlightColor,
    fontColor,
    fontHighlightColor,
    breakpoint,
  });
  const [open, setOpen] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [openShareModal, setOpenShareModal] = useState(false);
  const [bookmarked, setBookmarked] = useState(false);
  const [unbookmarked, setUnbookmarked] = useState(false);
  const [trackEvent] = useTracking();
  const context = useContext(GlobalContext);
  const [bookmarkLoading, setBookmarkLoading] = useState(false);

  const exhibitorsReducer = useSelector((state) => state.exhibitorsReducer);

  let time = undefined;

  useEffect(() => {
    if (
      !bookmarkLoading ||
      exhibitorsReducer.isFetching ||
      exhibitorsReducer.error.isError() ||
      (!bookmarked && !unbookmarked)
    ) {
      if (exhibitorsReducer.error.isError()) {
        setBookmarkLoading(false);
      }
      return;
    }

    if (bookmarked) {
      exhibitor.setIsBookmarked(true);
      CogoToast.info(t("frontend.added_bookmark"));
    }

    if (unbookmarked) {
      exhibitor.setIsBookmarked(false);
      CogoToast.info(t("frontend.removed_bookmark"));
    }

    refetchBookmarks();
    if (context) {
      context.setUpdateShortlist(true);
    }
    setBookmarkLoading(false);
    setBookmarked(false);
    setUnbookmarked(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exhibitorsReducer.isFetching]);

  // eslint-disable-next-line no-unused-vars
  const [ref, isVisible] = useInView({
    threshold: 0.5,
    onEnter: () => {
      time = Date.now();
      setTimeout(() => timer(), 20000);
    },
    onLeave: () => {
      timer();
    },
  });

  const timer = () => {
    if (!time) {
      return;
    }
    let diff = Date.now() - time;

    time = undefined;

    if (diff >= 800) {
      //trackEvent(EVENT_TRACKING_TYPES.exhibitor.exhibitorTileViewed, { exhibitor: exhibitor.getId() })
    }
  };

  const handleClose = () => {
    setOpen(false);
    setOpenShareModal(false);
  };

  useEffect(() => {
    if (!unbookmarked) {
      return;
    }

    dispatch(bookmarkExhibitor(exhibitor));
    trackEvent(EVENT_TRACKING_TYPES.exhibitor.unbookmark, {
      exhibitor: exhibitor.getId(),
    });
    setBookmarkLoading(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unbookmarked]);

  useEffect(() => {
    if (!bookmarked) {
      return;
    }

    dispatch(bookmarkExhibitor(exhibitor));
    trackEvent(EVENT_TRACKING_TYPES.exhibitor.bookmark, {
      exhibitor: exhibitor.getId(),
    });
    setBookmarkLoading(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookmarked]);

  const handleBookmark = () => {
    exhibitor.isBookmarked() ? setUnbookmarked(true) : setBookmarked(true);
  };

  return (
    <Grid
      ref={ref}
      container
      item
      alignItems="center"
      justify="center"
      className={clsx(classes.root, className)}
    >
      {bookmarkLoading ? (
        <CircularProgress
          thickness={7.0}
          size={20}
          color="secondary"
          className={classes.bookmarkIcon}
        />
      ) : exhibitor.isBookmarked() ? (
        <StarIcon
          className={classes.starFilled}
          onClick={() => handleBookmark()}
        />
      ) : (
        <StarBorderIcon
          className={classes.bookmarkIcon}
          onClick={() => handleBookmark()}
        />
      )}
      <Grid
        className={classes.avatarContainer}
        container
        item
        direction="column"
        alignItems="center"
        justify="center"
      >
        <Avatar
          alt="Person"
          className={classes.avatar}
          style={{ cursor: "pointer" }}
          component={RouterLink}
          src={
            exhibitor && exhibitor.getProfileThumb()
              ? exhibitor.getProfileThumb().getUrl("medium")
              : ""
          }
          onClick={() => {
            if (onClick) {
              onClick();
            }
            setExhibitor(exhibitor);
            trackEvent(EVENT_TRACKING_TYPES.exhibitor.exhibitorOpened, {
              exhibitor: exhibitor.getId(),
              source: 'logo',
            });
          }}
        />
        <Grid className={classes.subheader} direction="column" container>
          <Link
            variant={breakpoint <= 3 ? "h5":"h4"}
            noWrap
            onClick={() => {
              if (onClick) {
                onClick();
              }
              setExhibitor(exhibitor);
              trackEvent(EVENT_TRACKING_TYPES.exhibitor.exhibitorOpened, {
                exhibitor: exhibitor.getId(),
                source: 'name',
              });
            }}
            className={classes.title}
            style={{ cursor: "pointer", color: "inherit" }}
          >
            {exhibitor.getName()}
          </Link>
        </Grid>
      </Grid>

      <BookMeetingModal
        open={open}
        handleClose={() => handleClose()}
        exhibitor={exhibitor}
        fromClass="Exhibitor"
        payload={exhibitor}
      />
      {/* <ShareModal open={openShareModal} handleClose={() => handleClose()} payload={exhibitor} /> */}
    </Grid>
  );
}

ExhibitorCard.propTypes = {
  className: PropTypes.string,
  exhibitor: PropTypes.object.isRequired,
};

export default ExhibitorCard;