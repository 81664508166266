import { FormControl, MenuItem, Select, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import useDevices from "./useDevices";

export default function VideoInputList({ localTracks }) {
  const { t } = useTranslation();
  const { videoInputDevices } = useDevices();

  const localVideoTrack = localTracks.find((track) => track.kind === "video");
  const [storedLocalVideoDeviceId, setStoredLocalVideoDeviceId] = useState("");
  const localVideoInputDeviceId =
    storedLocalVideoDeviceId ||
    localVideoTrack?.mediaStreamTrack?.getSettings().deviceId ||
    "";

  function replaceTrack(newDeviceId) {
    setStoredLocalVideoDeviceId(newDeviceId);
    if (localVideoTrack) {
      localVideoTrack.restart({
        width: 1280,
        height: 720,
        frameRate: 24,
        deviceId: { exact: newDeviceId },
      });
    }
  }

  return (
    <FormControl fullWidth>
      <Typography variant="subtitle2" gutterBottom>
        {t("frontend.video_input")}
      </Typography>
      <Select
        onChange={(e) => replaceTrack(e.target.value)}
        value={localVideoInputDeviceId}
        variant="outlined"
      >
        {videoInputDevices.map((device) => (
          <MenuItem value={device.deviceId} key={device.deviceId}>
            {device.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
