import BaseMapper from "./BaseMapper";
import LocalText from "../models/LocalText";
import VideoMapper from "./VideoMapper";
import ConversationMapper from "./ConversationMapper";
import KeynoteMapper from "./KeynoteMapper";
import DocumentMapper from "./DocumentMapper";

const attributes = LocalText.attributes

export default class LocalTextMapper {
  static build = json => {
    if (!json) {
      return
    }

    return new LocalText({
      ...BaseMapper.attributes(json),
      text: json[attributes.text],
      language: json[attributes.language],
      video: VideoMapper.build(json[attributes.video]),
      streamUrl: json[attributes.streamUrl],
      title: json[attributes.title],
      conversation: ConversationMapper.build(json[attributes.conversation]),
      activeKeynote: KeynoteMapper.build(json[attributes.activeKeynote]),
      factSheet: DocumentMapper.build(json[attributes.factSheet])
    });
  };

  static buildFromArray = (jsonArray = []) => {
    if (!jsonArray) {
      return
    }
    return jsonArray.map(entry => {
      return this.build(entry);
    });
  };

  static toJsonString = (localText) => {
    return JSON.stringify({
      text: localText.getText(),
      language: localText.getLanguage(),
    });
  };
}
