import { useLazyQuery, useQuery } from '@apollo/client';
import React, { useContext, useEffect, useState } from 'react'
import { DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE } from '../../../constants/Urls';
import useExhibition from '../../../frontend/engines/GeneralHooks/useExhibition';
import GlobalContext from '../../../frontend/layouts/Dashboard/context/GlobalContext';
import BookmarkMapper from '../../../mapper/BookmarkMapper';
import CommentMapper from '../../../mapper/CommentMapper';
import VisitorMapper from '../../../mapper/UserMapper';
import MyStore from '../../../utils/MyStore';
import commentsQueries from '../queries/commentsQueries';
import visitorQueries from '../queries/visitorQueries';

function useCommentsQL(postId, pageIndex = DEFAULT_PAGE_INDEX, pageSize = DEFAULT_PAGE_SIZE) {
    const [totalPages, setTotalPages] = useState(1);
    const [comments, setComments] = useState([]);
    const [exhibition] = useExhibition();
    const context = useContext(GlobalContext);

    const [fetchData, {loading, data, error, refetch}] = useLazyQuery(commentsQueries.feedQuery, {
        variables: {
            postId: postId || undefined,
            start: ((pageIndex - 1) * pageSize),
            limit: pageSize,
            exhibitionId: exhibition ? exhibition.getId():"",
        },
        fetchPolicy: "no-cache"
    })

    // useEffect(() => {
    //     if (!postId) {
    //         return;
    //     }
        
    //     fetchData();
    // }, [postId])

    useEffect(() => {
        if (!data || !data.comments || error) {
            return
        }
        if (pageIndex === DEFAULT_PAGE_INDEX) {
            setComments(CommentMapper.buildFromArray(data.comments));
        } else {
            setComments(comments.concat(CommentMapper.buildFromArray(data.comments)));
        }

        if (data.commentsConnection) {
            setTotalPages(Math.floor(data.commentsConnection.aggregate.count/pageSize)+1)
        }
    }, [data])

    const fetchNewData = () => refetch ? refetch() : fetchData();

    const clearState = () => {
        setTotalPages(1);
        setComments([]);
    }
    
    return [comments, loading, totalPages, fetchNewData, clearState]
}



export default useCommentsQL
