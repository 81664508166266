/* eslint-disable react/no-multi-comp */
import { Button, colors, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { Trans } from "react-i18next";
import FrontendRoutes from "../../../../constants/FrontendRoutes";
import NeumorphicStyle from "../../../../constants/Neumorphic";
import useExhibition from "../../../../engines/GeneralHooks/useExhibition";
import { useExhibitorsUsers } from "../../../../engines/GeneralHooks/useExhibitors";
import Exhibition from "../../../../models/Exhibition";
import User from "../../../../models/User";
import useConfigurationService from "../../../../utils/ConfigurationService";
import MyStore from "../../../../utils/MyStore";
import TalkingPointList from "../components/TalkingPointList";
import VideoBox from "../components/VideoBox";
import useBreakpoints from "../../../../engines/Util/useBreakpoints";

const { auth, profiles } = FrontendRoutes;

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.background.default,
    borderRadius: NeumorphicStyle.borderRadius,
    boxShadow: NeumorphicStyle.boxShadowLarge(
      theme.palette.primary.main,
      theme.palette.secondary.dark
    ),
    width: (props) => props.breakpoint <= 3 ? 250 : 300,
    left: 16,
    position: "relative",
    top: 50,
    height: "calc(100% - 480px)",
    zIndex: 999,
    overflowY: "scroll",
  },
  rootHigh: {
    background: theme.palette.background.default,
    borderRadius: NeumorphicStyle.borderRadius,
    boxShadow: NeumorphicStyle.boxShadowLarge(
      theme.palette.primary.main,
      theme.palette.secondary.dark
    ),
    width: (props) => props.breakpoint <= 3 ? 250 : 300,
    left: 16,
    position: "relative",
    top: 50,
    height: "calc(100% - 410px)",
    zIndex: 999,
    overflowY: "scroll",
  },
  rootLanding: {
    width: 300,
    left: 16,
    background: theme.palette.background.default,
    borderRadius: NeumorphicStyle.borderRadius,
    boxShadow: NeumorphicStyle.boxShadowLarge(
      theme.palette.primary.main,
      theme.palette.secondary.dark
    ),
    position: "relative",
    top: 50,
    bottom: 32,
    zIndex: 999,
    overflowY: "scroll",
  },
  logoBox: {
    height: 90,
    background: theme.palette.background.default,
    borderRadius: NeumorphicStyle.borderRadius,
    boxShadow: NeumorphicStyle.boxShadowLarge(
      theme.palette.primary.main,
      theme.palette.secondary.dark
    ),
    width: "inherit",
    left: 16,
    position: "relative",
    top: 16,
    zIndex: 999,
  },
  logo: {
    marginLeft: "auto",
    width: (props) => props.breakpoint <= 3 ? 218 : 268,
    height: 100,
    marginLeft: 16,
    marginRight: 16,
    marginTop: 0,
    objectFit: "scale-down",
  },
  customerLinkArea: {
    height: 50,
    width: (props) => props.breakpoint <= 3 ? 250 : 300,
    left: 16,
    top: 30,
    zIndex: 999,
    padding: 0,
    overflow: 'hidden',
    borderRadius: NeumorphicStyle.borderRadius,
    position: "relative",
    background: `linear-gradient(to right, ${theme.palette.secondary.main}, ${theme.palette.primary.light})`,
    borderRadius: NeumorphicStyle.borderRadius,
    boxShadow: NeumorphicStyle.boxShadowLarge(
      theme.palette.primary.main,
      theme.palette.secondary.dark
    ),
  },
  customerLinkImage: {
    objectFit: "contain",
    width: "100%",
    height: "100%",
  },
  videoBox: {
    height: 169,
    width: (props) => props.breakpoint <= 3 ? 250 : 300,
    left: 16,
    background: theme.palette.background.default,
    boxShadow: NeumorphicStyle.boxShadowLarge(
      theme.palette.primary.main,
      theme.palette.secondary.dark
    ),
    borderRadius: NeumorphicStyle.borderRadius,
    bottom: 32,
    position: "absolute",
    zIndex: 999,
    transition: "width 0.2s, height 0.2s, opacity 0.2s 0.2s",
  },
  bottomButton: {
    height: 50,
    width: (props) => props.breakpoint <= 3 ? 250 : 300,
    left: 16,
    bottom: 40,
    zIndex: 999,
    paddingLeft: 40,
    paddingRight: 40,
    paddingTop: 20,
    paddingBottom: 20,
    borderRadius: NeumorphicStyle.borderRadius,
    position: "absolute",
    background: theme.palette.secondary.light,
    borderRadius: NeumorphicStyle.borderRadius,
    boxShadow: NeumorphicStyle.boxShadowLarge(
      theme.palette.primary.main,
      theme.palette.secondary.dark
    ),
  },
  videoBoxCinema: {
    height: 507,
    background: theme.palette.background.default,
    boxShadow: NeumorphicStyle.boxShadowLarge(
      theme.palette.primary.main,
      theme.palette.secondary.dark
    ),
    borderRadius: NeumorphicStyle.borderRadius,
    width: 900,
    left: 16,
    bottom: 32,
    position: "absolute",
    zIndex: 999,
    transition: "width 0.2s, height 0.2s, opacity 0.2s 0.2s",
  },
  videoBoxFull: {
    left: 16,
    right: 382,
    top: 96,
    background: theme.palette.background.default,
    boxShadow: NeumorphicStyle.boxShadowLarge(
      theme.palette.primary.main,
      theme.palette.secondary.dark
    ),
    borderRadius: NeumorphicStyle.borderRadius,
    bottom: 32,
    position: "absolute",
    zIndex: 999,
    transition: "width 0.2s, height 0.2s, opacity 0.2s 0.2s",
  },
  mobileDrawer: {
    width: 256,
    background: `linear-gradient(${theme.palette.primary.main},  ${theme.palette.secondary.dark});`,
  },
  desktopDrawer: {},
  navigation: {
    overflow: "auto",
    padding: theme.spacing(4, 4, 4, 4),
    flexGrow: 1,
  },
  profile: {
    padding: theme.spacing(2),
    display: "flex",
    alignItems: "center",
  },
  badge: {
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
  },
  badgeDot: {
    height: 9,
    minWidth: 9,
  },
  onlineBadge: {
    backgroundColor: colors.green[600],
  },
  awayBadge: {
    backgroundColor: colors.orange[600],
  },
  busyBadge: {
    backgroundColor: colors.red[600],
  },
  offlineBadge: {
    backgroundColor: colors.grey[300],
  },
  avatar: {
    cursor: "pointer",
    width: 40,
    height: 40,
  },
  details: {
    marginLeft: theme.spacing(2),
  },
  moreButton: {
    marginLeft: "auto",
    color: theme.palette.primary.main,
  },
  usersGrid: {
    width: "100%",
  },
}));

function ScheduleBar({
  openMobile,
  onMobileClose,
  setKeynoteConversation,
  setParentVideoMode,
  className,
  ...rest
}) {
  const [breakpoint] = useBreakpoints();
  const classes = useStyles({breakpoint});
  const history = useHistory();
  const location = useLocation();
  const [config] = useConfigurationService();
  const [exhibition] = useExhibition();
  const [exhibitorsUsers] = useExhibitorsUsers(
    exhibition && exhibition.getMainExhibitor()
  );

  const users = useSelector((state) => state.usersReducer);
  const keynoteExhibitor = useSelector((state) => state.keynotesReducer?.keynote?.state?.exhibitor);

  const [user, setUser] = useState(new User());

  useEffect(() => {
    if (!users.user) {
      return;
    }
    setUser(users.user);
  }, [users.user]);

  useEffect(() => {
    if (users.error.isUnauthorized()) {
      MyStore.removeAllKeys();
      history.push(auth.login());
    }
  }, [users.isFetching]);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }

    // eslint-disable-next-line
  }, [location.pathname]);

  return !config ? (
    <div />
  ) : (
    <div>
      <div className={classes.logoBox}>
        <img
          className={classes.logo}
          src={
            exhibition && exhibition.getProfilePicture()
              ? exhibition.getProfilePicture().getUrl("medium")
              : ""
          }
        />
      </div>
      {exhibition &&
        exhibition.getOrganizerLink() &&
        exhibition.getOrganizerLinkImage() && (
          <Button
            className={classes.customerLinkArea}
            href={exhibition.getOrganizerLink()}
          >
            <img
              className={classes.customerLinkImage}
              src={
                exhibition && exhibition.getOrganizerLinkImage()
                  ? exhibition.getOrganizerLinkImage().getUrl()
                  : ""
              }
            />
          </Button>
        )}
      <div
        className={keynoteExhibitor ? classes.root:classes.rootHigh}
      >
        <TalkingPointList talkingPointList={exhibition.getTalkingPoints()} isButtonExists={!!keynoteExhibitor} />

      </div>
      {/* 
        {exhibition && exhibition.getExhibitionType() === Exhibition.EXHIBITION_TYPES.LANDINGPAGE ? (
          <ExhibitorsUsersSB exhibitorsUsers={exhibitorsUsers} scheduleBar={true} exhibitor={exhibition.getMainExhibitor()}/>
        ): exhibition.getExhibitionType() === Exhibition.EXHIBITION_TYPES.VIDEO? (
          <div />//TO-DO: agenda
        ):(
          <ExhibitorsShortList exhibition={exhibition}/>
        )}
      </div> */}
      {exhibition &&
        exhibition.getExhibitionType() !==
          Exhibition.EXHIBITION_TYPES.VIDEO && (
          <VideoBox
            isBottomButtonExists={keynoteExhibitor}
            setParentVideoMode={setParentVideoMode}
            setKeynoteConversation={setKeynoteConversation}
          />
        )}
      {keynoteExhibitor && (
          <Button
            className={classes.bottomButton}
            onClick={() => history.push(`/?exhibitor=${keynoteExhibitor.getId()}`)}
          >
            <Typography variant="h4" style={{ fontSize: "0.9em", color: "white" }}>
              <Trans>sidebar.visit</Trans>&nbsp;{keynoteExhibitor.getName()} 
            </Typography>
          </Button>
      )}
      <div style={{ position: "fixed", bottom: 8, left: 16 }}>
        <Typography color={"textPrimary"}>
          powered by{" "}
          {
            <a href={"https://leadbase.de"} target="_blank">
              leadbase.de
            </a>
          }
        </Typography>
      </div>
    </div>
  );
}

ScheduleBar.propTypes = {
  className: PropTypes.string,
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default ScheduleBar;
