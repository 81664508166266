import PropTypes from '../../node_modules/prop-types'
import Conversation from "../models/Conversation";
import MyStore from "../utils/MyStore";

const CONVERSATIONS_CHANNEL = "private-co-";

export default class ConversationCable {

  static propTypes = {
    conversation: PropTypes.instanceOf(Conversation),
  };

  constructor(cable, conversation) {
    const userUid = MyStore.getCurrentUser() && MyStore.getCurrentUser().getId()

    this.state = {
      userUid: userUid,
      cable: cable,
      subscription: undefined,
      conversation: conversation
    };
  }

  disconnect = () => {
    this.state.cable.unsubscribe(
      CONVERSATIONS_CHANNEL + this.state.conversation.getId()
    )
  }

  connect = (callback) => {
    if(this.state.subscription) {
      this.disconnect()
    }


    let subscription = this.state.cable.subscribe(
      CONVERSATIONS_CHANNEL + this.state.conversation.getId()
    );

    subscription.bind("new-message", data => callback(data));

    this.state.subscription = subscription
  }
}
