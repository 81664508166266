import Document from "../models/Document";
import BaseMapper from "./BaseMapper";

const attributes = Document.attributes;

export default class DocumentMapper {
  static build = (json) => {
    if (!json) {
      return;
    }

    return new Document({
      ...BaseMapper.attributes(json),
      url: json[attributes.url],
      name: json[attributes.name],
      format: json[attributes.fileType],
      exhibitionId: json[attributes.exhibitionId]
    });
  };

  static buildFromArray = (jsonArray = []) => {
    return jsonArray.map(entry => {
      return this.build(entry);
    });
  };

  static fileToBase64 = async(file) => {
    return new Promise(resolve => {
      let fileReader = new FileReader();
      // Onload of file read the file content
      fileReader.onload = function(fileLoadedEvent) {
        let result = fileLoadedEvent.target.result;
        // Print data in console
        resolve(result)
      };

      // Convert data to base64
      fileReader.readAsDataURL(file);
    })
  }

  static async toJsonString(document) {
    return JSON.stringify({
      [attributes.name]: document.getFileName(),
      [attributes.attachedToId]: document.getAttachedToId(),
      [attributes.attachedToType]: document.getAttachedToType(),
      [attributes.content]: await this.fileToBase64(document.state.file),
    });
  }
}
