import { useLazyQuery } from "@apollo/client";
import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE } from "../../../constants/Urls";
import useExhibition from "../../../frontend/engines/GeneralHooks/useExhibition";
import GlobalContext from "../../../frontend/layouts/Dashboard/context/GlobalContext";
import ExhibitorMapper from "../../../mapper/ExhibitorMapper";
import { INTERNAL_IDS } from "../../../models/Role";
import MyStore from "../../../utils/MyStore";
import exhibitorQueries from "../queries/exhibitorQueries";

function useExhibitorsQL(
  searchText,
  pageIndex = DEFAULT_PAGE_INDEX,
  pageSize = DEFAULT_PAGE_SIZE,
  showBookmarks = false,
  talkingPointId = undefined
) {
  const [totalPages, setTotalPages] = useState(1);
  const [exhibitors, setExhibitors] = useState([]);
  const [exhibition] = useExhibition();
  const context = useContext(GlobalContext);
  const usersReducer = useSelector(state => state.usersReducer)

  const [fetchData, { loading, data, error, refetch }] = useLazyQuery(
    talkingPointId ? exhibitorQueries.feedQueryTalkingPoint : exhibitorQueries.feedQuery,
    {
      variables: {
        userId: MyStore.getCurrentUser()
          ? MyStore.getCurrentUser().getId()
          : undefined,
        tags:
          context && context.tags
            ? context.tags.map((tag) => tag.getName())
            : [],
        start: (pageIndex - 1) * pageSize,
        limit: pageSize,
        exhibitionId: exhibition ? exhibition.getId() : undefined,
        searchText: searchText,
        bmUserId:
          showBookmarks && MyStore.getCurrentUser()
            ? MyStore.getCurrentUser().getId()
            : undefined,
        talkingPointId,
        minPrice: context && context.minPrice,
        maxPrice: context && context.maxPrice,
        exhibitors: usersReducer.currentUser &&
          usersReducer.currentUser.getRole() &&
          usersReducer.currentUser.getRole().getName() === INTERNAL_IDS.EXHIBITOR &&
          (!MyStore.getVisitorView() || (exhibition && !exhibition.getExhibitionStarted()))
          ?
          usersReducer.exhibitors : undefined
      },
      fetchPolicy: "no-cache",
    }
  );

  useEffect(() => {
    if (context.loadingStage > 0) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context.loadingStage]);

  useEffect(() => {
    if (exhibitors && refetch) {
      refetch();
    }

  }, [showBookmarks, usersReducer.exhibitors]);

  useEffect(() => {
    if (context.updateShortlist) {
      fetchData();
      context.setUpdateShortlist(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context.updateShortlist]);

  useEffect(() => {
    if (
      data !== undefined &&
      data.exhibitors !== undefined &&
      data.exhibitors.length === 0
    ) {
      setExhibitors([]);
      setTotalPages(0);
    }
    if (!data || !data.exhibitors || error) {
      return;
    }

    if (pageIndex === DEFAULT_PAGE_INDEX) {
      const sortedExhibitors = [
        ...ExhibitorMapper.buildFromArray(data.exhibitors),
      ]
      setExhibitors(sortedExhibitors);
    } else {
      const sortedExhibitors = [
        ...ExhibitorMapper.buildFromArray(data.exhibitors),
      ]

      setExhibitors([...exhibitors, ...sortedExhibitors]);
    }

    if (data.exhibitorsConnection) {
      setTotalPages(
        Math.floor(data.exhibitorsConnection.aggregate.count / pageSize) + 1
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const refetchBookmarks = () => (refetch ? refetch() : fetchData());
  const fetchNewData = () => fetchData();

  return [exhibitors, loading, totalPages, refetchBookmarks, fetchNewData];
}

export function useExhibitorsQLReduced(
  searchText,
  pageIndex = DEFAULT_PAGE_INDEX,
  pageSize = DEFAULT_PAGE_SIZE,
  showBookmarks = false,
  shuffled = false,
  slice = true
) {
  const [totalPages, setTotalPages] = useState(1);
  const [exhibitors, setExhibitors] = useState([]);
  const [exhibition] = useExhibition();
  const context = useContext(GlobalContext);

  const [fetchData, { loading, data, error, refetch }] = useLazyQuery(
    exhibitorQueries.feedQueryReduced,
    {
      variables: {
        userId: MyStore.getCurrentUser()
          ? MyStore.getCurrentUser().getId()
          : undefined,
        tags:
          context && context.tags
            ? context.tags.map((tag) => tag.getName())
            : [],
        start: (pageIndex - 1) * pageSize,
        limit: shuffled ? 1000 : pageSize,
        exhibitionId: exhibition ? exhibition.getId() : "",
        searchText: searchText,
        bmUserId:
          showBookmarks && MyStore.getCurrentUser()
            ? MyStore.getCurrentUser().getId()
            : undefined,
      },
      fetchPolicy: "no-cache",
    }
  );

  useEffect(() => {
    if (context && context.loadingStage > 0) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context && context.loadingStage]);

  useEffect(() => {
    if (exhibitors && refetch) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showBookmarks]);

  useEffect(() => {
    if (context && context.updateShortlist) {
      fetchData();
      context.setUpdateShortlist(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context && context.updateShortlist]);

  useEffect(() => {
    if (!data || !data.exhibitors || error) {
      return;
    }
    if (pageIndex === DEFAULT_PAGE_INDEX) {
      if (shuffled) {
        let list = data.exhibitors.sort(() => Math.random() - 0.5);
        list = slice ? list.slice(0, pageSize) : list;
        setExhibitors(ExhibitorMapper.buildFromArray(list));
      } else {
        setExhibitors(ExhibitorMapper.buildFromArray(data.exhibitors));
      }
    } else {
      setExhibitors(
        exhibitors.concat(ExhibitorMapper.buildFromArray(data.exhibitors))
      );
    }

    if (data.exhibitorsConnection) {
      setTotalPages(
        Math.floor(data.exhibitorsConnection.aggregate.count / pageSize) + 1
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const refetchBookmarks = () => (refetch ? refetch() : fetchData());
  const fetchNewData = () => fetchData();

  return [exhibitors, loading, totalPages, refetchBookmarks, fetchNewData];
}

export function useExhibitorsQLPlain(
  searchText,
  pageIndex = DEFAULT_PAGE_INDEX,
  pageSize = DEFAULT_PAGE_SIZE,
  showBookmarks = false
) {
  const [totalPages, setTotalPages] = useState(1);
  const [exhibitors, setExhibitors] = useState([]);
  const [exhibition] = useExhibition();

  const [fetchData, { loading, data, error, refetch }] = useLazyQuery(
    exhibitorQueries.feedQuery,
    {
      variables: {
        userId: MyStore.getCurrentUser()
          ? MyStore.getCurrentUser().getId()
          : undefined,
        tags: [],
        start: (pageIndex - 1) * pageSize,
        limit: pageSize,
        exhibitionId: exhibition ? exhibition.getId() : "",
        searchText: searchText,
        bmUserId:
          showBookmarks && MyStore.getCurrentUser()
            ? MyStore.getCurrentUser().getId()
            : undefined,
      },
      fetchPolicy: "no-cache",
    }
  );

  useEffect(() => {
    if (exhibitors && refetch) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showBookmarks]);

  useEffect(() => {
    if (!data || !data.exhibitors || error) {
      return;
    }
    if (pageIndex === DEFAULT_PAGE_INDEX) {
      setExhibitors(ExhibitorMapper.buildFromArray(data.exhibitors));
    } else {
      setExhibitors(
        exhibitors.concat(ExhibitorMapper.buildFromArray(data.exhibitors))
      );
    }

    if (data.exhibitorsConnection) {
      setTotalPages(
        Math.floor(data.exhibitorsConnection.aggregate.count / pageSize) + 1
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const refetchBookmarks = () => (refetch ? refetch() : fetchData());
  const fetchNewData = () => fetchData();

  return [exhibitors, loading, totalPages, refetchBookmarks, fetchNewData];
}

export function useSponsorsQL(
  searchText,
  pageIndex = DEFAULT_PAGE_INDEX,
  pageSize = DEFAULT_PAGE_SIZE,
  showBookmarks = false
) {
  const [totalPages, setTotalPages] = useState(1);
  const [exhibitors, setExhibitors] = useState([]);
  const [exhibition] = useExhibition();
  const context = useContext(GlobalContext);
  const usersReducer = useSelector(state => state.usersReducer);
  
  const [fetchData, { loading, data, error, refetch }] = useLazyQuery(
    exhibitorQueries.sponsorQuery,
    {
      variables: {
        userId: MyStore.getCurrentUser()
          ? MyStore.getCurrentUser().getId()
          : undefined,
        start: (pageIndex - 1) * pageSize,
        limit: pageSize,
        exhibitionId: exhibition ? exhibition.getId() : "",
        searchText: searchText,
        bmUserId:
          showBookmarks && MyStore.getCurrentUser()
            ? MyStore.getCurrentUser().getId()
            : undefined,

        exhibitors: usersReducer.currentUser &&
          usersReducer.currentUser.getRole() &&
          usersReducer.currentUser.getRole().getName() === INTERNAL_IDS.EXHIBITOR &&
          (!MyStore.getVisitorView() || (exhibition && !exhibition.getExhibitionStarted()))
          ?
          usersReducer.exhibitors : undefined
      },
    }
  );

  useEffect(() => {
    if (context.loadingStage > 0) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context.loadingStage]);

  useEffect(() => {
    if (exhibitors && refetch) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showBookmarks]);

  useEffect(() => {
    if (!data || !data.exhibitors || error) {
      return;
    }
    if (pageIndex === DEFAULT_PAGE_INDEX) {
      setExhibitors(ExhibitorMapper.buildFromArray(data.exhibitors));
    } else {
      setExhibitors(
        exhibitors.concat(ExhibitorMapper.buildFromArray(data.exhibitors))
      );
    }

    if (data.exhibitorsConnection) {
      setTotalPages(
        Math.floor(data.exhibitorsConnection.aggregate.count / pageSize) + 1
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const refetchBookmarks = () => refetch();
  const fetchNewData = () => fetchData();

  return [exhibitors, loading, totalPages, refetchBookmarks, fetchNewData];
}

export function useExhibitorQL(exhibitorId) {
  const [exhibitor, setExhibitor] = useState();

  const [fetchData, { loading, data }] = useLazyQuery(
    exhibitorQueries.entityQuery,
    {
      variables: {
        userId: MyStore.getCurrentUser()
          ? MyStore.getCurrentUser().getId()
          : undefined,
        exhibitorId: exhibitorId,
      },
    }
  );

  useEffect(() => {
    if (!exhibitorId) {
      setExhibitor(undefined);
      return;
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exhibitorId]);

  useEffect(() => {
    if (!data || !data.exhibitor || data.error) {
      return;
    }
    setExhibitor(ExhibitorMapper.build(data.exhibitor));
  }, [data]);

  const fetchNewData = () => fetchData();

  return [exhibitor, loading, fetchNewData];
}

export default useExhibitorsQL;
