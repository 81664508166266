import { Chip, makeStyles, Input, FormControl, InputLabel,Select,MenuItem } from '@material-ui/core'
import React, { useContext, useEffect, useState } from 'react'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useTranslation } from 'react-i18next';
import MyStore from '../../../../../../utils/MyStore';

const useStyles = makeStyles(theme => ({
    root: {
    },
    container: {
        width: "inherit"
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    accordion: {
        border: "none",
        boxShadow: "none",
        width: "fit-content",
    },
    accordionSummery: {
        display: "inline-flex"
    },
    chipContainer: {
        margin: 2
    },
    field: {
        paddingRight: 15,
        paddingBottom: 15,
        "& .MuiFormLabel-root": {
            color: "grey",
            textAlign: "center",
        },
        "& .MuiInputLabel-outlined": {
            transform: "translate(14px, 12px) scale(1)",
        },
        "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
            transform: "translate(14px, -6px) scale(0.75)",
        },
    },
}))

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function EditTagField({ exhibition, entityTags, setEntityTags, disabled, maxTags = 3 }) {
    const classes = useStyles();

    const handleChange = (e) => {
        setEntityTags(e.target.value);
    }

    return !exhibition ? <div /> : (
        <div className={classes.container} onClick={(e) => {
            if (disabled) {
                e.stopPropagation()
            }
        }}>
            <FormControl>
                <InputLabel id="demo-mutiple-chip-label">Tags</InputLabel>
                <Select
                    className={classes.field}
                    labelId="demo-mutiple-chip-label"
                    id="demo-mutiple-chip"
                    multiple
                    fullWidth
                    variant="outlined"
                    value={entityTags}
                    onChange={handleChange}
                    renderValue={(selected) => (
                        <div>
                            {selected.map((tag) => (
                                <Chip key={tag.getName()} label={tag.getName(MyStore.getLocal() || 'en')} className={classes.chipContainer} />
                            ))}
                        </div>
                    )}
                    MenuProps={MenuProps}
                >
                    {exhibition.getTags().map((tag) => (
                        <MenuItem key={tag.getName()} value={tag}>
                            {tag.getName(MyStore.getLocal() || 'en')}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    )
}

export default EditTagField
