import { makeStyles } from '@material-ui/styles'
import React, { useContext } from 'react'
import { useKeynote } from '../../../../../engines/GeneralHooks/useKeynotes';
import MyStore from '../../../../../utils/MyStore';
import GlobalContext from '../../context/GlobalContext';
import KeynoteDetails from './KeynoteDetails'

const useStyles = makeStyles((theme) => ({
    root: {
        background: theme.palette.background.default,
        maxHeight: "100%",
        left: "inherit",
        right: "inherit",
        top: "inherit",
        bottom: "inherit",
        overflowY: "scroll",
        borderRadius: "inherit",
        margin: "0 auto",
    },
    inheritParent: {
        width: "100%",
        height: "100%",
        left: 0,
        top: 0,
        position: "absolute",
    },
}))

function KeynoteModal({ keynoteId }) {
    const classes = useStyles();
    const [keynote, loading] = useKeynote(keynoteId);
    const context = useContext(GlobalContext);

    return (
        <div className={classes.root}>
            {keynote && keynote.getStreamingUrl() && (
                <div
                    style={{
                        position: "relative",
                        backgroundColor: "lightGrey",
                        overflow: "hidden",
                        paddingBottom: "56.25%",
                        //maxWidth:750,
                        //maxHeight: 750 * 0.5525
                    }}
                >
                    <iframe
                        src={keynote.getStreamingUrl(MyStore.getLocal()) + (context.muted ? "?muted=1" : "")}
                        frameBorder="0"
                        allowFullScreen
                        allow="autoplay; fullscreen"
                        className={classes.inheritParent}
                    />
                </div>
            )}
            <KeynoteDetails keynote={keynote} />
        </div>
    )
}

export default KeynoteModal
