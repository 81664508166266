import SystemRole from "../models/SystemRole";
import BaseMapper from "./BaseMapper";
import Stage from "../models/Stage";
import ConversationMapper from "./ConversationMapper";
import KeynoteMapper from "./KeynoteMapper";
import LocalTextMapper from "./LocalTextMapper";

const attributes = Stage.attributes

export default class StageMapper {
  static build = json => {
    if (!json) {
      return
    }
    return new Stage({
      ...BaseMapper.attributes(json),
      name: json[attributes.name],
      conversationId: json[attributes.conversationId],
      currentKeynoteId: json[attributes.currentKeynoteId],
      streamUrl: json[attributes.streamUrl],
      localDescription: LocalTextMapper.buildFromArray(json[attributes.localDescription])
    });
  };

  static buildFromArray = (jsonArray = []) => {
    if (!jsonArray) {
      return
    }
    return jsonArray.map(entry => {
      return this.build(entry);
    });
  };

  static toJsonString = (stage) => {
    return JSON.stringify({
      name: stage.getName()
    });
  };
}
