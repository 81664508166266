import Urls, { DEFAULT_OPTIONAL_PARAMETERS } from "../../constants/Urls";
import ApplicationError from "../../utils/ApplicationError";
import ErrorLogger from "../../utils/ErrorLogger";
import { BEARER } from "../../utils/MyStore";
import ResponseService from "../../utils/ResponseService";
import TalkingPointMapper from "./../../mapper/TalkingPointMapper";
import {
  FETCHING_TALKING_POINTS_EXHIBITORS_FAILURE,
  FETCHING_TALKING_POINTS_EXHIBITORS_REQUEST,
  FETCHING_TALKING_POINTS_EXHIBITORS_SUCCESS,
} from "./types";

export const fetchingTalkingPointsExhibitorsRequest = () => ({
  type: FETCHING_TALKING_POINTS_EXHIBITORS_REQUEST,
});

export const fetchingTalkingPointsExhibitorsSuccess = (json) => ({
  type: FETCHING_TALKING_POINTS_EXHIBITORS_SUCCESS,
  payload: json,
});

export const fetchingTalkingPointsExhibitorsFailure = (error) => ({
  type: FETCHING_TALKING_POINTS_EXHIBITORS_FAILURE,
  payload: error,
});

export const fetchTalkingPointsExhibitors = (
  talkingPointId,
  _opts = DEFAULT_OPTIONAL_PARAMETERS
) => {
  return async (dispatch) => {
    dispatch(fetchingTalkingPointsExhibitorsRequest());

    try {
      const route = Urls.talkingPoints.exhibitors;
      const bearer = localStorage.getItem(BEARER);
      const headers = Urls.header.secure(bearer);
      const url = Urls.build(route.url(talkingPointId), _opts.filters);

      let response = await fetch(url, {
        method: route.method,
        headers: headers,
      });

      let responseService = new ResponseService(TalkingPointMapper);

      let action = await responseService.call(
        response,
        (value) => fetchingTalkingPointsExhibitorsSuccess(value),
        (error) => fetchingTalkingPointsExhibitorsFailure(error)
      );

      dispatch(action);
    } catch (error) {
      ErrorLogger.call(this, error);
      dispatch(
        fetchingTalkingPointsExhibitorsFailure(
          ApplicationError.buildApplicationError(error)
        )
      );
    }
  };
};
