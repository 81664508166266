import ApplicationModel from "./ApplicationModel";

export default class Document extends ApplicationModel {

  constructor(_opts = {}){
    super(_opts)
    this.state = { ..._opts }
  }

  getName         = () => this.state.name
  getFileName     = () => this.state.file.name
  getFileSize     = () => this.state.file.size
  getFileType     = () => this.state.file.type
  getFileContent  = async () => await this.state.file.text()
  getFileUrl      = () => this.state.url
  getExhibitionId = () => this.state.exhibitionId
  getAttachedToId   = () => this.state.attachedToId
  getAttachedToType = () => this.state.attachedToType
  

  static attributes = {
    ...super.attributes,
    url: 'url',
    name: 'name',
    content: 'content',
    fileType: 'file_content_type',
    exhibitionId: 'exhibition_id',
    attachedToId: 'attached_to_id',
    attachedToType: 'attached_to_type'
  }
}