export default class PushNotification {
  constructor(_opts = {}) {
    this.state = { ..._opts };
  }

  getPayload = () => this.state.payload
  getNotificationType = () => this.state.type
  getOtherUser = () => this.state.otherUser

  static attributes = {
    type: "type",
    payload: "payload",
  };

  static NOTIFICATION_TYPES = {
    meetingRequest: "meeting_request",
    meetingAccepted: "meeting_accepted",
    meetingDeclined: "meeting_declined",
    meetingStarted: "meeting_started",
    keynoteStarts: "keynote_starts",
    share: "share",
    newPosts: "new_posts",
    keynoteUpdate: "keynote_update",
    stageUpdate: "stage_update",
    exhibitionStarted: "exhibition_started",
    chatMessage: "chat_message",
    custom:"custom",
    commentUpdate: 'comment_update', // comment where you have posted
    commentUpdatePost: 'comment_update_post', // Comment on your own post
    postLikeCreate: 'post_like_create',
    postLikeDelete: 'post_like_delete',
    commentLikeCreate: "comment_like_create",
    commentLikeDelete: "comment_like_delete"
  };
}
