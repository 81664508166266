import React, { useContext } from 'react'
import { Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles';
import PDFIcon from "@material-ui/icons/PictureAsPdfOutlined";
import DownloadIcon from "@material-ui/icons/GetApp";
import useTracking, { EVENT_TRACKING_TYPES } from '../../engines/tracking/useTracking';
import context from 'react-bootstrap/esm/AccordionContext';
import GlobalContext from '../../layouts/Dashboard/context/GlobalContext';

const useStyles = makeStyles(theme => ({
    root: {
    },
    attachmentDropdownCard: {
        height: 40,
        cursor: "pointer"
    },
    pdfIcon: {
        width: "15%"
    },
    downloadIcon: {
        marginLeft: "auto", width: "15%"
    },
    name: {
        width: "70%", fontWeight: "normal"
    }
}));

function AttachmentCard({document}) {
    // TODO: differ types of attachments
    const classes = useStyles();
    const [trackEvent] = useTracking();
    const context = useContext(GlobalContext);

    const handleDownload = () => {
        window.open(document.getFileUrl());
        trackEvent(EVENT_TRACKING_TYPES.exhibitor.documentDownload, {
            exhibitor: context.currentExhibitor.getId(),
            file_name: document.getName(),
            factsheet: false,
        })
    }

    return document && (
        <Grid onClick={() => handleDownload()} container direction="row" className={classes.attachmentDropdownCard}>
            <PDFIcon className={classes.pdfIcon} />
            <Typography variant="h4" noWrap className={classes.name}>
                {document.getName()}
            </Typography>
            <DownloadIcon className={classes.downloadIcon}/>
        </Grid>
    )
}


export default AttachmentCard
