import { Modal, Grid, Typography, Button, Select, Checkbox, makeStyles, MenuItem, CircularProgress, Box } from '@material-ui/core'
import React, { useState, useEffect } from 'react'
import useExhibition from '../../../engines/GeneralHooks/useExhibition';
import Page from '../../components/Page';
import marked from "marked"
import MyStore, { BEARER } from '../../../utils/MyStore';
import NeumorphicStyle from '../../../constants/Neumorphic';
import gradients from '../../utils/gradients';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import useConfigurationService from '../../../utils/ConfigurationService';
import Flag from 'react-world-flags';
import { changeLocaleUser, clearUser, logOutUser, updateEventUser, updateUserRaw } from '../../../redux/actions/UsersActions';
import { deleteSession } from '../../../redux/actions/SessionActions';
import FrontendRoutes from '../../../constants/FrontendRoutes';
import { Link as RouterLink, useHistory } from "react-router-dom";
import InputIcon from "@material-ui/icons/Input";
import EditTagField from '../Dashboard/Leadbase/CustomComponents/EditTagArea/EditTagField';
import EditTagArea from '../Dashboard/Leadbase/CustomComponents/EditTagArea';
import Urls from '../../../constants/Urls';
import TagMapper from '../../../mapper/TagMapper';
import EventUserMapper from '../../../mapper/EventUserMapper';
import { isLocaleEnabled } from "../../../utils";

const useStyles = makeStyles((theme) => ({
    root: {
        height: "100vh",
        display: "flex",
        [theme.breakpoints.down("xs")]: {
            alignItems: "flex-start",
        },
        alignItems: "center",
        justifyContent: "center",
        padding: theme.spacing(2, 2),
        overflow: "auto",
    },
    card: {
        maxWidth: 820,
        width: "90%",
        [theme.breakpoints.down("xs")]: {
            paddingTop: '8vh',
            paddingBottom: '8vh',
            paddingLeft: '8vw',
            paddingRight: '8vw',
        },
        paddingTop: '5vh',
        paddingBottom: '5vh',
        paddingLeft: '4vw',
        paddingRight: '4vw',
        overflowY: "auto",
        overflow: "auto",
        display: "flex",
        position: "relative",
        boxShadow: NeumorphicStyle.boxShadowMedium(
            theme.palette.primary.main,
            theme.palette.secondary.dark
        ),
        borderRadius: NeumorphicStyle.borderRadius,
    },
    title: {
        textTransform: "uppercase",
    },
    subtitle: {
        textTransform: "uppercase",
        marginBottom: 30,
    },
    content: {
        marginTop: 20,
        width: "calc(100% + 14px)",
        marginBottom: 15,
        whiteSpace: "pre-wrap"
    },
    media: {
        borderTopRightRadius: 4,
        borderBottomRightRadius: 4,
        padding: theme.spacing(3),
        color: theme.palette.common.white,
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
        [theme.breakpoints.down("md")]: {
            display: "none",
        },
    },
    icon: {
        backgroundImage: gradients.orange,
        color: theme.palette.common.white,
        borderRadius: theme.shape.borderRadius,
        padding: theme.spacing(1),
        position: "absolute",
        top: -32,
        left: theme.spacing(3),
        height: 64,
        width: 64,
        fontSize: 32,
    },
    registerForm: {
        marginTop: theme.spacing(3),
    },
    divider: {
        margin: theme.spacing(2, 0),
    },
    person: {
        marginTop: theme.spacing(2),
        display: "flex",
    },
    avatar: {
        marginRight: theme.spacing(2),
    },
    stepper: {
        maxWidth: 400,
        "& .MuiMobileStepper-dotActive": {
            backgroundColor: theme.palette.secondary.main,
        },
    },
    navButton: {
        borderWidth: 2,
        borderColor: theme.palette.secondary.main,
        color: theme.palette.text.primary,
        borderStyle: "solid",
    },
    actionRow: {
        width: "calc(100% - 1px)",
        marginBottom: 30,
    },
    countdown: {
        marginTop: 15,
        color: "black",
        fontSize: "2em",
    },
    languageButton: {
        marginLeft: "auto",
    },
    submitButton: {
        width: "100%",
        fontWeight: "bold",
        marginBottom: '8px',
        marginLeft: 'auto',
    },
    policyCheckbox: {
        marginLeft: "-14px",
    },
    loginActions: {
        marginTop: 15
    }
}));

function AuthTermsPage() {
    const classes = useStyles();
    const [terms, setTerms] = useState();
    const [exhibition] = useExhibition();
    const [language, setLanguage] = useState("en");
    const dispatch = useDispatch();
    const [t, i18n] = useTranslation();
    const [config] = useConfigurationService();
    const history = useHistory();
    const [error, setError] = useState();
    const [toc, setToc] = useState(false);
    const [newsletter, setNewsletter] = useState(false);
    const [share, setShare] = useState(false);
    const [tags, setTags] = useState([]);
    const [loading, setLoading] = useState(false);
    const [formState, setFormState] = useState({
        isValid: false,
        values: {},
        touched: {},
        errors: {},
    });

    useEffect(() => {
        if (!config) {
            return
        }

        window.intercomSettings = {
            ...window.intercomSettings,
            app_id: config.system.intercom_app_id,
            alignment: 'right',
            horizontal_padding: 20,
            vertical_padding: 20
        };

        window.Intercom("update");
    }, [config])

    useEffect(() => {
        i18n.changeLanguage(language);
    }, [language]);

    useEffect(() => {
        if (!exhibition) {
            return;
        }

        setTerms(exhibition.getTerms());
    }, [exhibition])

    useEffect(() => {
        if (!MyStore.getLocal()) {
            MyStore.setLocal("en");
        } else {
            if(!isLocaleEnabled(MyStore.getLocal(),config)){
            MyStore.setLocal("en");
            }
            setLanguage(MyStore.getLocal());
        }
    }, [MyStore.getLocal()]);

    useEffect(() => {
        if (MyStore.getCurrentEventUser() && MyStore.getCurrentEventUser().getTocAccepted()) {
            history.push(FrontendRoutes.root());
        }
    }, [MyStore.getCurrentEventUser()]);

    useEffect(() => {
        if (!toc) {
            return;
        }

        setError(false);
    }, [toc])

    const handleLogout = async () => {
        await dispatch(deleteSession());
        await dispatch(logOutUser());
        await dispatch(clearUser());
        MyStore.removeAllKeys();
        history.push(FrontendRoutes.auth.login());
    };

    const handleSubmit = () => {
        if (!toc) {
            setError(true);
            return;
        } else {
            setError(false);

            setLoading(true);
            const route = Urls.users.eventuser;
            const headers = Urls.header.secure(localStorage.getItem(BEARER));
            fetch(route.url(), {
                method: route.method,
                headers: headers,
            })
                .then(r => {
                    r.json().then(j => {
                        if (j.id) {
                            const eventuser = {
                                tags: TagMapper.toJsonArray(tags),
                                toc_accepted: toc,
                                newsletter: newsletter,
                                share_information: share,
                            };
                            updateEventUser(j.id, eventuser).then(
                                (result) => {
                                    MyStore.setCurrentEventUser(EventUserMapper.build(result));
                                    dispatch(updateUserRaw(MyStore.getCurrentUser(), { 
                                        active_step: 6,
                                        heard_from: formState.values.heard_from,
                                        language: formState.values.language,
                                        zip_code: formState.values.zip_code
                                    }));
                                }
                            );
                        }
                    }
                    );
                })
                .catch(e => {
                    setLoading(false);
                })
        }
    }

    const handleChange = (event) => {
        event.persist();

        setFormState((prevFormState) => ({
            ...prevFormState,
            values: {
                ...prevFormState.values,
                [event.target.name]:
                    event.target.type === "checkbox"
                        ? event.target.checked
                        : event.target.name === "email"
                            ? event.target.value.toLowerCase()
                            : event.target.value,
            },
            touched: {
                ...prevFormState.touched,
                [event.target.name]: true,
            },
        }));
    };

    const hasError = (field) =>
        !!(formState.touched[field] && formState.errors[field]);

    return (
        <div className={classes.root} title="Terms">
            <Grid container direction="column" className={classes.card}>
                <Grid container direction="row">
                    <Typography
                        gutterBottom
                        variant="h1"
                        color="secondary"
                        className={classes.title}
                    >
                        <Trans>frontend.pages.login.welcome</Trans>
                    </Typography>
                    <Select
                        className={classes.languageButton}
                        value={language}
                        onChange={(e) => {
                            setLanguage(e.target.value);
                            MyStore.setLocal(e.target.value);
                            if (
                                MyStore.getCurrentUser() &&
                                MyStore.getCurrentUser().getLocale() !== e.target.value
                            ) {
                                dispatch(changeLocaleUser(e.target.value));
                            } else {
                                MyStore.setLocal(e.target.value);
                            }
                        }}
                        disableUnderline
                        disabled={loading}
                    >
                        {config?.enable_german ? <MenuItem value={"de"}><Flag code="de" height={20} width={30} /></MenuItem> : null}
                        <MenuItem value={"en"}><Flag code="gb" height={20} width={30} /></MenuItem>
                        {config?.enable_dutch ? <MenuItem value={"nl"}><Flag code="nl" height={20} width={30} /></MenuItem> : null}
                        {config?.enable_italy ? <MenuItem value={"it"}><Flag code="it" height={20} width={30} /></MenuItem> : null}
                        {config?.enable_french ? <MenuItem value={"fr"}><Flag code="fr" height={20} width={30} /></MenuItem> : null}
                    </Select>
                    <Button
                        className={classes.logoutButton}
                        onClick={handleLogout}
                        startIcon={<InputIcon className={classes.logoutIcon} />}
                    >
                        <Typography variant="h4">
                            <Trans>frontend.logout</Trans>
                        </Typography>
                    </Button>
                </Grid>
                <Grid container direction="row">
                    <Grid item xs={12}>
                        <Typography
                            variant="h3"
                            style={{
                                marginBottom: 15,
                                whiteSpace: "pre-wrap",
                            }}
                            color={"textPrimary"}
                        >
                            <Trans>frontend.accept_terms</Trans>
                        </Typography>
                    </Grid>
                    <EditTagArea center={true} entityTags={tags} setEntityTags={setTags} exhibition={exhibition} maxTags={3} disabled={loading} />
                    <Box height='50px' />
                    {terms && (
                        <Grid item container xs={12} style={{ marginTop: "12px" }}>
                            {terms.getGeneralTerms() && (
                                <Grid item container xs={12}>
                                    <Grid item xs={2} md={1}>
                                        <Checkbox
                                            onChange={(e) => setToc(e.target.checked)}
                                            checked={toc}
                                            className={classes.policyCheckbox}
                                            disabled={loading}
                                        />
                                    </Grid>
                                    <Grid item xs={10} md={11}>
                                        <Typography variant="body1">
                                            <div dangerouslySetInnerHTML={{ __html: marked(terms.getGeneralTerms(MyStore.getLocal())) }} />
                                        </Typography>
                                    </Grid>
                                </Grid>
                            )}
                            {error && (
                                <Typography variant="h6" color="error">
                                    <Trans>frontend.pages.register.confirmedPolicy</Trans>
                                </Typography>
                            )}
                            {terms.getNewsletter() && (
                                <Grid item container xs={12} style={{ marginTop: "12px" }}>
                                    <Grid item xs={2} md={1}>
                                        <Checkbox
                                            onChange={(e) => setNewsletter(e.target.checked)}
                                            checked={newsletter}
                                            className={classes.policyCheckbox}
                                            disabled={loading}
                                        />
                                    </Grid>
                                    <Grid item xs={10} md={11}>
                                        <Typography variant="body1">
                                            <div dangerouslySetInnerHTML={{ __html: marked(terms.getNewsletter(MyStore.getLocal())) }} />
                                        </Typography>
                                    </Grid>
                                </Grid>
                            )}
                            {terms.getShareInformation() && (
                                <Grid item container xs={12} style={{ marginTop: "12px" }}>
                                    <Grid item xs={2} md={1}>
                                        <Checkbox
                                            onChange={(e) => setShare(e.target.checked)}
                                            checked={share}
                                            className={classes.policyCheckbox}
                                            disabled={loading}
                                        />
                                    </Grid>
                                    <Grid item xs={10} md={11}>
                                        <Typography variant="body1">
                                            <div dangerouslySetInnerHTML={{ __html: marked(terms.getShareInformation(MyStore.getLocal())) }} />
                                        </Typography>
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                    )}
                </Grid>
                <Grid container xs={12} item direction="row" className={classes.loginActions}>
                    <Button
                        className={classes.submitButton}
                        color="secondary"
                        size="large"
                        type="submit"
                        variant="contained"
                        onClick={(e) => handleSubmit()}
                        disabled={loading}
                    >
                        {loading ? (
                            <CircularProgress />
                        ) : (
                            <Trans>frontend.pages.login.login</Trans>
                        )}
                    </Button>
                </Grid>
            </Grid>
        </div>
    )
}

export default AuthTermsPage
