import {
  Button,
  CircularProgress,
  colors,
  IconButton,
  Tooltip,
  Typography,
} from "@material-ui/core";
import CommentIcon from "@material-ui/icons/Comment";
import FavoriteIcon from "@material-ui/icons/Favorite";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import StarIcon from "@material-ui/icons/Star";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Trans } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import useExhibition from "../../../engines/GeneralHooks/useExhibition";
import {
  createPostsLike,
  deletePostsLike,
} from "../../../redux/actions/PostsLikesActions";
import useTracking, {
  EVENT_TRACKING_TYPES,
} from "../../engines/tracking/useTracking";
import { theme } from "../../theme";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
  },
  likeButton: {},
  likedButton: {
    color: colors.red[600],
  },
  shareButton: {
    marginLeft: "auto",
  },
  shareIcon: {
    marginRight: theme.spacing(1),
  },
  allComments: {
    marginLeft: theme.spacing(1),
  },
  favIcon: {
    width: 25,
    height: 25,
  },
  favIconPushed: {
    width: 25,
    height: 25,
    color: theme.palette.secondary.light,
  },
}));

function Reactions({
  disabled,
  setFullComments,
  bookmarkLoading,
  post,
  setOpenShareModal,
  setBookmarked,
  setUnbookmarked,
  className,
  active_comments,
  active_likes,
  ...rest
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [incomingComment, setIncomingComment] = useState();

  const [liked, setLiked] = useState(post.getLikedByUser());
  const [likes, setLikes] = useState(post.getLikesCount());
  const [commentCount, setCommentCount] = useState(post.getCommentsCount());
  const [exhibition] = useExhibition();
  const [trackEvent] = useTracking();

  const actionCableReducer = useSelector((state) => state.actionCableReducer);

  // useEffect(() => {
  //   if (!actionCableReducer.cable || !post || !exhibition) { return }

  //   let publicCable = new PublicCable(actionCableReducer.cable, exhibition)
  //   publicCable.connect((data) => {
  //     if (data.type === PushNotification.NOTIFICATION_TYPES.commentUpdate && post.getId() === data.payload.post.id) {
  //       setIncomingComment(data.payload);
  //     }
  //   })

  //   return () => {
  //     publicCable.disconnect()
  //   }
  // }, [actionCableReducer.cable, post, exhibition])

  // useEffect(() => {
  //   if (!incomingComment) {
  //     return;
  //   }

  //   setCommentCount(commentCount + 1);
  // }, [incomingComment])

  useEffect(() => {
    if (!post) {
      return;
    }

    setLiked(post.getLikedByUser());
    setLikes(post.getLikesCount());
  }, [post]);

  useEffect(() => {}, [disabled]);

  const handleLike = () => {
    if (disabled) {
      return;
    }

    dispatch(createPostsLike(post));
    setLiked(true);
    trackEvent(EVENT_TRACKING_TYPES.post.postLiked, {
      postId: post.getId(),
      exhibitor: post.getExhibitor() ? post.getExhibitor().getId() : "",
    });
    setLikes((prevLikes) => prevLikes + 1);
  };

  const handleUnlike = () => {
    if (disabled) {
      return;
    }

    dispatch(deletePostsLike(post));
    setLiked(false);
    trackEvent(EVENT_TRACKING_TYPES.post.postUnliked, {
      postId: post.getId(),
      exhibitor: post.getExhibitor() ? post.getExhibitor().getId() : "",
    });
    setLikes((prevLikes) => prevLikes - 1);
  };

  const handleComment = () => {};

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      {active_likes && (
        <>
          {liked ? (
            <Tooltip title="Unlike">
              <IconButton
                className={classes.likedButton}
                onClick={handleUnlike}
                size="small"
              >
                <FavoriteIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title="Like">
              <IconButton
                className={classes.likeButton}
                onClick={handleLike}
                size="small"
                disabled={disabled}
              >
                <FavoriteBorderIcon />
              </IconButton>
            </Tooltip>
          )}
          <Typography color="textSecondary" variant="h6">
            {likes}
          </Typography>
        </>
      )}

      {active_comments && (
        <>
          <Tooltip title="Kommentare" style={{ marginLeft: theme.spacing(1) }}>
            <IconButton
              className={classes.likeButton}
              onClick={() => setFullComments(true)}
              size="small"
            >
              <CommentIcon />
            </IconButton>
          </Tooltip>
          <Typography color="textPrimary" variant="h6">
            {commentCount}
          </Typography>
          <Typography color="textPrimary" variant="h6">
            <Button
              variant="text"
              color="textPrimary"
              size="small"
              onClick={() => setFullComments(true)}
              className={classes.allComments}
            >
              <Trans>frontend.allComments</Trans>
            </Button>
          </Typography>
        </>
      )}

      {exhibition && (
        <div className={classes.shareButton}>
          <Button
            startIcon={
              bookmarkLoading ? (
                <CircularProgress
                  color="secondary"
                  size={20}
                  thickness={7}
                  className={classes.circularProgress}
                />
              ) : post && post.isBookmarked() ? (
                <StarIcon className={classes.favIconPushed} />
              ) : (
                <StarBorderIcon className={classes.favIcon} />
              )
            }
            variant="text"
            color="textPrimary"
            size="small"
            onClick={
              post.isBookmarked()
                ? () => setUnbookmarked(true)
                : () => setBookmarked(true)
            }
            className={classes.socialButton}
          >
            <Trans>frontend.bookmark</Trans>
          </Button>
        </div>
      )}
    </div>
  );
}

Reactions.propTypes = {
  className: PropTypes.string,
  post: PropTypes.object.isRequired,
};

export default Reactions;
