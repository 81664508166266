import React from 'react'
import { ListItem, ListItemAvatar, Avatar, ListItemText } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles';
import { Link as RouterLink } from 'react-router-dom';
import FrontendRoutes from "../../../constants/FrontendRoutes";
import ExposurePlus1Icon from '@material-ui/icons/ExposurePlus1';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
    root: {},
    listItem: {
      '&:hover': {
        backgroundColor: theme.palette.background.default
      }
    },
    avatarBlue: {
      backgroundColor: "blue"
    },
    avatarGreen: {
        backgroundColor: "green"
    },
    avatarOrange: {
        backgroundColor: "orange"
    },
    avatarIndigo: {
        backgroundColor: "indigo"
    },
    avatarRed: {
        backgroundColor: "red"
    },
}));

function CommentsLike({commentsLike, payload}) {
    const classes = useStyles()
    const { t } = useTranslation();
    const dispatch = useDispatch();

    let primaryText;

    return (
        <ListItem
            className={classes.listItem}
            component={RouterLink}
            key={commentsLike.getCreatedAt()}
            to={FrontendRoutes.visitors.show(payload.getOtherUser().getId())}
        >
        <ListItemAvatar>
        {   
            <Avatar className={classes.avatarBlue}>
                <ExposurePlus1Icon />
            </Avatar>
        }
        </ListItemAvatar>
        <ListItemText
            primary={`${payload.getOtherUser().getFullName()} liked your comment`}
            primaryTypographyProps={{ variant: 'body1' }}
            secondary={moment(commentsLike.getRawCreatedAt()).fromNow()}
        />
    </ListItem>
    )}

export default CommentsLike
