import React from 'react'
import { ListItem, ListItemAvatar, Avatar, ListItemText } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles';
import { Link as RouterLink } from 'react-router-dom';
import FrontendRoutes from "../../../constants/FrontendRoutes";
import ShareIcon from '@material-ui/icons/Share';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
    root: {},
    listItem: {
      '&:hover': {
        backgroundColor: theme.palette.background.default
      }
    },
    avatarBlue: {
      backgroundColor: "blue"
    },
    avatarGreen: {
        backgroundColor: "green"
    },
    avatarOrange: {
        backgroundColor: "orange"
    },
    avatarIndigo: {
        backgroundColor: "indigo"
    },
    avatarRed: {
        backgroundColor: "red"
    },
}));

function Share({share, payload}) {
    const classes = useStyles()
    const { t } = useTranslation();
    const dispatch = useDispatch();

    let primaryText;
    let routeTo;
    switch(share.getSharableType()) {
        case 'User': 
            primaryText = payload.getOtherUser().getFullName() + t("frontend.notifications.share.shared_visitor");
            routeTo = FrontendRoutes.visitors.show(share.getSharableId());
            break;
        case "Post":
            primaryText = payload.getOtherUser().getFullName() + t("frontend.notifications.share.shared_post");
            routeTo = FrontendRoutes.posts.show(share.getSharableId());
            break;
        case "Keynote": 
            primaryText = payload.getOtherUser().getFullName() + t("frontend.notifications.share.shared_keynote");
            routeTo = FrontendRoutes.workshops.show(share.getSharableId());
            break;
        case "Exhibitor": 
            primaryText = payload.getOtherUser().getFullName() + t("frontend.notifications.share.shared_exhibitor");
            routeTo = FrontendRoutes.profiles.show(share.getSharableId());
            break;
        default: primaryText = "Unknown"
    }

    return (
        <ListItem
            className={classes.listItem}
            component={RouterLink}
            key={share.getCreatedAt()}
            to={routeTo}
        >
        <ListItemAvatar>
        {   
            <Avatar className={classes.avatarBlue}>
                <ShareIcon />
            </Avatar>
        }
        </ListItemAvatar>
        <ListItemText
            primary={primaryText}
            primaryTypographyProps={{ variant: 'body1' }}
            secondary={moment(share.getRawCreatedAt()).fromNow()}
        />
    </ListItem>
    )}

export default Share
