import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import useExhibition from './useExhibition';
import { fetchStages, fetchStage } from '../../redux/actions/StagesActions';
import { DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE } from '../../constants/Urls';
import useSearchEngine, { SEARCH_DATATYPE } from '../Search/SearchEngine';

function useStages(searchText, pageIndex = DEFAULT_PAGE_INDEX, pageSize = DEFAULT_PAGE_SIZE, filters = []) {
    const dispatch = useDispatch();
    const [stages, setStages] = useState([]);
    const [exhibition] = useExhibition();
    const [loading, setLoading] = useState(false);
    const [totalPages, setTotalPages] = useState(1);

    const stagesReducer = useSelector(state => state.stagesReducer);

    const [resultSet,searching,finishedSearch, totalPagesSearch, totalItemsCount] = useSearchEngine(searchText,SEARCH_DATATYPE.stage);

    useEffect(() => {
        if (!exhibition) {
            return;
        }

        dispatch(fetchStages({
            pageIndex:pageIndex,
            pageSize:pageSize,
            filters:filters.concat() //[FilterParams.orderByAsc(User.orderByParams.lastName)]
        }));
        setLoading(true);
    }, [exhibition, pageIndex, pageSize, filters.length])

    useEffect(() => {
        if (stagesReducer.isFetching || !stagesReducer.stages || stagesReducer.error.isError()) {
            return;
        }
        setLoading(false);
        if (pageIndex === DEFAULT_PAGE_INDEX) {
            setStages(stagesReducer.stages);
            setTotalPages(stagesReducer.totalPages);
        } else {
            setStages(stages.concat(stagesReducer.stages));
        }
    }, [stagesReducer.isFetching])

    return [stages,loading,totalPages,resultSet,searching,finishedSearch]
}

export function useStage(id) {
    const dispatch = useDispatch();
    const [exhibition] = useExhibition()
    
    const [stage, setStage] = useState();
    const [loading, setLoading] = useState(false);
    const [fetchPending, setFetchPending] = useState(false);

    const stagesReducer = useSelector(state => state.stagesReducer);
    const usersReducer = useSelector(state => state.usersReducer);

    useEffect(() => {
        if (!usersReducer.currentUser || 
            !exhibition ||
            !id
            //|| !usersReducer.currentUser.hasReadPermissions()
        ) {
            return;
        }

        dispatch(fetchStage(id))
        setLoading(true);

    }, [exhibition, id, usersReducer.currentUser])

    useEffect(() => {
        if (stagesReducer.isFetching || !stagesReducer.stage) {
            return
        }

        setLoading(false)

        if (stagesReducer.error.isError()) {
            return;
        }
        
        setStage(stagesReducer.stage);
    }, [stagesReducer.isFetching])

    return [stage,loading];
}

export default useStages
