import { ADD_ACTION_CABLE, REMOVE_ACTION_CABLE, SET_CABLE_STATUS } from "../actions/types";

export const STATUS = {
  CONNECTED: 'connected',
  DISCONNECTED: 'disconnected'
}

const initialState = {
  cable: undefined,
  status: STATUS.DISCONNECTED
};

const actionCableReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_ACTION_CABLE:
      return { ...state, cable: action.payload };
    case REMOVE_ACTION_CABLE:
      return {
        ...state,
        cable: undefined,
      };
    case SET_CABLE_STATUS:
      return {
        ...state,
        status: action.payload
      }  
    default:
      return state;
  }
};

export default actionCableReducer;
