import Timeslot from "../models/Timeslot";

export default class TimeslotMapper {
  static build = (json = undefined) => {
    if (!json) {
      return;
    }

    return new Timeslot({
      date: json.date,
      freeSlots: json.time_value,
    });
  };

  static buildFromArray = (jsonArray = []) => {
    return jsonArray.map((entry) => {
      return this.build(entry);
    });
  };
}
