import {
  Box,
  Button,
  LinearProgress,
  makeStyles,
  Typography,
  useTheme,
} from "@material-ui/core";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { fetchDiscussionRoom } from "../../../../../redux/actions/DiscussionRoomsActions";
import useTracking, { EVENT_TRACKING_TYPES, VISIT_TRACKING_TYPES } from "../../../../engines/tracking/useTracking";
import NeumorphicStyle from "./../../../../../constants/Neumorphic";

const useStyles = makeStyles((theme) => ({
  buttonRoot: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    paddingTop: theme.spacing(2.5),
    paddingBottom: theme.spacing(2.5),
    paddingLeft: theme.spacing(9),
    paddingRight: theme.spacing(9),
  },
  videoRoot: {
    width: "85%",
    borderRadius: NeumorphicStyle.borderRadius,
  },
}));

const Outer = ({ headerText, boxShadow, children }) => (
  <Box py={5} px={2.5} overflow="auto" height="100%">
    <Box py={2}>
      <Typography variant="h2">{headerText}</Typography>
    </Box>
    <Box
      p={4}
      boxShadow={boxShadow}
      borderRadius={NeumorphicStyle.borderRadius}
      textAlign="center"
      width="100%"
    >
      {children}
    </Box>
  </Box>
);

function DiscussionRoomView({ language }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles();
  const [trackEvent,trackVisit] = useTracking();

  const discussionRoomsReducer = useSelector(
    (state) => state.discussionRoomsReducer
  );

  useEffect(() => {
    dispatch(fetchDiscussionRoom(language));
  }, [dispatch, language]);

  useEffect(() => {
    trackVisit(VISIT_TRACKING_TYPES.discussionRoom, {
      language: language,
    });
  }, [])

  const headerText = t("pages.dashboard.discussionRoomsTitle");
  const boxShadow = NeumorphicStyle.boxShadowLarge(
    theme.palette.primary.main,
    theme.palette.secondary.dark
  );

  if (discussionRoomsReducer.isFetching)
    return (
      <Outer headerText={headerText} boxShadow={boxShadow}>
        <LinearProgress />
      </Outer>
    );

  if (discussionRoomsReducer.error.isError()) {
    return (
      <Outer headerText={headerText} boxShadow={boxShadow}>
        <Typography color="error">
          {discussionRoomsReducer.error.getErrorMessage()}
        </Typography>
      </Outer>
    );
  }

  if (!discussionRoomsReducer.discussion_room) return <Outer />;

  return (
    <Outer headerText={headerText} boxShadow={boxShadow}>
      <video
        controls
        src={discussionRoomsReducer.discussion_room.getHeaderVideoUrl()}
        className={classes.videoRoot}
        onPlay={() => {
          trackEvent(EVENT_TRACKING_TYPES.discussionRoom.watchedVideo,{
            language: language,
          });
        }}
      />
      {
        discussionRoomsReducer.discussion_room.getHint() && 
        discussionRoomsReducer.discussion_room.getHint() !== '' &&
        (
          <Box marginTop={2}>
            <Typography variant="h4" style={{ whiteSpace: "pre-wrap", }}>
              {discussionRoomsReducer.discussion_room.getHint()}
            </Typography>
          </Box>
        )
      }

      <Box my={4}>
        <Button
          className={classes.buttonRoot}
          target="_blank"
          href={discussionRoomsReducer.discussion_room.getUrl()}
          onClick={() => {
            trackEvent(EVENT_TRACKING_TYPES.discussionRoom.openedRoom,{
              language: language,
            });
          }}
        >
          {t("pages.dashboard.discussionRoomsButton")}
        </Button>
      </Box>
    </Outer>
  );
}

export default DiscussionRoomView;
