import { makeStyles } from "@material-ui/styles";
import React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh"
  }
}));

interface LbScreenContainerProps {
  color: string;
}

const LbScreenContainer: React.FC<LbScreenContainerProps> = ({ color }) => {
  const classes = useStyles();

  return (
    <div
      className={classes.root}
      style={{
        backgroundColor: color
      }}
    ></div>
  );
};

export default LbScreenContainer;
