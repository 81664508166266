import { Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { useEffect } from 'react';
import useBreakpoints from '../../../../../engines/Util/useBreakpoints';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
  },
  navButton: {
    padding: 4,
  },
  selectedNavButton: {
    padding: 4,
    color: theme.palette.secondary.main,
  },
}));

function LbResponsiveNavItem({tabIndex, setTabIndex, selectedIndex, label, icon, threshold, setTalkingPointId}) {
  const classes = useStyles();
  const [breakpoint] = useBreakpoints();
  const [belowThreshold, setBelowThreshold] = useState(false);

  useEffect(() => {
    setBelowThreshold(breakpoint <= threshold);
  }, [breakpoint])

  return (
    <Button
      className={
        tabIndex === selectedIndex ? classes.selectedNavButton : classes.navButton
      }
      onClick={() => {
        setTabIndex(tabIndex);
        if (setTalkingPointId) {
          setTalkingPointId(undefined);
        }
      }}
      style={{
        marginRight: belowThreshold ? 8 : 20
      }}
    >
      <Typography variant="h4" style={{ color: "inherit" }}>
        { belowThreshold ? (<FontAwesomeIcon style={{color: "inherit"}} icon={icon} />) : label}
      </Typography>
    </Button>
  )
}

export default LbResponsiveNavItem
