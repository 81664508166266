export default class Timeslot {
  constructor(_opts = {}) {
    this.state = { freeSlots: [], ..._opts };
  }

  getDate = () => new Date(this.state.date);
  getFreeSlots = () => this.state.freeSlots.filter(slot => !slot.meeting).map(slot => new Date(slot.slot));

  static attributes = {
    date: 'date', 
    freeSlots: 'time_value'
  } 

  static filterParams = {
    userId: 'user_id'
  } 
}
