import { Grid, IconButton, Box, Theme } from '@material-ui/core'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { VideoLabelOutlined, Close, VolumeOff, VolumeUp, PowerSettingsNew, Movie } from "@material-ui/icons";
import { makeStyles } from '@material-ui/styles';
import useExhibition from '../../../../engines/GeneralHooks/useExhibition';
import Logo from "../../../../assets/images/leadevents-logo-white.svg";
import { useKeynote } from '../../../../engines/GeneralHooks/useKeynotes';
import { useConversation, useKeynoteConversation } from '../../../../engines/Chat/ChatEngine';
import PushNotificationCable from '../../../../actioncable/PushNotificationCable';
import { STATUS } from "../../../../redux/reducers/actionCableReducer";
import { useDispatch, useSelector } from 'react-redux';
import { fetchKeynote } from '../../../../redux/actions/KeynotesActions';
import PushNotification from '../../../../models/PushNotification';
import { useStage } from '../../../../engines/GeneralHooks/useStages';
import { fetchStage } from '../../../../redux/actions/StagesActions';
import { useRawPushNotification } from '../../../../engines/Notification/NotificationEngine';
import NeumorphicStyle from '../../../../constants/Neumorphic';
import MyStore from '../../../../utils/MyStore';
import GlobalContext from '../../Dashboard/context/GlobalContext';
import useTracking, { EVENT_TRACKING_TYPES, VISIT_TRACKING_TYPES } from '../../../engines/tracking/useTracking';
import useBreakpoints from '../../../../engines/Util/useBreakpoints';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        background: theme.palette.background.default,
        width: "100%",
        height: "100%",
        position: "absolute",
    },
    inheritParent: {
        width: "inherit",
        height: "inherit",
        borderRadius: "inherit",
        left: "inherit",
        right: "inherit",
        top: "inherit",
        bottom: "inherit",
    },
    iconButtonArea: {
        width: "auto",
        borderRadius: "inherit",
        background: "rgba(255,255,255,0.7)",
        color: "black",
        position: "absolute",
        right: 8,
        top: 8,
        padding: "4px"
    },
    iconButtonClose: {
        padding: "4px"
    },
    iconButtonView: {
        marginLeft: "auto",
        padding: "4px"
    }
}));

const defaultVideoScreen = () => (
    <div
        style={{
            position: "relative",
            backgroundColor: "lightGrey",
            width: "inherit",
            height: "inherit",
        }}
    >
        <img
            src={Logo}
            style={{
                width: "50%",
                height: "50%",
                marginLeft: "25%",
                marginTop: "12.5%",
            }}
        />
    </div>
);

function LbMobileVideoBox({ setKeynoteConversation, setParentVideoMode, isBottomButtonExists = false }) {
    const [breakpoint] = useBreakpoints();
    const classes = useStyles({ breakpoint });
    const [videoMode, setVideoMode] = useState(0);
    const [exhibition] = useExhibition();
    const [stages, setStages] = useState();
    const [currStageId, setCurrStageId] = useState();
    const [stage, stageLoading] = useStage(currStageId);
    const containerRef = useRef();
    const [keynoteId, setKeynoteId] = useState();
    const [conversationId, setConversationId] = useState();
    const [conversation, loading] = useKeynoteConversation(exhibition, conversationId)
    const [keynote] = useKeynote(keynoteId);
    const context = useContext(GlobalContext);
    const [trackEvent, trackVisit] = useTracking();

    useEffect(() => {
        if ((context as any).videoFullWidth) {
            setVideoMode(2);
            (context as any).setVideoFullWidth(false);
        }
    }, [(context as any).videoFullWidth])

    useEffect(() => {
        if (!keynote) {
            return;
        }

        trackEvent(EVENT_TRACKING_TYPES.stage.enteredAgendKeynote, {
            keynote: (keynote as any).getId(),
            title: (keynote as any).getTitle(),
            language: MyStore.getLocal() || ''
        })
    }, [keynote])

    useEffect(() => {

        const onbeforeunloadFn = () => {
            if (keynote) {
                trackEvent(EVENT_TRACKING_TYPES.stage.endedAgendKeynote, {
                    keynote: (keynote as any).getId(),
                    title: (keynote as any).getTitle(),
                    language: MyStore.getLocal() || '',
                    muted: (context as any).muted
                })
            }
        }

        window.addEventListener('beforeunload', onbeforeunloadFn);
        return () => {
            window.removeEventListener('beforeunload', onbeforeunloadFn);
        }
    }, [keynote])

    useEffect(() => {
        setParentVideoMode(videoMode);
    }, [videoMode])

    useEffect(() => {
        if (!exhibition) {
            return
        }
        setStages((exhibition as any).getStages());
        if ((exhibition as any).getStages() && (exhibition as any).getStages().length > 0) {
            const locStage = (exhibition as any).getStages()[0];
            setCurrStageId((locStage as any).getId());
        }
    }, [exhibition, MyStore.getLocal()])

    useEffect(() => {
        if (videoMode === 2 && keynote) {
            trackVisit(VISIT_TRACKING_TYPES.mainStage, {
                keynote: (keynote as any).getId(),
                title: (keynote as any).getTitle(),
                language: MyStore.getLocal() || '',
                muted: (context as any).muted,
                fullscreen: true,
            })
        }
    }, [videoMode])

    useEffect(() => {
        if (!conversation) {
            return;
        }

        setKeynoteConversation(conversation);
    }, [conversation])

    useEffect(() => {
        if (!stage) {
            return
        }

        setKeynoteId((stage as any).getCurrentKeynoteId(MyStore.getLocal()));
        const convId = (stage as any).getConversationId(MyStore.getLocal());
        setConversationId(convId);
    }, [stage, MyStore.getLocal()])

    const dispatch = useDispatch();
    const [data] = useRawPushNotification();

    useEffect(() => {
        if (!keynote ||
            !data ||
            (data as any).getNotificationType() !== PushNotification.NOTIFICATION_TYPES.keynoteUpdate ||
            (data as any).getPayload().getId() !== (keynote as any).getId()
        ) {

            return;
        }

        loadKeynote();
    }, [data]);

    useEffect(() => {
        if (!stage ||
            !data ||
            (data as any).getNotificationType() !== PushNotification.NOTIFICATION_TYPES.stageUpdate ||
            (data as any).getPayload().getId() !== (stage as any).getId()
        ) {

            return;
        }

        loadStage();
    }, [data]);

    const loadKeynote = () => {
        if (keynote) {
            trackEvent(EVENT_TRACKING_TYPES.stage.endedAgendKeynote, {
                keynote: (keynote as any).getId(),
                title: (keynote as any).getTitle(),
                language: MyStore.getLocal() || '',
                muted: (context as any).muted
            })
        }
        dispatch(
            fetchKeynote((exhibition as any).getId(), (data as any).getPayload().getId())
        );
        trackEvent(EVENT_TRACKING_TYPES.stage.enteredAgendKeynote, {
            keynote: (data as any).getPayload().getId(),
            title: (data as any).getPayload().getTitle(),
            language: MyStore.getLocal() || '',
            muted: (context as any).muted
        })
    };
    const loadStage = () => {
        dispatch(
            fetchStage((data as any).getPayload().getId())
        );
    };

    return !stage ? (<div />) : (
        <div
            className={classes.root}
        >
            {(keynote && !(keynote as any).isRunning()) ? (
                <div
                    style={{
                        backgroundColor: "lightGrey",
                        width: "inherit",
                        height: "inherit",
                        borderRadius: "inherit"
                    }}
                >
                    <img
                        src={
                            (keynote as any).getImage() ? (keynote as any).getImage().getUrl("large") : Logo
                        }
                        style={{
                            width: "inherit",
                            height: "inherit",
                            marginLeft: (keynote as any).getImage() ? "0%" : "25%",
                            marginTop: (keynote as any).getImage() ? "0%" : "12.5%",
                            objectFit: "cover",
                            borderRadius: "inherit"
                        }}
                    />
                </div>
            ) : (stage as any).getStreamUrl(MyStore.getLocal()) ? (
                <div
                    style={{
                        backgroundColor: "lightGrey",
                        //maxWidth:750,
                        //maxHeight: 750 * 0.5525
                    }}
                    className={classes.inheritParent}
                >
                    <iframe
                        src={(stage as any).getStreamUrl(MyStore.getLocal()) + ((context as any).muted ? "?muted=1" : "")}
                        frameBorder="0"
                        allowFullScreen
                        allow="autoplay; fullscreen"
                        className={classes.inheritParent}
                    />
                </div>
            ) : (
                defaultVideoScreen()
            )}
        </div>
    )
}

export default LbMobileVideoBox