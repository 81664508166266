import KeynoteMapper from "./KeynoteMapper";
import MeetingMapper from "./MeetingMapper";
import MessageMapper from "./MessageMapper";
import MeetingsUserMapper from "./MeetingsUserMapper";
import ShareMapper from "./ShareMapper";
import UserMapper from "./UserMapper";
import PushNotification from "../models/PushNotification";
import StageMapper from "./StageMapper";

export default class NotificationMapper {
  static build = (json = undefined) => {
    if (!json) {
      return;
    }

    return new PushNotification({
      type: json.type,
      className: json.class_name,
      otherUser: UserMapper.build(json.other_user),
      payload: this.buildPayload(json),
    });

  };

  static buildPayload(json) {
    switch (json.class_name) {
      case 'Keynote':
        return KeynoteMapper.build(json.payload)
      case 'Stage':
        return StageMapper.build(json.payload)
      case 'Meeting':
        return MeetingMapper.build(json.payload)
      case 'MeetingsUser':
        return MeetingsUserMapper.build(json.payload)
      case 'Message':
        return MessageMapper.build(json.payload)
      case 'Share':
        return ShareMapper.build(json.payload)
      case 'String':
        return json.payload;
    }
  }
}
