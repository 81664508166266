import React, { useState, useEffect, useContext } from "react";
import { Button, Card, Grid, Typography, Avatar, Chip, CircularProgress, Link, Box } from "@material-ui/core";
import MailIcon from "@material-ui/icons/Mail";
import PhoneIcon from "@material-ui/icons/Phone";
import { Trans, useTranslation } from "react-i18next";
import FrontendRoutes from "../../../../../constants/FrontendRoutes";

import CogoToast from "../../../../../utils/CogoToast";
import { useSelector, useDispatch } from "react-redux";
import {
  bookmarkVisitor
} from "../../../../../redux/actions/VisitorsActions";
import Bookmark from "../../../../../models/Bookmark";
import ShareModal from "../../../../components/ShareModal/ShareModal";
import StartChatModal from "../../../../components/StartChatModal/StartChatModal";
import { Star, StarBorder, ChatBubbleOutlineRounded, Share, VideoCall } from "@material-ui/icons";
import GlobalContext from "../../context/GlobalContext";
import { MODAL_TYPE } from "../GlobalModal";
import MyStore from "../../../../../utils/MyStore";
import VideoModal from "../../../../views/ChatView/ThreadDetails/VideoModal";
import { compareEntitiesById } from "../../../../../utils";
import useStyles from './useStyles'
import UserDetailModal from "../UserDetailModal";
import useBreakpoints from "../../../../../engines/Util/useBreakpoints";

/**
 * Anmerkung: Visitor entspricht dem Model User
 * @param {visitor} param0
 */

function VisitorCard({ visitor, handleChatStart, handleShare, refetchBookmarks, visitorList, currentVisitor, currentIndex, bookmarkOnly }) {
  const dispatch = useDispatch();
  const [breakpoint] = useBreakpoints();
  const classes = useStyles({ breakpoint });

  const [bookmarked, setBookmarked] = useState(false);
  const [unbookmarked, setUnbookmarked] = useState(false);
  const { t } = useTranslation();
  const [shareOpen, setShareOpen] = useState(false)
  const [openDetailUser, setOpenDetailUser] = useState(false);
  const context = useContext(GlobalContext);

  const conversations = useSelector(state => state.conversationsReducer);
  const visitorsReducer = useSelector(state => state.visitorsReducer);
  const [chatOpen, setChatOpen] = useState(false);
  const [bookmarkLoading, setBookmarkLoading] = useState(false);
  const [videoOpen, setVideoOpen] = useState(false);

  useEffect(() => {
    if (!unbookmarked) {
      return;
    }

    dispatch(bookmarkVisitor(visitor));
    setBookmarkLoading(true);
  }, [unbookmarked]);

  useEffect(() => {
    if (!bookmarked) {
      return;
    }

    dispatch(bookmarkVisitor(visitor));
    setBookmarkLoading(true);
  }, [bookmarked]);

  // Bookmark
  useEffect(() => {
    if (
      (!bookmarked && !unbookmarked) ||
      !bookmarkLoading ||
      visitorsReducer.isFetching ||
      visitorsReducer.error.isError()
    ) {
      return;
    }

    if (bookmarked) {
      visitor.setIsBookmarked(true);
      CogoToast.info(t("frontend.added_bookmark"));
    }

    if (unbookmarked) {
      visitor.setIsBookmarked(false);
      CogoToast.info(t("frontend.removed_bookmark"));
    }

    setBookmarkLoading(false);
    setBookmarked(false);
    setUnbookmarked(false);
  }, [visitorsReducer.isFetching]);

  useEffect(() => {
    if (conversations.isFetching || !conversations.conversation) {
      return
    }
  }, [conversations.isFetching])

  const handleBookmark = () => {
    visitor.isBookmarked() ? setUnbookmarked(true) : setBookmarked(true)
  }

  return (
    <>
      {visitor !== undefined && (
        <Grid item key={visitor.getExhibitorId()} className={classes.root}>
          <Box className={classes.content} height="100%" onClick={() => setOpenDetailUser(true)}>
            <Grid item container direction="row" className={classes.userContainer}>
              <div style={{
                position: "absolute",
                width: breakpoint <= 3 ? "6px" : "10px",
                height: breakpoint <= 3 ? "6px" : "10px",
                backgroundColor: visitor.isOnline() ? "#44B700" : "#853434",
                zIndex: "100",
                borderRadius: "50%",
                bottom: breakpoint <= 3 ? "3px" : "5px",
                left: breakpoint <= 3 ? "30px" : "45px"
              }}
              />
              <Avatar
                className={classes.image}
                src={visitor.getProfilePicture() ? visitor.getProfilePicture().getUrl("small") : ""}
                style={{ cursor: bookmarkOnly ? "default" : "pointer", }}
              />
              <Grid container item direction="column" className={classes.nameContainer}>
                {
                  !bookmarkOnly ? (
                    <Link
                      variant={breakpoint <= 3 ? "h6" : "h4"}
                      noWrap
                      color="textPrimary"
                      style={{ cursor: "pointer" }}
                    >
                      {visitor.getFullName()}
                    </Link>
                  ) : (
                    <Typography
                      variant={breakpoint <= 3 ? "h6" : "h4"}
                      noWrap
                      color="textPrimary"
                    >
                      {visitor.getFullName()}
                    </Typography>
                  )
                }
                <Typography variant={breakpoint <= 3 ? "normal" : "h5"} className={classes.profession} noWrap>{visitor.getProfession()}</Typography>
                <Typography variant={breakpoint <= 3 ? "normal" : "h5"} className={classes.companyName} noWrap>{visitor.getCompanyName()}</Typography>
              </Grid>
            </Grid>
            <Grid item container direction="row" className={classes.tagContainer} spacing={1}>
              {visitor.getTags().map(tag => (
                <Grid item>
                  <Chip className={classes.tag} color="secondary" variant="default" label={tag.getName(MyStore.getLocal())} />
                </Grid>
              ))}
            </Grid>
            <Grid item container className={classes.contactContainer} direction="row">
              {visitor.getCompanyEmail() && <Typography variant="h5" noWrap><MailIcon className={classes.icon} />{visitor.getCompanyEmail()}</Typography>}
              {visitor.getPhoneNumber() && <Typography variant="h5" noWrap><PhoneIcon className={classes.icon} />{visitor.getPhoneNumber()}</Typography>}
            </Grid>
            {breakpoint > 3 &&
              <Grid item container className={classes.aboutContainer} >
                {visitor.getDescription() && <Typography style={{ fontWeight: "bolder", width: "100%" }} variant="h5" noWrap>{t("frontend.about") + " " + visitor.getFirstName()}</Typography>}
                <Typography variant="h5" className={classes.aboutText}>{
                  !visitor.getDescription() ? "" :
                    visitor.getDescription().length > 65 ?
                      visitor.getDescription().substring(0, 65) + "..."
                      : visitor.getDescription()}
                </Typography>
              </Grid>
            }
            <Grid container direction="row" className={classes.actionArea}>
              <Grid item container xs={!bookmarkOnly ? 4 : 12} alignItems="center">
                <Button onClick={(e) => { e.stopPropagation(); handleBookmark() }} style={{ marginLeft: "auto", marginRight: "auto" }}>
                  <Typography variant={breakpoint <= 3 ? "h6" : "h4"}>
                    {bookmarkLoading ? <CircularProgress color="secondary" size={20} thickness={7} /> : visitor.isBookmarked() ? <Star className={classes.starIcon} /> : <StarBorder />}
                    <Typography>
                      {bookmarkOnly && (visitor.isBookmarked() ? t("frontend.already_added") : t("frontend.add_to_favorites"))}
                    </Typography>
                  </Typography>
                </Button>
              </Grid>
              {!bookmarkOnly &&
                (<Grid item container xs={4} alignItems="center">
                  <Button style={{ marginLeft: "auto", marginRight: "auto" }} onClick={(e) => { e.stopPropagation(); setChatOpen(true) }}>
                    <Typography variant="h5">
                      <ChatBubbleOutlineRounded />
                    </Typography>
                  </Button>
                </Grid>)
              }
              {!bookmarkOnly &&
                <Grid item xs={4} alignItems="center">
                  <Button disabled={!visitor.isOnline() || compareEntitiesById(visitor, MyStore.getCurrentUser())} onClick={(e) => { e.stopPropagation(); setVideoOpen(true) }}>
                    <Typography variant="h5">
                      <VideoCall style={{ color: visitor.isOnline() && !compareEntitiesById(visitor, MyStore.getCurrentUser()) ? "black" : "grey" }} />
                    </Typography>
                  </Button>
                </Grid>
              }
            </Grid>
          </Box>
          {openDetailUser && (
            <UserDetailModal
              userId={visitor.getId()}
              setClose={setOpenDetailUser}
              open={openDetailUser}
              visitorList={visitorList}
              currentVisitor={currentVisitor}
              currentIndex={currentIndex} />
          )}
          <StartChatModal chatUser={visitor} open={chatOpen} handleClose={() => setChatOpen(false)} />
          <ShareModal open={shareOpen} handleClose={() => setShareOpen(false)} payload={visitor} />
          <VideoModal
            open={videoOpen}
            handleClose={() => setVideoOpen(false)}
            chatUser={visitor}
          />
        </Grid>
      )}
    </>
  )
}

export default VisitorCard;
