import {
    FETCHING_EXHIBITION_ACTIVE_KEYNOTES_REQUEST,
    FETCHING_EXHIBITION_ACTIVE_KEYNOTES_SUCCESS,
    FETCHING_EXHIBITION_ACTIVE_KEYNOTES_FAILURE,
  } from '../actions/types';
  import ResponseService from '../../utils/ResponseService';
  import ApplicationError from '../../utils/ApplicationError';
  
  const initialState = {
    keynotes: [],
    error: ApplicationError.buildDefault(),
    isFetching: false,
    ...ResponseService.defaultPaginationMetaData()
  };
  
  const activeKeynotesReducer = (state = initialState, action ) => {
    switch(action.type) {
      case FETCHING_EXHIBITION_ACTIVE_KEYNOTES_REQUEST:
        return {...state, isFetching: true, keynotes: [] }
      case FETCHING_EXHIBITION_ACTIVE_KEYNOTES_SUCCESS:
        return {...state, isFetching: false, 
          keynotes: action.payload.objects, 
          error: ApplicationError.buildDefault(),
          ...action.payload.paginationData }
      case FETCHING_EXHIBITION_ACTIVE_KEYNOTES_FAILURE:
        return {...state, isFetching: false, error: action.payload }
  
      default: 
        return state
    }
  }
  
  export default activeKeynotesReducer;