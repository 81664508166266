import {
  FETCHING_EXHIBITION_CONVERSATIONS_REQUEST,
  FETCHING_EXHIBITION_CONVERSATIONS_SUCCESS,
  FETCHING_EXHIBITION_CONVERSATIONS_FAILURE,
  
  FETCHING_EXHIBITION_CONVERSATION_REQUEST,
  FETCHING_EXHIBITION_CONVERSATION_SUCCESS,
  FETCHING_EXHIBITION_CONVERSATION_FAILURE,

  CREATE_EXHIBITION_CONVERSATION_FAILURE,
  CREATE_EXHIBITION_CONVERSATION_SUCCESS,
  CREATE_EXHIBITION_CONVERSATION_REQUEST,

  UPDATE_EXHIBITION_CONVERSATION_FAILURE,
  UPDATE_EXHIBITION_CONVERSATION_SUCCESS,
  UPDATE_EXHIBITION_CONVERSATION_REQUEST,

  DELETE_EXHIBITION_CONVERSATION_FAILURE,
  DELETE_EXHIBITION_CONVERSATION_SUCCESS,
  DELETE_EXHIBITION_CONVERSATION_REQUEST,

  CLEAR_EXHIBITION_CONVERSATION,

  SET_UNREAD_MESSAGES_COUNT
} from '../actions/types';
import ResponseService from '../../utils/ResponseService';
import ApplicationError from '../../utils/ApplicationError';

const initialState = {
  conversations: [],
  conversation: undefined,
  error: ApplicationError.buildDefault(),
  isFetching: false,
  unreadMessagesCount: 0,
  token: null,
  ...ResponseService.defaultPaginationMetaData()
};

const conversationsReducer = (state = initialState, action ) => {
  switch(action.type) {
    case FETCHING_EXHIBITION_CONVERSATIONS_REQUEST:
      return {...state, isFetching: true }
    case FETCHING_EXHIBITION_CONVERSATIONS_SUCCESS:
      return {...state, isFetching: false, 
        conversations: action.payload.objects, 
        error: ApplicationError.buildDefault(),
        unreadMessagesCount: 0,//action.payload.additional.unread_messages_count,
        ...action.payload.paginationData }

    case FETCHING_EXHIBITION_CONVERSATIONS_FAILURE:
      return {...state, isFetching: false, error: action.payload }
    
    case FETCHING_EXHIBITION_CONVERSATION_REQUEST:
      return {...state, isFetching: true }
    case FETCHING_EXHIBITION_CONVERSATION_SUCCESS: 
      return {...state, isFetching: false, conversation: action.payload, error: ApplicationError.buildDefault() }
    case FETCHING_EXHIBITION_CONVERSATION_FAILURE:
      return {...state, isFetching: false, error: action.payload }  

    case  CREATE_EXHIBITION_CONVERSATION_REQUEST:
      return {...state, isFetching: true }
    case  CREATE_EXHIBITION_CONVERSATION_SUCCESS:
      return {...state, isFetching: false, conversation: action.payload, error: ApplicationError.buildDefault() }
    case  CREATE_EXHIBITION_CONVERSATION_FAILURE:
      return {...state, isFetching: false, error: action.payload }

    case  UPDATE_EXHIBITION_CONVERSATION_REQUEST:
      return {...state, isFetching: true }
    case  UPDATE_EXHIBITION_CONVERSATION_SUCCESS:
      return {...state, isFetching: false, conversation: action.payload, error: ApplicationError.buildDefault() }
    case  UPDATE_EXHIBITION_CONVERSATION_FAILURE:
      return {...state, isFetching: false, error: action.payload }

    case  DELETE_EXHIBITION_CONVERSATION_REQUEST:
      return {...state, isFetching: true }
    case  DELETE_EXHIBITION_CONVERSATION_SUCCESS:
      return {...state, isFetching: false, error: ApplicationError.buildDefault() }
    case  DELETE_EXHIBITION_CONVERSATION_FAILURE:
      return {...state, isFetching: false, error: action.payload }

    case CLEAR_EXHIBITION_CONVERSATION:
      return { ...initialState }  

    case SET_UNREAD_MESSAGES_COUNT:
      return { ...state, unreadMessagesCount: action.payload > 0 ? action.payload : 0 }    

    case 'TOKEN_CONVERSATTION':
      return {...state, token: action.payload }

    default: 
      return state
  }
}

export default conversationsReducer;