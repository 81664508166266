import { useEffect, useState } from "react";

export async function getDeviceInfo() {
  const deviceList = await navigator.mediaDevices.enumerateDevices();

  const audioInputDevices = [];
  const videoInputDevices = [];
  const audioOutputDevices = [];
  for (const device of deviceList) {
    if (device.kind === "audioinput") {
      audioInputDevices.push(device);
    } else if (device.kind === "videoinput") {
      videoInputDevices.push(device);
    } else if (device.kind === "audiooutput") {
      audioOutputDevices.push(device);
    }
  }

  return {
    audioInputDevices: audioInputDevices,
    videoInputDevices: videoInputDevices,
    audioOutputDevices: audioOutputDevices,
    hasAudioInputDevices: !!audioInputDevices.length,
    hasVideoInputDevices: !!videoInputDevices.length,
  };
}

export default function useDevices() {
  const [deviceInfo, setDeviceInfo] = useState({
    audioInputDevices: [],
    videoInputDevices: [],
    audioOutputDevices: [],
    hasAudioInputDevices: false,
    hasVideoInputDevices: false,
  });

  useEffect(() => {
    const getDevices = () =>
      getDeviceInfo().then((devices) => setDeviceInfo(devices));
    navigator.mediaDevices.addEventListener("devicechange", getDevices);
    getDevices();

    return () => {
      navigator.mediaDevices.removeEventListener("devicechange", getDevices);
    };
  }, []);

  return deviceInfo;
}
