import {
    Avatar,
    Button,
    Card,
    CardContent,
    Divider,
    Grid,
    Theme,
    Tooltip,
    Typography,
    useTheme,
} from "@material-ui/core";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import marked from "marked";
import moment from "moment";
import "moment/locale/de";
import "moment/locale/nl";
import "moment/locale/fr";
import "moment/locale/it";
import PropTypes, { any } from "prop-types";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useInView } from "react-hook-inview";
import { Trans, useTranslation } from "react-i18next";
import Carousel from "react-material-ui-carousel";
import { useDispatch, useSelector } from "react-redux";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Link as RouterLink } from "react-router-dom";
import PublicCable from "../../../../../actioncable/PostCable";
import NeumorphicStyle from "../../../../../constants/Neumorphic";
import PostsLikeMapper from "../../../../../mapper/PostsLikeMapper";
import PushNotification from "../../../../../models/PushNotification";
import { salesmarkExhibitor } from "../../../../../redux/actions/ExhibitorsActions";
import { bookmarkPost } from "../../../../../redux/actions/PostsActions";
import { compareEntitiesById } from "../../../../../utils";
import CogoToast from "../../../../../utils/CogoToast";
import MyStore from "../../../../../utils/MyStore";
import useExhibition from "../../../../engines/GeneralHooks/useExhibition";
import useTracking, {
    EVENT_TRACKING_TYPES,
} from "../../../../engines/tracking/useTracking";
import GlobalContext from "../../../../layouts/Dashboard/context/GlobalContext";
import AttachmentCard from "../../../../components/AttachmentCard/AttachmentCard";
import PostCommentSection from "./MobilePostCommentSection/MobilePostCommentSection";
import useConfigurationService from "./../../../../../utils/ConfigurationService";
import Reactions from "../../../../components/PostCard/Reactions";
import useBreakpoints from "../../../../../engines/Util/useBreakpoints";
import Post from "../../../../../models/Post";
import zIndex from "@material-ui/core/styles/zIndex";

const Entities = require("html-entities").XmlEntities;

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        background: theme.palette.background.default,
        boxShadow: "none",
        padding: "0 16px 16px 16px",
    },
    subheader: {
        width: "auto",
        marginLeft: (props) => (props as any).breakpoint <= 3 ? 6 : 6,
        fontSize: "1rem",
        textAlign: "left",
    },
    subtitle: {
        marginBottom: 4,
        textAlign: "left",
    },
    cardHeader: {
        fontSize: "1rem",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        marginBottom: 6,
        //alignItems: "flex-start",
    },
    avatar: {
        width: "2.5rem",
        height: "2.5rem",
        marginRight: "1rem",
    },
    avatarContainer: {
        width: "100%",
        height: "64px",
        alignItems: "center",
        paddingLeft: "8px",
        paddingTop: "4px",
        paddingRight: "16px",
        paddingBottom: "4px",
        background: theme.palette.background.default,
        color: theme.palette.text.primary,
        borderRadius: "8px 8px 8px 8px",
    },
    actions: {
        justifySelf: "center",
        marginLeft: "auto",
        height: (props) => (props as any).breakpoint <= 3 ? 30 : 45,
        fontSize: ".75rem",
        background: theme.palette.secondary.light,
        boxShadow: NeumorphicStyle.boxShadowMedium(
            theme.palette.primary.main,
            theme.palette.secondary.dark
        ),
        color: theme.palette.secondary.contrastText,
        "&:hover": {
            color: theme.palette.secondary.contrastText,
            background: theme.palette.secondary.light,
        },
    },
    title: {
        color: theme.palette.text.primary,
        fontSize: "1rem",
        fontWeight: "bolder",
        marginBottom: 4,
        cursor: "pointer",
        textAlign: "left",
        maxWidth: "100%",
    },
    media16by9: {
        width: "100%",
        paddingBottom: "56.25%",
    },
    videoBox: {
        overflow: "hidden",
        backgroundColor: "grey",
        width: "100%",
        paddingTop: "56.25%",
        boxShadow: NeumorphicStyle.boxShadowMedium(
            theme.palette.primary.main,
            theme.palette.secondary.dark
        ),
        marginTop: 8,
        position: "relative",
        minWidth: "200px",
        minHeight: "112.5px",
    },
    video: {
        width: "100%",
        height: "100%",
        borderRadius: "inherit",
        border: "none",
        left: 0,
        top: 0,
        position: "absolute",
        objectFit: "cover",
    },
    media9by16: {
        width: "100%",
        paddingBottom: "177.78%%",
    },
    media5by4: {
        width: "100%",
        paddingBottom: "80%",
    },
    media4by5: {
        width: "100%",
        paddingBottom: "125%",
    },
    media1by1: {
        width: "100%",
        paddingBottom: "100%",
    },
    accessTimeIcon: {
        color: theme.palette.text.secondary,
        fontSize: ".75rem",
        marginRight: 4,
        paddingTop: (props) => (props as any).breakpoint <= 3 ? 4 : 0
    },
    content: {
        paddingTop: 24,
        padding: 0,
    },
    message: {
        marginBottom: theme.spacing(2),
    },
    mediaArea: {
        marginBottom: theme.spacing(2),
    },
    media: {
        backgroundPosition: "initial",
        width: "100%",
        paddingBottom: "55%",
        marginTop: "1em",
        marginBottom: "1em",
        objectFit: "cover",
        boxShadow: NeumorphicStyle.boxShadowMedium(
            theme.palette.primary.main,
            theme.palette.secondary.dark
        ),
        borderRadius: 5,
    },
    divider: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    contentBody: {
        fontVariant: "body1",
    },
    reactions: {}
}));

function LbMobilePostCard({
    postData,
    currentIndex,
    setCurrentIndex,
    totalPages,
    className,
    refetchBookmarks,
}) {
    const dispatch = useDispatch();
    const theme = useTheme();
    const { t } = useTranslation();
    const exhibitor = postData.getExhibitor();
    const entities = new Entities();
    const [breakpoint] = useBreakpoints();
    const classes = useStyles({ breakpoint });
    const [open, setOpen] = useState(false);
    const [openShareModal, setOpenShareModal] = useState(false);
    const [bookmarked, setBookmarked] = useState(false);
    const [unbookmarked, setUnbookmarked] = useState(false);
    const [state, setState] = useState({ numPages: undefined, pageNumber: 1 });
    const exhibitions = useSelector((state) => (state as any).exhibitionsReducer);
    const users = useSelector((state) => (state as any).usersReducer);
    const [trackEvent] = useTracking();
    const context = useContext(GlobalContext);
    const [bookmarkLoading, setBookmarkLoading] = useState(false);
    const [fullComments, setFullComments] = useState(false);
    const [checkIsLast, setCheckIsLast] = useState(false);
    const [currentPostId, setCurrentPostId] = useState<any>();
    const contextRef = useRef();
    const videoRef = useRef();
    const [videoTime, setVideoTime] = useState<number>();
    const [mainStageMutedBefore, setMainStageMutedBefore] = useState(undefined);
    const [playedFirstTime, setPlayedFirstTime] = useState(true);

    const postsReducer = useSelector((state) => (state as any).postsReducer);

    const [config] = useConfigurationService();

    let time = undefined;
    let blocked = true;

    useEffect(() => {
        moment.locale(MyStore.getLocal());
    }, [MyStore.getLocal()]);

    useEffect(() => {
        contextRef.current = context;
    }, [context.currentExhibitor, context.modalStack])

    useEffect(() => {
        contextRef.current = context;
    }, [context.muted])

    useEffect(() => {
        if (!postData) {
            return;
        }

        if (postData.getId() && postData.getId() !== currentPostId) {
            if (fullComments) {
                setFullComments(false);
            }
            setCurrentPostId(postData.getId() as any);
        }
    }, [postData]);

    useEffect(() => {
        if (
            context.salesmarkExhibitor &&
            compareEntitiesById(context.salesmarkExhibitor, exhibitor) &&
            context.salesmarkExhibitor.isSalesmarked() !== exhibitor.isSalesmarked()
        ) {
            exhibitor.setIsSalesmarked(context.salesmarkExhibitor.isSalesmarked());
        }
    }, [context.salesmarkExhibitor]);

    const [ref, isVisible] = useInView({
        threshold: 0.5,
        onEnter: () => {
            time = Date.now();
            setTimeout(() => timer(), 20000);
            setCheckIsLast(true);
        },
        onLeave: () => {
            timer();
        },
    });

    useEffect(() => {
        if (!videoRef.current) {
            return
        }

        let video = videoRef.current;

        if (isVisible) {
            (video as any).muted = true;
            (video as any).play();
        } else {
            (video as any).pause();
        }

    }, [videoRef.current, isVisible])

    const actionCableReducer = useSelector((state) => (state as any).actionCableReducer);
    const [incomingLike, setIncomingLike] = useState();
    const [exhibition] = useExhibition();
    useEffect(() => {
        if (!actionCableReducer.cable || !postData || !exhibition) {
            return;
        }

        let publicCable = new PublicCable(actionCableReducer.cable, exhibition);
        publicCable.connect((data) => {
            if (
                data.type === PushNotification.NOTIFICATION_TYPES.postLikeCreate ||
                data.type === PushNotification.NOTIFICATION_TYPES.postLikeDelete
            ) {
                const postLike = PostsLikeMapper.build(data.payload);
                const user = MyStore.getCurrentUser();
                if (
                    postLike.getUserId() !== user.getId() &&
                    postLike.getPostId() === postData.getId()
                ) {
                    setIncomingLike({ payload: postLike, method: data.type } as any);
                }
            }
        });

        return () => { };
    }, [actionCableReducer.cable, postData, exhibition]);

    useEffect(() => {
        if (!incomingLike) {
            return;
        }
    }, [incomingLike]);

    useEffect(() => {
        if (checkIsLast) {
            if (postData.isLast() && currentIndex < totalPages) {
                setCurrentIndex(currentIndex + 1);
            }
            setCheckIsLast(false);
        }
    }, [checkIsLast]);

    const timer = () => {
        if (!time) {
            return;
        }
        let diff = Date.now() - time;

        time = undefined;

        if (diff >= 800 && !(contextRef?.current as any)?.currentExhibitor && (contextRef?.current as any)?.modalStack.length === 0) {
            trackEvent(EVENT_TRACKING_TYPES.post.postView, {
                timeMs: diff,
                postId: postData.getId(),
                exhibitor: postData.getExhibitor()
                    ? postData.getExhibitor().getId()
                    : "",
            });
        }
    };

    const handleClose = () => {
        setOpen(false);
        setOpenShareModal(false);
    };

    useEffect(() => {
        if (!unbookmarked) {
            return;
        }

        dispatch(bookmarkPost(postData));
        trackEvent(EVENT_TRACKING_TYPES.post.unbookmark, {
            postId: postData.getId(),
            exhibitor: postData.getExhibitor() ? postData.getExhibitor().getId() : "",
        });
        setBookmarkLoading(true);
    }, [unbookmarked]);

    useEffect(() => {
        if (!bookmarked) {
            return;
        }

        dispatch(bookmarkPost(postData));
        trackEvent(EVENT_TRACKING_TYPES.post.bookmark, {
            postId: postData.getId(),
            exhibitor: postData.getExhibitor() ? postData.getExhibitor().getId() : "",
        });
        setBookmarkLoading(true);
    }, [bookmarked]);

    // Bookmark
    useEffect(() => {
        if (
            (!bookmarked && !unbookmarked) ||
            !bookmarkLoading ||
            postsReducer.isFetching ||
            postsReducer.error.isError()
        ) {
            return;
        }

        if (bookmarked) {
            postData.setIsBookmarked(true);
            //CogoToast.info(t('frontend.added_bookmark'));
            if (context.bookmarkedPost) {
                setTimeout(() => { }, 1000);
            }
            context.setBookmarkedPost(postData);
        }

        if (unbookmarked) {
            postData.setIsBookmarked(false);
            CogoToast.info(t("frontend.removed_bookmark"));
        }

        refetchBookmarks();
        setBookmarkLoading(false);
        setBookmarked(false);
        setUnbookmarked(false);
    }, [postsReducer.isFetching]);

    const handlePicutreClick = () => {
        trackEvent(EVENT_TRACKING_TYPES.post.exhibitorOpened, {
            postId: postData.getId(),
            exhibitor: postData.getExhibitor() ? postData.getExhibitor().getId() : "",
            source: 'image',
        })
        context.setCurrentExhibitor(exhibitor);
    };

    return (
        <Card
            ref={ref}
            id={postData?.getId()?.toString()}
            className={clsx(classes.root, className)}
        >
            <div className={classes.cardHeader}>
                <Grid container direction="row">
                    <Grid container direction="row" className={classes.avatarContainer}>
                        <Avatar
                            alt="Person"
                            to={""}
                            className={classes.avatar}
                            component={RouterLink}
                            src={
                                postData.getExhibitor() &&
                                    postData.getExhibitor().getProfileThumb()
                                    ? postData.getExhibitor().getProfileThumb().getUrl("small")
                                    : ""
                            }
                        // onClick={() => {
                        //     trackEvent(EVENT_TRACKING_TYPES.post.exhibitorOpened, {
                        //         postId: postData.getId(),
                        //         exhibitor: postData.getExhibitor() ? postData.getExhibitor().getId() : "",
                        //         source: 'logo',
                        //     });
                        //     context.setCurrentExhibitor(exhibitor);
                        // }}
                        />
                        <Grid
                            item
                            xs={6}
                            className={classes.subheader}
                            direction="column"
                            container
                        >
                            <Typography
                                noWrap
                                component="h5"
                                variant="h5"
                                // href="#"
                                // onClick={() => {
                                //     trackEvent(EVENT_TRACKING_TYPES.post.exhibitorOpened, {
                                //         postId: postData.getId(),
                                //         exhibitor: postData.getExhibitor() ? postData.getExhibitor().getId() : "",
                                //         source: 'name',
                                //     })
                                //     context.setCurrentExhibitor(exhibitor);
                                // }}
                                className={classes.title}
                            >
                                {postData.getExhibitor() && postData.getExhibitor().getName()}
                            </Typography>

                            <Typography style={{
                                lineHeight: "18px",
                                fontSize: ".75rem",
                            }} variant={breakpoint <= 3 ? "body1" : "h5"}>
                                <AccessTimeIcon className={classes.accessTimeIcon} />
                                {moment(postData.getVisibleAt()).fromNow()}
                            </Typography>
                        </Grid>
                        {/* {users.currentUser &&
                            users.currentUser.hasWritePermissions() &&
                            exhibitions.exhibition ? (
                            <Button
                                to=""
                                component={RouterLink}
                                className={classes.actions}
                                // style={
                                //   exhibitor.isSalesmarked()
                                //     ? {
                                //         backgroundColor: theme.palette.secondary.main,
                                //         "&:hover": {
                                //           backgroundColor: theme.palette.secondary.main,
                                //         },
                                //       }
                                //     : {}
                                // }
                                onClick={() => {
                                    trackEvent(EVENT_TRACKING_TYPES.post.exhibitorOpened, {
                                        postId: postData.getId(),
                                        exhibitor: postData.getExhibitor() ? postData.getExhibitor().getId() : "",
                                        source: 'button',
                                    })
                                    context.setCurrentExhibitor(exhibitor);
                                    // rebuild after fashioncloud
                                    // dispatch(salesmarkExhibitor(exhibitor));
                                    // exhibitor.setIsSalesmarked(!exhibitor.isSalesmarked());
                                    // if (exhibitor.isSalesmarked()) {
                                    //   CogoToast.started(t("frontend.contact_forwarded"));
                                    // }
                                    // context.setSalesmarkExhibitor(exhibitor);
                                }}
                                variant="contained"
                                size="small"
                            >
                                <Trans>
                                    {
                                        // exhibitor.isSalesmarked()
                                        //   ? "frontend.contact_made"
                                        //   : "frontend.make_contact"
                                        "frontend.watch_brand_video"
                                    }
                                </Trans>
                            </Button>
                        ) : undefined} */}
                    </Grid>
                </Grid>
            </div>
            {postData.getPostMedia(MyStore.getLocal()) ? (
                <div className={classes.videoBox}>
                    <video
                        className={classes.video}
                        id={"postVideo_" + postData.getId()}
                        ref={videoRef}
                        controls
                        onVolumeChange={() => {
                            let video = document.getElementById("postVideo_" + postData.getId());
                            if ((video as any)?.muted === false) {
                                setMainStageMutedBefore((contextRef?.current as any)?.muted);
                                (contextRef?.current as any)?.setMuted(true);
                            } else if ((video as any)?.muted === true && mainStageMutedBefore !== undefined) {
                                (contextRef?.current as any)?.setMuted(mainStageMutedBefore);
                            }
                            console.log('volume changed', (video as any)?.volume, (video as any)?.muted);
                        }}
                        onPlay={() => {
                            let video = document.getElementById("postVideo_" + postData.getId());
                            if ((video as any)?.muted === false) {
                                setMainStageMutedBefore((contextRef?.current as any)?.muted);
                                (contextRef?.current as any)?.setMuted(true);
                            }
                            setVideoTime(Date.now());
                            trackEvent(EVENT_TRACKING_TYPES.post.videoViewStarted, {
                                exhibitor: postData.getExhibitor()?.getId(),
                                post: postData.getId(),
                                language: MyStore.getLocal(),
                            });
                        }}
                        onPause={() => {
                            if (mainStageMutedBefore !== undefined) {
                                (contextRef?.current as any)?.setMuted(mainStageMutedBefore);
                            }
                            trackEvent(EVENT_TRACKING_TYPES.post.videoViewEnded, {
                                exhibitor: postData.getExhibitor()?.getId(),
                                post: postData.getId(),
                                language: MyStore.getLocal(),
                                timeViewed: Date.now() - videoTime,
                            });
                        }}
                        src={postData.getPostMedia(MyStore.getLocal()).getUrl()}
                    />
                </div>
            ) : (
                <Carousel
                    autoPlay={false}
                    animation="slide"
                    navButtonsAlwaysInvisible={
                        postData.getPictures() && postData.getPictures().length === 1
                    }
                >
                    {postData.getPictures()
                        ? postData.getPictures().map((pic) => (
                            <Tooltip title={t("frontend.more_about_exhibitor")}>
                                <div style={{ cursor: "pointer" }}>
                                    <img
                                        src={pic.getUrl("medium")}
                                        onClick={() => handlePicutreClick()}
                                        style={{ objectFit: "cover", width: "100%" }}
                                    />
                                </div>
                            </Tooltip>
                        ))
                        : undefined}
                </Carousel>
            )}
            <CardContent className={classes.content}>
                <Typography variant="h4" className={classes.subtitle}>
                    {postData.getTitle(MyStore.getLocal())}
                </Typography>
                <div
                    className={classes.contentBody}
                    dangerouslySetInnerHTML={{
                        __html: marked(postData.getDescription(MyStore.getLocal()) || ""),
                    }}
                ></div>
                {postData.getDocument(0) && (
                    <Grid
                        item
                        xs={5}
                        direction="row"
                        style={{
                            marginBottom: "8px",
                            marginRight: "8px",
                            marginTop: "8px",
                        }}
                    >
                        <Typography
                            variant="h6"
                            style={{ marginBottom: "8px", fontSize: "1em" }}
                        >
                            <Trans>frontend.attachment</Trans>
                        </Typography>
                        <AttachmentCard document={postData.getDocument(0)} />
                    </Grid>
                )}
                <Reactions
                    className={classes.reactions}
                    post={postData}
                    disabled={
                        !(users.currentUser && users.currentUser.hasWritePermissions())
                    }
                    setBookmarked={(value) => setBookmarked(value)}
                    setUnbookmarked={(value) => setUnbookmarked(value)}
                    setOpenShareModal={(value) => setOpenShareModal(value)}
                    bookmarkLoading={bookmarkLoading}
                    setFullComments={(v) => setFullComments(v)}
                    active_comments={
                        config && (config as any).system && (config as any).system.active_comments
                    }
                    active_likes={config && (config as any).system && (config as any).system.active_likes}
                />

                {config && (config as any).system && (config as any).system.active_comments && (
                    <>
                        <Divider className={classes.divider} />
                        <PostCommentSection
                            postData={postData}
                            currentPostId={currentPostId}
                            fullComments={fullComments}
                            setFullComments={setFullComments}
                        />
                    </>
                )}
            </CardContent>
            <Divider style={{ height: "1px", backgroundColor: "grey", marginTop: 16 }} />
        </Card>
    );
}

LbMobilePostCard.propTypes = {
    className: PropTypes.string,
    postData: PropTypes.object.isRequired,
};

export default LbMobilePostCard;
