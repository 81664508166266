import { Box, IconButton } from "@material-ui/core";
import ListIcon from "@material-ui/icons/List";
import StarIcon from "@material-ui/icons/Star";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import NeumorphicStyle from "../../../../../constants/Neumorphic";
import { useSelector } from 'react-redux'

const useStyles = makeStyles((theme) => ({
  switchButton: {
    height: 35,
    width: 35,
    padding: 0,
    borderRadius: 2,
    boxShadow: NeumorphicStyle.boxShadowSmall(
      theme.palette.primary.main,
      theme.palette.secondary.dark
    ),
    "&:hover": {
      backgroundColor: theme.palette.background.default,
      borderRadius: 2,
      boxShadow: NeumorphicStyle.boxShadowSmallInset(
        theme.palette.primary.main,
        theme.palette.secondary.dark
      ),
    },
  },
  pressedSwitchButton: {
    height: 35,
    width: 35,
    borderRadius: 2,
    boxShadow: NeumorphicStyle.boxShadowSmallInset(
      theme.palette.primary.main,
      theme.palette.secondary.dark
    ),
    color: theme.palette.secondary.main,
    "&:hover": {
      backgroundColor: theme.palette.background.default,
      borderRadius: 2,
      boxShadow: NeumorphicStyle.boxShadowSmallInset(
        theme.palette.primary.main,
        theme.palette.secondary.dark
      ),
    },
  },
}));

function BookmarkSwitch({
  showBookmarks,
  setShowBookmarks,
  horizontal = true,
}) {
  const classes = useStyles();
  const {configuration: {state: {filters}}} = useSelector(state => state.configurationsReducer)

  return (
    <Box>
      {filters && filters.bookmarket_filter && (
        <Box display="flex" flexDirection={horizontal ? "row" : "column"}>
          <IconButton
            className={
              !showBookmarks ? classes.pressedSwitchButton : classes.switchButton
            }
            onClick={() => setShowBookmarks(false)}
          >
            <ListIcon />
          </IconButton>
          <IconButton
            className={
              showBookmarks ? classes.pressedSwitchButton : classes.switchButton
            }
            onClick={() => setShowBookmarks(true)}
          >
            <StarIcon />
          </IconButton>
        </Box>
      )}
    </Box>
  );
}

export default BookmarkSwitch;
