import { LinearProgress } from "@material-ui/core";
import React from "react";
import MeetingRoomControls from "./MeetingRoomControls";
import Participant from "./Participant";
import trackpubsToTracks from "./trackpubsToTracks";

const DualCall = ({ participants, room, handleLogout, networkQuality }) => {
  const toggleVideo = (enabled) => {
    if (!room) {
      return;
    }

    room.localParticipant.videoTracks.forEach((publication) => {
      if (enabled) {
        publication.track.enable();
      } else {
        publication.track.disable();
      }
    });
  };

  const toggleAudio = (enabled) => {
    if (!room) {
      return;
    }

    room.localParticipant.audioTracks.forEach((publication) => {
      if (enabled) {
        publication.track.enable();
      } else {
        publication.track.disable();
      }
    });
  };

  return (
    <div
      className="room"
      style={{
        width: "90%",
        paddingBottom: "50.625%",
        position: "relative",
        marginLeft: "auto",
        marginRight: "auto",
      }}
    >
      {/* <h2>Room: {roomName}</h2> */}
      {participants && participants.length > 0 ? (
        <Participant
          key={participants[0].sid}
          participant={participants[0]}
          showIdentity={false}
        />
      ) : (
        <div
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            backgroundColor: "lightGrey",
          }}
        >
          <LinearProgress />
        </div>
      )}
      {room ? (
        <div
          style={{
            width: "20%",
            height: "20%",
            position: "absolute",
            left: "78%",
            top: "3%",
          }}
        >
          <Participant
            key={room.localParticipant.sid}
            participant={room.localParticipant}
            showIdentity={false}
          />
        </div>
      ) : (
        ""
      )}
      <div style={{ position: "absolute", left: "50%", top: "85%" }}>
        <div style={{ position: "relative", left: "-50%" }}>
          <MeetingRoomControls
            toggleVideo={(enabled) => toggleVideo(enabled)}
            toggleAudio={(enabled) => toggleAudio(enabled)}
            toggleLogout={handleLogout}
            networkQuality={networkQuality}
            localVideoTracks={
              (room &&
                room.localParticipant &&
                room.localParticipant.videoTracks &&
                trackpubsToTracks(room.localParticipant.videoTracks)) ||
              []
            }
            localAudioTracks={
              (room &&
                room.localParticipant &&
                room.localParticipant.audioTracks &&
                trackpubsToTracks(room.localParticipant.audioTracks)) ||
              []
            }
          />
        </div>
      </div>
      {/* 
        <button onClick={handleLogout}>Log out</button>
        */}
    </div>
  );
};

export default DualCall;
