import React, { useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Avatar,
  Box,
  Divider,
  IconButton,
  Input,
  Paper,
  SvgIcon,
  Tooltip,
  makeStyles
} from '@material-ui/core';
import AddPhotoIcon from '@material-ui/icons/AddPhotoAlternate';
import AttachFileIcon from '@material-ui/icons/AttachFile';
//import { Send as SendIcon } from 'react-feather';
import SendIcon from '@material-ui/icons/Send';
import { createMessage } from '../../../redux/actions/MessagesActions';
import Message from '../../../models/Message';
import MyStore from '../../../utils/MyStore';
import { useTranslation } from 'react-i18next';
import useTracking, { EVENT_TRACKING_TYPES } from '../../engines/tracking/useTracking';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 0,
    paddingRight: 0,
  },
  divider: {
    width: 1,
    height: 24
  },
  fileInput: {
    display: 'none'
  }
}));

function MessageAdd({
  className,
  conversation,
  disabled,
  sendMessage,
  ...rest
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);
  const [body, setBody] = useState('');
  const attachments = [];
  const { t } = useTranslation();
  const [trackEvent] = useTracking();

  const users = useSelector(state => state.usersReducer);

  const handleChange = (event) => {
    if (event.target.value.split("\n").length < 6) {
      event.persist();
      setBody(event.target.value);
    }
  };

  const createMessage = () => {
    // if (!body) {
    //   return;
    // }

    // if (!conversation) {
    //   sendMessage(body);
    //   setBody("");
    //   return;
    // }

    // let message = new Message({
    //   conversationId: conversation.getId(),
    //   conversation: conversation,
    //   exhibitionId: conversation.getExhibitionId(),
    //   me: true,
    //   text: body,
    //   user: users.currentUser,
    //   receivers: conversation.getOtherUser() ? [conversation.getOtherUser()]:undefined,
    //   createdAt: new Date(),
    // });
    if (body && body.length > 0) {
      sendMessage(body);
      setBody("");
    }
  };

  const handleKeyUp = (event) => {
    
    if (event.keyCode === 13 && !event.shiftKey) {
      createMessage();
    }
  };

  const handleAttach = () => {
    fileInputRef.current.click();
  };

  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Paper
        variant="outlined"
        component={Box}
        flexGrow={1}
        ml={2}
        p={1}
      >
        <Input
          className={classes.input}
          multiline
          rowsMax={5}
          inputProps={{
            maxlength: 500,
            rowsMax: 5
          }}
          disableUnderline
          fullWidth
          value={body}
          onChange={handleChange}
          onKeyUp={handleKeyUp}
          placeholder={t("frontend.components.chat.leave_message")}
        />
      </Paper>
      <Tooltip title="Send">
        <span>
          <IconButton
            color="secondary"
            disabled={!body || disabled || !body.replace(/\s/g, '').length}
            onClick={createMessage}
          >
            <SvgIcon fontSize="small">
              <SendIcon />
            </SvgIcon>
          </IconButton>
        </span>
      </Tooltip>
      {/* <Divider className={classes.divider} />
      <Tooltip title="Attach photo">
        <span>
          <IconButton
            edge="end"
            onClick={handleAttach}
            disabled={disabled}
          >
            <AddPhotoIcon />
          </IconButton>
        </span>
      </Tooltip>
      <Tooltip title="Attach file">
        <span>
          <IconButton
            edge="end"
            onClick={handleAttach}
            disabled={disabled}
          >
            <AttachFileIcon />
          </IconButton>
        </span>
      </Tooltip>
      <input
        className={classes.fileInput}
        ref={fileInputRef}
        type="file"
      /> */}
    </div>
  );
}

MessageAdd.propTypes = {
  className: PropTypes.string,
  thread: PropTypes.object,
  disabled: PropTypes.bool,
  createMessage: PropTypes.func
};

MessageAdd.defaultProps = {
  className: '',
  disabled: false,
  createMessage: () => {}
};

export default MessageAdd;
