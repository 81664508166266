import {
  Box,
  Grid,
  LinearProgress,
  makeStyles,
  Typography,
  Divider,
  useMediaQuery,
} from "@material-ui/core";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE } from "../../../../../constants/Urls";
import useExhibition from "../../../../../engines/GeneralHooks/useExhibition";
import useInfiniteScoll from "../../../../../engines/GeneralHooks/useInfiniteScoll";
import useExhibitorsQL from "../../../../../engines/GraphQL/hooks/useExhibitorsQL";
import { fetchTalkingPointsExhibitors } from "../../../../../redux/actions/TalkingPointsActions";
import BookmarkSwitch from "../../components/BookmarkSwitch";
import ExhibitorCard from "../../components/ExhibitorCard";
import TagArea from "../../components/TagArea";
import GlobalContext from "../../context/GlobalContext";
import { useTheme } from '@material-ui/core/styles';
import MyStore from "../../../../../utils/MyStore";

const useStyles = makeStyles((theme) => ({
  root: {
    overflowY: "auto",
    overflowX: "hidden",
    width: "100%",
    height: "100%",
    padding: theme.spacing(6),
    "&::-webkit-scrollbar": {
      "-webkit-appearance": "none",
      width: "7px",
    },
    "&::-webkit-scrollbar-thumb": {
      "border-radius": "4px",
      backgroundColor: "rgba(0, 0, 0, .5)",
      boxShadow: "0 0 1px rgba(255, 255, 255, .5)",
    },
  },
  headerImage: {
    width: "100%",
    paddingBottom: "15%",
    objectFit: "cover",
  },
  grid: {
    overflow: "auto",
    height: "700px",
  },
}));

function ExhibitorView({ setCurrentExhibitorList }) {
  const classes = useStyles();
  const containerRef = useRef();
  const dispatch = useDispatch();
  const { talkingPointId, setCurrentExhibitor, tags, minPrice, maxPrice } =
    useContext(GlobalContext);
  const [showBookmarks, setShowBookmarks] = useState(false);
  const [searchText, setSearchText] = useState();
  const [currentIndex, setCurrentIndex] = useState(DEFAULT_PAGE_INDEX);
  const xsSize = useMediaQuery('(min-width: 1360px)')
  const smSize = useMediaQuery('(min-width: 1600px)')
  const mdSize = useMediaQuery('(min-width: 1920px)')
  const mdxSize = useMediaQuery('(min-width: 2300px)')
  const lgSize = useMediaQuery('(min-width: 2560px)')

  const pageSize = lgSize ? 36 : mdxSize ? 18 : mdSize ? 15 : smSize ? 12 : xsSize ? 9 : DEFAULT_PAGE_SIZE

  const [exhibitors, loading, totalPages, refetchBookmarks, fetchNewData] =
    useExhibitorsQL(
      searchText,
      currentIndex,
      pageSize,
      showBookmarks,
      talkingPointId
    );
  const [exhibition] = useExhibition();

  const talkingPointsReducer = useSelector(
    (state) => state.talkingPointsReducer
  );

  // eslint-disable-next-line no-unused-vars
  const [currentPageIndex, isBottom, resetPageIndex] = useInfiniteScoll(
    containerRef.current,
    totalPages,
    true,
    loading
  );

  // if talkingPointId === undefined then show all
  const talkingPoint = useMemo(() => {
    if (!exhibition) return undefined;

    if (talkingPointId === undefined)
      return {
        getName: () => undefined,
        getHeaderImage: () => undefined,
        getPrimaryColor: () => "white",
        getHighlightColor: () => "white",
        getFontColor: () => "black",
        getFontHighlightColor: () => "black",
      };

    const talkingPoints = exhibition.getTalkingPoints();
    if (!talkingPoints) return undefined;

    return talkingPoints.find((tp) => {
      return tp.getId() === talkingPointId;
    });
  }, [exhibition, talkingPointId]);

  useEffect(() => {
    if (talkingPointId !== undefined) {
      dispatch(fetchTalkingPointsExhibitors(talkingPointId));
    }
  }, [dispatch, talkingPointId]);

  useEffect(() => {
    setCurrentIndex(DEFAULT_PAGE_INDEX);
    resetPageIndex();
  }, [talkingPointId]);

  useEffect(() => {
    if (searchText && searchText !== "") {
      resetPageIndex();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  useEffect(() => {
    if (currentPageIndex === currentIndex) {
      return;
    }
    setCurrentIndex(currentPageIndex);
    fetchNewData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPageIndex]);

  useEffect(() => {
    resetPageIndex();
    fetchNewData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tags, minPrice, maxPrice]);

  useEffect(() => {
    if (!exhibitors || exhibitors.length === 0) {
      return;
    }
    resetPageIndex();
    refetchBookmarks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showBookmarks]);

  useEffect(() => {
    setCurrentExhibitorList(exhibitors);
  }, [exhibitors, setCurrentExhibitorList]);

  const updateFeed = () => {
    if (showBookmarks) {
      resetPageIndex();
      fetchNewData();
    }
  };

  if (!exhibition) return <div />;

  return (
    <div ref={containerRef} className={classes.root}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={5}
      >
        <TagArea
          dropdownWidth="70%"
          resetPageIndex={() => resetPageIndex()}
          setSearchText={(text) => setSearchText(text)}
          exhibition={exhibition}
          showRange
        />
        <BookmarkSwitch
          showBookmarks={showBookmarks}
          setShowBookmarks={setShowBookmarks}
        />
      </Box>

      {(loading || talkingPointsReducer.isFetching) && <LinearProgress />}

      {talkingPoint && talkingPoint.getName(MyStore.getLocal()) && (
        <Box py={2}>
          <Typography variant="h4">{talkingPoint.getName(MyStore.getLocal())}</Typography>
        </Box>
      )}

      <Box
        border={talkingPointId !== undefined ? 3 : 0}
        borderColor={
          talkingPointId !== undefined &&
          talkingPoint &&
          talkingPoint.getPrimaryColor()
        }
        borderRadius={talkingPointId !== undefined && 4}
        p={2.5}
        mb={8}
      >
        {talkingPoint && talkingPoint.getHeaderImage(MyStore.getLocal()) && (
          <Box pb={3}>
            <img
              src={talkingPoint.getHeaderImage(MyStore.getLocal()).getUrl("large")}
              className={classes.headerImage}
              alt=""
            />
          </Box>
        )}
     
        <Grid container direction="row">
          {exhibitors.map((exhibitor) =>
            exhibitor.isBookmarked() || !showBookmarks ? (
              <Grid item>
                <ExhibitorCard
                  refetchBookmarks={updateFeed}
                  setExhibitor={setCurrentExhibitor}
                  exhibitor={exhibitor}
                  primaryColor={"white"}
                  fontColor={"black"}
                  highlightColor={
                    "white"
                  }
                  fontHighlightColor={
                    "black"
                  }
                  onClick={() => {
                    setCurrentExhibitorList(exhibitors);
                  }}
                />
              </Grid>
            ) : undefined
          )}
        </Grid>
        <Box height="1%">
          {loading && currentPageIndex !== DEFAULT_PAGE_INDEX && (
            <LinearProgress
              style={{ maxWidth: "80%", marginLeft: "auto", marginRight: "auto", marginTop: 12 }}
            />
          )}
          {currentPageIndex >= totalPages && (
            <Divider style={{ background: 'black', height: 2, marginTop: 8 }}/>            
          )}
        </Box>
        </Box>
    </div>
  );
}

export default ExhibitorView;
