import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import PushNotificationCable from "../../actioncable/PushNotificationCable";
import PushNotificationMapper from "../../mapper/PushNotificationMapper";
import { DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE } from "../../constants/Urls";
import { fetchNotifications } from "../../redux/actions/NotificationsActions";
import MyStore from "../../utils/MyStore";
import FilterParams from "../../utils/FilterParams";
import PushNotification from "../../models/PushNotification";
import CogoToast from "../../utils/CogoToast";
import { Link as RouterLink, useHistory } from "react-router-dom";
import FrontendRoutes from "../../constants/FrontendRoutes";
import Notification from "../../models/Notification";
import Meeting from "../../models/Meeting";
import MeetingsUser from "../../models/MeetingsUser";
import { updateMeetingsUser } from "../../redux/actions/MeetingsUsersActions";
import {
  fetchMeeting,
  updateMeeting,
} from "../../redux/actions/MeetingsActions";
import { set } from "immutable";
import { setCableStatus } from "../../redux/actions/ActionCableActions";
import { STATUS } from "../../redux/reducers/actionCableReducer";
import { IconButton } from "@material-ui/core";
import PublicCable from "../../actioncable/PostCable";
import useExhibition from "../GeneralHooks/useExhibition";
import GlobalContext from "../../frontend/layouts/Dashboard/context/GlobalContext";

export default function useNotificationEngine(
  open,
  pageSize = DEFAULT_PAGE_SIZE,
  pageIndex = DEFAULT_PAGE_INDEX
) {
  const dispatch = useDispatch();
  const localUser = MyStore.getCurrentUser();

  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(false);
  const [incomingNotification] = usePushNotificationCable();
  const [unreadMessagesCount, setUnreadMessagesCount] = useState(0);
  const [loadingUnread, setLoadingUnread] = useState(false);
  const [totalPages, setTotalPages] = useState(DEFAULT_PAGE_INDEX);

  const notificationsReducer = useSelector(
    (state) => state.notificationsReducer
  );
  const exhibitions = useSelector((state) => state.exhibitionsReducer);
  const users = useSelector((state) => state.usersReducer);

  useEffect(() => {
    if (!exhibitions.exhibition || !open) {
      return;
    }

    if (pageIndex === DEFAULT_PAGE_INDEX) {
      dispatch(
        fetchNotifications(exhibitions.exhibition.getId(), {
          pageIndex: 1,
          pageSize: 1,
          filters: [
            FilterParams.filterBy(Notification.attributes.unread, true),
          ],
        })
      );
    } else {
      setLoading(true);
      setLoadingUnread(false);
      dispatch(
        fetchNotifications(exhibitions.exhibition.getId(), {
          pageIndex: pageIndex,
          pageSize: pageSize,
          filters: [
            //FilterParams.orderByDesc(Notification.attributes.createdAt),
          ],
        })
      );
    }

    setLoadingUnread(true);
  }, [exhibitions.currentSet, users.user, open, pageSize, pageIndex]);

  useEffect(() => {
    if (
      !incomingNotification ||
      incomingNotification.getNotificationType() ===
      PushNotification.NOTIFICATION_TYPES.chatMessage ||
      incomingNotification.getNotificationType() ===
      PushNotification.NOTIFICATION_TYPES.custom ||
      incomingNotification.getNotificationType() ===
      PushNotification.NOTIFICATION_TYPES.newPosts
    ) {
      return;
    }

    setUnreadMessagesCount(unreadMessagesCount + 1);
  }, [incomingNotification]);

  useEffect(() => {
    if (
      notificationsReducer.isFetching ||
      !notificationsReducer.notifications ||
      notificationsReducer.error.isError()
    ) {
      return;
    }

    if (loadingUnread && !loading) {
      setUnreadMessagesCount(notificationsReducer.totalItemsCount);
      setLoading(true);
      dispatch(
        fetchNotifications(exhibitions.exhibition.getId(), {
          pageSize:
            notificationsReducer.totalItemsCount > pageSize
              ? notificationsReducer.totalItemsCount
              : pageSize,
          pageIndex: DEFAULT_PAGE_INDEX,
          filters: [
            //FilterParams.orderByDesc(Notification.attributes.createdAt),
          ],
        })
      );
    } else {
      if (pageIndex === DEFAULT_PAGE_INDEX) {
        setNotifications(notificationsReducer.notifications);
      } else {
        setNotifications(
          notifications.concat(notificationsReducer.notifications)
        );
      }

      setLoading(false);
      setLoadingUnread(false);
      setTotalPages(notificationsReducer.totalPages);
    }
  }, [notificationsReducer.isFetching]);

  const resetUnreadMessages = () => {
    setUnreadMessagesCount(0);
  };

  return [
    notifications,
    loading,
    incomingNotification,
    unreadMessagesCount,
    resetUnreadMessages,
    totalPages,
  ];
}

function usePushNotificationCable() {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const context = useContext(GlobalContext);

  const users = useSelector((state) => state.usersReducer);
  const actionCableReducer = useSelector((state) => state.actionCableReducer);
  const meetingsReducer = useSelector((state) => state.meetingsReducer);
  const meetingsUsersReducer = useSelector(
    (state) => state.meetingsUsersReducer
  );

  const [nc, setNc] = useState();
  const [pnc, setPnc] = useState();
  const [incomingMessage, setincomingMessage] = useState();
  const [incomingCall, setIncomingCall] = useState();
  const [incomingCallLoading, setIncomingCallLoading] = useState(false);
  const [updatingMeetingsUser, setUpdatingMeetingsUser] = useState(false);
  const [currentMeeting, setCurrentMeeting] = useState();
  const [exhibition] = useExhibition()

  useEffect(() => {
    if (!incomingCall || !incomingCall.getPayload()) {
      return;
    }

    setIncomingCallLoading(true);
    dispatch(
      fetchMeeting(
        incomingCall.getPayload().getExhibitionId(),
        incomingCall.getPayload().getId()
      )
    );
  }, [incomingCall]);

  useEffect(() => {
    if (
      !(incomingCallLoading || updatingMeetingsUser) ||
      meetingsReducer.isFetching ||
      !meetingsReducer.meeting ||
      meetingsReducer.error.isError()
    ) {
      return;
    }

    if (!incomingCall) {
      return;
    }

    let meeting = meetingsReducer.meeting;
    setCurrentMeeting(meeting);
    setIncomingCallLoading(false);
    let exhibitionId = meeting.getExhibitionId();
    CogoToast.incomingCall(
      {},
      incomingCall.getOtherUser(),
      () => handleAcceptMeeting(exhibitionId, meeting),
      () => handleDeclineMeeting(exhibitionId, meeting),
      meeting
    );
  }, [meetingsReducer.isFetching]);

  const handleAcceptMeeting = (exhibitionId, meeting) => {
    let user = MyStore.getCurrentUser();
    let updatedMeeting = meeting
      .getMeetingsUsers()
      .find((meetingsUser) => meetingsUser.getUserId() === user.getId());

    if (!updatedMeeting) {
      return;
    }
    updatedMeeting.setStatus(MeetingsUser.status.ACCEPTED);

    setUpdatingMeetingsUser(true);
    dispatch(updateMeetingsUser(exhibitionId, meeting.getId(), updatedMeeting));
    setIncomingCall(undefined);
  };

  useEffect(() => {
    if (
      meetingsUsersReducer.isFetching ||
      !updatingMeetingsUser ||
      !meetingsUsersReducer.meetingsUser ||
      meetingsUsersReducer.error.isError()
    ) {
      return;
    }

    setUpdatingMeetingsUser(false);
    currentMeeting.setMeetingStatus(Meeting.meetingStatus.MEETING_STARTED);
    dispatch(updateMeeting(currentMeeting));
  }, [meetingsUsersReducer.meetingsUser]);

  const handleDeclineMeeting = (exhibitionId, meeting) => {
    let user = MyStore.getCurrentUser();
    let updatedMeeting = meeting
      .getMeetingsUsers()
      .find((meetingsUser) => meetingsUser.getUserId() === user.getId());

    if (!updatedMeeting) {
      return;
    }
    updatedMeeting.setStatus(MeetingsUser.status.DECLINED);

    dispatch(updateMeetingsUser(exhibitionId, meeting.getId(), updatedMeeting));
    setIncomingCall(undefined);
  };

  useEffect(() => {
    if (!users.currentUser || !actionCableReducer.cable || !exhibition) {
      return;
    }

    const publicNotification = new PublicCable(
      actionCableReducer.cable,
      exhibition
    );

    publicNotification.connect(
      (data) => {
        let pushNotification = PushNotificationMapper.build(data);
        const payload = pushNotification.getPayload();

        switch (pushNotification.getNotificationType()) {
          case PushNotification.NOTIFICATION_TYPES.keynoteStarts:
            if (pushNotification.getPayload() && pushNotification.getPayload().getLanguage() === MyStore.getLocal()) {
              CogoToast.started(
                <div>{pushNotification.getPayload().getTitle()}</div>,
                {
                  heading: t("frontend.notifications.keynote.started"),
                  onClick: () =>
                    context.setVideoFullWidth(true)
                }
              );
            }
            break;
          case PushNotification.NOTIFICATION_TYPES.custom:
            CogoToast.custom(pushNotification.getPayload());
            break;
        }

        setincomingMessage(pushNotification);
      },
      () => {
        
      },
      () => {
        // Disconnected
        
      }
    );

    const pushNotification = new PushNotificationCable(
      users.currentUser,
      actionCableReducer.cable,
      exhibition
    );

    pushNotification.connect(
      (data) => {
        let pushNotification = PushNotificationMapper.build(data);
        const payload = pushNotification.getPayload();

        switch (pushNotification.getNotificationType()) {
          case PushNotification.NOTIFICATION_TYPES.keynoteStarts:
            CogoToast.started(
              <div>{pushNotification.getPayload().getTitle()}</div>,
              {
                heading: t("frontend.notifications.keynote.started"),
                onClick: () =>
                  (window.location.href = FrontendRoutes.workshops.show(
                    payload.getId()
                  )),
              }
            );
            break;
          case PushNotification.NOTIFICATION_TYPES.chatMessage:
            const loc = window.location.pathname;
            if (
              loc.indexOf(payload.getConversationId()) !== -1 ||
              loc === "/"
            ) {
              break;
            }
            // CogoToast.newChatMessage(<div>{payload.getText()}</div>, {
            //   heading:
            //     t("frontend.notifications.chat.new_message") +
            //     payload.getUser().getFullName(),
            //   onClick: () =>
            //     history.push(
            //       FrontendRoutes.chat.show(payload.getConversationId())
            //     ),  
            // });
            break;
          case PushNotification.NOTIFICATION_TYPES.meetingAccepted:
            CogoToast.success(
              pushNotification.getOtherUser().getFullName() +
              t("frontend.notifications.meeting.accepted")
            );
            break;
          case PushNotification.NOTIFICATION_TYPES.meetingDeclined:
            CogoToast.error(
              pushNotification.getOtherUser().getFullName() +
              t("frontend.notifications.meeting.declined")
            );
            break;
          case PushNotification.NOTIFICATION_TYPES.meetingRequest:
            if (
              pushNotification.getPayload().getReason() ===
              Meeting.reason.CHAT_ONE_TO_ONE
            ) {
              setIncomingCall(pushNotification);
            } else {
              CogoToast.info(
                t("frontend.notifications.meeting.request") +
                pushNotification.getOtherUser().getFullName() +
                ", " +
                new Date(payload.getStartAt()).toLocaleString()
              );
            }
            break;
          case PushNotification.NOTIFICATION_TYPES.meetingStarted:
            if (
              pushNotification.getPayload().getReason() ===
              Meeting.reason.CHAT_ONE_TO_ONE
            ) {
              if (!context.meetingId) {
                context.setMeetingId(pushNotification.getPayload().getId())
              }
            } else {
              CogoToast.started(
                <a href={FrontendRoutes.meetings.show(payload.getId())}>
                  {t("frontend.notifications.meeting.started") +
                    pushNotification.getOtherUser().getFullName()}
                </a>
              );
            }
            break;
          case PushNotification.NOTIFICATION_TYPES.commentUpdate:
            CogoToast.info("Someone commented where you have commented!");
            break;

          case PushNotification.NOTIFICATION_TYPES.postLikeUpdate:
            CogoToast.info("Someone liked your post!");
            break;
          case PushNotification.NOTIFICATION_TYPES.commentUpdatePost:
            CogoToast.info("Someone commented on your post!");
            break;
          case PushNotification.NOTIFICATION_TYPES.commentLikeUpdate:
            CogoToast.info("Someone liked your comment!");
            break;
          case PushNotification.NOTIFICATION_TYPES.share:
            let share = pushNotification.getPayload();
            let primaryText;
            let routeTo;
            switch (share.getSharableType()) {
              case "User":
                primaryText =
                  pushNotification.getOtherUser().getFullName() +
                  t("frontend.notifications.share.shared_visitor");
                routeTo = FrontendRoutes.visitors.show(share.getSharableId());
                break;
              case "Post":
                primaryText =
                  pushNotification.getOtherUser().getFullName() +
                  t("frontend.notifications.share.shared_post");
                routeTo = FrontendRoutes.posts.show(share.getSharableId());
                break;
              case "Keynote":
                primaryText =
                  pushNotification.getOtherUser().getFullName() +
                  t("frontend.notifications.share.shared_keynote");
                routeTo = FrontendRoutes.workshops.show(share.getSharableId());
                break;
              case "Exhibitor":
                primaryText =
                  pushNotification.getOtherUser().getFullName() +
                  t("frontend.notifications.share.shared_exhibitor");
                routeTo = FrontendRoutes.profiles.show(share.getSharableId());
                break;
              default:
                primaryText = "Unknown";
            }
            CogoToast.info(<a href={routeTo}>{primaryText}</a>);
            break;
          case PushNotification.NOTIFICATION_TYPES.custom:
            CogoToast.custom(pushNotification.getPayload());
            break;
        }

        setincomingMessage(pushNotification);
      },
      () => {
        // Connected
        dispatch(setCableStatus(STATUS.CONNECTED));
      },
      () => {
        // Disconnected
        dispatch(setCableStatus(STATUS.DISCONNECTED));
      }
    );

    setNc(pushNotification);
    setPnc(publicNotification);
    return () => {
      if (nc && nc.state.subscription) {
        nc.disconnect();
      }
      if (pnc && pnc.state.subscription) {
        pnc.disconnect();
      }
    };
  }, [users.currentUser, actionCableReducer.cable, exhibition]);

  return [incomingMessage];
}

export function useRawPushNotification() {
  const dispatch = useDispatch();

  const users = useSelector((state) => state.usersReducer);
  const actionCableReducer = useSelector((state) => state.actionCableReducer);

  const [nc, setNc] = useState();
  const [incomingMessage, setincomingMessage] = useState();
  const [exhibition] = useExhibition();

  useEffect(() => {
    if (!users.currentUser || !actionCableReducer.cable || !exhibition) {
      return;
    }

    const pushNotification = new PublicCable(
      actionCableReducer.cable,
      exhibition
    );

    pushNotification.connect(
      (data) => {
        setincomingMessage(PushNotificationMapper.build(data));
      }
    );

    setNc(pushNotification);
    return () => {
      if (nc && nc.state.subscription) {
        nc.disconnect();
      }
    };
  }, [users.currentUser, actionCableReducer.cable, exhibition]);

  return [incomingMessage];
}

export function useRawPrivatePushNotification() {
  const users = useSelector((state) => state.usersReducer);
  const actionCableReducer = useSelector((state) => state.actionCableReducer);

  const [nc, setNc] = useState();
  const [incomingMessage, setincomingMessage] = useState();
  const [exhibition] = useExhibition();

  useEffect(() => {
    if (!users.currentUser || !actionCableReducer.cable || !exhibition) {
      return;
    }

    const pushNotification = new PushNotificationCable(
      users.currentUser,
      actionCableReducer.cable,
      exhibition
    );

    pushNotification.connect(
      (data) => {
        setincomingMessage(PushNotificationMapper.build(data));
      }
    );

    setNc(pushNotification);
    return () => {
      if (nc && nc.state.subscription) {
        nc.disconnect();
      }
    };
  }, [users.currentUser, actionCableReducer.cable, exhibition]);

  return [incomingMessage];
}
