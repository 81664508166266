// Action Cable
export const ADD_ACTION_CABLE = "ADD_ACTION_CABLE";
export const REMOVE_ACTION_CABLE = "REMOVE_ACTION_CABLE";
export const SET_CABLE_STATUS = "SET_CABLE_STATUS";

//Config
export const FETCHING_CONFIGURATION_REQUEST = "FETCHING_CONFIGURATION_REQUEST";
export const FETCHING_CONFIGURATION_SUCCESS = "FETCHING_CONFIGURATION_SUCCESS";
export const FETCHING_CONFIGURATION_FAILURE = "FETCHING_CONFIGURATION_FAILURE";

// Login
export const SESSION_CREATE_REQUEST = "SESSION_CREATE_REQUEST";
export const SESSION_CREATE_SUCCESS = "SESSION_CREATE_SUCCESS";
export const SESSION_CREATE_FAILURE = "SESSION_CREATE_FAILURE";

// Logout
export const SESSION_DELETE_REQUEST = "SESSION_DELETE_REQUEST";
export const SESSION_DELETE_SUCCESS = "SESSION_DELETE_SUCCESS";
export const SESSION_DELETE_FAILURE = "SESSION_DELETE_FAILURE";

// Logout user
export const LOGOUT_USER_REQUEST = "LOGOUT_DELETE_REQUEST";
export const LOGOUT_USER_SUCCESS = "LOGOUT_DELETE_SUCCESS";
export const LOGOUT_USER_FAILURE = "LOGOUT_DELETE_FAILURE";

//Registration
export const REGISTRATION_CREATE_REQUEST = "REGISTRATION_CREATE_REQUEST";
export const REGISTRATION_CREATE_SUCCESS = "REGISTRATION_CREATE_SUCCESS";
export const REGISTRATION_CREATE_FAILURE = "REGISTRATION_CREATE_FAILURE";

//Users limit
export const FETCH_IS_ENOUGH_LIMIT_REQUEST = "FETCH_IS_ENOUGH_LIMIT_REQUEST";
export const FETCH_IS_ENOUGH_LIMIT_SUCCESS = "FETCH_IS_ENOUGH_LIMIT_SUCCESS";
export const FETCH_IS_ENOUGH_LIMIT_FAILURE = "FETCH_IS_ENOUGH_LIMIT_FAILURE";

// Password
export const RESET_PASSWORD_SHOW_SUCCESS = "RESET_PASSWORD_SHOW_SUCCESS";
export const RESET_PASSWORD_SHOW_REQUEST = "RESET_PASSWORD_SHOW_REQUEST";
export const RESET_PASSWORD_SHOW_FAILURE = "RESET_PASSWORD_SHOW_FAILURE";

export const RESET_PASSWORD_CREATE_SUCCESS = "RESET_PASSWORD_CREATE_SUCCESS";
export const RESET_PASSWORD_CREATE_REQUEST = "RESET_PASSWORD_CREATE_REQUEST";
export const RESET_PASSWORD_CREATE_FAILURE = "RESET_PASSWORD_CREATE_FAILURE";

export const RESET_PASSWORD_UPDATE_SUCCESS = "RESET_PASSWORD_UPDATE_SUCCESS";
export const RESET_PASSWORD_UPDATE_REQUEST = "RESET_PASSWORD_UPDATE_REQUEST";
export const RESET_PASSWORD_UPDATE_FAILURE = "RESET_PASSWORD_UPDATE_FAILURE";

// GET User
export const FETCHING_USER_REQUEST = "FETCHING_USER_REQUEST";
export const FETCHING_USER_SUCCESS = "FETCHING_USER_SUCCESS";
export const FETCHING_USER_FAILURE = "FETCHING_USER_FAILURE";

// Update User
export const UPDATE_USER_REQUEST = "UPDATE_USER_REQUEST";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAILURE = "UPDATE_USER_FAILURE";

export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const SET_CURRENT_EXHIBITORS = "SET_CURRENT_EXHIBITORS";

export const CLEAR_USER = "CLEAR_USER";
export const CLEAR_VISITOR = "CLEAR_VISITOR";

// Statistics Track Post
export const CREATE_EXHIBITION_STATISTICS_REQUEST =
  "CREATE_EXHIBITION_STATISTICS_REQUEST";
export const CREATE_EXHIBITION_STATISTICS_SUCCESS =
  "CREATE_EXHIBITION_STATISTICS_SUCCESS";
export const CREATE_EXHIBITION_STATISTICS_FAILURE =
  "CREATE_EXHIBITION_STATISTICS_FAILURE";

export const CREATE_PUSH_NOTIFICATION_REQUEST =
  "CREATE_PUSH_NOTIFICATION_REQUEST";
export const CREATE_PUSH_NOTIFICATION_SUCCESS =
  "CREATE_PUSH_NOTIFICATION_SUCCESS";
export const CREATE_PUSH_NOTIFICATION_FAILURE =
  "CREATE_PUSH_NOTIFICATION_FAILURE";

// SystemRoles
export const FETCHING_SYSTEM_ROLES_REQUEST = "FETCHING_SYSTEM_ROLES_REQUEST";
export const FETCHING_SYSTEM_ROLES_SUCCESS = "FETCHING_SYSTEM_ROLES_SUCCESS";
export const FETCHING_SYSTEM_ROLES_FAILURE = "FETCHING_SYSTEM_ROLES_FAILURE";

export const FETCHING_SYSTEM_ROLE_REQUEST = "FETCHING_SYSTEM_ROLE_REQUEST";
export const FETCHING_SYSTEM_ROLE_SUCCESS = "FETCHING_SYSTEM_ROLE_SUCCESS";
export const FETCHING_SYSTEM_ROLE_FAILURE = "FETCHING_SYSTEM_ROLE_FAILURE";

// Stages
export const FETCHING_STAGES_REQUEST = "FETCHING_STAGES_REQUEST";
export const FETCHING_STAGES_SUCCESS = "FETCHING_STAGES_SUCCESS";
export const FETCHING_STAGES_FAILURE = "FETCHING_STAGES_FAILURE";

export const FETCHING_STAGE_REQUEST = "FETCHING_STAGE_REQUEST";
export const FETCHING_STAGE_SUCCESS = "FETCHING_STAGE_SUCCESS";
export const FETCHING_STAGE_FAILURE = "FETCHING_STAGE_FAILURE";

// SystemRoles User
export const CREATE_SYSTEM_ROLES_USER_REQUEST =
  "CREATE_SYSTEM_ROLES_USER_REQUEST";
export const CREATE_SYSTEM_ROLES_USER_SUCCESS =
  "CREATE_SYSTEM_ROLES_USER_SUCCESS";
export const CREATE_SYSTEM_ROLES_USER_FAILURE =
  "CREATE_SYSTEM_ROLES_USER_FAILURE";

export const DELETE_SYSTEM_ROLES_USER_REQUEST =
  "DELETE_SYSTEM_ROLES_USER_REQUEST";
export const DELETE_SYSTEM_ROLES_USER_SUCCESS =
  "DELETE_SYSTEM_ROLES_USER_SUCCESS";
export const DELETE_SYSTEM_ROLES_USER_FAILURE =
  "DELETE_SYSTEM_ROLES_USER_FAILURE";

// Bookmarks
export const FETCHING_BOOKMARKS_REQUEST = "FETCHING_BOOKMARKS_REQUEST";
export const FETCHING_BOOKMARKS_SUCCESS = "FETCHING_BOOKMARKS_SUCCESS";
export const FETCHING_BOOKMARKS_FAILURE = "FETCHING_BOOKMARKS_FAILURE";

export const CREATE_BOOKMARK_REQUEST = "CREATE_BOOKMARK_REQUEST";
export const CREATE_BOOKMARK_SUCCESS = "CREATE_BOOKMARK_SUCCESS";
export const CREATE_BOOKMARK_FAILURE = "CREATE_BOOKMARK_FAILURE";

export const DELETE_BOOKMARK_REQUEST = "DELETE_BOOKMARK_REQUEST";
export const DELETE_BOOKMARK_SUCCESS = "DELETE_BOOKMARK_SUCCESS";
export const DELETE_BOOKMARK_FAILURE = "DELETE_BOOKMARK_FAILURE";

export const CLEAR_BOOKMARK = "CLEAR_BOOKMARK";

// Users Tickets
export const FETCHING_USERS_TICKETS_REQUEST = "FETCHING_USERS_TICKETS_REQUEST";
export const FETCHING_USERS_TICKETS_SUCCESS = "FETCHING_USERS_TICKETS_SUCCESS";
export const FETCHING_USERS_TICKETS_FAILURE = "FETCHING_USERS_TICKETS_FAILURE";

export const FETCHING_USERS_TICKET_REQUEST = "FETCHING_USERS_TICKET_REQUEST";
export const FETCHING_USERS_TICKET_SUCCESS = "FETCHING_USERS_TICKET_SUCCESS";
export const FETCHING_USERS_TICKET_FAILURE = "FETCHING_USERS_TICKET_FAILURE";

// GET All Exhibitions
export const FETCHING_EXHIBITIONS_REQUEST = "FETCHING_EXHIBITIONS_REQUEST";
export const FETCHING_EXHIBITIONS_SUCCESS = "FETCHING_EXHIBITIONS_SUCCESS";
export const FETCHING_EXHIBITIONS_FAILURE = "FETCHING_EXHIBITIONS_FAILURE";

export const FETCHING_EXHIBITIONS_UPCOMING_REQUEST =
  "FETCHING_EXHIBITIONS_UPCOMING_REQUEST";
export const FETCHING_EXHIBITIONS_UPCOMING_SUCCESS =
  "FETCHING_EXHIBITIONS_UPCOMING_SUCCESS";
export const FETCHING_EXHIBITIONS_UPCOMING_FAILURE =
  "FETCHING_EXHIBITIONS_UPCOMING_FAILURE";

export const FETCHING_EXHIBITION_REQUEST = "FETCHING_EXHIBITION_REQUEST";
export const FETCHING_EXHIBITION_SUCCESS = "FETCHING_EXHIBITION_SUCCESS";
export const FETCHING_EXHIBITION_FAILURE = "FETCHING_EXHIBITION_FAILURE";

export const UPDATE_EXHIBITION_REQUEST = "UPDATE_EXHIBITION_REQUEST";
export const UPDATE_EXHIBITION_SUCCESS = "UPDATE_EXHIBITION_SUCCESS";
export const UPDATE_EXHIBITION_FAILURE = "UPDATE_EXHIBITION_FAILURE";

export const CREATE_EXHIBITION_REQUEST = "CREATE_EXHIBITION_REQUEST";
export const CREATE_EXHIBITION_SUCCESS = "CREATE_EXHIBITION_SUCCESS";
export const CREATE_EXHIBITION_FAILURE = "CREATE_EXHIBITION_FAILURE";

export const DELETE_EXHIBITION_REQUEST = "DELETE_EXHIBITION_REQUEST";
export const DELETE_EXHIBITION_SUCCESS = "DELETE_EXHIBITION_SUCCESS";
export const DELETE_EXHIBITION_FAILURE = "DELETE_EXHIBITION_FAILURE";

export const SET_CURRENT_EXHIBITION = "SET_CURRENT_EXHIBITION";

export const CLEAR_EXHIBITION = "CLEAR_EXHIBITION";

// Share
export const CREATE_EXHIBITION_SHARE_FAILURE =
  "CREATE_EXHIBITION_SHARE_FAILURE";
export const CREATE_EXHIBITION_SHARE_SUCCESS =
  "CREATE_EXHIBITION_SHARE_SUCCESS";
export const CREATE_EXHIBITION_SHARE_REQUEST =
  "CREATE_EXHIBITION_SHARE_REQUEST";

// Notifications
export const FETCHING_EXHIBITION_NOTIFICATIONS_REQUEST =
  "FETCHING_EXHIBITION_NOTIFICATIONS_REQUEST";
export const FETCHING_EXHIBITION_NOTIFICATIONS_SUCCESS =
  "FETCHING_EXHIBITION_NOTIFICATIONS_SUCCESS";
export const FETCHING_EXHIBITION_NOTIFICATIONS_FAILURE =
  "FETCHING_EXHIBITION_NOTIFICATIONS_FAILURE";

// GET All Exhibitions
export const FETCHING_EMPLOYEES_REQUEST = "FETCHING_EMPLOYEES_REQUEST";
export const FETCHING_EMPLOYEES_SUCCESS = "FETCHING_EMPLOYEES_SUCCESS";
export const FETCHING_EMPLOYEES_FAILURE = "FETCHING_EMPLOYEES_FAILURE";

export const FETCHING_EMPLOYEE_REQUEST = "FETCHING_EMPLOYEE_REQUEST";
export const FETCHING_EMPLOYEE_SUCCESS = "FETCHING_EMPLOYEE_SUCCESS";
export const FETCHING_EMPLOYEE_FAILURE = "FETCHING_EMPLOYEE_FAILURE";

export const UPDATE_EMPLOYEE_REQUEST = "UPDATE_EMPLOYEE_REQUEST";
export const UPDATE_EMPLOYEE_SUCCESS = "UPDATE_EMPLOYEE_SUCCESS";
export const UPDATE_EMPLOYEE_FAILURE = "UPDATE_EMPLOYEE_FAILURE";

export const CREATE_EMPLOYEE_REQUEST = "CREATE_EMPLOYEE_REQUEST";
export const CREATE_EMPLOYEE_SUCCESS = "CREATE_EMPLOYEE_SUCCESS";
export const CREATE_EMPLOYEE_FAILURE = "CREATE_EMPLOYEE_FAILURE";

export const DELETE_EMPLOYEE_REQUEST = "DELETE_EMPLOYEE_REQUEST";
export const DELETE_EMPLOYEE_SUCCESS = "DELETE_EMPLOYEE_SUCCESS";
export const DELETE_EMPLOYEE_FAILURE = "DELETE_EMPLOYEE_FAILURE";

export const CLEAR_EMPLOYEE = "CLEAR_EMPLOYEE";

// GET All Exhibition Exhibitors
export const FETCHING_EXHIBITION_EXHIBITORS_REQUEST =
  "FETCHING_EXHIBITION_EXHIBITORS_REQUEST";
export const FETCHING_EXHIBITION_EXHIBITORS_SUCCESS =
  "FETCHING_EXHIBITION_EXHIBITORS_SUCCESS";
export const FETCHING_EXHIBITION_EXHIBITORS_FAILURE =
  "FETCHING_EXHIBITION_EXHIBITORS_FAILURE";

export const FETCHING_EXHIBITION_EXHIBITOR_REQUEST =
  "FETCHING_EXHIBITION_EXHIBITOR_REQUEST";
export const FETCHING_EXHIBITION_EXHIBITOR_SUCCESS =
  "FETCHING_EXHIBITION_EXHIBITOR_SUCCESS";
export const FETCHING_EXHIBITION_EXHIBITOR_FAILURE =
  "FETCHING_EXHIBITION_EXHIBITOR_FAILURE";

export const UPDATE_EXHIBITION_EXHIBITOR_REQUEST =
  "UPDATE_EXHIBITION_EXHIBITOR_REQUEST";
export const UPDATE_EXHIBITION_EXHIBITOR_SUCCESS =
  "UPDATE_EXHIBITION_EXHIBITOR_SUCCESS";
export const UPDATE_EXHIBITION_EXHIBITOR_FAILURE =
  "UPDATE_EXHIBITION_EXHIBITOR_FAILURE";

export const CREATE_EXHIBITION_EXHIBITOR_REQUEST =
  "CREATE_EXHIBITION_EXHIBITOR_REQUEST";
export const CREATE_EXHIBITION_EXHIBITOR_SUCCESS =
  "CREATE_EXHIBITION_EXHIBITOR_SUCCESS";
export const CREATE_EXHIBITION_EXHIBITOR_FAILURE =
  "CREATE_EXHIBITION_EXHIBITOR_FAILURE";

export const DELETE_EXHIBITION_EXHIBITOR_REQUEST =
  "DELETE_EXHIBITION_EXHIBITOR_REQUEST";
export const DELETE_EXHIBITION_EXHIBITOR_SUCCESS =
  "DELETE_EXHIBITION_EXHIBITOR_SUCCESS";
export const DELETE_EXHIBITION_EXHIBITOR_FAILURE =
  "DELETE_EXHIBITION_EXHIBITOR_FAILURE";

export const CLEAR_EXHIBITION_EXHIBITOR = "CLEAR_EXHIBITION_EXHIBITOR";

export const FETCHING_EXHIBITION_EXHIBITORS_USERS_REQUEST =
  "FETCHING_EXHIBITION_EXHIBITORS_USERS_REQUEST";
export const FETCHING_EXHIBITION_EXHIBITORS_USERS_SUCCESS =
  "FETCHING_EXHIBITION_EXHIBITORS_USERS_SUCCESS";
export const FETCHING_EXHIBITION_EXHIBITORS_USERS_FAILURE =
  "FETCHING_EXHIBITION_EXHIBITORS_USERS_FAILURE";

export const FETCHING_EXHIBITION_EXHIBITORS_USER_REQUEST =
  "FETCHING_EXHIBITION_EXHIBITORS_USER_REQUEST";
export const FETCHING_EXHIBITION_EXHIBITORS_USER_SUCCESS =
  "FETCHING_EXHIBITION_EXHIBITORS_USER_SUCCESS";
export const FETCHING_EXHIBITION_EXHIBITORS_USER_FAILURE =
  "FETCHING_EXHIBITION_EXHIBITORS_USER_FAILURE";

export const CREATE_EXHIBITION_EXHIBITORS_USER_REQUEST =
  "CREATE_EXHIBITION_EXHIBITORS_USER_REQUEST";
export const CREATE_EXHIBITION_EXHIBITORS_USER_SUCCESS =
  "CREATE_EXHIBITION_EXHIBITORS_USER_SUCCESS";
export const CREATE_EXHIBITION_EXHIBITORS_USER_FAILURE =
  "CREATE_EXHIBITION_EXHIBITORS_USER_FAILURE";

export const UPDATE_EXHIBITION_EXHIBITORS_USER_REQUEST =
  "UPDATE_EXHIBITION_EXHIBITORS_USER_REQUEST";
export const UPDATE_EXHIBITION_EXHIBITORS_USER_SUCCESS =
  "UPDATE_EXHIBITION_EXHIBITORS_USER_SUCCESS";
export const UPDATE_EXHIBITION_EXHIBITORS_USER_FAILURE =
  "UPDATE_EXHIBITION_EXHIBITORS_USER_FAILURE";

export const DELETE_EXHIBITION_EXHIBITORS_USER_REQUEST =
  "DELETE_EXHIBITION_EXHIBITORS_USER_REQUEST";
export const DELETE_EXHIBITION_EXHIBITORS_USER_SUCCESS =
  "DELETE_EXHIBITION_EXHIBITORS_USER_SUCCESS";
export const DELETE_EXHIBITION_EXHIBITORS_USER_FAILURE =
  "DELETE_EXHIBITION_EXHIBITORS_USER_FAILURE";

export const CLEAR_EXHIBITION_EXHIBITORS_USER =
  "CLEAR_EXHIBITION_EXHIBITORS_USER";

// GET All Exhibition Tickets
export const FETCHING_EXHIBITION_TICKETS_REQUEST =
  "FETCHING_EXHIBITION_TICKETS_REQUEST";
export const FETCHING_EXHIBITION_TICKETS_SUCCESS =
  "FETCHING_EXHIBITION_TICKETS_SUCCESS";
export const FETCHING_EXHIBITION_TICKETS_FAILURE =
  "FETCHING_EXHIBITION_TICKETS_FAILURE";

export const FETCHING_EXHIBITION_TICKET_REQUEST =
  "FETCHING_EXHIBITION_TICKET_REQUEST";
export const FETCHING_EXHIBITION_TICKET_SUCCESS =
  "FETCHING_EXHIBITION_TICKET_SUCCESS";
export const FETCHING_EXHIBITION_TICKET_FAILURE =
  "FETCHING_EXHIBITION_TICKET_FAILURE";

export const UPDATE_EXHIBITION_TICKET_REQUEST =
  "UPDATE_EXHIBITION_TICKET_REQUEST";
export const UPDATE_EXHIBITION_TICKET_SUCCESS =
  "UPDATE_EXHIBITION_TICKET_SUCCESS";
export const UPDATE_EXHIBITION_TICKET_FAILURE =
  "UPDATE_EXHIBITION_TICKET_FAILURE";

export const CREATE_EXHIBITION_TICKET_REQUEST =
  "CREATE_EXHIBITION_TICKET_REQUEST";
export const CREATE_EXHIBITION_TICKET_SUCCESS =
  "CREATE_EXHIBITION_TICKET_SUCCESS";
export const CREATE_EXHIBITION_TICKET_FAILURE =
  "CREATE_EXHIBITION_TICKET_FAILURE";

export const DELETE_EXHIBITION_TICKET_REQUEST =
  "DELETE_EXHIBITION_TICKET_REQUEST";
export const DELETE_EXHIBITION_TICKET_SUCCESS =
  "DELETE_EXHIBITION_TICKET_SUCCESS";
export const DELETE_EXHIBITION_TICKET_FAILURE =
  "DELETE_EXHIBITION_TICKET_FAILURE";

export const CLEAR_EXHIBITION_TICKET = "CLEAR_EXHIBITION_TICKET";

// Exhibition Image
export const CREATE_EXHIBITION_IMAGE_REQUEST =
  "CREATE_EXHIBITION_IMAGE_REQUEST";
export const CREATE_EXHIBITION_IMAGE_SUCCESS =
  "CREATE_EXHIBITION_IMAGE_SUCCESS";
export const CREATE_EXHIBITION_IMAGE_FAILURE =
  "CREATE_EXHIBITION_IMAGE_FAILURE";

export const UPDATE_EXHIBITION_IMAGE_REQUEST =
  "UPDATE_EXHIBITION_IMAGE_REQUEST";
export const UPDATE_EXHIBITION_IMAGE_SUCCESS =
  "UPDATE_EXHIBITION_IMAGE_SUCCESS";
export const UPDATE_EXHIBITION_IMAGE_FAILURE =
  "UPDATE_EXHIBITION_IMAGE_FAILURE";

export const DELETE_EXHIBITION_IMAGE_REQUEST =
  "DELETE_EXHIBITION_IMAGE_REQUEST";
export const DELETE_EXHIBITION_IMAGE_SUCCESS =
  "DELETE_EXHIBITION_IMAGE_SUCCESS";
export const DELETE_EXHIBITION_IMAGE_FAILURE =
  "DELETE_EXHIBITION_IMAGE_FAILURE";

export const CLEAR_EXHIBITION_IMAGE = "CLEAR_EXHIBITION_IMAGE";

// User Image
export const CREATE_USER_IMAGE_REQUEST = "CREATE_USER_IMAGE_REQUEST";
export const CREATE_USER_IMAGE_SUCCESS = "CREATE_USER_IMAGE_SUCCESS";
export const CREATE_USER_IMAGE_FAILURE = "CREATE_USER_IMAGE_FAILURE";

export const UPDATE_USER_IMAGE_REQUEST = "UPDATE_USER_IMAGE_REQUEST";
export const UPDATE_USER_IMAGE_SUCCESS = "UPDATE_USER_IMAGE_SUCCESS";
export const UPDATE_USER_IMAGE_FAILURE = "UPDATE_USER_IMAGE_FAILURE";

export const DELETE_USER_IMAGE_REQUEST = "DELETE_USER_IMAGE_REQUEST";
export const DELETE_USER_IMAGE_SUCCESS = "DELETE_USER_IMAGE_SUCCESS";
export const DELETE_USER_IMAGE_FAILURE = "DELETE_USER_IMAGE_FAILURE";

// Exhibitor Image
export const CREATE_EXHIBITOR_IMAGE_REQUEST = "CREATE_EXHIBITOR_IMAGE_REQUEST";
export const CREATE_EXHIBITOR_IMAGE_SUCCESS = "CREATE_EXHIBITOR_IMAGE_SUCCESS";
export const CREATE_EXHIBITOR_IMAGE_FAILURE = "CREATE_EXHIBITOR_IMAGE_FAILURE";

export const UPDATE_EXHIBITOR_IMAGE_REQUEST = "UPDATE_EXHIBITOR_IMAGE_REQUEST";
export const UPDATE_EXHIBITOR_IMAGE_SUCCESS = "UPDATE_EXHIBITOR_IMAGE_SUCCESS";
export const UPDATE_EXHIBITOR_IMAGE_FAILURE = "UPDATE_EXHIBITOR_IMAGE_FAILURE";

export const DELETE_EXHIBITOR_IMAGE_REQUEST = "DELETE_EXHIBITOR_IMAGE_REQUEST";
export const DELETE_EXHIBITOR_IMAGE_SUCCESS = "DELETE_EXHIBITOR_IMAGE_SUCCESS";
export const DELETE_EXHIBITOR_IMAGE_FAILURE = "DELETE_EXHIBITOR_IMAGE_FAILURE";

// Exhibition Keynote

export const FETCHING_EXHIBITION_KEYNOTES_REQUEST =
  "FETCHING_EXHIBITION_KEYNOTES_REQUEST";
export const FETCHING_EXHIBITION_KEYNOTES_SUCCESS =
  "FETCHING_EXHIBITION_KEYNOTES_SUCCESS";
export const FETCHING_EXHIBITION_KEYNOTES_FAILURE =
  "FETCHING_EXHIBITION_KEYNOTES_FAILURE";

export const FETCHING_EXHIBITION_ACTIVE_KEYNOTES_REQUEST =
  "FETCHING_EXHIBITION_ACTIVE_KEYNOTES_REQUEST";
export const FETCHING_EXHIBITION_ACTIVE_KEYNOTES_SUCCESS =
  "FETCHING_EXHIBITION_ACTIVE_KEYNOTES_SUCCESS";
export const FETCHING_EXHIBITION_ACTIVE_KEYNOTES_FAILURE =
  "FETCHING_EXHIBITION_ACTIVE_KEYNOTES_FAILURE";

export const FETCHING_EXHIBITION_KEYNOTE_REQUEST =
  "FETCHING_EXHIBITION_KEYNOTE_REQUEST";
export const FETCHING_EXHIBITION_KEYNOTE_SUCCESS =
  "FETCHING_EXHIBITION_KEYNOTE_SUCCESS";
export const FETCHING_EXHIBITION_KEYNOTE_FAILURE =
  "FETCHING_EXHIBITION_KEYNOTE_FAILURE";

export const UPDATE_EXHIBITION_KEYNOTE_REQUEST =
  "UPDATE_EXHIBITION_KEYNOTE_REQUEST";
export const UPDATE_EXHIBITION_KEYNOTE_SUCCESS =
  "UPDATE_EXHIBITION_KEYNOTE_SUCCESS";
export const UPDATE_EXHIBITION_KEYNOTE_FAILURE =
  "UPDATE_EXHIBITION_KEYNOTE_FAILURE";

export const CREATE_EXHIBITION_KEYNOTE_REQUEST =
  "CREATE_EXHIBITION_KEYNOTE_REQUEST";
export const CREATE_EXHIBITION_KEYNOTE_SUCCESS =
  "CREATE_EXHIBITION_KEYNOTE_SUCCESS";
export const CREATE_EXHIBITION_KEYNOTE_FAILURE =
  "CREATE_EXHIBITION_KEYNOTE_FAILURE";

export const DELETE_EXHIBITION_KEYNOTE_REQUEST =
  "DELETE_EXHIBITION_KEYNOTE_REQUEST";
export const DELETE_EXHIBITION_KEYNOTE_SUCCESS =
  "DELETE_EXHIBITION_KEYNOTE_SUCCESS";
export const DELETE_EXHIBITION_KEYNOTE_FAILURE =
  "DELETE_EXHIBITION_KEYNOTE_FAILURE";

export const CLEAR_EXHIBITION_KEYNOTE = "CLEAR_EXHIBITION_KEYNOTE";

// Exhibtion Thematic Area
export const FETCHING_EXHIBITION_THEMATIC_AREAS_REQUEST =
  "FETCHING_EXHIBITION_THEMATIC_AREAS_REQUEST";
export const FETCHING_EXHIBITION_THEMATIC_AREAS_SUCCESS =
  "FETCHING_EXHIBITION_THEMATIC_AREAS_SUCCESS";
export const FETCHING_EXHIBITION_THEMATIC_AREAS_FAILURE =
  "FETCHING_EXHIBITION_THEMATIC_AREAS_FAILURE";

export const FETCHING_EXHIBITION_THEMATIC_AREA_REQUEST =
  "FETCHING_EXHIBITION_THEMATIC_AREA_REQUEST";
export const FETCHING_EXHIBITION_THEMATIC_AREA_SUCCESS =
  "FETCHING_EXHIBITION_THEMATIC_AREA_SUCCESS";
export const FETCHING_EXHIBITION_THEMATIC_AREA_FAILURE =
  "FETCHING_EXHIBITION_THEMATIC_AREA_FAILURE";

export const CREATE_EXHIBITION_THEMATIC_AREA_FAILURE =
  "CREATE_EXHIBITION_THEMATIC_AREA_FAILURE";
export const CREATE_EXHIBITION_THEMATIC_AREA_SUCCESS =
  "CREATE_EXHIBITION_THEMATIC_AREA_SUCCESS";
export const CREATE_EXHIBITION_THEMATIC_AREA_REQUEST =
  "CREATE_EXHIBITION_THEMATIC_AREA_REQUEST";

export const UPDATE_EXHIBITION_THEMATIC_AREA_FAILURE =
  "UPDATE_EXHIBITION_THEMATIC_AREA_FAILURE";
export const UPDATE_EXHIBITION_THEMATIC_AREA_SUCCESS =
  "UPDATE_EXHIBITION_THEMATIC_AREA_SUCCESS";
export const UPDATE_EXHIBITION_THEMATIC_AREA_REQUEST =
  "UPDATE_EXHIBITION_THEMATIC_AREA_REQUEST";

export const DELETE_EXHIBITION_THEMATIC_AREA_FAILURE =
  "DELETE_EXHIBITION_THEMATIC_AREA_FAILURE";
export const DELETE_EXHIBITION_THEMATIC_AREA_SUCCESS =
  "DELETE_EXHIBITION_THEMATIC_AREA_SUCCESS";
export const DELETE_EXHIBITION_THEMATIC_AREA_REQUEST =
  "DELETE_EXHIBITION_THEMATIC_AREA_REQUEST";

export const CLEAR_EXHIBITION_THEMATIC_AREA = "CLEAR_EXHIBITION_THEMATIC_AREA";

// Agenda Entries

export const FETCHING_EXHIBITION_AGENDA_ENTRIES_REQUEST =
  "FETCHING_EXHIBITION_AGENDA_ENTRIES_REQUEST";
export const FETCHING_EXHIBITION_AGENDA_ENTRIES_SUCCESS =
  "FETCHING_EXHIBITION_AGENDA_ENTRIES_SUCCESS";
export const FETCHING_EXHIBITION_AGENDA_ENTRIES_FAILURE =
  "FETCHING_EXHIBITION_AGENDA_ENTRIES_FAILURE";

export const FETCHING_EXHIBITION_AGENDA_ENTRY_REQUEST =
  "FETCHING_EXHIBITION_AGENDA_ENTRY_REQUEST";
export const FETCHING_EXHIBITION_AGENDA_ENTRY_SUCCESS =
  "FETCHING_EXHIBITION_AGENDA_ENTRY_SUCCESS";
export const FETCHING_EXHIBITION_AGENDA_ENTRY_FAILURE =
  "FETCHING_EXHIBITION_AGENDA_ENTRY_FAILURE";

export const CREATE_EXHIBITION_AGENDA_ENTRY_REQUEST =
  "CREATE_EXHIBITION_AGENDA_ENTRY_REQUEST";
export const CREATE_EXHIBITION_AGENDA_ENTRY_SUCCESS =
  "CREATE_EXHIBITION_AGENDA_ENTRY_SUCCESS";
export const CREATE_EXHIBITION_AGENDA_ENTRY_FAILURE =
  "CREATE_EXHIBITION_AGENDA_ENTRY_FAILURE";

export const UPDATE_EXHIBITION_AGENDA_ENTRY_REQUEST =
  "UPDATE_EXHIBITION_AGENDA_ENTRY_REQUEST";
export const UPDATE_EXHIBITION_AGENDA_ENTRY_SUCCESS =
  "UPDATE_EXHIBITION_AGENDA_ENTRY_SUCCESS";
export const UPDATE_EXHIBITION_AGENDA_ENTRY_FAILURE =
  "UPDATE_EXHIBITION_AGENDA_ENTRY_FAILURE";

export const DELETE_EXHIBITION_AGENDA_ENTRY_REQUEST =
  "DELETE_EXHIBITION_AGENDA_ENTRY_REQUEST";
export const DELETE_EXHIBITION_AGENDA_ENTRY_SUCCESS =
  "DELETE_EXHIBITION_AGENDA_ENTRY_SUCCESS";
export const DELETE_EXHIBITION_AGENDA_ENTRY_FAILURE =
  "DELETE_EXHIBITION_AGENDA_ENTRY_FAILURE";

// Speakers for Keynote
export const CREATE_EXHIBITION_KEYNOTES_SPEAKER_REQUEST =
  "CREATE_EXHIBITION_KEYNOTES_SPEAKER_REQUEST";
export const CREATE_EXHIBITION_KEYNOTES_SPEAKER_SUCCESS =
  "CREATE_EXHIBITION_KEYNOTES_SPEAKER_SUCCESS";
export const CREATE_EXHIBITION_KEYNOTES_SPEAKER_FAILURE =
  "CREATE_EXHIBITION_KEYNOTES_SPEAKER_FAILURE";

export const DELETE_EXHIBITION_KEYNOTES_SPEAKER_REQUEST =
  "DELETE_EXHIBITION_KEYNOTES_SPEAKER_REQUEST";
export const DELETE_EXHIBITION_KEYNOTES_SPEAKER_SUCCESS =
  "DELETE_EXHIBITION_KEYNOTES_SPEAKER_SUCCESS";
export const DELETE_EXHIBITION_KEYNOTES_SPEAKER_FAILURE =
  "DELETE_EXHIBITION_KEYNOTES_SPEAKER_FAILURE";

// Users for Keynote
export const CREATE_EXHIBITION_KEYNOTES_USER_REQUEST =
  "CREATE_EXHIBITION_KEYNOTES_USER_REQUEST";
export const CREATE_EXHIBITION_KEYNOTES_USER_SUCCESS =
  "CREATE_EXHIBITION_KEYNOTES_USER_SUCCESS";
export const CREATE_EXHIBITION_KEYNOTES_USER_FAILURE =
  "CREATE_EXHIBITION_KEYNOTES_USER_FAILURE";

export const DELETE_EXHIBITION_KEYNOTES_USER_REQUEST =
  "DELETE_EXHIBITION_KEYNOTES_USER_REQUEST";
export const DELETE_EXHIBITION_KEYNOTES_USER_SUCCESS =
  "DELETE_EXHIBITION_KEYNOTES_USER_SUCCESS";
export const DELETE_EXHIBITION_KEYNOTES_USER_FAILURE =
  "DELETE_EXHIBITION_KEYNOTES_USER_FAILURE";

// Moderator for Keynote
export const CREATE_EXHIBITION_KEYNOTES_MODERATOR_REQUEST =
  "CREATE_EXHIBITION_KEYNOTES_MODERATOR_REQUEST";
export const CREATE_EXHIBITION_KEYNOTES_MODERATOR_SUCCESS =
  "CREATE_EXHIBITION_KEYNOTES_MODERATOR_SUCCESS";
export const CREATE_EXHIBITION_KEYNOTES_MODERATOR_FAILURE =
  "CREATE_EXHIBITION_KEYNOTES_MODERATOR_FAILURE";

export const DELETE_EXHIBITION_KEYNOTES_MODERATOR_REQUEST =
  "DELETE_EXHIBITION_KEYNOTES_MODERATOR_REQUEST";
export const DELETE_EXHIBITION_KEYNOTES_MODERATOR_SUCCESS =
  "DELETE_EXHIBITION_KEYNOTES_MODERATOR_SUCCESS";
export const DELETE_EXHIBITION_KEYNOTES_MODERATOR_FAILURE =
  "DELETE_EXHIBITION_KEYNOTES_MODERATOR_FAILURE";

// Exhibition Meetings
export const FETCHING_EXHIBITION_MEETINGS_REQUEST =
  "FETCHING_EXHIBITION_MEETINGS_REQUEST";
export const FETCHING_EXHIBITION_MEETINGS_SUCCESS =
  "FETCHING_EXHIBITION_MEETINGS_SUCCESS";
export const FETCHING_EXHIBITION_MEETINGS_FAILURE =
  "FETCHING_EXHIBITION_MEETINGS_FAILURE";

export const FETCHING_EXHIBITION_MEETING_REQUEST =
  "FETCHING_EXHIBITION_MEETING_REQUEST";
export const FETCHING_EXHIBITION_MEETING_SUCCESS =
  "FETCHING_EXHIBITION_MEETING_SUCCESS";
export const FETCHING_EXHIBITION_MEETING_FAILURE =
  "FETCHING_EXHIBITION_MEETING_FAILURE";

export const UPDATE_EXHIBITION_MEETING_REQUEST =
  "UPDATE_EXHIBITION_MEETING_REQUEST";
export const UPDATE_EXHIBITION_MEETING_SUCCESS =
  "UPDATE_EXHIBITION_MEETING_SUCCESS";
export const UPDATE_EXHIBITION_MEETING_FAILURE =
  "UPDATE_EXHIBITION_MEETING_FAILURE";

export const CREATE_EXHIBITION_MEETING_REQUEST =
  "CREATE_EXHIBITION_MEETING_REQUEST";
export const CREATE_EXHIBITION_MEETING_SUCCESS =
  "CREATE_EXHIBITION_MEETING_SUCCESS";
export const CREATE_EXHIBITION_MEETING_FAILURE =
  "CREATE_EXHIBITION_MEETING_FAILURE";

export const DELETE_EXHIBITION_MEETING_REQUEST =
  "DELETE_EXHIBITION_MEETING_REQUEST";
export const DELETE_EXHIBITION_MEETING_SUCCESS =
  "DELETE_EXHIBITION_MEETING_SUCCESS";
export const DELETE_EXHIBITION_MEETING_FAILURE =
  "DELETE_EXHIBITION_MEETING_FAILURE";

export const CLEAR_EXHIBITION_MEETING = "CLEAR_EXHIBITION_MEETING";

// Exhibition Documents
export const CREATE_EXHIBITION_DOCUMENT_REQUEST =
  "CREATE_EXHIBITION_DOCUMENT_REQUEST";
export const CREATE_EXHIBITION_DOCUMENT_SUCCESS =
  "CREATE_EXHIBITION_DOCUMENT_SUCCESS";
export const CREATE_EXHIBITION_DOCUMENT_FAILURE =
  "CREATE_EXHIBITION_DOCUMENT_FAILURE";

export const DELETE_EXHIBITION_DOCUMENT_REQUEST =
  "DELETE_EXHIBITION_DOCUMENT_REQUEST";
export const DELETE_EXHIBITION_DOCUMENT_SUCCESS =
  "DELETE_EXHIBITION_DOCUMENT_SUCCESS";
export const DELETE_EXHIBITION_DOCUMENT_FAILURE =
  "DELETE_EXHIBITION_DOCUMENT_FAILURE";

// Exhition MeetingsUsers
export const FETCHING_EXHIBITION_MEETINGS_USERS_REQUEST =
  "FETCHING_EXHIBITION_MEETINGS_USERS_REQUEST";
export const FETCHING_EXHIBITION_MEETINGS_USERS_SUCCESS =
  "FETCHING_EXHIBITION_MEETINGS_USERS_SUCCESS";
export const FETCHING_EXHIBITION_MEETINGS_USERS_FAILURE =
  "FETCHING_EXHIBITION_MEETINGS_USERS_FAILURE";

export const UPDATE_EXHIBITION_MEETINGS_USER_REQUEST =
  "UPDATE_EXHIBITION_MEETINGS_USER_REQUEST";
export const UPDATE_EXHIBITION_MEETINGS_USER_SUCCESS =
  "UPDATE_EXHIBITION_MEETINGS_USER_SUCCESS";
export const UPDATE_EXHIBITION_MEETINGS_USER_FAILURE =
  "UPDATE_EXHIBITION_MEETINGS_USER_FAILURE";

export const CREATE_EXHIBITION_MEETINGS_USER_REQUEST =
  "CREATE_EXHIBITION_MEETINGS_USER_REQUEST";
export const CREATE_EXHIBITION_MEETINGS_USER_SUCCESS =
  "CREATE_EXHIBITION_MEETINGS_USER_SUCCESS";
export const CREATE_EXHIBITION_MEETINGS_USER_FAILURE =
  "CREATE_EXHIBITION_MEETINGS_USER_FAILURE";

export const DELETE_EXHIBITION_MEETINGS_USER_REQUEST =
  "DELETE_EXHIBITION_MEETINGS_USER_REQUEST";
export const DELETE_EXHIBITION_MEETINGS_USER_SUCCESS =
  "DELETE_EXHIBITION_MEETINGS_USER_SUCCESS";
export const DELETE_EXHIBITION_MEETINGS_USER_FAILURE =
  "DELETE_EXHIBITION_MEETINGS_USER_FAILURE";

// Exhibition Speaker
export const FETCHING_EXHIBITION_SPEAKERS_REQUEST =
  "FETCHING_EXHIBITION_SPEAKERS_REQUEST";
export const FETCHING_EXHIBITION_SPEAKERS_SUCCESS =
  "FETCHING_EXHIBITION_SPEAKERS_SUCCESS";
export const FETCHING_EXHIBITION_SPEAKERS_FAILURE =
  "FETCHING_EXHIBITION_SPEAKERS_FAILURE";

export const FETCHING_EXHIBITION_SPEAKER_REQUEST =
  "FETCHING_EXHIBITION_SPEAKER_REQUEST";
export const FETCHING_EXHIBITION_SPEAKER_SUCCESS =
  "FETCHING_EXHIBITION_SPEAKER_SUCCESS";
export const FETCHING_EXHIBITION_SPEAKER_FAILURE =
  "FETCHING_EXHIBITION_SPEAKER_FAILURE";

export const UPDATE_EXHIBITION_SPEAKER_REQUEST =
  "UPDATE_EXHIBITION_SPEAKER_REQUEST";
export const UPDATE_EXHIBITION_SPEAKER_SUCCESS =
  "UPDATE_EXHIBITION_SPEAKER_SUCCESS";
export const UPDATE_EXHIBITION_SPEAKER_FAILURE =
  "UPDATE_EXHIBITION_SPEAKER_FAILURE";

export const CREATE_EXHIBITION_SPEAKER_REQUEST =
  "CREATE_EXHIBITION_SPEAKER_REQUEST";
export const CREATE_EXHIBITION_SPEAKER_SUCCESS =
  "CREATE_EXHIBITION_SPEAKER_SUCCESS";
export const CREATE_EXHIBITION_SPEAKER_FAILURE =
  "CREATE_EXHIBITION_SPEAKER_FAILURE";

export const DELETE_EXHIBITION_SPEAKER_REQUEST =
  "DELETE_EXHIBITION_SPEAKER_REQUEST";
export const DELETE_EXHIBITION_SPEAKER_SUCCESS =
  "DELETE_EXHIBITION_SPEAKER_SUCCESS";
export const DELETE_EXHIBITION_SPEAKER_FAILURE =
  "DELETE_EXHIBITION_SPEAKER_FAILURE";

// Moderator
export const FETCHING_EXHIBITION_MODERATORS_REQUEST =
  "FETCHING_EXHIBITION_MODERATORS_REQUEST";
export const FETCHING_EXHIBITION_MODERATORS_SUCCESS =
  "FETCHING_EXHIBITION_MODERATORS_SUCCESS";
export const FETCHING_EXHIBITION_MODERATORS_FAILURE =
  "FETCHING_EXHIBITION_MODERATORS_FAILURE";

// Post
export const FETCHING_EXHIBITION_POSTS_REQUEST =
  "FETCHING_EXHIBITION_POSTS_REQUEST";
export const FETCHING_EXHIBITION_POSTS_SUCCESS =
  "FETCHING_EXHIBITION_POSTS_SUCCESS";
export const FETCHING_EXHIBITION_POSTS_FAILURE =
  "FETCHING_EXHIBITION_POSTS_FAILURE";

export const FETCHING_EXHIBITION_POST_REQUEST =
  "FETCHING_EXHIBITION_POST_REQUEST";
export const FETCHING_EXHIBITION_POST_SUCCESS =
  "FETCHING_EXHIBITION_POST_SUCCESS";
export const FETCHING_EXHIBITION_POST_FAILURE =
  "FETCHING_EXHIBITION_POST_FAILURE";

export const UPDATE_EXHIBITION_POST_REQUEST = "UPDATE_EXHIBITION_POST_REQUEST";
export const UPDATE_EXHIBITION_POST_SUCCESS = "UPDATE_EXHIBITION_POST_SUCCESS";
export const UPDATE_EXHIBITION_POST_FAILURE = "UPDATE_EXHIBITION_POST_FAILURE";

export const CREATE_EXHIBITION_POST_REQUEST = "CREATE_EXHIBITION_POST_REQUEST";
export const CREATE_EXHIBITION_POST_SUCCESS = "CREATE_EXHIBITION_POST_SUCCESS";
export const CREATE_EXHIBITION_POST_FAILURE = "CREATE_EXHIBITION_POST_FAILURE";

export const DELETE_EXHIBITION_POST_REQUEST = "DELETE_EXHIBITION_POST_REQUEST";
export const DELETE_EXHIBITION_POST_SUCCESS = "DELETE_EXHIBITION_POST_SUCCESS";
export const DELETE_EXHIBITION_POST_FAILURE = "DELETE_EXHIBITION_POST_FAILURE";

export const DELETE_ALL_POSTS_LOCAL = "DELETE_ALL_POSTS_LOCAL";

// Post Image
export const CREATE_POST_IMAGE_REQUEST = "CREATE_POST_IMAGE_REQUEST";
export const CREATE_POST_IMAGE_SUCCESS = "CREATE_POST_IMAGE_SUCCESS";
export const CREATE_POST_IMAGE_FAILURE = "CREATE_POST_IMAGE_FAILURE";

export const UPDATE_POST_IMAGE_REQUEST = "UPDATE_POST_IMAGE_REQUEST";
export const UPDATE_POST_IMAGE_SUCCESS = "UPDATE_POST_IMAGE_SUCCESS";
export const UPDATE_POST_IMAGE_FAILURE = "UPDATE_POST_IMAGE_FAILURE";

export const DELETE_POST_IMAGE_REQUEST = "DELETE_POST_IMAGE_REQUEST";
export const DELETE_POST_IMAGE_SUCCESS = "DELETE_POST_IMAGE_SUCCESS";
export const DELETE_POST_IMAGE_FAILURE = "DELETE_POST_IMAGE_FAILURE";

// Keynote Image
export const CREATE_KEYNOTE_IMAGE_REQUEST = "CREATE_KEYNOTE_IMAGE_REQUEST";
export const CREATE_KEYNOTE_IMAGE_SUCCESS = "CREATE_KEYNOTE_IMAGE_SUCCESS";
export const CREATE_KEYNOTE_IMAGE_FAILURE = "CREATE_KEYNOTE_IMAGE_FAILURE";

export const UPDATE_KEYNOTE_IMAGE_REQUEST = "UPDATE_KEYNOTE_IMAGE_REQUEST";
export const UPDATE_KEYNOTE_IMAGE_SUCCESS = "UPDATE_KEYNOTE_IMAGE_SUCCESS";
export const UPDATE_KEYNOTE_IMAGE_FAILURE = "UPDATE_KEYNOTE_IMAGE_FAILURE";

export const DELETE_KEYNOTE_IMAGE_REQUEST = "DELETE_KEYNOTE_IMAGE_REQUEST";
export const DELETE_KEYNOTE_IMAGE_SUCCESS = "DELETE_KEYNOTE_IMAGE_SUCCESS";
export const DELETE_KEYNOTE_IMAGE_FAILURE = "DELETE_KEYNOTE_IMAGE_FAILURE";

// Comment
export const FETCHING_EXHIBITION_POST_COMMENTS_REQUEST =
  "FETCHING_EXHIBITION_POST_COMMENTS_REQUEST";
export const FETCHING_EXHIBITION_POST_COMMENTS_SUCCESS =
  "FETCHING_EXHIBITION_POST_COMMENTS_SUCCESS";
export const FETCHING_EXHIBITION_POST_COMMENTS_FAILURE =
  "FETCHING_EXHIBITION_POST_COMMENTS_FAILURE";

export const FETCHING_EXHIBITION_POST_COMMENT_REQUEST =
  "FETCHING_EXHIBITION_POST_COMMENT_REQUEST";
export const FETCHING_EXHIBITION_POST_COMMENT_SUCCESS =
  "FETCHING_EXHIBITION_POST_COMMENT_SUCCESS";
export const FETCHING_EXHIBITION_POST_COMMENT_FAILURE =
  "FETCHING_EXHIBITION_POST_COMMENT_FAILURE";

export const UPDATE_EXHIBITION_POST_COMMENT_REQUEST =
  "UPDATE_EXHIBITION_POST_COMMENT_REQUEST";
export const UPDATE_EXHIBITION_POST_COMMENT_SUCCESS =
  "UPDATE_EXHIBITION_POST_COMMENT_SUCCESS";
export const UPDATE_EXHIBITION_POST_COMMENT_FAILURE =
  "UPDATE_EXHIBITION_POST_COMMENT_FAILURE";

export const CREATE_EXHIBITION_POST_COMMENT_REQUEST =
  "CREATE_EXHIBITION_POST_COMMENT_REQUEST";
export const CREATE_EXHIBITION_POST_COMMENT_SUCCESS =
  "CREATE_EXHIBITION_POST_COMMENT_SUCCESS";
export const CREATE_EXHIBITION_POST_COMMENT_FAILURE =
  "CREATE_EXHIBITION_POST_COMMENT_FAILURE";

export const DELETE_EXHIBITION_POST_COMMENT_REQUEST =
  "DELETE_EXHIBITION_POST_COMMENT_REQUEST";
export const DELETE_EXHIBITION_POST_COMMENT_SUCCESS =
  "DELETE_EXHIBITION_POST_COMMENT_SUCCESS";
export const DELETE_EXHIBITION_POST_COMMENT_FAILURE =
  "DELETE_EXHIBITION_POST_COMMENT_FAILURE";

export const CLEAR_EXHIBITION_POST_COMMENT = "CLEAR_EXHIBITION_POST_COMMENT";

// Conversation
export const FETCHING_EXHIBITION_CONVERSATIONS_REQUEST =
  "FETCHING_EXHIBITION_CONVERSATIONS_REQUEST";
export const FETCHING_EXHIBITION_CONVERSATIONS_SUCCESS =
  "FETCHING_EXHIBITION_CONVERSATIONS_SUCCESS";
export const FETCHING_EXHIBITION_CONVERSATIONS_FAILURE =
  "FETCHING_EXHIBITION_CONVERSATIONS_FAILURE";

export const FETCHING_EXHIBITION_CONVERSATION_REQUEST =
  "FETCHING_EXHIBITION_CONVERSATION_REQUEST";
export const FETCHING_EXHIBITION_CONVERSATION_SUCCESS =
  "FETCHING_EXHIBITION_CONVERSATION_SUCCESS";
export const FETCHING_EXHIBITION_CONVERSATION_FAILURE =
  "FETCHING_EXHIBITION_CONVERSATION_FAILURE";

export const UPDATE_EXHIBITION_CONVERSATION_REQUEST =
  "UPDATE_EXHIBITION_CONVERSATION_REQUEST";
export const UPDATE_EXHIBITION_CONVERSATION_SUCCESS =
  "UPDATE_EXHIBITION_CONVERSATION_SUCCESS";
export const UPDATE_EXHIBITION_CONVERSATION_FAILURE =
  "UPDATE_EXHIBITION_CONVERSATION_FAILURE";

export const CREATE_EXHIBITION_CONVERSATION_REQUEST =
  "CREATE_EXHIBITION_CONVERSATION_REQUEST";
export const CREATE_EXHIBITION_CONVERSATION_SUCCESS =
  "CREATE_EXHIBITION_CONVERSATION_SUCCESS";
export const CREATE_EXHIBITION_CONVERSATION_FAILURE =
  "CREATE_EXHIBITION_CONVERSATION_FAILURE";

export const DELETE_EXHIBITION_CONVERSATION_REQUEST =
  "DELETE_EXHIBITION_CONVERSATION_REQUEST";
export const DELETE_EXHIBITION_CONVERSATION_SUCCESS =
  "DELETE_EXHIBITION_CONVERSATION_SUCCESS";
export const DELETE_EXHIBITION_CONVERSATION_FAILURE =
  "DELETE_EXHIBITION_CONVERSATION_FAILURE";

export const CLEAR_EXHIBITION_CONVERSATION = "CLEAR_EXHIBITION_CONVERSATION";
export const SET_UNREAD_MESSAGES_COUNT = "SET_UNREAD_MESSAGES_COUNT";

// KeynoteConversation
export const FETCHING_EXHIBITION_KEYNOTE_CONVERSATIONS_REQUEST =
  "FETCHING_EXHIBITION_KEYNOTE_CONVERSATIONS_REQUEST";
export const FETCHING_EXHIBITION_KEYNOTE_CONVERSATIONS_SUCCESS =
  "FETCHING_EXHIBITION_KEYNOTE_CONVERSATIONS_SUCCESS";
export const FETCHING_EXHIBITION_KEYNOTE_CONVERSATIONS_FAILURE =
  "FETCHING_EXHIBITION_KEYNOTE_CONVERSATIONS_FAILURE";

export const FETCHING_EXHIBITION_KEYNOTE_CONVERSATION_REQUEST =
  "FETCHING_EXHIBITION_KEYNOTE_CONVERSATION_REQUEST";
export const FETCHING_EXHIBITION_KEYNOTE_CONVERSATION_SUCCESS =
  "FETCHING_EXHIBITION_KEYNOTE_CONVERSATION_SUCCESS";
export const FETCHING_EXHIBITION_KEYNOTE_CONVERSATION_FAILURE =
  "FETCHING_EXHIBITION_KEYNOTE_CONVERSATION_FAILURE";

export const UPDATE_EXHIBITION_KEYNOTE_CONVERSATION_REQUEST =
  "UPDATE_EXHIBITION_KEYNOTE_CONVERSATION_REQUEST";
export const UPDATE_EXHIBITION_KEYNOTE_CONVERSATION_SUCCESS =
  "UPDATE_EXHIBITION_KEYNOTE_CONVERSATION_SUCCESS";
export const UPDATE_EXHIBITION_KEYNOTE_CONVERSATION_FAILURE =
  "UPDATE_EXHIBITION_KEYNOTE_CONVERSATION_FAILURE";

export const CREATE_EXHIBITION_KEYNOTE_CONVERSATION_REQUEST =
  "CREATE_EXHIBITION_KEYNOTE_CONVERSATION_REQUEST";
export const CREATE_EXHIBITION_KEYNOTE_CONVERSATION_SUCCESS =
  "CREATE_EXHIBITION_KEYNOTE_CONVERSATION_SUCCESS";
export const CREATE_EXHIBITION_KEYNOTE_CONVERSATION_FAILURE =
  "CREATE_EXHIBITION_KEYNOTE_CONVERSATION_FAILURE";

export const DELETE_EXHIBITION_KEYNOTE_CONVERSATION_REQUEST =
  "DELETE_EXHIBITION_KEYNOTE_CONVERSATION_REQUEST";
export const DELETE_EXHIBITION_KEYNOTE_CONVERSATION_SUCCESS =
  "DELETE_EXHIBITION_KEYNOTE_CONVERSATION_SUCCESS";
export const DELETE_EXHIBITION_KEYNOTE_CONVERSATION_FAILURE =
  "DELETE_EXHIBITION_KEYNOTE_CONVERSATION_FAILURE";

export const CLEAR_EXHIBITION_KEYNOTE_CONVERSATION =
  "CLEAR_EXHIBITION_KEYNOTE_CONVERSATION";
export const SET_UNREAD_KEYNOTE_MESSAGES_COUNT =
  "SET_UNREAD_KEYNOTE_MESSAGES_COUNT";

// Conversation User
export const FETCHING_EXHIBITION_CONVERSATIONS_USERS_REQUEST =
  "FETCHING_EXHIBITION_CONVERSATIONS_USERS_REQUEST";
export const FETCHING_EXHIBITION_CONVERSATIONS_USERS_SUCCESS =
  "FETCHING_EXHIBITION_CONVERSATIONS_USERS_SUCCESS";
export const FETCHING_EXHIBITION_CONVERSATIONS_USERS_FAILURE =
  "FETCHING_EXHIBITION_CONVERSATIONS_USERS_FAILURE";

export const CREATE_EXHIBITION_CONVERSATIONS_USER_REQUEST =
  "CREATE_EXHIBITION_CONVERSATIONS_USER_REQUEST";
export const CREATE_EXHIBITION_CONVERSATIONS_USER_SUCCESS =
  "CREATE_EXHIBITION_CONVERSATIONS_USER_SUCCESS";
export const CREATE_EXHIBITION_CONVERSATIONS_USER_FAILURE =
  "CREATE_EXHIBITION_CONVERSATIONS_USER_FAILURE";

export const DELETE_EXHIBITION_CONVERSATIONS_USER_REQUEST =
  "DELETE_EXHIBITION_CONVERSATIONS_USER_REQUEST";
export const DELETE_EXHIBITION_CONVERSATIONS_USER_SUCCESS =
  "DELETE_EXHIBITION_CONVERSATIONS_USER_SUCCESS";
export const DELETE_EXHIBITION_CONVERSATIONS_USER_FAILURE =
  "DELETE_EXHIBITION_CONVERSATIONS_USER_FAILURE";

// Message
export const FETCHING_EXHIBITION_MESSAGES_REQUEST =
  "FETCHING_EXHIBITION_MESSAGES_REQUEST";
export const FETCHING_EXHIBITION_MESSAGES_SUCCESS =
  "FETCHING_EXHIBITION_MESSAGES_SUCCESS";
export const FETCHING_EXHIBITION_MESSAGES_FAILURE =
  "FETCHING_EXHIBITION_MESSAGES_FAILURE";

export const FETCHING_EXHIBITION_MESSAGE_REQUEST =
  "FETCHING_EXHIBITION_MESSAGE_REQUEST";
export const FETCHING_EXHIBITION_MESSAGE_SUCCESS =
  "FETCHING_EXHIBITION_MESSAGE_SUCCESS";
export const FETCHING_EXHIBITION_MESSAGE_FAILURE =
  "FETCHING_EXHIBITION_MESSAGE_FAILURE";

export const UPDATE_EXHIBITION_MESSAGE_REQUEST =
  "UPDATE_EXHIBITION_MESSAGE_REQUEST";
export const UPDATE_EXHIBITION_MESSAGE_SUCCESS =
  "UPDATE_EXHIBITION_MESSAGE_SUCCESS";
export const UPDATE_EXHIBITION_MESSAGE_FAILURE =
  "UPDATE_EXHIBITION_MESSAGE_FAILURE";

export const CREATE_EXHIBITION_MESSAGE_REQUEST =
  "CREATE_EXHIBITION_MESSAGE_REQUEST";
export const CREATE_EXHIBITION_MESSAGE_SUCCESS =
  "CREATE_EXHIBITION_MESSAGE_SUCCESS";
export const CREATE_EXHIBITION_MESSAGE_FAILURE =
  "CREATE_EXHIBITION_MESSAGE_FAILURE";

export const DELETE_EXHIBITION_MESSAGE_REQUEST =
  "DELETE_EXHIBITION_MESSAGE_REQUEST";
export const DELETE_EXHIBITION_MESSAGE_SUCCESS =
  "DELETE_EXHIBITION_MESSAGE_SUCCESS";
export const DELETE_EXHIBITION_MESSAGE_FAILURE =
  "DELETE_EXHIBITION_MESSAGE_FAILURE";

export const CLEAR_EXHIBITION_MESSAGE = "CLEAR_EXHIBITION_MESSAGE";
export const SET_UNREAD_MESSAGES_COUNT_MESSAGES =
  "SET_UNREAD_MESSAGES_COUNT_MESSAGES";

// Exhibition PostsLikes
export const FETCHING_EXHIBITION_POSTS_LIKES_REQUEST =
  "FETCHING_EXHIBITION_POSTS_LIKES_REQUEST";
export const FETCHING_EXHIBITION_POSTS_LIKES_SUCCESS =
  "FETCHING_EXHIBITION_POSTS_LIKES_SUCCESS";
export const FETCHING_EXHIBITION_POSTS_LIKES_FAILURE =
  "FETCHING_EXHIBITION_POSTS_LIKES_FAILURE";

export const CREATE_EXHIBITION_POSTS_LIKE_REQUEST =
  "CREATE_EXHIBITION_POSTS_LIKE_REQUEST";
export const CREATE_EXHIBITION_POSTS_LIKE_SUCCESS =
  "CREATE_EXHIBITION_POSTS_LIKE_SUCCESS";
export const CREATE_EXHIBITION_POSTS_LIKE_FAILURE =
  "CREATE_EXHIBITION_POSTS_LIKE_FAILURE";

export const DELETE_EXHIBITION_POSTS_LIKE_REQUEST =
  "DELETE_EXHIBITION_POSTS_LIKE_REQUEST";
export const DELETE_EXHIBITION_POSTS_LIKE_SUCCESS =
  "DELETE_EXHIBITION_POSTS_LIKE_SUCCESS";
export const DELETE_EXHIBITION_POSTS_LIKE_FAILURE =
  "DELETE_EXHIBITION_POSTS_LIKE_FAILURE";

// Exhibition CommentsLikes
export const FETCHING_EXHIBITION_COMMENTS_LIKES_REQUEST =
  "FETCHING_EXHIBITION_COMMENTS_LIKES_REQUEST";
export const FETCHING_EXHIBITION_COMMENTS_LIKES_SUCCESS =
  "FETCHING_EXHIBITION_COMMENTS_LIKES_SUCCESS";
export const FETCHING_EXHIBITION_COMMENTS_LIKES_FAILURE =
  "FETCHING_EXHIBITION_COMMENTS_LIKES_FAILURE";

export const CREATE_EXHIBITION_COMMENTS_LIKE_REQUEST =
  "CREATE_EXHIBITION_COMMENTS_LIKE_REQUEST";
export const CREATE_EXHIBITION_COMMENTS_LIKE_SUCCESS =
  "CREATE_EXHIBITION_COMMENTS_LIKE_SUCCESS";
export const CREATE_EXHIBITION_COMMENTS_LIKE_FAILURE =
  "CREATE_EXHIBITION_COMMENTS_LIKE_FAILURE";

export const DELETE_EXHIBITION_COMMENTS_LIKE_REQUEST =
  "DELETE_EXHIBITION_COMMENTS_LIKE_REQUEST";
export const DELETE_EXHIBITION_COMMENTS_LIKE_SUCCESS =
  "DELETE_EXHIBITION_COMMENTS_LIKE_SUCCESS";
export const DELETE_EXHIBITION_COMMENTS_LIKE_FAILURE =
  "DELETE_EXHIBITION_COMMENTS_LIKE_FAILURE";

// Visitors of Exhibiton

export const FETCHING_EXHIBITION_VISITORS_REQUEST =
  "FETCHING_EXHIBITION_VISITORS_REQUEST";
export const FETCHING_EXHIBITION_VISITORS_SUCCESS =
  "FETCHING_EXHIBITION_VISITORS_SUCCESS";
export const FETCHING_EXHIBITION_VISITORS_FAILURE =
  "FETCHING_EXHIBITION_VISITORS_FAILURE";

export const FETCHING_EXHIBITION_VISITOR_REQUEST =
  "FETCHING_EXHIBITION_VISITOR_REQUEST";
export const FETCHING_EXHIBITION_VISITOR_SUCCESS =
  "FETCHING_EXHIBITION_VISITOR_SUCCESS";
export const FETCHING_EXHIBITION_VISITOR_FAILURE =
  "FETCHING_EXHIBITION_VISITOR_FAILURE";

export const UPDATE_EXHIBITION_VISITOR_REQUEST =
  "UPDATE_EXHIBITION_VISITOR_REQUEST";
export const UPDATE_EXHIBITION_VISITOR_SUCCESS =
  "UPDATE_EXHIBITION_VISITOR_SUCCESS";
export const UPDATE_EXHIBITION_VISITOR_FAILURE =
  "UPDATE_EXHIBITION_VISITOR_FAILURE";

// Timeslots
export const FETCHING_EXHIBITION_TIMESLOTS_REQUEST =
  "FETCHING_EXHIBITION_TIMESLOTS_REQUEST";
export const FETCHING_EXHIBITION_TIMESLOTS_SUCCESS =
  "FETCHING_EXHIBITION_TIMESLOTS_SUCCESS";
export const FETCHING_EXHIBITION_TIMESLOTS_FAILURE =
  "FETCHING_EXHIBITION_TIMESLOTS_FAILURE";

export const CLEAR_EXHIBITION_TIMESLOTS = "CLEAR_EXHIBITION_TIMESLOTS";

// TicketsUsers
export const CREATE_TICKETS_USER_REQUEST = "CREATE_TICKETS_USER_REQUEST";
export const CREATE_TICKETS_USER_SUCCESS = "CREATE_TICKETS_USER_SUCCESS";
export const CREATE_TICKETS_USER_FAILURE = "CREATE_TICKETS_USER_FAILURE";

export const DELETE_TICKETS_USER_REQUEST = "DELETE_TICKETS_USER_REQUEST";
export const DELETE_TICKETS_USER_SUCCESS = "DELETE_TICKETS_USER_SUCCESS";
export const DELETE_TICKETS_USER_FAILURE = "DELETE_TICKETS_USER_FAILURE";

export const CLEAR_TICKETS_USER = "CLEAR_TICKETS_USER";

// Ticket Invitation
export const FETCHING_EXHIBITION_TICKET_INVITATIONS_REQUEST =
  "FETCHING_EXHIBITION_TICKET_INVITATIONS_REQUEST";
export const FETCHING_EXHIBITION_TICKET_INVITATIONS_SUCCESS =
  "FETCHING_EXHIBITION_TICKET_INVITATIONS_SUCCESS";
export const FETCHING_EXHIBITION_TICKET_INVITATIONS_FAILURE =
  "FETCHING_EXHIBITION_TICKET_INVITATIONS_FAILURE";

export const FETCHING_EXHIBITION_TICKET_INVITATION_REQUEST =
  "FETCHING_EXHIBITION_TICKET_INVITATION_REQUEST";
export const FETCHING_EXHIBITION_TICKET_INVITATION_SUCCESS =
  "FETCHING_EXHIBITION_TICKET_INVITATION_SUCCESS";
export const FETCHING_EXHIBITION_TICKET_INVITATION_FAILURE =
  "FETCHING_EXHIBITION_TICKET_INVITATION_FAILURE";

export const CREATE_EXHIBITION_TICKET_INVITATION_REQUEST =
  "CREATE_EXHIBITION_TICKET_INVITATION_REQUEST";
export const CREATE_EXHIBITION_TICKET_INVITATION_SUCCESS =
  "CREATE_EXHIBITION_TICKET_INVITATION_SUCCESS";
export const CREATE_EXHIBITION_TICKET_INVITATION_FAILURE =
  "CREATE_EXHIBITION_TICKET_INVITATION_FAILURE";

export const UPDATE_EXHIBITION_TICKET_INVITATION_REQUEST =
  "UPDATE_EXHIBITION_TICKET_INVITATION_REQUEST";
export const UPDATE_EXHIBITION_TICKET_INVITATION_SUCCESS =
  "UPDATE_EXHIBITION_TICKET_INVITATION_SUCCESS";
export const UPDATE_EXHIBITION_TICKET_INVITATION_FAILURE =
  "UPDATE_EXHIBITION_TICKET_INVITATION_FAILURE";

export const DELETE_EXHIBITION_TICKET_INVITATION_REQUEST =
  "DELETE_EXHIBITION_TICKET_INVITATION_REQUEST";
export const DELETE_EXHIBITION_TICKET_INVITATION_SUCCESS =
  "DELETE_EXHIBITION_TICKET_INVITATION_SUCCESS";
export const DELETE_EXHIBITION_TICKET_INVITATION_FAILURE =
  "DELETE_EXHIBITION_TICKET_INVITATION_FAILURE";

export const CLEAR_EXHIBITION_TICKET_INVITATION =
  "CLEAR_EXHIBITION_TICKET_INVITATION";

//Talking point
export const FETCHING_TALKING_POINTS_EXHIBITORS_REQUEST =
  "FETCHING_TALKING_POINTS_EXHIBITORS_REQUEST";
export const FETCHING_TALKING_POINTS_EXHIBITORS_SUCCESS =
  "FETCHING_TALKING_POINTS_EXHIBITORS_SUCCESS";
export const FETCHING_TALKING_POINTS_EXHIBITORS_FAILURE =
  "FETCHING_TALKING_POINTS_EXHIBITORS_FAILURE";

//Discussion room
export const FETCHING_DISCUSSION_ROOM_REQUEST =
  "FETCHING_DISCUSSION_ROOM_REQUEST";
export const FETCHING_DISCUSSION_ROOM_SUCCESS =
  "FETCHING_DISCUSSION_ROOM_SUCCESS";
export const FETCHING_DISCUSSION_ROOM_FAILURE =
  "FETCHING_DISCUSSION_ROOM_FAILURE";
