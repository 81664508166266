import { useContext } from "react";
import MyStore from "../../../utils/MyStore";
import GlobalContext from "../../layouts/Dashboard/context/GlobalContext";

export const EVENT_TRACKING_TYPES = {
  post: {
    salesmark: "Post Salesmark Set",
    postView: "Post Viewed",
    postLiked: "Post Liked",
    postUnliked: "Post Unliked",
    postCommented: "Post Commented",
    bookmark: "Post Bookmarked",
    unbookmark: "Post Bookmark Removed",
    scheduleMeeting: "Meeting Scheduling Opened",
    exhibitorOpened: "Post Exhibitor Opened",
    videoViewStarted: "Post Video View Started",
    videoViewEnded: "Post Video View Ended",
  },
  feed: {
    enteredFeed: "Entered Feed",
    leftFeed: "Left Feed",
    tagActivated: "Activated Tag",
    deactivatedTag: "Deactivated Tag",
    reachedFeedEnd: "Reached End Of Feed",//TO-DO implement
  },
  exhibitor: {
    salesmark: "Exhibitor Salesmark Set",
    exhibitorView: "Exhibitor Viewed",
    exhibitorTileViewed: "Exhibitor Tile Viewed",
    exhibitorOpened: "Exhibitor Tile Details Opened",
    bookmark: "Exhibitor Bookmarked",
    unbookmark: "Exhibitor Bookmark Removed",
    exhibitorStartChat: "Exhibitor Chat Started",
    leftExhibitor: "Exhibitor Left",
    enteredExhibitors: "Entered Exhibitors List",
    leftExhibitors: "Exhibitors List Left",
    videoStarted: "Exhibitor Video Started",
    videoPaused: "Exhibitor Video Paused",
    documentDownload: "Exhibitor Document Download",
    websiteOpened: "Exhibitor Website Opened",
    openedSocialLink: "Exhibitor Social Link Opened",
  },
  sponsors: {
    enteredSponsors: "Entered Sponsors List",
    leftSponsors: "Left Sponsors List",
    viewedSponsor: "Sponsor Viewed",
    leftSponsor: "Sponsor Left",
  },
  attendees: {
    viewAttendee: "Attendee Viewed",
    leftAttendee: "Attendee Left",
    viewAttendees: "Attendees List Viewed",
    leftAttendees: "Attendees List Left",
    attendeeDetailOpen: "Attendee Details Viewed",
  },
  login: {
    userLoggedIn: "User Logged In",
    loginFailed: "User Login Failed",
    noRights: "No Exhibition Rights",
    leftLogin: "Left Page",
  },
  registration: {
    registered: "User Registered",
    emailConfirmed: "User Confirmed Email",
    tagsChosen: "Registration Tags Chosen",
    bookmarkedExhibitors: "Registration Exhibitor Bookmarked",
    enteredWaitingScreen: "Entered Waiting Screen",
    forwardWaitingScreen: "Auto-forwarded On Waiting Screen",
    registrationFailed: "Registration Failed", //To be implemented
  },
  stage: {
    stageViewed: "Stage View Started",
    stageMuted: "Stage Muted",
    stagePaused: "Stage Paused",
    stageClosed: "Stage Closed",
    enteredAgendKeynote: "Started viewing Keynote",
    endedAgendKeynote: "Ended viewing Keynote",
  },
  keynote: {
    keynoteOpened: "Looked Up Keynote",
    reviewdKeynote: "Reviewed Keynote",
    lookedUpSpeaker: "Looked Up Speaker",
    lookedUpModerator: "Looked Up Moderator",
  },
  chat: {
    messageSent: "Message Sent",
    conversationStarted: "Conversation Started",
    messageSentGlobalChat: "Global Chat Message Sent",
  },
  profile: {
    visited: "Visited Profile",
    edited: "Edited Profile",
    picture: "Uploaded picture",
    choseTags: "Chose Tags",
  },
  discussionRoom: {
    openedRoom: "Discussion Room Opened",
    watchedVideo: "Discussion Room Watched Video",
  },
  ticket: {
    requestTicket: "Ticket Requested"
  }
};

export const VISIT_TRACKING_TYPES = {
  exhibitor: "Exhibitor Modal",
  feed: "Post Feed",
  exhibitors: "Exhibitors List",
  attendees: "Attendees List",
  attendee: "Attendee Modal",
  sponsors: "Sponsors List",
  mainStage: "Main Stage Full View",
  agenda: "Agenda viewed",
  keynote: "Keynote viewed",
  userProfile: "User Profile",
  userProfileEdit: "User Profile Edit",
  matchmaking: "Used Matchmaking",
  discussionRoom: "Discussion room viewed",
  premiumBanner: "Premium Banner Viewed",
  premiumBannerClicked: "Premium Banner Clicked",
  bottomBanner: "Bottom Banner Viewed",
  bottomBannerClicked: "Bottom Banner Clicked",
  talkingPointOpened: "Exhibitor Hall Opened",
};

var vis = (function(){
  var stateKey, eventKey, keys = {
      hidden: "visibilitychange",
      webkitHidden: "webkitvisibilitychange",
      mozHidden: "mozvisibilitychange",
      msHidden: "msvisibilitychange"
  };
  for (stateKey in keys) {
      if (stateKey in document) {
          eventKey = keys[stateKey];
          break;
      }
  }
  return function(c) {
      if (c) document.addEventListener(eventKey, c);
      return !document[stateKey];
  }
})();

function useTracking() {
  const context = useContext(GlobalContext);

  const trackEvent = (eventTrackingType, properties,) => {
    if (!eventTrackingType) {
      return;
    }

    let body;
    if (MyStore.getCurrentUser()) {
      if (properties) {
        body = {
          ...properties,
          muted: context?.muted,
          user: MyStore.getCurrentUser().getId(),
          email: MyStore.getCurrentUser().getEmail(),
          focus: vis(),
        };
      } else {
        body = {
          user: MyStore.getCurrentUser().getId(),
          email: MyStore.getCurrentUser().getEmail(),
          muted: context?.muted,
          focus: vis(),
        };
      }
    }

    body
      ? window.analytics.track(eventTrackingType, body)
      : window.analytics.track(eventTrackingType);
  };

  const trackVisit = (visitTrackingType, properties) => {
    if (!visitTrackingType) {
      return;
    }

    let body;
    if (MyStore.getCurrentUser()) {
      if (properties) {
        body = {
          ...properties,
          user: MyStore.getCurrentUser().getId(),
          email: MyStore.getCurrentUser().getEmail(),
        };
      } else {
        body = {
          user: MyStore.getCurrentUser().getId(),
          email: MyStore.getCurrentUser().getEmail(),
        };
      }
    }

    body
      ? window.analytics.track(visitTrackingType, body)
      : window.analytics.track(visitTrackingType);
  };

  return [trackEvent, trackVisit];
}

export default useTracking;
