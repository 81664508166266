import React, { Component } from "react";
import ThreadDetails from "../../../components/GlobalChat/ThreadDetails";
import MessageAdd from "../../../views/ChatView/MessageAdd";
import { Divider } from "@material-ui/core";

class Conversation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newMessage: "",
      conversationProxy: props.conversationProxy,
      messages: [],
      loadingState: "initializing",
      boundConversations: new Set(),
      countMessages: 30,
      hasPrevPage: true,
    };
  }

  loadMessagesFor = (thisConversation) => {
    if (this.state.conversationProxy === thisConversation) {
      thisConversation
        .getMessages()
        .then((messagePaginator) => {
          if (this.state.conversationProxy === thisConversation) {
            this.setState({
              messages: messagePaginator.items,
              loadingState: "ready",
            });
          }
        })
        .catch((err) => {
          console.error("Couldn't fetch messages IMPLEMENT RETRY", err);
          this.setState({ loadingState: "failed" });
        });
    }
  };

  readCurrentConversationMessage = async (lastMessageIndex) => {
    await this.state.conversationProxy.advanceLastReadMessageIndex(
      lastMessageIndex
    );
    await this.state.conversationProxy.updateLastReadMessageIndex(
      lastMessageIndex
    );
    await this.props.reloadNotifications();
  };

  loadMore = () => {
    if (this.state.hasPrevPage) {
      this.state.conversationProxy
        .getMessages(this.state.countMessages)
        .then((messagePaginator) => {
          messagePaginator.prevPage().then((messages) => {
            this.setState({
              messages: [...messages.items, ...this.state.messages],
              loadingState: "ready",
              countMessages: this.state.countMessages + 30,
              hasPrevPage: messages.hasPrevPage,
            });
          });
        })
        .catch((err) => {
          console.error("Couldn't fetch messages IMPLEMENT RETRY", err);
          this.setState({ loadingState: "failed" });
        });
    }
  };

  componentDidMount = () => {
    if (this.state.conversationProxy) {
      this.loadMessagesFor(this.state.conversationProxy);

      if (!this.state.boundConversations.has(this.state.conversationProxy)) {
        this.state.conversationProxy.on("messageAdded", this.handleMessageAdded);
        this.setState({
          boundConversations: new Set([
            ...this.state.boundConversations,
            this.state.conversationProxy,
          ]),
        });
      }
      if (this.state.conversationProxy.channelState.lastMessage) {
        this.readCurrentConversationMessage(
          this.state.conversationProxy.channelState.lastMessage.index
        );
      }
    }
  };

  componentDidUpdate = (oldProps, oldState) => {
    if (this.state.conversationProxy !== oldState.conversationProxy) {
      this.loadMessagesFor(this.state.conversationProxy);

      if (!this.state.boundConversations.has(this.state.conversationProxy)) {
        this.state.conversationProxy.on("messageAdded", this.handleMessageAdded);
        this.setState({
          boundConversations: new Set([
            ...this.state.boundConversations,
            this.state.conversationProxy,
          ]),
        });
      }
    }
    if (this.state.conversationProxy.channelState.lastMessage) {
      this.readCurrentConversationMessage(
        this.state.conversationProxy.channelState.lastMessage.index
      );
    }
    this.props.reloadNotifications();
  };

  componentWillUnmount = () => {
    this.state.conversationProxy.off("messageAdded", this.handleMessageAdded);
    this.state.conversationProxy.removeListener("messageAdded", this.handleMessageAdded);
  };

  static getDerivedStateFromProps(newProps, oldState) {
    let logic =
      oldState.loadingState === "initializing" ||
      oldState.conversationProxy !== newProps.conversationProxy;
    if (logic) {
      return {
        loadingState: "loading messages",
        conversationProxy: newProps.conversationProxy,
      };
    } else {
      return null;
    }
  }

  handleMessageAdded = (event) => {
    this.messageAdded(event, this.state.conversationProxy);
  }

  messageAdded = (message, targetConversation) => {
    if (targetConversation.sid === message.conversation.sid)
      this.setState((prevState, props) => ({
        messages: [...prevState.messages, message],
      }));
    this.readCurrentConversationMessage(message.state.index);
  };

  onMessageChanged = (event) => {
    this.setState({ newMessage: event.target.value });
  };

  sendMessage = async (value) => {
    const lastMessageIndex = await this.state.conversationProxy.sendMessage(
      value
    );
    this.readCurrentConversationMessage(lastMessageIndex);
  };

  render = () => {
    return (
      <div
        id="OpenChannel"
        style={{ position: "relative", top: 0, height: "100%" }}
      >
        {/* <div className={styles.messages}> */}
        <div className={this.props.nameClass}>
          <ThreadDetails
            loading={false}
            messages={this.state.messages}
            // autoScroll={autoScroll}
            loadMore={() => this.loadMore()}
            hasPrevPage={this.state.hasPrevPage}
          />
        </div>
        <Divider />
        <div>
          <MessageAdd
            sendMessage={(value) => this.sendMessage(value)}
            // conversation={conversation}
          />
        </div>
      </div>
    );
  };
}

export default Conversation;
