import {
  Button,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  LinearProgress,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/styles";
import React, { useCallback, useEffect, useState } from "react";
import { Trans } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Meeting from "../../../../../models/Meeting";
import {
  fetchMeeting,
  updateMeeting,
} from "../../../../../redux/actions/MeetingsActions";
import { compareIds } from "../../../../../utils";
import MyStore from "../../../../../utils/MyStore";
import MeetingRoom from "../../../../components/MeetingRoom";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    left: "4vw",
    top: "4vh",
    height: "92vh",
    width: "92vw",
    overflow: "auto",
    zIndex: "1000",
  },
  closed: {
    position: "absolute",
    left: 0,
    top: 0,
    height: 0,
    width: 0,
    transition: "width 0.2s, height 0.2s, opacity 0.2s 0.2s",
  },
  lobby: {},
}));

const MeetingView = ({ meetingId, closeMeeting }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const [username, setUsername] = useState();
  const [roomName, setRoomName] = useState();
  const [token, setToken] = useState();
  const [loading, setLoading] = useState(false);

  const meetings = useSelector((state) => state.meetingsReducer);
  const exhibitions = useSelector((state) => state.exhibitionsReducer);

  useEffect(() => {
    if (!exhibitions.exhibition || !meetingId) {
      return;
    }

    dispatch(fetchMeeting(exhibitions.exhibition.getId(), meetingId));
    setLoading(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exhibitions.currentSet, meetingId]);

  useEffect(() => {
    if (meetings.isFetching || !meetings.meeting || !meetingId) {
      if (meetings.isFetching || !loading) {
        setLoading(true);
      }
      return;
    }

    setLoading(false);

    const config = meetings.meeting.getTwilioConfiguration();

    if (!config || config.token === "") {
      if (
        meetings.meeting.getReason() === Meeting.reason.CHAT_ONE_TO_ONE &&
        compareIds(
          meetings.meeting.getUser().getId(),
          MyStore.getCurrentUser().getId()
        )
      ) {
        handleRoomEntrance();
        return;
      }
    } else {
      setRoomName(config.unique_room_name);
      setUsername(config.unique_name);
      setToken(config.token);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetings.isFetching]);

  const handleRoomEntrance = () => {
    const newMeeting = meetings.meeting;
    if (newMeeting.getMeetingStatus() === Meeting.meetingStatus.INITIALIZED) {
      newMeeting.setMeetingStatus(Meeting.meetingStatus.MEETING_STARTED);
    }
    dispatch(updateMeeting(newMeeting));
  };

  const handleLogout = useCallback((event) => {
    setToken(null);
    closeMeeting();
  }, []);

  return (
    <Card className={meetingId ? classes.root : classes.closed}>
      {loading && <LinearProgress />}
      <CardHeader
        title={
          <Typography variant="h3">
            {meetings.meeting ? meetings.meeting.getTitle() : ""}
          </Typography>
        }
        action={
          <IconButton onClick={() => handleLogout()}>
            <CloseIcon />
          </IconButton>
        }
      />
      <CardContent>
        {token ? (
          <MeetingRoom
            roomName={roomName}
            token={token}
            handleLogout={handleLogout}
            meetingReason={meetings.meeting.getReason()}
          />
        ) : (
          <div>
            <h2>
              <Trans>frontend.components.meetingRoom.welcome_text</Trans>
            </h2>

            <Button
              variant="contained"
              color="primary"
              style={{
                marginRight: "auto",
                marginTop: "24px",
                fontWeight: "bolder",
              }}
              onClick={() => handleRoomEntrance()}
            >
              <Trans>frontend.components.meetingRoom.enter_room</Trans>
            </Button>
          </div>
        )}
      </CardContent>
    </Card>
  );
};

export default MeetingView;
