import {
  SESSION_CREATE_SUCCESS,
  SESSION_CREATE_REQUEST,
  SESSION_CREATE_FAILURE,
  SESSION_DELETE_REQUEST,
  SESSION_DELETE_SUCCESS,
  SESSION_DELETE_FAILURE
} from './types';
import Urls from '../../constants/Urls';
import { STATUS_CODES } from '../../utils/NetworkRequest';
import MyStore, { CURRENT_USER, BEARER, CURRENT_EVENT_USER } from '../../utils/MyStore';
import ApplicationError from '../../utils/ApplicationError';
import UserMapper from '../../mapper/UserMapper';
import ErrorLogger from '../../utils/ErrorLogger';
import axios from "axios"
import EventUserMapper from '../../mapper/EventUserMapper';

export const sessionCreateRequest = () => ({
  type: SESSION_CREATE_REQUEST
})

export const sessionCreateSuccess = (user) => ({
  type: SESSION_CREATE_SUCCESS,
  payload: user
})
export const sessionCreateFailure = (error) => ({
  type: SESSION_CREATE_FAILURE,
  payload: error
})

export const sessionDeleteRequest = () => ({
  type: SESSION_DELETE_REQUEST
})

export const sessionDeleteSuccess = () => ({
  type: SESSION_DELETE_SUCCESS
})
export const sessionDeleteFailure = (error) => ({
  type: SESSION_DELETE_FAILURE,
  payload: error
})

export const createSession = (email, password, exhibitionId) => {
  return async dispatch => {
    dispatch(sessionCreateRequest());
    try {

      let route = Urls.sessions.login
      let headers = Urls.header.insecure()
      
      let response = await fetch(Urls.build(route.url()), {
        method: route.method,
        headers: headers,
        body: JSON.stringify({
            "identifier": email,
            "password": password
        }),
        credentials: "same-origin"
      });

      switch (response.status) {
        case STATUS_CODES.UNAUTHORIZED:
          dispatch(sessionCreateFailure(ApplicationError.buildUnauthorized()))
          break
        case STATUS_CODES.OK:
          let data = await response.json();
          let bearer = data.jwt//response.headers.get("Authorization");

          let json = data.user;
          let jsoneventuser = data.eventuser;
          localStorage.setItem(BEARER, bearer);
          let user = UserMapper.build(json);
          let eventuser = EventUserMapper.build(jsoneventuser);
          MyStore.setCurrentUser(user);
          MyStore.setCurrentEventUser(eventuser);
          dispatch(sessionCreateSuccess(user));
          break
        case STATUS_CODES.INTERNAL_SERVER_ERROR:
          localStorage.removeItem(BEARER)
          localStorage.removeItem(CURRENT_USER)
          localStorage.removeItem(CURRENT_EVENT_USER)
          dispatch(sessionDeleteFailure(ApplicationError.buildInternalServerError()))
          break
        default:
          localStorage.removeItem(BEARER)
          localStorage.removeItem(CURRENT_USER)
          localStorage.removeItem(CURRENT_EVENT_USER)
          let message = await response.json();
          if (message.message[0].messages[0].id === "Auth.form.error.confirmed") {
            dispatch(sessionDeleteFailure(ApplicationError.buildConfirmationError()))
          } else {
            dispatch(sessionDeleteFailure(ApplicationError.buildInternalServerError()))
          }
      }
    } catch (error) {
      ErrorLogger.call(this, error)
      dispatch(sessionCreateFailure(ApplicationError.buildApplicationError(error)))
    }
  };
};

export const createSessionMember = (email, exhibitionId) => {
  return async dispatch => {
    dispatch(sessionCreateRequest());
    try {

      let route = Urls.sessions.memberLogin;
      let headers = Urls.header.insecure();
      
      let response = await fetch(Urls.build(route.url()), {
        method: route.method,
        headers: headers,
        body: JSON.stringify({
            "identifier": email,
        }),
        credentials: "same-origin"
      });

      switch (response.status) {
        case STATUS_CODES.UNAUTHORIZED:
          dispatch(sessionCreateFailure(ApplicationError.buildUnauthorized()))
          break
        case STATUS_CODES.OK:
          let data = await response.json();
          let bearer = data.jwt//response.headers.get("Authorization");

          let json = data.user;
          let jsoneventuser = data.eventuser;
          localStorage.setItem(BEARER, bearer);
          let user = UserMapper.build(json);
          let eventuser = EventUserMapper.build(jsoneventuser);
          MyStore.setCurrentUser(user);
          MyStore.setCurrentEventUser(eventuser);
          dispatch(sessionCreateSuccess(user));
          break
        case STATUS_CODES.INTERNAL_SERVER_ERROR:
          localStorage.removeItem(BEARER)
          localStorage.removeItem(CURRENT_USER)
          dispatch(sessionDeleteFailure(ApplicationError.buildInternalServerError()))
          break
        default:
          localStorage.removeItem(BEARER)
          localStorage.removeItem(CURRENT_USER)
          let message = await response.json();
          if (message.message[0].messages[0].id === "Auth.form.error.confirmed") {
            dispatch(sessionDeleteFailure(ApplicationError.buildConfirmationError()))
          } else {
            dispatch(sessionDeleteFailure(ApplicationError.buildInternalServerError()))
          }
      }
    } catch (error) {
      ErrorLogger.call(this, error)
      dispatch(sessionCreateFailure(ApplicationError.buildApplicationError(error)))
    }
  };
};


export const deleteSession = () => {
  return async dispatch => {
    dispatch(sessionDeleteRequest());
    try {
      localStorage.removeItem(BEARER)
      localStorage.removeItem(CURRENT_USER)

      dispatch(sessionDeleteSuccess());
    } catch (error) {
      ErrorLogger.call(this, error)
      dispatch(sessionDeleteFailure(error))
    }
  };
};