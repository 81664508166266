import ApplicationError from "../../utils/ApplicationError";
import ResponseService from "../../utils/ResponseService";
import {
  FETCHING_TALKING_POINTS_EXHIBITORS_FAILURE,
  FETCHING_TALKING_POINTS_EXHIBITORS_REQUEST,
  FETCHING_TALKING_POINTS_EXHIBITORS_SUCCESS,
} from "../actions/types";

const initialState = {
  exhibitors: [],
  error: ApplicationError.buildDefault(),
  isFetching: false,
  ...ResponseService.defaultPaginationMetaData(),
};

const TalkingPointsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCHING_TALKING_POINTS_EXHIBITORS_REQUEST:
      return { ...state, isFetching: true };

    case FETCHING_TALKING_POINTS_EXHIBITORS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        exhibitors: action.payload.objects,
        error: ApplicationError.buildDefault(),
        ...action.payload.paginationData,
      };

    case FETCHING_TALKING_POINTS_EXHIBITORS_FAILURE:
      return { ...state, isFetching: false, error: action.payload };

    default:
      return state;
  }
};

export default TalkingPointsReducer;
