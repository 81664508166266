import ApplicationModel from "./ApplicationModel";

export default class Post extends ApplicationModel {
  constructor(_opts = {}) {
    super(_opts);
    this.state = { ..._opts };
  }

  getText = () => this.state.text;
  setText = (text) => (this.state.text = text);

  getUid = () => this.state.uid;
  getUserId = () => this.state.userId;
  getLikesCount = () => this.state.likesCount;

  getExhibitorId = () => this.state.exhibitorId;
  getExhibitionId = () => this.state.exhibitionId;

  getExhibitor = () => this.state.exhibitor;
  setExhibitor = (exhibitor) => (this.state.exhibitor = exhibitor);

  setExhibitorId = (id) => this.state.exhibitorId = id
  getExhibitorId = () => this.state.exhibitorId 

  getLikedByUser = () => this.state.likedByUser;

  getPostMedia = (language) => {
    if (this.state.localDescription && this.state.localDescription.length > 0 && language)  {
      let desc = this.state.localDescription.find(element => element.getLanguage() === language);
      if (desc && desc.getVideo()) {
        return desc.getVideo();
      }
      desc = this.state.localDescription.find(element => element.getLanguage() === "en");
      if (desc && desc.getVideo()) {
        return desc.getVideo();
      }
    }
    return this.state.postMedia
  };
  getPictures = () => this.state.pictures;

  getDocument = (index) => this.state.documents[index];
  getDocuments = () => this.state.documents;

  getLastComments = () => this.state.lastComments;
  getCommentsCount = () => this.state.commentCount;

  isBookmarked = () => this.state.isBookmarked;
  setIsBookmarked = (val) => (this.state.isBookmarked = val);
  
  isSalesmarked = () => this.state.isSalesmarked;
  setIsSalesmarked = (val) => (this.state.isSalesmarked = val);

  getBookmark = () => this.state.bookmark;
  setBookmark = (bookmark) => (this.state.bookmark = bookmark);

  hasDocument = () => this.state.document !== undefined;
  setDocument = (document) => this.state.document = document

  getVisibleAt = () => this.state.visibleAt;
  setVisibleAt = (visibleAt) => (this.state.visibleAt = visibleAt);

  getVisibleAt = () => this.state.visibleAt
  setVisibleAt = (visibleAt) => this.state.visibleAt = visibleAt

  isLast = () => this.state.isLast
  setIsLast = (value) => this.state.isLast = value

  getTitle = (language) => {
    if (this.state.localDescription && this.state.localDescription.length > 0 && language)  {
      let desc = this.state.localDescription.find(element => element.getLanguage() === language);
      if (desc && desc.getTitle()) {
        return desc.getTitle();
      }
      desc = this.state.localDescription.find(element => element.getLanguage() === "en");
      if (desc && desc.getTitle()) {
        return desc.getTitle();
      }
    }
    return this.state.title
  };
  setTitle = (title) => this.state.title = title

  setPostType = (postType) => this.state.postType = postType
  getPostType = () => this.state.postType

  getDescription = (language) => {
    if (this.state.localDescription && this.state.localDescription.length > 0 && language)  {
      let desc = this.state.localDescription.find(element => element.getLanguage() === language);
      if (desc && desc.getText()) {
        return desc.getText();
      }
      desc = this.state.localDescription.find(element => element.getLanguage() === "en");
      if (desc && desc.getText()) {
        return desc.getText();
      }
    }
    return this.state.text
  };


  className = "Post"

  // Backend Attributes
  static attributes = {
    ...super.attributes,
    uid: "uid",
    text: "text",
    userId: "user_id",
    likesCount: "like_count",
    commentCount: "comment_count",
    isBookmarked: 'bookmarked',
    document: 'document',
    title: 'title',
    postType: 'post_type',
    visibleAt: 'visible_at',
    bookmark: 'bookmark',
    exhibitor: "exhibitor",
    exhibitorId: "exhibitor_id",
    exhibitionId: "exhibition_id",
    postMedia: "post_media"
  };

  static orderByParams = {
    ...super.orderByParams,
    visibleAt: this.attributes.visibleAt,
  };

  static postTypes = {
    DEFAULT: "default",
    EXHIBITOR_ONLY: "exhibitor_only",
    SCHEDULE_ONLY: 'schedule_only',
    EXHIBITOR_VISIBLE_SCHEDULED: "exhibitor_visible_scheduled",
    NOT_EXHBITION: 'not_exhibition',
    BETWEEN_EXHIBITION_DAYS: 'between_exhibition_days'
  };

  static filterParams = {
    ...super.filterParams,
    exhibitorId: this.attributes.exhibitorId,
    userId: this.attributes.userId,
    postType: this.attributes.postType,
    text: this.attributes.text,
    search: '_q',
    scheduled: 'scheduled'
  };
}
