import ApplicationModel from "./ApplicationModel";

export default class Share extends ApplicationModel {
  constructor(_opts = {}) {
    super(_opts);
    this.state = { ..._opts };
  }

  getUserId = () => this.state.user.id;
  getSharableType = () => this.state.sharableType;
  getSharableId = () => this.state.sharableId;
  getExhibitionId = () => this.state.exhibitionId;
  getUser = () => this.state.user

  // Backend Attributes
  static attributes = {
    ...super.attributes,
    sharableType: "sharable_type",
    sharableId: "sharable_id",
    user: "user",
    exhibition: "exhibition"
  };

  static orderByParams = {
    ...super.orderByParams,
  };

  static filterParams = {
    ...super.filterParams,
  };
}
