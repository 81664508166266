import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import { Container, Typography, Divider, LinearProgress, Button } from "@material-ui/core";
import CommentBubble from "./CommentBubble";
import CommentForm from "./CommentForm";
import { useSelector, useDispatch } from "react-redux";
import { fetchComments } from "../../../redux/actions/CommentsActions";
import Comment from "../../../models/Comment";
import FilterParams from "../../../utils/FilterParams";
import {
  createComment,
  deleteComment,
} from "../../../redux/actions/CommentsActions";
import { Trans } from "react-i18next";
import { DEFAULT_PAGE_SIZE, DEFAULT_PAGE_INDEX } from "../../../constants/Urls";
import PublicCable from "../../../actioncable/PostCable";
import CogoToast from "../../../utils/CogoToast";
import CommentMapper from "../../../mapper/CommentMapper";
import MyStore from "../../../utils/MyStore";
import useExhibition from "../../../engines/GeneralHooks/useExhibition";
import PushNotification from "../../../models/PushNotification";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import FrontendRoutes from "../../../constants/FrontendRoutes";
import useCommentsQL from "../../../engines/GraphQL/hooks/useCommentsQL";
import useTracking, { EVENT_TRACKING_TYPES } from "../../engines/tracking/useTracking";
import { compareEntitiesById, compareIds } from "../../../utils";

const useStyles = makeStyles((theme) => ({
  root: {
    //paddingTop: theme.spacing(1),
    padding: 0,

  },
  addPost: {
    marginTop: theme.spacing(3),
  },
  posts: {
    marginTop: theme.spacing(3),
  },
  post: {
    marginBottom: theme.spacing(3),
  },
}));

function PostCommentSection({ postData, fullComments, currentPostId, setFullComments }) {
  const classes = useStyles();

  const dispatch = useDispatch();
  const [loadMore, setLoadMore] = useState(false);
  const [created, setCreated] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const [pageSize, setPageSize] = useState(3);
  const [pageIndex, setPageIndex] = useState(DEFAULT_PAGE_INDEX);
  const [writePermission, setWritePermission] = useState(false);
  const [initialized, setInitialized] = useState(false);
  const [incomingComment, setIncomingComment] = useState();
  const [exhibition] = useExhibition();
  const [trackEvent] = useTracking();
  const [comments, setComments] = useState([]);
  const [newComments, setNewComments] = useState(false)

  const [commentsQL, loading, totalPages, fetchNewData, clearState] = useCommentsQL(postData.getId(), pageIndex, pageSize)

  const usersReducer = useSelector(state => state.usersReducer);

  useEffect(() => {
    if (fullComments && commentsQL && commentsQL.length > 0) {
      setComments(commentsQL);
    }
  }, [commentsQL])

  useEffect(() => {
    if (deleted || !postData || !currentPostId) {
      return;
    }

    if (fullComments) {
      fetchNewData();
    } else {
      setNewComments(false);
      setPageIndex(DEFAULT_PAGE_INDEX);
      clearState();
      setComments(postData.getLastComments());
      //setInitialized(true);
    }
  }, [postData, fullComments, currentPostId]);

  const actionCableReducer = useSelector(state => state.actionCableReducer);
  useEffect(() => {
    if (!actionCableReducer.cable || !postData || !exhibition) { return }

    let publicCable = new PublicCable(actionCableReducer.cable, exhibition);
    publicCable.connect((data) => {
      if (data.type === PushNotification.NOTIFICATION_TYPES.commentUpdate) {
        const comment = CommentMapper.build(data.payload);
        const user = MyStore.getCurrentUser();
        if (comment.getPostId().toString() === postData.getId().toString()) {
          setIncomingComment(comment);
        }
      }
    })

    return () => {
    }
  }, [actionCableReducer.cable, postData, exhibition])

  useEffect(() => {
    if (!incomingComment) {
      return;
    }
    
    if (comments.filter(comment => compareEntitiesById(comment, incomingComment)).length === 0) {
      if (compareEntitiesById(incomingComment.getUser(), MyStore.getCurrentUser())) {
        setComments([incomingComment].concat(comments));
      } else {
        if (fullComments || comments.length > 1) {
          setNewComments(true);
        } else {
          setComments([incomingComment]);
        }
      }
    }
  }, [incomingComment])

  useEffect(() => {
    if (!usersReducer.currentUser) {
      return
    }

    setWritePermission(usersReducer.currentUser.hasWritePermissions())
  }, [usersReducer.currentUser])

  useEffect(() => {
    if (!loadMore) {
      return;
    }

    if (pageIndex < totalPages) {
      setPageIndex(pageIndex + 1);
    }
    setLoadMore(false);
  }, [loadMore])

  const loadNewComments = () => {
    setNewComments(false);
    setFullComments(true);
    setPageIndex(DEFAULT_PAGE_INDEX);
    fetchNewData();
  }

  const handleSubmit = (value, user) => {
    var comment = new Comment({
      exhibitionId: postData.getExhibitionId(),
      text: value,
      postId: postData.getId(),
      user: user,
    });

    dispatch(createComment(comment));
    trackEvent(EVENT_TRACKING_TYPES.post.postCommented, {
      postId: postData.getId(),
      message: value,
      exhibitor: postData.getExhibitor() ? postData.getExhibitor().getId() : "",
    })
    setCreated(true);
  };

  const handleDeleteComment = (comment) => {
    dispatch(deleteComment(comment));
    const items = comments.filter((item) => item.state.id !== comment.getId());
    setComments(items);
    setDeleted(true);
  };

  const handleLoadMore = () => {
    setLoadMore(true);
  }

  return (
    <Container maxWidth="lg" className={classes.root}>
      <CommentForm
        post={postData}
        disabled={!writePermission}
        handleSubmit={(value, user) => handleSubmit(value, user)}
      />
      {
        newComments && <div style={{
          marginLeft: "43%",
          marginBottom: "16px"
        }}>
          <Button
            color="secondary"
            variant="contained"
            style={{
              height: "20px",
              borderRadius: "10px",
              width: "130px",
              fontSize: "0.8em"
            }}
            size="small"
            onClick={loadNewComments}
          >
            <Trans>frontend.newComments</Trans>
          </Button>
        </div>
      }
      {comments.length > 0 ? (
        <div className={classes.comments}>
          {comments.map((comment) => (
            <CommentBubble
              comment={comment}
              disabled={!writePermission}
              handleDeleteComment={(comment) => handleDeleteComment(comment)}
              key={comment.getId()}
            />
          ))}
        </div>
      ) : loading ? (
        <LinearProgress />
      ) : (
            <Typography variant="body1" style={{ paddingBottom: "15px" }}>
              <Trans>
                {comments.length > 1
                  ? "frontend.loading"
                  : "frontend.first_comment"}
              </Trans>
            </Typography>
          )}
      {fullComments && comments && comments.length > 0 && totalPages > pageIndex && (
        <div style={{
          marginLeft: "47%",
          marginBottom: "16px"
        }}>
          <Button
            color="textPrimary"
            style={{
              height: "30px",
              borderRadius: "15px",
              width: "80px",
              fontSize: "0.6em"
            }}
            size="small"
            onClick={handleLoadMore}
          >
            <Trans>frontend.components.chat.load_more</Trans>
          </Button>
        </div>
      )}
      <Divider className={classes.divider} />
    </Container>
  );
}

export default PostCommentSection;
