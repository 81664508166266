const NOTIFICATIONS_CHANNEL = "private-pn-";

export default class PushNotificationCable {
  constructor(user, cable, exhibition) {
    this.state = {
      user: user,
      cable: cable,
      exhibition: exhibition
    };
  }

  disconnect = () => {
    if(!this.state.subscription)  { return }

    //this.state.cable.unsubscribe(NOTIFICATIONS_CHANNEL + this.state.user.getId() + "-" + this.state.exhibition.getId());
  };

  connect = (callback, connected = () => {}, disconnected = () => {}) => {
    if (this.state.subscription) {
      this.disconnect();
    }

    let subscription = this.state.cable.channel(NOTIFICATIONS_CHANNEL + this.state.user.getId() + "-" + this.state.exhibition.getId());
    if (!subscription) {
      subscription = this.state.cable.subscribe(NOTIFICATIONS_CHANNEL + this.state.user.getId() + "-" + this.state.exhibition.getId());
    }
    subscription.bind('pusher:subscription_succeeded', () => connected());
    subscription.bind("new-notification",data => callback(
      data
    ));

    this.state.subscription = subscription;
  };
}
