import Tag from "../models/Tag";
import LocalNameMapper from "./LocalNameMapper";

export default class TagMapper {
  static build = (json = undefined) => {
    if (!json) {
      return;
    }

    return new Tag({
      id: json.id,
      name: json.name,
      additional: json.additional,
      localName: LocalNameMapper.buildFromArray(json.local_name)
    });
  };

  static buildFromArray = (jsonArray = []) => {
    return jsonArray.map((entry) => {
      return this.build(entry);
    });
  };

  static toJsonArray(tags) {
    if (!tags || tags.lenght === 0) {
      return []
    }

    const arr = tags.map(tag => tag.state.id)

    return arr
  }
}
