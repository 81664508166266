import React, { useState, useEffect } from 'react'
import { DEFAULT_PAGE_INDEX } from '../../constants/Urls';

function useInfiniteScoll(container,totalPages,triggerBottom = true,loading) {
    const [loadMore, setLoadMore] = useState(false);
    const [currentPageIndex, setCurrentPageIndex] = useState(DEFAULT_PAGE_INDEX);
    const [isBottom, setIsBottom] = useState(false);

    useEffect(() => {
        if (loading) {
            return;
        }

        if (!container) {
            window.addEventListener("scroll", handleScrollWindow)
        } else {
            container.addEventListener('scroll', handleScroll);
        }
        
        return () => {
            if (!container) {
                window.addEventListener("scroll", handleScrollWindow)
            } else {
                container.removeEventListener('scroll', handleScroll);
            }
        }
    }, [container,loading])

    const handleScroll = () => {
        var scrollBottom = container.scrollTop + container.clientHeight;
        if (scrollBottom > container.scrollHeight - 25 && !loading) {
            setIsBottom(true);
        } else {
            setIsBottom(false);
        }
        
        if (triggerBottom) {
            if (scrollBottom > container.scrollHeight - 25) {
                setLoadMore(true);
            }
        } else {
            if (container.scrollTop === 0) {
                setLoadMore(true);
            }
        }
    }

    const handleScrollWindow = () => {
        var scrollBottom = window.innerHeight + document.documentElement.scrollTop >= document.documentElement.offsetHeight - 100;
        if (scrollBottom && !loading) {
            setIsBottom(true);
        } else {
            setIsBottom(false);
        }
        
        if (triggerBottom && scrollBottom) {
            setLoadMore(true);
        } else {
            if (document.documentElement.scrollTop === 0) {
                setLoadMore(true);
            }
        }
    }

    useEffect(() => {
        if (!loadMore) {
            return;
        }
        if (!loading && currentPageIndex < totalPages) {
            setCurrentPageIndex(currentPageIndex + 1);
        }
        setLoadMore(false);
    }, [loadMore])

    const resetIndex = () => {
        setCurrentPageIndex(DEFAULT_PAGE_INDEX);
    }

    return [currentPageIndex, isBottom, resetIndex, setIsBottom]
}

export default useInfiniteScoll
