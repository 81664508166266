import {
  FETCHING_EXHIBITION_NOTIFICATIONS_REQUEST,
  FETCHING_EXHIBITION_NOTIFICATIONS_SUCCESS,
  FETCHING_EXHIBITION_NOTIFICATIONS_FAILURE,
} from "./types";
import Urls, { DEFAULT_OPTIONAL_PARAMETERS } from "../../constants/Urls";
import { BEARER } from "../../utils/MyStore";
import ResponseService from "../../utils/ResponseService";
import ApplicationError from "../../utils/ApplicationError";
import ErrorLogger from "../../utils/ErrorLogger";
import NotificationMapper from "../../mapper/NotificationMapper";

const CLASS = "NotificationsActions";

export const fetchingNotificationsRequest = () => ({
  type: FETCHING_EXHIBITION_NOTIFICATIONS_REQUEST,
});

export const fetchingNotificationsSuccess = (json) => ({
  type: FETCHING_EXHIBITION_NOTIFICATIONS_SUCCESS,
  payload: json,
});
export const fetchingNotificationsFailure = (error) => ({
  type: FETCHING_EXHIBITION_NOTIFICATIONS_FAILURE,
  payload: error,
});

export const fetchNotifications = (
  exhibitionId,
  _opts = DEFAULT_OPTIONAL_PARAMETERS
) => {
  return async (dispatch) => {
    dispatch(fetchingNotificationsRequest());

    try {
      const route = Urls.exhibitions.notifications.all;
      const bearer = localStorage.getItem(BEARER);
      const headers = Urls.header.secure(bearer);
      const url = Urls.build(
        route.url(exhibitionId, _opts.pageIndex, _opts.pageSize),
        _opts.filters
      );

      let response = await fetch(url, {
        method: route.method,
        headers: headers,
      });

      let responseService = new ResponseService(NotificationMapper);

      let action = await responseService.call(
        response,
        (value) => fetchingNotificationsSuccess(value),
        (error) => fetchingNotificationsFailure(error)
      );

      dispatch(action);
    } catch (error) {
      ErrorLogger.call(this, error);
      dispatch(
        fetchingNotificationsFailure(
          ApplicationError.buildApplicationError(error, this)
        )
      );
    }
  };
};
