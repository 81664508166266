import SystemRole from "../models/SystemRole";
import BaseMapper from "./BaseMapper";
import Role from "../models/Role";

const attributes = SystemRole.attributes

export default class RoleMapper {
  static build = json => {
    if (!json) {
      return
    }
    return new Role({
      ...BaseMapper.attributes(json),
      name: json[attributes.name],
      type: json[attributes.type],
      description: json[attributes.description]
    });
  };

  static buildFromArray = (jsonArray = []) => {
    return jsonArray.map(entry => {
      return this.build(entry);
    });
  };

  static toJsonString = (role) => {
    return JSON.stringify({
      name: role.getName(),
      type: role.getType(),
      description: role.getDescription()
    });
  };
}
