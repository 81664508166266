import Message from "../models/Message";
import BaseMapper from "./BaseMapper";
import UserMapper from "./UserMapper";
import Conversation from "../models/Conversation";
import ConversationMapper from "./ConversationMapper";

const attributes = Message.attributes;

export default class MessageMapper {
  static build = (json) => {
    if (!json) {
      return;
    }

    return new Message({
      ...BaseMapper.attributes(json),
      me: json[attributes.me] || false,
      text: json[attributes.text],
      user: UserMapper.build(json.user),
      deleted: json[attributes.deleted],
      conversationId: json.conversation ? json.conversation.id:undefined,
      conversation: ConversationMapper.build(json.conversation),
      moderatorOnly: json.moderator_only,
    });
  };

  static buildFromArray = (jsonArray = []) => {
    return jsonArray.map((entry) => {
      return this.build(entry);
    });
  };

  static toJsonString(message) {
    
    return JSON.stringify({
      [attributes.conversationId]: message.getConversationId(),
      conversation: message.getConversation() ? message.getConversation().state:undefined,
      [attributes.receivers]: message.getReceivers() ? message.getReceivers().map(data => {return data ? data.state:undefined}):undefined,
      [attributes.deleted]: message.isDeleted(),
      [attributes.text]: message.getText(),
      [attributes.moderatorOnly]: message.getModeratorOnly(),
    });
  }
}
