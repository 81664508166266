import {
  FETCHING_EXHIBITION_TICKET_INVITATIONS_REQUEST,
  FETCHING_EXHIBITION_TICKET_INVITATIONS_SUCCESS,
  FETCHING_EXHIBITION_TICKET_INVITATIONS_FAILURE,
  
  FETCHING_EXHIBITION_TICKET_INVITATION_REQUEST,
  FETCHING_EXHIBITION_TICKET_INVITATION_SUCCESS,
  FETCHING_EXHIBITION_TICKET_INVITATION_FAILURE,

  CREATE_EXHIBITION_TICKET_INVITATION_FAILURE,
  CREATE_EXHIBITION_TICKET_INVITATION_SUCCESS,
  CREATE_EXHIBITION_TICKET_INVITATION_REQUEST,

  UPDATE_EXHIBITION_TICKET_INVITATION_FAILURE,
  UPDATE_EXHIBITION_TICKET_INVITATION_SUCCESS,
  UPDATE_EXHIBITION_TICKET_INVITATION_REQUEST,

  DELETE_EXHIBITION_TICKET_INVITATION_FAILURE,
  DELETE_EXHIBITION_TICKET_INVITATION_SUCCESS,
  DELETE_EXHIBITION_TICKET_INVITATION_REQUEST,
} from '../actions/types';
import ResponseService from '../../utils/ResponseService';
import ApplicationError from '../../utils/ApplicationError';

const initialState = {
  ticketInvitations: [],
  ticketInvitation: undefined,
  error: ApplicationError.buildDefault(),
  isFetching: false,
  ...ResponseService.defaultPaginationMetaData()
};

const ticketInvitationsReducer = (state = initialState, action ) => {
  switch(action.type) {
    case FETCHING_EXHIBITION_TICKET_INVITATIONS_REQUEST:
      return {...state, isFetching: true }
    case FETCHING_EXHIBITION_TICKET_INVITATIONS_SUCCESS:
      return {...state, isFetching: false, 
        ticketInvitations: action.payload.objects, 
        error: ApplicationError.buildDefault(),
        ...action.payload.paginationData }

    case FETCHING_EXHIBITION_TICKET_INVITATIONS_FAILURE:
      return {...state, isFetching: false, error: action.payload }
    
    case FETCHING_EXHIBITION_TICKET_INVITATION_REQUEST:
      return {...state, isFetching: true }
    case FETCHING_EXHIBITION_TICKET_INVITATION_SUCCESS: 
      return {...state, isFetching: false, ticketInvitation: action.payload, error: ApplicationError.buildDefault() }
    case FETCHING_EXHIBITION_TICKET_INVITATION_FAILURE:
      return {...state, isFetching: false, error: action.payload }  


    case  CREATE_EXHIBITION_TICKET_INVITATION_REQUEST:
      return {...state, isFetching: true }
    case  CREATE_EXHIBITION_TICKET_INVITATION_SUCCESS:
      return {...state, isFetching: false, ticketInvitation: action.payload, error: ApplicationError.buildDefault() }
    case  CREATE_EXHIBITION_TICKET_INVITATION_FAILURE:
      return {...state, isFetching: false, error: action.payload }

    case  UPDATE_EXHIBITION_TICKET_INVITATION_REQUEST:
      return {...state, isFetching: true }
    case  UPDATE_EXHIBITION_TICKET_INVITATION_SUCCESS:
      return {...state, isFetching: false, ticketInvitation: action.payload, error: ApplicationError.buildDefault() }
    case  UPDATE_EXHIBITION_TICKET_INVITATION_FAILURE:
      return {...state, isFetching: false, error: action.payload }

    case  DELETE_EXHIBITION_TICKET_INVITATION_REQUEST:
      return {...state, isFetching: true }
    case  DELETE_EXHIBITION_TICKET_INVITATION_SUCCESS:
      return {...state, isFetching: false, error: ApplicationError.buildDefault() }
    case  DELETE_EXHIBITION_TICKET_INVITATION_FAILURE:
      return {...state, isFetching: false, error: action.payload }


    default: 
      return state
  }
}

export default ticketInvitationsReducer;