import React, { useContext, useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';
import { Avatar, colors, Link, Typography, IconButton, Button, Grid, Menu, MenuItem, Input, TextField, Tooltip } from '@material-ui/core';
import frontendRoutes from "../../../constants/FrontendRoutes";
import MoreIcon from "@material-ui/icons/MoreHoriz"
import SendIcon from "@material-ui/icons/Send"
import { Trans } from 'react-i18next';
import { updateComment } from "../../../redux/actions/CommentsActions"
import { set } from 'immutable';
import Comment from '../../../models/Comment';
import MyStore from '../../../utils/MyStore';
import { INTERNAL_IDS } from "../../../models/Role";
import { createCommentsLike, deleteCommentsLike } from "../../../redux/actions/CommentsLikesActions"
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import GlobalContext from '../../layouts/Dashboard/context/GlobalContext';
import { MODAL_TYPE } from '../../layouts/Dashboard/components/GlobalModal';
import PushNotification from '../../../models/PushNotification';
import CommentsLikeMapper from '../../../mapper/CommentsLikeMapper';
import useExhibition from '../../engines/GeneralHooks/useExhibition';
import PublicCable from '../../../actioncable/PostCable';
import { compareIds } from '../../../utils';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    marginBottom: theme.spacing(2)
  },
  bubble: {
    flexGrow: 1,
    padding: theme.spacing(1),
    marginLeft: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.default
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    minHeight: 30
  },
  time: {
    marginLeft: 'auto',
    marginRight: "4px"
  },
  message: {
    marginTop: theme.spacing(1),
    width: "90%",
    fontSize: "1em",
  },
  likeButton: {},
  likedButton: {
    color: colors.red[600]
  },
}));

const ITEM_HEIGHT = 32;

function CommentBubble({ disabled, comment, handleDeleteComment, className, ...rest }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const context = useContext(GlobalContext);

  var commentObject = {
    image: comment.getUser() && comment.getUser().getProfilePicture() ? 
      comment.getUser().getProfilePicture().getUrl("thumbnail"):"",
    message: comment.getText(),
    userId: comment.getUser().getId(),
    created_at: comment.getRawCreatedAt(),
    author: comment.getUser().getFullName()
  }

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [edit, setEdit] = useState(false);
  const [message, setMessage] = useState(comment.getText());
  const [liked, setLiked] = useState(comment.getLike() !== undefined || comment.getLikedByUser());
  const [likes, setLikes] = useState(comment.getLikesCount());

  const user = MyStore.getCurrentUser();
  const isMe = user ? compareIds(user.getId(),comment.getUserId()) : null;

  const exhibitions = useSelector(state => state.exhibitionsReducer);
  const [exhibition] = useExhibition();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleEditComment = () => {
    setAnchorEl(null);
    setEdit(true);
  };

  const handleSubmitEdit = (value) => {
    var newComment = new Comment({
      exhibitionId: exhibitions.exhibition.getId(),
      text: message,
      postId: comment.getPostId(),
      user: comment.getUser(),
      userId: comment.getUserId(),
      id: comment.getId()
    });

    dispatch(updateComment(newComment));
    setEdit(false);
  }

  const handleDelete = () => {
    setAnchorEl(null);
    handleDeleteComment(comment);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMessageChange = (value) => {
    setMessage(value.target.value);
  }

  const handleLike = () => {
    if(disabled) { return }

    dispatch(createCommentsLike(comment));
    setLiked(true);
    setLikes((prevLikes) => prevLikes + 1);
  };

  const handleUnlike = () => {
    if(disabled) { return }

    dispatch(deleteCommentsLike(comment.getLike()));
    setLiked(false);
    setLikes((prevLikes) => prevLikes - 1);
  };

  const [incomingLike, setIncomingLike] = useState();
  const actionCableReducer = useSelector(state => state.actionCableReducer);
  useEffect(() => {
    if(!actionCableReducer.cable || !comment || !exhibition) { return }
    
    let publicCable = new PublicCable(actionCableReducer.cable, exhibition);
    publicCable.connect((data) => {
      if (data.type === PushNotification.NOTIFICATION_TYPES.commentLikeCreate || data.type === PushNotification.NOTIFICATION_TYPES.commentLikeDelete) {
        const commentLike = CommentsLikeMapper.build(data.payload);
        const user = MyStore.getCurrentUser();
        if (user && commentLike.getUserId() !== user.getId() && commentLike.getCommentId() === comment.getId()) {
          setIncomingLike({payload: commentLike, method: data.type});
        }
      }
    })

    return () => {
    }
  }, [actionCableReducer.cable, comment, exhibition])

  useEffect(() => {
    if (!incomingLike) {
      return;
    }

    if (incomingLike.type === PushNotification.NOTIFICATION_TYPES.commentLikeCreate) {
      setLikes(likes + 1);
    } else if (incomingLike.type === PushNotification.NOTIFICATION_TYPES.commentLikeDelete) {
      setLikes(likes - 1);
    }
  }, [incomingLike])

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Avatar
        alt="Person"
        component={RouterLink}
        src={commentObject.image}
        to={frontendRoutes.visitors.show(commentObject.userId)}
      />
      <div className={classes.bubble}>
        <div className={classes.header}>
          <Link
            color="textPrimary"
            component={RouterLink}
            onClick={() => context.openGlobalModal(MODAL_TYPE.USER_MODAL,comment.getUserId())}
            variant="h6"
          >
            {commentObject.author}
          </Link>
          <Typography
            className={classes.time}
            variant="body2"
          >
            {moment(commentObject.created_at).fromNow()}
          </Typography>
          {(user && (isMe || user.getRole().getName() === (INTERNAL_IDS.AUTHENTICATED)) && !disabled) && (
            <div>
              <IconButton size="small" onClick={handleClick}><MoreIcon /></IconButton>
              <Menu
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                PaperProps={{
                  style: {
                    maxHeight: ITEM_HEIGHT * 4.5,
                    width: '20ch',
                  },
                }}
              >
                <MenuItem key={"edit"} onClick={handleEditComment}>
                  <Trans>frontend.edit</Trans>
                </MenuItem>
                <MenuItem key={"delete"} onClick={(handleDelete)}>
                  <Trans>frontend.delete</Trans>
                </MenuItem>
              </Menu>
            </div>
          )}
          {liked ? (
            <Tooltip title="Unlike">
              <IconButton
                className={classes.likedButton}
                onClick={handleUnlike}
                size="small"
              >
                <FavoriteIcon />
              </IconButton>
            </Tooltip>
          ) : (
              <Tooltip title="Like">
                <IconButton
                  className={classes.likeButton}
                  onClick={handleLike}
                  size="small"
                  disabled={disabled}
                >
                  <FavoriteBorderIcon />
                </IconButton>
              </Tooltip>
            )}
          <Typography
            color="textSecondary"
            variant="h6"
          >
            {likes}
          </Typography>
        </div>
        {edit ? (<div>
          <TextField className={classes.message} defaultValue={message} multiline onChange={handleMessageChange} />
          <IconButton style={{ width: "10%" }} onClick={handleSubmitEdit}><SendIcon /></IconButton>
        </div>) : (
            <Typography
              className={classes.message}
              variant="body1"
            >
              {message}
            </Typography>
          )}
      </div>
    </div>
  );
}

CommentBubble.propTypes = {
  className: PropTypes.string,
  comment: PropTypes.object.isRequired
};

export default CommentBubble;
