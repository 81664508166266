import { useLazyQuery, useQuery } from '@apollo/client';
import React, { useContext, useEffect, useState } from 'react'
import { DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE } from '../../../constants/Urls';
import useExhibition from '../../../frontend/engines/GeneralHooks/useExhibition';
import GlobalContext from '../../../frontend/layouts/Dashboard/context/GlobalContext';
import BookmarkMapper from '../../../mapper/BookmarkMapper';
import VisitorMapper from '../../../mapper/UserMapper';
import MyStore from '../../../utils/MyStore';
import visitorQueries from '../queries/visitorQueries';
import { getOnlineVisitorQueries } from '../queries/visitorQueries';

export function useGetOnlineVisitorsQL(userIds) {
    const [onlineVisitors, setOnlineVisitors] = useState([]);
    const [exhibition] = useExhibition();

    const { data, error } = useQuery(getOnlineVisitorQueries.feedQuery, {
        variables: {
            userIds: userIds,
            exhibitionId: exhibition ? exhibition.getId() : "",
        },
        fetchPolicy: "no-cache"
    })

    useEffect(() => {
        if (!data || !data.eventusers || error) {
            return
        }
        setOnlineVisitors(data.eventusers.map(visitor => {
            var u = JSON.parse(JSON.stringify(visitor.user)); 
            if (!u) {
                return
            }
            u.eventuser_id = visitor.id;
            u.online = visitor.online
            return u;
        }))

    }, [data])

    
    return [onlineVisitors]
}

function useVisitorsQL(searchText, pageIndex = DEFAULT_PAGE_INDEX, pageSize = DEFAULT_PAGE_SIZE, showBookmarks = false, loadAlways = false) {
    const [totalPages, setTotalPages] = useState(1);
    const [visitors, setVisitors] = useState([]);
    const [exhibition] = useExhibition();
    const context = useContext(GlobalContext);

    const [fetchData, {loading, data, error, refetch}] = useLazyQuery(visitorQueries.feedQuery, {
        variables: {
            userId: MyStore.getCurrentUser() ? MyStore.getCurrentUser().getId() : undefined,
            bmUserId: showBookmarks && MyStore.getCurrentUser() ? MyStore.getCurrentUser().getId() : undefined,
            tags: context && context.tags ? context.tags.map(tag => tag.getName()) : [],
            start: ((pageIndex - 1) * pageSize),
            limit: pageSize,
            exhibitionId: exhibition ? exhibition.getId():"",
            searchText: searchText,
        },
        fetchPolicy: "no-cache"
    })

    useEffect(() => {
        if (loadAlways || (context.tabIndex === 2 && context.loadingStage > 0)) {
            fetchData();
        }
    }, [context.tabIndex, context.loadingStage])

    useEffect(() => {
        if (visitors && refetch) {
            refetch();
        }
    }, [showBookmarks])

    useEffect(() => {
        if (!data || !data.eventusers || error) {
            return
        }
        if (pageIndex === DEFAULT_PAGE_INDEX) {
            setVisitors(VisitorMapper.buildFromArray(data.eventusers.map(visitor => {
                var u = JSON.parse(JSON.stringify(visitor.user)); 
                if (!u) {
                    return
                }
                u.tags = visitor.tags;
                u.eventuser_id = visitor.id;
                u.online = visitor.online
                return u;
            }), BookmarkMapper.buildFromArray(data.bookmarks)));
        } else {
            setVisitors(visitors.concat(VisitorMapper.buildFromArray(data.eventusers.map(visitor => {
                var u = JSON.parse(JSON.stringify(visitor.user)); 
                if (!u) {
                    return
                }
                u.tags = visitor.tags;
                u.eventuser_id = visitor.id;
                u.online = visitor.online
                return u;
            }), BookmarkMapper.buildFromArray(data.bookmarks))))
        }

        if (data.eventusersConnection) {
            setTotalPages(Math.floor(data.eventusersConnection.aggregate.count/pageSize)+1)
        }
    }, [data])

    const refetchBookmarks = () => refetch ? refetch():fetchData();
    const fetchNewData = () => fetchData();
    
    return [visitors, loading, totalPages, refetchBookmarks, fetchNewData]
}

export function useVisitorQL(visitorId) {    
    const [visitor, setVisitor] = useState();
    const [exhibition] = useExhibition();

    const [ fetchData, {loading, data, error, refetch}] = useLazyQuery(visitorQueries.entityQuery, {
        variables: {
            userId: MyStore.getCurrentUser() ? MyStore.getCurrentUser().getId() : undefined,
            visitorId: visitorId,
            exhibitionId: exhibition ? exhibition.getId():"",
        }
    })

    useEffect(() => {
        if (!visitorId || !exhibition) {
            return;
        }
        fetchData();
    }, [visitorId, exhibition])

    useEffect(() => {
        if (!data || !data.user || data.error) {
            return
        }
        var u = JSON.parse(JSON.stringify(data.user)); 
        u.tags = data.eventusers && data.eventusers.length > 0 ? data.eventusers[0].tags:[];
        u.eventuser_id = data.eventusers && data.eventusers.length > 0 ? data.eventusers[0].id:undefined;
        setVisitor(VisitorMapper.build(u));
    }, [data])

    const fetchNewData = () => refetch() || fetchData();
    
    return [visitor, loading, fetchNewData]
}

export function useVisitorByUuidQL(visitorUuid) {    
    const [visitor, setVisitor] = useState();
    const [exhibition] = useExhibition();

    const [ fetchData, {loading, data, error, refetch}] = useLazyQuery(visitorQueries.entityUuidQuery, {
        variables: {
            userId: MyStore.getCurrentUser() ? MyStore.getCurrentUser().getId() : undefined,
            visitorUuid: visitorUuid,
            exhibitionId: exhibition ? exhibition.getId():"",
        }
    })

    useEffect(() => {
        if (!visitorUuid || !exhibition) {
            return;
        }
        fetchData();
    }, [visitorUuid, exhibition])

    useEffect(() => {
        if (!data || !data.users || data.error) {
            return
        }
        var u = JSON.parse(JSON.stringify(data.users[0])); 
        setVisitor(VisitorMapper.build(u));
    }, [data])

    const fetchNewData = () => refetch() || fetchData();
    
    return [visitor, loading, fetchNewData]
}

export function useVisitorByEmail(visitorEmail) {    
    const [visitor, setVisitor] = useState();
    const [exhibition] = useExhibition();

    const [ fetchData, {loading, data, error, refetch}] = useLazyQuery(visitorQueries.entityEmailQuery, {
        variables: {
            userId: MyStore.getCurrentUser() ? MyStore.getCurrentUser().getId() : undefined,
            visitorEmail: visitorEmail,
            exhibitionId: exhibition ? exhibition.getId():"",
        },
        fetchPolicy: "no-cache"
    })

    useEffect(() => {
        if (!visitorEmail || !exhibition) {
            if (visitor) {
                setVisitor(undefined);
            }
            return;
        }
        fetchData();
    }, [visitorEmail, exhibition])

    useEffect(() => {
        if (!data || !data.users || data.error || !visitorEmail) {
            return
        }
        var u = JSON.parse(JSON.stringify(data.users[0])); 
        setVisitor(VisitorMapper.build(u));
    }, [data])

    const fetchNewData = () => {
        if (!visitorEmail) {
            return;
        }
        refetch() || fetchData();
    }
    
    return [visitor, loading, fetchNewData]
}

export default useVisitorsQL
