import BaseMapper from "./BaseMapper";
import ImageMapper from "./ImageMapper";
import ConversationMapper from "./ConversationMapper";
import LocalTitle from "../models/LocalTitle";

const attributes = LocalTitle.attributes

export default class LocalTitleMapper {
  static build = json => {
    if (!json) {
      return
    }
    return new LocalTitle({
      ...BaseMapper.attributes(json),
      name: json[attributes.name],
      language: json[attributes.language],
      header: ImageMapper.build(json[attributes.header]),
    });
  };

  static buildFromArray = (jsonArray = []) => {
    if (!jsonArray) {
      return
    }
    return jsonArray.map(entry => {
      return this.build(entry);
    });
  };

  static toJsonString = (localTitle) => {
    return JSON.stringify({
      text: localTitle.getText(),
      language: localTitle.getLanguage(),
      image: ImageMapper.toJsonString(localTitle.getImage()),
    });
  };
}
