import ApplicationModel from "./ApplicationModel";
export default class DiscussionRoom extends ApplicationModel {
  constructor(_opts = {}) {
    super(_opts);
    this.state = { ..._opts };
  }

  getName = () => this.state.name;
  getUrl = () => this.state.url;
  getLanguage = () => this.state.language;
  getHeaderVideoUrl = () => this.state.headerVideo.getUrl();
  getHint = () => this.state.hint;

  static attributes = {
    name: "name",
    url: "url",
    language: "language",
    hint: "hint"
    // header_video: "header_video",
  };
}
