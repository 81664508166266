import {
    FETCHING_EXHIBITION_ACTIVE_KEYNOTES_REQUEST,
    FETCHING_EXHIBITION_ACTIVE_KEYNOTES_SUCCESS,
    FETCHING_EXHIBITION_ACTIVE_KEYNOTES_FAILURE,  
  } from './types';
  import Urls, { DEFAULT_OPTIONAL_PARAMETERS } from '../../constants/Urls';
  import { BEARER } from '../../utils/MyStore';
  import ApplicationError from '../../utils/ApplicationError';
  import ResponseService from '../../utils/ResponseService';
  import KeynoteMapper from '../../mapper/KeynoteMapper';
  import ErrorLogger from '../../utils/ErrorLogger';
import FilterParams from '../../utils/FilterParams';
import Keynote from '../../models/Keynote';
  
  export const fetchingKeynotesRequest = () => ({
    type: FETCHING_EXHIBITION_ACTIVE_KEYNOTES_REQUEST
  })
  
  export const fetchingKeynotesSuccess = (json) => ({
    type: FETCHING_EXHIBITION_ACTIVE_KEYNOTES_SUCCESS,
    payload: json
  })
  export const fetchingKeynotesFailure = (error) => ({
    type: FETCHING_EXHIBITION_ACTIVE_KEYNOTES_FAILURE,
    payload: error
  })
  
  export const fetchActiveKeynotes = (exhibitionId, _opts = DEFAULT_OPTIONAL_PARAMETERS) => {
    return async dispatch => {
      dispatch(fetchingKeynotesRequest());
      
      try {
        const route   = Urls.exhibitions.keynotes.all
        const bearer  = localStorage.getItem(BEARER)
        const headers = Urls.header.secure(bearer)
        const url     = Urls.build(route.url(exhibitionId, _opts.pageIndex, _opts.pageSize), [FilterParams.filterBy(Keynote.filterParams.running, true)])
  
        let response = await fetch(url, {
          method: route.method,
          headers: headers
        });
  
        let responseService = new ResponseService(KeynoteMapper)
  
        let action = await responseService.call(response, 
          (value) => fetchingKeynotesSuccess(value),
          (error) => fetchingKeynotesFailure(error))
  
        dispatch(action)
      } catch(error) {
        ErrorLogger.call(this, error)
        dispatch(fetchingKeynotesFailure(ApplicationError.buildApplicationError(error)))
      }
    };
  };