import ApplicationModel from "./ApplicationModel";
import MyStore from "../utils/MyStore";
import { compareIds } from "../utils";

export default class Message extends ApplicationModel {
  constructor(_opts = {}) {
    super(_opts);
    this.state = { ..._opts };
  }

  getText = () => this.state.text;
  getUser = () => this.state.user;
  getReceivers = () => this.state.receivers
  isMe = () => {
    return compareIds(this.state.user.getId(),MyStore.getCurrentUser().getId());
  };
  setIsMe = (me) => this.state.me = me;
  getExhibitionId = () => this.state.exhibitionId
  getConversation = () => this.state.conversation
  getConversationId = () => this.state.conversationId
  
  isDeleted = () => this.state.deleted
  getModeratorOnly = () => this.state.moderatorOnly

  // Backend Attributes
  static attributes = {
    ...super.attributes,
    me: "me",
    text: "text",
    user: "user",
    deleted: "deleted",
    receivers: 'receivers',
    conversationId: "conversation_id",
    moderatorOnly: "moderator_only",
  };

  static orderByParams = {
    ...super.orderByParams,
  };

  static filterParams = {
    ...super.filterParams,
    conversationId: "conversation.id",
    superuser: 'superuser',
    text: this.attributes.text,
  };
}
