import Exhibition from "../models/Exhibition";
import BaseMapper from "./BaseMapper";
import ConversationMapper from "./ConversationMapper";
import ExhibitorMapper from "./ExhibitorMapper";
import ImageMapper from "./ImageMapper";
import StageMapper from "./StageMapper";
import TagMapper from "./TagMapper";
import TalkingPointMapper from "./TalkingPointMapper";
import TermsMapper from "./TermsMapper";
import UserMapper from "./UserMapper";

const attributes = Exhibition.attributes;

export default class ExhibitionMapper {
  static build = (json = undefined) => {
    if (!json) {
      return;
    }

    return new Exhibition({
      ...BaseMapper.attributes(json),
      banners: json.banners,
      upBanners: json.upBanners,
      openingHour: json.opening_hour,
      name: json[attributes.name],
      shortKey: json[attributes.shortKey],
      subtitle: json[attributes.subtitle],
      description: json[attributes.description],
      startAt: new Date(json[attributes.startAt]),
      endAt: new Date(json[attributes.endAt]),
      profilePicture: ImageMapper.build(json.profile_picture),
      headerPicture: ImageMapper.build(json.header_picture),
      registeredUrl: json[attributes.registeredUrl],
      globalData: json.global_data || {},
      timeslotLengthMin: json.timeslot_length_min,
      conversation: ConversationMapper.build(json.conversation),
      exhibitionType: json.exhibition_type,
      users: UserMapper.buildFromArray(json.users),
      bookingUrl: json.booking_url,
      stages: StageMapper.buildFromArray(json.stages),
      tags: TagMapper.buildFromArray(json.tags),
      organizerLink: json.organizer_link,
      organizerLinkImage: ImageMapper.build(json.organizer_link_image),
      mainExhibitor: ExhibitorMapper.build(json.main_exhibitor),
      terms: TermsMapper.build(json.term),
      talkingPoints: TalkingPointMapper.buildFromArray(json.talking_points),
      minPrice: json.min_price,
      maxPrice: json.max_price,
      priceSteps: json.price_steps,
      rematchHours: json.rematch_hours,
      matchmakingLimitMinutes: json.matchmaking_limit_minutes,
    });
  };

  static buildFromArray = (jsonArray = []) => {
    return jsonArray.map((entry) => {
      return this.build(entry);
    });
  };

  static toJsonString(exhibition) {
    return JSON.stringify({
      [attributes.name]: exhibition.getName(),
      [attributes.shortKey]: exhibition.getShortKey(),
      [attributes.subtitle]: exhibition.getSubtitle(),
      [attributes.description]: exhibition.getDescription(),
      [attributes.endAt]: exhibition.getEndAt().toISOString(),
      [attributes.startAt]: exhibition.getStartAt().toISOString(),
      [attributes.conversation]: exhibition.getConversation(),
    });
  }
}
