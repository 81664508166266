import User from "../models/User";
import BaseMapper from "./BaseMapper";
import OrganizationMapper from "./OrganizationMapper";
import ImageMapper from "./ImageMapper";
import BookmarkMapper from "./BookmarkMapper";
import ErrorLogger from "../utils/ErrorLogger";
import SystemRole, { INTERNAL_IDS } from "../models/SystemRole";
import RoleMapper from "./RoleMapper";
import TagMapper from "./TagMapper";
import VideoMapper from "./VideoMapper";

const attributes = User.attributes;

export default class UserMapper {
  static build = (json, bookmark = undefined) => {
    if (!json) {
      return;
    }

    try {
      return new User({
        ...BaseMapper.attributes(json),
        email: json.email,
        firstName: json.first_name,
        lastName: json.last_name,
        role: RoleMapper.build(json.role),
        //exhibitionId: json.exhibition.id,
        profilePicture: ImageMapper.build(json.profile_picture),
        headerPicture: ImageMapper.build(json.header_picture),
        organization: OrganizationMapper.build(json.organization),
        exhibitorId: json.exhibitor_id,
        gender: json.gender,
        uid: json.uid,
        blocked: json.blocked,
        locale: json.locale,
        tags: TagMapper.buildFromArray(json.tags),
        video: VideoMapper.build(json.video),

        description: json[attributes.description],
        searchText: json[attributes.searchText],

        //Social Links
        xingUrl: json[attributes.xingUrl],
        twitterUrl: json[attributes.twitterUrl],
        websiteUrl: json[attributes.websiteUrl],
        instagramUrl: json[attributes.instagramUrl],
        linkedInUrl: json[attributes.linkedInUrl],
        facebookUrl: json[attributes.facebookUrl],

        // Contact Information
        companyName: json[attributes.companyName],
        companyEmail: json[attributes.companyEmail],
        profession: json[attributes.profession],
        phoneNumber: json[attributes.phoneNumber],
        availabilityStatus: json[attributes.availabilityStatus],
        title: json[attributes.title],
        isBookmarked: json.marks && json.marks.length > 0 && json.marks[0].bookmark ? true : false,
        bookmark: json.marks && json.marks.length > 0 && json.marks[0].bookmark ? json.marks[0] : undefined,
        salesmark: json.marks && json.marks.length > 0 && json.marks[0].salesmark ? json.marks[0] : undefined,
        isSalesmarked: json.marks && json.marks.length > 0 && json.marks[0].salesmark ? true : false,
        salutation: json[attributes.salutation],
        onlineStatus: json[attributes.onlineStatus],
        writePermissions: json[attributes.writePermissions],
        readPermissions: json[attributes.readPermissions],
        expoOnlyPermissions: json[attributes.expoOnlyPermissions],
        activeStep: json[attributes.activeStep],
        confirmed: json[attributes.confirmed],
        eventuserId: json.eventuser_id,
        online: json.online,
        uuid: json.user_uuid,
      });
    } catch (error) {
      ErrorLogger.call(this, error);
    }
  };

  static toJsonString = (user) => {
    let obj = {
      id: user.getId(),
      uid: user.getUid(),
      first_name: user.getFirstName(),
      last_name: user.getLastName(),
      email: user.getEmail(),
      role: user.getRole() ? user.getRole().state : undefined,
      profile_picture: user.getProfilePicture() ? ImageMapper.toObject(user.getProfilePicture()) : undefined,

      created_at: user.getId() ? user.getRawCreatedAt().toISOString() : "",
      updated_at: user.getId() ? user.getRawUpdatedAt().toISOString() : "",

      // Social Links
      xing_url: user.getXingUrl(),
      twitter_url: user.getTwitterUrl(),
      website_url: user.getWebsiteUrl(),
      youtube_url: user.getYoutubeUrl(),
      linkedIn_url: user.getLinkedInUrl(),
      facebook_url: user.getFacebookUrl(),

      // Contact Information
      description: user.getDescription(),
      search_text: user.getSearchText(),

      compnay_name: user.getCompanyName(),
      company_email: user.getCompanyEmail(),
      phone_number: user.getPhoneNumber(),
      profession: user.getProfession(),
      blocked: user.isBlocked(),
      gender: user.getGender(),
      title: user.getTitle(),
      salutation: user.getSalutation(),
      [attributes.writePermissions]: user.hasWritePermissions(),
      [attributes.readPermissions]: user.hasReadPermissions(),

      [attributes.invitationCode]: user.getInvitationCode(),
      [attributes.availabilityStatus]: user.getAvailabilityStatus(),
      [attributes.activeStep]: user.getActiveStep(),
      [attributes.confirmed]: user.getConfirmed(),
    };

    if (user.getPassword() && user.getPasswordConfirmation() && user.getPassword() === user.getPasswordConfirmation()) {
      obj.password = user.getPassword();
    }

    return JSON.stringify(obj);
  };

  static toJsonRegistrationString = (user, optionalParameters = {}) => {
    return JSON.stringify({
      user: {
        ...optionalParameters,
        first_name: user.getFirstName(),
        last_name: user.getLastName(),
        email: user.getEmail(),
        blocked: user.isBlocked(),
        password: user.getPassword(),
        password_confirmation: user.getPasswordConfirmation(),
        company_name: user.getCompanyName(),
        profession: user.getProfession(),
      },
    });
  };

  static buildFromArray = (jsonArray = [], bookmarks = []) => {
    return jsonArray.map((entry) => {
      const bm = bookmarks.find(b => b.getBookmarkableId() === entry.id);
      return this.build(entry, bm);
    });
  };
}
