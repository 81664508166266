import React, { useEffect, useState,useRef,useCallback } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import { Lightbox } from 'react-modal-image';
import {
  Avatar,
  Box,
  Link,
  Typography,
  makeStyles
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import "moment/locale/de";
import "moment/locale/nl";
import MyStore from "../../../../utils/MyStore";
import Tooltip from "@material-ui/core/Tooltip";
import UserDetailModal from "../components/UserDetailModal";

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(2),
    display: 'flex',
    overflow: "hidden",
  },
  avatar: {
    height: 32,
    width: 32,
    cursor: 'pointer',
  },
  image: {
    cursor: 'pointer',
    height: 'auto',
    maxWidth: '100%',
    width: 380
  },
  messageText: {
    whiteSpace: "pre-wrap",
    marginLeft: 8,
  },
  demiBold: {
    fontWeight: 700,
    cursor: 'pointer',
  },
  wordBreak: {
    wordBreak: "break-word"
  }
}));

function Message({ className, message, messagesRef,scrollTop,a, ...rest }) {
  const messageRef = useRef();
  const classes = useStyles();
  const [openedFile, setOpenedFile] = useState(null);
  const [t] = useTranslation();
  const [tooltipIsOpen, setTooltipIsOpen] = useState(false);

  useEffect(() => {
    moment.locale(MyStore.getLocal())
  }, [MyStore.getLocal()])


  useEffect(() =>{
    const spaceForTooltipCheck = () =>{
      if (messageRef.current.getBoundingClientRect().top + 50 <
                messagesRef.current.clientHeight){
                  setTooltipIsOpen(false);
                }
    }
    messagesRef.current.addEventListener('scroll', spaceForTooltipCheck);

  },[])

  const [isProfileOpened, setIsProfileOpened] = useState(false);

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Box
        display="flex"
        maxWidth={500}
        ml={message.isMe() ? 'auto' : 0}
        style={{ position: "relative" }}
      >
        <Avatar
          className={classes.avatar}
          onClick={() => setIsProfileOpened(true)}
          src={
            message.getUser() && message.getUser().getProfilePicture()
              ? message.getUser().getProfilePicture().getUrl("thumbnail")
              : ""
          }
        />
        <Box ml={2} border={message.getModeratorOnly() ? "2px solid deepSkyBlue":""} borderRadius="6px">
          <Tooltip
            title={`${moment(message.getRawCreatedAt()).fromNow()} ${message.getModeratorOnly() ? "(moderator-only)":""}`}
            open={tooltipIsOpen}
            ref={messageRef}
            onOpen={() => {
              if (
                (messageRef.current.getBoundingClientRect().top + 50)>
                messagesRef.current.clientHeight
              ) {
                setTooltipIsOpen(true);
              }
            }}
            onClose={() => setTooltipIsOpen(false)}
            placement="top"
          >
            <Box
              bgcolor={message.isMe() ? "primary.main" : "white"}
              color={message.isMe() ? "primary.contrastText" : "text.primary"}
              py={1}
              px={2}
              borderRadius="borderRadius"
              boxShadow={1}
            >
              <Box className={classes.wordBreak}>
                <Typography>
                  <Typography component="b" className={classes.demiBold} onClick={() => setIsProfileOpened(true)}>
                        {message.isMe()
                      ? t("frontend.me")
                      : message.getUser().getFullName()
                      }
                  </Typography>
                      {message.contentType === "image" ? (
                        <Box mt={2} onClick={() => setOpenedFile(message.body)}>
                          <img
                            alt="Attachment"
                            className={classes.image}
                            src={message.body}
                          />
                        </Box>
                      ) : (
                        <Typography
                          color="inherit"
                          variant="body1"
                          display="inline"
                          className={classes.messageText}
                        >
                          {message.getText()}
                        </Typography>
                      )}
                  </Typography>
              </Box>
            </Box>
          </Tooltip>
        </Box>
      </Box>
      {openedFile && (
        <Lightbox large={openedFile} onClose={() => setOpenedFile(null)} />
      )}
      {isProfileOpened && (
        <UserDetailModal 
          userId={message.getUser().getId()} 
          setClose={setIsProfileOpened} 
          open={isProfileOpened} 
          visitorList={[message.getUser()]} 
          currentVisitor={message.getUser()} 
          currentIndex={0}/>
      )}
    </div>
  );
}

Message.propTypes = {
  className: PropTypes.string,
  message: PropTypes.object.isRequired,
};

export default Message;
