import React, { useEffect, useState } from "react";
import Video, { createLocalTracks } from "twilio-video";
import Meeting from "../../../models/Meeting";
import DualCall from "./DualCall";
import GroupCall from "./GroupCall";

const MeetingRoom = ({ roomName, token, handleLogout, meetingReason }) => {
  const [room, setRoom] = useState(null);
  const [localParticipant, setLocalParticipant] = useState();
  const [participants, setParticipants] = useState([]);
  const [networkQuality, setNetworkQuality] = useState(0);

  useEffect(() => {
    const participantConnected = (participant) => {
      setParticipants((prevParticipants) => [...prevParticipants, participant]);
    };
    const participantDisconnected = (participant) => {
      setParticipants((prevParticipants) =>
        prevParticipants.filter((p) => p !== participant)
      );
    };
    Video.connect(token, {
      audio: true,
      name: roomName,
      networkQuality: {
        local: 1, // LocalParticipant's Network Quality verbosity [1 - 3]
        remote: 0, // RemoteParticipants' Network Quality verbosity [0 - 3]
      },
      video: { height: 1080, frameRate: 24, width: 1920 },
    }).then((room) => {
      setRoom(room);
      setLocalParticipant(room.localParticipant);
      room.on("participantConnected", participantConnected);
      room.on("participantDisconnected", participantDisconnected);
      room.participants.forEach(participantConnected);
    });

    return () => {
      setRoom((currentRoom) => {
        if (currentRoom && currentRoom.localParticipant.state === "connected") {
          currentRoom.localParticipant.tracks.forEach(function (
            trackPublication
          ) {
            trackPublication.track.stop();
          });
          currentRoom.disconnect();
          return null;
        } else {
          return currentRoom;
        }
      });
    };
  }, [roomName, token]);

  useEffect(() => {
    if (!localParticipant) {
      return;
    }
  }, [localParticipant]);

  // const changeNetworkQualityStats = (
  //   networkQualityLevel,
  //   networkQualityStats
  // ) => {
  //   setNetworkQuality(networkQualityLevel);

  //   if (networkQualityStats) {
  //     // Print in console the networkQualityStats, which is non-null only if Network Quality
  //     // verbosity is 2 (moderate) or greater
  //     console.log("Network Quality statistics:", networkQualityStats);
  //   }
  // };

  return meetingReason === Meeting.reason.ONE_TO_ONE ||
    meetingReason === Meeting.reason.CHAT_ONE_TO_ONE ? (
    <DualCall
      participants={participants}
      room={room}
      handleLogout={handleLogout}
      networkQuality={networkQuality}
    />
  ) : (
    <GroupCall />
  );
};

export default MeetingRoom;
