import {
  FETCHING_STAGES_REQUEST,
  FETCHING_STAGES_SUCCESS,
  FETCHING_STAGES_FAILURE,

  FETCHING_STAGE_REQUEST,
  FETCHING_STAGE_SUCCESS,
  FETCHING_STAGE_FAILURE,

} from './types';
import Urls, { DEFAULT_OPTIONAL_PARAMETERS } from '../../constants/Urls';
import { BEARER } from '../../utils/MyStore';

import StageMapper from '../../mapper/StageMapper';
import ResponseService from '../../utils/ResponseService';
import ApplicationError from '../../utils/ApplicationError';
import ErrorLogger from '../../utils/ErrorLogger';

export const fetchingStagesRequest = () => ({
  type: FETCHING_STAGES_REQUEST
})

export const fetchingStagesSuccess = (json) => ({
  type: FETCHING_STAGES_SUCCESS,
  payload: json
})
export const fetchingStagesFailure = (error) => ({
  type: FETCHING_STAGES_FAILURE,
  payload: error
})

export const fetchingStageRequest = () => ({
  type: FETCHING_STAGE_REQUEST
})

export const fetchingStageSuccess = (exhibitor) => ({
  type: FETCHING_STAGE_SUCCESS,
  payload: exhibitor
})
export const fetchingStageFailure = (error) => ({
  type: FETCHING_STAGE_FAILURE,
  payload: error
})


export const fetchStages = (_opts = DEFAULT_OPTIONAL_PARAMETERS) => {
  return async dispatch => {
    dispatch(fetchingStagesRequest());
    
    try {
      const route   = Urls.stages.all
      const bearer  = localStorage.getItem(BEARER)
      const headers = Urls.header.secure(bearer)
      const url     = Urls.build(route.url(_opts.pageIndex, _opts.pageSize), _opts.filters)

      let response = await fetch(url, {
        method: route.method,
        headers: headers
      });

      let responseService = new ResponseService(StageMapper)

      let action = await responseService.call(response, 
        (value) => fetchingStagesSuccess(value),
        (error) => fetchingStagesFailure(error))

      dispatch(action)
    } catch(error) {
      ErrorLogger.call(this, error)
      dispatch(fetchingStagesFailure(ApplicationError.buildApplicationError(error)))
    }
  };
};

export const fetchStage = (id) => {
  return async dispatch => {
    dispatch(fetchingStageRequest());
    
    try {
      const route   = Urls.stages.show
      const bearer  = localStorage.getItem(BEARER)
      const headers = Urls.header.secure(bearer)
      const url     = Urls.build(route.url(id))

      let response = await fetch(url, {
        method: route.method,
        headers: headers
      });

      let responseService = new ResponseService(StageMapper)

      let action = await responseService.call(response, 
        (value) => fetchingStageSuccess(value),
        (error) => fetchingStageFailure(error))

      dispatch(action)
    } catch(error) {
      ErrorLogger.call(this, error)
      dispatch(fetchingStageFailure(ApplicationError.buildApplicationError(error)))
    }
  };
};