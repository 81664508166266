import ApplicationModel from "./ApplicationModel"

export default class User extends ApplicationModel {
  /*
    Additional Getter
  */

  constructor(_opts) {
    super()
    this.state = { password: '', passwordConfirmation: '', ..._opts }
  }

  getFirstName = () => this.state.firstName
  setFirstName = (value) => this.state.firstName = value

  getLastName = () => this.state.lastName
  setLastName = (value) => this.state.lastName = value

  getUid = () => this.state.uid

  getLocale = () => this.state.locale
  
  getEmail = () => this.state.email
  setEmail = (value) => this.state.email = value

  getFullName = () => { return this.state.firstName + " " + this.state.lastName }
  getInvitationCode = () => this.state.invitationCode
  
  getPassword = () => this.state.password
  getPasswordConfirmation = () => this.state.passwordConfirmation

  setPassword = (password) => this.state.password = password
  setPasswordConfirmation = (passwordConfirmation) => this.state.passwordConfirmation = passwordConfirmation

  setOldPassword = (oldPassword) => this.state.oldPassword = oldPassword
  getOldPassword = () => this.state.oldPassword 

  hasWritePermissions = () => true//this.state.writePermissions
  hasReadPermissions = () => true//this.state.readPermissions
  hasExpoOnlyPermissions = () => this.state.expoOnlyPermissions

  getRole = () => this.state.role

  getProfilePicture = () => this.state.profilePicture
  setProfilePicture = (picture) => this.state.profilePicture = picture
  getHeaderPicture = () => this.state.headerPicture

  isOnline = () => this.state.online ? true:false
  setIsOnline = (value) => this.state.onlineStatus = value

  getTitle = () => this.state.title
  getAvailabilityStatus = () => this.state.availabilityStatus
  getUuid = () => this.state.uuid

  /*
   * Special SystemRole Attributes
   * - Visitor
   * - Speaker
   * - Exhibitor
   * - Employee for Organization
   * - Provider ( System SAL )
   */

  // Visitors / Speaker
  getExhibitionId = () => this.state.exhibitionId

  // Exhibitor
  getExhibitorId = () => this.state.exhibitorId

  // Social Links
  getXingUrl = () => this.state.xingUrl
  setXingUrl = (value) => this.state.xingUrl = value
  
  getTwitterUrl = () => this.state.twitterUrl
  setTwitterUrl = (value) => this.state.twitterUrl = value

  getWebsiteUrl = () => this.state.websiteUrl
  setWebsiteUrl = (value) => this.state.websiteUrl = value

  getYoutubeUrl = () => this.state.youtubeUrl
  setYoutubeUrl = (value) => this.state.youtubeUrl = value

  getLinkedInUrl = () => this.state.linkedInUrl
  setLinkedInUrl = (value) => this.state.linkedInUrl = value

  getFacebookUrl = () => this.state.facebookUrl
  setFacebookUrl = (value) => this.state.facebookUrl = value

  getInstagramUrl = () => this.state.instagramUrl
  setInstagramUrl = (value) => this.state.instagramUrl = value


  getUid = () => this.state.uid

  // Contact Information
  getCompanyName = () => this.state.companyName
  setCompanyName = (value) => this.state.companyName = value

  getCompanyEmail = () => this.state.companyEmail
  setCompanyEmail = (value) => this.state.companyEmail = value

  getProfession = () => this.state.profession
  setProfession = (value) => this.state.profession = value

  getPhoneNumber = () => this.state.phoneNumber
  setPhoneNumber = (value) => this.state.phoneNumber = value

  getDescription = () => this.state.description
  setDescription = (value) => this.state.description = value

  getSearchText = () => this.state.searchText
  setSearchText = (value) => this.state.searchText = value

  getBookmark = () => this.state.bookmark
  setBookmark = (bookmark) => this.state.bookmark = bookmark

  isBookmarked = () => this.state.isBookmarked
  setIsBookmarked = (isBookmarked) => this.state.isBookmarked = isBookmarked

  isSalesmarked = () => this.state.isSalesmarked;
  setIsSalesmarked = (val) => (this.state.isSalesmarked = val);

  setTitle = (title) => this.state.title = title
  setAvailabilityStatus = (status) => this.state.availabilityStatus = status

  getGender = () => this.state.gender
  setGender = (gender) => this.state.gender = gender

  isBlocked = () => this.state.blocked
  setIsBlocked = (blocked) => this.state.blocked = blocked

  getSalutation = () => this.state.salutation
  setSalutation = (value) => this.state.salutation = value

  getTags = () => this.state.tags || []
  setTags = (value) => this.state.tags = value
  getVideo = () => this.state.video
  getActiveStep = () => this.state.activeStep
  getConfirmed = () => this.state.confirmed
  
  className = "User"

  // Backend Attributes
  static attributes = {
    ...super.attributes,
    firstName: 'first_name',
    lastName: 'last_name',
    email: 'email',
    gender: 'gender',
    locale: 'locale',
    description: 'description',
    searchText: 'search_text',
    exhibitionId: 'exhibition_id',
    tags: "tags",
    video: "video",
    activeStep: "active_step",

    // Social Links
    linkedInUrl: 'linkedIn_url',
    instagramUrl: 'instagram_url',
    facebookUrl: 'facebook_url',
    twitterUrl: 'twitter_url',
    xingUrl: 'xing_url',
    websiteUrl: 'website_url',

    // Contact Information
    companyName: 'compnay_name',
    companyEmail: 'company_email',
    phoneNumber: 'phone_number',
    profession: 'profession',
    title:"title",
    salutation:"salutation",

    freeMeetingSlots: 'free_meeting_slots',
    bookmark: 'bookmark',

    availabilityStatus: 'availability_status',
    onlineStatus: 'online_status',

    writePermissions: 'write_permissions',
    readPermissions: 'read_permissions',
    expoOnlyPermissions: 'expo_only',
    confirmed: "confirmed"

  }

  static ALLOWED_GENDERS = {
    empty: "empty", 
    male: "male", 
    female: "female", 
    divers: "divers"
  }
  
  static ALLOWED_SALUTATIONS = {
    empty: "empty", 
    without_salutation: "without_salutation", 
    mr: "mr", 
    ms: "ms",
    mrs: "mrs",
  }

  static ALLOWED_ACADEMIC_TITLES = {
    empty: "empty", 
    none: "none", 
    dr: "dr",
    prof: "prof",
    phd: "phd",
  }

  static AVAILABILITY_STATUS = {
    AVAILABLE: 'available',
    MUTED: 'muted'
  }

  static ONLINE_STATUSES = {
    ONLINE: "online",
    OFFLINE: "offline"
  }

  static orderByParams = {
    ...super.orderByParams,
    firstName: this.attributes.firstName,
    lastName: this.attributes.lastName
  }

  static filterParams = {
    ...super.filterParams,
    search: '_q',
    blocked: 'blocked'
  }
}