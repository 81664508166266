import ApplicationModel from "./ApplicationModel";

const second = 1000;
const minute = second * 60;

export default class Meeting extends ApplicationModel {
  constructor(_opts = {}) {
    super(_opts);
    this.state = { ..._opts };
  }

  getTitle = () => this.state.title;

  getParticipants = () => this.state.participants; // User
  getStartAt = () => this.state.startAt;
  getEndAt = () => this.state.endAt;
  getReason = () => this.state.reason;
  getStatus = () => this.state.status;

  getMeetingStatus = () => this.state.meetingStatus;
  setMeetingStatus = (value) => this.state.meetingStatus = value;

  getExhibitionId = () => this.state.exhibition.id;
  getExhibition = () => this.state.exhibition

  getDurationMinutes = () =>
    parseInt((this.getEndAt() - this.getStartAt()) / minute);
  getUser = () => this.state.user;
  getMeetingsUsers = () => this.state.meetingsUsers

  getTwilioConfiguration = () => this.state.twilioConfiguration;

  getMeetingsUsers = () => this.state.meetingsUsers

  static reason = {
    ONE_TO_ONE: "one_to_one",
    GROUP_CALL: "group_call",
    CHAT_ONE_TO_ONE:"chat_one_to_one"
  };

  static meetingStatus = {
    INITIALIZED: "initialized",
    MEETING_STARTED: "meeting_started",
    MEETING_CLOSED: "meeting_closed",
  };

  static status = {
    ACCEPTED: "accepted",
    DECLINED: "declined",
  };

  // Backend Attributes
  static attributes = {
    ...super.attributes,
    title: "title",
    status: "status",
    startAt: "start_at",
    endAt: "end_at",
    reason: "reason",
    user: "user",
    meetingsUsers: "meetings_users",
    meetingStatus: "meeting_status",
    meetingsUsers: "meetings_users",
    participants: "participants",
    exhibitionId: "exhibition_id",
    exhibition: "exhibition",
    participantIds: "participant_ids",
    active: "active"
  };

  static orderByParams = {
    ...super.orderByParams,
    startAt: this.attributes.startAt,
  };

  static filterParams = {
    ...super.filterParams,
    status: this.attributes.status,
    reason: this.attributes.reason,
    requested: 'requested',
    active: this.attributes.active
  };
}
