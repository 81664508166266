import {
  FETCHING_USER_REQUEST,
  FETCHING_USER_SUCCESS,
  FETCHING_USER_FAILURE,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILURE,
  SET_CURRENT_USER,
  SET_CURRENT_EXHIBITORS,
  CLEAR_USER,
  LOGOUT_USER_REQUEST,
  LOGOUT_USER_SUCCESS,
  LOGOUT_USER_FAILURE,
} from "../actions/types";
import ApplicationError from "../../utils/ApplicationError";

const initialState = {
  user: undefined,
  currentUser: undefined,
  error: ApplicationError.buildDefault(),
  isFetching: false,
  exhibitors: undefined,
};

const usersReducer = (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_USER:
      return { ...initialState, currentUser: state.currentUser };

    // Get User
    case FETCHING_USER_REQUEST:
      return { ...state, isFetching: true };
    case FETCHING_USER_SUCCESS:
      return {
        ...state,
        isFetching: false,
        user: action.payload,
        error: ApplicationError.buildDefault(),
      };
    case FETCHING_USER_FAILURE:
      return { ...state, isFetching: false, error: action.payload };

    // Update Current User
    case UPDATE_USER_REQUEST:
      return { ...state, isFetching: true };
    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        isFetching: false,
        user: action.payload,
        error: ApplicationError.buildDefault(),
      };
    case UPDATE_USER_FAILURE:
      return { ...state, isFetching: false, error: action.payload };

    // Logout user
    case LOGOUT_USER_REQUEST:
      return { ...state, isFetching: true };
    case LOGOUT_USER_SUCCESS:
      return {
        ...initialState,
      };
    case LOGOUT_USER_FAILURE:
      return { ...state, isFetching: false, error: action.payload };

    case SET_CURRENT_USER:
      return {
        ...state,
        isFetching: false,
        currentUser: action.payload,
        error: ApplicationError.buildDefault(),
      };

    case SET_CURRENT_EXHIBITORS:
      return {
        ...state,
        exhibitors: action.payload,
      };

    default:
      return state;
  }
};

export default usersReducer;
