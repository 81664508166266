/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect } from "react";
import { useHistory } from "react-router";
import PropTypes from "prop-types";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import {
  AppBar,
  Badge,
  Button,
  IconButton,
  Toolbar,
  Hidden,
  Input,
  colors,
  Popper,
  Paper,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ClickAwayListener,
  InputLabel,
  Select,
  FormControl,
  Typography,
  Tooltip,
  Avatar,
  Divider,
  useTheme,
} from "@material-ui/core";
import NotificationsIcon from "@material-ui/icons/Notifications";
import InputIcon from "@material-ui/icons/Input";
import MenuIcon from "@material-ui/icons/Menu";
import SearchIcon from "@material-ui/icons/Search";
import NotificationsPopover from "../../components/NotificationsPopover";
import { deleteSession } from "../../../redux/actions/SessionActions";
import FrontendRoutes from "../../../constants/FrontendRoutes";
import useNotificationEngine from "../../../engines/Notification/NotificationEngine";
import { MenuItem } from "react-contextmenu";
import { Trans, useTranslation } from "react-i18next";
import {
  changeLocaleUser,
  updateUser,
  logOutUser,
  clearUser,
} from "../../../redux/actions/UsersActions";
import User from "../../../models/User";
import PushNotification from "../../../models/PushNotification";
import {
  DEFAULT_PAGE_INDEX,
  DEFAULT_PAGE_SIZE,
  MAX_PAGE_SIZE,
} from "../../../constants/Urls";
import { useActiveKeynote } from "../../../engines/GeneralHooks/useKeynotes";
import { Link } from "react-router-dom";
import Exhibition from "../../../models/Exhibition";
import TodayIcon from "@material-ui/icons/Today";
import useConfigurationService from "../../../utils/ConfigurationService";
import Flag from "react-world-flags";
import MyStore, { LOCAL_STORAGE } from "../../../utils/MyStore";
import NeumorphicStyle from "../../../constants/Neumorphic";
import { faVideo, faHome, faUserFriends, faClock, faTablet } from "@fortawesome/free-solid-svg-icons"
import { faDiscourse } from "@fortawesome/free-brands-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isLocaleEnabled } from "../../../utils";

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.background.default,
    borderRadius: NeumorphicStyle.borderRadius,
    boxShadow: NeumorphicStyle.boxShadowLarge(
      theme.palette.primary.main,
      theme.palette.secondary.dark
    ),
    height: "60px",
    width: "100%",
    position: "relative",
    zIndex: "999",
  },
  flexGrow: {
    flexGrow: 1,
  },
  formControl: {
    maxHeight: "40px",
  },
  search: {
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: theme.palette.primary.main,
    backgroundColor: "rgba(255,255,255, 0.1)",
    borderRadius: 4,
    flexBasis: 300,
    height: 36,
    padding: theme.spacing(0, 2),
    display: "flex",
    alignItems: "center",
  },
  searchIcon: {
    marginRight: theme.spacing(2),
    color: theme.palette.primary.main,
  },
  searchInput: {
    flexGrow: 1,
    color: "text",
    "& input::placeholder": {
      opacity: 1,
      color: "inherit",
    },
  },
  searchPopper: {
    zIndex: theme.zIndex.appBar + 100,
  },
  searchPopperContent: {
    marginTop: theme.spacing(1),
  },
  menuButton: {
    marginRight: theme.spacing(1) - 256,
  },
  chatButton: {
    marginLeft: theme.spacing(1),
  },
  notificationsButton: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  languageButton: {
    marginLeft: theme.spacing(1),
    borderRadius: 0,
    padding: 4,
    backgroundColor: theme.palette.background.default,
  },
  notificationsBadge: {
    backgroundColor: colors.orange[600],
  },
  appointmentButton: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    fontSize: "1em",
    fontWeight: "",
    borderRadius: "5px",
    marginRight: "12px",
    boxShadow: NeumorphicStyle.boxShadowSmall(
      theme.palette.primary.main,
      theme.palette.secondary.dark
    ),
  },
  logoutButton: {
    marginLeft: theme.spacing(2),
    paddingLeft: "8px",
    paddingRight: "8px",
    color: theme.palette.text.primary,
    borderRadius: "4px",
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
      paddingLeft: "8px",
      borderRadius: "4px",
      paddingRight: "8px",
      color: "#FFF",
    },
  },
  logoutIcon: {
    color: theme.palette.text.primary,
  },

  profileButton: {
    marginLeft: 20,
    "&:hover": {
      transition: "none",
    },
  },
  navButton: {
    marginRight: 12,
    padding: 4,
    fontSize: "1.5em"
  },
  selectedNavButton: {
    marginRight: 12,
    padding: 4,
    fontSize: "1.5em",
    color: theme.palette.secondary.main,
  },
  profilePicture: {
    height: theme.spacing(4),
    width: theme.spacing(4),
    borderRadius: "50%",
  },
}));

const popularSearches = [];

function TopBar({
  onOpenNavBarMobile,
  tabIndex,
  setTabIndex,
  className,
  exhibition,
  ...rest
}) {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const notificationsRef = useRef(null);
  const [openSearchPopover, setOpenSearchPopover] = useState(false);
  const [openNotifications, setOpenNotifications] = useState(false);
  const [notificationPageIndex, setNotificationPageIndex] = useState(
    DEFAULT_PAGE_INDEX
  );
  const [notificationPageSize, setNotificationPageSize] = useState(
    DEFAULT_PAGE_SIZE
  );
  const [logout, setLogout] = useState(false);
  const [openChatBar, setOpenChatBar] = useState(false);
  const [availability, setAvailability] = useState();
  const [config] = useConfigurationService();

  const users = useSelector((state) => state.usersReducer);
  const session = useSelector((state) => state.sessionReducer);
  const [
    notifications,
    loading,
    incomingNotification,
    unreadMessagesCount,
    resetUnreadMessages,
    totalPages,
  ] = useNotificationEngine(
    openNotifications,
    notificationPageSize,
    notificationPageIndex
  );
  const [activeKeynote, activeKeynoteLoading] = useActiveKeynote();

  const [imageUrl, setImageUrl] = useState();

  const [language, setLanguage] = useState("en");

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);

  useEffect(() => {
    if(!isLocaleEnabled(users.currentUser.getLocale(),config)){
      dispatch(changeLocaleUser('en'));
      return
    }
  }, [config])

  useEffect(() => {
    if (!users.user) {
      return;
    }

    setImageUrl(
      users.user.getProfilePicture()
        ? users.user.getProfilePicture().getUrl("small")
        : ""
    );
    setAvailability(
      users.user.getAvailabilityStatus()
        ? users.user.getAvailabilityStatus("small")
        : ""
    );
    setLanguage(users.currentUser.getLocale());
    MyStore.setLocal(users.currentUser.getLocale());
  }, [users.user, users.currentUser]);

  useEffect(() => {
    if (openNotifications) {
      return;
    }
    setNotificationPageIndex(DEFAULT_PAGE_INDEX);
  }, [openNotifications]);

  useEffect(() => {
    if (!logout || session.isFetching) {
      return;
    }
    history.push(FrontendRoutes.auth.login());
  }, [session.isFetching]);

  const handleLogout = async () => {
    setLogout(true);
    history.push(FrontendRoutes.auth.logout());
  };

  const handleChatBarClose = () => {
    setOpenChatBar(false);
  };

  const handleNotificationsOpen = () => {
    setOpenNotifications(true);
    resetUnreadMessages();
  };

  const handleNotificationsClose = () => {
    setOpenNotifications(false);
  };

  const handleAvailabilityChange = (event) => {
    const status = event.target.value
      ? event.target.value
      : User.AVAILABILITY_STATUS.AVAILABLE;
    if (!users.user) {
      return;
    }
    users.user.setAvailabilityStatus(status);
    dispatch(updateUser(users.user));
    setAvailability(status);
  };

  // const handleSearchChange = (event) => {
  //   setSearchValue(event.target.value);

  //   if (event.target.value) {
  //     if (!openSearchPopover) {
  //       setOpenSearchPopover(true);
  //     }
  //   } else {
  //     setOpenSearchPopover(false);
  //   }
  // };

  const handleSearchPopverClose = () => {
    setOpenSearchPopover(false);
  };

  const handleLoadMore = () => {
    setNotificationPageIndex(notificationPageIndex + 1);
  };

  return (
    <div className={clsx(classes.root, className)}>
      <Toolbar>
        {exhibition &&
          exhibition.getExhibitionType() ===
          Exhibition.EXHIBITION_TYPES.CLASSIC && (
            <>
              <Button
                className={
                  tabIndex === 0 ? classes.selectedNavButton : classes.navButton
                }
                onClick={() => setTabIndex(0)}
              >
                {/* {config && config.navbar.attendees ? config.navbar.attendees : "Attendees"} */}
                <FontAwesomeIcon icon={faHome} />
              </Button>
              <Button
                className={
                  tabIndex === 1 ? classes.selectedNavButton : classes.navButton
                }
                onClick={() => setTabIndex(1)}
              >
                {/* {config && config.navbar.exhibitors ? config.navbar.exhibitors : "Exhibitors"} */}
                <FontAwesomeIcon icon={faVideo} />
              </Button>
              <Button
                className={
                  tabIndex === 2 ? classes.selectedNavButton : classes.navButton
                }
                onClick={() => setTabIndex(2)}
              >
                {/* {config && config.navbar.home ? config.navbar.home : "Home"} */}

                <FontAwesomeIcon icon={faUserFriends} />

              </Button>
              <Button
                className={
                  tabIndex === 3 ? classes.selectedNavButton : classes.navButton
                }
                onClick={() => setTabIndex(3)}
              >
                {/* {config && config.navbar.schedule ? config.navbar.schedule : "Agenda"} */}
                <Typography variant="h4" style={{ color: "inherit" }}>
                  <FontAwesomeIcon icon={faClock} />
                </Typography>
              </Button>
              <Button
                className={
                  tabIndex === 4 ? classes.selectedNavButton : classes.navButton
                }
                onClick={() => setTabIndex(4)}
              >
                {/* {config && config.navbar.schedule ? config.navbar.schedule : "Agenda"} */}
                <FontAwesomeIcon icon={faTablet} />
              </Button>
            </>
          )}
        {exhibition &&
          exhibition.getExhibitionType() ===
          Exhibition.EXHIBITION_TYPES.LANDINGPAGE && (
            <>
              <Typography
                variant="h3"
                style={{ color: theme.palette.primary.contrastText }}
              >
                Herzlichen Willkommen zu unserer Leadevents Live Marketing
                Plattform
              </Typography>
            </>
          )}
        <div className={classes.flexGrow} />
        <Select
          className={classes.languageButton}
          value={language}
          onChange={(e) => {
            setLanguage(e.target.value);
            MyStore.setLocal(e.target.value);
            if (users.user && users.user.getLocale() !== e.target.value) {
              dispatch(changeLocaleUser(e.target.value));
            }
          }}
          disableUnderline
        >
           {config?.enable_german ? <MenuItem value={"de"}><Flag code="de" height={20} width={30} /></MenuItem> : null}
            <MenuItem value={"en"}><Flag code="gb" height={20} width={30} /></MenuItem>
            {config?.enable_dutch ? <MenuItem value={"nl"}><Flag code="nl" height={20} width={30} /></MenuItem> : null}
            {config?.enable_italy ? <MenuItem value={"it"}><Flag code="it" height={20} width={30} /></MenuItem> : null}
            {config?.enable_french ? <MenuItem value={"fr"}><Flag code="fr" height={20} width={30} /></MenuItem> : null}
        </Select>
        {exhibition &&
          exhibition.getExhibitionType() ===
          Exhibition.EXHIBITION_TYPES.CLASSIC && (
            <>
              {/* <IconButton
            className={classes.notificationsButton}
            color="text"
            onClick={handleNotificationsOpen}
            ref={notificationsRef}
          >
            <Badge
              badgeContent={unreadMessagesCount}
              classes={{ badge: classes.notificationsBadge }}
              variant="dot"
            >
              <NotificationsIcon />
            </Badge>
          </IconButton> */}

              <Button
                className={classes.profileButton}
                color={theme.palette.secondary.contrastText}
                onClick={() => {
                  setTabIndex(5);
                }}
              >
                <Avatar
                  src={
                    users.currentUser
                      ? users.currentUser.getProfilePicture()
                        ? users.currentUser
                          .getProfilePicture()
                          .getUrl("thumbnail")
                        : ""
                      : ""
                  }
                  className={classes.profilePicture}
                />
              </Button>
            </>
          )}
        {exhibition &&
          exhibition.getExhibitionType() ===
          Exhibition.EXHIBITION_TYPES.LANDINGPAGE && (
            <Button
              className={classes.appointmentButton}
              onClick={() => { }}
              target="_blank"
              href={
                exhibition && exhibition.getBookingUrl()
                  ? exhibition.getBookingUrl()
                  : ""
              }
              variant="contained"
              size="small"
              startIcon={
                <TodayIcon
                  className={classes.mailIcon}
                  style={{ fontSize: "1.3em" }}
                />
              }
            >
              <Trans>frontend.schedule_meeting</Trans>
            </Button>
          )}
        <Button
          className={classes.logoutButton}
          onClick={handleLogout}
        >
          <InputIcon className={classes.logoutIcon} />
        </Button>
        {/* </Hidden> */}
      </Toolbar>
      {/* <NotificationsPopover
        anchorEl={notificationsRef.current}
        notifications={notifications}
        loading={loading}
        handleLoadMore={() => handleLoadMore()}
        onClose={handleNotificationsClose}
        open={openNotifications}
        loadMoreActive={notificationPageIndex !== totalPages}
      /> */}
    </div>
  );
}

TopBar.propTypes = {
  className: PropTypes.string,
  onOpenNavBarMobile: PropTypes.func,
};

export default TopBar;
