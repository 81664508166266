import {
  FETCHING_EXHIBITION_TIMESLOTS_REQUEST,
  FETCHING_EXHIBITION_TIMESLOTS_SUCCESS,
  FETCHING_EXHIBITION_TIMESLOTS_FAILURE,
} from './types';
import Urls, { DEFAULT_OPTIONAL_PARAMETERS } from '../../constants/Urls';
import { BEARER } from '../../utils/MyStore';
import ApplicationError from '../../utils/ApplicationError';
import ResponseService from '../../utils/ResponseService';
import TimeslotMapper from '../../mapper/TimeslotMapper';
import FilterParams from '../../utils/FilterParams';
import Timeslot from '../../models/Timeslot';
import ErrorLogger from '../../utils/ErrorLogger';

export const fetchingTimeslotsRequest = () => ({
  type: FETCHING_EXHIBITION_TIMESLOTS_REQUEST
})

export const fetchingTimeslotsSuccess = (json) => ({
  type: FETCHING_EXHIBITION_TIMESLOTS_SUCCESS,
  payload: json
})
export const fetchingTimeslotsFailure = (error) => ({
  type: FETCHING_EXHIBITION_TIMESLOTS_FAILURE,
  payload: error
})


export const fetchTimeslots = (exhibitionId, userId, _opts = DEFAULT_OPTIONAL_PARAMETERS) => {
  return async dispatch => {
    dispatch(fetchingTimeslotsRequest());
    
    try {
      const route   = Urls.exhibitions.timeslots.all
      const bearer  = localStorage.getItem(BEARER)
      const headers = Urls.header.secure(bearer)
      const url     = Urls.build(route.url(exhibitionId, _opts.pageIndex, _opts.pageSize), [FilterParams.filterBy(Timeslot.filterParams.userId, userId)])

      let response = await fetch(url, {
        method: route.method,
        headers: headers
      });

      let responseService = new ResponseService(TimeslotMapper)

      let action = await responseService.call(response, 
        (value) => fetchingTimeslotsSuccess(value),
        (error) => fetchingTimeslotsFailure(error))

      dispatch(action)
    } catch(error) {
      ErrorLogger.call(this, error)
      dispatch(fetchingTimeslotsFailure(ApplicationError.buildApplicationError(error)))
    }
  };
};