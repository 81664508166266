import {
  FETCHING_EXHIBITION_SPEAKERS_REQUEST,
  FETCHING_EXHIBITION_SPEAKERS_SUCCESS,
  FETCHING_EXHIBITION_SPEAKERS_FAILURE,
  
  FETCHING_EXHIBITION_SPEAKER_REQUEST,
  FETCHING_EXHIBITION_SPEAKER_SUCCESS,
  FETCHING_EXHIBITION_SPEAKER_FAILURE,

  CREATE_EXHIBITION_SPEAKER_FAILURE,
  CREATE_EXHIBITION_SPEAKER_SUCCESS,
  CREATE_EXHIBITION_SPEAKER_REQUEST,

  UPDATE_EXHIBITION_SPEAKER_FAILURE,
  UPDATE_EXHIBITION_SPEAKER_SUCCESS,
  UPDATE_EXHIBITION_SPEAKER_REQUEST,

  DELETE_EXHIBITION_SPEAKER_FAILURE,
  DELETE_EXHIBITION_SPEAKER_SUCCESS,
  DELETE_EXHIBITION_SPEAKER_REQUEST,
} from '../actions/types';
import ResponseService from '../../utils/ResponseService';
import ApplicationError from '../../utils/ApplicationError';

const initialState = {
  speakers: [],
  speaker: undefined,
  error: ApplicationError.buildDefault(),
  isFetching: false,
  ...ResponseService.defaultPaginationMetaData()
};

const speakersReducer = (state = initialState, action ) => {
  switch(action.type) {

    // Get All Speaker
    case FETCHING_EXHIBITION_SPEAKERS_REQUEST:
      return {...state, isFetching: true }
    case FETCHING_EXHIBITION_SPEAKERS_SUCCESS:
      return {...state, isFetching: false, 
        speakers: action.payload.objects, 
        error: ApplicationError.buildDefault(),
        ...action.payload.paginationData }

    case FETCHING_EXHIBITION_SPEAKERS_FAILURE:
      return {...state, isFetching: false, error: action.payload }
    
    // Get Speaker  
    case FETCHING_EXHIBITION_SPEAKER_REQUEST:
      return {...state, isFetching: true }
    case FETCHING_EXHIBITION_SPEAKER_SUCCESS: 
      return {...state, isFetching: false, speaker: action.payload, error: ApplicationError.buildDefault() }
    case FETCHING_EXHIBITION_SPEAKER_FAILURE:
      return {...state, isFetching: false, error: action.payload }  

    // Create Speaker
    case  CREATE_EXHIBITION_SPEAKER_REQUEST:
      return {...state, isFetching: true }
    case  CREATE_EXHIBITION_SPEAKER_SUCCESS:
      return {...state, isFetching: false, speaker: action.payload, error: ApplicationError.buildDefault() }
    case  CREATE_EXHIBITION_SPEAKER_FAILURE:
      return {...state, isFetching: false, error: action.payload }

    //Update Speaker  
    case  UPDATE_EXHIBITION_SPEAKER_REQUEST:
      return {...state, isFetching: true }
    case  UPDATE_EXHIBITION_SPEAKER_SUCCESS:
      return {...state, isFetching: false, speaker: action.payload, error: ApplicationError.buildDefault() }
    case  UPDATE_EXHIBITION_SPEAKER_FAILURE:
      return {...state, isFetching: false, error: action.payload }

    // Delete Speaker  
    case  DELETE_EXHIBITION_SPEAKER_REQUEST:
      return {...state, isFetching: true }
    case  DELETE_EXHIBITION_SPEAKER_SUCCESS:
      return {...state, isFetching: false, error: ApplicationError.buildDefault() }
    case  DELETE_EXHIBITION_SPEAKER_FAILURE:
      return {...state, isFetching: false, error: action.payload }


    default: 
      return state
  }
}

export default speakersReducer;