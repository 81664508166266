import Keynote from "../models/Keynote";
import BaseMapper from "./BaseMapper";
import UserMapper from "./UserMapper";
import DocumentMapper from "./DocumentMapper";
import BookmarkMapper from "./BookmarkMapper";
import ImageMapper from "./ImageMapper";
import ConversationMapper from "./ConversationMapper";
import LocalTextMapper from "./LocalTextMapper";
import ExhibitorMapper from './ExhibitorMapper';

const attributes = Keynote.attributes;

export default class KeynoteMapper {
  static build = (json) => {
    if (!json) {
      return;
    }

    return new Keynote({
      ...BaseMapper.attributes(json),
      title: json[attributes.title],
      shortKey: json[attributes.shortKey],
      description: json[attributes.description],
      keynoteType: json[attributes.keynoteType],
      approvedAt: json[attributes.approvedAt]
        ? new Date(json[attributes.approvedAt])
        : undefined,
      startAt: json[attributes.startAt]
        ? new Date(json[attributes.startAt])
        : undefined,
      endAt: json[attributes.endAt]
        ? new Date(json[attributes.endAt])
        : undefined,
      speakers: UserMapper.buildFromArray(json.speakers),
      moderators: UserMapper.buildFromArray(json.moderators),
      documents: DocumentMapper.buildFromArray(json.documents),
      image: ImageMapper.build(json.image),
      status: json[attributes.status],
      isBookmarked: json[attributes.isBookmarked],
      isRegistered: json[attributes.isRegistered],
      bookmark: BookmarkMapper.build(json[attributes.bookmark]),
      exhibitor: ExhibitorMapper.build(json[attributes.exhibitor]),
      conversation: ConversationMapper.build(json.conversation),
      thematicAreaId: json[attributes.thematicAreaId],
      streamingUrl: json[attributes.streamingUrl],
      running: json[attributes.running],
      disconnected: json[attributes.disconnected],
      temporaryEnabled: json[attributes.temporaryEnabled],
      registeredUsersCount: json[attributes.registeredUsersCount],
      exhibitionId: json[attributes.exhibitionId],
      localDescription: LocalTextMapper.buildFromArray(json[attributes.localDescription]),
      language: json[attributes.language]
    });
  };

  static buildFromArray = (jsonArray = []) => {
    return jsonArray.map((entry) => {
      return this.build(entry);
    });
  };

  static toJsonString(keynote) {
    return JSON.stringify({
      [attributes.title]: keynote.getTitle(),
      [attributes.status]: keynote.getStatus(),
      [attributes.endAt]: keynote.getEndAt(),
      [attributes.startAt]: keynote.getStartAt(),
      [attributes.shortKey]: keynote.getShortKey(),
      [attributes.approvedAt]: keynote.getApprovedAt(),
      [attributes.keynoteType]: keynote.getKeynoteType(),
      [attributes.description]: keynote.getDescription(),
      [attributes.streamingUrl]: keynote.getStreamingUrl(),
      [attributes.disconnected]: keynote.getDisconnected(),
      [attributes.thematicAreaId]: keynote.getThematicAreaId(),
      [attributes.temporaryEnabled]: keynote.getTemporaryEnabled()
    });
  }
}
