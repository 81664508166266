import ApplicationModel from "./ApplicationModel";
import MyStore from "../utils/MyStore";

export const INTERNAL_IDS = {
  CUSTOMER: 'Customer',
  AUTHENTICATED: 'Authenticated',
  EXHIBITOR: 'Exhibitor',
  VISITOR: 'Visitor',
}

export default class SystemRole extends ApplicationModel {

  constructor(_opts = {}){
    super(_opts)
    this.state = { ..._opts }
  }

  getName = () => {
    let name = MyStore.getVisitorView() ? INTERNAL_IDS.VISITOR : this.state.name;
    console.log(name);
    return name;
  }
  getInternalId = () => this.state.internalId
  showPreEvent = () => {
    
    return false
  }

  // Backend Attributes
  static attributes = {
    ...super.attributes,
    name: 'name',
    internalId: "internal_id",
  }

  static orderByParams = {
    ...super.orderByParams
  }

  static filterParams = {
    ...super.filterParams,
  }
}