import React, { Suspense, useEffect } from 'react';
import { renderRoutes } from 'react-router-config';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { LinearProgress } from '@material-ui/core';
import Topbar from './Topbar';
import { useSelector } from 'react-redux';
import MyStore from '../../../utils/MyStore';
import getBrowserLocales from '../../../utils/GetBrowserLocales';

const useStyles = makeStyles((theme) => ({
  container: {
    height: '100vh',
    display: 'flex',
    '@media all and (-ms-high-contrast:none)': {
      height: 0 // IE11 fix
    }
  },
  content: {
    flexGrow: 1,
    height: '100%',
    maxWidth: '100%',
    overflowX: 'hidden',
  }
}));

function Auth({ route }) {
  const classes = useStyles();
  
  const usersReducer = useSelector(state => state.usersReducer)

  useEffect(() => {
    if (!usersReducer.currentUser) {
      return
    }

    var userLocale = usersReducer.currentUser.getLocale();
    if (!userLocale) {
      let browserLocales = getBrowserLocales({languageCodeOnly: true})
      userLocale = browserLocales.find(e => e in {en: "en", de: "de", nl: "nl"})
    }

    MyStore.setLocal(userLocale || "en");
  }, [usersReducer.currentUser])

  return (
    <>
      <div className={classes.container}>
        <div className={classes.content}>
          <Suspense fallback={<LinearProgress />}>
            {renderRoutes(route.routes)}
          </Suspense>
        </div>
      </div>
    </>
  );
}

Auth.propTypes = {
  route: PropTypes.object
};

export default Auth;
