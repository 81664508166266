import {
  FETCHING_EXHIBITION_MEETINGS_REQUEST,
  FETCHING_EXHIBITION_MEETINGS_SUCCESS,
  FETCHING_EXHIBITION_MEETINGS_FAILURE,
  
  FETCHING_EXHIBITION_MEETING_REQUEST,
  FETCHING_EXHIBITION_MEETING_SUCCESS,
  FETCHING_EXHIBITION_MEETING_FAILURE,

  CREATE_EXHIBITION_MEETING_FAILURE,
  CREATE_EXHIBITION_MEETING_SUCCESS,
  CREATE_EXHIBITION_MEETING_REQUEST,

  UPDATE_EXHIBITION_MEETING_FAILURE,
  UPDATE_EXHIBITION_MEETING_SUCCESS,
  UPDATE_EXHIBITION_MEETING_REQUEST,

  DELETE_EXHIBITION_MEETING_FAILURE,
  DELETE_EXHIBITION_MEETING_SUCCESS,
  DELETE_EXHIBITION_MEETING_REQUEST,
} from '../actions/types';
import ResponseService from '../../utils/ResponseService';
import ApplicationError from '../../utils/ApplicationError';

const initialState = {
  meetings: [],
  meeting: undefined,
  error: ApplicationError.buildDefault(),
  isFetching: false,
  ...ResponseService.defaultPaginationMetaData()
};

const meetingsReducer = (state = initialState, action ) => {
  switch(action.type) {
    case FETCHING_EXHIBITION_MEETINGS_REQUEST:
      return {...state, isFetching: true }
    case FETCHING_EXHIBITION_MEETINGS_SUCCESS:
      return {...state, isFetching: false, 
        meetings: action.payload.objects, 
        error: ApplicationError.buildDefault(),
        ...action.payload.paginationData }
        
    case FETCHING_EXHIBITION_MEETINGS_FAILURE:
      return {...state, isFetching: false, error: action.payload }
    
    case FETCHING_EXHIBITION_MEETING_REQUEST:
      return {...state, isFetching: true }
    case FETCHING_EXHIBITION_MEETING_SUCCESS: 
      return {...state, isFetching: false, meeting: action.payload, error: ApplicationError.buildDefault() }
    case FETCHING_EXHIBITION_MEETING_FAILURE:
      return {...state, isFetching: false, error: action.payload }  


    case  CREATE_EXHIBITION_MEETING_REQUEST:
      return {...state, isFetching: true }
    case  CREATE_EXHIBITION_MEETING_SUCCESS:
      return {...state, isFetching: false, meeting: action.payload, error: ApplicationError.buildDefault() }
    case  CREATE_EXHIBITION_MEETING_FAILURE:
      return {...state, isFetching: false, error: action.payload }

    case  UPDATE_EXHIBITION_MEETING_REQUEST:
      return {...state, isFetching: true, twilioConfiguration: undefined }
    case  UPDATE_EXHIBITION_MEETING_SUCCESS:
      return {...state, isFetching: false, meeting: action.payload, error: ApplicationError.buildDefault() }
    case  UPDATE_EXHIBITION_MEETING_FAILURE:
      return {...state, isFetching: false, error: action.payload }

    case  DELETE_EXHIBITION_MEETING_REQUEST:
      return {...state, isFetching: true }
    case  DELETE_EXHIBITION_MEETING_SUCCESS:
      return {...state, isFetching: false, error: ApplicationError.buildDefault() }
    case  DELETE_EXHIBITION_MEETING_FAILURE:
      return {...state, isFetching: false, error: action.payload }


    default: 
      return state
  }
}

export default meetingsReducer;