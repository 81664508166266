import "@fullcalendar/core/main.css";
import listPlugin from "@fullcalendar/list";
import "@fullcalendar/list/main.css";
import FullCalendar from "@fullcalendar/react";
// import interactionPlugin from "@fullcalendar/interaction";
// import dayGridPlugin from "@fullcalendar/daygrid";
// import "@fullcalendar/daygrid/main.css";
// import timeGridPlugin from "@fullcalendar/timegrid";
// import "@fullcalendar/timegrid/main.css";
// import timelinePlugin from "@fullcalendar/timeline";
import {
  Card,
  colors,
  Container,
  LinearProgress,
  Modal,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import marked from "marked";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { MAX_PAGE_SIZE } from "../../../constants/Urls";
import {
  clearKeynotes,
  fetchKeynotes,
} from "../../../redux/actions/KeynotesActions";
import { compareIds } from "../../../utils";
import CogoToast from "../../../utils/CogoToast";
import FilterParams from "../../../utils/FilterParams";
import MyStore from "../../../utils/MyStore";
import ExhibitionHeader from "../../components/ExhibitionHeader";
import Page from "../../components/Page";
import useExhibitionDates from "../../engines/Util/useExhibitionDates";
import { MODAL_TYPE } from "../../layouts/Dashboard/components/GlobalModal";
import GlobalContext from "../../layouts/Dashboard/context/GlobalContext";
import FrontendRoutes from "./../../../constants/FrontendRoutes";
import AddEditEvent from "./AddEditEvent";
import formatTime from "./formatTime";
import Header from "./Header";
import useStyles from "./useStyles";

const Outer = ({ rootClassName, exhibition, children }) => (
  <Page className={rootClassName}>
    <ExhibitionHeader exhibition={exhibition} />
    {children}
  </Page>
);

function Agenda() {
  const classes = useStyles();
  const history = useHistory();
  const calendarRef = useRef(null);
  const context = useContext(GlobalContext);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const exhibitionsReducer = useSelector((state) => state.exhibitionsReducer);
  const keynotesReducer = useSelector((state) => state.keynotesReducer);
  const usersReducer = useSelector((state) => state.usersReducer);

  const [currentDate, setCurrentDate] = useState(new Date());

  const [eventModal, setEventModal] = useState({
    open: false,
    event: null,
  });

  const dateOptions = useMemo(() => {
    if (!exhibitionsReducer.exhibition) {
      return {
        minTime: "12:00",
        maxTime: "22:00",
        days: 3,
      };
    } else {
      const startAt = exhibitionsReducer.exhibition.getStartAt();
      const endAt = exhibitionsReducer.exhibition.getEndAt();

      return {
        minTime: formatTime(startAt),
        maxTime: formatTime(endAt),
        days: parseInt((endAt - startAt) / (1000 * 60 * 60 * 24) + 1),
      };
    }
  }, [exhibitionsReducer.exhibition]);

  const [dates] = useExhibitionDates(dateOptions.days);

  const events = useMemo(() => {
    if (keynotesReducer.isFetching || keynotesReducer.error.isError()) {
      return [];
    }

    return keynotesReducer.keynotes.map((keynote) => ({
      id: keynote.getId(),
      title:
        "<h5><b>" +
        keynote.getTitle() +
        "</b></h5>" +
        marked(keynote.getDescription(MyStore.getLocal()) || ""),
      start: keynote.getStartAt(),
      end: keynote.getEndAt(),
      className: classes.event,
    }));
  }, [
    classes.event,
    keynotesReducer.error,
    keynotesReducer.isFetching,
    keynotesReducer.keynotes,
  ]);

  const handleEventClick = (info) => {
    if (!usersReducer.currentUser) {
      return;
    }

    const selected = keynotesReducer.keynotes.find((keynote) =>
      compareIds(keynote.getId(), info.event.id)
    );

    if (selected && selected.getStreamingUrl()) {
      context.openGlobalModal(MODAL_TYPE.KEYNOTE_MODAL, selected.getId());
    } else if (selected) {
      CogoToast.info(t("frontend.keynote_not_available"));
    }
  };

  const handleModalClose = () => {
    setEventModal({
      open: false,
      event: null,
    });
  };

  const gotoKeynote = (keynote) => {
    if (usersReducer.currentUser) {
      history.push(FrontendRoutes.workshops.show(keynote.getId()));
    }
  };

  useEffect(() => {
    return () => {
      dispatch(clearKeynotes());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (exhibitionsReducer.exhibition) {
      dispatch(
        fetchKeynotes(exhibitionsReducer.exhibition.getId(), {
          pageSize: MAX_PAGE_SIZE,
          filters: [FilterParams.filterBy("language", MyStore.getLocal())],
        })
      );
    }
  }, [dispatch, exhibitionsReducer.exhibition]);

  useEffect(() => {
    if (!calendarRef.current) return;

    const calendarApi = calendarRef.current.getApi();
    calendarApi.changeView("listDay", currentDate);
  }, [currentDate]);

  useEffect(() => {
    if (dates.length > 0) {
      if (dates[dates.length - 1] < currentDate) {
        setCurrentDate(dates[dates.length - 1]);
      } else if (dates[0] > currentDate) {
        setCurrentDate(dates[0]);
      } else {
        const now = new Date();
        setCurrentDate(
          new Date(now.getFullYear(), now.getMonth(), now.getDate())
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dates]);

  if (keynotesReducer.isFetching) {
    return (
      <Outer
        rootClassName={classes.root}
        exhibition={exhibitionsReducer.exhibition}
      >
        <LinearProgress />
      </Outer>
    );
  }

  if (keynotesReducer.error.isError()) {
    return (
      <Outer
        rootClassName={classes.root}
        exhibition={exhibitionsReducer.exhibition}
      >
        <Typography color="error">
          {keynotesReducer.error.getErrorMessage()}
        </Typography>
      </Outer>
    );
  }

  return (
    <Outer
      rootClassName={classes.root}
      exhibition={exhibitionsReducer.exhibition}
    >
      <Container style={{ overflowY: "auto" }} maxWidth={false}>
        <Header />
        <Tabs
          scrollButtons="auto"
          variant="scrollable"
          value={currentDate.getTime()}
          onChange={(_, value) => setCurrentDate(new Date(value))}
        >
          {dates.map((d) => (
            <Tab
              value={d.getTime()}
              key={d.getTime()}
              label={d.toLocaleDateString()}
            />
          ))}
        </Tabs>
        <Card className={classes.card}>
          <FullCalendar
            view
            weekends
            defaultDate={currentDate}
            defaultView="listDay"
            minTime={dateOptions.minTime || "12:00"}
            maxTime="23:59" //dateOptions.maxTime || '18:00'}
            slotDuration="00:05"
            eventClick={handleEventClick}
            height="auto"
            events={events}
            eventTimeFormat={{
              hour: "2-digit",
              minute: "2-digit",
              omitZeroMinute: false,
              meridiem: false,
              hour12: false,
            }}
            slotLabelFormat={{
              hour: "2-digit",
              minute: "2-digit",
              omitZeroMinute: false,
              meridiem: false,
              hour12: false,
            }}
            eventRender={(info) => {
              info.el.querySelector(".fc-list-item-title").innerHTML =
                info.event.title;

              const dotEl = info.el.getElementsByClassName("fc-event-dot")[0];
              if (!dotEl) return;

              dotEl.style.backgroundColor = colors.grey[400];

              if (info.event.classNames.includes(classes.other)) {
                dotEl.style.backgroundColor = colors.lightBlue[50];
              }

              const now = Date.now();
              if (info.event.start <= now && info.event.end >= now) {
                dotEl.style.backgroundColor = "green";
              }
            }}
            header={false}
            plugins={[
              listPlugin,
              // dayGridPlugin,
              // timeGridPlugin,
              // interactionPlugin,
              // timelinePlugin,
            ]}
            duration={{ days: dateOptions.days || 1 }}
            ref={calendarRef}
            locale={MyStore.getLocal() || "en"}
          />
        </Card>
        <Modal onClose={handleModalClose} open={eventModal.open}>
          <AddEditEvent
            event={eventModal.event}
            onClose={handleModalClose}
            onGoto={gotoKeynote}
          />
        </Modal>
      </Container>
    </Outer>
  );
}

export default Agenda;
