import { Grid, LinearProgress, makeStyles } from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react'
import { useContext } from 'react';
import NeumorphicStyle from '../../../../../constants/Neumorphic';
import { DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE } from '../../../../../constants/Urls';
import useInfiniteScoll from '../../../../../engines/GeneralHooks/useInfiniteScoll';
import useVisitorsQL from '../../../../../engines/GraphQL/hooks/useVisitorsQL';
import useExhibition from '../../../../engines/GeneralHooks/useExhibition';
import BookmarkSwitch from '../../components/BookmarkSwitch';
import TagArea from '../../components/TagArea';
import VisitorCard from '../../components/VisitorCard/Mobile'
import GlobalContext from '../../context/GlobalContext';

const useStyles = makeStyles(theme => ({
    root: {
        paddingTop: theme.spacing(5),
        paddingBottom: theme.spacing(3),
        overflow: "auto",
        height:"100%",
        paddingLeft: 20,
        paddingRight: 20,
    },
    tagArea: {
        width: "98.5%",
        marginBottom: 16,
    },
    visitorContainer: {
        width: "100%",
        marginLeft: 20
    },
    switch: {
        marginRight: 0,
        marginBottom: -80,
        marginTop: 4
    }
}));

function VisitorView() {
    const classes = useStyles();
    const containerRef = useRef();
    const [exhibition] = useExhibition();
    const [searchText, setSearchText] = useState()
    const [showBookmarks, setShowBookmarks] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(DEFAULT_PAGE_INDEX);
    const [visitors, loading, totalPages, refetchBookmarks, fetchNewData] = useVisitorsQL(searchText,currentIndex,DEFAULT_PAGE_SIZE,showBookmarks);
    const context = useContext(GlobalContext);
    const [currentPageIndex, isBottom, resetPageIndex] = useInfiniteScoll(containerRef.current,totalPages,true,loading);

    useEffect(() => {
        if (currentPageIndex === currentIndex) {
            return
        }
        setCurrentIndex(currentPageIndex);
    }, [currentPageIndex])

    useEffect(() => {
        resetPageIndex();
        fetchNewData();
    }, [context.tags])

    useEffect(() => {
        if (!visitors || visitors.length === 0) {
            return;
        }
        resetPageIndex();
        refetchBookmarks();
    }, [showBookmarks])

    const updateFeed = () => {
        if (showBookmarks) {
            setCurrentIndex(DEFAULT_PAGE_INDEX);
            fetchNewData();
        }
    }
    
    return !visitors ? (<div />) : (
        <div className={classes.root} ref={containerRef}>
            <div className={classes.switch}>
                <BookmarkSwitch showBookmarks={showBookmarks} setShowBookmarks={setShowBookmarks} />
            </div>
            <Grid container className={classes.tagArea}>
                <TagArea resetPageIndex={() => resetPageIndex()} setSearchText={(text) => setSearchText(text)} exhibition={exhibition} />
            </Grid>
            {loading && visitors.length === 0 && <LinearProgress />}
            <Grid container justify="flex-start" direction="row" spacing={3} className={classes.visitorContainer}>
                {visitors.map(visitor => (
                    <Grid item>
                        <VisitorCard visitor={visitor} refetchBookmarks={() => updateFeed()}/>
                    </Grid>
                ))}
            </Grid>
            <div style={{height: 80}}/>
        </div>
    )
}

export default VisitorView
