import { Box, Grid, LinearProgress, makeStyles } from "@material-ui/core";
import React, { useContext, useEffect, useRef, useState } from "react";
import { DEFAULT_PAGE_INDEX } from "../../../../../constants/Urls";
import useExhibition from "../../../../../engines/GeneralHooks/useExhibition";
import useInfiniteScoll from "../../../../../engines/GeneralHooks/useInfiniteScoll";
import { useSponsorsQL } from "../../../../../engines/GraphQL/hooks/useExhibitorsQL";
import BookmarkSwitch from "../../components/BookmarkSwitch";
import ExhibitorCard from "../../components/ExhibitorCard";
import TagArea from "../../components/TagArea";
import GlobalContext from "../../context/GlobalContext";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(5),
    //paddingBottom: theme.spacing(3),
    overflowY: "auto",
    overflowX: "hidden",
    paddingTop: "32px",
    width: "100%",
    height: "100%",
    marginLeft: 20,
    paddingLeft: 20,
    paddingRight: 20,
  },
  tagArea: {
    marginRight: 50,
  },
}));

function SponsorView({ setCurrentExhibitorList }) {
  const classes = useStyles();
  const containerRef = useRef();
  const { currentExhibitor, currentUser, setCurrentUser, setCurrentExhibitor } =
    useContext(GlobalContext);
  const [showBookmarks, setShowBookmarks] = useState(false);
  const [searchText, setSearchText] = useState();
  const [currentIndex, setCurrentIndex] = useState(DEFAULT_PAGE_INDEX);
  const [exhibitors, loading, totalPages, refetchBookmarks, fetchNewData] =
    useSponsorsQL(searchText, currentIndex, 15, showBookmarks);
  const [exhibition] = useExhibition();
  const context = useContext(GlobalContext);

  const [currentPageIndex, isBottom, resetPageIndex] = useInfiniteScoll(
    containerRef.current,
    totalPages,
    true,
    loading
  );

  useEffect(() => {
    if (searchText && searchText !== "") {
      resetPageIndex();
    }
  }, [searchText]);

  useEffect(() => {
    if (currentPageIndex === currentIndex) {
      return;
    }
    setCurrentIndex(currentPageIndex);
    fetchNewData();
  }, [currentPageIndex]);

  useEffect(() => {
    resetPageIndex();
    fetchNewData();
  }, [context.tags]);

  useEffect(() => {
    if (!exhibitors || exhibitors.length === 0) {
      return;
    }
    resetPageIndex();
    refetchBookmarks();
  }, [showBookmarks]);

  const updateFeed = () => {
    if (showBookmarks) {
      resetPageIndex();
      fetchNewData();
    }
  };

  useEffect(() => {
    setCurrentExhibitorList(exhibitors);
  }, [exhibitors, setCurrentExhibitorList]);

  return !exhibitors ? (
    <div />
  ) : (
    <div ref={containerRef} className={classes.root}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={5}
        mr={5}
      >
        <TagArea
          fullSearchWidth
          searchOnly={true}
          resetPageIndex={() => resetPageIndex()}
          setSearchText={(text) => setSearchText(text)}
          exhibition={exhibition}
          showRange={false}
          showTags={false}
        />
        <BookmarkSwitch
          showBookmarks={showBookmarks}
          setShowBookmarks={setShowBookmarks}
        />
      </Box>

      {loading && <LinearProgress />}
      <Box height="100%">
        <Grid container direction="row">
          {exhibitors.map((exhibitor) =>
            exhibitor.isBookmarked() || !showBookmarks ? (
              <ExhibitorCard
                refetchBookmarks={() => updateFeed()}
                setExhibitor={setCurrentExhibitor}
                exhibitor={exhibitor}
              />
            ) : undefined
          )}
        </Grid>
        <Box height="1%"/>
      </Box>
    </div>
  );
}

export default SponsorView;
