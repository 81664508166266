export default class Tag {
  constructor(_opts = {}) {
    this.state = { freeSlots: [], ..._opts };
  }

  getName = (language) => {
    if (this.state.localName && this.state.localName.length > 0 && language)  {
      const desc = this.state.localName.find(element => element.getLanguage() === language);
      if (desc && desc.getName()) {
        return desc.getName();
      }
    }
    return this.state.name
  };
  isAdditional = () => this.state.additional || false;
  getLocalName = () => this.localName || []

  static attributes = {
    name: 'name',
    additional: "additional",
    localName: "local_name",
  } 

  static filterParams = {
    name: 'name'
  } 
}
