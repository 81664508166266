import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux';

export default function useExhibition() {
    const [exhibition, setExhibition] = useState();

    const exhibitionsReducer = useSelector(state => state.exhibitionsReducer);

    useEffect(() => {
        if (!exhibitionsReducer.exhibition) {
            return
        }

        setExhibition(exhibitionsReducer.exhibition);
    }, [exhibitionsReducer.currentSet])

    return [exhibition]
}