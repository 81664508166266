import MeetingsUser from "../models/MeetingsUser";
import BaseMapper from "./BaseMapper";
import ErrorLogger from "../utils/ErrorLogger";
import UserMapper from "./UserMapper";

const attributes = MeetingsUser.attributes;

export default class MeetingsUserMapper {
  static build = (json = undefined) => {
    if (!json) {
      return;
    }

    try {
      return new MeetingsUser({
        ...BaseMapper.attributes(json),
        user: UserMapper.build(json.user),
        status: json[attributes.status],
        userFullName: json.user.first_name + " " + json.user.last_name,
        meetingId: undefined,
      });
    } catch (error) {
      ErrorLogger.call(this, error);
    }
  };

  static buildFromArray = (jsonArray = []) => {
    return jsonArray.map((entry) => {
      return this.build(entry);
    });
  };

  static toJsonString = (meetingsUser) => {
    return JSON.stringify({
      [attributes.meetingId]: meetingsUser.getMeetingId(),
      user: meetingsUser.getUser().state,
      [attributes.status]: meetingsUser.getStatus(),
    });
  };
}
