import { makeStyles } from '@material-ui/styles';
import React, { useEffect, useState } from 'react';
import { Avatar, CircularProgress, Grid, Theme, Typography } from '@material-ui/core';
import StarIcon from "@material-ui/icons/Star";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import CogoToast from '../../../../../utils/CogoToast';
import { useDispatch, useSelector } from 'react-redux';
import { bookmarkVisitor } from '../../../../../redux/actions/VisitorsActions';
import { useTranslation } from 'react-i18next';

interface LbDynamicVisitorCardProps {
    visitor: any;
    loading: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        backgroundColor: "rgba(255,255,255,0.8)",
        borderRadius: '1.5em',
        height: "3em",
        display: 'flex',
        justifyContent: "flex-start",
        alignContent: "center",
        padding: '.8rem',
        fontSize: 'clamp(1rem, 3vw, 1.5rem)',
        zIndex: 3
    },
    avatar: {
        fontSize: 'inherit',
        height: '2.35em',
        width: '2.35em',
        marginRight: '1em',
    },
    name: {
        fontSize: "0.9em",
        lineHeight: "1.2em",
        alignSelf: "center",
        marginRight: '0.75em',
        maxWidth: '20vw'
    },
    progress: {
        fontSize: "1.3em",
        width: "1.3rem",
        height: "1.3rem",
        color: theme.palette.secondary.light,
        alignSelf: "center",
    },
    bookmarkIcon: {
        fontSize: "1.3em",
        alignSelf: "center",
        cursor: "pointer",
    },
    bookmarkIconFilled: {
        fontSize: "1.3em",
        alignSelf: "center",
        cursor: "pointer",
        color: theme.palette.secondary.light,
    },
}))

//Card should later extend so that on hover aditional informations are visible.

const LbDynamicVisitorCard: React.FC<LbDynamicVisitorCardProps> = ({visitor, loading}) => {
    const classes = useStyles();
    const [bookmarked, setBookmarked] = useState(false);
    const [unbookmarked, setUnbookmarked] = useState(false);
    const [bookmarkLoading, setBookmarkLoading] = useState(false);
    const dispatch = useDispatch();
    const visitorsReducer = useSelector(state => (state as any).visitorsReducer);
    const {t} = useTranslation();

    useEffect(() => {
        if (!unbookmarked) {
          return;
        }
    
        dispatch(bookmarkVisitor(visitor));
        setBookmarkLoading(true);
      }, [unbookmarked]);
    
      useEffect(() => {
        if (!bookmarked) {
          return;
        }
    
        dispatch(bookmarkVisitor(visitor));
        setBookmarkLoading(true);
      }, [bookmarked]);
    
      // Bookmark
      useEffect(() => {
        if (
          (!bookmarked && !unbookmarked) ||
          !bookmarkLoading ||
          visitorsReducer.isFetching ||
          visitorsReducer.error.isError()
        ) {
          return;
        }
    
        if (bookmarked) {
          visitor.setIsBookmarked(true);
          CogoToast.info(t("frontend.added_bookmark"));
        }
    
        if (unbookmarked) {
          visitor.setIsBookmarked(false);
          CogoToast.info(t("frontend.removed_bookmark"));
        }
    
        setBookmarkLoading(false);
        setBookmarked(false);
        setUnbookmarked(false);
      }, [visitorsReducer.isFetching]);
        
      const handleBookmark = () => {
        visitor.isBookmarked() ? setUnbookmarked(true) : setBookmarked(true)
      }
  

    return (
        <Grid container direction="row" className={classes.root}>
            {loading ? <CircularProgress />:(<Avatar className={classes.avatar} src={visitor?.getProfilePicture()?.getUrl('small')} />)}
            <Typography noWrap variant="h4" className={classes.name}>{`${visitor?.getFirstName()} ${visitor?.getLastName()}`}</Typography>
            {bookmarkLoading ? (
                <CircularProgress className={classes.progress} size="1.3rem"/>
            ): visitor.isBookmarked() ? (
                <StarIcon className={classes.bookmarkIconFilled} onClick={() => handleBookmark()}/>
            ) : (
                <StarBorderIcon className={classes.bookmarkIcon} onClick={() => handleBookmark()}/>
            )}
        </Grid>
    )
}

export default LbDynamicVisitorCard
