import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import {
  Card,
  CardHeader,
  CardContent,
  Typography,
  Radio,
  colors,
  Grid,
  Avatar,
} from "@material-ui/core";
import { useSelector } from "react-redux";
import { fetchExhibitorsUsers } from "../../../../redux/actions/ExhibitorsUsersActions";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {},
  option: {
    border: `1px solid ${theme.palette.divider}`,
    display: "flex",
    alignItems: "flex-start",
    padding: theme.spacing(1),
    maxWidth: 560,
    "& + &": {
      marginTop: theme.spacing(2),
    },
  },
  selectedOption: {
    backgroundColor: colors.grey[50],
  },
  optionRadio: {
    margin: -5,
    alignSelf: "center"
  },
  optionDetails: {
    display: 'flex',
    marginLeft: theme.spacing(2),
  },
  profilePicture: {
    height: theme.spacing(4),
    width: theme.spacing(4),
    borderRadius: "50%",
    marginRight: theme.spacing(3),
    display: "flex",
    alignSelf: "center"
  },
}));

function AboutAuthor({ handleSelectUser, exhibitorsUsers, className, ...rest }) {
  const classes = useStyles();
  const [selected, setSelected] = useState();
  const { t } = useTranslation();

  const handleChange = (event, user) => {
    handleSelectUser(user)
    setSelected(user.getId());
  };

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader title={t("frontend.choose_for_talking")} />
      <CardContent>
        {exhibitorsUsers.map((user) => (
          <div
            className={clsx(classes.option, {
              [classes.selectedOption]: selected === user.getId(),
            })}
            key={user.getId()}
          >
            <Radio
              checked={selected === user.getId()}
              className={classes.optionRadio}
              color="primary"
              onClick={(event) => handleChange(event, user)}
            />
            <div className={classes.optionDetails}>
              <Avatar
                src={user.getProfilePicture() ? user.getProfilePicture().getUrl("thumbnail"):""}
                className={classes.profilePicture}
              />
              <Grid container direction="column" spacing={0}>
                <Grid item>
                  <Typography gutterBottom variant="h5">
                    {user.getFullName()}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body1"> {user.getProfession()}</Typography>
                </Grid>
              </Grid>
            </div>
          </div>
        ))}
      </CardContent>
    </Card>
  );
}

AboutAuthor.propTypes = {
  className: PropTypes.string,
};

export default AboutAuthor;
