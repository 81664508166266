import React, { useState, useRef, useEffect } from 'react'
import useVisitors from '../../../engines/GeneralHooks/useVisitors'
import { DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE } from '../../../constants/Urls'
import { LinearProgress, Typography, Grid, Avatar, IconButton, Input, Link as MaterialLink } from '@material-ui/core';
import FilterParams from '../../../utils/FilterParams';
import User from '../../../models/User';
import { Link } from 'react-router-dom';
import FrontendRoutes from '../../../constants/FrontendRoutes';
import AddIcon from '@material-ui/icons/Add';
import useVisitorsQL from '../../../engines/GraphQL/hooks/useVisitorsQL';
import { useTranslation } from 'react-i18next';
import { Search as SearchIcon } from '@material-ui/icons';
import MyStore from '../../../utils/MyStore';

function sleep(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}

function VisitorsList({ searchString, handleChatStart }) {
  const containerRef = useRef();
  const [pageIndex, setPageIndex] = useState(DEFAULT_PAGE_INDEX);
  const [pageSize, setPageSize] = useState(20);
  const [filters, setFilters] = useState([]);
  const { t } = useTranslation();
  const [searchInput, setSearchInput] = useState();
  const [searchText, setSearchText] = useState();
  const [visitors, loading, totalPages] = useVisitorsQL(searchText, pageIndex, pageSize, false, true);
  const [loadMore, setLoadMore] = useState(false);

  const resetPageIndex = () => {
    setPageIndex(DEFAULT_PAGE_INDEX);
  }

  useEffect(() => {
    if (!searchString || searchString.length === 0) {
      //setFilters([FilterParams.orderByAsc(User.orderByParams.lastName)]);
    } else {
      setFilters(filters.concat([FilterParams.filterBy(User.filterParams.search, searchString)]))
    }
  }, [searchString])

  useEffect(() => {
    if (!containerRef.current) {
      return;
    }

    containerRef.current.addEventListener('scroll', handleScroll);

    return () => {
      containerRef.current.removeEventListener('scroll', handleScroll);
    }
  }, [containerRef.current])

  const handleScroll = () => {
    var scrollBottom = containerRef.current.scrollTop + containerRef.current.clientHeight;
    if (scrollBottom > containerRef.current.scrollHeight - 5) {
      setLoadMore(true);
    }
  }

  useEffect(() => {
    if (!loadMore) {
      return;
    }
    if (pageIndex < totalPages) {
      setPageIndex(pageIndex + 1);
    }
    setLoadMore(false);
  }, [loadMore])

  useEffect(() => {
    if (!setSearchText) {
      return
    }
    let active = true;

    (async () => {
      await sleep(1e3);
      if (active) {
        resetPageIndex();
        setSearchText(searchInput);
      }
    })();

    return () => {
      active = false;
    }
  }, [searchInput])

  return (
    <Grid ref={containerRef} style={{ overflow: "auto", height: "100%", paddingBottom: 20, boxSizing: "content-box" }}>
      <Grid
        item
        container
        direction="row"
        alignItems="center"
        style={{ marginLeft: 20, marginRight: "auto", marginBottom: 15 }}
      >
        <Input
          type="search"
          placeholder={t('frontend.search')}
          style={{ width: 290 }}
          value={searchInput}
          onKeyUp={(event) => {
            if (event.keyCode === 13 || event.key === 13) {
              setSearchText(searchInput);
            }
          }}
          onChange={(e) => setSearchInput(e.target.value)}
        ></Input>
        {/* <IconButton
                    onClick={() => setSearchText(searchInput)}
                    size="30px"
                    style={{ padding: 0 }}
                >
                    <SearchIcon></SearchIcon>
                </IconButton> */}
      </Grid>
      {visitors.map(visitor => (
        <>
          {visitor && visitor.getId().toString() !== MyStore.getCurrentUser().getId().toString() && (
            <Grid container direction="row" style={{ padding: "12px" }}>
              <Grid container item xs={3} justify="center" alignItems="center" style={{ position: "relative" }}>
                <div style={{
                    position: "absolute",
                    width: "10px",
                    height: "10px",
                    backgroundColor: visitor.isOnline() ? "#44B700" : "#853434",
                    zIndex: "100",
                    borderRadius: "50%",
                    bottom: "5px",
                    right: "15px"
                  }}
                />
                <Avatar style={{ cursor: "pointer" }} onClick={() => handleChatStart(visitor)} variant="round" src={visitor.getProfilePicture() ? visitor.getProfilePicture().getUrl("thumbnail") : ""} />
              </Grid>
              <Grid container item xs={7} justify="center" alignItems="center">
                <MaterialLink onClick={() => handleChatStart(visitor)}>
                  <Typography style={{ textAlign: "center" }} variant="h6">{visitor.getFullName()}</Typography>
                </MaterialLink>
              </Grid>
              <Grid container item xs={2} justify="center" alignItems="center">
                <IconButton onClick={() => handleChatStart(visitor)}>
                  <AddIcon />
                </IconButton>
              </Grid>
            </Grid>
          )}
        </>
      ))}
      {loading &&
        <LinearProgress />
      }
    </Grid>
  )
}

export default VisitorsList
