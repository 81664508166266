import { makeStyles } from "@material-ui/styles";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router";
import NeumorphicStyle from "../../../constants/Neumorphic";
import useBreakpoints from "../../../engines/Util/useBreakpoints";
import useTracking, { VISIT_TRACKING_TYPES } from "../../engines/tracking/useTracking";
import GlobalContext from "./context/GlobalContext";

const useStyles = makeStyles((theme) => ({
  link: {
    display: "block",
    cursor: ({ url }) => (url ? "pointer" : "default"),
  },
  image: {
    display: "block",
    width: ({ width, breakpoint }) => breakpoint <= 3 ? 0.714 * width : width,
    height: ({ height, breakpoint }) => breakpoint <= 3 ? 0.714 * height : height,
    objectFit: "cover",
    borderRadius: NeumorphicStyle.borderRadius,
    boxShadow: NeumorphicStyle.boxShadowLarge(
      theme.palette.primary.main,
      theme.palette.secondary.dark
    ),
  },
}));

const BannerBar = ({ banners, size = "small", exhibitionId }) => {
  const [breakpoint] = useBreakpoints();
  const context = useContext(GlobalContext);
  const contextRef = useRef();
  const [actualBanner, setActualBanner] = useState(
    banners[
    size === 'small' ?
      context.bottomBannerPosition :
      context.upBannerPosition
    ]
  );
  const [trackEvent] = useTracking();
  const classes = useStyles({
    height: size === "large" ? 120 : 60,
    width: size === "large" ? 350 : 275,
    url: !!actualBanner.banner_url,
    breakpoint,
  });
  let delay = actualBanner.seconds_show;

  const [intervalContext] = useState({ interval: null });
  const history = useHistory();

  useEffect(() => {
    contextRef.current = context;
  }, [context.upBannerPosition, context.bottomBannerPosition])

  const stopInterval = () => {
    if (intervalContext.interval) {
      clearInterval(intervalContext.interval);
    }
  };

  const showBanners = () => {
    stopInterval();
    const showBanner = () => {
      stopInterval();

      let startPosition = size === 'small' ?
        contextRef.current.bottomBannerPosition :
        contextRef.current.upBannerPosition

      setActualBanner(
        banners[banners.length - 1 === startPosition ? 0 : startPosition + 1]
      );
      delay =
        banners[banners.length - 1 === startPosition ? 0 : startPosition + 1]
          .seconds_show;
      startPosition =
        banners.length - 1 === startPosition ? 0 : startPosition + 1;
      
      if (size === 'small'){
        contextRef.current.setBottomBannerPosition(startPosition);       
      } else {
        contextRef.current.setUpBannerPosition(startPosition);
      }

      intervalContext.interval = setInterval(showBanner, delay * 1000);
    };
    showBanner();
  };

  useEffect(() => {
    trackEvent(size === "large" ? VISIT_TRACKING_TYPES.premiumBanner : VISIT_TRACKING_TYPES.bottomBanner, {
      bannerId: actualBanner.id,
      exhibition: exhibitionId,
    });
  }, [actualBanner.id]);

  useEffect(() => {
    showBanners();

    return () => {
      stopInterval();
    };
  }, [banners]);

  return (
    <a
      onClick={() => {
        if (actualBanner.banner_url) {
          trackEvent(size === "large" ? VISIT_TRACKING_TYPES.premiumBannerClicked : VISIT_TRACKING_TYPES.bottomBannerClicked, {
            bannerId: actualBanner.id,
            exhibition: exhibitionId,
          });
          if (actualBanner.external) {
            window.open(actualBanner.banner_url, "_blank");
          } else {
            history.push(actualBanner.banner_url);
          }
          
        }
      }}
      rel="noreferrer"
      className={classes.link}
    >
      <img
        src={actualBanner.banner_image.url}
        alt={actualBanner.banner_image.name}
        className={classes.image}
      />
    </a>
  );
};

export default BannerBar;
