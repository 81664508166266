import {
  FETCHING_SYSTEM_ROLES_REQUEST,
  FETCHING_SYSTEM_ROLES_SUCCESS,
  FETCHING_SYSTEM_ROLES_FAILURE,
  
  FETCHING_SYSTEM_ROLE_REQUEST,
  FETCHING_SYSTEM_ROLE_SUCCESS,
  FETCHING_SYSTEM_ROLE_FAILURE
} from '../actions/types';
import ResponseService from '../../utils/ResponseService';
import ApplicationError from '../../utils/ApplicationError';

const initialState = {
  systemRoles: [],
  systemRole: undefined,
  error: ApplicationError.buildDefault(),
  isFetching: false,
  ...ResponseService.defaultPaginationMetaData()
};

const systemRolesReducer = (state = initialState, action ) => {
  switch(action.type) {
    case FETCHING_SYSTEM_ROLES_REQUEST:
      return {...state, isFetching: true }
    case FETCHING_SYSTEM_ROLES_SUCCESS:
      return {...state, isFetching: false, 
        systemRoles: action.payload.objects, 
        error: ApplicationError.buildDefault(),
        ...action.payload.paginationData }
        
    case FETCHING_SYSTEM_ROLES_FAILURE:
      return {...state, isFetching: false, error: action.payload }
    
    case FETCHING_SYSTEM_ROLE_REQUEST:
      return {...state, isFetching: true }
    case FETCHING_SYSTEM_ROLE_SUCCESS: 
      return {...state, isFetching: false, systemRole: action.payload }
    case FETCHING_SYSTEM_ROLE_FAILURE:
      return {...state, isFetching: false, error: action.payload }  
    default: 
      return state
  }
}

export default systemRolesReducer;