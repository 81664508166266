import LocalTextMapper from "./LocalTextMapper";
import Terms from "../models/Terms";
import BaseMapper from "./BaseMapper";
import ErrorLogger from "../utils/ErrorLogger";
import MyStore from "../utils/MyStore";

const attributes = Terms.attributes;

export default class TermsMapper {
  static build = (json, bookmark = undefined) => {
    if (!json) {
      return;
    }

    try {
      return new Terms({
        ...BaseMapper.attributes(json),
        shareInformationDefault: json.share_information_default,
        newsletterDefault: json.newsletter_default,
        generalTermsDefault: json.general_terms_default,
        shareInformation: LocalTextMapper.buildFromArray(json.share_information),
        newsletter: LocalTextMapper.buildFromArray(json.newsletter),
        generalTerms: LocalTextMapper.buildFromArray(json.general_terms),
      });
    } catch (error) {
      ErrorLogger.call(this, error);
    }
  };

  static buildFromArray = (jsonArray = [], bookmarks = []) => {
    const resultArray = jsonArray.map((entry) => {
      const bm = bookmarks.find(b => b.getBookmarkableId() === entry.id);
      return this.build(entry, bm);
    });
    
    return resultArray.filter(a => a !== undefined);
  };

  static toJsonString(post) {
    return JSON.stringify({
      [attributes.visibleAt]: post.getVisibleAt(),
      [attributes.exhibitorId]: post.getExhibitorId(),
      [attributes.text]: post.getText(),
      [attributes.title]: post.getTitle(),
      [attributes.postType]: post.getPostType(),
      post_media: post.getPostMedia(MyStore.getLocal())
    });
  }
}
