export default class Configuration {
  constructor(_opts = {}) {
    this.state = { ..._opts };
  }

  static LOGIN_TYPE = {
    STANDARD: 'standard',
    MEMBER: 'member',
    MAIL_ONLY: 'mail_only'
  }
}