import { Avatar, Badge, Button, CircularProgress, FormControl, Grid, Input, InputAdornment, InputLabel, OutlinedInput, TextField, Typography } from '@material-ui/core';
import { Edit, Facebook, Instagram, LinkedIn, Lock, Save, Twitter } from '@material-ui/icons';
import { makeStyles, withStyles } from '@material-ui/styles';
import React, { useEffect } from 'react'
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { updateEventUser, updateUserRaw } from '../../../../../redux/actions/UsersActions';
import EditTagArea from '../../Leadbase/CustomComponents/EditTagArea'
import LeadbaseDropdown from '../../Leadbase/CustomComponents/LeadbaseDropdown';
import useExhibition from '../../../../engines/GeneralHooks/useExhibition'
import Image from '../../../../../models/Image';
import TagMapper from '../../../../../mapper/TagMapper';
import ChangePasswordModal from '../../../../views/UserProfile/ChangePasswordModal';
import ImageUploadModal from '../../../../components/ImageUploadModal/ImageUploadModal';
import { createUserImage, updateUserImage } from '../../../../../redux/actions/UsersImagesActions';
import useTracking, { EVENT_TRACKING_TYPES, VISIT_TRACKING_TYPES } from '../../../../engines/tracking/useTracking';

const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: theme.spacing(6),
        paddingBottom: theme.spacing(3),
        overflow: "auto",
        height:"100%",
        paddingLeft: 50,
        paddingRight: 50,
    },
    inputAdornment: {
        marginLeft: 8
    },
    nameContainer: {
        marginBottom: 30,
    },
    personalContainer: {
        marginBottom: 30,
    },
    contactContainer: {
        marginBottom: 30,
    },
    aboutContainer: {
        width: "100%",
        marginBottom: 30,
    },
    searchContainer: {
        width: "100%",
        marginBottom: 30,
    },
    socialContainer: {
        marginBottom: 30,
    },
    avatar: {
        marginRight: 15,
        height: 65,
        width: 65,
        objectFit: "cover"
    },
    avatarEdit: {},
    titleEdit: {
    },
    normalEdit: {
        padding: 0
    },
    genderDropdown: {},
    subtitle: {
        marginBottom: 15
    },
    contactEdit: {},
    aboutEdit: {
        width: "100%"
    },
    socialEdit: {},
    buttonContainer: {
        marginLeft: "auto",
    },
    passwordButton: {
        height: 35,
        width: "100%",
        marginBottom: 15,
        padding: 8,
        borderWidth: 1,
        borderColor: "black",
        borderStyle: "solid"
    },
    saveButton: {
        height: 35,
        background: theme.palette.secondary.light,
        color: theme.palette.secondary.contrastText,
        textTransform: "uppercase",
        width: "100%",
        "&:hover": {
            background: theme.palette.secondary.light,
            color: theme.palette.secondary.contrastText,
        }
    },
    backButton: {
        height: 35,
        background: theme.palette.background.default,
        color: theme.palette.text.primary,
        width: "100%",
    },
    formControl: {
        width: "90%",
        marginBottom: 8,
    },
    hint: {
        marginLeft: "auto"
    },
    editBadge: {
        cursor: "pointer",
        color: theme.palette.secondary.contrast,
        width: 15,
    },
}))

const StyledBadge = withStyles((theme) => ({
    badge: {
        height: 26,
        width: 26,
        borderRadius: 13
    },
}))(Badge);

function UserProfileEdit({ user, setEditMode }) {
    const classes = useStyles();
    const [t] = useTranslation();
    const [companyName, setCompanyName] = useState();
    const [firstName, setFirstName] = useState();
    const [lastName, setLastName] = useState();
    const [profession, setProfession] = useState();
    const [gender, setGender] = useState();
    const [email, setEmail] = useState();
    const [phoneNumber, setPhoneNumber] = useState();
    const [websiteUrl, setWebsiteUrl] = useState();
    const [about, setAbout] = useState();
    const [search, setSearch] = useState();
    const [linkedIn, setLinkedIn] = useState();
    const [instagram, setInstagram] = useState();
    const [facebook, setFacebook] = useState();
    const [twitter, setTwitter] = useState();
    const [profilePicture, setProfilePicture] = useState();
    const [profilePictureUrl, setProfilePictureUrl] = useState();
    const [tags, setTags] = useState();
    const dispatch = useDispatch();
    const [exhibition] = useExhibition();
    const imageType = Image.imageTypes.PROFILE_IMAGE
    const [imageUploadOpen, setImageUploadOpen] = useState(false);
    const [changePasswordOpen, setChangePasswordOpen] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [savedImage, setSavedImage] = useState(false);
    const dispatchImage = (id, image) => {
        setUploading(true);
        id === null
            ? dispatch(createUserImage(exhibition.getId(), image))
            : dispatch(updateUserImage(exhibition.getId(), image));

        setImageUploadOpen(false);
    };

    const [trackEvent,trackVisit] = useTracking()

    const imageReducer = useSelector(state => state.usersImagesReducer)

    useEffect(() => {
        if (imageReducer.isFetching || !uploading || imageReducer.error.isError()) {
            if (imageReducer.error.isError()) {
                setUploading(false);
                setProfilePictureUrl(profilePicture ? profilePicture.getUrl("small"):"");
            }
            return
        }
        trackEvent(EVENT_TRACKING_TYPES.profile.picture)
        setUploading(false);
    }, [imageReducer.isFetching])

    useEffect(() => {
        trackVisit(VISIT_TRACKING_TYPES.userProfileEdit);
    }, [])

    const [loading, setLoading] = useState(false)

    const setLocalPicture = (url) => {
        switch (imageType) {
            case Image.imageTypes.PROFILE_IMAGE:
                setProfilePictureUrl(url);
                break;
            default:
                return;
        }
    };

    const handleClose = () => {
        setImageUploadOpen(false);
    };

    const translateGender = (value) => {
        switch (value) {
            case "empty":
                return t("frontend.pages.user_details.gender.empty");
            case "male":
                return t("frontend.pages.user_details.gender.male");
            case "female":
                return t("frontend.pages.user_details.gender.female");
            case "divers":
                return t("frontend.pages.user_details.gender.divers");
            default:
                return "";
        }
    }

    useEffect(() => {
        if (!user) {
            return
        }

        setFirstName(user.getFirstName());
        setLastName(user.getLastName());
        setProfession(user.getProfession());
        setGender(user.getGender());
        setCompanyName(user.getCompanyName());
        setEmail(user.getCompanyEmail());
        setPhoneNumber(user.getPhoneNumber());
        setWebsiteUrl(user.getWebsiteUrl());
        setAbout(user.getDescription());
        setSearch(user.getSearchText());
        setProfilePicture(user.getProfilePicture());
        setProfilePictureUrl(user.getProfilePicture() ? user.getProfilePicture().getUrl("small") : "");
        setFacebook(user.getFacebookUrl());
        setLinkedIn(user.getLinkedInUrl());
        setTwitter(user.getTwitterUrl());
        setInstagram(user.getInstagramUrl());
        setTags(user.getTags());
    }, [user]);

    const valueChange = (e) => {
        switch (e.target.id) {
            case "firstName":
                setFirstName(e.target.value);
                break;
            case "lastName":
                setLastName(e.target.value);
                break;
            case "profession":
                setProfession(e.target.value);
                break;
            case "companyName":
                setCompanyName(e.target.value);
                break;
            case "email":
                setEmail(e.target.value);
                break;
            case "phoneNumber":
                setPhoneNumber(e.target.value);
                break;
            case "websiteUrl":
                setWebsiteUrl(e.target.value);
                break;
            case "about":
                if (e.target.value.length <= 500) {
                    setAbout(e.target.value);
                } else {
                    setAbout(e.target.value.substr(0, 500));
                }
                break;
            case "search":
                if (e.target.value.length <= 200) {
                    setSearch(e.target.value);
                } else {
                    setSearch(e.target.value.substr(0, 200));
                }
                break;
            case "instagramUrl":
                setInstagram(e.target.value);
                break;
            case "facebookUrl":
                setFacebook(e.target.value);
                break;
            case "twitterUrl":
                setTwitter(e.target.value);
                break;
            case "linkedInUrl":
                setLinkedIn(e.target.value);
                break;
            default:
                break;
        }
    }

    const saveUser = () => {
        const userUpdate = {
            first_name: firstName,
            last_name: lastName,
            profession: profession,
            gender: gender,
            compnay_name: companyName,
            company_email: email,
            phone_number: phoneNumber,
            website_url: websiteUrl,
            description: about,
            search_text: search,
            linkedIn_url: linkedIn,
            instagram_url: instagram,
            facebook_url: facebook,
            twitter_url: twitter,
        }
        if (user.state.eventuserId && tags && tags.length > 0) {
            const eventuser = {
                tags: TagMapper.toJsonArray(tags)
            }
            updateEventUser(user.state.eventuserId, eventuser).then(
                dispatch(updateUserRaw(user, userUpdate))
            )
        } else {
            dispatch(updateUserRaw(user, userUpdate));
        }
        setLoading(true);
    }

    const usersReducer = useSelector(state => state.usersReducer);

    useEffect(() => {
        if (usersReducer.isFetching || !loading || usersReducer.error.isError()) {
            return
        }

        setEditMode(false);
        setLoading(false);
    }, [usersReducer.isFetching])

    return !user ? (<div />) : (
        <div className={classes.root}>
            <form onChange={valueChange}>
                <Grid container direction="row" alignItems="flex-start" className={classes.nameContainer}>
                    <Grid item>
                        <StyledBadge
                            overlap="circle"
                            color="secondary"
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            onClick={() => setImageUploadOpen(true)}
                            badgeContent={<Edit className={classes.editBadge} />}
                        >
                            <Avatar src={profilePictureUrl} className={classes.avatar}>P</Avatar>
                        </StyledBadge>
                    </Grid>
                    <Grid container direction="column" item xs={7}>
                        <FormControl className={classes.formControl}>
                            <OutlinedInput
                                id="firstName"
                                value={firstName}
                                className={classes.titleEdit}
                                placeholder={t("frontend.first_name")}
                            />
                        </FormControl>
                        <FormControl className={classes.formControl}>
                            <OutlinedInput
                                id="lastName"
                                value={lastName}
                                className={classes.titleEdit}
                                placeholder={t("frontend.last_name")}
                            />
                        </FormControl>
                        <FormControl className={classes.formControl}>
                            <OutlinedInput
                                id="profession"
                                value={profession}
                                className={classes.normalEdit}
                                placeholder={t("frontend.profession")}
                            />
                        </FormControl>
                        <FormControl className={classes.formControl}>
                            <OutlinedInput
                                id="companyName"
                                value={companyName}
                                className={classes.normalEdit}
                                placeholder={t("frontend.company_name")}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item container xs={3} direction="column" alignItems="flex-start" className={classes.buttonContainer}>
                        <Button startIcon={<Lock />} variant="text" className={classes.passwordButton} disabled={uploading} onClick={() => setChangePasswordOpen(true)}><Trans>frontend.change_password</Trans></Button>
                        <Button variant="contained" onClick={() => saveUser()} className={classes.saveButton} disabled={uploading}>{uploading ? <CircularProgress />:<Trans>frontend.save</Trans>}</Button>
                        <Button variant="text" disabled={uploading} onClick={() => setEditMode(false)} className={classes.backButton}><Trans>frontend.back</Trans></Button>
                    </Grid>
                </Grid>
                <Grid item container xs={12} className={classes.personalContainer}>
                    <Typography variant="h4" className={classes.subtitle}><Trans>frontend.categories</Trans></Typography>
                    <EditTagArea center={true} exhibition={exhibition} entityTags={tags} setEntityTags={(tags) => setTags(tags)} maxTags={3} />
                </Grid>
                <Grid item container xs={5} className={classes.personalContainer}>
                    <Typography variant="h4" className={classes.subtitle}><Trans>frontend.pages.user_details.personal_data</Trans></Typography>
                    <LeadbaseDropdown childHeight={35} value={translateGender(gender)} placeholder={t("frontend.pages.user_details.gender_caption")}>
                        <div id="gender" style={{ marginTop: 5 }} onClick={() => setGender("empty")}>{t("frontend.pages.user_details.gender.empty")}</div>
                        <div id="gender" onClick={() => setGender("male")}>{t("frontend.pages.user_details.gender.male")}</div>
                        <div id="gender" onClick={() => setGender("female")}>{t("frontend.pages.user_details.gender.female")}</div>
                        <div id="gender" onClick={() => setGender("divers")}>{t("frontend.pages.user_details.gender.divers")}</div>
                    </LeadbaseDropdown>
                </Grid>
                <div className={classes.contactContainer}>
                    <Typography variant="h4" className={classes.subtitle}><Trans>frontend.pages.user_details.contact_data</Trans></Typography>
                    <Grid container direction="row">
                        <Grid item xs={5}>
                            <FormControl className={classes.formControl}>
                                <OutlinedInput
                                    id="email"
                                    className={classes.normalEdit}
                                    startAdornment={<InputAdornment position="start" className={classes.inputAdornment}></InputAdornment>}
                                    placeholder={t("frontend.pages.login.user_email")}
                                    value={email}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={5}>
                            <FormControl className={classes.formControl}>
                                <OutlinedInput
                                    id="phoneNumber"
                                    className={classes.normalEdit}
                                    startAdornment={<InputAdornment position="start" className={classes.inputAdornment}></InputAdornment>}
                                    placeholder={t("frontend.pages.user_details.phone_number")}
                                    value={phoneNumber}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={5}>
                            <FormControl className={classes.formControl}>
                                <OutlinedInput
                                    id="websiteUrl"
                                    className={classes.normalEdit}
                                    startAdornment={<InputAdornment position="start" className={classes.inputAdornment}></InputAdornment>}
                                    placeholder={t("frontend.pages.user_details.website_url")}
                                    value={websiteUrl}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </div>
                <div className={classes.aboutContainer}>
                    <Typography variant="h4" className={classes.subtitle}><Trans>frontend.pages.user_details.about_you</Trans></Typography>
                    <TextField id="about" variant="outlined" placeholder={t("frontend.about_visitor")} value={about} className={classes.aboutEdit} multiline rows={5}></TextField>
                    <Typography variant="caption" className={classes.hint}><Trans>frontend.max_value_500</Trans></Typography>
                </div>
                <div className={classes.searchContainer}>
                    <Typography variant="h4" className={classes.subtitle}><Trans>frontend.pages.user_details.looking_for</Trans></Typography>
                    <TextField id="search" variant="outlined" placeholder={t("frontend.visitor_search")} className={classes.aboutEdit} multiline rows={3} value={search}></TextField>
                    <Typography variant="caption" className={classes.hint}><Trans>frontend.max_value_200</Trans></Typography>
                </div>
                <div className={classes.socialContainer}>
                    <Typography variant="h4" className={classes.subtitle}><Trans>frontend.pages.user_details.link_social_accounts</Trans></Typography>
                    <Grid container direction="row">
                        <Grid item xs={5}>
                            <FormControl className={classes.formControl}>
                                <OutlinedInput
                                    id="instagramUrl"
                                    className={classes.normalEdit}
                                    startAdornment={<InputAdornment position="start" className={classes.inputAdornment}><Instagram /></InputAdornment>}
                                    placeholder={t("frontend.pages.user_details.instagram_url")}
                                    value={instagram}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={5}>
                            <FormControl className={classes.formControl}>
                                <OutlinedInput
                                    id="twitterUrl"
                                    className={classes.normalEdit}
                                    startAdornment={<InputAdornment position="start" className={classes.inputAdornment}><Twitter /></InputAdornment>}
                                    placeholder={t("frontend.pages.user_details.twitter_url")}
                                    value={twitter}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={5}>
                            <FormControl className={classes.formControl}>
                                <OutlinedInput
                                    id="linkedInUrl"
                                    className={classes.normalEdit}
                                    startAdornment={<InputAdornment position="start" className={classes.inputAdornment}><LinkedIn /></InputAdornment>}
                                    placeholder={t("frontend.pages.user_details.linkedin_url")}
                                    value={linkedIn}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={5}>
                            <FormControl className={classes.formControl}>
                                <OutlinedInput
                                    id="facebookUrl"
                                    className={classes.normalEdit}
                                    startAdornment={<InputAdornment position="start" className={classes.inputAdornment}><Facebook /></InputAdornment>}
                                    placeholder={t("frontend.pages.user_details.facebook_url")}
                                    value={facebook}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </div>
            </form>
            <ImageUploadModal
                open={imageUploadOpen}
                prevImage={profilePicture}
                dispatchImage={(id, image) => dispatchImage(id, image)}
                imageType={imageType}
                attachedToType={Image.attachedToTypes.USER}
                attachedToId={user.getId()}
                setSavedImage={(value) => setSavedImage(value)}
                handleClose={() => handleClose(false)}
                exhibitionId={exhibition ? exhibition.getId():undefined}
                setLocalPicture={(url) => setLocalPicture(url)}
            />
            <ChangePasswordModal
                open={changePasswordOpen}
                handleClose={() => {
                    setChangePasswordOpen(false);
                }}
            />
        </div>
    )
}

export default UserProfileEdit
