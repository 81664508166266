import Share from "../models/Share";
import BaseMapper from "./BaseMapper";
import ErrorLogger from "../utils/ErrorLogger";
import UserMapper from "./UserMapper";

const attributes = Share.attributes;

export default class ShareMapper {
  static build = (json) => {
    if (!json) {
      return;
    }

    try {
      return new Share({
        ...BaseMapper.attributes(json),
        exhibition: json.exhibition,
        sharableType: json[attributes.sharableType],
        sharableId: json[attributes.sharableId],
        user: UserMapper.build(json.user),
      });
    } catch (error) {
      ErrorLogger.call(this, error);
    }
  };

  static buildFromArray = (jsonArray = []) => {
    return jsonArray.map((entry) => {
      return this.build(entry);
    });
  };

  static toJsonString(share) {
    return JSON.stringify({
      id: share.getId(),
      [attributes.user]: share.getUser() && share.getUser().state ? share.getUser().state:share.getUser(),
      [attributes.sharableType]: share.getSharableType(),
      [attributes.sharableId]: share.getSharableId(),
    });
  }
}
