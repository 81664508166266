import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import React, { useContext, useState } from "react";
import NeumorphicStyle from "../../../../../constants/Neumorphic";
import GlobalContext from "../../context/GlobalContext";

const useStyles = makeStyles((theme) => ({
  notPressed: {
    borderRadius: 5,
    boxShadow: NeumorphicStyle.boxShadowLarge(
      theme.palette.primary.main,
      theme.palette.secondary.dark
    ),
  },
  pressed: {
    borderRadius: 5,
    borderWidth: 3,
    borderColor: ({ highlightColor }) => highlightColor,
    borderStyle: "solid",
  },
  notHovered: {
    backgroundColor: ({ primaryColor }) => primaryColor,
    color: ({ fontColor }) => fontColor,
  },
  hovered: {
    cursor: "pointer",
    backgroundColor: ({ highlightColor }) => highlightColor,
    color: ({ fontHighlightColor }) => fontHighlightColor,
  },
}));

export default function TalkingPointElement({
  talkingPointElementId,
  primaryColor,
  fontColor,
  highlightColor,
  fontHighlightColor,
  label,
  onClick,
}) {
  const { talkingPointId, tabIndex } = useContext(GlobalContext);
  const [hovered, setHovered] = useState(false);
  const pressed = tabIndex === 1 && talkingPointElementId === talkingPointId;
  const classes = useStyles({
    primaryColor,
    fontColor,
    highlightColor,
    fontHighlightColor,
  });

  return (
    <Box
      width="inherit"
      height={70}
      borderRadius={5}
      justifyContent="center"
      display="flex"
      alignItems="center"
      className={clsx({
        [classes.hovered]: hovered,
        [classes.notHovered]: !hovered,
        [classes.pressed]: pressed,
        [classes.notPressed]: !pressed,
      })}
      onClick={onClick}
      onMouseOver={() => setHovered(true)}
      onMouseOut={() => setHovered(false)}
    >
      <Typography variant="h4" style={{ color: "inherit" }}>
        {label}
      </Typography>
    </Box>
  );
}
