export const DEFAULT                = -1
export const INTERNAL_SERVER_ERROR  = 0
export const UNAUTHORIZED           = 1
export const NOT_FOUND              = 2
export const APPLICATION_ERROR      = 3
export const FORBIDDEN              = 4
export const BAD_REQUEST            = 5
export const CONFIRMATION_NEEDED    = 6

export default class ApplicationError {

  static buildInternalServerError = (message = "Es ist ein unerwarteter Fehler aufgetreten!") => { 
    return new this({ errorCode: INTERNAL_SERVER_ERROR, errorMessage: message })
  }

  static buildUnauthorized = (message = "Der User ist nicht eingelogged!") => { 
    return new this({ errorCode: UNAUTHORIZED, errorMessage: message })
  }

  static buildNotFound = (message = "Die Resource konnte nicht gefunden werden!") => { 
    return new this({ errorCode: NOT_FOUND, errorMessage: message })
  }

  static buildForbidden = (message = "Keine Berechtigung für diesen Vorgang!") => { 
    return new this({ errorCode: FORBIDDEN, errorMessage: message })
  }

  static buildBadRequest = (message = "Leider ist etwas schiefgelaufen. Bei anhaltenden Problemen wenden Sie sich bitte an unseren Support.") => {
    return new this({ errorCode: BAD_REQUEST, errorMessage: message})
  }
  static buildConfirmationError = (message = "E-Mail Adresse ist noch nicht bestätigt.") => {
    return new this({ errorCode: CONFIRMATION_NEEDED, errorMessage: message})
  }

  static buildDefault = () => { return new this() }

  static buildApplicationError = (error, klass = "NoClass::") => {
    return new this({errorCode: APPLICATION_ERROR, errorMessage: klass + error})
  }

  state = {
    errorMessage: '',
    errorCode: DEFAULT,
  }

  constructor(_opts = {}) {
    this.state = {...this.state,
      errorMessage: _opts.errorMessage || this.state.errorMessage,
      errorCode: _opts.errorCode || this.state.errorCode
    }
  }

  isError               = () => this.state.errorCode !== DEFAULT
  isNotFound            = () => this.state.errorCode === NOT_FOUND
  isForbidden           = () => this.state.errorCode === FORBIDDEN
  isBadRequest          = () => this.state.errorCode === BAD_REQUEST
  isUnauthorized        = () => this.state.errorCode === UNAUTHORIZED
  isInternalServerError = () => this.state.errorCode === INTERNAL_SERVER_ERROR
  isConfirmationError   = () => this.state.errorCode === CONFIRMATION_NEEDED

  getErrorCode = () => this.state.errorCode 
  getErrorMessage = () => this.state.errorMessage
}