import React, { useState, useRef, useEffect } from "react";
import MessageBox from "./PublicMessageBox";
import {
  CircularProgress,
  Divider,
  LinearProgress,
  ListItem,
} from "@material-ui/core";

function ThreadDetails({ loading, messages, loadMore }) {
  const messagesRef = useRef();
  const [startUp, setStartUp] = useState(true);
  const [autoScroll, setAutoScroll] = useState(true);
  function scrollMessagesToBottom() {
    if (messagesRef.current) {
      // eslint-disable-next-line no-underscore-dangle
      messagesRef.current.scrollTop = messagesRef.current.scrollHeight;
      setStartUp(false);
    }
  }

  useEffect(() => {
    if (!messagesRef.current) {
      return;
    }

    messagesRef.current.addEventListener("scroll", handleScroll);

    return () => {
      messagesRef.current.removeEventListener('scroll', handleScroll)
  }
  }, [messagesRef.current]);

  const handleScroll = () => {
    var scrollBottom =
      messagesRef.current.scrollTop + messagesRef.current.clientHeight;
    if (scrollBottom > messagesRef.current.scrollHeight - 25 && !loading) {
      setAutoScroll(true);
      return;
    }

    if (startUp || loading || messagesRef.current.scrollTop > 0) {
      return;
    }
    setAutoScroll(false);
    loadMore();
  };

  useEffect(() => {
    if (!autoScroll) {
      return;
    }
    scrollMessagesToBottom();
    // eslint-disable-next-line
  }, [messages]);

  return (
    <div
      ref={messagesRef}
      style={{
        overflowY: "scroll",
        height: "calc(100% - 71px)",
        padding: "12px",
      }}
    >
      {loading && <LinearProgress />}
      {messages.map((message) => (
        <MessageBox
          key={message.id}
          message={message}
          messagesRef={messagesRef}
        />
      ))}
    </div>
  );
}

export default ThreadDetails;
