import React, { useContext, useEffect, useState } from "react";
import {
  makeStyles,
  Typography,
  Grid,
  Button,
  IconButton,
  Backdrop,
  Divider,
} from "@material-ui/core";
import {
  useExhibitor,
  useExhibitorsUsers,
} from "../../../../../engines/GeneralHooks/useExhibitors";
import AttachmentCard from "../../../../components/AttachmentCard/AttachmentCard";
import GlobalContext from "../../context/GlobalContext";
import marked from "marked";
import CloseIcon from "@material-ui/icons/Close";
import StarIcon from "@material-ui/icons/Star";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import NeumorphicStyle from "../../../../../constants/Neumorphic";
import MyStore from "../../../../../utils/MyStore";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import useExhibition from "../../../../../engines/GeneralHooks/useExhibition";
import {
  createBookmark,
  deleteBookmark,
} from "../../../../../redux/actions/BookmarksActions";
import CogoToast from "../../../../../utils/CogoToast";
import Bookmark from "../../../../../models/Bookmark";
import { KeyboardArrowDown } from "@material-ui/icons";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { bookmarkExhibitor } from "../../../../../redux/actions/ExhibitorsActions";
import { useExhibitorQL } from "../../../../../engines/GraphQL/hooks/useExhibitorsQL";
import { useHistory, useLocation } from "react-router";
import FrontendRoutes from "../../../../../constants/FrontendRoutes";
import Exhibitor from "../../../../../models/Exhibitor";
import queryString from "query-string";
import useTracking, {
  EVENT_TRACKING_TYPES,
  VISIT_TRACKING_TYPES,
} from "../../../../engines/tracking/useTracking";

const useStyles = makeStyles((theme) => ({
  root: {
    left: 16,
    right: 16,
    top: 96,
    bottom: 32,
    padding: 80,
    background: theme.palette.background.default,
    boxShadow: NeumorphicStyle.boxShadowMedium(
      theme.palette.primary.main,
      theme.palette.secondary.dark
    ),
    borderRadius: NeumorphicStyle.borderRadius,
    position: "absolute",
    zIndex: 10000,
    transition: "width 0.2s, height 0.2s, opacity 0.2s 0.2s",
    overflow: "auto",
  },
  inner: {
    display: "grid",
    gridTemplateColumns: "1fr",
    justifyItems: "center",
  },
  videoBox: {
    overflow: "hidden",
    backgroundColor: "grey",
    width: "100%",
    paddingTop: "56.25%",
    boxShadow: NeumorphicStyle.boxShadowMedium(
      theme.palette.primary.main,
      theme.palette.secondary.dark
    ),
    marginTop: 30,
    position: "relative",
    minWidth: "200px",
    minHeight: "112.5px",
  },
  video: {
    width: "100%",
    height: "100%",
    borderRadius: "inherit",
    border: "none",
    left: 0,
    top: 0,
    position: "absolute",
    objectFit: "cover",
  },
  avatarRow: {
    justifySelf: "strech",
  },
  avatar: {
    height: 100,
    objectFit: "scale-down",
  },
  title: {
    marginLeft: 40,
    alignSelf: "center",
  },
  content: {
    whiteSpace: "pre-wrap",
    paddingTop: 30,
    paddingBottom: 30,
    width: "100%",
    alignSelf: "center",
    marginBottom: "auto",
    "&> p": {
      whiteSpace: "pre-wrap",
      fontSize: 20,
      fontWeight: 400,
      lineHeight: 1.235,
      fontFamily: "Lato, sans-serif",
      paddingBottom: 16,
    },
    "& iframe": {
      width: "100%"
    }
  },
  closeIcon: {
    position: "sticky",
    top: 15,
    marginLeft: "auto",
    marginRight: 8,
    background: theme.palette.background.default,
    zIndex: 800,
  },
  exhibitorsUsers: {
    padding: 12,
  },
  contactButton: {
    marginTop: "auto",
    marginBottom: "auto",
    color: theme.palette.secondary.contrastText,
    height: 50,
    fontSize: "1.5em",
    background: theme.palette.secondary.light,
    boxShadow: NeumorphicStyle.boxShadowMedium(
      theme.palette.primary.main,
      theme.palette.secondary.dark
    ),
  },
  favButton: {},
  favIcon: {
    marginRight: "auto",
    height: 30,
    width: 30,
    alignSelf: "center",
    marginLeft: 15,
    borderRadius: 0,
    padding: 0,
  },
  favIconFilled: {
    marginRight: "auto",
    height: 30,
    width: 30,
    alignSelf: "center",
    marginLeft: 15,
    borderRadius: 0,
    padding: 0,
    color: theme.palette.secondary.light,
  },
  attachmentContainter: {
    marginTop: 30,
  },
  attachmentDropdownContainer: {
    width: 350,
    height: 50,
    justifyItems: "center",
  },
  attachmentDropdown: {
    background: theme.palette.background.default,
    width: 350,
    height: 50,
    padding: 10,
    justifyItems: "center",
    borderRadius: 5,
    boxShadow: NeumorphicStyle.boxShadowMedium(
      theme.palette.primary.main,
      theme.palette.secondary.dark
    ),
    transition: "height 0.2s, opacity 0.2s 0.2s",
  },
  attachmentDropdownOpen: {
    background: theme.palette.background.default,
    width: 350,
    overflow: "hidden",
    position: "absolute",
    padding: 10,
    borderRadius: 5,
    boxShadow: NeumorphicStyle.boxShadowMedium(
      theme.palette.primary.main,
      theme.palette.secondary.dark
    ),
    transition: "height 0.2s, opacity 0.2s 0.2s",
  },
  attachmentDropdownTitle: {
    height: 30,
  },
  openDropdownIcon: {
    transition: "transform .3s ease-in-out",
  },
  closeDropdownIcon: {
    transition: "transform .3s ease-in-out",
    transform: "rotate(180deg)",
  },
  attachmentDropdownCard: {
    marginTop: 10,
    height: 40,
  },
  backdrop: {
    zIndex: 5000,
    width: "inherit",
    height: "inherit",
    background: "rgba(255,255,255,0.0)",
    right: 16,
  },
}));

const responsive = {
  0: { items: 1 },
  568: { items: 2 },
  1024: { items: 3 },
};

function ExhibitorDetailsModal({ payload, handleClose }) {
  const classes = useStyles();
  const context = useContext(GlobalContext);
  const [open, setOpen] = useState(false);
  const [exhibitorId, setExhibitorId] = useState();
  const [exhibitor] = useExhibitorQL(exhibitorId);
  const [exhibitorsUsers] = useExhibitorsUsers(exhibitor);
  const dispatch = useDispatch();
  const [exhibition] = useExhibition();
  const [t] = useTranslation();
  const [attachmentsOpen, setAttachmentsOpen] = useState(false);
  const [carouselIndex, setCarouselIndex] = useState(0);
  const history = useHistory();
  const location = useLocation();
  const [trackEvent, trackVisit] = useTracking();

  const [bookmarked, setBookmarked] = useState(false);
  const [bookmarksLoading, setBookmarksLoading] = useState(false);
  const [unbookmarked, setUnbookmarked] = useState(false);
  const exhibitorsReducer = useSelector((state) => state.exhibitorsReducer);

  const [videoPlaying, setVideoPlaying] = useState(false);
  const [time, setTime] = useState();

  useEffect(() => {
    return () => {
      handleVideoPlayPause(true);
    }
  }, [])

  useEffect(() => {
    setOpen(context.currentExhibitor !== undefined);
    if (!context.currentExhibitor) {
      if (exhibitorId) {
        trackEvent(
          exhibitor.getSponsorName()
            ? EVENT_TRACKING_TYPES.sponsors.leftSponsor
            : EVENT_TRACKING_TYPES.exhibitor.leftExhibitor,
          {
            exhibitor: exhibitor.getId(),
            exhibitor_name: exhibitor.getName(),
          }
        );
      }
      setExhibitorId(undefined);
      setCarouselIndex(0);
      setAttachmentsOpen(false);
      if (location.search.includes("exhibitor")) {
        history.push(FrontendRoutes.root());
      }
      return;
    }
    setExhibitorId(context.currentExhibitor.getId());
    if (!location.search.includes("exhibitor")) {
      history.push(
        FrontendRoutes.exhibitors.show(context.currentExhibitor.getId())
      );
    }
    trackVisit(VISIT_TRACKING_TYPES.exhibitor, {
      exhibitor: context.currentExhibitor.getId()
    });
    trackEvent(
      context.currentExhibitor.getSponsorName()
        ? EVENT_TRACKING_TYPES.sponsors.viewedSponsor
        : EVENT_TRACKING_TYPES.exhibitor.exhibitorView,
      {
        exhibitor: context.currentExhibitor.getId(),
        exhibitor_name: context.currentExhibitor.getName(),
      }
    );
  }, [context.currentExhibitor]);

  useEffect(() => {
    let searchParams = queryString.parse(location.search);
    if (
      searchParams.exhibitor &&
      searchParams.exhibitor !== "" &&
      !context.currentExhibitor
    ) {
      context.setCurrentExhibitor(
        new Exhibitor({ id: searchParams.exhibitor })
      );
    }
  }, [location.search]);

  useEffect(() => {
    if (!unbookmarked) {
      return;
    }

    dispatch(bookmarkExhibitor(exhibitor));
    trackEvent(EVENT_TRACKING_TYPES.exhibitor.unbookmarked, {
      exhibitor: context.currentExhibitor.getId(),
    });
    setBookmarksLoading(true);
  }, [unbookmarked]);

  useEffect(() => {
    if (!bookmarked) {
      return;
    }

    dispatch(bookmarkExhibitor(exhibitor));
    trackEvent(EVENT_TRACKING_TYPES.exhibitor.bookmark, {
      exhibitor: context.currentExhibitor.getId(),
    });
    setBookmarksLoading(true);
  }, [bookmarked]);

  // Bookmark
  useEffect(() => {
    if (
      (!bookmarked && !unbookmarked) ||
      bookmarksLoading ||
      exhibitorsReducer.isFetching ||
      exhibitorsReducer.error.isError()
    ) {
      return;
    }

    if (bookmarked) {
      exhibitor.setIsBookmarked(true);
      CogoToast.info(t("frontend.added_bookmark"));
    }

    if (unbookmarked) {
      exhibitor.setIsBookmarked(false);
      CogoToast.info(t("frontend.removed_bookmark"));
    }

    setBookmarked(false);
    setUnbookmarked(false);
  }, [exhibitorsReducer.isFetching]);

  const handleBookmark = () =>
    exhibitor.isBookmarked() ? setUnbookmarked(true) : setBookmarked(true);

  const handleVideoPlayPause = (closing) => {
    if (closing && !videoPlaying) {
      return
    }

    if (videoPlaying) {
      trackEvent(EVENT_TRACKING_TYPES.exhibitor.videoPaused, {
        exhibitor: context.currentExhibitor.getId(),
        exhibitor_name: context.currentExhibitor.getName(),
        language: MyStore.getLocal(),
        timeViewed: Date.now() - time,
      })
      setVideoPlaying(false);
      setTime(undefined);
    } else {
      setTime(Date.now());
      trackEvent(EVENT_TRACKING_TYPES.exhibitor.videoStarted, {
        exhibitor: context.currentExhibitor.getId(),
        exhibitor_name: context.currentExhibitor.getName(),
        language: MyStore.getLocal(),
      })
      setVideoPlaying(true);
    }
  }

  return !exhibitor ? (
    <div />
  ) : (
      <Backdrop
        className={classes.backdrop}
        open={open}
        onClick={() => context.setCurrentExhibitor(undefined)}
      >
        <div className={classes.root} onClick={(e) => e.stopPropagation()}>
          <div className={classes.inner}>
            <IconButton
              className={classes.closeIcon}
              onClick={() => context.setCurrentExhibitor(undefined)}
            >
              <CloseIcon />
            </IconButton>

            <Grid
              item
              container
              className={classes.avatarRow}
              direction="row"
              justify="center"
            >
              <img
                src={
                  exhibitor && exhibitor.getProfilePicture()
                    ? exhibitor.getProfilePicture().getUrl("medium")
                    : ""
                }
                className={classes.avatar}
              />
              <Typography className={classes.title} variant="h1">
                {exhibitor ? exhibitor.getName() : ""}
                {/* <Grid
                                    container
                                    direction="row"
                                    style={{ padding: "2px" }}
                                    alignItems="center"
                                >
                                    <Grid item>
                                        <WorldIcon />
                                    </Grid>
                                    <Grid item alignItems="center">
                                        <a href={exhibitor.getCompanyWebsite()} target="_blank">
                                            <Typography
                                                variant="body1"
                                                style={{
                                                    marginLeft: "4px",
                                                    fontWeight: "bold",
                                                    width: "20%",
                                                }}
                                            >
                                                {exhibitor.getCompanyWebsite()}
                                            </Typography>
                                        </a>
                                    </Grid>
                                </Grid> */}
              </Typography>
              {exhibitor && exhibitor.isBookmarked() ? (
                <StarIcon
                  onClick={handleBookmark}
                  className={classes.favIconFilled}
                />
              ) : (
                  <StarBorderIcon
                    onClick={handleBookmark}
                    className={classes.favIcon}
                  />
                )}
              <Button variant="contained" className={classes.contactButton}>
                <Trans>frontend.make_contact</Trans>
              </Button>
            </Grid>
            {exhibitor && exhibitor.getProfileVideo(MyStore.getLocal()) ? (
              <div className={classes.videoBox}>
                <video
                  src={exhibitor.getProfileVideo(MyStore.getLocal()).getUrl()}
                  onPlay={() => {
                    context.setMuted(true);
                    handleVideoPlayPause();
                  }}
                  onPause={() => {
                    context.setMuted(false);
                    handleVideoPlayPause();
                  }}
                  on
                  className={classes.video}
                  disablePictureInPicture
                  controls
                  controlsList="nodownload"
                />
              </div>
            ) : exhibitor && exhibitor.getHeaderPicture() && (
              <div className={classes.videoBox}>
                <img
                  src={
                    exhibitor && exhibitor.getHeaderPicture()
                      ? exhibitor.getHeaderPicture().getUrl()
                      : ""
                  }
                  className={classes.video}
                />
              </div>
            )}
            <Grid
              item
              container
              direction="row"
              xs={12}
              alignItems="flex-start"
              className={classes.attachmentContainter}
            >
              <Grid item xs={12}>
                <Typography variant="h3" style={{ marginBottom: 20 }}>
                  <Trans>frontend.want_to_know_more</Trans>
                </Typography>
              </Grid>
              {exhibitor.getDocuments() && exhibitor.getDocuments().length === 1 && (
                <div
                  container
                  direction="row"
                  className={classes.attachmentDropdown}
                >
                  <AttachmentCard document={exhibitor.getDocuments()[0]} />
                </div>
              )}
              {exhibitor.getDocuments() && exhibitor.getDocuments().length > 1 && (
                <div className={classes.attachmentDropdownContainer}>
                  <div
                    onClick={() => setAttachmentsOpen(!attachmentsOpen)}
                    className={
                      attachmentsOpen
                        ? classes.attachmentDropdownOpen
                        : classes.attachmentDropdown
                    }
                    style={{
                      height: attachmentsOpen
                        ? exhibitor.getDocuments().length * 45 + 55
                        : 50,
                    }}
                  >
                    <Grid container direction="row">
                      <Typography
                        className={classes.attachmentDropdownTitle}
                        variant="h4"
                      >
                        <Trans>frontend.download_catalogs</Trans>
                      </Typography>
                      <KeyboardArrowDown
                        className={
                          attachmentsOpen
                            ? classes.closeDropdownIcon
                            : classes.openDropdownIcon
                        }
                        style={{ marginLeft: "auto" }}
                      />
                    </Grid>
                    {attachmentsOpen && (
                      <Divider color="black" variant="fullWidth" />
                    )}
                    {attachmentsOpen &&
                      exhibitor.getDocuments().map((document) => (
                        <div style={{ marginTop: 10 }}>
                          <AttachmentCard document={document} />
                        </div>
                      ))}
                  </div>
                </div>
              )}
              <Grid item container xs={6} direction="row" alignItems="center">
                {exhibitor && exhibitor.getCompanyWebsite() && (
                  <Button
                    onClick={() => {
                      let url = exhibitor.getCompanyWebsite();
                      url = (!url.match(/^https?:\/\//i) ? "https://" : "") + url;
                      window.open(url, "_blank");
                    }}
                    variant="contained"
                    style={{ marginLeft: 40 }}
                    className={classes.contactButton}
                  >
                    <Trans>frontend.visit_orderpage</Trans>
                  </Button>
                )}
              </Grid>
            </Grid>
            <Typography
              style={{ marginRight: "auto", marginTop: 30 }}
              variant="h3"
            >
              {exhibitor.getTitle(MyStore.getLocal())}
            </Typography>
            <Grid
              item
              container
              direction="column"
              className={classes.content}
              style={{ fontSize: "1.5em" }}
              dangerouslySetInnerHTML={{
                __html: marked(
                  exhibitor.getDescription(MyStore.getLocal()) || ""
                ),
              }}
            ></Grid>
            <Grid
              container
              alignItems="stretch"
              direction="row"
              style={{ position: "relative", width: "100%" }}
            >
              {exhibitor.getPictures() &&
                exhibitor.getPictures().map((picture, i) =>
                  Math.floor(i / 3) === carouselIndex ? (
                    <Grid
                      item
                      style={{
                        width: "33.33%",
                        height: 0,
                        position: "relative",
                        paddingBottom: "33.33%",
                      }}
                    >
                      <div
                        style={{
                          position: "absolute",
                          width: "100%",
                          height: "100%",
                          padding: 10,
                        }}
                      >
                        <img
                          src={picture.getUrl("medium")}
                          style={{
                            objectFit: "cover",
                            borderRadius: 10,
                            width: "100%",
                            height: "100%",
                            boxShadow: NeumorphicStyle.boxShadowSmall(
                              "#d3d3d3",
                              "#FFFFFF"
                            ),
                          }}
                        />
                      </div>
                    </Grid>
                  ) : undefined
                )}
            </Grid>
            <Grid
              direction="row"
              justify="center"
              alignItems="center"
              container
              style={{ height: 30, marginLeft: "auto", marginRight: "auto" }}
            >
              {carouselIndex > 0 ? (
                <ArrowBackIosIcon
                  onClick={() => setCarouselIndex(carouselIndex - 1)}
                  style={{ cursor: "pointer" }}
                />
              ) : (
                  <div style={{ width: 25 }} />
                )}
              {exhibitor.getPictures().map((p, i) =>
                i % 3 === 0 ? (
                  <FiberManualRecordIcon
                    onClick={() => setCarouselIndex(Math.floor((i + 1) / 3))}
                    style={{
                      color:
                        carouselIndex === Math.floor((i + 1) / 3)
                          ? "grey"
                          : "lightgrey",
                      cursor: "pointer",
                      width: 15,
                      height: 15,
                    }}
                  />
                ) : (
                    <div />
                  )
              )}
              {exhibitor.getPictures() &&
                Math.floor((exhibitor.getPictures().length - 1) / 3) >
                carouselIndex ? (
                  <ArrowForwardIosIcon
                    onClick={() => setCarouselIndex(carouselIndex + 1)}
                    style={{ cursor: "pointer", width: 25 }}
                  />
                ) : (
                  <div style={{ width: 25 }} />
                )}
            </Grid>
          </div>
        </div>
      </Backdrop>
    );
}

export default ExhibitorDetailsModal;
