import {
  FETCHING_EXHIBITION_EXHIBITORS_REQUEST,
  FETCHING_EXHIBITION_EXHIBITORS_SUCCESS,
  FETCHING_EXHIBITION_EXHIBITORS_FAILURE,
  
  FETCHING_EXHIBITION_EXHIBITOR_REQUEST,
  FETCHING_EXHIBITION_EXHIBITOR_SUCCESS,
  FETCHING_EXHIBITION_EXHIBITOR_FAILURE,

  CREATE_EXHIBITION_EXHIBITOR_FAILURE,
  CREATE_EXHIBITION_EXHIBITOR_SUCCESS,
  CREATE_EXHIBITION_EXHIBITOR_REQUEST,

  UPDATE_EXHIBITION_EXHIBITOR_FAILURE,
  UPDATE_EXHIBITION_EXHIBITOR_SUCCESS,
  UPDATE_EXHIBITION_EXHIBITOR_REQUEST,

  DELETE_EXHIBITION_EXHIBITOR_FAILURE,
  DELETE_EXHIBITION_EXHIBITOR_SUCCESS,
  DELETE_EXHIBITION_EXHIBITOR_REQUEST,
} from '../actions/types';
import ApplicationError from '../../utils/ApplicationError';
import ResponseService from '../../utils/ResponseService';

const initialState = {
  exhibitors: [],
  exhibitor: undefined,
  error: ApplicationError.buildDefault(),
  isFetching: false,
  ...ResponseService.defaultPaginationMetaData()
};

const exhibitorsReducer = (state = initialState, action ) => {
  switch(action.type) {
    case FETCHING_EXHIBITION_EXHIBITORS_REQUEST:
      return {...state, isFetching: true, exhibitors: [] }
    case FETCHING_EXHIBITION_EXHIBITORS_SUCCESS:
      return {...state, isFetching: false, 
        exhibitors: action.payload.objects, 
        error: ApplicationError.buildDefault(),
        ...action.payload.paginationData }

    case FETCHING_EXHIBITION_EXHIBITORS_FAILURE:
      return {...state, isFetching: false, error: action.payload }
    
    case FETCHING_EXHIBITION_EXHIBITOR_REQUEST:
      return {...state, isFetching: true }
    case FETCHING_EXHIBITION_EXHIBITOR_SUCCESS: 
      return {...state, isFetching: false, exhibitor: action.payload, error: ApplicationError.buildDefault() }
    case FETCHING_EXHIBITION_EXHIBITOR_FAILURE:
      return {...state, isFetching: false, error: action.payload }  

    // CREATE  
    case  CREATE_EXHIBITION_EXHIBITOR_REQUEST:
      return {...state, isFetching: true }
    case  CREATE_EXHIBITION_EXHIBITOR_SUCCESS:
      return {...state, isFetching: false, exhibitor: action.payload, error: ApplicationError.buildDefault() }
    case  CREATE_EXHIBITION_EXHIBITOR_FAILURE:
      return {...state, isFetching: false, error: action.payload }

    // UPDATE  
    case  UPDATE_EXHIBITION_EXHIBITOR_REQUEST:
      return {...state, isFetching: true }
    case  UPDATE_EXHIBITION_EXHIBITOR_SUCCESS:
      return {...state, isFetching: false, exhibitor: action.payload, error: ApplicationError.buildDefault() }
    case  UPDATE_EXHIBITION_EXHIBITOR_FAILURE:
      return {...state, isFetching: false, error: action.payload }

    // DELETE  
    case  DELETE_EXHIBITION_EXHIBITOR_REQUEST:
      return {...state, isFetching: true }
    case  DELETE_EXHIBITION_EXHIBITOR_SUCCESS:
      return {...state, isFetching: false, error: ApplicationError.buildDefault() }
    case  DELETE_EXHIBITION_EXHIBITOR_FAILURE:
      return {...state, isFetching: false, error: action.payload }


    default: 
      return state
  }
}

export default exhibitorsReducer;