import ApplicationError from "../../utils/ApplicationError";
import {
  FETCHING_CONFIGURATION_FAILURE,
  FETCHING_CONFIGURATION_REQUEST,
  FETCHING_CONFIGURATION_SUCCESS,
} from "../actions/types";

const initialState = {
  configuration: undefined,
  error: ApplicationError.buildDefault(),
  isFetching: false,
};

const configurationsReducer = (state = initialState, action) => {
  switch (action.type) {
    // ALL
    case FETCHING_CONFIGURATION_REQUEST:
      return { ...state, isFetching: true };
    case FETCHING_CONFIGURATION_SUCCESS:
      return {
        ...state,
        isFetching: false,
        configuration: action.payload,
        error: ApplicationError.buildDefault(),
      };
    case FETCHING_CONFIGURATION_FAILURE:
      return { ...state, isFetching: false, error: action.payload };

    default:
      return state;
  }
};

export default configurationsReducer;
