import BaseMapper from "./BaseMapper";
import VideoMapper from "./VideoMapper";
import ConversationMapper from "./ConversationMapper";
import LocalName from "../models/LocalName";

const attributes = LocalName.attributes

export default class LocalNameMapper {
  static build = json => {
    if (!json) {
      return
    }
    return new LocalName({
      ...BaseMapper.attributes(json),
      name: json[attributes.name],
      language: json[attributes.language],
    });
  };

  static buildFromArray = (jsonArray = []) => {
    if (!jsonArray) {
      return
    }
    return jsonArray.map(entry => {
      return this.build(entry);
    });
  };

  static toJsonString = (localText) => {
    return JSON.stringify({
      text: localText.getText(),
      language: localText.getLanguage(),
    });
  };
}
