
const NeumorphicStyle = {
    boxShadowLarge: (darkShadow,lightShadow) => `7px 7px 15px ${darkShadow}, -7px -7px 15px ${lightShadow}`,
    boxShadowMedium: (darkShadow,lightShadow) => `5px 5px 10px ${darkShadow}, -5px -5px 10px ${lightShadow}`,
    boxShadowSmall: (darkShadow,lightShadow) => `2px 2px 4px ${darkShadow}, -2px -2px 4px ${lightShadow}`,
    boxShadowLargeInset: (darkShadow,lightShadow) => `inset 7px 7px 15px ${darkShadow}, inset -7px -7px 15px ${lightShadow}`,
    boxShadowMediumInset: (darkShadow,lightShadow) => `inset 5px 5px 10px ${darkShadow}, inset  -5px -5px 10px ${lightShadow}`,
    boxShadowSmallInset: (darkShadow,lightShadow) => `inset 2px 2px 4px ${darkShadow}, inset -2px -2px 4px ${lightShadow}`,
    hoveringShadow: (darkShadow,lightShadow) => `0px 0px 12px ${darkShadow}`,
    borderRadius: "12px",
    brand: '#E22525',
    footerHeight: 72,
    mobileFooterHeight: 56,
    sidebarWidth: 300,
    sidebarMobileHeight: 90,
    sidebarMobilePadding: 8,
    participantBorderWidth: 2,
    mobileTopBarHeight: 52,
    rightDrawerWidth: 320,
}

export default NeumorphicStyle