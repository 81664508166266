import {
  RESET_PASSWORD_SHOW_SUCCESS,
  RESET_PASSWORD_SHOW_REQUEST,
  RESET_PASSWORD_SHOW_FAILURE,
  RESET_PASSWORD_CREATE_SUCCESS,
  RESET_PASSWORD_CREATE_REQUEST,
  RESET_PASSWORD_CREATE_FAILURE,
  RESET_PASSWORD_UPDATE_SUCCESS,
  RESET_PASSWORD_UPDATE_REQUEST,
  RESET_PASSWORD_UPDATE_FAILURE,
} from '../actions/types';
import ApplicationError from '../../utils/ApplicationError';

const initialState = {
  error: ApplicationError.buildDefault(),
  isFetching: false,
  isReset: false,
};

const userPasswordReducer = (state = initialState, action ) => {
  switch(action.type) {
    case RESET_PASSWORD_SHOW_REQUEST:
      return {...state, isFetching: true }
    case RESET_PASSWORD_SHOW_SUCCESS:
      return {...state, isFetching: false, error: ApplicationError.buildDefault() }
    case RESET_PASSWORD_SHOW_FAILURE:
      return {...state, isFetching: false, error: action.payload }

    case RESET_PASSWORD_CREATE_REQUEST:
      return {...state, isFetching: true }
    case RESET_PASSWORD_CREATE_SUCCESS:
      return {...state, isFetching: false, isReset: true, error: ApplicationError.buildDefault() }
    case RESET_PASSWORD_CREATE_FAILURE:
      return {...state, isFetching: false, error: action.payload }
        
        
    case RESET_PASSWORD_UPDATE_REQUEST:
      return {...state, isFetching: true }
    case RESET_PASSWORD_UPDATE_SUCCESS:
      return {...state, isFetching: false, error: ApplicationError.buildDefault() }
    case RESET_PASSWORD_UPDATE_FAILURE:
      return {...state, isFetching: false, error: action.payload }

    default: 
      return state
  }
}

export default userPasswordReducer;