/* eslint-disable react/display-name */
import React, { useState, forwardRef } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import moment from "moment";
import { makeStyles } from "@material-ui/styles";
import {
  Card,
  CardContent,
  CardActions,
  Typography,
  Button,
  Divider,
} from "@material-ui/core";
import { Trans } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    outline: "none",
    boxShadow: theme.shadows[20],
    width: 700,
    maxHeight: "100%",
    overflowY: "auto",
    maxWidth: "100%",
  },
  field: {
    marginTop: theme.spacing(3),
  },
  cancelButton: {
    marginLeft: "auto",
  },
  confirmButton: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.secondary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
  },
}));

const AddEditEvent = forwardRef((props, ref) => {
  const { event, onClose, onGoto, className, ...rest } = props;
  const classes = useStyles();

  return (
    <Card {...rest} className={clsx(classes.root, className)} ref={ref}>
      <form>
        <CardContent>
          <Typography align="center" gutterBottom variant="h3">
            {event.getTitle()}
          </Typography>
        </CardContent>
        <Divider />
        <CardActions>
          <Button
            className={classes.cancelButton}
            onClick={onClose}
            variant="contained"
          >
            <Trans>frontend.actions.close</Trans>
          </Button>
          <Button
            className={classes.confirmButton}
            onClick={() => onGoto(event)}
            variant="contained"
            color="secondary"
          >
           <Trans>frontend.actions.to_workshop</Trans>
          </Button>
        </CardActions>
      </form>
    </Card>
  );
});

AddEditEvent.propTypes = {
  className: PropTypes.string,
  event: PropTypes.object,
  onCancel: PropTypes.func
};

export default AddEditEvent;
