import {
  FETCHING_USERS_TICKETS_REQUEST,
  FETCHING_USERS_TICKETS_SUCCESS,
  FETCHING_USERS_TICKETS_FAILURE,
  
  FETCHING_USERS_TICKET_REQUEST,
  FETCHING_USERS_TICKET_SUCCESS,
  FETCHING_USERS_TICKET_FAILURE,
} from '../actions/types';
import ResponseService from '../../utils/ResponseService';
import ApplicationError from '../../utils/ApplicationError';


const initialState = {
  tickets: [],
  ticket: undefined,
  error: ApplicationError.buildDefault(),
  isFetching: false,
  ...ResponseService.defaultPaginationMetaData()
};

const userTicketsReducer = (state = initialState, action ) => {
  switch(action.type) {
    case FETCHING_USERS_TICKETS_REQUEST:
      return {...state, isFetching: true }
    case FETCHING_USERS_TICKETS_SUCCESS:
      return {...state, isFetching: false, 
        tickets: action.payload.objects, 
        error: ApplicationError.buildDefault(),
        ...action.payload.paginationData }
    case FETCHING_USERS_TICKETS_FAILURE:
      return {...state, isFetching: false, error: action.payload }
    
    case FETCHING_USERS_TICKET_REQUEST:
      return {...state, isFetching: true }
    case FETCHING_USERS_TICKET_SUCCESS: 
      return {...state, isFetching: false, ticket: action.payload }
    case FETCHING_USERS_TICKET_FAILURE:
      return {...state, isFetching: false, error: action.payload }  

    default: 
      return state
  }
}

export default userTicketsReducer;