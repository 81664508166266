import {
  CREATE_EXHIBITION_SHARE_FAILURE,
  CREATE_EXHIBITION_SHARE_SUCCESS,
  CREATE_EXHIBITION_SHARE_REQUEST
} from '../actions/types';
import ResponseService from '../../utils/ResponseService';
import ApplicationError from '../../utils/ApplicationError';

const initialState = {
  share: undefined,
  error: ApplicationError.buildDefault(),
  isFetching: false,
  ...ResponseService.defaultPaginationMetaData()
};

const sharesReducer = (state = initialState, action ) => {
  switch(action.type) {
    case  CREATE_EXHIBITION_SHARE_REQUEST:
      return {...state, isFetching: true }
    case  CREATE_EXHIBITION_SHARE_SUCCESS:
      return {...state, isFetching: false, share: action.payload, error: ApplicationError.buildDefault() }
    case  CREATE_EXHIBITION_SHARE_FAILURE:
      return {...state, isFetching: false, error: action.payload }

    default: 
      return state
  }
}

export default sharesReducer;