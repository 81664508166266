import {
  FETCHING_EXHIBITION_EXHIBITORS_USERS_REQUEST,
  FETCHING_EXHIBITION_EXHIBITORS_USERS_SUCCESS,
  FETCHING_EXHIBITION_EXHIBITORS_USERS_FAILURE,
  
  FETCHING_EXHIBITION_EXHIBITORS_USER_REQUEST,
  FETCHING_EXHIBITION_EXHIBITORS_USER_SUCCESS,
  FETCHING_EXHIBITION_EXHIBITORS_USER_FAILURE,

  CREATE_EXHIBITION_EXHIBITORS_USER_FAILURE,
  CREATE_EXHIBITION_EXHIBITORS_USER_SUCCESS,
  CREATE_EXHIBITION_EXHIBITORS_USER_REQUEST,

  UPDATE_EXHIBITION_EXHIBITORS_USER_FAILURE,
  UPDATE_EXHIBITION_EXHIBITORS_USER_SUCCESS,
  UPDATE_EXHIBITION_EXHIBITORS_USER_REQUEST,

  DELETE_EXHIBITION_EXHIBITORS_USER_FAILURE,
  DELETE_EXHIBITION_EXHIBITORS_USER_SUCCESS,
  DELETE_EXHIBITION_EXHIBITORS_USER_REQUEST,

  CLEAR_EXHIBITION_EXHIBITORS_USER
} from '../actions/types';
import ResponseService from '../../utils/ResponseService';
import ApplicationError from '../../utils/ApplicationError';

const initialState = {
  exhibitorsUsers: [],
  exhibitorsUser: undefined,
  error: ApplicationError.buildDefault(),
  isFetching: false,
  ...ResponseService.defaultPaginationMetaData()
};

const exhibitorsUsersReducer = (state = initialState, action ) => {
  switch(action.type) {

    // Get All ExhibitorsUser
    case FETCHING_EXHIBITION_EXHIBITORS_USERS_REQUEST:
      return {...state, isFetching: true, exhibitorsUsers: [] }
    case FETCHING_EXHIBITION_EXHIBITORS_USERS_SUCCESS:
      return {...state, isFetching: false, 
        exhibitorsUsers: action.payload.objects, 
        error: ApplicationError.buildDefault(),
        ...action.payload.paginationData }

    case FETCHING_EXHIBITION_EXHIBITORS_USERS_FAILURE:
      return {...state, isFetching: false, error: action.payload }
    
    // Get ExhibitorsUser  
    case FETCHING_EXHIBITION_EXHIBITORS_USER_REQUEST:
      return {...state, isFetching: true }
    case FETCHING_EXHIBITION_EXHIBITORS_USER_SUCCESS: 
      return {...state, isFetching: false, exhibitorsUser: action.payload, error: ApplicationError.buildDefault() }
    case FETCHING_EXHIBITION_EXHIBITORS_USER_FAILURE:
      return {...state, isFetching: false, error: action.payload }  

    // Create ExhibitorsUser
    case  CREATE_EXHIBITION_EXHIBITORS_USER_REQUEST:
      return {...state, isFetching: true }
    case  CREATE_EXHIBITION_EXHIBITORS_USER_SUCCESS:
      return {...state, isFetching: false, exhibitorsUser: action.payload, error: ApplicationError.buildDefault() }
    case  CREATE_EXHIBITION_EXHIBITORS_USER_FAILURE:
      return {...state, isFetching: false, error: action.payload }

    //Update ExhibitorsUser  
    case  UPDATE_EXHIBITION_EXHIBITORS_USER_REQUEST:
      return {...state, isFetching: true }
    case  UPDATE_EXHIBITION_EXHIBITORS_USER_SUCCESS:
      return {...state, isFetching: false, exhibitorsUser: action.payload, error: ApplicationError.buildDefault() }
    case  UPDATE_EXHIBITION_EXHIBITORS_USER_FAILURE:
      return {...state, isFetching: false, error: action.payload }

    // Delete ExhibitorsUser  
    case  DELETE_EXHIBITION_EXHIBITORS_USER_REQUEST:
      return {...state, isFetching: true }
    case  DELETE_EXHIBITION_EXHIBITORS_USER_SUCCESS:
      return {...state, isFetching: false, error: ApplicationError.buildDefault() }
    case  DELETE_EXHIBITION_EXHIBITORS_USER_FAILURE:
      return {...state, isFetching: false, error: action.payload }

    case CLEAR_EXHIBITION_EXHIBITORS_USER:
      return { ...initialState }

    default: 
      return state
  }
}

export default exhibitorsUsersReducer;