import {
  CREATE_EXHIBITION_POST_REQUEST,
  CREATE_EXHIBITION_POST_SUCCESS,
  CREATE_EXHIBITION_POST_FAILURE,
} from './types';
import Urls from '../../constants/Urls';
import { BEARER } from '../../utils/MyStore';

import ShareMapper from '../../mapper/ShareMapper';
import ResponseService from '../../utils/ResponseService';
import ApplicationError from '../../utils/ApplicationError';
import ErrorLogger from '../../utils/ErrorLogger';


export const createShareRequest = () => ({
  type: CREATE_EXHIBITION_POST_REQUEST
})

export const createShareSuccess = (share) => ({
  type: CREATE_EXHIBITION_POST_SUCCESS,
  payload: share
})

export const createShareFailure = (error) => ({
  type: CREATE_EXHIBITION_POST_FAILURE,
  payload: error
})

export const createShare = (share) => {
  return async dispatch => {
    dispatch(createShareRequest());
    try {

      const route = Urls.exhibitions.shares.create
      const bearer = localStorage.getItem(BEARER)
      const headers = Urls.header.secure(bearer)
      const url = Urls.build(route.url(share.getExhibitionId(), share.getId()))

      let response = await fetch(url, {
        method: route.method,
        headers: headers,
        body: ShareMapper.toJsonString(share)
      });

      let responseService = new ResponseService(ShareMapper)

      let action = await responseService.call(response, 
        (value) => createShareSuccess(value),
        (error) => createShareFailure(error))

      dispatch(action)  
    } catch(error) {
      ErrorLogger.call(this, error)
      dispatch(createShareFailure(ApplicationError.buildApplicationError(error)))
    }
  };
};