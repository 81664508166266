import {
  FETCHING_EXHIBITION_MESSAGES_REQUEST,
  FETCHING_EXHIBITION_MESSAGES_SUCCESS,
  FETCHING_EXHIBITION_MESSAGES_FAILURE,
  
  FETCHING_EXHIBITION_MESSAGE_REQUEST,
  FETCHING_EXHIBITION_MESSAGE_SUCCESS,
  FETCHING_EXHIBITION_MESSAGE_FAILURE,

  CREATE_EXHIBITION_MESSAGE_FAILURE,
  CREATE_EXHIBITION_MESSAGE_SUCCESS,
  CREATE_EXHIBITION_MESSAGE_REQUEST,

  UPDATE_EXHIBITION_MESSAGE_FAILURE,
  UPDATE_EXHIBITION_MESSAGE_SUCCESS,
  UPDATE_EXHIBITION_MESSAGE_REQUEST,

  DELETE_EXHIBITION_MESSAGE_FAILURE,
  DELETE_EXHIBITION_MESSAGE_SUCCESS,
  DELETE_EXHIBITION_MESSAGE_REQUEST,
  CLEAR_EXHIBITION_MESSAGE,

  SET_UNREAD_MESSAGES_COUNT_MESSAGES
} from '../actions/types';
import ResponseService from '../../utils/ResponseService';
import ApplicationError from '../../utils/ApplicationError';

const initialState = {
  messages: [],
  message: undefined,
  error: ApplicationError.buildDefault(),
  isFetching: false,
  unreadMessagesCount: 0,
  ...ResponseService.defaultPaginationMetaData()
};

const messagesReducer = (state = initialState, action ) => {
  switch(action.type) {
    case FETCHING_EXHIBITION_MESSAGES_REQUEST:
      return {...initialState, isFetching: true }
    case FETCHING_EXHIBITION_MESSAGES_SUCCESS:
      return {...state, isFetching: false, 
        messages: action.payload.objects, 
        error: ApplicationError.buildDefault(),
        unreadMessagesCount: 0, //action.payload.additional.unread_messages_count,
        ...action.payload.paginationData }

    case FETCHING_EXHIBITION_MESSAGES_FAILURE:
      return {...state, isFetching: false, error: action.payload }
    
    case FETCHING_EXHIBITION_MESSAGE_REQUEST:
      return {...state, isFetching: true }
    case FETCHING_EXHIBITION_MESSAGE_SUCCESS: 
      return {...state, isFetching: false, message: action.payload, error: ApplicationError.buildDefault() }
    case FETCHING_EXHIBITION_MESSAGE_FAILURE:
      return {...state, isFetching: false, error: action.payload }  

    case  CREATE_EXHIBITION_MESSAGE_REQUEST:
      return {...state, isFetching: true, message:undefined }
    case  CREATE_EXHIBITION_MESSAGE_SUCCESS:
      return {...state, isFetching: false, message: action.payload, error: ApplicationError.buildDefault() }
    case  CREATE_EXHIBITION_MESSAGE_FAILURE:
      return {...state, isFetching: false, error: action.payload }

    case  UPDATE_EXHIBITION_MESSAGE_REQUEST:
      return {...state, isFetching: true }
    case  UPDATE_EXHIBITION_MESSAGE_SUCCESS:
      return {...state, isFetching: false, message: action.payload, error: ApplicationError.buildDefault() }
    case  UPDATE_EXHIBITION_MESSAGE_FAILURE:
      return {...state, isFetching: false, error: action.payload }

    case  DELETE_EXHIBITION_MESSAGE_REQUEST:
      return {...state, isFetching: true }
    case  DELETE_EXHIBITION_MESSAGE_SUCCESS:
      return {...state, isFetching: false, error: ApplicationError.buildDefault() }
    case  DELETE_EXHIBITION_MESSAGE_FAILURE:
      return {...state, isFetching: false, error: action.payload }

    case CLEAR_EXHIBITION_MESSAGE:
      return { ...initialState }  
    case SET_UNREAD_MESSAGES_COUNT_MESSAGES:
      return { ...state, unreadMessagesCount: action.payload > 0 ? action.payload : 0 }  

    default: 
      return state
  }
}

export default messagesReducer;