import React, { useEffect, useRef, useState } from 'react'
import Sync, { SyncClient, SyncList, SyncListItem } from 'twilio-sync'
import Urls from '../../constants/Urls';
import MyStore, { BEARER } from '../../utils/MyStore';
import useExhibition from './useExhibition';
import useFetch, { useFetchLazy } from './useFetch';

function useTwilioSyncList(
    name: string | undefined, 
    onItemAdded: (element: SyncListItem | undefined) => void
) {
    const [list, setList] = useState<SyncList | undefined>();
    const [elements, setElements] = useState<SyncListItem[]>([]);
    const [token, setToken] = useState<any>();    
    const [syncClient, setSyncClient] = useState<SyncClient | undefined>()
    const url = Urls.twilio.video_token.url();
    const [exhibition] = useExhibition();
    const listRef = useRef<SyncList>();
    
    const { response, isLoading, error, fetchData } = useFetchLazy(url, {
        headers: Urls.header.secure(localStorage.getItem(BEARER)) as HeadersInit,
        method: Urls.twilio.video_token.method,
    });

    useEffect(() => {
        if (name) {
            fetchData();
        }
    }, [name])

    useEffect(() => {
        if (!response) {
            return;
        }

        const { token } = response;
        setToken(token);
        setSyncClient(new Sync(token));
    }, [response])

    useEffect(() => {
        if (!token || !exhibition || !syncClient || !name) {
            return;
        }

        syncClient.list(name).then(list => {
            setList(list);
        })
    }, [token, exhibition, syncClient])

    useEffect(() => {
        if (!list) {
            return;
        }

        if (!listRef.current) {
            list.getItems({ limit: 1000, pageSize: 1000,  }).then((page) => updateElements(page.items))
            
            list.on("itemAdded", (e) => {
                setElements(elements => elements.concat(e.item))
            })
            list.on('itemRemoved', event => {
                setElements(elements => elements.filter(p => p.index !== event.index));
            });
        }

        listRef.current = list;

        return () => {
            setList(list => {
                list?.close();
                return undefined
            });
        }
    }, [list])

    const updateElements = (items) => {
        setElements(items);
    }
    
    const addElement = async (element: any): Promise<SyncListItem | undefined> => {
        if (!listRef.current) {
            return;
        }

        const newElement = await listRef.current.push(element).catch(e => {
            return undefined;
        })

        onItemAdded(newElement);

        return newElement;
    }

    const removeElement = async (index: number): Promise<boolean> => {
        if (!list) {
            return false
        }

        await list.remove(index).catch(e => {
            return false;
        })

        return true;
    }

    return { elements, isListLoaded: list !== undefined, addElement, removeElement }
}

export default useTwilioSyncList
