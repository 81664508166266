import { combineReducers } from "redux";
import actionCableReducer from "./actionCableReducer";
import activeKeynotesReducer from "./activeKeynotesReducer";
import agendaEntriesReducer from "./agendaEntriesReducer";
import bookmarksReducer from "./bookmarksReducer";
import commentsReducer from "./commentsReducer";
import configurationsReducer from "./configurationsReducer";
import conversationsReducer from "./conversationsReducer";
import conversationsUsersReducer from "./conversationsUsersReducer";
import discussionRoomsReducer from "./discussionRoomsReducer";
import documentsReducer from "./documentsReducer";
import employeesReducer from "./employeesReducer";
import exhibitionsImagesReducer from "./exhibitionsImagesReducer";
import exhibitionsReducer from "./exhibitionsReducer";
import exhibitorsImagesReducer from "./exhibitorsImagesReducer";
import exhibitorsReducer from "./exhibitorsReducer";
import exhibitorsUsersReducer from "./exhibitorsUsersReducer";
import keynoteConversationsReducer from "./keynoteConversationsReducer";
import keynotesImagesReducer from "./keynotesImagesReducer";
import keynotesReducer from "./keynotesReducer";
import keynotesSpeakersReducer from "./keynotesSpeakersReducer";
import keynotesUsersReducer from "./keynotesUsersReducer";
import meetingsReducer from "./meetingsReducer";
import meetingsUsersReducer from "./meetingsUsersReducer";
import messagesReducer from "./messagesReducer";
import moderatorsReducer from "./moderatorsReducer";
import notificationsReducer from "./notificationsReducer";
import postsImagesReducer from "./postsImagesReducer";
import postsLikesReducer from "./postsLikesReducer";
import postsReducer from "./postsReducer";
import pushNotificationsReducer from "./pushNotificationsReducer";
import registrationReducer from "./registrationReducer";
import sessionReducer from "./sessionReducer";
import sharesReducer from "./sharesReducer";
import speakersReducer from "./speakersReducer";
import stagesReducer from "./stagesReducer";
import systemRolesReducer from "./systemRolesReducer";
import talkingPointsReducer from "./talkingPointsReducer";
import thematicAreasReducer from "./thematicAreasReducer";
import ticketInvitationsReducer from "./ticketInvitationsReducer";
import ticketsReducer from "./ticketsReducer";
import ticketsUsersReducer from "./ticketsUsersReducer";
import timeslotsReducer from "./timeslotsReducer";
import userPasswordReducer from "./userPasswordReducer";
import usersImagesReducer from "./usersImagesReducer";
import usersReducer from "./usersReducer";
import usersTicketsReducer from "./usersTicketsReducer";
import visitorsReducer from "./visitorsReducer";

export default combineReducers({
  actionCableReducer,
  activeKeynotesReducer,
  agendaEntriesReducer,
  bookmarksReducer,
  commentsReducer,
  configurationsReducer,
  conversationsReducer,
  conversationsUsersReducer,
  discussionRoomsReducer,
  documentsReducer,
  employeesReducer,
  exhibitionsImagesReducer,
  exhibitionsReducer,
  exhibitorsImagesReducer,
  exhibitorsReducer,
  exhibitorsUsersReducer,
  keynoteConversationsReducer,
  keynotesImagesReducer,
  keynotesReducer,
  keynotesSpeakersReducer,
  keynotesUsersReducer,
  meetingsReducer,
  meetingsUsersReducer,
  messagesReducer,
  moderatorsReducer,
  notificationsReducer,
  postsImagesReducer,
  postsLikesReducer,
  postsReducer,
  pushNotificationsReducer,
  registrationReducer,
  sessionReducer,
  sharesReducer,
  speakersReducer,
  stagesReducer,
  systemRolesReducer,
  talkingPointsReducer,
  thematicAreasReducer,
  ticketInvitationsReducer,
  ticketsReducer,
  ticketsUsersReducer,
  timeslotsReducer,
  userPasswordReducer,
  usersImagesReducer,
  usersReducer,
  usersTicketsReducer,
  visitorsReducer,
});
