import React, { useEffect, useState } from "react";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useSelector } from "react-redux";
import useConfigurationService from "../../utils/ConfigurationService";
import NeumorphicStyle from "../../constants/Neumorphic"

const ExhibitionHeader = ({ exhibition }) => {
  const useStyles = makeStyles((theme) => ({
    backgroundImage: {
      marginTop: "12px",
      marginLeft: 16,
      marginRight: 16,
      background: theme.palette.background.default,
      borderRadius: NeumorphicStyle.borderRadius,
      boxShadow: NeumorphicStyle.boxShadowLarge(theme.palette.primary.main,theme.palette.secondary.dark),
      height: 200,
      backgroundSize: "cover",
      justifySelf: "center"
    },

    h1: {
      bottom: 120,
      left: 80,
      position: "absolute",
      color: "white",
    },

    h2: {
      bottom: 70,
      left: 80,
      position: "absolute",
      color: "white",
    },
  }));

  const classes = useStyles();

  const [config] = useConfigurationService()

  return !config || !config.keyvisual || config.keyvisual.url ? <div></div> : (
    <div className={classes.backgroundImage} style={{ backgroundImage: `url(${config.keyvisual.url})` }}>
    </div>
  );
};

export default ExhibitionHeader;
