import { makeStyles } from '@material-ui/core'
import React, { useEffect, useRef, useState} from 'react'
import Countdown, { CountdownRendererFn, CountdownRenderProps } from 'react-countdown';

const useStyles = makeStyles(theme => ({
    root:{
        fontSize: "2rem",
        fontWeight: "bold",
        width: "4em",
        height: "1.5em",
        borderRadius: "0.75em",
        padding: '0.25em 0.5em',
        alignItems: "center",
        justifyItems: 'center',
        display: "grid",
        backgroundColor: 'rgba(255,255,255,0.85)',
    },
    countdown: {

    }
}))

export interface LbVideoTimerProps {
    active: boolean;
    milliseconds: number;
    roomId: number;
    onTimerEnd: () => void;
}

const LbVideoTimer: React.FC<LbVideoTimerProps> = ({
    active,
    milliseconds,
    roomId,
    onTimerEnd,
}) => {
    const classes = useStyles();
    const startDate = React.useRef(Date.now());
    const defaultRenderer: CountdownRendererFn = (props) => {
        return (
            <div>{`${props.minutes}:${props.seconds < 10 ? "0" : ""}${
                props.seconds
            }`}</div>
        );
      };
    const countdownRef = useRef();

    useEffect(() => {
        if (active) {
            startDate.current = Date.now();
        }
    }, [active])

    return active ? (
        <div className={classes.root}>
            <Countdown
                ref={countdownRef}
                key={roomId}
                date={startDate.current + milliseconds}
                intervalDelay={1000}
                renderer={defaultRenderer}
                onComplete={() => {
                    onTimerEnd()
                }}                
            />
        </div>
    ) : null
}

export default LbVideoTimer
