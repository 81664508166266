import ApplicationModel from "./ApplicationModel";

export default class Exhibition extends ApplicationModel {
  constructor(_opts = {}) {
    super(_opts);
    this.state = { ..._opts };
  }

  getName = () => this.state.name;
  getShortKey = () => this.state.shortKey;
  getSubtitle = () => this.state.subtitle;
  getDescription = () => this.state.description;

  setName = (name) => (this.state.name = name);
  setShortKey = (key) => (this.state.shortKey = key);
  setSubtitle = (subtitle) => (this.state.subtitle = subtitle);
  setDescription = (description) => (this.state.description = description);

  getStartAt = () => this.state.startAt;
  setStartAt = (date) => (this.state.startAt = date);

  getEndAt = () => this.state.endAt;
  setEndAt = (date) => (this.state.endAt = date);

  getGlobalData = () => this.state.globalData;

  getProfilePicture = () => this.state.profilePicture;
  getHeaderPicture = () => this.state.headerPicture;

  hasProfilePicture = () => this.state.profilePicture.getId() !== undefined;
  hasHeaderPicture = () => this.state.headerPicture.getId() !== undefined;

  getConversation = () => this.state.conversation;

  getTimeslotLengthMin = () => this.state.timeslotLengthMin;

  getExhibitionType = () => this.state.exhibitionType;

  getUsers = () => this.state.users;

  getBookingUrl = () => this.state.bookingUrl;

  getStages = () => this.state.stages;

  getTags = () => this.state.tags;

  getOrganizerLink = () => this.state.organizerLink;

  getOrganizerLinkImage = () => this.state.organizerLinkImage;

  getMainExhibitor = () => this.state.mainExhibitor;

  getTerms = () => this.state.terms;

  getTalkingPoints = () => {
    if (this.state.talkingPoints) {
      this.state.talkingPoints.sort((a,b) => b.getOrderIndex() - a.getOrderIndex());
    }
    return this.state.talkingPoints
  };

  getMinPrice = () => this.state.minPrice;
  getMaxPrice = () => this.state.maxPrice;
  getPriceSteps = () => this.state.priceSteps;

  getExhibitionStarted = () => {
    return this.state.startAt < Date.now();
  }

  getRematchHours = () => this.state.rematchHours;

  getMatchmakingLimitMinutes = () => this.state.matchmakingLimitMinutes;

  static attributes = {
    ...super.attributes,
    name: "exhibition_name",
    endAt: "end_at",
    startAt: "start_at",
    subtitle: "subtitle",
    shortKey: "short_key",
    description: "description",
    registeredUrl: "registered_url",
    timeslotLengthMin: "timeslot_length_min",
    conversation: "conversation",
    exhibitionType: "exhibition_type",
    users: "users",
    bookingUrl: "booking_url",
    stages: "stages",
    tags: "tags",
    mainExhibitor: "main_exhibitor",
    terms: "terms",
    talkingPoints: "talking_points",
    minPrice: "min_price",
    maxPrice: "max_price",
    priceSteps: "price_steps",
    rematchHours: "rematch_hours",
    matchmakingLimitMinutes: 'matchmaking_limit_minutes',
  };

  static EXHIBITION_TYPES = {
    CLASSIC: "Classic",
    LANDINGPAGE: "Landingpage",
    VIDEO: "Video",
  };

  static orderByParams = {
    ...super.orderByParams,
    name: this.attributes.name,
    startAt: this.attributes.startAt,
  };

  static filterParams = {
    ...super.filterParams,
    upcoming: "upcoming",
    registeredUrl: this.attributes.registeredUrl,
  };
}
