import {
  FETCHING_EXHIBITION_VISITORS_REQUEST,
  FETCHING_EXHIBITION_VISITORS_SUCCESS,
  FETCHING_EXHIBITION_VISITORS_FAILURE,

  FETCHING_EXHIBITION_VISITOR_REQUEST,
  FETCHING_EXHIBITION_VISITOR_SUCCESS,
  FETCHING_EXHIBITION_VISITOR_FAILURE,
  UPDATE_EXHIBITION_VISITOR_REQUEST,
  UPDATE_EXHIBITION_VISITOR_SUCCESS,
  UPDATE_EXHIBITION_VISITOR_FAILURE,

  CLEAR_VISITOR

} from './types';
import Urls, { DEFAULT_OPTIONAL_PARAMETERS } from '../../constants/Urls';
import { BEARER } from '../../utils/MyStore';

import UserMapper from '../../mapper/UserMapper';
import ResponseService from '../../utils/ResponseService';
import ApplicationError from '../../utils/ApplicationError';
import ErrorLogger from '../../utils/ErrorLogger';

export const fetchingVisitorsRequest = () => ({
  type: FETCHING_EXHIBITION_VISITORS_REQUEST
})

export const fetchingVisitorsSuccess = (json) => ({
  type: FETCHING_EXHIBITION_VISITORS_SUCCESS,
  payload: json
})
export const fetchingVisitorsFailure = (error) => ({
  type: FETCHING_EXHIBITION_VISITORS_FAILURE,
  payload: error
})

export const fetchingVisitorRequest = () => ({
  type: FETCHING_EXHIBITION_VISITOR_REQUEST
})

export const fetchingVisitorSuccess = (exhibitor) => ({
  type: FETCHING_EXHIBITION_VISITOR_SUCCESS,
  payload: exhibitor
})
export const fetchingVisitorFailure = (error) => ({
  type: FETCHING_EXHIBITION_VISITOR_FAILURE,
  payload: error
})

export const updateVisitorRequest = () => ({
  type: UPDATE_EXHIBITION_VISITOR_REQUEST
})
export const updateVisitorSuccess = (exhibitor) => ({
  type: UPDATE_EXHIBITION_VISITOR_SUCCESS,
  payload: exhibitor
})
export const updateVisitorFailure = (error) => ({
  type: UPDATE_EXHIBITION_VISITOR_FAILURE,
  payload: error
})

export const clearVisitor = () => ({
  type: CLEAR_VISITOR
})


export const fetchVisitors = (exhibitionId, _opts = DEFAULT_OPTIONAL_PARAMETERS) => {
  return async dispatch => {
    dispatch(fetchingVisitorsRequest());
    
    try {
      const route   = Urls.exhibitions.visitors.all
      const bearer  = localStorage.getItem(BEARER)
      const headers = Urls.header.secure(bearer)
      const url     = Urls.build(route.url(exhibitionId, _opts.pageIndex, _opts.pageSize), _opts.filters)

      let response = await fetch(url, {
        method: route.method,
        headers: headers
      });

      let responseService = new ResponseService(UserMapper)

      let action = await responseService.call(response, 
        (value) => fetchingVisitorsSuccess(value),
        (error) => fetchingVisitorsFailure(error))

      dispatch(action)
    } catch(error) {
      ErrorLogger.call(this, error)
      dispatch(fetchingVisitorsFailure(ApplicationError.buildApplicationError(error)))
    }
  };
};

export const bookmarkVisitor = (user) => {
  return async dispatch => {
    dispatch(updateVisitorRequest());
    
    try {
      const route = Urls.users.bookmark;
      const bearer = localStorage.getItem(BEARER);
      const headers = Urls.header.secure(bearer);
      const url = Urls.build(route.url(user.getId()));

      let responseService = new ResponseService(UserMapper)

      let response = await fetch(url, {
        method: route.method,
        headers: headers,
      })

      let action = await responseService.call(response, 
        (value) => updateVisitorSuccess(value), 
        (error) => updateVisitorFailure(error))

      dispatch(action)
    } catch(error){
      ErrorLogger.call(this, error)
      dispatch(updateVisitorFailure(ApplicationError.buildApplicationError(error)))
    }
  }
}
export const salesmarkVisitor = (user) => {
  return async dispatch => {
    dispatch(updateVisitorRequest());
    
    try {
      const route = Urls.users.salesmark;
      const bearer = localStorage.getItem(BEARER);
      const headers = Urls.header.secure(bearer);
      const url = Urls.build(route.url(user.getId()));

      let responseService = new ResponseService(UserMapper)

      let response = await fetch(url, {
        method: route.method,
        headers: headers,
      })

      let action = await responseService.call(response, 
        (value) => updateVisitorSuccess(value), 
        (error) => updateVisitorFailure(error))

      dispatch(action)
    } catch(error){
      ErrorLogger.call(this, error)
      dispatch(updateVisitorFailure(ApplicationError.buildApplicationError(error)))
    }
  }
}

export const fetchVisitor = (exhibitionId, id) => {
  return async dispatch => {
    dispatch(fetchingVisitorRequest());

    try {
      const route   = Urls.exhibitions.visitors.show;
      const bearer  = localStorage.getItem(BEARER);
      const headers = Urls.header.secure(bearer);
      const url     = Urls.build(route.url(exhibitionId, id))

      let response = await fetch(url, {
        method: route.method,
        headers: headers
      })

      let responseService = new ResponseService(UserMapper)

      let action = await responseService.call(response, 
        (value) => fetchingVisitorSuccess(value),
        (error) => fetchingVisitorFailure(error))

      dispatch(action)

    } catch(error) {
      ErrorLogger.call(this, error)
      dispatch(fetchingVisitorFailure(ApplicationError.buildApplicationError(error)))
    }
  }
}