import React, { useState } from 'react'

import { create } from '../react-doka/lib/doka.esm.min';
import '../react-doka/lib/doka.min.css';

import './DropArea.css';
import { FilePond, registerPlugin } from 'react-filepond';

import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import 'filepond-plugin-image-edit/dist/filepond-plugin-image-edit.min.css';

import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginImageEdit from 'filepond-plugin-image-edit';
import FilePondPluginImageCrop from 'filepond-plugin-image-crop';
import FilePondPluginImageResize from 'filepond-plugin-image-resize';
import FilePondPluginImageFilter from 'filepond-plugin-image-filter';
import FilePondPluginImageTransform from 'filepond-plugin-image-transform';
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import { makeStyles } from '@material-ui/styles';

registerPlugin(
    FilePondPluginImageExifOrientation,
    FilePondPluginImageCrop,
    FilePondPluginImageResize,
    FilePondPluginImageFilter,
    FilePondPluginImagePreview,
    FilePondPluginImageEdit,
    FilePondPluginImageTransform,
    FilePondPluginFileEncode,
);

const useStyles = makeStyles(theme => ({
    root: {
        // width: 800,
        // height: 800,
    }
}));

function UploadCropComponent({maxFiles = 1, aspectRation = 1, setFile}) {
    const classes = useStyles();
    const [files, setFiles] = useState([])

    return (
        <div className={classes.root}>
            <FilePond
                files={files}
                onupdatefiles={setFiles}
                onprocessfile={f => console.log(f)}
                maxFiles={maxFiles}
                onpreparefile={res => setFile(res)}
                name="files"
                labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                styleItemPanelAspectRatio={.5625}
                imageCropAspectRatio="1:1"
                imageEditEditor={create({
                    onconfirm: () => setFile(undefined),
                    cropAspectRatioOptions: [
                        {
                            label: '',
                            value: aspectRation
                        },
                    ],
                })}
            />
        </div>
    )
}

export default UploadCropComponent
