import {
  FETCHING_USER_REQUEST,
  FETCHING_USER_SUCCESS,
  FETCHING_USER_FAILURE,

  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILURE,

  LOGOUT_USER_REQUEST,
  LOGOUT_USER_SUCCESS,
  LOGOUT_USER_FAILURE,

  CLEAR_USER,

  SET_CURRENT_USER,
  SET_CURRENT_EXHIBITORS,
} from './types';
import Urls from '../../constants/Urls';
import { BEARER } from '../../utils/MyStore';

import UserMapper from '../../mapper/UserMapper';
import ApplicationError from '../../utils/ApplicationError';
import ResponseService from '../../utils/ResponseService'
import ErrorLogger from '../../utils/ErrorLogger';

export const fetchingUserRequest = () => ({
  type: FETCHING_USER_REQUEST
})

export const fetchingUserSuccess = (user) => ({
  type: FETCHING_USER_SUCCESS,
  payload: user
})
export const fetchingUserFailure = (error) => ({
  type: FETCHING_USER_FAILURE,
  payload: error
})

export const updateUserRequest = () => ({
  type: UPDATE_USER_REQUEST
})

export const updateUserSuccess = (json) => ({
  type: UPDATE_USER_SUCCESS,
  payload: json
})
export const updateUserFailure = (error) => ({
  type: UPDATE_USER_FAILURE,
  payload: error
})

export const logOutUserRequest = () => ({
  type: LOGOUT_USER_REQUEST
})

export const logOutUserSuccess = () => ({
  type: LOGOUT_USER_SUCCESS,
})

export const logOutUserFailure = (error) => ({
  type: LOGOUT_USER_FAILURE,
  payload: error
})

export const currentUser = (user) => ({
  type: SET_CURRENT_USER,
  payload: user
})

export const currentExhibitors = (exhibitors) => ({
  type: SET_CURRENT_EXHIBITORS,
  payload: exhibitors
})

export const setCurrentUser = (user) => {
  return async dispatch => { dispatch(currentUser(user)) }
}

export const setCurrentExhibitors = (exhibitors) => {
  return async dispatch => { dispatch(currentExhibitors(exhibitors)) }
}

export const clearUser = () => ({
  type: CLEAR_USER
})

export const updateUser = (user) => {
  return async dispatch => {
    dispatch(fetchingUserRequest());

    try {
      const route = Urls.users.update;
      const bearer = localStorage.getItem(BEARER);
      const headers = Urls.header.secure(bearer);
      const url = Urls.build(route.url(user.getId()));

      let responseService = new ResponseService(UserMapper)

      let response = await fetch(url, {
        method: route.method,
        headers: headers,
        body: UserMapper.toJsonString(user)
      })

      let action = await responseService.call(response,
        (value) => updateUserSuccess(value),
        (error) => updateUserFailure(error))

      dispatch(action)
    } catch (error) {
      ErrorLogger.call(this, error)
      dispatch(updateUserFailure(ApplicationError.buildApplicationError(error)))
    }
  }
}

export const updateUserRaw = (user, userUpdate) => {
  return async dispatch => {
    dispatch(fetchingUserRequest());

    try {
      const route = Urls.users.update;
      const bearer = localStorage.getItem(BEARER);
      const headers = Urls.header.secure(bearer);
      const url = Urls.build(route.url(user.getId()));

      let responseService = new ResponseService(UserMapper)

      let response = await fetch(url, {
        method: route.method,
        headers: headers,
        body: JSON.stringify(userUpdate)
      })

      let action = await responseService.call(response,
        (value) => updateUserSuccess(value),
        (error) => updateUserFailure(error))

      dispatch(action)
    } catch (error) {
      ErrorLogger.call(this, error)
      dispatch(updateUserFailure(ApplicationError.buildApplicationError(error)))
    }
  }
}
export const updateEventUser = async (eventuserId, userUpdate) => {

  try {
    const route = Urls.users.eventuserUpdate;
    const bearer = localStorage.getItem(BEARER);
    const headers = Urls.header.secure(bearer);
    const url = Urls.build(route.url(eventuserId));

    const req = {
      method: route.method,
      headers: headers,
      body: JSON.stringify(userUpdate)
    }

    let response = await fetch(url, req)

    return response.json();
  } catch (error) {
    ErrorLogger.call(this, error)
  }

}

export const changeLocaleUser = (locale) => {
  return async dispatch => {
    dispatch(fetchingUserRequest());

    try {
      const route = Urls.users.changeLocale;
      const bearer = localStorage.getItem(BEARER);
      const headers = Urls.header.secure(bearer);
      const url = Urls.build(route.url(locale));

      let responseService = new ResponseService(UserMapper)

      let response = await fetch(url, {
        method: route.method,
        headers: headers,
      })

      let action = await responseService.call(response,
        (value) => updateUserSuccess(value),
        (error) => updateUserFailure(error))

      dispatch(action)
    } catch (error) {
      ErrorLogger.call(this, error)
      dispatch(updateUserFailure(ApplicationError.buildApplicationError(error)))
    }
  }
}

export const fetchUser = (id) => {
  return async dispatch => {
    dispatch(fetchingUserRequest());

    try {
      const route = Urls.users.show;
      const bearer = localStorage.getItem(BEARER);
      const headers = Urls.header.secure(bearer);

      let responseService = new ResponseService(UserMapper)

      let response = await fetch(route.url(id), {
        method: route.method,
        headers: headers
      })
      let action = await responseService.call(response,
        (value) => fetchingUserSuccess(value),
        (error) => fetchingUserFailure(error))

      dispatch(action)
    } catch (error) {
      ErrorLogger.call(this, error)
      dispatch(fetchingUserFailure(ApplicationError.buildApplicationError(error)))
    }
  }
}

export const logOutUser = () => {
  return async dispatch => {
    dispatch(logOutUserRequest());

    try {
      dispatch(logOutUserSuccess());
    } catch (error) {
      ErrorLogger.call(this, error)
      dispatch(logOutUserFailure(ApplicationError.buildApplicationError(error)))
    }
  }
}