import ApplicationModel from "./ApplicationModel";

export default class Terms extends ApplicationModel {
  constructor(_opts = {}) {
    super(_opts);
    this.state = { ..._opts };
  }

  getGeneralTerms = (language) => {
    if (this.state.generalTerms && this.state.generalTerms.length > 0 && language)  {
      let desc = this.state.generalTerms.find(element => element.getLanguage() === language);
      if (desc && desc.getText()) {
        return desc.getText();
      }
      desc = this.state.generalTerms.find(element => element.getLanguage() === "en");
      if (desc && desc.getText()) {
        return desc.getText();
      }
    }
    return this.state.generalTermsDefault
  };
  getNewsletter = (language) => {
    if (this.state.newsletter && this.state.newsletter.length > 0 && language)  {
      let desc = this.state.newsletter.find(element => element.getLanguage() === language);
      if (desc && desc.getText()) {
        return desc.getText();
      }
      desc = this.state.newsletter.find(element => element.getLanguage() === "en");
      if (desc && desc.getText()) {
        return desc.getText();
      }
    }
    return this.state.newsletterDefault
  };
  getShareInformation = (language) => {
    if (this.state.shareInformation && this.state.shareInformation.length > 0 && language)  {
      let desc = this.state.shareInformation.find(element => element.getLanguage() === language);
      if (desc && desc.getText()) {
        return desc.getText();
      }
      desc = this.state.shareInformation.find(element => element.getLanguage() === "en");
      if (desc && desc.getText()) {
        return desc.getText();
      }
    }
    return this.state.shareInformationDefault
  };


  className = "Terms"

  // Backend Attributes
  static attributes = {
    ...super.attributes,
    generalTerms: "general_terms",
    newsletter: "newsletter",
    shareInformation: "share_information",
    generalTermsDefault: "general_terms_default",
    newsletterDefault: "newsletter_default",
    shareInformationDefault: "share_information_default",
    exhibition: "exhibition"
  };

}
