import { Avatar, Button, Card, CardHeader, Divider, Grid, Typography, useTheme, Box, Popover } from '@material-ui/core'
import { FileCopy } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import React, { useContext, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next';
import NeumorphicStyle from '../../../constants/Neumorphic';
import useBreakpoints from '../../../engines/Util/useBreakpoints';

const useStyles = makeStyles(theme => ({
    root: {
        width: "100%",
        height: (props) => props.breakpoint <= 3 ? "calc(100% - 100px)" : "calc(100% - 100px)",
        overflowY: "auto",
        overflowX: "hidden",
        paddingLeft: 15
    },
    actions: {
        width: (props) => props.breakpoint <= 3 ? 170 : 220,
        marginTop: "12px",
        marginBottom: "20px",
        justifySelf: "center",
        marginLeft: "auto",
        marginRight: "auto",
        height: (props) => props.breakpoint <= 3 ? 35 : 45,
        background: theme.palette.secondary.light,
        boxShadow: NeumorphicStyle.boxShadowMedium(theme.palette.primary.main, theme.palette.secondary.dark),
        color: theme.palette.secondary.contrastText,
        "&:hover": {
            color: theme.palette.secondary.contrastText,
            background: theme.palette.secondary.light,
        }
    },
    avatarContainer: {
        position: "relative",
        cursor: 'pointer',
    },
    professionText: {
        maxWidth: 240
    },
    avatar: {
        width: (props) => props.breakpoint <= 3 ? 35 : 50,
        height: (props) => props.breakpoint <= 3 ? 35 : 50,
    },
    chatButtonContainer: {
        maxWidth: (props) => props.breakpoint <= 3 ? 150 : 220,
        marginLeft: (props) => props.breakpoint <= 3 ? 20 : 30
    },
    chatButton: {
        marginTop: (props) => props.breakpoint <= 3 ? "8px" : "12px",
        fontSize: (props) => props.breakpoint <= 3 ? 12 : 14,
        color: theme.palette.secondary.contrastText,
        background: `linear-gradient(to right, ${theme.palette.secondary.main}, ${theme.palette.primary.light})`,
        boxShadow: NeumorphicStyle.boxShadowMedium(theme.palette.primary.main, theme.palette.secondary.dark),
        // "&:hover": {
        //     backgroundColor: `linear-gradient(to right, ${theme.palette.secondary.main}, ${theme.palette.primary.light}`,
        // }
    },
    userContainer: {
        width: "100%"
    },
    header: {

    },
    contactText: {
        marginTop: (props) => props.breakpoint <= 3 ? 12 : 20,
        marginLeft: (props) => props.breakpoint <= 3 ? 10 : 15
    },
    contactButton: {
        marginTop: (props) => props.breakpoint <= 3 ? 12 : 20,
        marginBottom: "auto",
        marginLeft: "auto",
        marginRight: "auto",
        color: theme.palette.secondary.contrastText,
        height: (props) => props.breakpoint <= 3 ? 35 : 50,
        width: (props) => props.breakpoint <= 3 ? 120 : 200,
        background: `linear-gradient(to right, ${theme.palette.secondary.main}, ${theme.palette.primary.light})`,
        boxShadow: NeumorphicStyle.boxShadowMedium(theme.palette.primary.main, theme.palette.secondary.dark),
    },
    popover: {
        background: theme.palette.background.default,
        boxShadow: NeumorphicStyle.boxShadowSmall(
            theme.palette.primary.main,
            theme.palette.secondary.dark
        ),
        borderRadius: NeumorphicStyle.borderRadius,
        minHeight: 75,
        width: 250,
        padding: 16,
    }
}));

function ExhibitorUsersCard({user, handleStartChat}) {
    const theme = useTheme();
    const [breakpoint] = useBreakpoints();
    const classes = useStyles({ breakpoint })

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <Grid item container direction="column" className={classes.userContainer}>
            <Grid item container direction="row" justify="flex-start" className={classes.header}>
                <Grid item className={classes.avatarContainer}>
                    <div style={{
                        position: "absolute",
                        width: "10px",
                        height: "10px",
                        backgroundColor: user.isOnline() ? "#44B700" : "#853434",
                        zIndex: "100",
                        borderRadius: "50%",
                        top: breakpoint <= 3 ? "27px" : "40px",
                        right: breakpoint <= 3 ? "1px" : "5px",
                    }}
                    />

                    <Avatar
                        variant="circle"
                        src={user.getProfilePicture() ? user.getProfilePicture().getUrl("thumbnail") : ""}
                        className={classes.avatar}
                        aria-describedby={id}
                        onClick={handleClick}
                        style={{ cursor: "pointer" }}
                    />
                    {(user.getCompanyEmail() || user.getPhoneNumber()) &&
                        <Popover
                            id={id}
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            style={{ boxShadow: "none" }}
                        >
                            <Grid
                                container direction="column"
                                className={classes.popover}
                            >
                                {user.getCompanyEmail() && (
                                    <Grid container item xs={12} style={{ flexBasis: 0 }} direction="row">
                                        <Grid item xs={12}>
                                            {user.getCompanyEmail()}
                                        </Grid>
                                        {/* <Grid item xs={1}>
                                                            <FileCopy onClick={() => {
                                                                navigator.clipboard.writeText(user.getCompanyEmail());
                                                            }} />
                                                        </Grid> */}
                                    </Grid>
                                )}
                                {user.getPhoneNumber() && (
                                    <Grid container item xs={12} style={{ flexBasis: 0, marginTop: 4 }} direction="row">
                                        <Grid item xs={12}>
                                            {user.getPhoneNumber()}
                                        </Grid>
                                        {/* <Grid item xs={1}>
                                                            <Button onClick={() => {
                                                                navigator.clipboard.writeText(user.getPhoneNumber());
                                                            }}>
                                                                <FileCopy />
                                                            </Button>
                                                        </Grid> */}
                                    </Grid>
                                )}
                            </Grid>
                        </Popover>
                    }
                </Grid>
                <Grid item container className={classes.chatButtonContainer}>
                    <Grid container direction="column"
                        className={classes.nameContainer}
                    >
                        <Typography
                            variant={breakpoint <= 3 ? "h5" : "h4"}
                            onClick={handleClick}
                            style={{ cursor: "pointer" }}
                        >
                            {user.getFullName()}
                        </Typography>
                        <Typography className={classes.professionText} style={{ fontSize: breakpoint <= 3 ? "10px" : "12px", color: "black" }} variant="caption">
                            {user.getProfession()}
                        </Typography>
                        <Typography className={classes.professionText} style={{ fontSize: breakpoint <= 3 ? "10px" : "12px", color: "black" }} variant="caption">
                            {user.getCompanyName()}
                        </Typography>
                    </Grid>
                    <Button
                        variant="contained"
                        disabled={!user.hasWritePermissions()}
                        className={classes.chatButton}
                        onClick={() => handleStartChat(user)}
                    >
                        <Trans>frontend.start_chat</Trans>
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default ExhibitorUsersCard
