import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import FrontendRoutes from "../constants/FrontendRoutes";
import AuthLayout from "./layouts/Auth";
import DashboardLayout from "./layouts/Dashboard";
import TabletDashboardLayout from "./layouts/Dashboard/Tablet";
import ErrorLayout from "./layouts/Error";
import Logout from "./layouts/Logout";

const {
  bookmarks,
  visitors,
  exhibitors,
  agenda,
  chat,
  root,
  auth,
  errors,
  meetings,
  profiles,
  workshops,
  posts,
  users,
} = FrontendRoutes;

const Login = lazy(() => import("./views/Login"));
const Waiting = lazy(() => import("./views/Waiting"));
const Terms = lazy(() => import("./layouts/Auth/AuthTermsPage"));
const FAQ = lazy(() => import("./views/FAQ"));
const Register = lazy(() => import("./views/Register"));
const PasswordReset = lazy(() => import("./views/Login/ForgetPassword"));

const Error404 = lazy(() => import("./views/Error404"));
const Error500 = lazy(() => import("./views/Error500"));

const SocialFeed = lazy(() => import("./views/SocialFeed"));
const PostDetail = lazy(() => import("./components/PostCard/PostDetails"));

const Profile = lazy(() => import("./views/Profile"));

const Agenda = lazy(() => import("./views/Agenda"));

const Visitor = lazy(() => import("./views/Visitor"));
const VisitorDetails = lazy(() => import("./views/Visitor/VisitorDetails"));
const Workshop = lazy(() => import("./views/Workshop"));

const Meeting = lazy(() => import("./views/Meeting"));
const MeetingDetails = lazy(() => import("./views/Meeting/MeetingDetails"));

const WorkshopDetails = lazy(() => import("./views/Workshop/Details"));

const Exhibitor = lazy(() => import("./views/Exhibitor"));
const Bookmark = lazy(() => import("./views/Bookmark"));

//const Chat = lazy(() => import("./views/ChatView"));

const UserProfile = lazy(() => import("./views/UserProfile"));

const LandingpageLogin = lazy(() => import("./views/LandingpageLogin"));
const UsersLimitReached = lazy(() => import("./views/UsersLimitReached"));

const errorRoutes = {
  path: errors.scope(),
  component: ErrorLayout,
  routes: [
    {
      path: errors.notFound(),
      exact: true,
      component: Error404,
    },
    {
      path: errors.serverError(),
      exact: true,
      component: Error500,
    },
    {
      component: () => <Redirect to={errors.notFound()} />,
    },
  ],
};

const authRoutes = {
  path: auth.scope(),
  component: AuthLayout,
  routes: [
    {
      path: auth.login(),
      exact: true,
      component: Login,
    },
    {
      path: auth.logout(),
      exact: true,
      component: Logout,
    },
    {
      path: auth.faq(),
      exact: true,
      component: FAQ,
    },
    {
      path: auth.register.root(),
      exact: true,
      component: Register,
    },
    {
      path: auth.register.limitReached(),
      exact: true,
      component: UsersLimitReached,
    },
    {
      path: auth.register.welcome(),
      exact: true,
      component: Register,
    },
    {
      path: auth.register.password(),
      exact: true,
      component: Register,
    },
    {
      path: auth.waiting(),
      exact: true,
      component: Waiting,
    },
    {
      path: auth.terms(),
      exact: true,
      component: Terms,
    },
    {
      path: auth.register.confirm(),
      exact: true,
      component: Register,
    },
    {
      path: auth.register.tags(),
      exact: true,
      component: Register,
    },
    {
      path: auth.register.exhibitors(),
      exact: true,
      component: Register,
    },
    {
      path: auth.register.profile_media(),
      exact: true,
      component: Register,
    },
    {
      path: auth.reset(),
      exact: true,
      component: PasswordReset,
    },
    {
      path: auth.landing(),
      exact: true,
      component: LandingpageLogin,
    },
    {
      component: () => <Redirect to={errors.notFound()} />,
    },
  ],
};

export default () => [
  authRoutes,
  errorRoutes,
  {
    route: "*",
    component: DashboardLayout,
    routes: [
      {
        path: workshops.show(),
        exact: true,
        component: WorkshopDetails,
      },
      {
        path: workshops.all(),
        exact: true,
        component: Workshop,
      },
      {
        path: root(),
        exact: true,
        component: SocialFeed,
      },
      // {
      //   path: [chat.all(),chat.show()],
      //   exact: true,
      //   component: Chat
      // },
      {
        path: profiles.all(),
        exact: true,
        component: Profile,
      },
      {
        path: exhibitors.show(),
        exact: true,
        component: Profile,
      },
      {
        path: bookmarks.all(),
        exact: true,
        component: Bookmark,
      },
      {
        path: exhibitors.all(),
        exact: true,
        component: Exhibitor,
      },
      {
        path: visitors.all(),
        exact: true,
        component: Visitor,
      },
      {
        path: visitors.show(),
        exact: true,
        component: VisitorDetails,
      },
      {
        path: agenda(),
        exact: true,
        component: Agenda,
      },
      {
        path: posts.show(),
        exact: true,
        component: PostDetail,
      },
      {
        path: meetings.all(),
        exact: true,
        component: Meeting,
      },
      {
        path: meetings.show(),
        exact: true,
        component: MeetingDetails,
      },
      {
        path: users.show(),
        exact: true,
        component: UserProfile,
      },
      {
        component: () => <Redirect to={errors.notFound()} />,
      },
    ],
  },
];
