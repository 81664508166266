import React, { useState, useEffect, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";
import clsx from "clsx";
import moment from "moment";
import { makeStyles } from "@material-ui/styles";
import {
    Card,
    CardHeader,
    CardContent,
    CardActionArea,
    CardMedia,
    Avatar,
    Typography,
    Divider,
    Tooltip,
    IconButton,
    Button,
    Grid,
    useTheme,
    CardActions,
    Link,
    CircularProgress
} from "@material-ui/core";
import StarIcon from "@material-ui/icons/Star";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import BookMeetingModal from "../../../../components/BookMeetingModal/BookMeetingModal";
import frontendRoutes from "../../../../../constants/FrontendRoutes";
import { Trans, useTranslation } from "react-i18next";
import Bookmark from "../../../../../models/Bookmark";
import {
    deleteBookmark,
    createBookmark,
} from "../../../../../redux/actions/BookmarksActions";
import CogoToast from "../../../../../utils/CogoToast";
import ShareModal from "../../../../components/ShareModal/ShareModal";
import { useInView } from 'react-hook-inview'
import marked from "marked"
import { useMatomo } from '@datapunt/matomo-tracker-react';
import FrontendRoutes from "../../../../../constants/FrontendRoutes";
import MyStore from "../../../../../utils/MyStore";
import ReactPlayer from 'react-player'
import AttachmentCard from "../../../../components/AttachmentCard/AttachmentCard";
import ExhibitorsUsers from "../../../../components/ExhibitorsUsers";
import NeumorphicStyle from "../../../../../constants/Neumorphic";
import context from "react-bootstrap/esm/AccordionContext";
import GlobalContext from "../../context/GlobalContext";
import { bookmarkExhibitor } from "../../../../../redux/actions/ExhibitorsActions";
import useTracking, { EVENT_TRACKING_TYPES } from "../../../../engines/tracking/useTracking";

const Entities = require("html-entities").XmlEntities;

const useStyles = makeStyles((theme) => ({
    root: {
        // background: theme.palette.background.default,
        // borderRadius: NeumorphicStyle.borderRadius,
        // boxShadow: `-5px 5px 15px ${theme.palette.primary.main}, 5px -5px 15px ${theme.palette.secondary.dark}, inset 2px -2px 3px ${theme.palette.primary.main}, inset -2px 2px 4px ${theme.palette.secondary.dark}`,
        marginBottom: "16px",
        marginRight:"16px",
        height: 180,
        width: 180,
        padding: 16,
        background: theme.palette.background.default,
        color: theme.palette.text.primary,
        borderRadius: "10px",
        boxShadow: NeumorphicStyle.boxShadowMedium(theme.palette.primary.main,theme.palette.secondary.dark),
        position: "relative",
        cursor: "pointer",
    },
    subheader: {
        width: "auto",
        maxWidth: "100%",
        marginTop: 12
    },
    bookmarkIcon: {
        position: "absolute",
        right: 15,
        top: 15,
        width: 25,
        height: 25,
        cursor: "pointer"
    },
    cardHeader: {
        backgroundSize: "cover",
        width: "95%",
        paddingBottom: "10%",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        //alignItems: "flex-start",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "16px",
        borderRadius: "15px",
        boxShadow: NeumorphicStyle.boxShadowMedium(theme.palette.primary.main,theme.palette.secondary.dark),
    },
    avatar: {
        width: "70%",
        height: "70%",
        objectFit: "scale-down"
    },
    avatarContainer: {
        width: "100%",
        height: "100%",
        alignItems: "center",
    },
    profileVideo: {
        width: 356,
        height: 200,
        alignItems: "center",
        justifyItems: "center",
        marginRight: "24px",
        marginTop: "20px",
        borderRadius: "10px",
        boxShadow: NeumorphicStyle.boxShadowSmall(theme.palette.primary.main,theme.palette.secondary.dark),
    },
    actions: {
        background: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText,
        borderRadius: "5px",
        boxShadow: NeumorphicStyle.boxShadowSmall(theme.palette.primary.main,theme.palette.secondary.dark),
    },
    title: {
        color: theme.palette.text.primary,
        maxWidth: "100%"
    },
    accessTimeIcon: {
        color: theme.palette.text.secondary,
        fontSize: "14px",
        height: 14,
        width: 14,
        marginRight: 6,
    },
    content: {
        paddingTop: 24,
    },
    message: {
        marginBottom: theme.spacing(2),
    },
    mediaArea: {
        marginBottom: theme.spacing(2),
        padding: 16,
        justifyItems: "flex-end",
        width: "auto",
        marginLeft: "auto"
    },
    media: {
        backgroundPosition: "initial",
        width: "100%",
        paddingBottom: "55%",
        marginTop: "1em",
        marginBottom: "1em",
        objectFit: "cover"
    },
    divider: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    contentBody: {
        "& img": {
            width: "100%",
            height: "auto",
            marginTop: "1em",
            marginBottom: "1em",
        },
        "& iframe": {
            width: "100%",
            height: "414px", // 16:9 bei einer Breite von 736px
            marginTop: "1em",
            marginBottom: "1em",
        },
        "& p": {
            marginTop: "0em",
            marginBottom: "0em",
            fontSize: "1em",
        },
    },
    starFilled: {
        color: theme.palette.secondary.light,
        position: "absolute",
        right: 15,
        top: 15,
        width: 25,
        height: 25,
        cursor: "pointer"
    }
}));

function ExhibitorCard({ exhibitor, setExhibitor, refetchBookmarks, className, ...rest }) {
    const dispatch = useDispatch();
    const theme = useTheme();
    const { t } = useTranslation();
    const entities = new Entities();
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [openShareModal, setOpenShareModal] = useState(false)
    const [bookmarked, setBookmarked] = useState(false);
    const [unbookmarked, setUnbookmarked] = useState(false);
    const [state, setState] = useState({ numPages: undefined, pageNumber: 1 });
    const bookmarks = useSelector((state) => state.bookmarksReducer);
    const exhibitions = useSelector((state) => state.exhibitionsReducer);
    const users = useSelector(state => state.usersReducer);
    const [ trackEvent ] = useTracking();
    const context = useContext(GlobalContext);
    const [bookmarkLoading, setBookmarkLoading] = useState(false);

    const exhibitorsReducer = useSelector(state => state.exhibitorsReducer);

    let time = undefined;
    let blocked = true;

    useEffect(() => {
        if (
            !bookmarkLoading || 
            exhibitorsReducer.isFetching || 
            exhibitorsReducer.error.isError() || 
            (!bookmarked && !unbookmarked)
        ) {
            if (exhibitorsReducer.error.isError()) {
                setBookmarkLoading(false);
            }
            return
        }

        if (bookmarked) {
            exhibitor.setIsBookmarked(true);
            CogoToast.info(t('frontend.added_bookmark'));
        }

        if (unbookmarked) {
            exhibitor.setIsBookmarked(false);
            CogoToast.info(t('frontend.removed_bookmark'));
        }

        refetchBookmarks();
        if (context) {
            context.setUpdateShortlist(true);
        }
        setBookmarkLoading(false);
        setBookmarked(false);
        setUnbookmarked(false);
    }, [exhibitorsReducer.isFetching])

    const [ref, isVisible] = useInView({
        threshold: .5,
        onEnter: () => { time = Date.now(); setTimeout(() => timer(), 20000) },
        onLeave: () => { timer() }
    })

    const timer = () => {
        if (!time) { return }
        let diff = Date.now() - time;

        time = undefined

        if (diff >= 800) {
            //trackEvent(EVENT_TRACKING_TYPES.exhibitor.exhibitorTileViewed, { exhibitor: exhibitor.getId() })
        }
    }

    const handleClose = () => {
        setOpen(false);
        setOpenShareModal(false);
    };

    useEffect(() => {
        if (!unbookmarked) {
            return;
        }

        dispatch(bookmarkExhibitor(exhibitor));
        trackEvent(EVENT_TRACKING_TYPES.exhibitor.unbookmark, { exhibitor: exhibitor.getId() })
        setBookmarkLoading(true);
    }, [unbookmarked]);

    useEffect(() => {
        if (!bookmarked) {
            return;
        }

        dispatch(bookmarkExhibitor(exhibitor));
        trackEvent(EVENT_TRACKING_TYPES.exhibitor.bookmark, { exhibitor: exhibitor.getId() })
        setBookmarkLoading(true);
    }, [bookmarked]);

    function hexToRgbA(hex) {
        var c;
        if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
            c = hex.substring(1).split('');
            if (c.length == 3) {
                c = [c[0], c[0], c[1], c[1], c[2], c[2]];
            }
            c = '0x' + c.join('');
            return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',0.85)';
        }
        throw new Error('Bad Hex');
    }

    const handleBookmark = () => {
        exhibitor.isBookmarked() ? setUnbookmarked(true) : setBookmarked(true)
    }

    return (
        <Grid ref={ref} container item alignItems="center" justify="center" className={clsx(classes.root, className)}>
            {bookmarkLoading ? <CircularProgress thickness={7.0} size={20} color="secondary" className={classes.bookmarkIcon}/> :
                exhibitor.isBookmarked() ? 
                    <StarIcon className={classes.starFilled} onClick={() => handleBookmark()}/>
                        :<StarBorderIcon className={classes.bookmarkIcon} onClick={() => handleBookmark()}/>
            }
            <Grid className={classes.avatarContainer} container item direction="column" alignItems="center" justify="center">
                <Avatar
                    alt="Person"
                    className={classes.avatar}
                    style={{cursor: "pointer"}}
                    component={RouterLink}
                    src={exhibitor && exhibitor.getProfileThumb() ? exhibitor.getProfileThumb().getUrl("medium") : ""}
                    onClick={() => setExhibitor(exhibitor)}
                />
                <Grid className={classes.subheader} direction="column" container>
                    <Link variant="h4" noWrap onClick={() => setExhibitor(exhibitor)} className={classes.title} style={{cursor: "pointer"}}>
                        {exhibitor.getName()}
                    </Link>
                </Grid>
            </Grid>

            <BookMeetingModal
                open={open}
                handleClose={() => handleClose()}
                exhibitor={exhibitor}
                fromClass="Exhibitor"
                payload={exhibitor}
            />
            {/* <ShareModal open={openShareModal} handleClose={() => handleClose()} payload={exhibitor} /> */}
        </Grid>
    );
}

ExhibitorCard.propTypes = {
    className: PropTypes.string,
    exhibitor: PropTypes.object.isRequired,
};

export default ExhibitorCard;