import ApplicationModel from "./ApplicationModel";

export default class EventUser extends ApplicationModel {
  constructor(_opts = {}) {
    super(_opts);
    this.state = { ..._opts };
  }

  getUserId = () => this.state.user.getId();
  getUser = () => this.state.user;
  getName = () => this.state.name;
  getFirstName = () => this.state.user.getFirstName();
  getLastName = () => this.state.user.getLastName();
  getTags = () => this.state.tags
  getContactPerson = () => this.state.contactPerson
  getOnline = () => this.state.online
  getFakeUser = () => this.state.fakeUser
  getTocAccepted = () => this.state.tocAccepted
  getNewsletter = () => this.state.newsletter
  getShareInformation = () => this.state.shareInformation
  getLastStatusAt = () => this.state.last_statusAt
  getExhibition = () => this.state.exhhibition

  // Backend Attributes
  static attributes = {
    ...super.attributes,
    user: 'user',
    name: 'name',
    firstName: 'first_name',
    lastName: 'last_name',
    tags: 'tags',
    contactPerson: 'contact_person',
    online: 'online',
    fakeUser: 'fake_user',
    tocAccepted: 'toc_accepted',
    newsletter: 'newsletter',
    shareInformation: 'share_information',
    lastStatusAt: 'last_status_at',
    exhibition: 'exhhibition'
  };

  static orderByParams = {
    ...super.orderByParams,
  };

  static filterParams = {
    ...super.filterParams,
    status: this.attributes.status,
    userId: this.attributes.userId
  };
}
