import { makeStyles } from '@material-ui/styles'
import React, { useContext, useEffect, useState } from 'react'
import GlobalContext from '../../context/GlobalContext';
import MatchMaking from '../MatchMaking';
import KeynoteView from '../KeynoteView';
import UserProfile from '../UserProfile';
import NeumorphicStyle from '../../../../../constants/Neumorphic';
import { Backdrop, IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import useTracking, { VISIT_TRACKING_TYPES } from '../../../../engines/tracking/useTracking';
import { useTranslation } from 'react-i18next';
import KeynoteModal from '../KeynoteView/KeynoteModal';

const useStyles = makeStyles((theme) => ({
    root: {
        position: "absolute",
        left: "4vw",
        top: "4vh",
        height: "92vh",
        width: "92vw",
        overflow: "auto",
        zIndex: "7000",
        background: "white",
        padding: 60,
    },
    rootSmall: {
        left: 282,
        top: 96,
        bottom: 32,
        right: 16,
        padding: 60,
        background: theme.palette.background.default,
        boxShadow: NeumorphicStyle.boxShadowMedium(theme.palette.primary.main, theme.palette.secondary.dark),
        borderRadius: NeumorphicStyle.borderRadius,
        position: "absolute",
        zIndex: 10000,
        transition: "width 0.2s, height 0.2s, opacity 0.2s 0.2s",
        overflow: "auto",
    },
    rootMedium: {
        left: 16,
        top: 96,
        bottom: 32,
        padding: 60,
        right: 16,
        background: theme.palette.background.default,
        boxShadow: NeumorphicStyle.boxShadowMedium(theme.palette.primary.main, theme.palette.secondary.dark),
        borderRadius: NeumorphicStyle.borderRadius,
        position: "absolute",
        zIndex: 10000,
        transition: "width 0.2s, height 0.2s, opacity 0.2s 0.2s",
        overflow: "auto",
    },
    closed: {
        height: 0,
        width: 0,
    },
    content: {

    },
    backdrop: {
        zIndex: 5000,
        width: "inherit",
        height: "inherit",
        background: "rgba(255,255,255,0.0)",
    },
    backdropMedium: {
        zIndex: 5000,
        width: "inherit",
        height: "inherit",
        background: "rgba(255,255,255,0.0)",
        right: 8,
    },   
    closeIcon: {
        position: "absolute",
        top: 30,
        right: 30,
        marginRight: 8,
        background: theme.palette.background.default,
        zIndex: 800,
    },
    
}))

export const MODAL_TYPE = {
    USER_MODAL: "user_modal",
    MEETING_MODAL: "meeting_modal",
    BRAND_MODAL: "brand_modal",
    KEYNOTE_MODAL: "keynote_modal",
    MAINSTAGE_MODAL: "mainstage_modal",
    MATCHMAKING_MODAL: "matchmaking_modal"
}

function GlobalModal({ children }) {
    const classes = useStyles();
    const context = useContext(GlobalContext);
    const [currentModal, setCurrentModal] = useState();
    const [trackEvent,trackVisit] = useTracking();
    const [t] = useTranslation();

    const getContentClass = (content) => {
        if (!currentModal) {
            return classes.closed
        }
        switch (currentModal.type) {
            case MODAL_TYPE.USER_MODAL:
                return content ? classes.rootSmall : classes.backdropMedium;
            case MODAL_TYPE.KEYNOTE_MODAL:
            case MODAL_TYPE.BRAND_MODAL:
            case MODAL_TYPE.MAINSTAGE_MODAL:
                return content ? classes.rootMedium : classes.backdropMedium;
            case MODAL_TYPE.MEETING_MODAL:
            case MODAL_TYPE.MATCHMAKING_MODAL:
                return content ? classes.root : classes.backdrop;
            default:
                break;
        }
    }

    const renderContent = (type, id) => {
        switch (type) {
            case MODAL_TYPE.BRAND_MODAL:
                break;
            case MODAL_TYPE.MEETING_MODAL:
                break;
            case MODAL_TYPE.KEYNOTE_MODAL:
                trackVisit(VISIT_TRACKING_TYPES.keynote,{
                    keynote: id
                });
                return (<KeynoteModal keynoteId={id}/>)
            case MODAL_TYPE.MAINSTAGE_MODAL:
                trackVisit(VISIT_TRACKING_TYPES.mainStage, {
                    keynote: id
                });
                return (<KeynoteView keynoteId={id} />)
            case MODAL_TYPE.MATCHMAKING_MODAL:
                trackVisit(VISIT_TRACKING_TYPES.matchmaking);
                return (<MatchMaking />);
            case MODAL_TYPE.USER_MODAL:
                trackVisit(VISIT_TRACKING_TYPES.attendee, {
                    attendee: id
                });
                return (<UserProfile userId={id} />);
            default:
                return <div />
        }
    }

    useEffect(() => {
        if (context.modalStack.length > 0) {
            setCurrentModal(context.modalStack[context.modalStack.length - 1])
        } else {
            setCurrentModal(undefined);
        }
    }, [context.modalStack])

    useEffect(() => {
        if (!currentModal || currentModal.type === MODAL_TYPE.USER_MODAL) {
            return;
        }

        if (currentModal) {
            context.setMuted(true);
        } else {
            context.setMuted(false);
        }
    }, [currentModal])

    return (
        <Backdrop
            open={context.modalStack.length > 0}
            className={currentModal ? getContentClass(false) : classes.closed}
            onClick={() => {
                if ( currentModal.type !== MODAL_TYPE.MATCHMAKING_MODAL || window.confirm(t("frontend.confirm_close"))) {
                    context.closeGlobalModal();
                }
            }}
        >
            <div className={getContentClass(true)} onClick={(e) => e.stopPropagation()}>
                {currentModal && currentModal.type !== MODAL_TYPE.MAINSTAGE_MODAL && (
                    <IconButton
                        className={classes.closeIcon}
                        onClick={() => context.closeGlobalModal()}
                    >
                        <Close />
                    </IconButton>
                )}
                {currentModal && renderContent(currentModal.type, currentModal.id)}
            </div>
        </Backdrop>
    )
}

export default GlobalModal



