import React from 'react';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core';
import ChatWindow from '../ChatWindow/ChatWindow';
import ParticipantList from '../ParticipantList/ParticipantList';
import MainParticipant from '../MainParticipant/MainParticipant';
import BackgroundSelectionDialog from '../BackgroundSelectionDialog/BackgroundSelectionDialog';
import useChatContext from '../../../../../engines/CommunicationHooks/useChatContext/useChatContext';
import useVideoContext from '../../../../../engines/CommunicationHooks/useVideoContext/useVideoContext';
import NeumorphicStyle from '../../../../../constants/Neumorphic';

const useStyles = makeStyles((theme: Theme) => {
  const totalMobileSidebarHeight = `${NeumorphicStyle.sidebarMobileHeight +
    NeumorphicStyle.sidebarMobilePadding * 2 +
    NeumorphicStyle.participantBorderWidth}px`;
  return {
    container: {
      width: "100%",
      height: "100%",
      borderRadius: "inherit",
      border: "none",
      left: 0,
      top: 0,
      position: "absolute",
      objectFit: "cover",
      display: 'grid',
      gridTemplateColumns: `1fr ${NeumorphicStyle.sidebarWidth}px`,
      gridTemplateRows: 'calc(100% - 72px)',
      [theme.breakpoints.down('sm')]: {
        gridTemplateColumns: `100%`,
        gridTemplateRows: `calc(100% - ${totalMobileSidebarHeight}) ${totalMobileSidebarHeight}`,
      },
    },
    rightDrawerOpen: { gridTemplateColumns: `1fr ${NeumorphicStyle.sidebarWidth}px ${NeumorphicStyle.rightDrawerWidth}px` },
  };
});

export default function Room() {
  const classes = useStyles();
  const { isChatWindowOpen } = useChatContext();
  const { isBackgroundSelectionOpen } = useVideoContext();
  return (
    <div
      className={clsx(classes.container, {
        [classes.rightDrawerOpen]: isChatWindowOpen || isBackgroundSelectionOpen,
      })}
    >
      <MainParticipant />
      <ParticipantList />
      <ChatWindow />
      <BackgroundSelectionDialog />
    </div>
  );
}
