import { gql } from "@apollo/client";

const exhibitorQueries = {
  feedQuery: gql`
  query (
    $userId: Int
    $bmUserId: Int
    $tags: [String]
    $limit: Int
    $searchText: String
    $start: Int
    $exhibitionId: Int!
    $talkingPointId: Int
    $minPrice: Int
    $maxPrice: Int
    $exhibitors: [Int]
  ) {
    exhibitors(
      sort: "category:asc, order_index:desc"
      limit: $limit
      start: $start
      where: {
        _or: [
          { 
            exhibitor_min_price_gte: $minPrice, 
            exhibitor_min_price_lte: $maxPrice 
          },
          { 
            exhibitor_max_price_gte: $minPrice, 
            exhibitor_max_price_lte: $maxPrice 
          }
        ]
        id_in: $exhibitors
        tags: { name_in: $tags }
        exhibition: { id: $exhibitionId }
        marks: { user: { id: $bmUserId } }
        talking_points: { id: $talkingPointId }
        sponsor_name_null: true
        category_null: false
        order_index_null: false
        name_contains: $searchText
      }
    ) {
      id
      category
      short_description
      company_website
      profile_picture {
        name
        url
        formats
      }
      profile_thumb {
        name
        url
        formats
      }
      name
      profile_video {
        url
        formats
        name
        mime
        size
      }
      tags {
        name
      }
      local_description {
        text
        language
        video {
          name
          url
          formats
          previewUrl
        }
        title
      }
      marks(where: { user: { id: $userId } }) {
        user {
          id
        }
        bookmark
        salesmark
      }
      order_index
    }
    exhibitorsConnection(
      where: { exhibition: { id: $exhibitionId }, sponsor_name_null: true, id_in: $exhibitors }
    ) {
      aggregate {
        count
      }
    }
  }
  `,
  feedQueryTalkingPoint: gql`
  query (
    $userId: Int
    $bmUserId: Int
    $tags: [String]
    $limit: Int
    $searchText: String
    $start: Int
    $exhibitionId: Int!
    $talkingPointId: Int
    $minPrice: Int
    $maxPrice: Int
    $exhibitors: [Int]
  ) {
    exhibitors(
      sort: "category:asc, order_index:desc"
      limit: $limit
      start: $start
      where: {
        _or: [
          { 
            exhibitor_min_price_gte: $minPrice, 
            exhibitor_min_price_lte: $maxPrice 
          },
          { 
            exhibitor_max_price_gte: $minPrice, 
            exhibitor_max_price_lte: $maxPrice 
          }
        ]
        id_in: $exhibitors
        tags: { name_in: $tags }
        exhibition: { id: $exhibitionId }
        marks: { user: { id: $bmUserId } }
        talking_points: { id: $talkingPointId }
        category_null: false
        order_index_null: false
        name_contains: $searchText
      }
    ) {
      id
      category
      short_description
      company_website
      profile_picture {
        name
        url
        formats
      }
      profile_thumb {
        name
        url
        formats
      }
      name
      profile_video {
        url
        formats
        name
        mime
        size
      }
      tags {
        name
      }
      local_description {
        text
        language
        video {
          name
          url
          formats
          previewUrl
        }
        title
      }
      marks(where: { user: { id: $userId } }) {
        user {
          id
        }
        bookmark
        salesmark
      }
      order_index
    }
    exhibitorsConnection(
      where: { exhibition: { id: $exhibitionId }, sponsor_name_null: true, id_in: $exhibitors }
    ) {
      aggregate {
        count
      }
    }
  }
  `,
  feedQueryReduced: gql`
    query (
      $userId: Int
      $bmUserId: Int
      $tags: [String]
      $limit: Int
      $start: Int
      $exhibitionId: Int!
      $searchText: String
    ) {
      exhibitors(
        sort: "name:asc"
        limit: $limit
        start: $start
        where: {
          _or: [{ name_contains: $searchText }]
          tags: { name_in: $tags }
          exhibition: { id: $exhibitionId }
          marks: { user: { id: $bmUserId } }
          sponsor_name_null: true
        }
      ) {
        id
        profile_picture {
          name
          url
          formats
        }
        profile_thumb {
          name
          url
          formats
        }
        name
        marks(where: { user: { id: $userId } }) {
          user {
            id
          }
          bookmark
          salesmark
        }
      }
      exhibitorsConnection(
        where: { exhibition: { id: $exhibitionId }, sponsor_name_null: true }
      ) {
        aggregate {
          count
        }
      }
    }
  `,
  sponsorQuery: gql`
    query (
      $userId: Int
      $bmUserId: Int
      $limit: Int
      $start: Int
      $exhibitionId: Int!
      $searchText: String
      $exhibitors: [Int]
    ) {
      exhibitors(
        sort: "name:asc"
        limit: $limit
        start: $start
        where: {
          _or: [{ name_contains: $searchText }]
          exhibition: { id: $exhibitionId }
          marks: { user: { id: $bmUserId } }
          sponsor_name_null: false
          id_in: $exhibitors
        }
      ) {
        id
        sponsor_name
        short_description
        company_website
        profile_picture {
          name
          url
          formats
        }
        profile_thumb {
          name
          url
          formats
        }
        name
        profile_video {
          url
          formats
          name
          mime
          size
        }
        documents {
          name
          url
        }
        tags {
          name
        }
        local_description {
          text
          language
          video {
            name
            url
            formats
            previewUrl
          }
          title
        }
        marks(where: { user: { id: $userId } }) {
          user {
            id
          }
          bookmark
          salesmark
        }
      }
      exhibitorsConnection(
        where: { exhibition: { id: $exhibitionId }, sponsor_name_null: false }
      ) {
        aggregate {
          count
        }
      }
    }
  `,
  entityQuery: gql`
    query ($userId: Int, $exhibitorId: ID!) {
      exhibitor(id: $exhibitorId) {
        id
        short_description
        company_website
        profile_picture {
          name
          url
          formats
        }
        header_picture {
          name
          url
          formats
        }
        profile_thumb {
          name
          url
          formats
        }
        name
        profile_video {
          url
          formats
          name
          mime
          size
        }
        documents {
          name
          url
        }
        tags {
          name
        }
        local_description {
          text
          language
          video {
            name
            url
            formats
            previewUrl
          }
          fact_sheet{
            name
            url
          }
          title
        }
        marks(where: { user: { id: $userId } }) {
          user {
            id
          }
          bookmark
          salesmark
        }
        pictures {
          url
          formats
          name
        }
        instagram_url
        linkedIn_url
        facebook_url
        twitter_url
        FC_Module
        category
        date_of_establishment
        badge {
          badge_image {
            name
            url
          }
          badge_url
          seconds_show
        }
        exhibition {
          min_price
          max_price
          price_steps
        }
        exhibitor_min_price
        exhibitor_max_price
        created_at
        sponsor_name
        host_products {
          product_1
          product_2
          product_3
          product_4
          product_5
        }
      }
    }
  `,
};

export default exhibitorQueries;
