import {
  Box,
  Button,
  Card,
  Grid,
  IconButton,
  makeStyles,
  Popover,
} from "@material-ui/core";
import {
  CallEnd as CallEndIcon,
  Mic as MicIcon,
  MicOff as MicOffIcon,
  MoreHoriz as MoreHorizIcon,
  Videocam as VideocamIcon,
  VideocamOff as VideocamOffIcon,
} from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import AudioInputList from "./AudioInputList";
import TimeDisplay from "./TimeDisplay";
import VideoInputList from "./VideoInputList";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "lightGrey",
    padding: 4,
    borderRadius: 0,
  },
  gridContainer: {
    width: "100%",
    height: "100%",
  },
  gridItem: {
    width: "20%",
    height: "100%",
  },
}));

function MeetingRoomControls({
  toggleVideo,
  toggleAudio,
  toggleLogout,
  networkQuality,
  localVideoTracks,
  localAudioTracks,
}) {
  const classes = useStyles();

  const [quality, setQuality] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [videoEnabled, setVideoEnabled] = useState(true);
  const [audioEnabled, setAudioEnabled] = useState(true);

  const [anchorOptionsEl, setAnchorOptionsEl] = React.useState(null);

  const handleOptionsClick = (event) => {
    setAnchorOptionsEl(event.currentTarget);
  };

  const handleOptionsClose = () => {
    setAnchorOptionsEl(null);
  };

  const optionsOpen = Boolean(anchorOptionsEl);
  const optionsId = optionsOpen ? "options" : undefined;

  useEffect(() => {
    let interval = null;

    interval = setInterval(() => {
      setSeconds((seconds) => seconds + 1);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    setQuality(networkQuality);
  }, [networkQuality]);

  const handleAudioToggle = () => {
    toggleAudio(!audioEnabled);
    setAudioEnabled(!audioEnabled);
  };

  const handleVideoToggle = () => {
    toggleVideo(!videoEnabled);
    setVideoEnabled(!videoEnabled);
  };

  const handleLogoutToggle = () => {
    toggleLogout();
  };

  return (
    <>
      <Card variant="outlined" className={classes.root}>
        <Grid
          container
          direction="row"
          style={{ height: "100%" }}
          alignItems="center"
        >
          <Grid item>
            <Button onClick={() => handleVideoToggle()}>
              {videoEnabled ? <VideocamIcon /> : <VideocamOffIcon />}
            </Button>
          </Grid>
          <Grid item>
            <Button onClick={() => handleAudioToggle()}>
              {audioEnabled ? <MicIcon /> : <MicOffIcon />}
            </Button>
          </Grid>
          <Grid item>
            <Button onClick={() => handleLogoutToggle()}>
              <CallEndIcon />
            </Button>
          </Grid>
          <Grid item style={{ marginLeft: 16, marginRight: 16, width: 60 }}>
            <TimeDisplay seconds={seconds} />
          </Grid>
          <Grid item>
            <IconButton aria-label="more" onClick={handleOptionsClick}>
              <MoreHorizIcon />
            </IconButton>
          </Grid>
          {/* <Grid item> // only works for group room
                {networkQualityIndicator()}
            </Grid> */}
        </Grid>
      </Card>
      <Popover
        id={optionsId}
        open={optionsOpen}
        anchorEl={anchorOptionsEl}
        onClose={handleOptionsClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <Box p={2} display="flex" flexDirection="column">
          <Box>
            <VideoInputList localTracks={localVideoTracks} />
          </Box>
          <Box mt={1}>
            <AudioInputList localTracks={localAudioTracks} />
          </Box>
        </Box>
      </Popover>
    </>
  );
}

export default MeetingRoomControls;
