import { useLazyQuery } from "@apollo/client";
import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE } from "../../../constants/Urls";
import useExhibition from "../../GeneralHooks/useExhibition";
import GlobalContext from "../../../frontend/layouts/Dashboard/context/GlobalContext";
import PostMapper from "../../../mapper/PostMapper";
import { INTERNAL_IDS } from "../../../models/Role";
import MyStore from "../../../utils/MyStore";
import postQueries from "../queries/postQueries";
import Exhibition from "../../../models/Exhibition";
import Post from "../../../models/Post";

interface PostHookReturnType {
  posts: [Post];
  loading: boolean;
  totalPages: number;
  refetchBookmarks: CallableFunction;
  fetchNewData: CallableFunction;
}

function usePostsQL(
  pageIndex = DEFAULT_PAGE_INDEX,
  pageSize = DEFAULT_PAGE_SIZE,
  showBookmarks = false,
) {
  const [totalPages, setTotalPages] = useState(1);
  const [posts, setPosts] = useState([]);
  const context = useContext(GlobalContext);
  const [exhibition] = useExhibition();
  const usersReducer = useSelector(state  => (state as any).usersReducer);

  const exhibitorQuery = MyStore.getCurrentUser()?.getRole().getName() === INTERNAL_IDS.EXHIBITOR &&
  (!MyStore.getVisitorView() || (exhibition && !(exhibition as Exhibition).getExhibitionStarted()));

  const [fetchData, { loading, data, error, refetch }] = useLazyQuery(
    exhibitorQuery ? postQueries.feedQueryExhibitor : postQueries.feedQuery,
    {
      variables: {
        userId: MyStore.getCurrentUser()
          ? MyStore.getCurrentUser()?.getId()
          : undefined,
        bmUserId:
          showBookmarks === true && MyStore.getCurrentUser()
            ? MyStore.getCurrentUser()?.getId()
            : undefined,
        tags:
          context && context.tags
            ? context.tags.map((tag) => tag.getName())
            : undefined,
        start: (pageIndex - 1) * pageSize,
        limit: pageSize,
        exhibitionId: exhibition ? (exhibition as Exhibition).getId() : "",
        locale: MyStore.getLocal(),
        minPrice: context && context.minPrice,
        maxPrice: context && context.maxPrice,
        eventUser: MyStore.getCurrentEventUser().getId(),
        exhibitors: usersReducer.currentUser &&
          usersReducer.currentUser.getRole() &&
          usersReducer.currentUser.getRole().getPreEventVisible()
          ?
          [] :
          usersReducer.exhibitors || [],

        show: 
          MyStore.getVisitorView() ? true :
          usersReducer.currentUser?.getRole() &&
          [
            INTERNAL_IDS.AUTHENTICATED,
            INTERNAL_IDS.CUSTOMER,
            INTERNAL_IDS.EXHIBITOR
          ].includes(usersReducer.currentUser.getRole().getName(true))
           ?
            undefined : true,
      },
      fetchPolicy: "no-cache",
    }
  );

  useEffect(() => {
    if (context.tabIndex === 0) {
      fetchData();
    }
  }, [context.tabIndex, usersReducer.exhibitors]);

  useEffect(() => {
    if (!data || !data.posts || error) {
      return;
    }

    if (pageIndex === DEFAULT_PAGE_INDEX) {
      var postsData = PostMapper.buildFromArray(data.posts);
      if (postsData.length > 0) {
        postsData[postsData.length - 1].setIsLast(true);
      }
      setPosts(postsData);
    } else {
      var postsData = PostMapper.buildFromArray(data.posts);
      postsData = postsData.filter((post) => post !== undefined);
      if (postsData.length > 0) {
        const position =
          postsData.length > 2 ? postsData.length - 2 : postsData.length - 1;
        postsData[position].setIsLast(true);
        for (let index = 0; index < posts.length; index++) {
          if (posts[index].isLast()) {
            posts[index].setIsLast(false);
          }
        }
      }
      setPosts(posts.concat(postsData));
    }

    if (data.postsConnection) {
      const itemCount =
        data.postsConnection.aggregate.count === 0
          ? data.postsConnection.aggregate.totalCount
          : data.postsConnection.aggregate.count;
      setTotalPages(Math.floor(itemCount / pageSize) + 1);
    }
    if (context.loadingStage === 0) {
      context.setLoadingStage(1);
    }
  }, [data]);

  const refetchBookmarks = () => (refetch ? refetch() : fetchData());
  const fetchNewData = () => fetchData();

  return [posts as [Post], loading, totalPages, refetchBookmarks, fetchNewData] as const;
}

export default usePostsQL;
