import React, { useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import clsx from "clsx";
import moment from "moment";
import { makeStyles } from "@material-ui/styles";
import {
  Typography,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  colors,
  Box,
} from "@material-ui/core";
import Label from "../../../components/Label";

const useStyles = makeStyles((theme) => ({
  active: {
    backgroundColor: colors.grey[50],
  },
  avatar: {
    height: 40,
    width: 40,
  },
  details: {
    marginLeft: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
  },
  unread: {
    marginTop: 2,
    padding: 2,
    height: 18,
    minWidth: 18,
  },
  demiBold: {
    fontWeight: 700,
  }
}));

function ConversationListItem({
  loadChat,
  active,
  conversation,
  className,
  isOnline,
  ...rest
}) {
  const classes = useStyles();
  const [unreadMessageCount, setUnreadMessageCount] = useState(0);
  const usersReducer = useSelector((state) => state.usersReducer);

  const getUnreadMessage = async () => {
    const unreadCount = await conversation.getUnreadMessagesCount();
    if (unreadCount === null && conversation.channelState.lastMessage) {
      const unreadMessage = conversation.channelState.lastMessage.index;
      setUnreadMessageCount(unreadMessage + 1);
    } else {
      setUnreadMessageCount(unreadCount);
    }
  };

  const initMessageAdd = () => {
    conversation.on("messageAdded", () => {
      getUnreadMessage();
    });
    conversation.on("participantUpdated", async (data) => {
      getUnreadMessage();
    });
  };

  useEffect(() => {
    initMessageAdd();
    getUnreadMessage();
  }, []);

  useEffect(() => {
    getUnreadMessage();
  }, [conversation]);

  useEffect(() => {
    if (!active) {
      return;
    }

    setUnreadMessageCount(0);
  }, [active]);

  const date = conversation.lastMessage
    ? conversation.lastMessage.dateCreated
    : conversation.channelState.dateUpdated;

  if (
    !conversation.channelState.attributes[usersReducer.currentUser.state.id]
  ) return <></>;

  return (
    <ListItem
      {...rest}
      button
      className={clsx(
        {
          [classes.active]: active,
        },
        className
      )}
      component={RouterLink}
      to={"!#"}
      onClick={(e) => {
        e.preventDefault();
        loadChat(conversation);
      }}
    >
      <ListItemAvatar>
        <Box>
          <div
            style={{
              position: "absolute",
              width: "10px",
              height: "10px",
              backgroundColor: isOnline ? "#44B700" : "#853434",
              zIndex: "100",
              borderRadius: "50%",
              marginTop: "30px",
              marginLeft: "30px",
            }}
          />
          <Avatar
            alt="Person"
            className={classes.avatar}
            title={
              conversation.channelState.attributes[
                usersReducer.currentUser.state.id
              ].nameChat
            }
            src={
              conversation.channelState.attributes[
                usersReducer.currentUser.state.id
              ].img
            }
          />
        </Box>
      </ListItemAvatar>
      <ListItemText
        primary={
          conversation.channelState.attributes[
            usersReducer.currentUser.state.id
          ].nameChat
        }
        primaryTypographyProps={{
          noWrap: true,
          variant: "h6",
          className: classes.demiBold
        }}
      />
      <div className={classes.details}>
        <Typography noWrap variant="body2">
          {moment(date).isSame(moment(), "day")
            ? moment(date).format("LT")
            : moment(date).fromNow()}
        </Typography>
        {unreadMessageCount > 0 && (
          <Label
            className={classes.unread}
            color={colors.red[500]}
            shape="rounded"
          >
            {unreadMessageCount}
          </Label>
        )}
      </div>
    </ListItem>
  );
}

ConversationListItem.propTypes = {
  active: PropTypes.bool,
  className: PropTypes.string,
  conversation: PropTypes.object.isRequired,
};

export default ConversationListItem;
