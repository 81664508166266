import React, { useRef, useState, useEffect } from "react";
import ConversationListItem from "../../views/Chat/ConversationList/ConversationListItem";
import { useConversations } from "../../../engines/Chat/ChatEngine";
import {
  DEFAULT_PAGE_SIZE,
  DEFAULT_PAGE_INDEX,
  MAX_PAGE_SIZE,
} from "../../../constants/Urls";
import { LinearProgress } from "@material-ui/core";
import useInfiniteScoll from "../../../engines/GeneralHooks/useInfiniteScoll";
import { compareEntitiesById } from "../../../utils";

function ThreadList({
  selectedConversation,
  conversations,
  loadChat,
  onlineVisitors,
  currentUserId,
  localMessagesCount,
}) {
  const containerRef = useRef();
  const [currentPageIndex, setCurrentPageIndex] = useState(DEFAULT_PAGE_INDEX);
  const [pageSize, setPageSize] = useState(MAX_PAGE_SIZE);

  const [loading, totalPages, reloadConversations] = useConversations(
    currentPageIndex,
    pageSize,
    []
  );
  const [pageIndex, isBottom] = useInfiniteScoll(
    containerRef.current,
    totalPages,
    true,
    loading
  );

  const getOnlineStatus = (conversation) => {
    if (conversation.channelState.attributes && onlineVisitors.length !== 0) {
      const keys = Object.keys(conversation.channelState.attributes);
      const id = keys.filter((key) => String(key) !== String(currentUserId));

      if (id.length === 0) return false;

      const user = onlineVisitors.find(
        (visitor) => visitor && String(visitor.id) === String(id)
      );

      return user ? user.online : false;
    }
    return false;
  };

  return (
    <div ref={containerRef} style={{ overflowY: "auto", height: "100%" }}>
      {/* {loading && <LinearProgress />} */}

      {conversations.map((conversation, i) => (
        <ConversationListItem
          active //={selectedConversation && conversation.getId() === selectedConversation.getId() ? true:false}
          conversation={conversation}
          isOnline={
            onlineVisitors.length > 0 ? getOnlineStatus(conversation) : false
          }
          divider={i < conversations.length - 1}
          key={conversation.entityName}
          loadChat={() => loadChat(conversation)}
        />
      ))}
    </div>
  );
}

export default ThreadList;
