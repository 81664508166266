import { gql, useQuery } from '@apollo/client';
import { Button, CircularProgress, Grid, LinearProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { useContext, useEffect, useRef } from 'react'
import { useState } from 'react';
import { Trans } from 'react-i18next';
import NeumorphicStyle from '../../../../constants/Neumorphic';
import { DEFAULT_PAGE_INDEX } from '../../../../constants/Urls';
import useExhibition from '../../../../engines/GeneralHooks/useExhibition';
import useInfiniteScoll from '../../../../engines/GeneralHooks/useInfiniteScoll';
import usePosts from '../../../../engines/GeneralHooks/usePosts'
import usePostsQL, { useBookmarkedPosts } from '../../../../engines/GraphQL/hooks/usePostsQL';
import postQueries from '../../../../engines/GraphQL/queries/postQueries';
import { useRawPushNotification } from '../../../../engines/Notification/NotificationEngine';
import PostMapper from '../../../../mapper/PostMapper';
import PushNotification from '../../../../models/PushNotification';
import MyStore from '../../../../utils/MyStore';
import PostCard from '../../../components/PostCard/Mobile';
import BookmarkSwitch from '../components/BookmarkSwitch';
import TagArea from '../components/TagArea';
import GlobalContext from '../context/GlobalContext';
import { useSelector } from 'react-redux'

const useStyles = makeStyles((theme) => ({
    root: {
        overflowY: "auto",
        overflowX: "hidden",
        paddingTop: "32px",
        width: "100%",
        height: "100%",
    },
    post: {
        minWidth: "520px",
        maxWidth: "665px",
        marginTop: "32px",
        marginLeft: "auto",
        marginRight: "auto",
    },
    keyvisual: {
        marginBottom: "24px",
        width: "85%",
        background: theme.palette.background.default,
        borderRadius: NeumorphicStyle.borderRadius,
        padding: "0px",
        boxShadow: `-7px 7px 15px ${theme.palette.primary.main}, 7px -7px 15px ${theme.palette.secondary.dark}, inset 1px -1px 2px ${theme.palette.primary.main}, inset -1px 1px 2px ${theme.palette.secondary.dark}`,
        height: 200,
        objectFit: "cover",
    },
    tagArea: {
        marginLeft: "auto",
        marginRight: "auto",
        paddingRight: 20
    },
    switch: {
        marginRight: 20,
        marginBottom: -80,
        marginTop: 4
    },
    circularProgress: {
        width: 50,
        height: 50,
    }
}));

function PostFeed({ ...rest }) {
    const classes = useStyles();
    const [exhibition] = useExhibition();
    const containerRef = useRef();
    const [showBookmarks, setShowBookmarks] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(DEFAULT_PAGE_INDEX);
    const [posts, loading, totalPages, refetchBookmarks, fetchNewData] = usePostsQL(currentIndex, 3, showBookmarks);
    const context = useContext(GlobalContext);
    const newPostsButton = useRef();
    const [incomingMessage] = useRawPushNotification();
    const [showReloadButton, setshowReloadButton] = useState(false);
    const [loadMore, setLoadMore] = useState(false)
    const {configuration: {state: {filters}}} = useSelector(state => state.configurationsReducer)

    useEffect(() => {
        setCurrentIndex(DEFAULT_PAGE_INDEX);
        fetchNewData();
    }, [context.tags])

    useEffect(() => {
        setCurrentIndex(DEFAULT_PAGE_INDEX);
        fetchNewData();
    }, [showBookmarks])

    const updateFeed = () => {
        if (showBookmarks) {
            setCurrentIndex(DEFAULT_PAGE_INDEX);
            fetchNewData();
        }
    }

    const handleReloadButtonClick = () => {
        setshowReloadButton(false);
        containerRef.current.scrollTo({
            top: 0,
            behavior: "smooth"
        })
        fetchNewData();
    }

    useEffect(() => {
        if (!incomingMessage || incomingMessage.getNotificationType() !== PushNotification.NOTIFICATION_TYPES.newPosts) {
            return
        }

        setshowReloadButton(true);
        setCurrentIndex(DEFAULT_PAGE_INDEX);
    }, [incomingMessage])

    useEffect(() => {
        if (!containerRef.current || loading) {
            return;
        }

        containerRef.current.addEventListener('scroll', handleScroll);
        
        return () => {
            containerRef.current.removeEventListener('scroll', handleScroll);
        }
    }, [containerRef.current,loading])

    const handleScroll = () => {
        if (containerRef.current.scrollTop === 0) {
            setLoadMore(true);
        }
    }

    useEffect(() => {
        if (!loadMore) {
            return;
        }
        handleReloadButtonClick();
        setLoadMore(false);
    }, [loadMore])

    return (
        <div ref={containerRef} className={classes.root} container>
            
                <div className={classes.switch}>
                    <BookmarkSwitch showBookmarks={showBookmarks} setShowBookmarks={setShowBookmarks} />
                </div>
          
            {exhibition && exhibition.getHeaderPicture() && (
                <img src={exhibition.getHeaderPicture().getUrl("large")} className={classes.keyvisual} />
            )}
            
                <div className={classes.tagArea}>
                    <TagArea center={true} exhibition={exhibition} />
                </div>
           
            {showReloadButton && <Button
                ref={newPostsButton}
                variant="contained"
                color="secondary"
                onClick={() => handleReloadButtonClick()}
                style={{ position: "fixed", zIndex: 3000, height: "50px", width: "100px", left: "46%" }}
            >
                <Trans>frontend.pages.feed.new_posts</Trans>
            </Button>}
            { posts && posts.length > 0 ? posts.map((post) => (
                <PostCard className={classes.post} totalPages={totalPages} currentIndex={currentIndex} setCurrentIndex={setCurrentIndex} postData={post} refetchBookmarks={() => updateFeed()} />
            )) : (<div />)}
            {loading && (<LinearProgress style={{ maxWidth: "80%", marginLeft: "auto", marginRight: "auto" }} />)}
        </div>
    )
}

export default PostFeed
