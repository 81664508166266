import {
  FETCHING_EXHIBITION_MESSAGES_REQUEST,
  FETCHING_EXHIBITION_MESSAGES_SUCCESS,
  FETCHING_EXHIBITION_MESSAGES_FAILURE,

  FETCHING_EXHIBITION_MESSAGE_REQUEST,
  FETCHING_EXHIBITION_MESSAGE_SUCCESS,
  FETCHING_EXHIBITION_MESSAGE_FAILURE,

  CREATE_EXHIBITION_MESSAGE_REQUEST,
  CREATE_EXHIBITION_MESSAGE_SUCCESS,
  CREATE_EXHIBITION_MESSAGE_FAILURE,

  UPDATE_EXHIBITION_MESSAGE_REQUEST,
  UPDATE_EXHIBITION_MESSAGE_SUCCESS,
  UPDATE_EXHIBITION_MESSAGE_FAILURE,

  DELETE_EXHIBITION_MESSAGE_REQUEST,
  DELETE_EXHIBITION_MESSAGE_SUCCESS,
  DELETE_EXHIBITION_MESSAGE_FAILURE,

  CLEAR_EXHIBITION_MESSAGE,
  SET_UNREAD_MESSAGES_COUNT_MESSAGES

} from './types';
import Urls, { DEFAULT_OPTIONAL_PARAMETERS } from '../../constants/Urls';
import { BEARER } from '../../utils/MyStore';
import ApplicationError from '../../utils/ApplicationError';
import ResponseService from '../../utils/ResponseService';
import MessageMapper from '../../mapper/MessageMapper';
import ErrorLogger from '../../utils/ErrorLogger';

export const fetchingMessagesRequest = () => ({
  type: FETCHING_EXHIBITION_MESSAGES_REQUEST
})

export const fetchingMessagesSuccess = (json) => ({
  type: FETCHING_EXHIBITION_MESSAGES_SUCCESS,
  payload: json
})
export const fetchingMessagesFailure = (error) => ({
  type: FETCHING_EXHIBITION_MESSAGES_FAILURE,
  payload: error
})

export const fetchingMessageRequest = () => ({
  type: FETCHING_EXHIBITION_MESSAGE_REQUEST
})

export const fetchingMessageSuccess = (message) => ({
  type: FETCHING_EXHIBITION_MESSAGE_SUCCESS,
  payload: message
})
export const fetchingMessageFailure = (error) => ({
  type: FETCHING_EXHIBITION_MESSAGE_FAILURE,
  payload: error
})

export const createMessageRequest = () => ({
  type: CREATE_EXHIBITION_MESSAGE_REQUEST
})

export const createMessageSuccess = (message) => ({
  type: CREATE_EXHIBITION_MESSAGE_SUCCESS,
  payload: message
})

export const createMessageFailure = (error) => ({
  type: CREATE_EXHIBITION_MESSAGE_FAILURE,
  payload: error
})

export const updateMessageRequest = () => ({
  type: UPDATE_EXHIBITION_MESSAGE_REQUEST
})

export const updateMessageSuccess = (message) => ({
  type: UPDATE_EXHIBITION_MESSAGE_SUCCESS,
  payload: message
})

export const updateMessageFailure = (error) => ({
  type: UPDATE_EXHIBITION_MESSAGE_FAILURE,
  payload: error
})

export const deleteMessageRequest = () => ({
  type: DELETE_EXHIBITION_MESSAGE_REQUEST
})

export const deleteMessageSuccess = () => ({
  type: DELETE_EXHIBITION_MESSAGE_SUCCESS
})

export const deleteMessageFailure = (error) => ({
  type: DELETE_EXHIBITION_MESSAGE_FAILURE,
  payload: error
})

export const clearMessages = () => ({
  type: CLEAR_EXHIBITION_MESSAGE
})

export const setUnreadMessagesCount = (val) => ({
  type: SET_UNREAD_MESSAGES_COUNT_MESSAGES,
  payload: val
})

export const fetchMessages = (exhibitionId, _opts = DEFAULT_OPTIONAL_PARAMETERS) => {
  return async dispatch => {
    dispatch(fetchingMessagesRequest());
    
    try {
      const route   = Urls.exhibitions.messages.all
      const bearer  = localStorage.getItem(BEARER)
      const headers = Urls.header.secure(bearer)
      const url     = Urls.build(route.url(exhibitionId, _opts.pageIndex, _opts.pageSize), _opts.filters)

      let response = await fetch(url, {
        method: route.method,
        headers: headers
      });

      let responseService = new ResponseService(MessageMapper)

      let action = await responseService.call(response, 
        (value) => fetchingMessagesSuccess(value),
        (error) => fetchingMessagesFailure(error))

      dispatch(action)
    } catch(error) {
      ErrorLogger.call(this, error)
      dispatch(fetchingMessagesFailure(ApplicationError.buildApplicationError(error)))
    }
  };
};

export const fetchMessage = (exhibitionId, id) => {
  return async dispatch => {
    dispatch(fetchingMessageRequest());

    try {
      const route   = Urls.exhibitions.messages.show;
      const bearer  = localStorage.getItem(BEARER);
      const headers = Urls.header.secure(bearer);
      const url     = route.url(exhibitionId, id)

      let response = await fetch(url, {
        method: route.method,
        headers: headers
      })

      let responseService = new ResponseService(MessageMapper)

      let action = await responseService.call(response, 
        (value) => fetchingMessageSuccess(value),
        (error) => fetchingMessageFailure(error))

      dispatch(action)

    } catch(error) {
      ErrorLogger.call(this, error)
      dispatch(fetchingMessageFailure(error))
    }
  }
}

export const createMessage = (message) => {
  return async dispatch => {
    dispatch(createMessageRequest());
    
    try {
      const route   = Urls.exhibitions.messages.create
      const bearer  = localStorage.getItem(BEARER)
      const headers = Urls.header.secure(bearer)
      const url     = route.url()

      let response = await fetch(url, {
        method: route.method,
        headers: headers,
        body: MessageMapper.toJsonString(message)
      });

      let responseService = new ResponseService(MessageMapper)

      let action = await responseService.call(response, 
        (value) => createMessageSuccess(value),
        (error) => createMessageFailure(error))

      dispatch(action)
    } catch(error) {
      ErrorLogger.call(this, error)
      dispatch(createMessageFailure(ApplicationError.buildApplicationError(error)))
    }
  };
};

export const updateMessage = (message) => {
  return async dispatch => {
    dispatch(updateMessageRequest());
    
    try {
      const route   = Urls.exhibitions.messages.update
      const bearer  = localStorage.getItem(BEARER)
      const headers = Urls.header.secure(bearer)
      const url     = route.url(message.getExhibitionId(), message.getId())

      let response = await fetch(url, {
        method: route.method,
        headers: headers,
        body: MessageMapper.toJsonString(message)
      });

      let responseService = new ResponseService(MessageMapper)

      let action = await responseService.call(response, 
        (value) => updateMessageSuccess(value),
        (error) => updateMessageFailure(error))

      dispatch(action)
    } catch(error) {
      ErrorLogger.call(this, error)
      dispatch(updateMessageFailure(ApplicationError.buildApplicationError(error)))
    }
  };
};

export const deleteMessage = (message) => {
  return async dispatch => {
    dispatch(deleteMessageRequest());
    
    try {
      const route   = Urls.exhibitions.messages.delete
      const bearer  = localStorage.getItem(BEARER)
      const headers = Urls.header.secure(bearer)
      const url     = route.url(message.getExhibitionId(), message.getId())

      let response = await fetch(url, {
        method: route.method,
        headers: headers
      });

      let responseService = new ResponseService(MessageMapper)

      let action = await responseService.call(response, 
        (value) => deleteMessageSuccess(value),
        (error) => deleteMessageFailure(error))

      dispatch(action)
    } catch(error) {
      ErrorLogger.call(this, error)
      dispatch(deleteMessageFailure(ApplicationError.buildApplicationError(error)))
    }
  };
};