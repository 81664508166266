import ApplicationModel from "./ApplicationModel"; 

export default class Video extends ApplicationModel {
  constructor(_opts = {}) {
    super(_opts);
    this.state = { ..._opts };
  }

  getName = () => this.state.name;
  setName = (name) => this.state.name = name; //deprecated

  getContent = () => this.state.content;
  getVideoType = () => this.state.videoType;
  setVideoType = (videoType) => this.state.videoType = videoType;
  getExhibitionId = () => this.state.exhibitionId;
  setExhibitionId = (id) => this.state.exhibitionId = id
  getAttachedToId = () => this.state.attachedToId;
  getAttachedToType = () => this.state.attachedToType;

  setAttachedToId = (id) => this.state.attachedToId = id;
  setAttachedToType = (type) => this.state.attachedToType = type;

  setContent = (content) => this.state.content = content
  getFormats = () => this.state.formats

  getUrl = () => this.state.url;

  static attributes = {
    ...super.attribtes,
    url: "url",
    formats: "formats",
    name: "name",
    content: "content",
    videoType: "mime",
    exhibitionId: "exhibition_id",
    attachedToId: "attached_to_id",
    attachedToType: "attached_to_type",
  };

  static attachedToTypes = {
    USER: "user",
    EXHIBITOR: "exhibitor",
    EXHIBITION: "exhibition"
  }

  static imageTypes = {
    PROFILE_IMAGE: "profile_picture",
    HEADER_IMAGE: "header_picture"
  }
}
