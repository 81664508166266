import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import useExhibition from './useExhibition';
import { fetchExhibitor, fetchExhibitors } from '../../redux/actions/ExhibitorsActions';
import { DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE } from '../../constants/Urls';
import useSearchEngine, { SEARCH_DATATYPE } from '../Search/SearchEngine';
import FilterParams from '../../utils/FilterParams';
import User from '../../models/User';
import { fetchExhibitorsUsers } from '../../redux/actions/ExhibitorsUsersActions';

function useExhibitors(searchText, pageIndex = DEFAULT_PAGE_INDEX, pageSize = DEFAULT_PAGE_SIZE, filters = []) {
    const dispatch = useDispatch();
    const [exhibitors, setExhibitors] = useState([]);
    const [exhibition] = useExhibition();
    const [loading, setLoading] = useState(false);
    const [totalPages, setTotalPages] = useState(1);

    const exhibitorsReducer = useSelector(state => state.exhibitorsReducer);

    const [resultSet, searching, finishedSearch, totalPagesSearch, totalItemsCount] = useSearchEngine(searchText, SEARCH_DATATYPE.exhibitor);

    useEffect(() => {
        if (!exhibition) {
            return;
        }

        dispatch(fetchExhibitors(exhibition.getId(), {
            pageIndex: pageIndex,
            pageSize: pageSize,
        }));
        setLoading(true);
    }, [exhibition, pageIndex, pageSize, filters.length])

    useEffect(() => {
        if (exhibitorsReducer.isFetching || !exhibitorsReducer.exhibitors || exhibitorsReducer.error.isError()) {
            return;
        }
        setLoading(false);
        if (pageIndex === DEFAULT_PAGE_INDEX) {
            setExhibitors(exhibitorsReducer.exhibitors);
            setTotalPages(exhibitorsReducer.totalPages);
        } else {
            setExhibitors(exhibitors.concat(exhibitorsReducer.exhibitors));
        }
    }, [exhibitorsReducer.isFetching])

    return [exhibitors, loading, totalPages, resultSet, searching, finishedSearch]
}

export function useExhibitor(id) {
    const dispatch = useDispatch();
    const [exhibition] = useExhibition()

    const [exhibitor, setExhibitor] = useState();
    const [loading, setLoading] = useState(false);

    const exhibitorsReducer = useSelector(state => state.exhibitorsReducer);
    const usersReducer = useSelector(state => state.usersReducer);

    useEffect(() => {
        if (!usersReducer.currentUser ||
            !exhibition ||
            !id
            //|| !usersReducer.currentUser.hasReadPermissions()
        ) {
            return;
        }


        dispatch(fetchExhibitor(exhibition.getId(), id))
        setLoading(true);

    }, [exhibition, id, usersReducer.currentUser])

    useEffect(() => {
        if (exhibitorsReducer.isFetching || !exhibitorsReducer.exhibitor) {
            return
        }

        setLoading(false)

        if (exhibitorsReducer.error.isError()) {
            return;
        }

        setExhibitor(exhibitorsReducer.exhibitor);
    }, [exhibitorsReducer.isFetching])



    return [exhibitor, loading];
}

export function useExhibitorsUsers(exhibitor) {
    const dispatch = useDispatch();
    const [exhibition] = useExhibition();

    const exhibitorsUsersReducer = useSelector(
        (state) => state.exhibitorsUsersReducer
    );

    const [exhibitorsUsers, setExhibitorsUsers] = useState();

    useEffect(() => {
        if (
            exhibitorsUsersReducer.isFetching ||
            !exhibitorsUsersReducer.exhibitorsUsers ||
            exhibitorsUsersReducer.error.isError()
        ) {
            return;
        }

        setExhibitorsUsers(exhibitorsUsersReducer.exhibitorsUsers);
    }, [exhibitorsUsersReducer.isFetching]);

    useEffect(() => {
        if (!exhibition || !exhibitor) {
            setExhibitorsUsers(undefined)
            return;
        }

        dispatch(
            fetchExhibitorsUsers(exhibition.getId(), exhibitor.getId(),{
                filters: [FilterParams.filterBy("contact_person",true)]
            })
        );
    }, [exhibitor, exhibition]);

    return [exhibitorsUsers]
}

export default useExhibitors
