import { Avatar, Button, Card, CardHeader, Divider, Grid, Typography, useTheme, Box, Popover } from '@material-ui/core'
import { FileCopy } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import React, { useContext, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useHistory } from "react-router-dom";
import FrontendRoutes from '../../../constants/FrontendRoutes';
import NeumorphicStyle from '../../../constants/Neumorphic';
import { useExhibitorQL } from '../../../engines/GraphQL/hooks/useExhibitorsQL';
import useBreakpoints from '../../../engines/Util/useBreakpoints';
import { salesmarkExhibitor } from '../../../redux/actions/ExhibitorsActions';
import CogoToast from '../../../utils/CogoToast';
import useTracking, { EVENT_TRACKING_TYPES } from '../../engines/tracking/useTracking';
import GlobalContext from '../../layouts/Dashboard/context/GlobalContext';
import StartChatModal from '../StartChatModal/StartChatModal';
import ExhibitorUsersCard from './ExhibitorUsersCard';

const useStyles = makeStyles(theme => ({
    root: {
        width: "100%",
        height: (props) => props.breakpoint <= 3 ? "calc(100% - 100px)" : "calc(100% - 100px)",
        overflowY: "auto",
        overflowX: "hidden",
        paddingLeft: 15
    },
    actions: {
        width: (props) => props.breakpoint <= 3 ? 170 : 220,
        marginTop: "12px",
        marginBottom: "20px",
        justifySelf: "center",
        marginLeft: "auto",
        marginRight: "auto",
        height: (props) => props.breakpoint <= 3 ? 35 : 45,
        background: theme.palette.secondary.light,
        boxShadow: NeumorphicStyle.boxShadowMedium(theme.palette.primary.main, theme.palette.secondary.dark),
        color: theme.palette.secondary.contrastText,
        "&:hover": {
            color: theme.palette.secondary.contrastText,
            background: theme.palette.secondary.light,
        }
    },
    avatarContainer: {
        position: "relative",
        cursor: 'pointer',
    },
    professionText: {
        maxWidth: 240
    },
    avatar: {
        width: (props) => props.breakpoint <= 3 ? 35 : 50,
        height: (props) => props.breakpoint <= 3 ? 35 : 50,
    },
    chatButtonContainer: {
        maxWidth: (props) => props.breakpoint <= 3 ? 150 : 220,
        marginLeft: (props) => props.breakpoint <= 3 ? 20 : 30
    },
    chatButton: {
        marginTop: (props) => props.breakpoint <= 3 ? "8px" : "12px",
        fontSize: (props) => props.breakpoint <= 3 ? 12 : 14,
        color: theme.palette.secondary.contrastText,
        background: `linear-gradient(to right, ${theme.palette.secondary.main}, ${theme.palette.primary.light})`,
        boxShadow: NeumorphicStyle.boxShadowMedium(theme.palette.primary.main, theme.palette.secondary.dark),
        // "&:hover": {
        //     backgroundColor: `linear-gradient(to right, ${theme.palette.secondary.main}, ${theme.palette.primary.light}`,
        // }
    },
    userContainer: {
        width: "100%"
    },
    header: {

    },
    contactText: {
        marginTop: (props) => props.breakpoint <= 3 ? 12 : 20,
        marginLeft: (props) => props.breakpoint <= 3 ? 10 : 15
    },
    contactButton: {
        marginTop: (props) => props.breakpoint <= 3 ? 12 : 20,
        marginBottom: "auto",
        marginLeft: "auto",
        marginRight: "auto",
        color: theme.palette.secondary.contrastText,
        height: (props) => props.breakpoint <= 3 ? 35 : 50,
        width: (props) => props.breakpoint <= 3 ? 120 : 200,
        background: `linear-gradient(to right, ${theme.palette.secondary.main}, ${theme.palette.primary.light})`,
        boxShadow: NeumorphicStyle.boxShadowMedium(theme.palette.primary.main, theme.palette.secondary.dark),
    },
    popover: {
        background: theme.palette.background.default,
        boxShadow: NeumorphicStyle.boxShadowSmall(
            theme.palette.primary.main,
            theme.palette.secondary.dark
        ),
        borderRadius: NeumorphicStyle.borderRadius,
        minHeight: 75,
        width: 250,
        padding: 16,
    }
}));

function ExhibitorsUsers({ exhibitorsUsers, handleChatStart, setChatIndex, chatBar }) {
    const theme = useTheme();
    const [breakpoint] = useBreakpoints();
    const classes = useStyles({ breakpoint })
    const usersReducer = useSelector(state => state.usersReducer);
    const history = useHistory()
    const context = useContext(GlobalContext);
    const dispatch = useDispatch();
    const [trackEvent] = useTracking();
    const [t] = useTranslation();
    
    const [startChatOpen, setStartChatOpen] = useState(false);
    const [chatUser, setChatUser] = useState();

    const handleStartChat = (user) => {
        if (chatBar) {
            handleChatStart(user);
            trackEvent(EVENT_TRACKING_TYPES.exhibitor.exhibitorStartChat, {
                exhibitor_user: user.getId(),
                name: user.getFullName(),
                exhibitor: context.currentExhibitor.getId()
            })
            setChatIndex(1);
        } else {
            setChatUser(user);
            trackEvent(EVENT_TRACKING_TYPES.exhibitor.exhibitorStartChat, {
                exhibitor_user: user.getId(),
                name: user.getFullName(),
                exhibitor: context.currentExhibitor.getId()
            })
            setStartChatOpen(true);
        }
    };

    const pushToChat = (id) => {

    }

    return (
        <Box className={classes.root} direction="column">
            <Grid item>
                <Typography variant="h3" style={{ marginTop: 30, marginBottom: 30, color: theme.palette.text.primary }}>
                    <Trans>frontend.contact_persons</Trans>
                </Typography>
                <Typography variant="h3" className={classes.contactText}><Trans>frontend.simply_ask</Trans></Typography>
                {context.currentExhibitor && context.currentExhibitor.getId() && (
                    <Box mx="auto" width="fit-content">
                        <Button
                            component={RouterLink}
                            className={classes.actions}
                            style={context.currentExhibitor.isSalesmarked() ? { backgroundColor: theme.palette.secondary.main, "&:hover": { backgroundColor: theme.palette.secondary.main } } : {}}
                            onClick={() => {
                                dispatch(salesmarkExhibitor(context.currentExhibitor));
                                context.currentExhibitor.setIsSalesmarked(!context.currentExhibitor.isSalesmarked());
                                trackEvent(EVENT_TRACKING_TYPES.exhibitor.salesmark, { exhibitor: context.currentExhibitor.getId() })
                                if (context.currentExhibitor.isSalesmarked()) {
                                    CogoToast.started(t("frontend.contact_forwarded"))
                                }
                            }}
                            variant="contained"
                            size="small"
                        >
                            <Trans>{context.currentExhibitor.isSalesmarked() ? "frontend.contact_made" : "frontend.make_contact"}</Trans>
                        </Button>
                    </Box>
                )}
            </Grid>
            {exhibitorsUsers && (
                <Grid
                    item
                    container
                    direction="column"
                    spacing={2}
                >
                    {exhibitorsUsers.map((user) => (
                        <ExhibitorUsersCard user={user} handleStartChat={handleStartChat}/>
                    ))}
                </Grid>
            )}
            <StartChatModal
                open={startChatOpen && usersReducer.currentUser.hasWritePermissions()}
                chatUser={chatUser}
                handleClose={() => {
                    setChatUser(undefined);
                    setStartChatOpen(false);
                }}
                pushToChat={(id) => pushToChat(id)}
                history={history}
            />
        </Box>
    )
}

export default ExhibitorsUsers
