import {
  FETCHING_EXHIBITION_TICKETS_REQUEST,
  FETCHING_EXHIBITION_TICKETS_SUCCESS,
  FETCHING_EXHIBITION_TICKETS_FAILURE,
  
  FETCHING_EXHIBITION_TICKET_REQUEST,
  FETCHING_EXHIBITION_TICKET_SUCCESS,
  FETCHING_EXHIBITION_TICKET_FAILURE,

  CREATE_EXHIBITION_TICKET_FAILURE,
  CREATE_EXHIBITION_TICKET_SUCCESS,
  CREATE_EXHIBITION_TICKET_REQUEST,

  UPDATE_EXHIBITION_TICKET_FAILURE,
  UPDATE_EXHIBITION_TICKET_SUCCESS,
  UPDATE_EXHIBITION_TICKET_REQUEST,

  DELETE_EXHIBITION_TICKET_FAILURE,
  DELETE_EXHIBITION_TICKET_SUCCESS,
  DELETE_EXHIBITION_TICKET_REQUEST,
} from '../actions/types';
import ResponseService from '../../utils/ResponseService';
import ApplicationError from '../../utils/ApplicationError';

const initialState = {
  tickets: [],
  ticket: undefined,
  error: ApplicationError.buildDefault(),
  isFetching: false,
  ...ResponseService.defaultPaginationMetaData()
};

const ticketsReducer = (state = initialState, action ) => {
  switch(action.type) {
    case FETCHING_EXHIBITION_TICKETS_REQUEST:
      return {...state, isFetching: true }
    case FETCHING_EXHIBITION_TICKETS_SUCCESS:
      return {...state, isFetching: false, 
        tickets: action.payload.objects, 
        error: ApplicationError.buildDefault(),
        ...action.payload.paginationData }

    case FETCHING_EXHIBITION_TICKETS_FAILURE:
      return {...state, isFetching: false, error: action.payload }
    
    case FETCHING_EXHIBITION_TICKET_REQUEST:
      return {...state, isFetching: true }
    case FETCHING_EXHIBITION_TICKET_SUCCESS: 
      return {...state, isFetching: false, ticket: action.payload, error: ApplicationError.buildDefault() }
    case FETCHING_EXHIBITION_TICKET_FAILURE:
      return {...state, isFetching: false, error: action.payload }  


    case  CREATE_EXHIBITION_TICKET_REQUEST:
      return {...state, isFetching: true }
    case  CREATE_EXHIBITION_TICKET_SUCCESS:
      return {...state, isFetching: false, ticket: action.payload, error: ApplicationError.buildDefault() }
    case  CREATE_EXHIBITION_TICKET_FAILURE:
      return {...state, isFetching: false, error: action.payload }

    case  UPDATE_EXHIBITION_TICKET_REQUEST:
      return {...state, isFetching: true }
    case  UPDATE_EXHIBITION_TICKET_SUCCESS:
      return {...state, isFetching: false, ticket: action.payload, error: ApplicationError.buildDefault() }
    case  UPDATE_EXHIBITION_TICKET_FAILURE:
      return {...state, isFetching: false, error: action.payload }

    case  DELETE_EXHIBITION_TICKET_REQUEST:
      return {...state, isFetching: true }
    case  DELETE_EXHIBITION_TICKET_SUCCESS:
      return {...state, isFetching: false, error: ApplicationError.buildDefault() }
    case  DELETE_EXHIBITION_TICKET_FAILURE:
      return {...state, isFetching: false, error: action.payload }


    default: 
      return state
  }
}

export default ticketsReducer;