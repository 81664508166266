import {
  SESSION_CREATE_FAILURE,
  SESSION_CREATE_REQUEST,
  SESSION_CREATE_SUCCESS,
  SESSION_DELETE_FAILURE,
  SESSION_DELETE_REQUEST,
  SESSION_DELETE_SUCCESS,
} from "../actions/types";
import ApplicationError from "../../utils/ApplicationError";

const initialState = {
  user: undefined,
  error: ApplicationError.buildDefault(),
  isFetching: false,
};

const sessionReducer = (state = initialState, action) => {
  switch (action.type) {
    // Login
    case SESSION_CREATE_REQUEST:
      return { ...state, isFetching: true };
    case SESSION_CREATE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        user: action.payload,
        error: ApplicationError.buildDefault(),
      };
    case SESSION_CREATE_FAILURE:
      return { ...state, isFetching: false, error: action.payload };

    // Logout
    case SESSION_DELETE_REQUEST:
      return { ...state, isFetching: true };
    case SESSION_DELETE_SUCCESS:
      return { ...state };
    case SESSION_DELETE_FAILURE:
      return { ...state, isFetching: false, error: action.payload };

    default:
      return state;
  }
};

export default sessionReducer;
