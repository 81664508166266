import { useMemo } from "react";
import useExhibition from "../GeneralHooks/useExhibition";

const getDiffDays = (startAt, endAt) => {
  return Math.ceil(Math.abs(endAt - startAt) / (1000 * 60 * 60 * 24));
};

const getZeroHourDate = (d, i) => {
  return new Date(d.getFullYear(), d.getMonth(), d.getDate() + i);
};

function useExhibitionDates(duration) {
  const [exhibition] = useExhibition();

  const dates = useMemo(() => {
    if (!exhibition) return [];

    const startAt = exhibition.getStartAt();
    const endAt = exhibition.getEndAt();

    const diffDays = duration || getDiffDays(startAt, endAt);

    const exhibitionDateList = Array.from({ length: diffDays }).map((_, i) =>
      getZeroHourDate(startAt, i)
    );

    return exhibitionDateList;
  }, [duration, exhibition]);

  return [dates];
}

export default useExhibitionDates;
