import Urls from "../../constants/Urls";
import ConfigurationMapper from "../../mapper/ConfigurationMapper";
import ApplicationError from "../../utils/ApplicationError";
import ErrorLogger from "../../utils/ErrorLogger";
import ResponseService from "../../utils/ResponseService";
import {
  FETCHING_CONFIGURATION_FAILURE,
  FETCHING_CONFIGURATION_REQUEST,
  FETCHING_CONFIGURATION_SUCCESS,
} from "./types";

export const fetchingConfigurationRequest = () => ({
  type: FETCHING_CONFIGURATION_REQUEST,
});

export const fetchingConfigurationSuccess = (configuration) => ({
  type: FETCHING_CONFIGURATION_SUCCESS,
  payload: configuration,
});

export const fetchingConfigurationFailure = (error) => ({
  type: FETCHING_CONFIGURATION_FAILURE,
  payload: error,
});

export const fetchConfiguration = () => {
  return async (dispatch) => {
    dispatch(fetchingConfigurationRequest());

    try {
      const route = Urls.configurations.show;
      const headers = Urls.header.insecure();
      const url = Urls.build(route.url());

      let response = await fetch(url, {
        method: route.method,
        headers: headers,
      });

      let responseService = new ResponseService(ConfigurationMapper);

      let action = await responseService.call(
        response,
        (value) => fetchingConfigurationSuccess(value),
        (error) => fetchingConfigurationFailure(error)
      );

      dispatch(action);
    } catch (error) {
      ErrorLogger.call("ConfigurationsActions", error);
      dispatch(
        fetchingConfigurationFailure(
          ApplicationError.buildApplicationError(error)
        )
      );
    }
  };
};
