import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import ReactCrop from "react-image-crop";

import CogoToast from "../../../utils/CogoToast";
import { Modal, Backdrop, Grid, Card, CardContent, Typography, Button } from "@material-ui/core";
import { useTranslation, Trans } from "react-i18next";
import { makeStyles } from "@material-ui/styles";
import Image from "../../../models/Image";
import UploadCropComponent from "../../layouts/Dashboard/components/UploadCropComponent";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "grid",
    alignItems: "flex-start",
    justifyContent: "center"
  },
  modal: {
    width: 600,
    height: "auto",
    padding: 30,
    maxHeight: window.innerHeight*0.5,
    maxWidth: "1000px",
    overflow:"auto",
    backgroundColor: theme.palette.background.default,
  },
  paper: {
    backgroundColor: theme.palette.background.default,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

function ImageUploadModal({
  open,
  prevImage,
  dispatchImage,
  imageType,
  attachedToType,
  attachedToId,
  setSavedImage,
  handleClose,
  exhibitionId,
  setLocalPicture,
}) {
  const { t } = useTranslation();
  const classes = useStyles();

  const [preview, setPreview] = useState();

  const [crop, setCrop] = useState(1);
  const [previewUrl, setPreviewUrl] = useState();
  const [imageBlob,setImageBlob] = useState();
  const [file, setFile] = useState();

  useEffect(() => {
    imageType === Image.imageTypes.HEADER_IMAGE
      ? setCrop(100 / 33)
      : setCrop(1 / 1);
  }, [open]);

  useEffect(() => {
    console.log(file);
    if (!file) {
      return;
    }
    setPreview(file.getFileEncodeBase64String());
    setPreviewUrl(file.getFileEncodeDataURL());
    setImageBlob(file.file)
  }, [file])

  const saveImage = () => {
    if (!preview) {
      CogoToast.error(t("frontend.no_picture"));
      return;
    }

    let image = prevImage ? prevImage : new Image();

    image.setContent(imageBlob);
    image.setAttachedToType(attachedToType);
    image.setAttachedToId(attachedToId);
    image.setImageType(imageType);
    image.setExhibitionId(exhibitionId);
    image.setName("test");

    setSavedImage(true);
    setLocalPicture(previewUrl);

    dispatchImage(image.getId(), image);
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.root}
      open={open}
      onClose={() => handleClose()}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <div className={classes.modal}>
        <UploadCropComponent setFile={setFile} aspectRation={crop} />
        <Button variant="contained" color="secondary" onClick={() => saveImage()} disabled={!file}><Trans>frontend.save</Trans></Button>
        <Button variant="contained" onClick={() => handleClose()}><Trans>frontend.cancel</Trans></Button>
      </div>
    </Modal>
  );
}

export default ImageUploadModal;
