import ApplicationModel from "./ApplicationModel";

export default class Exhibitor extends ApplicationModel {
  constructor(_opts = {}) {
    super(_opts);
    this.state = { ..._opts };
  }

  getId = () => this.state.id;
  getName = () => this.state.name;
  setName = (name) => (this.state.name = name);
  getUsersCount = () => this.state.usersCount;
  getExhibitionId = () => this.state.exhibitionId;

  getShortDescription = () => this.state.shortDescription;
  setShortDescription = (shortDescription) =>
    (this.state.shortDescription = shortDescription);

  getDescription = (language) => {
    if (
      this.state.localDescription &&
      this.state.localDescription.length > 0 &&
      language
    ) {
      let desc = this.state.localDescription.find(
        (element) => element.getLanguage() === language
      );
      if (desc) {
        return desc.getText();
      }
      desc = this.state.localDescription.find(
        (element) => element.getLanguage() === "en"
      );
      if (desc) {
        return desc.getText();
      }
    }
    return this.state.shortDescription;
  };
  setDescription = (description) => (this.state.shortDescription = description);

  getTitle = (language) => {
    if (
      this.state.localDescription &&
      this.state.localDescription.length > 0 &&
      language
    ) {
      let desc = this.state.localDescription.find(
        (element) => element.getLanguage() === language
      );
      if (desc) {
        return desc.getTitle();
      }
      desc = this.state.localDescription.find(
        (element) => element.getLanguage() === "en"
      );
      if (desc) {
        return desc.getTitle();
      }
    }
    return this.state.title;
  };

  getCompanyWebsite = () => this.state.companyWebsite;
  setCompanyWebsite = (companyWebsite) =>
    (this.state.companyWebsite = companyWebsite);

  getProfilePicture = () => this.state.profilePicture;
  getHeaderPicture = () => this.state.headerPicture;

  setProfilePicture = (picture) => (this.state.profilePicture = picture);
  setHeaderPicture = (picture) => (this.state.headerPicture = picture);

  getProfileThumb = () => this.state.profileThumb || this.state.profilePicture;
  setProfileThumb = (value) => (this.state.profileThumb = value);

  hasProfilePicture = () => this.state.profilePicture.getId() !== undefined;
  hasHeaderPicture = () => this.state.headerPicture.getId() !== undefined;

  isBookmarked = () => this.state.isBookmarked;
  setIsBookmarked = (value) => (this.state.isBookmarked = value);

  isSalesmarked = () => this.state.isSalesmarked;
  setIsSalesmarked = (val) => (this.state.isSalesmarked = val);

  getBookmark = () => this.state.bookmark;
  setBookmark = (bookmark) => (this.state.bookmark = bookmark);

  isMarked = () => this.state.isMarked;
  setIsMarked = (value) => (this.state.isMarked = value);

  getMark = () => this.state.mark;
  setMark = (mark) => (this.state.mark = mark);

  getSponsor = () => this.state.sponsor;
  setSponsor = (sponsor) => (this.state.sponsor = sponsor);

  getSponsorName = () => this.state.sponsorName;
  setSponsorName = (value) => (this.state.sponsorName = value);

  getDocuments = () => this.state.documents || [];
  getProfileVideo = (language) => {
    if (
      this.state.localDescription &&
      this.state.localDescription.length > 0 &&
      language
    ) {
      let desc = this.state.localDescription.find(
        (element) => element.getLanguage() === language
      );
      if (desc && desc.getVideo()) {
        return desc.getVideo();
      }
      desc = this.state.localDescription.find(
        (element) => element.getLanguage() === "en"
      );
      if (desc && desc.getVideo()) {
        return desc.getVideo();
      }
    }
    return this.state.profileVideo;
  };
  getBookingUrl = () => this.state.bookingUrl;
  getUsers = () => this.state.users;
  getSponsorName = () => this.state.sponsorName;
  getPictures = () => this.state.pictures || [];
  getTags = () => this.state.tags || [];

  getInstagramUrl = () => this.state.instagramUrl;
  setInstagramUrl = (value) => (this.state.instagramUrl = value)

  getLinkedInUrl = () => this.state.linkedInUrl;
  setLinkedInUrl = (value) => (this.state.linkedInUrl = value)

  getFacebookUrl = () => this.state.facebookUrl;
  setFacebookUrl = (value) => (this.state.facebookUrl = value)

  getTwitterUrl = () => this.state.twitterUrl;
  setTwitterUrl = (value) => (this.state.twitterUrl = value)

  getFCModule = () => this.state.FC_Module;
  setFCModule = (value) => (this.state.FC_Module = value)

  getFactSheet = (language) => {
    if (
      this.state.localDescription &&
      this.state.localDescription.length > 0 &&
      language
    ) {
      let desc = this.state.localDescription.find(
        (element) => element.getLanguage() === language
      );

      if (desc && desc.getFactSheet()) {
        return desc.getFactSheet();
      }
      desc = this.state.localDescription.find(
        (element) => element.getLanguage() === "en"
      );
      if (desc && desc.getFactSheet()) {
        return desc.getFactSheet();
      }
    }
    return this.state.factSheet;
  };

  getCategory = () => this.state.category;
  setCategory = (value) => (this.state.category = value)

  getYearOfEstablishment = () => this.state.yearOfEstablishment
  setYearOfEstablishment = (value) => (this.state.yearOfEstablishment = value)

  getBadge = () => this.state.badge || []

  getPriceInfo = () => this.state.priceInfo

  getExhibitorMaxPrice = () => this.state.exhibitorMaxPrice
  getExhibitorMinPrice = () => this.state.exhibitorMinPrice

  getCreatedAt = () => this.state.createdAt

  getHostProducts = () => this.state.hostProducts

  className = "Exhibitor";

  // Backend Attributes
  static attributes = {
    ...super.attributes,
    name: "name",
    usersCount: "users_count",
    isBookmarked: "is_bookmarked",
    bookmark: "bookmark",
    sponsor: "sponsor",
    companyWebsite: "company_website",
    shortDescription: "short_description",
    sponsorName: "sponsor_name",
    category: "category",
    profileThumb: "profile_thumb",
    documents: "documents",
    profileVideo: "profile_video",
    bookingUrl: "booking_url",
    users: "users",
    pictures: "pictures",
    marked_users: "marked_users",
    tags: "tags",
    instagramUrl: "instagram_url",
    linkedInUrl: "linkedIn_url",
    facebookUrl: "facebook_url",
    twitterUrl: "twitter_url",
    FC_Module: "FC_Module",
    yearOfEstablishment: "year_of_establishment",
    badge: "badge",
    priceInfo: "exhibition",
    exhibitorMaxPrice: "exhibitor_max_price",
    exhibitorMinPrice: "exhibitor_min_price",
    createdAt: "created_at",
    hostProducts: "host_products",
  };

  static orderByParams = {
    ...super.orderByParams,
    name: this.attributes.name,
  };

  static filterParams = {
    ...super.filterParams,
    name: this.attributes.name,
    search: "_q",
  };
}
