import {
  FETCHING_EXHIBITION_KEYNOTE_CONVERSATIONS_REQUEST,
  FETCHING_EXHIBITION_KEYNOTE_CONVERSATIONS_SUCCESS,
  FETCHING_EXHIBITION_KEYNOTE_CONVERSATIONS_FAILURE,

  FETCHING_EXHIBITION_KEYNOTE_CONVERSATION_REQUEST,
  FETCHING_EXHIBITION_KEYNOTE_CONVERSATION_SUCCESS,
  FETCHING_EXHIBITION_KEYNOTE_CONVERSATION_FAILURE,

  CREATE_EXHIBITION_KEYNOTE_CONVERSATION_REQUEST,
  CREATE_EXHIBITION_KEYNOTE_CONVERSATION_SUCCESS,
  CREATE_EXHIBITION_KEYNOTE_CONVERSATION_FAILURE,

  UPDATE_EXHIBITION_KEYNOTE_CONVERSATION_REQUEST,
  UPDATE_EXHIBITION_KEYNOTE_CONVERSATION_SUCCESS,
  UPDATE_EXHIBITION_KEYNOTE_CONVERSATION_FAILURE,

  DELETE_EXHIBITION_KEYNOTE_CONVERSATION_REQUEST,
  DELETE_EXHIBITION_KEYNOTE_CONVERSATION_SUCCESS,
  DELETE_EXHIBITION_KEYNOTE_CONVERSATION_FAILURE,

  CLEAR_EXHIBITION_KEYNOTE_CONVERSATION,
  SET_UNREAD_KEYNOTE_MESSAGES_COUNT

} from './types';
import Urls, { DEFAULT_OPTIONAL_PARAMETERS } from '../../constants/Urls';
import { BEARER } from '../../utils/MyStore';
import ApplicationError from '../../utils/ApplicationError';
import ResponseService from '../../utils/ResponseService';
import ConversationMapper from '../../mapper/ConversationMapper';
import ErrorLogger from '../../utils/ErrorLogger';

export const fetchingKeynoteConversationsRequest = () => ({
  type: FETCHING_EXHIBITION_KEYNOTE_CONVERSATIONS_REQUEST
})

export const fetchingKeynoteConversationsSuccess = (json) => ({
  type: FETCHING_EXHIBITION_KEYNOTE_CONVERSATIONS_SUCCESS,
  payload: json
})
export const fetchingKeynoteConversationsFailure = (error) => ({
  type: FETCHING_EXHIBITION_KEYNOTE_CONVERSATIONS_FAILURE,
  payload: error
})

export const fetchingKeynoteConversationRequest = () => ({
  type: FETCHING_EXHIBITION_KEYNOTE_CONVERSATION_REQUEST
})

export const fetchingKeynoteConversationSuccess = (conversation) => ({
  type: FETCHING_EXHIBITION_KEYNOTE_CONVERSATION_SUCCESS,
  payload: conversation
})
export const fetchingKeynoteConversationFailure = (error) => ({
  type: FETCHING_EXHIBITION_KEYNOTE_CONVERSATION_FAILURE,
  payload: error
})

export const createKeynoteConversationRequest = () => ({
  type: CREATE_EXHIBITION_KEYNOTE_CONVERSATION_REQUEST
})

export const createKeynoteConversationSuccess = (conversation) => ({
  type: CREATE_EXHIBITION_KEYNOTE_CONVERSATION_SUCCESS,
  payload: conversation
})

export const createKeynoteConversationFailure = (error) => ({
  type: CREATE_EXHIBITION_KEYNOTE_CONVERSATION_FAILURE,
  payload: error
})

export const updateKeynoteConversationRequest = () => ({
  type: UPDATE_EXHIBITION_KEYNOTE_CONVERSATION_REQUEST
})

export const updateKeynoteConversationSuccess = (conversation) => ({
  type: UPDATE_EXHIBITION_KEYNOTE_CONVERSATION_SUCCESS,
  payload: conversation
})

export const updateKeynoteConversationFailure = (error) => ({
  type: UPDATE_EXHIBITION_KEYNOTE_CONVERSATION_FAILURE,
  payload: error
})

export const deleteKeynoteConversationRequest = () => ({
  type: DELETE_EXHIBITION_KEYNOTE_CONVERSATION_REQUEST
})

export const deleteKeynoteConversationSuccess = () => ({
  type: DELETE_EXHIBITION_KEYNOTE_CONVERSATION_SUCCESS
})

export const deleteKeynoteConversationFailure = (error) => ({
  type: DELETE_EXHIBITION_KEYNOTE_CONVERSATION_FAILURE,
  payload: error
})

export const clearKeynoteConversation = () => ({
  type: CLEAR_EXHIBITION_KEYNOTE_CONVERSATION
})

export const setUnreadKeynoteConversationsMessagesCount = (val) => ({
  type: SET_UNREAD_KEYNOTE_MESSAGES_COUNT,
  payload: val
})

export const fetchKeynoteConversations = (exhibitionId, _opts = DEFAULT_OPTIONAL_PARAMETERS) => {
  return async dispatch => {
    dispatch(fetchingKeynoteConversationsRequest());
    
    try {
      const route   = Urls.exhibitions.conversations.all
      const bearer  = localStorage.getItem(BEARER)
      const headers = Urls.header.secure(bearer)
      const url     = Urls.build(route.url(exhibitionId, _opts.pageIndex, _opts.pageSize), _opts.filters)

      let response = await fetch(url, {
        method: route.method,
        headers: headers
      });

      let responseService = new ResponseService(ConversationMapper)

      let action = await responseService.call(response, 
        (value) => fetchingKeynoteConversationsSuccess(value),
        (error) => fetchingKeynoteConversationsFailure(error))

      dispatch(action)
    } catch(error) {
      ErrorLogger.call(this, error)
      dispatch(fetchingKeynoteConversationsFailure(ApplicationError.buildApplicationError(error)))
    }
  };
};

export const fetchKeynoteConversation = (exhibitionId, id) => {
  return async dispatch => {
    dispatch(fetchingKeynoteConversationRequest());

    try {
      const route   = Urls.exhibitions.conversations.show;
      const bearer  = localStorage.getItem(BEARER);
      const headers = Urls.header.secure(bearer);
      const url     = Urls.build(route.url(exhibitionId, id))

      let response = await fetch(url, {
        method: route.method,
        headers: headers
      })

      let responseService = new ResponseService(ConversationMapper)

      let action = await responseService.call(response, 
        (value) => fetchingKeynoteConversationSuccess(value),
        (error) => fetchingKeynoteConversationFailure(error))

      dispatch(action)

    } catch(error) {
      ErrorLogger.call(this, error)
      dispatch(fetchingKeynoteConversationFailure(error))
    }
  }
}

export const createKeynoteConversation = (conversation) => {
  return async dispatch => {
    dispatch(createKeynoteConversationRequest());
    
    try {
      const route   = Urls.exhibitions.conversations.create
      const bearer  = localStorage.getItem(BEARER)
      const headers = Urls.header.secure(bearer)
      const url     = Urls.build(route.url(conversation.getExhibitionId()))

      let response = await fetch(url, {
        method: route.method,
        headers: headers,
        body: ConversationMapper.toJsonString(conversation)
      });

      let responseService = new ResponseService(ConversationMapper)

      let action = await responseService.call(response, 
        (value) => createKeynoteConversationSuccess(value),
        (error) => createKeynoteConversationFailure(error))

      dispatch(action)
    } catch(error) {
      ErrorLogger.call(this, error)
      dispatch(createKeynoteConversationFailure(ApplicationError.buildApplicationError(error)))
    }
  };
};

export const updateKeynoteConversation = (conversation) => {
  return async dispatch => {
    dispatch(updateKeynoteConversationRequest());
    
    try {
      const route   = Urls.exhibitions.conversations.update
      const bearer  = localStorage.getItem(BEARER)
      const headers = Urls.header.secure(bearer)
      const url     = Urls.build(route.url(conversation.getExhibitionId(), conversation.getId()))

      let response = await fetch(url, {
        method: route.method,
        headers: headers,
        body: ConversationMapper.toJsonString(conversation)
      });

      let responseService = new ResponseService(ConversationMapper)

      let action = await responseService.call(response, 
        (value) => updateKeynoteConversationSuccess(value),
        (error) => updateKeynoteConversationFailure(error))

      dispatch(action)
    } catch(error) {
      ErrorLogger.call(this, error)
      dispatch(updateKeynoteConversationFailure(ApplicationError.buildApplicationError(error)))
    }
  };
};

export const deleteKeynoteConversation = (conversation) => {
  return async dispatch => {
    dispatch(deleteKeynoteConversationRequest());
    
    try {
      const route   = Urls.exhibitions.conversations.delete
      const bearer  = localStorage.getItem(BEARER)
      const headers = Urls.header.secure(bearer)
      const url     = route.url(conversation.getExhibitionId(), conversation.getId())

      let response = await fetch(url, {
        method: route.method,
        headers: headers
      });

      let responseService = new ResponseService(ConversationMapper)

      let action = await responseService.call(response, 
        (value) => deleteKeynoteConversationSuccess(value),
        (error) => deleteKeynoteConversationFailure(error))

      dispatch(action)
    } catch(error) {
      ErrorLogger.call(this, error)
      dispatch(deleteKeynoteConversationFailure(ApplicationError.buildApplicationError(error)))
    }
  };
};