import { createTheme } from '@material-ui/core/styles'
import palette, {customPalette} from './palette';
import typography from './typography';
import overrides from './overrides';

export const genereateTheme = (
  colorPrimary,
  colorSecondary, 
  additionalColor, 
  fontColor,
  accentColor,
  primaryContrast,
  secondaryContrast,
  background,
  highlightButton
) => {
  let p = customPalette(
    colorPrimary,
    colorSecondary, 
    additionalColor, 
    fontColor,
    accentColor,
    primaryContrast,
    secondaryContrast,
    background,
    highlightButton
  );
  const customOverrides = { ...overrides, MuiButton: {
    ...overrides.MuiButton,
    containedSecondary: {
      '&:hover': {
        backgroundColor: p.secondary.main,
      }
    },
  }}
  let base = {
    palette: p,
    typography,
    overrides: customOverrides,
  }
  return createTheme(base);
}

const baseTheme = {
  palette,
  typography,
  overrides
};

export const theme = createTheme(baseTheme);
