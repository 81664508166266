import React, { useState, useRef, useEffect } from "react";
import MessageBox from "./LbChatMessageBox";
import {
  CircularProgress,
  Divider,
  LinearProgress,
  ListItem,
} from "@material-ui/core";

function LbMobileThreadDetails({ loading, messages, loadMore }) {
  const messagesRef = useRef();
  const [startUp, setStartUp] = useState(true);
  const [autoScroll, setAutoScroll] = useState(true);
  function scrollMessagesToBottom() {
    if (messagesRef.current) {
      // eslint-disable-next-line no-underscore-dangle
      (messagesRef.current as any).scrollTop = (messagesRef?.current as any)?.scrollHeight;
      setStartUp(false);
    }
  }

  useEffect(() => {
    if (!messagesRef.current) {
      return;
    }

    (messagesRef?.current as any)?.addEventListener("scroll", handleScroll);

    return () => {
      (messagesRef?.current as any)?.removeEventListener('scroll', handleScroll)
  }
  }, [messagesRef.current]);

  const handleScroll = () => {
    var scrollBottom =
      (messagesRef?.current as any)?.scrollTop + (messagesRef?.current as any)?.clientHeight;
    if (scrollBottom > (messagesRef?.current as any)?.scrollHeight - 25 && !loading) {
      setAutoScroll(true);
      return;
    }

    if (startUp || loading || (messagesRef?.current as any)?.scrollTop > 0) {
      return;
    }
    setAutoScroll(false);
    loadMore();
  };

  useEffect(() => {
    if (!autoScroll) {
      return;
    }
    scrollMessagesToBottom();
    // eslint-disable-next-line
  }, [messages]);

  return (
    <div
      ref={messagesRef}
      style={{
        overflowY: "scroll",
        padding: "12px",
        height: "calc(100% - 75px)"
      }}
    >
      {loading && <LinearProgress />}
      {messages.map((message) => (
        <MessageBox
          key={message.id}
          message={message}
          messagesRef={messagesRef}
        />
      ))}
      <div style={{ height: 1, width: "100%", backgroundColor: "grey"}} />
    </div>
  );
}

export default LbMobileThreadDetails;
