import {
  FETCHING_EXHIBITION_VISITORS_REQUEST,
  FETCHING_EXHIBITION_VISITORS_SUCCESS,
  FETCHING_EXHIBITION_VISITORS_FAILURE,
  
  FETCHING_EXHIBITION_VISITOR_REQUEST,
  FETCHING_EXHIBITION_VISITOR_SUCCESS,
  FETCHING_EXHIBITION_VISITOR_FAILURE,
  UPDATE_EXHIBITION_VISITOR_REQUEST,
  UPDATE_EXHIBITION_VISITOR_SUCCESS,
  UPDATE_EXHIBITION_VISITOR_FAILURE,

  CLEAR_VISITOR
} from '../actions/types';
import ResponseService from '../../utils/ResponseService';
import ApplicationError from '../../utils/ApplicationError';

const initialState = {
  visitors: [],
  visitor: undefined,
  error: ApplicationError.buildDefault(),
  isFetching: false,
  ...ResponseService.defaultPaginationMetaData()
};

const visitorsReducer = (state = initialState, action ) => {
  switch(action.type) {

    case CLEAR_VISITOR:
      return { ...initialState }

    case FETCHING_EXHIBITION_VISITORS_REQUEST:
      return {...state, isFetching: true, visitors: [] }
    case FETCHING_EXHIBITION_VISITORS_SUCCESS:
      return {...state, isFetching: false, 
        visitors: action.payload.objects, 
        error: ApplicationError.buildDefault(),
        ...action.payload.paginationData }
        
    case FETCHING_EXHIBITION_VISITORS_FAILURE:
      return {...state, isFetching: false, error: action.payload }
    
    case FETCHING_EXHIBITION_VISITOR_REQUEST:
      return {...state, isFetching: true }
    case FETCHING_EXHIBITION_VISITOR_SUCCESS: 
      return {...state, isFetching: false, visitor: action.payload }
    case FETCHING_EXHIBITION_VISITOR_FAILURE:
      return {...state, isFetching: false, error: action.payload }  
    case UPDATE_EXHIBITION_VISITOR_REQUEST:
      return {...state, isFetching: true }
    case UPDATE_EXHIBITION_VISITOR_SUCCESS: 
      return {...state, isFetching: false, visitor: action.payload }
    case UPDATE_EXHIBITION_VISITOR_FAILURE:
      return {...state, isFetching: false, error: action.payload }  
    default: 
      return state
  }
}

export default visitorsReducer;