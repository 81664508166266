import React, { useEffect, useState } from 'react'
import { useFetchLazy } from "../../GeneralHooks/useFetch";
import Urls from '../../../constants/Urls';
import MyStore, { BEARER } from '../../../utils/MyStore';

function useVideoToken(roomName: string) {
    const [token, setToken] = useState<any>()
    const url = Urls.twilio.video_token.url();
    const { response, isLoading, error, fetchData } = useFetchLazy(url, {
        headers: Urls.header.secure(localStorage.getItem(BEARER)) as HeadersInit,
        method: Urls.twilio.video_token.method,
        body: `{ room_name: ${roomName}, }`
    });

    useEffect(() => {
        if (!roomName || !isLoading) {
            return;
        }

        fetchData();
    }, [roomName])
    
    useEffect(() => {
        if (!response || !roomName) {
            return;
        }

        const { token } = response;
        setToken(token);

    }, [response])

    return [token, isLoading, error];
}

export default useVideoToken
