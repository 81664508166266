import { colors } from "@material-ui/core";

const white = "#FFF";
const violett1 = "#000000";
const violett2 = "#441256";
const violett3 = "#E4007D";

export const customPalette = (colorPrimary,colorSecondary, additionalColor, fontColor, accentColor,primaryContrast,secondaryContrast,background,highlightButton) => {
  return {
    primary: {
      contrastText: primaryContrast ? primaryContrast:white,
      dark: additionalColor,
      main: colorPrimary,
      light: accentColor ? accentColor:colorSecondary,
    },
    secondary: {
      contrastText: secondaryContrast ? secondaryContrast:white,
      dark: additionalColor,
      main: colorSecondary,
      light: highlightButton ? highlightButton:colorSecondary,
    },
    error: {
      contrastText: white,
      dark: colors.red[900],
      main: colors.red[600],
      light: colors.red[400]
    },
    text: {
      primary: fontColor,
      secondary: colors.blueGrey[600],
      link: colorSecondary
    },
    link: colorPrimary,
    icon: colorPrimary,
    background: {
      default: background || "#F4F6F8",
      paper: background || "#F4F6F8",
    },
    divider: colors.grey[200]
  };
}

export default {
  primary: {
    contrastText: white,
    dark: violett2,
    main: violett1,
    light: violett3,
  },
  secondary: {
    contrastText: white,
    dark: violett2,
    main: violett3,
    light: violett3
  },
  error: {
    contrastText: white,
    dark: colors.red[900],
    main: colors.red[600],
    light: colors.red[400]
  },
  text: {
    primary: violett1,
    secondary: colors.blueGrey[600],
    link: violett3
  },
  link: violett1,
  icon: violett1,
  background: {
    default: "#F4F6F8",
    paper: white
  },
  divider: colors.grey[200]
};
