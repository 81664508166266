import BaseMapper from "./BaseMapper";
import UserMapper from "./UserMapper";
import Comment from "../models/Comment";
import MyStore from "../utils/MyStore";
import { compareIds } from "../utils";
import CommentsLikeMapper from "./CommentsLikeMapper";

const attributes = Comment.attributes

export default class CommentMapper {
  static build = json => {
    if(!json) { return }

    const commentLike = json.comment_likes ? json.comment_likes.find((like) => {
      return compareIds(like.user.id,MyStore.getCurrentUser().getId()) || compareIds(like.user,MyStore.getCurrentUser().getId());
    }):undefined;

    return new Comment({
      ...BaseMapper.attributes(json),
      text: json[attributes.text],
      userId: json.user && json.user.id,
      postId: json.post && json.post.id,
      likesCount: json.comment_likes ? json.comment_likes.length:0,
      like: commentLike ? CommentsLikeMapper.build(commentLike):undefined,
      exhibitionId: json.exhibition ? json.exhibition.id:undefined,
      user: UserMapper.build(json[attributes.user]),
    });
  };

  static buildFromArray = (jsonArray = []) => {
    let entities = jsonArray.map(entry => {
      return this.build(entry);
    });

    return entities.filter(entity => entity !== undefined)
  };

  static toJsonString(post) {
    return JSON.stringify({
      [Comment.attributes.text]: post.getText()
    });
  }
}
