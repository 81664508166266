import { Box, Button, LinearProgress, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import NeumorphicStyle from "../../../constants/Neumorphic";
import { useSelector } from 'react-redux'
import { DEFAULT_PAGE_INDEX } from "../../../constants/Urls";
import useExhibition from "../../../engines/GeneralHooks/useExhibition";
import usePostsQL from "../../../engines/GraphQL/hooks/usePostsQL";
import { useRawPushNotification } from "../../../engines/Notification/NotificationEngine";
import PushNotification from "../../../models/PushNotification";
import PostCard from "../../components/PostCard";
import BookmarkSwitch from "../Dashboard/components/BookmarkSwitch";
import TagArea from "../Dashboard/components/TagArea";
import GlobalContext from "../Dashboard/context/GlobalContext";
import useBreakpoints from "../../../engines/Util/useBreakpoints";
import LbMobilePostCard from "./components/MobilePostCard";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    overflowY: "auto",
    overflowX: "hidden",
    paddingTop: "5rem",
    paddingBottom: "8rem",
    width: "100%",
    height: "100%",
  },
  post: {
    marginLeft: "auto",
    marginRight: "auto",
  },
  keyvisual: {
    marginBottom: "24px",
    width: "85%",
    background: theme.palette.background.default,
    borderRadius: NeumorphicStyle.borderRadius,
    padding: "0px",
    boxShadow: `-7px 7px 15px ${theme.palette.primary.main}, 7px -7px 15px ${theme.palette.secondary.dark}, inset 1px -1px 2px ${theme.palette.primary.main}, inset -1px 1px 2px ${theme.palette.secondary.dark}`,
    height: 200,
    objectFit: "cover",
  },
  tagArea: {
    marginRight: "auto",
    paddingRight: 20,
  },
  switch: {
    marginRight: 20,
    marginBottom: -80,
    marginTop: 4,
  },
  circularProgress: {
    width: 50,
    height: 50,
  },
}));

function LbPostContainer({ ...rest }) {
  const { t } = useTranslation();
  const [breakpoint] = useBreakpoints();
  const classes = useStyles({breakpoint});
  const [exhibition] = useExhibition();
  const containerRef = useRef();
  const [showBookmarks, setShowBookmarks] = useState<boolean>(false);
  const [currentIndex, setCurrentIndex] = useState(DEFAULT_PAGE_INDEX);
  const [posts, loading, totalPages, refetchBookmarks, fetchNewData] =
    usePostsQL(currentIndex, 3, showBookmarks);
  const context = useContext(GlobalContext);
  const newPostsButton = useRef();
  const [incomingMessage] = useRawPushNotification();
  const [showReloadButton, setshowReloadButton] = useState(false);
  const [loadMore, setLoadMore] = useState(false);
  const {configuration: {state: {filters}}} = useSelector(state => (state as any).configurationsReducer)

  useEffect(() => {
    setCurrentIndex(DEFAULT_PAGE_INDEX);
    fetchNewData();
  }, [context.tags, showBookmarks, context.minPrice, context.maxPrice]);

  const updateFeed = () => {
    if (showBookmarks) {
      setCurrentIndex(DEFAULT_PAGE_INDEX);
      fetchNewData();
    }
  };

  const handleReloadButtonClick = () => {
    if (!window) {
      return;
    }
    setshowReloadButton(false);
    (window as any).scrollTo({
      top: 0,
      behavior: "smooth",
    });
    fetchNewData();
  };

  useEffect(() => {
    if (
      !incomingMessage ||
      (incomingMessage as any).getNotificationType() !==
        PushNotification.NOTIFICATION_TYPES.newPosts
    ) {
      return;
    }

    setshowReloadButton(true);
    setCurrentIndex(DEFAULT_PAGE_INDEX);
  }, [incomingMessage]);

  useEffect(() => {
    if (!containerRef?.current || loading) {
      return;
    }

    (containerRef.current as any).addEventListener("scroll", handleScroll);

    return () => {
      (containerRef.current as any).removeEventListener("scroll", handleScroll);
    };
  }, [containerRef.current, loading]);

  const handleScroll = () => {
    if ((containerRef?.current as any)?.scrollTop === 0) {
      setLoadMore(true);
    }
  };

  useEffect(() => {
    if (!loadMore) {
      return;
    }
    handleReloadButtonClick();
    setLoadMore(false);
  }, [loadMore]);

  return (
    <div ref={containerRef} className={classes.root}>
      {showReloadButton && (
        <Button
          ref={newPostsButton}
          variant="contained"
          color="secondary"
          onClick={() => handleReloadButtonClick()}
          style={{
            position: "fixed",
            zIndex: 2,
            height: "50px",
            width: "100px",
            left: "calc(50vw - 50px)",
          }}
        >
          <Trans>frontend.pages.feed.new_posts</Trans>
        </Button>
      )}
      {posts && posts.length > 0 ? (
        posts.map((post) => (
          <LbMobilePostCard
            className={classes.post}
            totalPages={totalPages}
            currentIndex={currentIndex}
            setCurrentIndex={setCurrentIndex}
            postData={post}
            refetchBookmarks={() => updateFeed()}
          />
        ))
      ) : (
        <div />
      )}
      {loading && (
        <LinearProgress
          style={{ maxWidth: "80%", marginLeft: "auto", marginRight: "auto", marginTop: 12 }}
        />
      )}
    </div>
  );
}

export default LbPostContainer;