import React, { useEffect, useState } from "react"
import { Grid, Avatar, makeStyles, ButtonBase, Typography } from "@material-ui/core"
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Stories from 'react-insta-stories';

const useStyles = makeStyles((theme) => ({
    root: {
        height: theme.spacing(36),
        marginLeft: 16,
        marginTop: 16,
        flexGrow: 1,
    },
    storyImg: {
        height: theme.spacing(30),
        width: theme.spacing(20),
        borderRadius: 16,
    },
    avatar: {
        width: theme.spacing(20),
        height: theme.spacing(30),
        borderRadius: 16,
    },
    text: {
        maxWidth: theme.spacing(20),
    },
    storiesOverlay: {
        position: "absolute",
        width: "100%",
        height: "100%",
        backgroundColor: "black",
        marginLeft: -24,
        marginTop: -24,
        opacity: "30%",
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}))

export default function StoryBar() {
    const [showStories, setShowStories] = useState([]);
    const [storyIndex,setStoryIndex] = useState(0);
    const classes = useStyles();

    const show = showStories.length !== 0;

    const handleOpen = (str, index) => {
        setStoryIndex(index)
        setShowStories(str);
    };

    const handleClose = () => {
        setShowStories([]);
    };

    return (
        <div>
            <Grid container className={classes.root} direction="row" spacing={2}>
                {userStories.map((userStory, index) => {
                    return (<Grid item key={userStory.heading}>
                        <ButtonBase className={classes.storyImg} onClick={() => handleOpen(userStory.stories, index)}>
                            <Avatar variant="rounded" src={userStory.profileImage} className={classes.avatar}>
                            </Avatar>
                        </ButtonBase>
                        <Typography className={classes.text}>{userStory.heading}</Typography>
                    </Grid>)
                })}
            </Grid>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={show}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={show}>
                    <Stories
                        className={classes.paper}
                        stories={showStories}
                        defaultInterval={1500}
                        width={400}
                        height={600}
                        onAllStoriesEnd= {() => {
                                handleClose();                            
                        }}
                    />
                </Fade>
            </Modal>
        </div>
    )
}

const userStories = [
    {
        heading: 'Mohit Karekar',
        profileImage: 'https://picsum.photos/1000/1000',
        stories: [
            {
                url: 'https://picsum.photos/1080/1920',
                seeMore: ({ close }) => (
                    <div style={{ width: '100%', height: '100%' }}>Hello</div>
                ),
                header: {
                    heading: 'Mohit Karekar',
                    subheading: 'Posted 5h ago',
                    profileImage: 'https://picsum.photos/1000/1000'
                }
            },
        ]
    },
    {
        heading: 'mohitk05',
        profileImage:
            'https://avatars0.githubusercontent.com/u/24852829?s=400&v=4',
        stories: [
            {
                url:
                    'https://fsa.zobj.net/crop.php?r=dyJ08vhfPsUL3UkJ2aFaLo1LK5lhjA_5o6qEmWe7CW6P4bdk5Se2tYqxc8M3tcgYCwKp0IAyf0cmw9yCmOviFYb5JteeZgYClrug_bvSGgQxKGEUjH9H3s7PS9fQa3rpK3DN3nx-qA-mf6XN',
                header: {
                    heading: 'Mohit Karekar',
                    subheading: 'Posted 32m ago',
                    profileImage: 'https://picsum.photos/1080/1920'
                }
            },
        ]
    },
    {
        heading: 'insta-stories',
        profileImage:
            'https://rockitmasterclass.com/wp-content/uploads/2019/03/IMG_1866.jpg',
        stories: [
            {
                url:
                    'https://media.idownloadblog.com/wp-content/uploads/2016/04/iPhone-wallpaper-abstract-portrait-stars-macinmac.jpg',
                header: {
                    heading: 'mohitk05/react-insta-stories',
                    subheading: 'Posted 32m ago',
                    profileImage:
                        'https://avatars0.githubusercontent.com/u/24852829?s=400&v=4'
                }
            },
            {
                url: 'https://storage.googleapis.com/coverr-main/mp4/Footboys.mp4',
                type: 'video',
                duration: 1000,
                header: {
                    heading: 'mohitk05/react-insta-stories',
                    subheading: 'Posted 32m ago',
                    profileImage:
                        'https://avatars0.githubusercontent.com/u/24852829?s=400&v=4'
                }
            },
            {
                url:
                    'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerJoyrides.mp4',
                type: 'video',
                seeMore: ({ close }) => (
                    <div style={{ width: '100%', height: '100%' }}>Hello</div>
                ),
                header: {
                    heading: 'mohitk05/react-insta-stories',
                    subheading: 'Posted 32m ago',
                    profileImage:
                        'https://avatars0.githubusercontent.com/u/24852829?s=400&v=4'
                }
            },
            {
                url:
                    'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerBlazes.mp4',
                type: 'video',
                header: {
                    heading: 'mohitk05/react-insta-stories',
                    subheading: 'Posted 32m ago',
                    profileImage:
                        'https://avatars0.githubusercontent.com/u/24852829?s=400&v=4'
                }
            },
        ]
    }
]