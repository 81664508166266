import ApplicationModel from "./ApplicationModel";

export const INTERNAL_IDS = {
  AUTHENTICATED: 'Authenticated',
  VISITOR: 'Visitor',
  CUSTOMER: 'Customer',
  EXHIBITOR: "Exhibitor"
}

export default class LocalText extends ApplicationModel {

  constructor(_opts = {}){
    super(_opts)
    this.state = { ..._opts }
  }

  getLanguage = () => this.state.language
  getText = () => this.state.text
  getVideo = () => this.state.video
  getStreamUrl = () => this.state.streamUrl
  getTitle = () => this.state.title
  getConversation = () => this.state.conversation
  getActiveKeynote = () => this.state.activeKeynote
  getFactSheet = () => this.state.factSheet
  

  // Backend Attributes
  static attributes = {
    ...super.attributes,
    language: "language",
    text: "text",
    video: "video",
    streamUrl: "stream_url",
    title: "title",
    conversation: "conversation",
    activeKeynote: "active_keynote",
    factSheet: "fact_sheet",
  }

  static orderByParams = {
    ...super.orderByParams
  }

  static filterParams = {
    ...super.filterParams,
  }
}