import React, { useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/styles";

import { Button, Card, Grid, Typography, Avatar, Chip, CircularProgress, Link } from "@material-ui/core";
import MailIcon from "@material-ui/icons/Mail";
import PhoneIcon from "@material-ui/icons/Phone";
import { Trans, useTranslation } from "react-i18next";
import FrontendRoutes from "../../../../../constants/FrontendRoutes";

import CogoToast from "../../../../../utils/CogoToast";
import { useSelector, useDispatch } from "react-redux";
import {
  bookmarkVisitor
} from "../../../../../redux/actions/VisitorsActions";
import Bookmark from "../../../../../models/Bookmark";
import NeumorphicStyle from "../../../../../constants/Neumorphic";
import ShareModal from "../../../../components/ShareModal/ShareModal";
import StartChatModal from "../../../../components/StartChatModal/StartChatModal";
import { Star, StarBorder, ChatBubbleOutlineRounded, Share, VideoCall } from "@material-ui/icons";
import GlobalContext from "../../context/GlobalContext";
import { MODAL_TYPE } from "../GlobalModal";
import MyStore from "../../../../../utils/MyStore";
import VideoModal from "../../../../views/ChatView/ThreadDetails/VideoModal";
import { compareEntitiesById } from "../../../../../utils";

const backgroundUrl = (url = "") =>
  `linear-gradient(to bottom, rgba(255, 255, 255, 0.2), rgba(171, 6, 112, 0.4)), url(${url})`;

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 20,
    height: 320,
    width: 300,
    borderRadius: 8,
    overflow: "hidden",
    boxShadow: NeumorphicStyle.boxShadowMedium(theme.palette.primary.main, theme.palette.secondary.dark)
  },
  content: {
    width: "100%",
    height: "100%",
    overflowY: "auto",
    overflowX: "hidden",
    position: "relative"
  },
  userContainer: {
    width: "100%",
    position: "relative"
  },
  tagContainer: {
    marginTop: 15,
  },
  tag: {
    borderRadius: 4,
    height: 25,
    fontSize: 14,
  },
  image: {
    height: 56,
    width: 56,
    marginTop: 2,
    cursor: "pointer",
  },
  nameContainer: {
    maxWidth: 190,
    marginLeft: 12
  },
  icon: {
    marginRight: 8,
  },
  name: {
  },
  contactContainer: {
    marginTop: 12,
  },
  profession: {
    lineHeight: "18px",
  },
  companyName: {
    lineHeight: "18px",
  },
  aboutContainer: {
    marginTop: 12,
    overflow: "hidden",
    maxHeight: 80,
  },
  aboutText: {
    lineHeight: "17px",
  },
  subtitle: {
  },
  socialButton: {
  },
  actionArea: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0
  },
  starIcon: {
    color: theme.palette.secondary.light
  }
}));

/**
 * Anmerkung: Visitor entspricht dem Model User
 * @param {visitor} param0
 */

function VisitorCard({ visitor, handleChatStart, handleShare, refetchBookmarks }) {
  const dispatch = useDispatch();
  const classes = useStyles();

  const [bookmarked, setBookmarked] = useState(false);
  const [unbookmarked, setUnbookmarked] = useState(false);
  const { t } = useTranslation();
  const [shareOpen, setShareOpen] = useState(false)
  const context = useContext(GlobalContext);

  const conversations = useSelector(state => state.conversationsReducer);
  const visitorsReducer = useSelector(state => state.visitorsReducer);
  const [chatOpen, setChatOpen] = useState(false);
  const [bookmarkLoading, setBookmarkLoading] = useState(false);
  const [videoOpen, setVideoOpen] = useState(false);

  useEffect(() => {
    if (!unbookmarked) {
      return;
    }

    dispatch(bookmarkVisitor(visitor));
    setBookmarkLoading(true);
  }, [unbookmarked]);

  useEffect(() => {
    if (!bookmarked) {
      return;
    }

    dispatch(bookmarkVisitor(visitor));
    setBookmarkLoading(true);
  }, [bookmarked]);

  // Bookmark
  useEffect(() => {
    if (
      (!bookmarked && !unbookmarked) ||
      !bookmarkLoading ||
      visitorsReducer.isFetching ||
      visitorsReducer.error.isError()
    ) {
      return;
    }

    if (bookmarked) {
      visitor.setIsBookmarked(true);
      CogoToast.info(t("frontend.added_bookmark"));
    }

    if (unbookmarked) {
      visitor.setIsBookmarked(false);
      CogoToast.info(t("frontend.removed_bookmark"));
    }

    refetchBookmarks();
    setBookmarkLoading(false);
    setBookmarked(false);
    setUnbookmarked(false);
  }, [visitorsReducer.isFetching]);

  useEffect(() => {
    if (conversations.isFetching || !conversations.conversation) {
      return
    }
  }, [conversations.isFetching])

  const handleBookmark = () => {
    visitor.isBookmarked() ? setUnbookmarked(true) : setBookmarked(true)
  }

  return !visitor ? <div /> : (
    <Grid item key={visitor.getExhibitorId()} className={classes.root}>
      <div className={classes.content}>
        <Grid item container direction="row" className={classes.userContainer}>
          <div style={{
            position: "absolute",
            width: "10px",
            height: "10px",
            backgroundColor: visitor.isOnline() ? "#44B700" : "#853434",
            zIndex: "100",
            borderRadius: "50%",
            bottom: "5px",
            left: "45px"
          }}
          />
          <Avatar
            className={classes.image}
            src={visitor.getProfilePicture() ? visitor.getProfilePicture().getUrl("small") : ""}
            onClick={() => context.openGlobalModal(MODAL_TYPE.USER_MODAL, visitor.getId())}
          />
          <Grid container item direction="column" className={classes.nameContainer}>
            <Link
              variant="h4"
              noWrap
              color="textPrimary"
              style={{ cursor: "pointer" }}
              onClick={() => context.openGlobalModal(MODAL_TYPE.USER_MODAL, visitor.getId())}
            >
              {visitor.getFullName()}
            </Link>
            <Typography variant="h5" className={classes.profession} noWrap>{visitor.getProfession()}</Typography>
            <Typography variant="h5" className={classes.companyName} noWrap>{visitor.getCompanyName()}</Typography>
          </Grid>
        </Grid>
        <Grid item container direction="row" className={classes.tagContainer} spacing={1}>
          {visitor.getTags().map(tag => (
            <Grid item>
              <Chip className={classes.tag} color="secondary" variant="default" label={tag.getName(MyStore.getLocal())} />
            </Grid>
          ))}
        </Grid>
        <Grid item container className={classes.contactContainer} direction="row">
          {visitor.getCompanyEmail() && <Typography variant="h5" noWrap><MailIcon className={classes.icon} />{visitor.getCompanyEmail()}</Typography>}
          {visitor.getPhoneNumber() && <Typography variant="h5" noWrap><PhoneIcon className={classes.icon} />{visitor.getPhoneNumber()}</Typography>}
        </Grid>
        <Grid item container className={classes.aboutContainer} >
          {visitor.getDescription() && <Typography style={{ fontWeight: "bolder", width: "100%" }} variant="h5" noWrap>{t("frontend.about") + " " + visitor.getFirstName()}</Typography>}
          <Typography variant="h5" className={classes.aboutText}>{
            !visitor.getDescription() ? "" :
              visitor.getDescription().length > 75 ?
                visitor.getDescription().substring(0, 75) + "..."
                : visitor.getDescription()}
          </Typography>
        </Grid>
        <Grid container direction="row" className={classes.actionArea}>
          <Grid item container xs={6} alignItems="center">
            <Button onClick={() => handleBookmark()} style={{ marginLeft: "auto", marginRight: "auto" }}>
              <Typography variant="h4">
                {bookmarkLoading ? <CircularProgress color="secondary" size={20} thickness={7} /> : visitor.isBookmarked() ? <Star className={classes.starIcon} /> : <StarBorder />}
              </Typography>
            </Button>
          </Grid>
          <Grid item container xs={6} alignItems="center">
            <Button style={{ marginLeft: "auto", marginRight: "auto" }} onClick={() => setChatOpen(true)}>
              <Typography variant="h5">
                <ChatBubbleOutlineRounded />
              </Typography>
            </Button>
          </Grid>
          {/* <Grid item xs={4} alignItems="center">
            <Button disabled={!visitor.isOnline() || compareEntitiesById(visitor,MyStore.getCurrentUser())} onClick={() => setVideoOpen(true)}>
              <Typography variant="h5">
                <VideoCall style={{color: visitor.isOnline() && !compareEntitiesById(visitor,MyStore.getCurrentUser()) ? "black":"grey"}}/>
              </Typography>
            </Button>
          </Grid> */}
        </Grid>
      </div>
      <StartChatModal chatUser={visitor} open={chatOpen} handleClose={() => setChatOpen(false)} />
      <ShareModal open={shareOpen} handleClose={() => setShareOpen(false)} payload={visitor} />
      <VideoModal
        open={videoOpen}
        handleClose={() => setVideoOpen(false)}
        chatUser={visitor}
      />
    </Grid>
  );
}

export default VisitorCard;
