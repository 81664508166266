/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React from "react";
import { colors } from "@material-ui/core";
import BarChartIcon from "@material-ui/icons/BarChart";
import ChatIcon from "@material-ui/icons/ChatOutlined";
import PeopleIcon from "@material-ui/icons/PeopleOutlined";
import PersonIcon from "@material-ui/icons/PersonOutlined";
import FolderIcon from "@material-ui/icons/FolderOutlined";
import HomeIcon from "@material-ui/icons/HomeOutlined";
import Label from "../../components/Label";
import SlideshowIcon from "@material-ui/icons/Slideshow";
import FrontendRoutes from "../../../constants/FrontendRoutes";

const {
  root,
  chat,
  agenda,
  visitors,
  workshops,
  exhibitors,
  meetings,
  bookmarks,
} = FrontendRoutes;

export default [
 
  {
    subheader: "Pages",
    items: [
      {
        title: 'home',
        href: root(),
        icon: HomeIcon,
      },
      {
        title: 'schedule',
        href: agenda(),
        icon: PeopleIcon,
      },
      {
        title: 'program',
        href: workshops.all(),
        icon: SlideshowIcon
      },
      {
        title: 'exhibitors',
        href: exhibitors.all(),
        icon: BarChartIcon,
      },
      {
        title: 'attendees',
        href: visitors.all(),
        icon: PeopleIcon,
        divider: true
      },
      {
        title: 'bookmarks',
        href: bookmarks.all(),
        icon: FolderIcon,
      },
      // {
      //   title: 'chat',
      //   href: chat.all(),
      //   icon: ChatIcon,
      //   label: () => true ? <div /> : (
      //     <Label color={'#E4007D'} shape="rounded">
      //       1
      //     </Label>
      //   ),
      // },
      {
        title: 'meetings',
        href: meetings.all(),
        icon: FolderIcon,
      }
    ],
  },
];
