import { Avatar, Chip, Grid, IconButton, LinearProgress, Typography, Box } from '@material-ui/core'
import { MailOutline, PhoneAndroid, Language, Instagram, LinkedIn, Facebook, Twitter, Edit } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import React, { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import NeumorphicStyle from '../../../../../constants/Neumorphic'
import { useVisitorQL } from '../../../../../engines/GraphQL/hooks/useVisitorsQL'
import { compareIds } from '../../../../../utils'
import MyStore from '../../../../../utils/MyStore'
import UserProfileEdit from './edit'

const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
        paddingLeft: theme.spacing(5),
        paddingRight: theme.spacing(5),
        background: theme.palette.background.default,
        borderRadius: NeumorphicStyle.borderRadius,
        zIndex: 10000,
        transition: "width 0.2s, height 0.2s, opacity 0.2s 0.2s",
        overflow: "auto",
        minWidth: "50vw",
        minHeight: "30vh",
        maxWidth: "50vw"
    },
    avatar: {
        marginRight: 15,
        height: 65,
        width: 65,
    },
    headerContainer: {
        marginBottom: 40
    },
    nameContainer: {
        alignSelf: "center",
    },
    name: {
        textTransform: "uppercase"
    },
    profession: {

    },
    infoContainer: {
        marginBottom: 40,
    },
    info: {
        marginRight: 30,
        width: "auto"
    },
    infoLabel: {
    },
    infoIcon: {
        marginRight: 15,
    },
    aboutContainer: {

    },
    aboutTitle: {
        marginBottom: 8,
    },
    about: {
        marginBottom: 40,
    },
    search: {

    },
    videoContainer: {
        overflow: "hidden",
        backgroundColor: "grey",
        width: "100%",
        paddingTop: "56.25%",
        boxShadow: NeumorphicStyle.boxShadowMedium(theme.palette.primary.main, theme.palette.secondary.dark),
        marginTop: 30,
        position: "relative",
        minWidth: "200px",
        minHeight: "112.5px",
        marginBottom: 40
    },
    video: {
        width: "100%",
        height: "100%",
        borderRadius: "inherit",
        border: "none",
        left: 0,
        top: 0,
        position: "absolute",
        objectFit: "cover",
    },
    socialIconButton: {
        width: 25,
        height: 25,
        padding: 0,
        borderRadius: 2,
        marginLeft: 8
    },
    socialIcon: {
        width: 25,
        height: 25
    },
    tagContainer: {
        marginTop: 15,
    },
    tag: {
        borderRadius: 4,
        height: 25,
        fontSize: 14,
    },
}))

function UserProfile({ userId }) {
    const classes = useStyles();
    const [visitor, loading, fetchNewData] = useVisitorQL(userId);
    const [t] = useTranslation();
    const [editMode, setEditMode] = useState(false)
    const [reload, setReload] = useState(false)

    useEffect(() => {
        if (editMode) {
            setReload(true);
        } else if (reload) {
            fetchNewData();
            setReload(false);
        }
    }, [editMode])



    const handleOpenLink = (url) => {
        url = (!url.match(/^https?:\/\//i) ? "https://" : "") + url;
        window.open(url, "_blank");
    }

    return loading ? (<Box className={classes.root}> <LinearProgress /> </Box>) : !visitor ? (<div />) : editMode ? (<UserProfileEdit user={visitor} setEditMode={(value) => setEditMode(value)} />) : (
        <Box className={classes.root}>
            <Grid container direction="row" className={classes.headerContainer}>
                <Avatar alt={visitor.getFullName()} src={visitor.getProfilePicture() ? visitor.getProfilePicture().getUrl("small") : ""} className={classes.avatar}>{visitor.getFirstName() && visitor.getFirstName()[0]}{visitor.getLastName() && visitor.getLastName()[0]}</Avatar>
                <Box className={classes.nameContainer}>
                    <Typography variant="h2" className={classes.name}>
                        {visitor.getFullName()}
                        {
                            compareIds(MyStore.getCurrentUser().getId(), visitor.getId()) ?
                                <Edit style={{ marginLeft: 20, cursor: "pointer" }} onClick={() => setEditMode(true)} />
                                : ""
                        }
                    </Typography>
                    <Typography variant="h5" className={classes.profession}>{visitor.getProfession() + " "}{visitor.getCompanyName() ? t("frontend.at") + " " + visitor.getCompanyName() : " "}</Typography>
                    {visitor.getTags() && visitor.getTags().length > 0 && (
                        <Grid item container direction="row" className={classes.tagContainer} spacing={1}>
                            {visitor.getTags().map(tag => (
                                <Grid item>
                                    <Chip className={classes.tag} color="secondary" variant="default" label={tag.getName(MyStore.getLocal())} />
                                </Grid>
                            ))}
                        </Grid>
                    )}
                </Box>
            </Grid>
            {
                <Grid container direction="row" className={classes.infoContainer}>
                    {visitor.getCompanyEmail() && (
                        <Grid container item className={classes.info} direction="row" alignItems="center">
                            <MailOutline className={classes.infoIcon} />
                            <Typography className={classes.infoLabel}>{visitor.getCompanyEmail()}</Typography>
                        </Grid>
                    )}
                    {visitor.getPhoneNumber() && (
                        <Grid container item className={classes.info} direction="row" alignItems="center">
                            <PhoneAndroid className={classes.infoIcon} />
                            <Typography className={classes.infoLabel}>{visitor.getPhoneNumber()}</Typography>
                        </Grid>
                    )}
                    {visitor.getWebsiteUrl() && (
                        <Grid container item className={classes.info} direction="row" alignItems="center">
                            <Language className={classes.infoIcon} />
                            <Typography className={classes.infoLabel}>{visitor.getWebsiteUrl()}</Typography>
                        </Grid>
                    )}
                </Grid>
            }
            {visitor.getDescription() && (
                <Box>
                    <Typography variant="h4" className={classes.aboutTitle}><Trans>frontend.about</Trans>{" " + visitor.getFullName()}</Typography>
                    <Typography variant="body1" className={classes.about}>
                        {visitor.getDescription()}
                    </Typography>
                </Box>
            )}
            {visitor.getVideo() && (
                <Box className={classes.videoContainer}>
                    <video className={classes.video} controls src={visitor.getVideo() || ""} />
                </Box>
            )}
            {visitor.getSearchText() && (
                <Box>
                    <Typography variant="h4" className={classes.aboutTitle}><Trans>frontend.visitor_search</Trans></Typography>
                    <Typography variant="body1" className={classes.about}>
                        {visitor.getSearchText()}
                    </Typography>
                </Box>
            )}
            <Grid container direction="row" justify="flex-end">
                {visitor.getInstagramUrl() && (<IconButton onClick={() => handleOpenLink(visitor.getInstagramUrl())} className={classes.socialIconButton}><Instagram className={classes.socialIcon} /></IconButton>)}
                {visitor.getTwitterUrl() && (<IconButton onClick={() => handleOpenLink(visitor.getTwitterUrl())} className={classes.socialIconButton}><Twitter className={classes.socialIcon} /></IconButton>)}
                {visitor.getLinkedInUrl() && (<IconButton onClick={() => handleOpenLink(visitor.getLinkedInUrl())} className={classes.socialIconButton}><LinkedIn className={classes.socialIcon} /></IconButton>)}
                {visitor.getFacebookUrl() && (<IconButton onClick={() => handleOpenLink(visitor.getFacebookUrl())} className={classes.socialIconButton}><Facebook className={classes.socialIcon} /></IconButton>)}
            </Grid>
        </Box>
    )
}

export default UserProfile
