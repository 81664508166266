export default class ErrorLogger {
  state = { clazz: '' };

  constructor(clazz, error) {

  this.state = { clazz: clazz = '', error: error };

  }

  static call(clazz, error) {
    new this(clazz || '', error).call();
  }

  call = () => {
    console.error("ERROR_REPORT in ");
    console.error(this.state.error);
  };
}
