import { makeStyles } from '@material-ui/core/styles'
import NeumorphicStyle from "../../../../../constants/Neumorphic";

const useStyles = makeStyles((theme) => ({
    root: {
      paddingLeft: theme.spacing(7),
      paddingRight: theme.spacing(7),
      paddingTop: theme.spacing(1.5),
      paddingBottom: theme.spacing(2),
      background: theme.palette.background.default,
      boxShadow: NeumorphicStyle.boxShadowMedium(
        theme.palette.primary.main,
        theme.palette.secondary.dark
      ),
      borderRadius: NeumorphicStyle.borderRadius,
      zIndex: 10000,
      transition: "width 0.2s, height 0.2s, opacity 0.2s 0.2s",
      overflow: "auto",
      width: "100%",
    },
    videoBox: {
      backgroundColor: "grey",
      boxShadow: NeumorphicStyle.boxShadowMedium(
        theme.palette.primary.main,
        theme.palette.secondary.dark
      ),
      position: "relative",
      minWidth: "200px",
      minHeight: "112.5px",
    },
    video: {
      width: "100%",
      height: "100%",
      borderRadius: "inherit",
      border: "none",
      left: 0,
      top: 0,
      position: "absolute",
      objectFit: "cover",
    },
    avatarRow: {
      justifySelf: "strech",
    },
    avatar: {
        width: theme.spacing(7),
        height: theme.spacing(7)
    },
    title: {
      marginLeft: theme.spacing(5),
      alignSelf: "center",
    },
    content: {
      whiteSpace: "pre-wrap",
      paddingBottom: theme.spacing(0),
      width: "100%",
      alignSelf: "center",
      marginBottom: "auto",
      color: "black",
      "&> p": {
        whiteSpace: "pre-wrap",
        fontSize: 20,
        fontWeight: 400,
        lineHeight: 1.235,
        fontFamily: "Lato, sans-serif",
      },
      "& iframe": {
        width: "100%"
      }
    },
    closeIcon: {
      background: theme.palette.background.default,
      zIndex: 800,
    },
    exhibitorsUsers: {
      padding: 12,
    },
    contactButton: {
      marginTop: "auto",
      marginBottom: "auto",
      color: theme.palette.secondary.contrastText,
      height: 50,
      background: `linear-gradient(to right, ${theme.palette.secondary.main}, ${theme.palette.primary.light})`,
      boxShadow: NeumorphicStyle.boxShadowMedium(
        theme.palette.primary.main,
        theme.palette.secondary.dark
      ),
    },
    favButton: {},
    favIcon: {
      marginRight: "auto",
      height: 30,
      width: 30,
      alignSelf: "center",
      marginLeft: 15,
      borderRadius: 0,
      padding: 0,
      cursor: "pointer",
    },
    favIconFilled: {
      marginRight: "auto",
      height: 30,
      width: 30,
      alignSelf: "center",
      marginLeft: 15,
      borderRadius: 0,
      padding: 0,
      color: theme.palette.secondary.light,
      cursor: "pointer",
    },
    attachmentContainter: {
      marginTop: 30,
    },
    attachmentDropdownContainer: {
      width: (props) => props.breakpoint <= 3 ? 250 : 350,
      height: (props) => props.breakpoint <= 3 ? 40 : 50,
      marginBottom: 12,
      justifyItems: "center",
      cursor: "pointer",
    },
    attachmentDropdown: {
      background: theme.palette.background.default,
      width: (props) => props.breakpoint <= 3 ? 250 : 350,
      height: (props) => props.breakpoint <= 3 ? 50 : 50,
      padding: 10,
      justifyItems: "center",
      borderRadius: 5,
      boxShadow: NeumorphicStyle.boxShadowMedium(
        theme.palette.primary.main,
        theme.palette.secondary.dark
      ),
      transition: "height 0.2s, opacity 0.2s 0.2s",
    },
    attachmentDropdownOpen: {
      background: theme.palette.background.default,
      position: "relative",
      width: (props) => props.breakpoint <= 3 ? 250 : 350,
      overflow: "hidden",
      padding: 10,
      borderRadius: 5,
      boxShadow: NeumorphicStyle.boxShadowMedium(
        theme.palette.primary.main,
        theme.palette.secondary.dark
      ),
      transition: "height 0.2s, opacity 0.2s 0.2s",
      zIndex: 10000
    },
    attachmentDropdownTitle: {
      height: 30,
    },
    openDropdownIcon: {
      transition: "transform .3s ease-in-out",
    },
    closeDropdownIcon: {
      transition: "transform .3s ease-in-out",
      transform: "rotate(180deg)",
    },
    attachmentDropdownCard: {
      marginTop: 10,
      height: 40,
    },
    backdrop: {
      zIndex: 5000,
      width: "inherit",
      height: "inherit",
      background: "rgba(255,255,255,0.0)",
      right: (props) => props.breakpoint <= 3 ? 252 : 352,
      justifyContent: "unset",
    },
    textInfo: {
        paddingLeft: theme.spacing(0.5)
    },
    socialIconButton: {
        width: 37,
        height: 37,
        padding: theme.spacing(0),
        borderRadius: 2,
        marginLeft: theme.spacing(1)
    },
    socialIcon: {
        width: 37,
        height: 37,
        marginLeft: theme.spacing(1),
    },
    carouselImage: {
      objectFit: "cover",
      borderRadius: 10,
      width: "100%",
      height: "100%",
      boxShadow: NeumorphicStyle.boxShadowSmall(
        "#d3d3d3",
        "#FFFFFF"
      ),
    },
    carouselContainer: {
      width: "33.33%",
      height: 0,
      position: "relative",
      paddingBottom: "33.33%",
    },
    carouselButtons: {
      height: 30, 
      marginLeft: "auto", 
      marginRight: "auto"
    },
    pointer: {
      cursor: "pointer"
    },
    arrowIcons: {
      background: theme.palette.background.default,
    },
    responsive: {},
    "@media (min-width: 2100px)": {
      responsive: {
        height: "97%",
      },
    },
  }));

export default useStyles;
