import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { fetchConversation, fetchConversations, setUnreadConversationsMessagesCount } from '../../redux/actions/ConversationsActions';
import { fetchKeynoteConversation, fetchKeynoteConversations, setUnreadKeynoteConversationsMessagesCount } from '../../redux/actions/KeynoteConversationsActions';
import { DEFAULT_PAGE_SIZE, DEFAULT_PAGE_INDEX } from '../../constants/Urls';
import useExhibition from '../GeneralHooks/useExhibition';
import { fetchMessages, clearMessages, createMessage } from '../../redux/actions/MessagesActions';
import FilterParams from '../../utils/FilterParams';
import Message from '../../models/Message';
import ConversationCable from '../../actioncable/ConversationCable';
import MessageMapper from '../../mapper/MessageMapper';
import PushNotificationMapper from '../../mapper/PushNotificationMapper';
import Conversation from '../../models/Conversation';
import PushNotificationCable from '../../actioncable/PushNotificationCable';
import PushNotification from '../../models/PushNotification';
import MyStore from '../../utils/MyStore';
import { compareIds } from '../../utils';

function useChatEngine(conversationId, pageIndex = DEFAULT_PAGE_INDEX, pageSize = DEFAULT_PAGE_SIZE) {
    const dispatch = useDispatch();
    const [exhibition] = useExhibition();
    const [conversation] = useConversation(exhibition,conversationId);
    const [incomingMessage] = useChatConversationChannel(conversation);
    const [messages, setMessages] = useState([]);
    const [incomingMessagesCount, setincomingMessagesCount] = useState(0);
    const [totalPages, setTotalPages] = useState(1);
    const [loading, setLoading] = useState(false);
    const [sending, setSending] = useState(false);
    const messagesReducer = useSelector(state => state.messagesReducer);
    const conversationsReducer =  useSelector(state => state.conversationsReducer)
    const usersReducer = useSelector(state => state.usersReducer);

    const sendMessage = (message) => {
        dispatch(createMessage(message))
        setSending(true);
    }

    useEffect(() => {
        if (!conversation) {
            return;
        }

        setLoading(true);
        dispatch(
            fetchMessages(exhibition.getId(), {
                pageSize: pageSize,
                pageIndex: pageIndex,
                filters: [
                    FilterParams.filterBy(
                        Message.filterParams.conversationId,
                        conversationId
                    ),
                ],
            })
        );
    }, [exhibition,conversation,pageIndex, pageSize])

    useEffect(() => {
        if (!conversation || messagesReducer.isFetching || messagesReducer.message || messagesReducer.error.isError()) {
            if (messagesReducer.error.isError()) {
                setLoading(false)
            }
            return;
        }

        if(!sending) {
            conversation.setUnreadMessagesCount(conversation.getUnreadMessagesCount() - messagesReducer.unreadMessagesCount)
            dispatch(setUnreadConversationsMessagesCount(conversationsReducer.unreadMessagesCount - messagesReducer.unreadMessagesCount))
        }

        setSending(false)
        setLoading(false);
        if (pageIndex === DEFAULT_PAGE_INDEX) {
            setMessages(messagesReducer.messages);
            setTotalPages(messagesReducer.totalPages);
        } else {
            setMessages(messagesReducer.messages.concat(messages));
        }
        
    }, [messagesReducer.isFetching])

    useEffect(() => {
        if (
            !incomingMessage ||
            incomingMessage.getConversationId() !== conversationId ||
            (
                usersReducer.currentUser.getRole().getName() !== 'Authenticated' &&
                usersReducer.currentUser.getRole().getName() !== 'Customer' &&
                incomingMessage.getUser().getId() !== MyStore.getCurrentUser().getId() &&
                incomingMessage.getModeratorOnly()
            )
        )
        {
            return;
        }

        setincomingMessagesCount(incomingMessagesCount + 1);
        setMessages(messages.concat(incomingMessage));
        setSending(false);
    }, [incomingMessage])

    const resetIncomingMessagesCount = () => {
        setincomingMessagesCount(0);
    }

    const clearThread = () => {
        dispatch(clearMessages());
        setMessages([]);
    }

    return [conversation, messages, loading, incomingMessagesCount, totalPages, resetIncomingMessagesCount, sendMessage, sending, clearThread]
}

export function useConversation(exhibition,conversationId) {
    const dispatch = useDispatch();

    const [conversation, setConversation] = useState();
    const [loading, setLoading] = useState(false);

    const conversationsReducer = useSelector(state => state.conversationsReducer);

    useEffect(() => {
        if (!exhibition || !conversationId) {
            return;
        }
        
        dispatch(fetchConversation(exhibition.getId(), conversationId));
    }, [exhibition, conversationId])

    useEffect(() => {
        if (
            conversationsReducer.isFetching ||
            !conversationsReducer.conversation ||
            conversationsReducer.error.isError()
        ) {
            return;
        }

        setLoading(false);
        if (compareIds(conversationsReducer.conversation.getId(),conversationId)) {
            setConversation(conversationsReducer.conversation);
        }

    }, [conversationsReducer.isFetching]);

    return [conversation,loading]
}

export function useKeynoteConversation(exhibition,conversationId) {
    const dispatch = useDispatch();

    const [conversation, setConversation] = useState();
    const [loading, setLoading] = useState(false);

    const keynoteConversationsReducer = useSelector(state => state.keynoteConversationsReducer);

    useEffect(() => {
        if (!exhibition || !conversationId) {
            return;
        }
        
        dispatch(fetchKeynoteConversation(exhibition.getId(), conversationId));
    }, [exhibition, conversationId])

    useEffect(() => {
        if (
            keynoteConversationsReducer.isFetching ||
            !keynoteConversationsReducer.conversation ||
            keynoteConversationsReducer.error.isError()
        ) {
            return;
        }

        setLoading(false);
        if (compareIds(keynoteConversationsReducer.conversation.getId(),conversationId)) {
            setConversation(keynoteConversationsReducer.conversation);
        }

    }, [keynoteConversationsReducer.isFetching]);

    return [conversation,loading]
}

function useChatConversationChannel(conversation) {
    const dispatch = useDispatch();
    const [incomingMessage, setIncomingMessage] = useState();

    const actionCableReducer = useSelector(state => state.actionCableReducer);

    useEffect(() => {
        if (!actionCableReducer.cable || !conversation) {
            return;
        }

        let conversationCable = new ConversationCable(actionCableReducer.cable,conversation);
        conversationCable.connect((data) => {
            let newMessage = MessageMapper.build(data.payload);

            setIncomingMessage(newMessage);
        });

        return () => {
            conversationCable.disconnect();
            dispatch(clearMessages());
        };
    }, [actionCableReducer.cable, conversation]);

    return [incomingMessage]
}

export function useChatPushNotification() {
    const [incomingChatPush, setIncomingChatPush] = useState()
    const [exhibition] = useExhibition();

    const actionCableReducer = useSelector(state => state.actionCableReducer);

    useEffect(() => {
        if (!actionCableReducer.cable || !exhibition) {
            return;
        }
        
        let pnc = new PushNotificationCable(MyStore.getCurrentUser(),actionCableReducer.cable, exhibition);
        pnc.connect((data) => {
            let pn = PushNotificationMapper.build(data);

            if (pn.getNotificationType() === PushNotification.NOTIFICATION_TYPES.chatMessage) {
                setIncomingChatPush(pn.getPayload());
            }
        });

        return () => {
            pnc.disconnect();
        };
    }, [actionCableReducer.cable, exhibition]);

    return [incomingChatPush]
}

export function useConversations(pageIndex = DEFAULT_PAGE_INDEX, pageSize = DEFAULT_PAGE_SIZE, filters = [], addedConversationId) {
    const dispatch = useDispatch();
    const [conversations, setConversations] = useState([]);
    const [loading, setLoading] = useState(false);
    const [exhibition] = useExhibition();
    const [totalPages, setTotalPages] = useState(1);

    const [conversation] = useConversation(exhibition,addedConversationId);

    const conversationsReducer = useSelector(state => state.conversationsReducer);

    useEffect(() => {
        if (!exhibition) {
            return;
        }

        dispatch(fetchConversations(exhibition.getId(),{
            pageIndex: pageIndex,
            pageSize:pageSize,
            filters: []
        }))
        setLoading(true);
    }, [exhibition,pageIndex,pageSize, filters.length])

    useEffect(() => {
        if (!conversation) {
            return
        }

        setConversations([conversation].concat(conversations));
    }, [conversation])

    useEffect(() => {
        if (
            conversationsReducer.isFetching ||
            !conversationsReducer.conversations ||
            !loading ||
            //conversationsReducer.conversation ||
            conversationsReducer.error.isError()
        ) {
            return;
        }

        setLoading(false);
        setConversations(conversationsReducer.conversations)

    }, [conversationsReducer.isFetching]);

    const reloadConversations = () => {
        if (!exhibition) {
            return;
        }

        dispatch(fetchConversations(exhibition.getId(),{
            pageIndex: pageIndex,
            pageSize:pageSize,
            filters: [
            //   FilterParams.filterBy(Conversation.filterParams.superuser, false),
            //   FilterParams.filterBy(Conversation.filterParams.standalone, true),
            ]
        }))
        setLoading(true);
    }

    return [conversations, loading, totalPages, reloadConversations]
}

export function useCreateConversation(chatUser) {
    const dispatch = useDispatch();
    const [exhibition] = useExhibition();

    const [conversationId, setConversationId] = useState()
    const [loading, setLoading] = useState(false);

    const messagesReducer = useSelector(state => state.messagesReducer);
    const usersReducer = useSelector(state => state.usersReducer)

    useEffect(() => {
        if (messagesReducer.isFetching || !chatUser || 
            !messagesReducer.message || 
            !loading) {
            return
        }

        setLoading(false);

        if (messagesReducer.error.isError()) {
            return
        }

        setConversationId(messagesReducer.message.getConversationId())
    }, [messagesReducer.isFetching])
    
    const sendNewChatMessage = (value) => {
        if (!exhibition || !chatUser || value === "") {
            return
        }
        const user = usersReducer.currentUser

        let message = new Message({
            exhibitionId: exhibition.getId(),
            me: true,
            text: value.getText ? value.getText() : value,
            user: user,
            receivers: [chatUser],
            createdAt: new Date(),
        });
    
        dispatch(createMessage(message));
        setLoading(true);
    }

    const clearNewConversationId = () => {
        setConversationId(undefined);
    }

    return [conversationId,loading, sendNewChatMessage, clearNewConversationId]
}

export default useChatEngine
