import TalkingPoint from "../models/TalkingPoint";
import BaseMapper from "./BaseMapper";
import ConversationMapper from "./ConversationMapper";
import ImageMapper from "./ImageMapper";
import LocalTitleMapper from "./LocalTitleMapper";

const attributes = TalkingPoint.attributes;

export default class TalkingPointMapper {
  static build = (json = undefined) => {
    if (!json) {
      return;
    }

    return new TalkingPoint({
      ...BaseMapper.attributes(json),
      id: json[attributes.id],
      name: json[attributes.name],
      description: json[attributes.description],
      primaryColor: json[attributes.primaryColor],
      highlightColor: json[attributes.highlightColor],
      fontColor: json[attributes.fontColor],
      fontHighlightColor: json[attributes.fontHighlightColor],
      headerImage: ImageMapper.build(json.header_image),
      conversation: ConversationMapper.build(json.conversation),
      orderIndex: json[attributes.orderIndex] || 0,
      localTitle: LocalTitleMapper.buildFromArray(json[attributes.localTitle]),
    });
  };

  static buildFromArray = (jsonArray = []) => {
    return jsonArray.map((entry) => {
      return this.build(entry);
    });
  };

  static toJsonString(talkingPoint) {
    return JSON.stringify({
      [talkingPoint.name]: talkingPoint.getName(),
      [talkingPoint.description]: talkingPoint.getDescription(),
      [talkingPoint.primaryColor]: talkingPoint.getPrimaryColor(),
      [talkingPoint.highlightColor]: talkingPoint.getHighligntColor(),
      [talkingPoint.fontColor]: talkingPoint.getFontColor(),
      [talkingPoint.fontHighlightColor]: talkingPoint.getFontHighlightColor(),
      [talkingPoint.conversation]: talkingPoint.getConversation(),
    });
  }
}
