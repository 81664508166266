import {
  FETCHING_EXHIBITIONS_REQUEST,
  FETCHING_EXHIBITIONS_SUCCESS,
  FETCHING_EXHIBITIONS_FAILURE,

  FETCHING_EXHIBITIONS_UPCOMING_REQUEST,
  FETCHING_EXHIBITIONS_UPCOMING_SUCCESS,
  FETCHING_EXHIBITIONS_UPCOMING_FAILURE,
  
  FETCHING_EXHIBITION_REQUEST,
  FETCHING_EXHIBITION_SUCCESS,
  FETCHING_EXHIBITION_FAILURE,

  CREATE_EXHIBITION_FAILURE,
  CREATE_EXHIBITION_SUCCESS,
  CREATE_EXHIBITION_REQUEST,

  UPDATE_EXHIBITION_FAILURE,
  UPDATE_EXHIBITION_SUCCESS,
  UPDATE_EXHIBITION_REQUEST,

  DELETE_EXHIBITION_FAILURE,
  DELETE_EXHIBITION_SUCCESS,
  DELETE_EXHIBITION_REQUEST,

  SET_CURRENT_EXHIBITION
} from '../actions/types';
import ResponseService from '../../utils/ResponseService';
import ApplicationError from '../../utils/ApplicationError';

const initialState = {
  exhibitions: [],
  exhibitionsUpcoming: [],
  exhibition: undefined,
  currentSet: false,
  error: ApplicationError.buildDefault(),
  isFetching: false,
  ...ResponseService.defaultPaginationMetaData()
};

const exhibitionsReducer = (state = initialState, action ) => {
  switch(action.type) {
    //ALL Request
    case FETCHING_EXHIBITIONS_REQUEST:
      return {...state, isFetching: true }
    case FETCHING_EXHIBITIONS_SUCCESS:
      return {...state, isFetching: false, 
        exhibitions: action.payload.objects, 
        error: ApplicationError.buildDefault(),
        ...action.payload.paginationData }
        
    case FETCHING_EXHIBITIONS_FAILURE:
      return {...state, isFetching: false, error: action.payload }

      //ALL Request
    case FETCHING_EXHIBITIONS_UPCOMING_REQUEST:
      return {...state, isFetching: true }
    case FETCHING_EXHIBITIONS_UPCOMING_SUCCESS:
      return {...state, isFetching: false, 
        exhibitionsUpcoming: action.payload.objects, 
        error: ApplicationError.buildDefault(),
        ...action.payload.paginationData }
        
    case FETCHING_EXHIBITIONS_UPCOMING_FAILURE:
      return {...state, isFetching: false, error: action.payload }
    
    //SHOW Request
    case FETCHING_EXHIBITION_REQUEST:
      return {...state, isFetching: true }
    case FETCHING_EXHIBITION_SUCCESS:
      return {...state, isFetching: false, exhibition: action.payload, error: ApplicationError.buildDefault() }
    case FETCHING_EXHIBITION_FAILURE:
      return {...state, isFetching: false, error: action.payload }  


    //CREATE Request
    case  CREATE_EXHIBITION_REQUEST:
      return {...state, isFetching: true }
    case  CREATE_EXHIBITION_SUCCESS:
      return {...state, isFetching: false, exhibition: action.payload, error: ApplicationError.buildDefault() }
    case  CREATE_EXHIBITION_FAILURE:
      return {...state, isFetching: false, error: action.payload }


    //UPDATE Request
    case  UPDATE_EXHIBITION_REQUEST:
      return {...state, isFetching: true }
    case  UPDATE_EXHIBITION_SUCCESS:
      return {...state, isFetching: false, exhibition: action.payload, error: ApplicationError.buildDefault() }
    case  UPDATE_EXHIBITION_FAILURE:
      return {...state, isFetching: false, error: action.payload }


    //DELETE Request  
    case  DELETE_EXHIBITION_REQUEST:
      return {...state, isFetching: true }
    case  DELETE_EXHIBITION_SUCCESS:
      return {...state, isFetching: false, error: ApplicationError.buildDefault() }
    case  DELETE_EXHIBITION_FAILURE:
      return {...state, isFetching: false, error: action.payload }


    case SET_CURRENT_EXHIBITION:
      return {...state, isFetching: false, exhibition: action.payload, error: ApplicationError.buildDefault(), currentSet: true }  

    default: 
      return state
  }
}

export default exhibitionsReducer;