import ApplicationError from "../../utils/ApplicationError";
import {
  FETCHING_DISCUSSION_ROOM_FAILURE,
  FETCHING_DISCUSSION_ROOM_REQUEST,
  FETCHING_DISCUSSION_ROOM_SUCCESS,
} from "../actions/types";

const initialState = {
  discussion_room: null,
  error: ApplicationError.buildDefault(),
  isFetching: false,
  // ...ResponseService.defaultPaginationMetaData(),
};

const TalkingPointsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCHING_DISCUSSION_ROOM_REQUEST:
      return { ...state, isFetching: true };

    case FETCHING_DISCUSSION_ROOM_SUCCESS:
      return {
        ...state,
        isFetching: false,
        discussion_room: action.payload,
        error: ApplicationError.buildDefault(),
        ...action.payload.paginationData,
      };

    case FETCHING_DISCUSSION_ROOM_FAILURE:
      return { ...state, isFetching: false, error: action.payload };

    default:
      return state;
  }
};

export default TalkingPointsReducer;
