import {
  FETCHING_EXHIBITION_POSTS_REQUEST,
  FETCHING_EXHIBITION_POSTS_SUCCESS,
  FETCHING_EXHIBITION_POSTS_FAILURE,

  FETCHING_EXHIBITION_POST_REQUEST,
  FETCHING_EXHIBITION_POST_SUCCESS,
  FETCHING_EXHIBITION_POST_FAILURE,

  CREATE_EXHIBITION_POST_REQUEST,
  CREATE_EXHIBITION_POST_SUCCESS,
  CREATE_EXHIBITION_POST_FAILURE,

  UPDATE_EXHIBITION_POST_REQUEST,
  UPDATE_EXHIBITION_POST_SUCCESS,
  UPDATE_EXHIBITION_POST_FAILURE,

  DELETE_EXHIBITION_POST_REQUEST,
  DELETE_EXHIBITION_POST_SUCCESS,
  DELETE_EXHIBITION_POST_FAILURE,
  DELETE_ALL_POSTS_LOCAL
} from './types';
import Urls, { DEFAULT_OPTIONAL_PARAMETERS } from '../../constants/Urls';
import { BEARER } from '../../utils/MyStore';

import PostMapper from '../../mapper/PostMapper';
import ResponseService from '../../utils/ResponseService';
import ApplicationError from '../../utils/ApplicationError';
import ErrorLogger from '../../utils/ErrorLogger';

export const fetchingPostsRequest = () => ({
  type: FETCHING_EXHIBITION_POSTS_REQUEST
})

export const fetchingPostsSuccess = (json) => ({
  type: FETCHING_EXHIBITION_POSTS_SUCCESS,
  payload: json
})
export const fetchingPostsFailure = (error) => ({
  type: FETCHING_EXHIBITION_POSTS_FAILURE,
  payload: error
})

export const fetchingPostRequest = () => ({
  type: FETCHING_EXHIBITION_POST_REQUEST
})

export const fetchingPostSuccess = (post) => ({
  type: FETCHING_EXHIBITION_POST_SUCCESS,
  payload: post
})
export const fetchingPostFailure = (error) => ({
  type: FETCHING_EXHIBITION_POST_FAILURE,
  payload: error
})

export const createPostRequest = () => ({
  type: CREATE_EXHIBITION_POST_REQUEST
})

export const createPostSuccess = (post) => ({
  type: CREATE_EXHIBITION_POST_SUCCESS,
  payload: post
})

export const createPostFailure = (error) => ({
  type: CREATE_EXHIBITION_POST_FAILURE,
  payload: error
})

export const updatePostRequest = () => ({
  type: UPDATE_EXHIBITION_POST_REQUEST
})

export const updatePostSuccess = (post) => ({
  type: UPDATE_EXHIBITION_POST_SUCCESS,
  payload: post
})

export const updatePostFailure = (error) => ({
  type: UPDATE_EXHIBITION_POST_FAILURE,
  payload: error
})

export const deletePostRequest = () => ({
  type: DELETE_EXHIBITION_POST_REQUEST
})

export const deletePostSuccess = () => ({
  type: DELETE_EXHIBITION_POST_SUCCESS
})

export const deletePostFailure = (error) => ({
  type: DELETE_EXHIBITION_POST_FAILURE,
  payload: error
})

export const deleteAllPostsLocal = () => ({
  type: DELETE_ALL_POSTS_LOCAL, 
  payload: []
})

export const fetchPosts = (exhibitionId, _opts = DEFAULT_OPTIONAL_PARAMETERS) => {
  return async dispatch => {
    dispatch(fetchingPostsRequest());
    
    try {
      const route   = Urls.exhibitions.posts.all
      const bearer  = localStorage.getItem(BEARER)
      const headers = Urls.header.secure(bearer)
      const url     = Urls.build(route.url(exhibitionId, _opts.pageIndex, _opts.pageSize), _opts.filters)

      let response = await fetch(url, {
        method: route.method,
        headers: headers
      });
      let responseService = new ResponseService(PostMapper)

      let action = await responseService.call(response, 
        (value) => fetchingPostsSuccess(value),
        (error) => fetchingPostsFailure(error))

      dispatch(action)  
    } catch(error) {
      ErrorLogger.call(this, error)
      dispatch(fetchingPostsFailure(error))
    }
  };
};

export const fetchPost = (exhibitionId, id) => {
  return async dispatch => {
    dispatch(fetchingPostRequest());

    try {
      const route   = Urls.exhibitions.posts.show
      const bearer  = localStorage.getItem(BEARER)
      const headers = Urls.header.secure(bearer)
      const url     = route.url(exhibitionId, id)

      let response = await fetch(url, {
        method: route.method,
        headers: headers
      })

      let responseService = new ResponseService(PostMapper)

      let action = await responseService.call(response, 
        (value) => fetchingPostSuccess(value),
        (error) => fetchingPostFailure(error))

      dispatch(action)
    } catch(error) {
      ErrorLogger.call(this, error)
      dispatch(fetchingPostFailure(ApplicationError.buildApplicationError(error)))
    }
  }
}

export const removePosts = () => {
  return async dispatch => dispatch(deleteAllPostsLocal());
}

export const createPost = (post) => {
  return async dispatch => {
    dispatch(createPostRequest());
    try {

      const route = Urls.exhibitions.posts.create
      const bearer = localStorage.getItem(BEARER)
      const headers = Urls.header.secure(bearer)
      const url = route.url(post.getExhibitionId(), post.getId())

      let response = await fetch(url, {
        method: route.method,
        headers: headers,
        body: PostMapper.toJsonString(post)
      });

      let responseService = new ResponseService(PostMapper)

      let action = await responseService.call(response, 
        (value) => createPostSuccess(value),
        (error) => createPostFailure(error))

      dispatch(action)  
    } catch(error) {
      ErrorLogger.call(this, error)
      dispatch(createPostFailure(ApplicationError.buildApplicationError(error)))
    }
  };
};

export const updatePost = (post) => {
  return async dispatch => {
    dispatch(updatePostRequest());
    
    try {
      const route   = Urls.exhibitions.posts.update
      const bearer  = localStorage.getItem(BEARER)
      const headers = Urls.header.secure(bearer)
      const url     = route.url(post.getExhibitionId(), post.getId())

      let response = await fetch(url, {
        method: route.method,
        headers: headers,
        body: PostMapper.toJsonString(post)
      });

      let responseService = new ResponseService(PostMapper)

      let action = await responseService.call(response, 
        (value) => updatePostSuccess(value),
        (error) => updatePostFailure(error))

      dispatch(action)  
    } catch(error) {
      ErrorLogger.call(this, error)
      dispatch(updatePostFailure(ApplicationError.buildApplicationError(error)))
    }
  };
};
export const salesmarkPost = (post) => {
  return async dispatch => {
    dispatch(updatePostRequest());
    
    try {
      const route   = Urls.exhibitions.posts.salesmark
      const bearer  = localStorage.getItem(BEARER)
      const headers = Urls.header.secure(bearer)
      const url     = route.url(post.getExhibitionId(), post.getId())

      let response = await fetch(url, {
        method: route.method,
        headers: headers,
      });

      let responseService = new ResponseService(PostMapper)

      let action = await responseService.call(response, 
        (value) => updatePostSuccess(value),
        (error) => updatePostFailure(error))

      dispatch(action)  
    } catch(error) {
      ErrorLogger.call(this, error)
      dispatch(updatePostFailure(ApplicationError.buildApplicationError(error)))
    }
  };
};
export const bookmarkPost = (post) => {
  return async dispatch => {
    dispatch(updatePostRequest());
    
    try {
      const route   = Urls.exhibitions.posts.bookmark
      const bearer  = localStorage.getItem(BEARER)
      const headers = Urls.header.secure(bearer)
      const url     = route.url(post.getExhibitionId(), post.getId())

      let response = await fetch(url, {
        method: route.method,
        headers: headers,
      });

      let responseService = new ResponseService(PostMapper)

      let action = await responseService.call(response, 
        (value) => updatePostSuccess(value),
        (error) => updatePostFailure(error))

      dispatch(action)  
    } catch(error) {
      ErrorLogger.call(this, error)
      dispatch(updatePostFailure(ApplicationError.buildApplicationError(error)))
    }
  };
};

export const deletePost = (post) => {
  return async dispatch => {
    dispatch(deletePostRequest());
    
    try {
      const route   = Urls.exhibitions.posts.delete
      const bearer  = localStorage.getItem(BEARER)
      const headers = Urls.header.secure(bearer)
      const url     = route.url(post.getExhibitionId(), post.getId())

      let response = await fetch(url, {
        method: route.method,
        headers: headers
      });

      let responseService = new ResponseService(PostMapper)

      let action = await responseService.call(response, 
        (value) => deletePostSuccess(value),
        (error) => deletePostFailure(error))

      dispatch(action)  
    } catch(error) {
      ErrorLogger.call(this, error)
      dispatch(deletePostFailure(ApplicationError.buildApplicationError(error)))
    }
  };
};