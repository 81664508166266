import { Grid, IconButton, Box } from '@material-ui/core'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { VideoLabelOutlined, Close, VolumeOff, VolumeUp, PowerSettingsNew, Movie } from "@material-ui/icons";
import { makeStyles } from '@material-ui/styles';
import useExhibition from '../../../../../engines/GeneralHooks/useExhibition';
import Logo from "../../../../../assets/images/leadevents-logo-white.svg";
import useWindowSize from '../../../../../engines/Util/useWindowSize';
import { useKeynote } from '../../../../../engines/GeneralHooks/useKeynotes';
import FullVideoView from './FullVideoView';
import { useConversation, useKeynoteConversation } from '../../../../../engines/Chat/ChatEngine';
import PushNotificationCable from '../../../../../actioncable/PushNotificationCable';
import { STATUS } from "../../../../../redux/reducers/actionCableReducer";
import { useDispatch, useSelector } from 'react-redux';
import { fetchKeynote } from '../../../../../redux/actions/KeynotesActions';
import PushNotification from '../../../../../models/PushNotification';
import { fetchExhibition } from '../../../../../redux/actions/ExhibitionsActions';
import { useStage } from '../../../../../engines/GeneralHooks/useStages';
import { fetchStage } from '../../../../../redux/actions/StagesActions';
import PublicCable from '../../../../../actioncable/PostCable';
import { useRawPushNotification } from '../../../../../engines/Notification/NotificationEngine';
import NeumorphicStyle from '../../../../../constants/Neumorphic';
import MyStore from '../../../../../utils/MyStore';
import GlobalContext from '../../context/GlobalContext';
import useTracking, { EVENT_TRACKING_TYPES, VISIT_TRACKING_TYPES } from '../../../../engines/tracking/useTracking';
import useBreakpoints from '../../../../../engines/Util/useBreakpoints';

const useStyles = makeStyles(theme => ({
    root: {
        background: theme.palette.background.default,
        borderRadius: NeumorphicStyle.borderRadius,
        boxShadow: NeumorphicStyle.boxShadowLarge(theme.palette.primary.main, theme.palette.secondary.dark),
        width: (props) => props.breakpoint <= 3 ? 250 : 300,
        left: 16,
        position: "absolute",
        top: 250,
        bottom: 235,
        zIndex: 999,
        overflowY: "scroll"
    },
    videoBox: {
        height: (props) => props.breakpoint <= 3 ? 141 : 169,
        width: (props) => props.breakpoint <= 3 ? 250 : 300,
        left: 16,
        background: theme.palette.background.default,
        boxShadow: NeumorphicStyle.boxShadowMedium(theme.palette.primary.main, theme.palette.secondary.dark),
        borderRadius: NeumorphicStyle.borderRadius,
        position: "absolute",
        zIndex: 5000,
        transition: "width 0.2s, height 0.2s, opacity 0.2s 0.2s",
    },
    videoBoxCinema: {
        height: 507,
        background: theme.palette.background.default,
        boxShadow: NeumorphicStyle.boxShadowMedium(theme.palette.primary.main, theme.palette.secondary.dark),
        borderRadius: NeumorphicStyle.borderRadius,
        width: 900,
        left: 16,
        position: "absolute",
        zIndex: 5000,
        transition: "width 0.2s, height 0.2s, opacity 0.2s 0.2s",
    },
    videoBoxFull: {
        left: 16,
        right: (props) => props.breakpoint <= 3 ? 282 : 382,
        top: 96,
        background: theme.palette.background.default,
        boxShadow: NeumorphicStyle.boxShadowMedium(theme.palette.primary.main, theme.palette.secondary.dark),
        borderRadius: NeumorphicStyle.borderRadius,
        position: "absolute",
        zIndex: 5000,
        transition: "width 0.2s, height 0.2s, opacity 0.2s 0.2s",
    },
    inheritParent: {
        width: "inherit",
        height: "inherit",
        borderRadius: "inherit",
        left: "inherit",
        right: "inherit",
        top: "inherit",
        bottom: "inherit",
    },
    iconButtonArea: {
        width: "auto",
        borderRadius: "inherit",
        background: "rgba(255,255,255,0.7)",
        color: "black",
        position: "absolute",
        right: 8,
        top: 8,
        padding: "4px"
    },
    iconButtonClose: {
        padding: "4px"
    },
    iconButtonView: {
        marginLeft: "auto",
        padding: "4px"
    }
}));

const defaultVideoScreen = () => (
    <div
        style={{
            position: "relative",
            backgroundColor: "lightGrey",
            width: "inherit",
            height: "inherit",
            //maxWidth:750,
            //maxHeight: 750 * 0.5525
        }}
    >
        <img
            src={Logo}
            style={{
                width: "50%",
                height: "50%",
                marginLeft: "25%",
                marginTop: "12.5%",
            }}
        />
    </div>
);

function VideoBox({ setKeynoteConversation, setParentVideoMode, isBottomButtonExists = false }) {
    const [breakpoint] = useBreakpoints();
    const classes = useStyles({ breakpoint });
    const [videoMode, setVideoMode] = useState(0);
    const [exhibition] = useExhibition();
    const [stages, setStages] = useState();
    const [currStageId, setCurrStageId] = useState();
    const [stage, stageLoading] = useStage(currStageId);
    const containerRef = useRef();
    const [keynoteId, setKeynoteId] = useState();
    const [conversationId, setConversationId] = useState();
    const [conversation, loading] = useKeynoteConversation(exhibition, conversationId)
    const [keynote] = useKeynote(keynoteId);
    const context = useContext(GlobalContext);
    const [trackEvent, trackVisit] = useTracking();

    useEffect(() => {
        if (context.videoFullWidth) {
            setVideoMode(2);
            context.setVideoFullWidth(false);
        }
    }, [context.videoFullWidth])

    useEffect(() => {
        if (!keynote) {
            return;
        }

        trackEvent(EVENT_TRACKING_TYPES.stage.enteredAgendKeynote, {
            keynote: keynote.getId(),
            title: keynote.getTitle(),
            language: MyStore.getLocal() || ''
        })
    }, [keynote])

    useEffect(() => {

        const onbeforeunloadFn = () => {
            if (keynote) {
                trackEvent(EVENT_TRACKING_TYPES.stage.endedAgendKeynote, {
                    keynote: keynote.getId(),
                    title: keynote.getTitle(),
                    language: MyStore.getLocal() || '',
                    muted: context.muted
                })
            }
        }

        window.addEventListener('beforeunload', onbeforeunloadFn);
        return () => {
            window.removeEventListener('beforeunload', onbeforeunloadFn);
        }
    }, [keynote])

    useEffect(() => {
        setParentVideoMode(videoMode);
    }, [videoMode])

    useEffect(() => {
        if (!exhibition) {
            return
        }
        setStages(exhibition.getStages());
        if (exhibition.getStages() && exhibition.getStages().length > 0) {
            const locStage = exhibition.getStages()[0];
            setCurrStageId(locStage.getId());
        }
    }, [exhibition, MyStore.getLocal()])

    useEffect(() => {
        if (videoMode === 2 && keynote) {
            trackVisit(VISIT_TRACKING_TYPES.mainStage, {
                keynote: keynote.getId(),
                title: keynote.getTitle(),
                language: MyStore.getLocal() || '',
                muted: context.muted,
                fullscreen: true,
            })
        }
    }, [videoMode])

    useEffect(() => {
        if (!conversation) {
            return;
        }

        setKeynoteConversation(conversation);
    }, [conversation])

    useEffect(() => {
        if (!stage) {
            return
        }

        setKeynoteId(stage.getCurrentKeynoteId(MyStore.getLocal()));
        const convId = stage.getConversationId(MyStore.getLocal());
        setConversationId(convId);
    }, [stage, MyStore.getLocal()])

    const dispatch = useDispatch();
    const [data] = useRawPushNotification();

    useEffect(() => {
        if (!keynote ||
            !data ||
            data.getNotificationType() !== PushNotification.NOTIFICATION_TYPES.keynoteUpdate ||
            data.getPayload().getId() !== keynote.getId()
        ) {

            return;
        }

        loadKeynote();
    }, [data]);

    useEffect(() => {
        if (!stage ||
            !data ||
            data.getNotificationType() !== PushNotification.NOTIFICATION_TYPES.stageUpdate ||
            data.getPayload().getId() !== stage.getId()
        ) {

            return;
        }

        loadStage();
    }, [data]);

    const loadKeynote = () => {
        if (keynote) {
            trackEvent(EVENT_TRACKING_TYPES.stage.endedAgendKeynote, {
                keynote: keynote.getId(),
                title: keynote.getTitle(),
                language: MyStore.getLocal() || '',
                muted: context.muted
            })
        }
        dispatch(
            fetchKeynote(exhibition.getId(), data.getPayload().getId())
        );
        trackEvent(EVENT_TRACKING_TYPES.stage.enteredAgendKeynote, {
            keynote: data.getPayload().getId(),
            title: data.getPayload().getTitle(),
            language: MyStore.getLocal() || '',
            muted: context.muted
        })
    };
    const loadStage = () => {
        dispatch(
            fetchStage(data.getPayload().getId())
        );
    };

    document.documentElement.style.overflow = videoMode === 2 ? "hidden" : "scroll";
    document.body.scroll = videoMode === 2 ? "no" : "yes";

    return !stage ? (<div />) : (
        <Box
            ref={containerRef}
            className={videoMode === 0 ? classes.videoBox : videoMode === 1 ? classes.videoBoxCinema : classes.videoBoxFull}
            bottom={isBottomButtonExists ? 102 : 32}
        >

            {videoMode === 2 ? (<FullVideoView stage={stage} keynote={keynote} exhibition={exhibition} />) : (keynote && !keynote.isRunning()) ? (
                <div
                    style={{
                        backgroundColor: "lightGrey",
                        width: "inherit",
                        height: "inherit",
                        borderRadius: "inherit"
                        //maxWidth:750,
                        //maxHeight: 750 * 0.5525
                    }}
                >
                    <img
                        src={
                            keynote.getImage() ? keynote.getImage().getUrl("large") : Logo
                        }
                        style={{
                            width: "inherit",
                            height: "inherit",
                            marginLeft: keynote.getImage() ? "0%" : "25%",
                            marginTop: keynote.getImage() ? "0%" : "12.5%",
                            objectFit: "cover",
                            borderRadius: "inherit"
                        }}
                    />
                </div>
            ) : stage.getStreamUrl(MyStore.getLocal()) ? (
                <div
                    style={{
                        backgroundColor: "lightGrey",
                        //maxWidth:750,
                        //maxHeight: 750 * 0.5525
                    }}
                    className={classes.inheritParent}
                >
                    <iframe
                        src={stage.getStreamUrl(MyStore.getLocal()) + (context.muted ? "?muted=1" : "")}
                        frameBorder="0"
                        allowFullScreen
                        allow="autoplay; fullscreen"
                        className={classes.inheritParent}
                    />
                </div>
            ) : (
                defaultVideoScreen()
            )}
            <Grid className={classes.iconButtonArea} container direction="row">
                {(<IconButton className={classes.iconButtonView} onClick={() => {
                    context.setMuted(!context.muted);
                }}>
                    {context.muted ? <VolumeOff /> : <VolumeUp />}
                </IconButton>)}
                {videoMode === 0 && (<IconButton className={classes.iconButtonView} onClick={() => {
                    setVideoMode(videoMode === 0 ? 2 : 0);
                }}>
                    <VideoLabelOutlined />
                </IconButton>)}
                {videoMode !== 0 && (
                    <IconButton className={classes.iconButtonClose} onClick={() => { setVideoMode(0) }}>
                        <Close />
                    </IconButton>
                )}
            </Grid>
        </Box>
    )
}

export default VideoBox
