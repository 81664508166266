import { useEffect, useState } from "react"
import { useSelector } from "react-redux"


const useConfigurationService = () => {

  const configurationsReducer = useSelector(state => state.configurationsReducer)
  const [config, setConfig] = useState()

  useEffect(() => {
    if(!configurationsReducer.configuration) { return }


    setConfig(configurationsReducer.configuration.state)
  }, [configurationsReducer.configuration])

  return [config]
}

export default useConfigurationService