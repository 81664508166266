import ApplicationModel from "./ApplicationModel";

export default class Comment extends ApplicationModel {
  constructor(_opts = {}) {
    super(_opts);
    this.state = { ..._opts };
  }

  getText = () => this.state.text;
  setText = text => (this.state.text = text);

  getUser = () => this.state.user;
  getUserId = () => this.state.userId;

  getPostId = () => this.state.postId;
  setPostId = (postId) => this.state.postId = postId;

  getLikesCount = () => this.state.likesCount;
  getLikedByUser = () => this.state.likedByUser || false;

  getLike = () => this.state.like
  setLike = (value) => this.state.like = value

  getExhibitionId = () => this.state.exhibitionId;
  setExhibitionId = (exhibitionId) => this.state.exhibitionId = exhibitionId;

  // Backend Attributes
  static attributes = {
    ...super.attributes,
    text: "text",
    user: "user",
    userId: "user_id",
    postId: "post_id",
    likesCount: "like_count",
    exhibitionId: "exhibition_id"
  };

  static orderByParams = {
    ...super.orderByParams
  };

  static filterParams = {
    ...super.filterParams,
    exhibitionId: "exhibition_id"
  };
}
