const chat_url = () => "/chat";
const chat_conversation_url = (id = ":id") => chat_url() + "/" + id;

const agenda_url = () => "/agenda";

const auth_scope = () => "/auth";
const login_url = () => auth_scope() + "/login";
const faq_url = () => auth_scope() + "/faq";
const register_url = () => auth_scope() + "/register";
const waiting_url = () => auth_scope() + "/waiting";
const terms_url = () => auth_scope() + "/terms";
const logout_url = () => auth_scope() + "/logout";
const passwort_reset_url = () => auth_scope() + "/reset";

const landing_url = () => auth_scope() + "/landing";
const alt_landing_url = () => "/landing";

const root_url = () => "/";

const error_scope = () => "/errors";
const not_found_url = () => error_scope() + "/404";
const server_error_url = () => error_scope() + "/500";

const exhibitors_url = () => "/exhibitors";
const exhibitor_url = (id = ":id") => "?exhibitor=" + id;

const meetings_url = () => "/meetings";
const meeting_url = (id = ":id") => meetings_url() + "/" + id;

const workshops_url = () => "/workshops";
const workshop_url = (id = ":id") => workshops_url() + "/" + id;
const workshop_stream_url = (id = ":id") => workshops_url(id) + "/stream";

const profiles_url = () => "/profiles";
const profile_url = (id = ":id") => profiles_url() + "/" + id;

const user_profiles = () => "/users";
const user_profile = (id = ":id") => user_profiles() + "/" + id;

const visitors_url = () => "/visitors";
const visitor_url = (id = ":id") => visitors_url() + "/" + id;

const bookmarks_url = () => "/bookmarks";
const bookmark_url = (id = ":id") => bookmarks_url() + "/" + id;

const posts_url = () => "/posts";
const post_url = (id = ":id") => posts_url() + "/" + id;

export default {
  //chat: () => chat_url(),
  root: () => root_url(),
  faq: () => faq_url(),
  login: () => login_url(),
  landing: () => landing_url(),
  agenda: () => agenda_url(),
  register: () => register_url(),
  reset: () => passwort_reset_url(),

  auth: {
    scope: () => auth_scope(),
    faq: () => faq_url(),
    login: () => login_url(),
    landing: () => landing_url(),
    waiting: () => waiting_url(),
    terms: () => terms_url(),
    logout: () => logout_url(),
    register: {
      root: () => register_url(),
      welcome: () => register_url() + "/signup",
      password: () => register_url() + "/password",
      confirm: () => register_url() + "/confirm",
      tags: () => register_url() + "/tags",
      exhibitors: () => register_url() + "/exhibitors",
      profile_media: () => register_url() + "/profilemedia",
      limitReached: () => register_url() + "/limitReached",
    },
    reset: () => passwort_reset_url(),
    alt_landing: () => alt_landing_url(),
  },

  errors: {
    scope: () => error_scope(),
    notFound: () => not_found_url(),
    serverError: () => server_error_url(),
  },

  exhibitors: {
    all: () => exhibitors_url(),
    show: (id = ":id") => exhibitor_url(id),
  },

  workshops: {
    all: () => workshops_url(),
    show: (id = ":id") => workshop_url(id),

    stream: (id = ":id") => workshop_stream_url(id),
  },

  meetings: {
    all: () => meetings_url(),
    show: (id = ":id") => meeting_url(id),
  },

  visitors: {
    all: () => visitors_url(),
    show: (id = ":id") => visitor_url(id),
  },

  profiles: {
    all: () => profiles_url(),
    show: (id = ":id") => profile_url(id),
  },

  bookmarks: {
    all: () => bookmarks_url(),
    show: (id = ":id") => bookmark_url(id),
  },

  posts: {
    all: () => posts_url(),
    show: (id = ":id") => post_url(id),
  },
  users: {
    show: (id = ":id") => user_profile(id),
  },
  chat: {
    all: () => chat_url(),
    show: (id = ":id") => chat_conversation_url(id),
  },
};
