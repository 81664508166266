import React, { useState, useRef, useEffect } from 'react'
import MessageBox from "../../views/ChatView/Message"

function ThreadDetails({ loading, messages, loadMore, hasPrevPage }) {
  const messagesRef = useRef();
  const [startUp, setStartUp] = useState(true);
  const [autoScroll, setAutoScroll] = useState(true);

  function scrollMessagesToBottom() {
    if (messagesRef.current) {
      // eslint-disable-next-line no-underscore-dangle
      messagesRef.current.scrollTop = messagesRef.current.scrollHeight;
      setStartUp(false);
    }
  }

  useEffect(() => {
    if (!messagesRef.current) {
      return;
    }

    messagesRef.current.addEventListener('scroll', handleScroll);

    return () => {
      messagesRef.current.removeEventListener('scroll', handleScroll)
    }

  }, [messagesRef.current])

  const handleScroll = () => {
    if (startUp || loading || messagesRef.current.scrollTop > 0) {
      return;
    }
    setAutoScroll(false);
    loadMore();
  }

  useEffect(() => {
    if (messagesRef.current.scrollHeight > 2844 && hasPrevPage && messages.length > 0) {
      messagesRef.current.scrollTop = messagesRef.current.scrollHeight / 2;
    }
    if (!autoScroll) {
      return;
    }
    scrollMessagesToBottom();
    // eslint-disable-next-line
  }, [messages]);

  return (
    <div
      ref={messagesRef}
      style={{ overflowY: "scroll", height: "100%", padding: "12px" }}
    >
      {/* {loading && <LinearProgress />} */}
      {messages.map((message) => (
        <MessageBox
          key={message.id}
          message={message}
          messagesRef={messagesRef}
        />
      ))}
    </div>
  );
}

export default ThreadDetails;
