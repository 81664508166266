import ApplicationModel from "./ApplicationModel";
import MyStore from "../utils/MyStore";
import { compareIds } from "../utils";

export default class Conversation extends ApplicationModel {
  constructor(_opts = {}) {
    super(_opts);
    this.state = { ..._opts };
  }

  getName = () => this.state.name;
  getTitle = () => this.state.title;
  
  getUserIds = () => this.state.userIds
  setUserIds = (userIds) => this.state.userIds = userIds;

  getOtherUser = () => {
    if (!this.state.otherUser) {
      return undefined
    }
    const otherUser = 
      compareIds(this.state.otherUser.getId(), MyStore.getCurrentUser().getId()) ? 
        this.state.user:
        this.state.otherUser
    return otherUser
  };
  getLastMessage = () => this.state.lastMessage;
  getUnreadMessagesCount = () => this.state.unreadMessagesCount;
  setUnreadMessagesCount = (val) => this.state.unreadMessagesCount = val

  getExhibitionId = () => this.state.exhibitionId
  getUser = () => this.state.user

  // Backend Attributes
  static attributes = {
    ...super.attributes,
    name: "name",
    title: "title",
    otherUser: "otherUser",
    unreadMessagesCount: "unread_messages_count",
    usersCount: "users_count",
    lastMessage: "last_message",
    exhibitionId: "exhibition_id",
    user: "user"
  };

  static orderByParams = {
    ...super.orderByParams
  };

  static filterParams = {
    ...super.filterParams,
    userIds: 'user_ids',
    userId: 'user_id',
    superuser: 'superuser',
    standalone: 'standalone',
    name: this.attributes.name,
    search: "_q",
  };
}
