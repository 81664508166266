import {
  FETCHING_EXHIBITION_EXHIBITORS_REQUEST,
  FETCHING_EXHIBITION_EXHIBITORS_SUCCESS,
  FETCHING_EXHIBITION_EXHIBITORS_FAILURE,

  FETCHING_EXHIBITION_EXHIBITOR_REQUEST,
  FETCHING_EXHIBITION_EXHIBITOR_SUCCESS,
  FETCHING_EXHIBITION_EXHIBITOR_FAILURE,

  CREATE_EXHIBITION_EXHIBITOR_REQUEST,
  CREATE_EXHIBITION_EXHIBITOR_SUCCESS,
  CREATE_EXHIBITION_EXHIBITOR_FAILURE,

  UPDATE_EXHIBITION_EXHIBITOR_REQUEST,
  UPDATE_EXHIBITION_EXHIBITOR_SUCCESS,
  UPDATE_EXHIBITION_EXHIBITOR_FAILURE,

  DELETE_EXHIBITION_EXHIBITOR_REQUEST,
  DELETE_EXHIBITION_EXHIBITOR_SUCCESS,
  DELETE_EXHIBITION_EXHIBITOR_FAILURE,

} from './types';
import Urls, { DEFAULT_OPTIONAL_PARAMETERS } from '../../constants/Urls';
import { BEARER } from '../../utils/MyStore';

import ExhibitorMapper from '../../mapper/ExhibitorMapper';
import ApplicationError from '../../utils/ApplicationError';
import ResponseService from '../../utils/ResponseService';
import ErrorLogger from '../../utils/ErrorLogger';

export const fetchingExhibitorsRequest = () => ({
  type: FETCHING_EXHIBITION_EXHIBITORS_REQUEST
})

export const fetchingExhibitorsSuccess = (json) => ({
  type: FETCHING_EXHIBITION_EXHIBITORS_SUCCESS,
  payload: json
})
export const fetchingExhibitorsFailure = (error) => ({
  type: FETCHING_EXHIBITION_EXHIBITORS_FAILURE,
  payload: error
})

export const fetchingExhibitorRequest = () => ({
  type: FETCHING_EXHIBITION_EXHIBITOR_REQUEST
})

export const fetchingExhibitorSuccess = (exhibitor) => ({
  type: FETCHING_EXHIBITION_EXHIBITOR_SUCCESS,
  payload: exhibitor
})
export const fetchingExhibitorFailure = (error) => ({
  type: FETCHING_EXHIBITION_EXHIBITOR_FAILURE,
  payload: error
})

export const createExhibitorRequest = () => ({
  type: CREATE_EXHIBITION_EXHIBITOR_REQUEST
})

export const createExhibitorSuccess = (exhibitor) => ({
  type: CREATE_EXHIBITION_EXHIBITOR_SUCCESS,
  payload: exhibitor
})

export const createExhibitorFailure = (error) => ({
  type: CREATE_EXHIBITION_EXHIBITOR_FAILURE,
  payload: error
})

export const updateExhibitorRequest = () => ({
  type: UPDATE_EXHIBITION_EXHIBITOR_REQUEST
})

export const updateExhibitorSuccess = (exhibitor) => ({
  type: UPDATE_EXHIBITION_EXHIBITOR_SUCCESS,
  payload: exhibitor
})

export const updateExhibitorFailure = (error) => ({
  type: UPDATE_EXHIBITION_EXHIBITOR_FAILURE,
  payload: error
})

export const deleteExhibitorRequest = () => ({
  type: DELETE_EXHIBITION_EXHIBITOR_REQUEST
})

export const deleteExhibitorSuccess = () => ({
  type: DELETE_EXHIBITION_EXHIBITOR_SUCCESS
})

export const deleteExhibitorFailure = (error) => ({
  type: DELETE_EXHIBITION_EXHIBITOR_FAILURE,
  payload: error
})

export const fetchExhibitors = (exhibitionId, _opts = DEFAULT_OPTIONAL_PARAMETERS) => {
  return async dispatch => {
    dispatch(fetchingExhibitorsRequest());
    try {

      const route   = Urls.exhibitions.exhibitors.all
      const bearer  = localStorage.getItem(BEARER)
      const headers = Urls.header.secure(bearer)
      const url     = Urls.build(route.url(exhibitionId, _opts.pageIndex, _opts.pageSize), _opts.filters)

      let response = await fetch(url, {
        method: route.method,
        headers: headers
      });

      let responseService = new ResponseService(ExhibitorMapper)

      let action = await responseService.call(response, 
        (value) => fetchingExhibitorsSuccess(value), 
        (error) => fetchingExhibitorsFailure(error))

      dispatch(action)
    } catch(error) {
      ErrorLogger.call(this, error)
      dispatch(fetchingExhibitorsFailure(error))
    }
  };
};

export const fetchExhibitor = (exhibitionId, id) => {
  return async dispatch => {
    dispatch(fetchingExhibitorRequest());

    try {
      const route = Urls.exhibitions.exhibitors.show;
      const bearer = localStorage.getItem(BEARER);
      const headers = Urls.header.secure(bearer);

      let response = await fetch(route.url(exhibitionId, id), {
        method: route.method,
        headers: headers
      })

      let responseService = new ResponseService(ExhibitorMapper)
      let action = await responseService.call(response, 
        (value) => fetchingExhibitorSuccess(value), 
        (error) => fetchingExhibitorFailure(error))

      dispatch(action)
    } catch(error) {
      ErrorLogger.call(this, error)
      dispatch(fetchingExhibitorFailure(error))
    }
  }
}

export const createExhibitor = (exhibitor) => {
  return async dispatch => {
    dispatch(createExhibitorRequest());
    try {

      const route = Urls.exhibitions.exhibitors.create
      const bearer = localStorage.getItem(BEARER)
      const headers = Urls.header.secure(bearer)
      const url = Urls.build(route.url(exhibitor.getExhibitionId(), exhibitor.getId()))

      let response = await fetch(url, {
        method: route.method,
        headers: headers,
        body: ExhibitorMapper.toJsonString(exhibitor)
      });

      let responseService = new ResponseService(ExhibitorMapper)

      let action = await responseService.call(response, 
        (value) => createExhibitorSuccess(value),
        (error) => createExhibitorFailure(error))

      dispatch(action)  

    } catch(error) {
      ErrorLogger.call(this, error)
      dispatch(createExhibitorFailure(ApplicationError.buildApplicationError(error)))
    }
  };
};

export const bookmarkExhibitor = (exhibitor) => {
  return async dispatch => {
    dispatch(createExhibitorRequest());
    try {

      const route = Urls.exhibitions.exhibitors.bookmark
      const bearer = localStorage.getItem(BEARER)
      const headers = Urls.header.secure(bearer)
      const url = Urls.build(route.url(exhibitor.getExhibitionId(), exhibitor.getId()))

      let response = await fetch(url, {
        method: route.method,
        headers: headers,
      });

      let responseService = new ResponseService(ExhibitorMapper)

      let action = await responseService.call(response, 
        (value) => createExhibitorSuccess(value),
        (error) => createExhibitorFailure(error))

      dispatch(action)  

    } catch(error) {
      ErrorLogger.call(this, error)
      dispatch(createExhibitorFailure(ApplicationError.buildApplicationError(error)))
    }
  };
};
export const salesmarkExhibitor = (exhibitor) => {
  return async dispatch => {
    dispatch(createExhibitorRequest());
    try {

      const route = Urls.exhibitions.exhibitors.salesmark
      const bearer = localStorage.getItem(BEARER)
      const headers = Urls.header.secure(bearer)
      const url = Urls.build(route.url(exhibitor.getExhibitionId(), exhibitor.getId()))

      let response = await fetch(url, {
        method: route.method,
        headers: headers,
      });

      let responseService = new ResponseService(ExhibitorMapper)

      let action = await responseService.call(response, 
        (value) => createExhibitorSuccess(value),
        (error) => createExhibitorFailure(error))

      dispatch(action)  

    } catch(error) {
      ErrorLogger.call(this, error)
      dispatch(createExhibitorFailure(ApplicationError.buildApplicationError(error)))
    }
  };
};

export const updateExhibitor = (exhibitor) => {
  return async dispatch => {
    dispatch(updateExhibitorRequest());
    try {

      const route   = Urls.exhibitions.exhibitors.update
      const bearer  = localStorage.getItem(BEARER)
      const headers = Urls.header.secure(bearer)
      const url     = Urls.build(route.url(exhibitor.getExhibitionId(), exhibitor.getId()))

      let response = await fetch(url, {
        method: route.method,
        headers: headers,
        body: ExhibitorMapper.toJsonString(exhibitor)
      });

      let responseService = new ResponseService(ExhibitorMapper)

      let action = await responseService.call(response, 
        (value) => updateExhibitorSuccess(value),
        (error) => updateExhibitorFailure(error))

      dispatch(action)  
    } catch(error) {
      ErrorLogger.call(this, error)
      dispatch(updateExhibitorFailure(ApplicationError.buildApplicationError(error)))
    }
  };
};

export const deleteExhibitor = (exhibitor) => {
  return async dispatch => {
    dispatch(deleteExhibitorRequest());
    try {

      const route = Urls.exhibitions.exhibitors.delete
      const bearer = localStorage.getItem(BEARER)
      const headers = Urls.header.secure(bearer)
      const url = Urls.build(route.url(exhibitor.getExhibitionId(), exhibitor.getId()))

      let response = await fetch(url, {
        method: route.method,
        headers: headers
      });

      let responseService = new ResponseService(ExhibitorMapper)

      let action = await responseService.call(response, 
        (value) => deleteExhibitorSuccess(value),
        (error) => deleteExhibitorFailure(error))

      dispatch(action)  
    } catch(error) {
      ErrorLogger.call(this, error)
      dispatch(deleteExhibitorFailure(ApplicationError.buildApplicationError(error)))
    }
  };
};