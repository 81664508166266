import PostsLike from "../models/PostsLike";
import BaseMapper from "./BaseMapper";
import ErrorLogger from "../utils/ErrorLogger";

export default class PostsLikeMapper {
  static build = (json) => {
    if (!json) {
      return;
    }

    try {
      return new PostsLike({
        ...BaseMapper.attributes(json),
        userId: json.user_id,
        postId: json.post_id
      });
    } catch (error) {
      ErrorLogger.call(this, error);
    }
  };

  static buildFromArray = (jsonArray = []) => {
    return jsonArray.map((entry) => {
      return this.build(entry);
    });
  };
}
