import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { Button, Container, Grid, TextField } from "@material-ui/core";
import { Trans, useTranslation } from "react-i18next";
import { updateUser } from "../../../redux/actions/UsersActions";
import CogoToast from "../../../utils/CogoToast";
import { MIN_PASSWORD_LENGTH } from "../../../constants/Urls";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "grid",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    overflow: "auto",
    maxHeight: window.innerHeight * 0.7,
    // maxHeight: "1000px",
    // maxWidth: "800px",
  },
  timeSlot: {
    width: 200,
  },
  timeSlotGrid: {
    marginTop: "16px",
    overflow: "auto",
    maxHeight: "500px",
    padding: "2vh",
  },
  timeSlotCard: {
    display: "flex",
    justifyContent: "center",
    padding: "16px",
  },
  selectedTimeSlotCard: {
    display: "flex",
    justifyContent: "center",
    padding: "16px",
    backgroundColor: "lightBlue",
  },
  phantom: {
    display: "block",
    padding: "20px",
    height: "60px",
    width: "100%",
    marginTop: "1vh",
  },
  avatarItem: {
    textAlign: "center",
  },
  avatarCard: {
    justifyContent: "center",
    padding: "16px",
    flexGrow: "1",
  },
  selectedAvatarCard: {
    justifyContent: "center",
    padding: "16px",
    backgroundColor: "lightBlue",
  },
  avatar: {
    width: theme.spacing(11),
    height: theme.spacing(11),
  },
  aboutAuthor: {
    marginTop: theme.spacing(3),
  },
  aboutProject: {
    marginTop: theme.spacing(3),
  },
  projectCover: {
    marginTop: theme.spacing(3),
  },
  projectDetails: {
    marginTop: theme.spacing(3),
  },
  preferences: {
    marginTop: theme.spacing(3),
  },
  actions: {
    marginTop: theme.spacing(3),
  },
}));

export default function ChangePasswordModal({ open, handleClose }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const users = useSelector((state) => state.usersReducer);

  const [saved, setSaved] = useState(false)
  const [oldPassword, setOldPassword] = useState();
  const [newPassword, setNewPassword] = useState();
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState();

  useEffect(() => {
    if (!open) {
      return;
    }
  }, [open]);

  const handleChangePassword = () => {
    if(oldPassword === undefined || oldPassword.length === 0) { CogoToast.error("Please set old password!"); return }

    if(newPassword !== newPasswordConfirmation || newPassword === undefined) { CogoToast.error(t('frontend.pages.login.pass_no_match')); return }

    if(newPassword.length < MIN_PASSWORD_LENGTH) { CogoToast.error(t("frontend.pages.login.pass_length_error")); return }

    let user = users.currentUser;

    user.setOldPassword(oldPassword);
    user.setPassword(newPassword);
    user.setPasswordConfirmation(newPasswordConfirmation);

    dispatch(updateUser(user));
    setSaved(true)
  };

  useEffect(() => {
    if (users.isFetching || !saved) {
      return;
    }

    if (users.error.isError()) {
      CogoToast.error(users.error.getMessage());
    } else {
      CogoToast.success("Passwort geändert");
      handleClose();
    }
    setSaved(false)
  }, [users.isFetching]);

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Container className={classes.paper}>
            <div className={classes.actions}>
            <h1><Trans>frontend.change_password</Trans></h1>
              <Grid item container direction="column" xs={9}>
                <Grid item style={{ marginLeft: "1.5em" }}>
                  <Grid container item direction="row">
                    <TextField
                      required
                      name="oldPassword"
                      type="password"
                      label={t("frontend.old_password")}
                      onChange={(e) => setOldPassword(e.target.value)}
                      InputProps={{
                        style: { fontSize: "1.3em", fontWeight: "normal" },
                      }}
                      style={{ marginRight: "2px" }}
                    />
                  </Grid>
                  <Grid container item direction="row">
                    <TextField
                      required
                      type="password"
                      name="newPassword"
                      label={t("frontend.new_password")}
                      password
                      onChange={(e) => setNewPassword(e.target.value)}
                      InputProps={{
                        style: { fontSize: "1.3em", fontWeight: "normal" },
                      }}
                      style={{ marginRight: "2px" }}
                    />
                  </Grid>
                  <Grid container item direction="row" style={{marginBottom: 20}}>
                    <TextField
                      required
                      type="password"
                      name="newPasswordConfirmation"
                      label={t("frontend.new_password_confirmation")}
                      password
                      onChange={(e) =>
                        setNewPasswordConfirmation(e.target.value)
                      }
                      InputProps={{
                        style: { fontSize: "1.3em", fontWeight: "normal" },
                      }}
                      style={{ marginRight: "2px" }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid container direction="row">
                <Button
                  color="primary"
                  variant="contained"
                  onClick={handleChangePassword}
                >
                  <Trans>frontend.change_password</Trans>
                </Button>
                <Button
                  color="default"
                  variant="contained"
                  onClick={() => handleClose()}
                  style={{ marginLeft: "auto" }}
                >
                  <Trans>frontend.cancel</Trans>
                </Button>
              </Grid>
            </div>
          </Container>
        </Fade>
      </Modal>
    </div>
  );
}
