import { Box, Divider, List, ListItem, Typography } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/styles";
import { ArrowDownward } from "@material-ui/icons";
import React, { useContext, useRef } from "react";
import { Trans, useTranslation } from "react-i18next";
import clsx from 'clsx';
import NeumorphicStyle from "../../../../../constants/Neumorphic";
import GlobalContext from "../../context/GlobalContext";
import TalkingPointElement from "./TalkingPointElement";
import useOnScreen from '../../../../engines/GeneralHooks/useOnScreen';
import useBreakpoints from "../../../../../engines/Util/useBreakpoints";
import useTracking, { VISIT_TRACKING_TYPES } from "../../../../engines/tracking/useTracking";
import MyStore from "../../../../../utils/MyStore";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "inherit",
  },
  listRoot: {
    maxHeight: "inherit",
    position: "relative",
    overflow: "auto",
  },
  more: {
    position: "sticky",
    top: "95%",
    color: "black",
    zIndex: 500,
    width: "24px",
    height: "24px",
    background: "rgba(255,255,255,0.6)",
    marginLeft: "auto",
    marginRight: "auto",
    borderRadius: "12px",
  },  
}));

function TalkingPointList({ talkingPointList, isButtonExists = false }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const { setTalkingPointId, setTabIndex } = useContext(GlobalContext);
  const ref = useRef()
  const isVisible = useOnScreen(ref)
  const [trackEvent,trackVisit] = useTracking();

  return (
    <Box pr={2} pb={2} pl={2} className={clsx(classes.root, isButtonExists ? classes.responsiveRoot : '')}>
      {!isVisible && (
        <Box className={classes.more}>
          <ArrowDownward />
        </Box>
      )}
      <Box
        height={30}
        alignItems="center"
        justifyContent="center"
        display="flex"
        mb={2}
      >
        <Typography variant="h3">
          <Trans>frontend.components.talking_point_list.header</Trans>
        </Typography>
      </Box>
      <Divider />
      <List
        component="nav"
        classes={{ root: clsx(classes.listRoot, isButtonExists ? classes.responsiveListRoot : '') }}
      >
        {talkingPointList &&
          talkingPointList.map((talkingPoint) => (
            <ListItem>
              <TalkingPointElement
                talkingPointElementId={talkingPoint.getId()}
                primaryColor={talkingPoint.getPrimaryColor()}
                fontColor={talkingPoint.getFontColor()}
                highlightColor={talkingPoint.getHighlightColor()}
                fontHighlightColor={talkingPoint.getFontHighlightColor()}
                label={talkingPoint.getName(MyStore.getLocal())}
                onClick={() => {
                  setTabIndex(1); // Dashboard/index TAB_DEFINITION
                  setTalkingPointId(talkingPoint.getId());
                  trackVisit(VISIT_TRACKING_TYPES.talkingPointOpened, {
                    exhibitorHall: talkingPoint.getId(),
                    exhibitorHallName: talkingPoint.getName(MyStore.getLocal()),
                  });
                }}
              />
            </ListItem>
          ))}

        {/* All */}

        <ListItem>
          <TalkingPointElement
            talkingPointElementId={undefined}
            primaryColor={"#F1D6A1"}
            highlightColor={"#C0A97D"}
            fontColor={theme.palette.text.primary}
            fontHighlightColor={theme.palette.secondary.contrastText}
            label={t("pages.dashboard.allTalkingPointsButton")}
            onClick={() => {
              setTabIndex(1); // Dashboard/index TAB_DEFINITION
              setTalkingPointId(undefined);
            }}
          />
        </ListItem>
        <Box ref={ref} height={2} opacity={0} />
      </List>
    </Box>
  );
}

export default TalkingPointList;
