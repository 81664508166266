import {
  ADD_ACTION_CABLE,
  REMOVE_ACTION_CABLE,

  SET_CABLE_STATUS
} from "./types";

export const addActionCable = (cable) => ({
  type: ADD_ACTION_CABLE,
  payload: cable
});

export const setCableStatus = (status) => ({
  type: SET_CABLE_STATUS,
  payload: status
})

export const removeActionCable = () => ({
  type: REMOVE_ACTION_CABLE
});