import React from 'react'
import { ListItem, ListItemAvatar, IconButton, ListItemSecondaryAction, ListItemText, Avatar } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles';
import { Link as RouterLink } from 'react-router-dom';
import FrontendRoutes from "../../../constants/FrontendRoutes";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import ShareIcon from '@material-ui/icons/Share';
import OndemandVideoIcon from '@material-ui/icons/OndemandVideo';
import InsertInvitationIcon from '@material-ui/icons/InsertInvitation';
import DoneIcon from '@material-ui/icons/Done';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import MyStore from '../../../utils/MyStore';
import MeetingsUser from '../../../models/MeetingsUser';
import { updateMeetingsUser } from "../../../redux/actions/MeetingsUsersActions"
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
    root: {},
    listItem: {
      '&:hover': {
        backgroundColor: theme.palette.background.default
      }
    },
    avatarBlue: {
      backgroundColor: "blue"
    },
    avatarGreen: {
        backgroundColor: "green"
    },
    avatarOrange: {
        backgroundColor: "orange"
    },
    avatarIndigo: {
        backgroundColor: "indigo"
    },
    avatarRed: {
        backgroundColor: "red"
    },
}));

function MeetingRequest({meeting, payload}) {
    const classes = useStyles()
    const { t } = useTranslation();
    const dispatch = useDispatch();
    let user = MyStore.getCurrentUser();
    let updatedMeeting = meeting.getMeetingsUsers().find((meetingsUser) => meetingsUser.getUserId() === user.getId());
    
    const handleAcceptMeeting = () => {    
        if (!updatedMeeting) {
            return
        }
        updatedMeeting.setStatus(MeetingsUser.status.ACCEPTED);

        dispatch(updateMeetingsUser(payload.getExhibitionId(),meeting.getId(),updatedMeeting));
    }

    const handleDeclineMeeting = () => {
        if (!updatedMeeting) {
            return
        }
        updatedMeeting.setStatus(MeetingsUser.status.DECLINED);

        dispatch(updateMeetingsUser(payload.getExhibitionId(),meeting.getId(),updatedMeeting));
    }

    return (
        <ListItem
            className={classes.listItem}
            component={RouterLink}
            key={meeting.getCreatedAt()}
            to={FrontendRoutes.meetings.all() + "?tab=requests"}
        >
        <ListItemAvatar>
        {   
            <Avatar className={classes.avatarIndigo}>
                <InsertInvitationIcon />
            </Avatar>
        }
        </ListItemAvatar>
        <ListItemText
            primary={t("frontend.notifications.meeting.request") + payload.getOtherUser().getFullName() + ", " + new Date(meeting.getStartAt()).toLocaleString()}
            primaryTypographyProps={{ variant: 'body1' }}
            secondary={moment(meeting.getRawCreatedAt()).fromNow()}
        />
        {updatedMeeting && updatedMeeting.getStatus() === MeetingsUser.status.REQUESTED && (
            <ListItemSecondaryAction>
                <IconButton
                    color="primary"
                    onClick={() => handleAcceptMeeting()}
                >
                    <DoneIcon />
                </IconButton>
            </ListItemSecondaryAction>
        )}
    </ListItem>
    )}

export default MeetingRequest
