import Bookmark from "../models/Bookmark";
import BaseMapper from "./BaseMapper";
import UserMapper from "./UserMapper";
import KeynoteMapper from './KeynoteMapper';
import PostMapper from './PostMapper';
import ExhibitorMapper from './ExhibitorMapper'
import ErrorLogger from "../utils/ErrorLogger";

const attributes = Bookmark.attributes;

export default class BookmarkMapper {
  static build = (json) => {
    if (!json || !json.id) {
      return;
    }

    const bm = new Bookmark({
      ...BaseMapper.attributes(json),
      exhibitionId: json.exhibition ? json.exhibition.id:undefined,
      bookmarkableType: json[attributes.bookmarkableType],
      bookmarkableId: json[attributes.bookmarkableId],
    });

    bm.setBookmarkable(this.buildBookmarkable(json,bm));
    bm.state.bookmarkable.setBookmark(bm);
    
    return bm;
  };

  static buildBookmarkable = (json, bm) => {
    if(!json) { return }

    let mapper = undefined;
    switch (json[attributes.bookmarkableType]) {
      case "User":
        mapper = UserMapper;
        break;
      case "Post":
        mapper = PostMapper;
        break;
      case "Exhibitor":
        mapper = ExhibitorMapper;
        break;
      case "Keynote":
        mapper = KeynoteMapper;
        break;
      default:
        ErrorLogger.call(this, "BookmarkMapper::BuildBookmarkable: Es wurde kein Mapper definiert! / " + json[attributes.bookmarkableType])
        break;
    }
    return mapper.build(json[attributes.bookmarkable],bm);
  }

  static buildFromArray = (jsonArray = []) => {
    if (!jsonArray) {
      return
    }
    return jsonArray.map((entry) => {
      return this.build(entry);
    });
  };

  static async toJsonString(bookmark) {
    return JSON.stringify({
      [attributes.exhibitionId]: bookmark.getExhibitionId(),
      [attributes.bookmarkableId]: bookmark.getBookmarkableId(),
      [attributes.bookmarkableType]: bookmark.getBookmarkableType(),
    });
  }
}
