import i18n from "../config/i18n";
import FilterParams from "../utils/FilterParams";

require("dotenv").config();

const GET = "GET";
const POST = "POST";
const DELETE = "DELETE";
const PUT = "PUT";

export const DEFAULT_PAGE_INDEX = 1;
export const DEFAULT_PAGE_SIZE = 10;
export const MAX_PAGE_SIZE = 1000;

export const MIN_PASSWORD_LENGTH = 8;

export const DEFAULT_OPTIONAL_PARAMETERS = {
  pageSize: DEFAULT_PAGE_SIZE,
  pageIndex: DEFAULT_PAGE_INDEX,
  filters: [],
};

const server_url =
  (process.env.REACT_APP_WEB_PROTOCOL || "https://") +
  process.env.REACT_APP_API_URL +
  "/";
const websocket_server_url =
  (process.env.REACT_APP_WEBSOCKET_PROTOCOL || "wss://") +
  process.env.REACT_APP_API_URL;

const scope = "/api/v1/";

const default_url = server_url; //+ scope
const graph_url = default_url + "graphql"; //+ scope
const content_type = "application/json";
const login_url = default_url + "auth/local";
const member_login_url = default_url + "auth/local/member";
const sendConfirmation_url = default_url + "auth/send-email-confirmation";
const logout_url = default_url + "logout";
const logincount_url = default_url + "logincount";
const logoutcount_url = default_url + "logoutcount";

const registration_url = default_url + "tickets_sign_up";
const signup_url = (ticketCode) =>
  default_url + "signup" + (ticketCode ? "?code=" + ticketCode : "");
const check_mail_url = default_url + "tickets/check";
const landingpagesignup_url = default_url + "landingpagesignup";

const exhibition_filter = (exhibition_id) => "?exhibition.id=" + exhibition_id;

const users_url = () => default_url + "users";
const users_eventuser_url = () => default_url + "users/eventuser";
const user_url = (id) => default_url + "users/" + id;
const user_exhibitors_url = () => default_url + "users/exhibitors";
const eventuser_url = (id) => default_url + "eventusers/" + id;
const eventuser_uuid_url = (id) => default_url + "eventusers/uuid/" + id;

const changeLocale_url = (locale) =>
  default_url + "changeLocale?locale=" + locale;

const configuration_url = () => default_url + "configurations";

const reset_password_url = () => default_url + "auth/reset-password";
const forgot_password_url = () => default_url + "auth/forgot-password";

const system_roles_url = () => default_url + "system_roles";
const system_role_url = (id) => system_roles_url() + "/" + id;

const stages_url = () => default_url + "stages";
const stage_url = (id) => stages_url() + "/" + id;

const system_roles_users_url = (systemRoleId) =>
  system_role_url(systemRoleId) + "/system_roles_users";
const system_roles_user_url = (systemRoleId, userId) =>
  system_roles_users_url(systemRoleId) + "/" + userId;

const user_tickets_url = (userId) => user_url(userId) + "/tickets";
const user_ticket_url = (userId, id) => user_tickets_url(userId) + "/" + id;

const user_images_url = (id) => user_url(id) + "/images";
const user_image_url = (userId, id) => user_images_url(userId) + "/" + id;

const exhibitions_url = () => default_url + "events";
const exhibition_url = (id) => exhibitions_url() + "/" + id;

const exhibition_bookmarks_url = (exhibitionId) => default_url + "bookmarks";
const exhibition_bookmark_url = (exhibitionId, id) =>
  exhibition_bookmarks_url(exhibitionId) + "/" + id;

const exhibition_visitors_url = (exhibitionId) => default_url + "eventusers";
const exhibition_visitor_url = (exhibitionId, id) =>
  exhibition_visitors_url(exhibitionId) + "/" + id;

const exhibition_tickets_url = (exhibitionId) => default_url + "tickets";
const exhibition_ticket_url = (exhibitionId, id) =>
  exhibition_tickets_url(exhibitionId) + "/" + id;

const exhibition_statistics_url = (exhibitionId) => default_url + "statistics";

const exhibition_timeslots_url = (exhibitionId) => default_url + "timeslots";

const exhibition_keynotes_url = (exhibitionId) => default_url + "keynotes";
const exhibition_keynote_url = (exhibitionId, id) =>
  exhibition_keynotes_url(exhibitionId) + "/" + id;

const exhibition_thematicAreas_url = (exhibitionId) =>
  default_url + "thematic_areas";
const exhibition_thematicArea_url = (exhibitionId, id) =>
  exhibition_thematicAreas_url(exhibitionId) + "/" + id;

const exhibition_agenda_entries_url = (exhibitionId) =>
  default_url + "agenda_entries";
const exhibition_agenda_entry_url = (exhibitionId, id) =>
  exhibition_agenda_entries_url(exhibitionId) + "/" + id;

const exhibition_conversations_url = (exhibitionId) =>
  default_url + "conversations";
const exhibition_conversation_url = (exhibitionId, id) =>
  exhibition_conversations_url(exhibitionId) + "/" + id;

const exhibition_conversations_users_url = (exhibitionId, conversationId) =>
  exhibition_conversation_url(exhibitionId, conversationId) + "/users";
const exhibition_conversations_user_url = (exhibitionId, conversationId, id) =>
  exhibition_conversations_users_url(exhibitionId, conversationId) + "/" + id;

const exhibition_messages_url = (exhibitionId) => default_url + "messages";
const exhibition_message_url = (exhibitionId, id) =>
  exhibition_messages_url(exhibitionId) + "/" + id;

const exhibition_keynotes_speakers_url = (exhibitionId, keynoteId) =>
  exhibition_keynote_url(exhibitionId, keynoteId) + "/keynotes_speakers";
const exhibition_keynotes_speaker_url = (exhibitionId, keynoteId, userId) =>
  exhibition_keynotes_speakers_url(exhibitionId, keynoteId) + "/" + userId;

const exhibition_keynotes_moderators_url = (exhibitionId, keynoteId) =>
  exhibition_keynote_url(exhibitionId, keynoteId) + "/keynotes_moderators";
const exhibition_keynotes_moderator_url = (exhibitionId, keynoteId, userId) =>
  exhibition_keynotes_moderators_url(exhibitionId, keynoteId) + "/" + userId;

const exhibition_keynotes_users_url = (exhibitionId, keynoteId) =>
  exhibition_keynote_url(exhibitionId, keynoteId) + "/keynotes_users";
const exhibition_keynotes_user_url = (exhibitionId, keynoteId, userId) =>
  exhibition_keynotes_users_url(exhibitionId, keynoteId) + "/" + userId;

const exhibition_exhibitors_url = (exhibitionId) => default_url + "exhibitors";
const exhibition_exhibitor_url = (exhibitionId, id) =>
  exhibition_exhibitors_url(exhibitionId) + "/" + id;

const exhibition_exhibitor_images_url = (exhibitionId, id) =>
  exhibition_exhibitor_url(exhibitionId, id) + "/images";
const exhibition_exhibitor_image_url = (exhibitionId, exhibitorId, id) =>
  exhibition_exhibitor_images_url(exhibitionId, exhibitorId) + "/" + id;

const exhibition_exhibitors_users_url = (exhibitionId, exhibitorId) =>
  exhibition_exhibitor_url(exhibitionId, exhibitorId) + "/users";
const exhibition_exhibitors_user_url = (exhibitionId, exhibitorId, id) =>
  exhibition_exhibitors_users_url(exhibitionId, exhibitorId) + "/" + id;

const exhibition_meetings_url = (exhibitionId) => default_url + "meetings";
const exhibition_meeting_url = (exhibitionId, id) =>
  exhibition_meetings_url(exhibitionId) + "/" + id;

const exhibition_meetings_users_url = (exhibitionId, meeting_id) =>
  exhibition_meeting_url(exhibitionId, meeting_id) + "/meetings_users";
const exhibition_meetings_user_url = (exhibitionId, meeting_id, id) =>
  exhibition_meetings_users_url(exhibitionId, meeting_id) + "/" + id;

const exhibition_documents_url = (exhibitionId) => default_url + "documents";
const exhibition_document_url = (exhibitionId, id) =>
  exhibition_documents_url(exhibitionId) + "/" + id;

const exhibition_images_url = (exhibitionId) => default_url + "images";
const exhibition_image_url = (exhibitionId, id) =>
  exhibition_images_url(exhibitionId) + "/" + id;

const exhibition_speakers_url = (exhibitionId) => default_url + "speakers";
const exhibition_speaker_url = (exhibitionId, id) =>
  exhibition_speakers_url(exhibitionId) + "/" + id;

const exhibition_moderators_url = (exhibitionId) => default_url + "moderators";

const exhibition_posts_url = (exhibitionId) => default_url + "posts";
const exhibition_post_url = (exhibitionId, id) =>
  default_url + "posts" + "/" + id;

const exhibition_shares_url = (exhibitionId) => default_url + "shares";

const exhibition_notifications_url = (exhibitionId) =>
  default_url + "notifications";

const exhibition_post_images_url = (exhibitionId, postId) =>
  exhibition_post_url(exhibitionId, postId) + "/images";
const exhibition_post_image_url = (exhibitionId, exhibitorId, id) =>
  exhibition_post_images_url(exhibitionId, exhibitorId) + "/" + id;

const exhibition_keynote_images_url = (exhibitionId, keynoteId) =>
  exhibition_keynote_url(exhibitionId, keynoteId) + "/images";
const exhibition_keynote_image_url = (exhibitionId, exhibitorId, id) =>
  exhibition_keynote_images_url(exhibitionId, exhibitorId) + "/" + id;

const exhibition_post_comments_url = (exhibitionId, postId) =>
  default_url + "comments?post.id=" + postId;
const exhibition_post_comment_url = (exhibitionId, postId, id) =>
  default_url + "comments/" + id;

const exhibition_posts_likes_url = (exhibitionId, postId) =>
  default_url + "post-likes";
const exhibition_posts_like_url = (exhibitionId, postId, id) =>
  exhibition_posts_likes_url(exhibitionId, postId) + "?post.id=" + postId;

const exhibition_comments_likes_url = (exhibitionId, postId, commentId) =>
  default_url + "comment-likes?post.id=" + postId + "&comment.id=" + commentId;
const exhibition_comments_like_url = (id) =>
  default_url + "comment-likes" + "/" + id;

const exhibition_pushNotifications_url = (exhibitionId) =>
  exhibition_url(exhibitionId) + "/push_notifications";

const ticket_invitations_url = () => default_url + "tickets";
const ticket_invitation_url = (id) => ticket_invitations_url() + "/" + id;

const exhibition_tickets_users_url = (exhibitionId, ticketId) =>
  exhibition_ticket_url(exhibitionId, ticketId) + "/tickets_users";
const exhibition_tickets_user_url = (exhibitionId, ticketId, id) =>
  exhibition_tickets_users_url(exhibitionId, ticketId) + "/" + id;

const organizations_url = () => default_url + "organizations";
const organization_url = (id) => organizations_url() + "/" + id;

const employees_url = () => default_url + "employees";
const employee_url = (id) => employees_url() + "/" + id;

const page_attr = (page_index, page_size, additional_attribute = false) =>
  (additional_attribute ? "&" : "?") +
  "_start=" +
  (page_index - 1) * page_size +
  "&_limit=" +
  page_size;

const talking_points_exhibitors_url = (talkingPointId) =>
  default_url + "exhibitors/talkingPoint/" + talkingPointId;

const discussion_room_url = (language) =>
  default_url + "discussion-rooms/findPrimary?language=" + language;

const is_enough_limit_url = () => default_url + "events/isEnoughLimit";
const waiting_user_add_email_url = () => default_url + "waiting-users/addEmail";

const languageParam = (locale) => "locale=" + locale;

const nakama_session = () => default_url + "nakama-auth";
const video_token = () => default_url + "videotoken";

export default {
  serverUrl: server_url,
  graphUrl: graph_url,

  build: (url, filters = [], locale = i18n.language || "de") =>
    url + (url.includes("?") ? "" : "?") + FilterParams.formatUrl(filters), //languageParam(locale) +

  header: {
    secure: (authorization_code) => {
      return {
        //'Accept': content_type,
        "Access-Control-Allow-Origin": "*",
        "Content-Type": content_type,
        Authorization: "Bearer " + authorization_code,
        "fairnxt-request-url": window.location.hostname,
        exhibition: process.env.REACT_APP_EXHIBITION_ID,
      };
    },

    insecure: () => {
      return {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": content_type,
        "fairnxt-request-url": window.location.hostname,
        exhibition: process.env.REACT_APP_EXHIBITION_ID,
      };
    },
  },

  websocket: (token) => {
    return websocket_server_url + "?token=" + token;
  },

  systemRoles: {
    all: {
      method: GET,
      url: (page_index = DEFAULT_PAGE_INDEX, page_size = DEFAULT_PAGE_SIZE) =>
        system_roles_url() + page_attr(page_index, page_size),
    },
    users: {
      create: {
        method: POST,
        url: (systemRoleId) => system_roles_users_url(systemRoleId),
      },

      delete: {
        method: DELETE,
        url: (systemRoleId, userId) =>
          system_roles_user_url(systemRoleId, userId),
      },
    },
  },

  stages: {
    all: {
      method: GET,
      url: () => stages_url(),
    },
    show: {
      method: GET,
      url: (id) => stage_url(id),
    },
  },

  online_status: {
    logincount: {
      method: POST,
      url: () => logincount_url,
    },
    logoutcount: {
      method: POST,
      url: () => logoutcount_url,
    },
  },

  configurations: {
    show: {
      method: GET,
      url: () => configuration_url(),
    },
  },

  registration: {
    create: {
      method: POST,
      url: () => registration_url,
    },
    signup: {
      method: POST,
      url: (ticketCode) => signup_url(ticketCode),
    },
    checkMail: {
      method: POST,
      url: () => check_mail_url,
    },
    sendConfirmation: {
      method: POST,
      url: () => sendConfirmation_url,
    },
  },

  landingpagesignup: {
    create: {
      method: POST,
      url: () => landingpagesignup_url,
    },
  },

  resetPassword: {
    create: {
      method: POST,
      url: () => forgot_password_url(),
    },
    show: {
      method: GET,
      url: (token) => reset_password_url() + "?token=" + token,
    },
    update: {
      method: POST,
      url: () => reset_password_url(),
    },
  },

  organizations: {
    all: {
      method: GET,
      url: (page_index = DEFAULT_PAGE_INDEX, page_size = DEFAULT_PAGE_SIZE) =>
        organizations_url() + page_attr(page_index, page_size),
    },

    show: {
      method: GET,
      url: (id) => organization_url(id),
    },

    create: {
      method: POST,
      url: () => organizations_url(),
    },

    update: {
      method: PUT,
      url: (id) => organization_url(id),
    },

    delete: {
      method: DELETE,
      url: (id) => organization_url(id),
    },
  },

  employees: {
    all: {
      method: GET,
      url: (page_index = DEFAULT_PAGE_INDEX, page_size = DEFAULT_PAGE_SIZE) =>
        employees_url() + page_attr(page_index, page_size),
    },

    show: {
      method: GET,
      url: (id) => employee_url(id),
    },

    create: {
      method: POST,
      url: () => employees_url(),
    },

    update: {
      method: PUT,
      url: (id) => employee_url(id),
    },

    delete: {
      method: DELETE,
      url: (id) => employee_url(id),
    },
  },

  ticket_invitations: {
    all: {
      method: GET,
      url: (page_index = DEFAULT_PAGE_INDEX, page_size = DEFAULT_PAGE_SIZE) =>
        ticket_invitations_url() + page_attr(page_index, page_size),
    },

    show: {
      method: GET,
      url: (id) => ticket_invitation_url(id),
    },

    create: {
      method: POST,
      url: () => ticket_invitations_url(),
    },

    update: {
      method: PUT,
      url: (id) => ticket_invitation_url(id),
    },

    delete: {
      method: DELETE,
      url: (id) => ticket_invitation_url(id),
    },
  },

  exhibitions: {
    all: {
      method: GET,
      url: (page_index = DEFAULT_PAGE_INDEX, page_size = DEFAULT_PAGE_SIZE) =>
        exhibitions_url() + page_attr(page_index, page_size),
    },

    show: {
      method: GET,
      url: (id) => exhibition_url(id),
    },

    update: {
      method: PUT,
      url: (id) => exhibition_url(id),
    },

    create: {
      method: POST,
      url: () => exhibitions_url(),
    },

    delete: {
      method: DELETE,
      url: (id) => exhibition_url(id),
    },

    statistics: {
      create: {
        method: POST,
        url: (exhibitionId) => exhibition_statistics_url(exhibitionId),
      },
    },

    pushNotifications: {
      create: {
        method: POST,
        url: (exhibitionId) => exhibition_pushNotifications_url(exhibitionId),
      },
    },

    tickets: {
      all: {
        method: GET,
        url: (
          exhibitionId,
          page_index = DEFAULT_PAGE_INDEX,
          page_size = DEFAULT_PAGE_SIZE
        ) =>
          exhibition_tickets_url(exhibitionId) +
          page_attr(page_index, page_size),
      },

      show: {
        method: GET,
        url: (exhibitionId, id) => exhibition_ticket_url(exhibitionId, id),
      },

      update: {
        method: PUT,
        url: (exhibitionId, id) => exhibition_ticket_url(exhibitionId, id),
      },

      create: {
        method: POST,
        url: (exhibitionId) => exhibition_tickets_url(exhibitionId),
      },

      delete: {
        method: DELETE,
        url: (exhibitionId, id) => exhibition_ticket_url(exhibitionId, id),
      },

      ticketsUsers: {
        create: {
          method: POST,
          url: (exhibitionId, ticketId) =>
            exhibition_tickets_users_url(exhibitionId, ticketId),
        },
        delete: {
          method: DELETE,
          url: (exhibitionId, ticketId, id) =>
            exhibition_tickets_user_url(exhibitionId, ticketId, id),
        },
      },
    },

    documents: {
      create: {
        method: POST,
        url: (exhibitionId) => exhibition_documents_url(exhibitionId),
      },

      delete: {
        method: DELETE,
        url: (exhibitionId, id) => exhibition_document_url(exhibitionId, id),
      },
    },

    shares: {
      create: {
        method: POST,
        url: (exhibitionId) => exhibition_shares_url(exhibitionId),
      },
    },

    notifications: {
      all: {
        method: GET,
        url: (
          exhibitionId,
          page_index = DEFAULT_PAGE_INDEX,
          page_size = DEFAULT_PAGE_SIZE
        ) =>
          exhibition_notifications_url(exhibitionId) +
          page_attr(page_index, page_size),
      },
    },

    images: {
      create: {
        method: POST,
        url: (exhibitionId) => exhibition_images_url(exhibitionId),
      },

      update: {
        method: PUT,
        url: (exhibitionId, id) => exhibition_image_url(exhibitionId, id),
      },

      delete: {
        method: DELETE,
        url: (exhibitionId, id) => exhibition_image_url(exhibitionId, id),
      },
    },

    exhibitors: {
      all: {
        method: GET,
        url: (
          exhibitionId,
          page_index = DEFAULT_PAGE_INDEX,
          page_size = DEFAULT_PAGE_SIZE
        ) =>
          exhibition_exhibitors_url(exhibitionId) +
          page_attr(page_index, page_size),
      },

      show: {
        method: GET,
        url: (exhibitionId, id) => exhibition_exhibitor_url(exhibitionId, id),
      },

      update: {
        method: PUT,
        url: (exhibitionId, id) => exhibition_exhibitor_url(exhibitionId, id),
      },

      create: {
        method: POST,
        url: (exhibitionId) => exhibition_exhibitors_url(exhibitionId),
      },

      salesmark: {
        method: PUT,
        url: (exhibitionId, id) =>
          exhibition_exhibitor_url(exhibitionId, id) + "/salesmark",
      },
      bookmark: {
        method: PUT,
        url: (exhibitionId, id) =>
          exhibition_exhibitor_url(exhibitionId, id) + "/bookmark",
      },

      delete: {
        method: DELETE,
        url: (exhibitionId, id) => exhibition_exhibitor_url(exhibitionId, id),
      },

      images: {
        create: {
          method: POST,
          url: (exhibitionId, exhibitorId) =>
            exhibition_exhibitor_images_url(exhibitionId, exhibitorId),
        },

        update: {
          method: PUT,
          url: (exhibitionId, exhibitorId, id) =>
            exhibition_exhibitor_image_url(exhibitionId, exhibitorId, id),
        },

        delete: {
          method: DELETE,
          url: (exhibitionId, exhibitorId, id) =>
            exhibition_exhibitor_image_url(exhibitionId, exhibitorId, id),
        },
      },

      users: {
        all: {
          method: GET,
          url: (
            exhibitionId,
            exhibitorId,
            page_index = DEFAULT_PAGE_INDEX,
            page_size = DEFAULT_PAGE_SIZE
          ) =>
            exhibition_exhibitors_users_url(exhibitionId, exhibitorId) +
            page_attr(page_index, page_size),
        },

        show: {
          method: GET,
          url: (exhibitionId, exhibitorId, id) =>
            exhibition_exhibitors_user_url(exhibitionId, exhibitorId, id),
        },

        update: {
          method: PUT,
          url: (exhibitionId, exhibitorId, id) =>
            exhibition_exhibitors_user_url(exhibitionId, exhibitorId, id),
        },

        create: {
          method: POST,
          url: (exhibitionId, exhibitorId) =>
            exhibition_exhibitors_users_url(exhibitionId, exhibitorId),
        },

        delete: {
          method: DELETE,
          url: (exhibitionId, exhibitorId, id) =>
            exhibition_exhibitors_user_url(exhibitionId, exhibitorId, id),
        },
      },
    },

    visitors: {
      all: {
        method: GET,
        url: (
          exhibitionId,
          page_index = DEFAULT_PAGE_INDEX,
          page_size = DEFAULT_PAGE_SIZE
        ) =>
          exhibition_visitors_url(exhibitionId) +
          page_attr(page_index, page_size),
      },
      bookmark: {
        method: PUT,
        url: (exhibitionId, id) =>
          exhibition_visitor_url(exhibitionId, id) + "/bookmark",
      },
      salesmark: {
        method: PUT,
        url: (exhibitionId, id) =>
          exhibition_visitor_url(exhibitionId, id) + "/salesmark",
      },
      show: {
        method: GET,
        url: (exhibitionId, id) => exhibition_visitor_url(exhibitionId, id),
      },
    },

    timeslots: {
      all: {
        method: GET,
        url: (
          exhibitionId,
          page_index = DEFAULT_PAGE_INDEX,
          page_size = DEFAULT_PAGE_SIZE
        ) =>
          exhibition_timeslots_url(exhibitionId) +
          page_attr(page_index, page_size),
      },
    },

    conversations: {
      all: {
        method: GET,
        url: (
          exhibitionId,
          page_index = DEFAULT_PAGE_INDEX,
          page_size = DEFAULT_PAGE_SIZE
        ) =>
          exhibition_conversations_url(exhibitionId) +
          page_attr(page_index, page_size),
      },

      show: {
        method: GET,
        url: (exhibitionId, id) =>
          exhibition_conversation_url(exhibitionId, id),
      },

      update: {
        method: PUT,
        url: (exhibitionId, id) =>
          exhibition_conversation_url(exhibitionId, id),
      },

      create: {
        method: POST,
        url: (exhibitionId) => exhibition_conversations_url(exhibitionId),
      },

      delete: {
        method: DELETE,
        url: (exhibitionId, id) =>
          exhibition_conversation_url(exhibitionId, id),
      },
      users: {
        all: {
          method: GET,
          url: (
            exhibitionId,
            conversationId,
            page_index = DEFAULT_PAGE_INDEX,
            page_size = DEFAULT_PAGE_SIZE
          ) =>
            exhibition_conversations_users_url(exhibitionId, conversationId) +
            page_attr(page_index, page_size),
        },

        create: {
          method: POST,
          url: (exhibitionId, conversationId) =>
            exhibition_conversations_users_url(exhibitionId, conversationId),
        },

        delete: {
          method: DELETE,
          url: (exhibitionId, conversationId, id) =>
            exhibition_conversations_user_url(exhibitionId, conversationId, id),
        },
      },
    },

    messages: {
      all: {
        method: GET,
        url: (
          exhibitionId,
          page_index = DEFAULT_PAGE_INDEX,
          page_size = DEFAULT_PAGE_SIZE
        ) =>
          exhibition_messages_url(exhibitionId) +
          page_attr(page_index, page_size),
      },

      show: {
        method: GET,
        url: (exhibitionId, id) => exhibition_message_url(exhibitionId, id),
      },

      update: {
        method: PUT,
        url: (exhibitionId, id) => exhibition_message_url(exhibitionId, id),
      },

      create: {
        method: POST,
        url: (exhibitionId) => exhibition_messages_url(exhibitionId),
      },

      delete: {
        method: DELETE,
        url: (exhibitionId, id) => exhibition_message_url(exhibitionId, id),
      },
    },

    agenda_entries: {
      all: {
        method: GET,
        url: (
          exhibitionId,
          page_index = DEFAULT_PAGE_INDEX,
          page_size = DEFAULT_PAGE_SIZE
        ) =>
          exhibition_agenda_entries_url(exhibitionId) +
          page_attr(page_index, page_size),
      },

      show: {
        method: GET,
        url: (exhibitionId, id) =>
          exhibition_agenda_entry_url(exhibitionId, id),
      },

      update: {
        method: PUT,
        url: (exhibitionId, id) =>
          exhibition_agenda_entry_url(exhibitionId, id),
      },

      create: {
        method: POST,
        url: (exhibitionId) => exhibition_agenda_entries_url(exhibitionId),
      },

      delete: {
        method: DELETE,
        url: (exhibitionId, id) =>
          exhibition_agenda_entry_url(exhibitionId, id),
      },
    },

    thematicAreas: {
      all: {
        method: GET,
        url: (
          exhibitionId,
          page_index = DEFAULT_PAGE_INDEX,
          page_size = DEFAULT_PAGE_SIZE
        ) =>
          exhibition_thematicAreas_url(exhibitionId) +
          page_attr(page_index, page_size),
      },

      show: {
        method: GET,
        url: (exhibitionId, id) =>
          exhibition_thematicArea_url(exhibitionId, id),
      },

      update: {
        method: PUT,
        url: (exhibitionId, id) =>
          exhibition_thematicArea_url(exhibitionId, id),
      },

      create: {
        method: POST,
        url: (exhibitionId) => exhibition_thematicAreas_url(exhibitionId),
      },

      delete: {
        method: DELETE,
        url: (exhibitionId, id) =>
          exhibition_thematicArea_url(exhibitionId, id),
      },
    },

    keynotes: {
      all: {
        method: GET,
        url: (
          exhibitionId,
          page_index = DEFAULT_PAGE_INDEX,
          page_size = DEFAULT_PAGE_SIZE
        ) =>
          exhibition_keynotes_url(exhibitionId) +
          page_attr(page_index, page_size),
      },

      show: {
        method: GET,
        url: (exhibitionId, id) => exhibition_keynote_url(exhibitionId, id),
      },

      update: {
        method: PUT,
        url: (exhibitionId, id) => exhibition_keynote_url(exhibitionId, id),
      },

      create: {
        method: POST,
        url: (exhibitionId) => exhibition_keynotes_url(exhibitionId),
      },

      delete: {
        method: DELETE,
        url: (exhibitionId, id) => exhibition_keynote_url(exhibitionId, id),
      },

      keynotes_speakers: {
        create: {
          method: POST,
          url: (exhibitionId, keynoteId) =>
            exhibition_keynotes_speakers_url(exhibitionId, keynoteId),
        },

        delete: {
          method: DELETE,
          url: (exhibitionId, keynoteId, userId) =>
            exhibition_keynotes_speaker_url(exhibitionId, keynoteId, userId),
        },
      },

      keynotes_moderators: {
        create: {
          method: POST,
          url: (exhibitionId, keynoteId) =>
            exhibition_keynotes_moderators_url(exhibitionId, keynoteId),
        },

        delete: {
          method: DELETE,
          url: (exhibitionId, keynoteId, userId) =>
            exhibition_keynotes_moderator_url(exhibitionId, keynoteId, userId),
        },
      },

      keynotes_users: {
        create: {
          method: POST,
          url: (exhibitionId, keynoteId) =>
            exhibition_keynotes_users_url(exhibitionId, keynoteId),
        },

        delete: {
          method: DELETE,
          url: (exhibitionId, keynoteId, userId) =>
            exhibition_keynotes_user_url(exhibitionId, keynoteId, userId),
        },
      },

      images: {
        create: {
          method: POST,
          url: (exhibitionId, keynoteId) =>
            exhibition_keynote_images_url(exhibitionId, keynoteId),
        },

        update: {
          method: PUT,
          url: (exhibitionId, keynoteId, id) =>
            exhibition_keynote_image_url(exhibitionId, keynoteId, id),
        },

        delete: {
          method: DELETE,
          url: (exhibitionId, keynoteId, id) =>
            exhibition_keynote_image_url(exhibitionId, keynoteId, id),
        },
      },
    },

    speakers: {
      all: {
        method: GET,
        url: (
          exhibitionId,
          page_index = DEFAULT_PAGE_INDEX,
          page_size = DEFAULT_PAGE_SIZE
        ) =>
          exhibition_speakers_url(exhibitionId) +
          page_attr(page_index, page_size),
      },

      show: {
        method: GET,
        url: (exhibitionId, id) => exhibition_speaker_url(exhibitionId, id),
      },

      update: {
        method: PUT,
        url: (exhibitionId, id) => exhibition_speaker_url(exhibitionId, id),
      },

      create: {
        method: POST,
        url: (exhibitionId) => exhibition_speakers_url(exhibitionId),
      },

      delete: {
        method: DELETE,
        url: (exhibitionId, id) => exhibition_speaker_url(exhibitionId, id),
      },
    },

    moderators: {
      all: {
        method: GET,
        url: (
          exhibitionId,
          page_index = DEFAULT_PAGE_INDEX,
          page_size = DEFAULT_PAGE_SIZE
        ) =>
          exhibition_moderators_url(exhibitionId) +
          page_attr(page_index, page_size),
      },
    },

    bookmarks: {
      all: {
        method: GET,
        url: (
          exhibitionId,
          page_index = DEFAULT_PAGE_INDEX,
          page_size = DEFAULT_PAGE_SIZE
        ) =>
          exhibition_bookmarks_url(exhibitionId) +
          page_attr(page_index, page_size),
      },
      create: {
        method: POST,
        url: (exhibitionId) => exhibition_bookmarks_url(exhibitionId),
      },
      delete: {
        method: DELETE,
        url: (exhibitionId, id) => exhibition_bookmark_url(exhibitionId, id),
      },
    },

    posts: {
      all: {
        method: GET,
        url: (
          exhibitionId,
          page_index = DEFAULT_PAGE_INDEX,
          page_size = DEFAULT_PAGE_SIZE
        ) =>
          exhibition_posts_url(exhibitionId) + page_attr(page_index, page_size),
      },

      show: {
        method: GET,
        url: (exhibitionId, id) => exhibition_post_url(exhibitionId, id),
      },

      update: {
        method: PUT,
        url: (exhibitionId, id) => exhibition_post_url(exhibitionId, id),
      },

      salesmark: {
        method: PUT,
        url: (exhibitionId, id) =>
          exhibition_post_url(exhibitionId, id) + "/salesmark",
      },
      bookmark: {
        method: PUT,
        url: (exhibitionId, id) =>
          exhibition_post_url(exhibitionId, id) + "/bookmark",
      },

      create: {
        method: POST,
        url: (exhibitionId) => exhibition_posts_url(exhibitionId),
      },

      delete: {
        method: DELETE,
        url: (exhibitionId, id) => exhibition_post_url(exhibitionId, id),
      },

      comments: {
        all: {
          method: GET,
          url: (
            exhibitionId,
            postId,
            page_index = DEFAULT_PAGE_INDEX,
            page_size = DEFAULT_PAGE_SIZE
          ) =>
            exhibition_post_comments_url(exhibitionId, postId) +
            page_attr(page_index, page_size, true),
        },

        show: {
          method: GET,
          url: (exhibitionId, postId, id) =>
            exhibition_post_comment_url(exhibitionId, postId, id),
        },

        update: {
          method: PUT,
          url: (exhibitionId, postId, id) =>
            exhibition_post_comment_url(exhibitionId, postId, id),
        },

        create: {
          method: POST,
          url: (exhibitionId, postId) =>
            exhibition_post_comments_url(exhibitionId, postId),
        },

        delete: {
          method: DELETE,
          url: (exhibitionId, postId, id) =>
            exhibition_post_comment_url(exhibitionId, postId, id),
        },

        likes: {
          all: {
            method: GET,
            url: (
              exhibitionId,
              postId,
              commentId,
              page_index = DEFAULT_PAGE_INDEX,
              page_size = DEFAULT_PAGE_SIZE
            ) =>
              exhibition_comments_likes_url(exhibitionId, postId, commentId) +
              page_attr(page_index, page_size),
          },

          create: {
            method: POST,
            url: (exhibitionId, postId, commentId) =>
              exhibition_comments_likes_url(exhibitionId, postId, commentId),
          },

          delete: {
            method: DELETE,
            url: (id) => exhibition_comments_like_url(id),
          },
        },
      },

      images: {
        create: {
          method: POST,
          url: (exhibitionId, postId) =>
            exhibition_post_images_url(exhibitionId, postId),
        },

        update: {
          method: PUT,
          url: (exhibitionId, postId, id) =>
            exhibition_post_image_url(exhibitionId, postId, id),
        },

        delete: {
          method: DELETE,
          url: (exhibitionId, postId, id) =>
            exhibition_post_image_url(exhibitionId, postId, id),
        },
      },

      likes: {
        all: {
          method: GET,
          url: (
            exhibitionId,
            postId,
            page_index = DEFAULT_PAGE_INDEX,
            page_size = DEFAULT_PAGE_SIZE
          ) =>
            exhibition_posts_likes_url(exhibitionId, postId) +
            page_attr(page_index, page_size),
        },

        create: {
          method: POST,
          url: (exhibitionId, postId) =>
            exhibition_posts_likes_url(exhibitionId, postId),
        },

        delete: {
          method: DELETE,
          url: (exhibitionId, postId, id) =>
            exhibition_posts_like_url(exhibitionId, postId, id),
        },
      },
    },

    meetings: {
      all: {
        method: GET,
        url: (
          exhibitionId,
          page_index = DEFAULT_PAGE_INDEX,
          page_size = DEFAULT_PAGE_SIZE
        ) =>
          exhibition_meetings_url(exhibitionId) +
          page_attr(page_index, page_size),
      },

      show: {
        method: GET,
        url: (exhibitionId, id) => exhibition_meeting_url(exhibitionId, id),
      },

      update: {
        method: PUT,
        url: (exhibitionId, id) => exhibition_meeting_url(exhibitionId, id),
      },

      create: {
        method: POST,
        url: (exhibitionId) => exhibition_meetings_url(exhibitionId),
      },

      delete: {
        method: DELETE,
        url: (exhibitionId, id) => exhibition_meeting_url(exhibitionId, id),
      },
    },

    meetings_users: {
      all: {
        method: GET,
        url: (
          exhibitionId,
          meetingId,
          page_index = DEFAULT_PAGE_INDEX,
          page_size = DEFAULT_PAGE_SIZE
        ) =>
          exhibition_meetings_users_url(exhibitionId, meetingId) +
          page_attr(page_index, page_size),
      },

      update: {
        method: PUT,
        url: (exhibitionId, meetingId, id) =>
          exhibition_meetings_user_url(exhibitionId, meetingId, id),
      },

      create: {
        method: POST,
        url: (exhibitionId, meetingId) =>
          exhibition_meetings_users_url(exhibitionId, meetingId),
      },

      delete: {
        method: DELETE,
        url: (exhibitionId, meetingId, id) =>
          exhibition_meetings_user_url(exhibitionId, meetingId, id),
      },
    },
    discussion_rooms: {
      show: {
        method: GET,
        url: discussion_room_url,
      },
    },

    isEnoughLimit: {
      method: GET,
      url: is_enough_limit_url,
    },
  },

  sessions: {
    login: {
      method: POST,
      url: () => login_url,
    },
    memberLogin: {
      method: POST,
      url: () => member_login_url,
    },
    logout: {
      method: DELETE,
      url: () => logout_url,
    },
  },

  users: {
    all: {
      method: GET,
      url: (page_index = DEFAULT_PAGE_INDEX, page_size = DEFAULT_PAGE_SIZE) =>
        users_url + page_attr(page_index, page_size),
    },

    exhibitors: {
      method: GET,
      url: () => user_exhibitors_url(),
    },

    eventuser: {
      method: GET,
      url: () => users_eventuser_url(),
    },

    eventuser_uuid: {
      method: GET,
      url: (id) => eventuser_uuid_url(id),
    },

    show: {
      method: GET,
      url: (id) => user_url(id),
    },

    update: {
      method: PUT,
      url: (id) => user_url(id),
    },
    eventuserUpdate: {
      method: PUT,
      url: (id) => eventuser_url(id),
    },

    salesmark: {
      method: PUT,
      url: (id) => user_url(id) + "/salesmark",
    },
    bookmark: {
      method: PUT,
      url: (id) => user_url(id) + "/bookmark",
    },

    tickets: {
      all: {
        method: GET,
        url: (userId) => user_tickets_url(userId),
      },
      show: {
        method: GET,
        url: (userId, id) => user_ticket_url(userId, id),
      },
      create: {
        method: POST,
        url: (userId) => user_tickets_url(userId),
      },
      delete: {
        method: DELETE,
        url: (userId, id) => user_ticket_url(userId, id),
      },
    },
    changeLocale: {
      method: PUT,
      url: (locale) => changeLocale_url(locale),
    },

    images: {
      create: {
        method: POST,
        url: (exhibitionId, userId) =>
          user_images_url(userId) + "?exhibition_id=" + exhibitionId,
      },

      update: {
        method: PUT,
        url: (exhibitionId, userId, id) =>
          user_image_url(userId, id) + "?exhibition_id=" + exhibitionId,
      },

      delete: {
        method: DELETE,
        url: (exhibitionId, userId, id) =>
          user_image_url(userId, id) + "?exhibition_id" + exhibitionId,
      },
    },
  },

  talkingPoints: {
    exhibitors: {
      method: GET,
      url: (talkingPointId) => talking_points_exhibitors_url(talkingPointId),
    },
  },

  waitingUsers: {
    addEmail: {
      method: POST,
      url: waiting_user_add_email_url,
    },
  },
  nakama: {
    auth: {
      method: POST,
      url: nakama_session,
    }
  },
  twilio: {
    video_token: {
      method: POST,
      url: video_token,
    }
  },
};
