import Image from "../models/Image";
import BaseMapper from "./BaseMapper";
import ErrorLogger from "../utils/ErrorLogger";
import { isJSDocNonNullableType } from "typescript";

const attributes = Image.attributes;

export default class ImageMapper {
  static build = (json) => {
    try {
      if (!json || !json.name) {
        return;
      }
  
      return new Image({
        ...BaseMapper.attributes(json),
        url: json.url,
        formats: json.formats,
        name: json.name,
        imageType: json.mime,
        fileName: json.name,
        fileContentType: json.mime,
        fileSize: json.size,
        //exhibitionId: json[attributes.exhibitionId]
      });
    } catch(error)  { ErrorLogger.call(this, error) }
  };

  static buildFromArray = (jsonArray = []) => {
    return jsonArray.map(entry => {
      return this.build(entry);
    });
  };

  static toObject = (image) => (
    {
      id: image.getId(),
      created_at: image.getCreatedAt(),
      update_at: image.getUpdatedAt(),
      [attributes.formats]: image.getFormats(),
      [attributes.name]: image.getName(),
      [attributes.attachedToId]: image.getAttachedToId(),
      [attributes.attachedToType]: image.getAttachedToType(),
      [attributes.url]: image.state.url,
      [attributes.imageType]: image.getImageType(),
      [attributes.content]: image.getContent(),
    }
  )

  static toJsonString(image) {
    return JSON.stringify(this.toObject(image));
  }
}
