import React, { createContext, ReactNode, useEffect, useRef, useState } from 'react'
import useNakamaServer from '../../../../../engines/CommunicationHooks/useNakamaServer/useNakamaServer';
import { Client as NakamaClient, Match, MatchData, MatchmakerMatched, MatchmakerTicket, MatchPresenceEvent, Session } from '@heroiclabs/nakama-js'
import useExhibition from '../../../../../engines/GeneralHooks/useExhibition';
import jwt_decode from 'jwt-decode';
import useTwilioSyncList from '../../../../../engines/GeneralHooks/useTwilioSync'
import MyStore from '../../../../../utils/MyStore';
import { SyncListItem } from 'twilio-sync';

export interface IMatchmakingContext {
    nakamaSession: Session | undefined,
    nakamaSocketSession: Session | undefined,
    ticket: MatchmakerTicket | undefined,
    match: Match | undefined,
    roomId: string | undefined,
    isLoadingNakamaSession: boolean,
    startMatchmaking: () => void,
    disconnectNakamaSession: () => Promise<void>,
    matchmakingState: MatchmakingState,
    matchedUsername: string | undefined,
    onVideoConnected: () => void,
    onVideoError: () => void,
    sessionError: any,
}

export type MatchmakingUser = {
    username: string;
}

export const MatchmakingContext = createContext<IMatchmakingContext>(null!);

interface MatchmakingProviderProps {
    options?: any;
    onError?: any;
    children: ReactNode;
}

export enum MatchmakingState {
    initializing,
    waiting,
    searching,
    connected,
    error
}

function MatchmakingProvider({ options, children, onError = (e: any) => { } }: MatchmakingProviderProps) {
    const [nakamaSocketSession, setNakamaSocketSession] = useState<Session>();
    const [ticket, setTicket] = useState<MatchmakerTicket>();
    const [match, setMatch] = useState<Match>();
    const [sessionError, setSessionError] = useState<any>();
    const [matchmakerMatched, setMatchmakerMatched] = useState<MatchmakerMatched>();
    const [matchmakingState, setMatchmakingState] = useState<MatchmakingState>(MatchmakingState.initializing);
    const [nakamaSession, isLoadingNakamaSession, nakamaError] = useNakamaServer();
    const [roomId, setRoomId] = useState<string>();
    const [exhibition] = useExhibition() as any[];
    const [syncListName, setSyncListName] = useState<string | undefined>();
    const [matchedUsername, setMatchedUsername] = useState<string | undefined>();
    const { elements, isListLoaded, addElement, removeElement } = useTwilioSyncList(syncListName, () => { });
    const elementsRef = useRef<SyncListItem[]>();
    const roomRef = useRef<string>();

    const useSSL = true;

    const [client] = useState<NakamaClient>(new NakamaClient(
        undefined,
        process.env.REACT_APP_NAKAMA_SERVER,
        process.env.REACT_APP_NAKAMA_PORT,
        useSSL
    ));

    const verboseLogging = false;
    const [socket] = useState(client.createSocket(useSSL, verboseLogging));

    useEffect(() => {
        elementsRef.current = elements;
    }, [elements])

    useEffect(() => {
        roomRef.current = roomId;
    }, [roomId])

    useEffect(() => {
        if (nakamaSocketSession || !nakamaSession || !exhibition) {
            return;
        }

        localStorage.nakamaAuthToken = nakamaSession.token;
        socket.connect(nakamaSession, true)
            .then(s => {
                socket.onmatchmakermatched = async (item) => {
                    let matchId = item.match_id;
                    setTicket(item);
                    let matchToken = item.token;
                    let matchedUser = item.users.filter((user) => user.presence.username !== item.self.presence.username)[0]
                    // if (elementsRef.current.map(e => e.data["username"] as string).includes(matchedUser.presence.username)) {
                    //     if (ticket?.ticket) {
                    //         socket.removeMatchmaker(ticket.ticket);
                    //     }
                    //     startMatchmaking();
                    //     return
                    // }
                    // addElement({ username: item.users.filter((user) => user.presence.username !== MyStore.getCurrentUser().getEmail())[0].presence.username });
                    const newMatch = (await socket.joinMatch(matchId, matchToken));
                    setMatchedUsername(matchedUser.presence.username);
                    setRoomId(newMatch.match_id);
                    setMatch(newMatch);
                    
                    setMatchmakingState(MatchmakingState.connected);
                }
                socket.onmatchpresence = async (item: MatchPresenceEvent) => {
                    if (item.leaves?.length) {
                        await disconnectNakamaSession(true);
                    }
                }
                socket.onerror = async (e) => {
                    await disconnectNakamaSession(false);
                };
                socket.ondisconnect = async (e) => {
                    await disconnectNakamaSession(false);
                }

                setNakamaSocketSession(s);
            })
            .catch(e => {
                setMatchmakingState(MatchmakingState.error);
                setSessionError(e);
            })

        var dateString = (new Date()).toISOString().slice(0, 10).replace(/-/g, "");
        setSyncListName(`${exhibition.getId()}-${MyStore.getCurrentUser()?.getId()}-matchedusers-${dateString}`)

        return () => {
            try {
                if (ticket?.ticket) {
                    socket.removeMatchmaker(ticket.ticket);
                }
                socket.disconnect(true);
            } catch (e) {

            }
        }

    }, [nakamaSession, exhibition])

    useEffect(() => {
        if (!nakamaSocketSession || !isListLoaded) {
            return
        }

        setMatchmakingState(MatchmakingState.waiting);
    }, [isListLoaded, nakamaSocketSession])

    useEffect(() => {
        if (matchmakingState === MatchmakingState.error) {
            disconnectNakamaSession();
        }
    }, [matchmakingState])

    const disconnectNakamaSession = async (skip = false) => {
        if (roomRef.current) {
            await socket.leaveMatch(roomRef.current).catch(e => {
                //console.log(e);
            });
            setRoomId(undefined);
            setTicket(undefined);
            setMatch(undefined);
            setMatchedUsername(undefined);
        }
        setMatchmakingState(skip ? MatchmakingState.searching : MatchmakingState.waiting);
        if (skip) {
            startMatchmaking();
        }
    }

    const startMatchmaking = () => {
        if (!exhibition) {
            return;
        }

        const query = `*`;
        const minCount = 2;
        const maxCount = 2;

        const numericProperties = {
            exhibition_id: exhibition.getId(),
        };

        socket.addMatchmaker(query, minCount, maxCount, undefined, numericProperties)
            .then(t => {
                setTicket(t);
                setMatchmakingState(MatchmakingState.searching);
            })
            .catch(e => {
                setMatchmakingState(MatchmakingState.error);
                setSessionError(e);
            })
    }

    const onVideoConnected = () => {
        setMatchmakingState(MatchmakingState.connected);
    }
    const onVideoError = () => {
        setMatchmakingState(MatchmakingState.error);
    }

    return (
        <MatchmakingContext.Provider value={{
            nakamaSession,
            nakamaSocketSession,
            ticket,
            match,
            roomId,
            isLoadingNakamaSession,
            startMatchmaking,
            disconnectNakamaSession,
            matchmakingState,
            matchedUsername,
            onVideoConnected,
            onVideoError,
            sessionError,
        }}>
            {children}
        </MatchmakingContext.Provider>
    )
}

export default MatchmakingProvider
