import { Box, Button, LinearProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import NeumorphicStyle from "../../../../constants/Neumorphic";
import { useSelector } from 'react-redux'
import { DEFAULT_PAGE_INDEX } from "../../../../constants/Urls";
import useExhibition from "../../../../engines/GeneralHooks/useExhibition";
import usePostsQL from "../../../../engines/GraphQL/hooks/usePostsQL";
import { useRawPushNotification } from "../../../../engines/Notification/NotificationEngine";
import PushNotification from "../../../../models/PushNotification";
import PostCard from "../../../components/PostCard";
import BookmarkSwitch from "../components/BookmarkSwitch";
import TagArea from "../components/TagArea";
import GlobalContext from "../context/GlobalContext";
import useBreakpoints from "../../../../engines/Util/useBreakpoints";

const useStyles = makeStyles((theme) => ({
  root: {
    overflowY: "auto",
    overflowX: "hidden",
    paddingTop: "32px",
    paddingBottom: "32px",
    width: "100%",
    height: "100%",
  },
  post: {
    maxWidth: "665px",
    marginTop: "32px",
    marginLeft: (props) => props.breakpoint <= 3 ? 20 : "auto",
    marginRight: (props) => props.breakpoint <= 3 ? 20 : "auto",
  },
  keyvisual: {
    marginBottom: "24px",
    width: "85%",
    background: theme.palette.background.default,
    borderRadius: NeumorphicStyle.borderRadius,
    padding: "0px",
    boxShadow: `-7px 7px 15px ${theme.palette.primary.main}, 7px -7px 15px ${theme.palette.secondary.dark}, inset 1px -1px 2px ${theme.palette.primary.main}, inset -1px 1px 2px ${theme.palette.secondary.dark}`,
    height: 200,
    objectFit: "cover",
  },
  tagArea: {
    marginRight: "auto",
    paddingRight: 20,
  },
  switch: {
    marginRight: 20,
    marginBottom: -80,
    marginTop: 4,
  },
  circularProgress: {
    width: 50,
    height: 50,
  },
}));

function PostFeed({ ...rest }) {
  const { t } = useTranslation();
  const [breakpoint] = useBreakpoints();
  const classes = useStyles({breakpoint});
  const [exhibition] = useExhibition();
  const containerRef = useRef();
  const [showBookmarks, setShowBookmarks] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(DEFAULT_PAGE_INDEX);
  const [posts, loading, totalPages, refetchBookmarks, fetchNewData] =
    usePostsQL(currentIndex, 3, showBookmarks);
  const context = useContext(GlobalContext);
  const newPostsButton = useRef();
  const [incomingMessage] = useRawPushNotification();
  const [showReloadButton, setshowReloadButton] = useState(false);
  const [loadMore, setLoadMore] = useState(false);
  const {configuration: {state: {filters}}} = useSelector(state => state.configurationsReducer)

  useEffect(() => {
    setCurrentIndex(DEFAULT_PAGE_INDEX);
    fetchNewData();
  }, [context.tags, showBookmarks, context.minPrice, context.maxPrice]);

  const updateFeed = () => {
    if (showBookmarks) {
      setCurrentIndex(DEFAULT_PAGE_INDEX);
      fetchNewData();
    }
  };

  const handleReloadButtonClick = () => {
    setshowReloadButton(false);
    containerRef.current.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    fetchNewData();
  };

  useEffect(() => {
    if (
      !incomingMessage ||
      incomingMessage.getNotificationType() !==
        PushNotification.NOTIFICATION_TYPES.newPosts
    ) {
      return;
    }

    setshowReloadButton(true);
    setCurrentIndex(DEFAULT_PAGE_INDEX);
  }, [incomingMessage]);

  useEffect(() => {
    if (!containerRef.current || loading) {
      return;
    }

    containerRef.current.addEventListener("scroll", handleScroll);

    return () => {
      containerRef.current.removeEventListener("scroll", handleScroll);
    };
  }, [containerRef.current, loading]);

  const handleScroll = () => {
    if (containerRef.current.scrollTop === 0) {
      setLoadMore(true);
    }
  };

  useEffect(() => {
    if (!loadMore) {
      return;
    }
    handleReloadButtonClick();
    setLoadMore(false);
  }, [loadMore]);

  return (
    <div ref={containerRef} className={classes.root} container>
      {exhibition && exhibition.getHeaderPicture() && (
        <img
          src={exhibition.getHeaderPicture().getUrl("large")}
          className={classes.keyvisual}
        />
      )}
      <Box display="flex" alignItems="center" pl={5} pr={2}>
        <Box className={classes.tagArea}>
          <TagArea center={true} exhibition={exhibition} showRange={filters.feed_price_filter} />
        </Box>
        <Box>
          <BookmarkSwitch
            showBookmarks={showBookmarks}
            setShowBookmarks={setShowBookmarks}
          />
        </Box>
      </Box>
      {showReloadButton && (
        <Button
          ref={newPostsButton}
          variant="contained"
          color="secondary"
          onClick={() => handleReloadButtonClick()}
          style={{
            position: "fixed",
            zIndex: 3000,
            height: "50px",
            width: "100px",
            left: "46%",
          }}
        >
          <Trans>frontend.pages.feed.new_posts</Trans>
        </Button>
      )}
      {posts && posts.length > 0 ? (
        posts.map((post) => (
          <PostCard
            className={classes.post}
            totalPages={totalPages}
            currentIndex={currentIndex}
            setCurrentIndex={setCurrentIndex}
            postData={post}
            refetchBookmarks={() => updateFeed()}
          />
        ))
      ) : (
        <div />
      )}
      {loading && (
        <LinearProgress
          style={{ maxWidth: "80%", marginLeft: "auto", marginRight: "auto", marginTop: 12 }}
        />
      )}
    </div>
  );
}

export default PostFeed;
