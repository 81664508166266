import {
  Avatar,
  BottomNavigation,
  BottomNavigationAction
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faVideo, faHome, faCalendar } from "@fortawesome/free-solid-svg-icons";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import ApartmentIcon from '@material-ui/icons/Apartment';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "rgba(255,255,255,.95)",
    position: "fixed",
    left: ".5rem",
    bottom: ".5rem",
    right: ".5rem",
    display: "flex",
    flexWrap: "nowrap",
    justifyItems: "stretch",
    alignItems: "center",
    paddingTop: 0,
    height: "3rem",
    borderRadius: "1.5rem"
  },
  avatar: {
    height: "2rem",
    width: "2rem",
    boxShadow: "1px 1px 5px 1px rgba(90,90,90,0.4)"
  },
  bottomNavItem: {
    alignItems: "center",
    padding: "0 !important",
    height: "3rem"
  },
  navigationIcon: {
    height: "1.4rem",
    width: "1.4rem !important"
  }
}));

const styles = {
  root: {
    color: "green",
    "&$selected": {
      color: "red"
    }
  },
  selected: {}
};

interface LbMobileNavigationProps {}

const LbMobileNavigation: React.FC<LbMobileNavigationProps> = () => {
  const classes = useStyles();
  const [value, setValue] = useState<string>("/feed");
  const history = useHistory();

  useEffect(() => {
    console.log(value);
    history.push(value);
  }, [value, history]);

  return (
    <BottomNavigation
      className={classes.root}
      showLabels={true}
      value={value}
      onChange={(event, newValue) => setValue(newValue)}
    >
      <BottomNavigationAction
        className={classes.bottomNavItem}
        icon={
          <FontAwesomeIcon
            width="1.5rem"
            className={classes.navigationIcon}
            icon={faHome}
          />
        }
        value="/feed"
      />
      <BottomNavigationAction
        className={classes.bottomNavItem}
        icon={
          <ApartmentIcon />
        }
        value="/exhibitors"
      />
      <BottomNavigationAction
        className={classes.bottomNavItem}
        icon={
          <FontAwesomeIcon
            width="1.5rem"
            className={classes.navigationIcon}
            icon={faCalendar}
          />
        }
        value="/agenda"
      />
      <BottomNavigationAction
        className={classes.bottomNavItem}
        disabled
        //icon={<Avatar className={classes.avatar} />}
        value="/profile"
      />
    </BottomNavigation>
  );
};

export default LbMobileNavigation;
