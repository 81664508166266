import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE } from '../../constants/Urls';
import { fetchKeynotes, fetchKeynote } from '../../redux/actions/KeynotesActions';
import useExhibition from './useExhibition';
import useSearchEngine, { SEARCH_DATATYPE } from "../../engines/Search/SearchEngine";
import FilterParams from '../../utils/FilterParams';
import Keynote from '../../models/Keynote';
import { useRawPushNotification } from '../Notification/NotificationEngine';
import PushNotification from '../../models/PushNotification';
import { fetchActiveKeynotes } from '../../redux/actions/ActiveKeynotesActions';

function useKeynotes(searchText,pageIndex = DEFAULT_PAGE_INDEX, pageSize = DEFAULT_PAGE_SIZE, filters = []) {
    const dispatch = useDispatch();

    const [keynotes, setKeynotes] = useState([]);
    const [exhibition] = useExhibition();
    const [loading, setLoading] = useState(false);
    const [totalPages, setTotalPages] = useState(1);
    const [fetchPending, setFetchPending] = useState(false);

    const [resultSet,searching,finishedSearch, searchTotalPages, totalItemsCount] = useSearchEngine(searchText,SEARCH_DATATYPE.keynote);

    const keynotesReducer = useSelector(state => state.keynotesReducer);
    const usersReducer = useSelector(state => state.usersReducer);

    useEffect(() => {
        if (!usersReducer.currentUser ||
            fetchPending ||
            !exhibition ||
            (searchText && searchText.length > 0)
            //!usersReducer.currentUser.hasReadPermissions()
        ){
            return;
        }

        if (keynotesReducer.isFetching) {
            if (fetchPending) {
                setFetchPending(true);
            }
            return
        } else {
            dispatch(fetchKeynotes(exhibition.getId(), {
                pageIndex:pageIndex,
                pageSize:pageSize,
                filters:filters,
            }));
            setLoading(true);
        }
    }, [usersReducer.currentUser,exhibition, pageIndex, pageSize, filters.length])

    useEffect(() => {
        if ((fetchPending && keynotesReducer.isFetching) || !fetchPending) {
            return;
        }

        setFetchPending(false);

        dispatch(fetchKeynotes(exhibition.getId(), {
            pageIndex:pageIndex,
            pageSize:pageSize,
            filters:filters,
        }));
        setLoading(true);
    }, [keynotesReducer.isFetching])

    useEffect(() => {
        if (keynotesReducer.isFetching || 
            !loading ||
            !keynotesReducer.keynotes || 
            keynotesReducer.error.isError()
        ) {
            return;
        }
        setLoading(false);

        // if(!usersReducer.currentUser.hasReadPermissions()) {
        //     return
        // }
        if (pageIndex === DEFAULT_PAGE_INDEX) {
            setKeynotes(keynotesReducer.keynotes);
            setTotalPages(keynotesReducer.totalPages);
        } else {
            setKeynotes(keynotesReducer.keynotes.concat(keynotesReducer.keynotes));
        }
    }, [keynotesReducer.isFetching])

    return [keynotes,loading,totalPages,resultSet,searching,finishedSearch]
}

export function useActiveKeynote() {
    const dispatch = useDispatch();
    const [exhibition] = useExhibition();

    const [keynote, setKeynote] = useState();
    const [loading, setLoading] = useState(false);
    const [fetchPending,setFetchPending] = useState(false);

    const keynotesReducer = useSelector(state => state.activeKeynotesReducer);
    const usersReducer = useSelector(state => state.usersReducer);
    
    const [incomingMessage] = useRawPushNotification();

    useEffect(() => {
        if (!incomingMessage || incomingMessage.getNotificationType() != PushNotification.NOTIFICATION_TYPES.keynoteUpdate) {
            return
        }

        if (incomingMessage.getPayload() && incomingMessage.getPayload().isRunning()) {
            setKeynote(incomingMessage.getPayload())
        } else {
            setKeynote(undefined)
        }
    }, [incomingMessage])

    useEffect(() => {
        if (fetchPending || !exhibition || !usersReducer.currentUser || loading) {
            return;
        }

        if (keynotesReducer.isFetching) {
            if (!fetchPending) {
                setFetchPending(true);
            }
            return;
        } else {
            setLoading(true);
            dispatch(fetchActiveKeynotes(exhibition.getId(),{
                pageIndex:1,
                pageSize:1,
                filters:[FilterParams.filterBy(Keynote.attributes.running, true)]
            }))
        }
    }, [exhibition,usersReducer.currentUser])

    useEffect(() => {
        if ((fetchPending && keynotesReducer.isFetching) || !fetchPending) {
            return;
        }

        setLoading(true);
        setFetchPending(false);
        dispatch(fetchActiveKeynotes(exhibition.getId(),{
            pageIndex:1,
            pageSize:1,
            filters:[FilterParams.filterBy(Keynote.attributes.running, true)]
        }))
    }, [keynotesReducer.isFetching,fetchPending])

    useEffect(() => {
        if (keynotesReducer.isFetching || 
            !loading ||
            !keynotesReducer.keynotes
        ) {
            return;
        }
        setLoading(false);

        if (keynotesReducer.error.isError()) {
            return
        }

        if(keynotesReducer.keynotes.length !== 1) { //|| !usersReducer.currentUser.hasReadPermissions()
            return
        }

        setKeynote(keynotesReducer.keynotes[0]);
        
    }, [keynotesReducer.isFetching])

    return [keynote,loading]
}

export function useKeynote(id) {
    const dispatch = useDispatch();
    const [exhibition] = useExhibition()
    
    const [keynote, setKeynote] = useState();
    const [loading, setLoading] = useState(false);

    const keynotesReducer = useSelector(state => state.keynotesReducer);
    const usersReducer = useSelector(state => state.usersReducer);

    useEffect(() => {
        if (!usersReducer.currentUser || 
            !exhibition ||
            !id 
            //|| !usersReducer.currentUser.hasReadPermissions()
        ) {
            return;
        }

        
        dispatch(fetchKeynote(exhibition.getId(),id))
        setLoading(true);

    }, [exhibition, id, usersReducer.currentUser])

    useEffect(() => {
        if (keynotesReducer.isFetching || !keynotesReducer.keynote) {
            return
        }

        setLoading(false)

        if (keynotesReducer.error.isError()) {
            return;
        }
        
        setKeynote(keynotesReducer.keynote);
    }, [keynotesReducer.isFetching])



    return [keynote,loading];
}

export default useKeynotes
